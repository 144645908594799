import {
    VEHICLE_VIEW_REQUEST,
    VEHICLE_VIEW_SUCCESS,
    VEHICLE_VIEW_FAIL,
    ALL_BADGES_VIEW_REQUEST,
    ALL_BADGES_VIEW_SUCCESS,
    ALL_BADGES_VIEW_FAIL,
    ALL_VEHICLE_VIEW_REQUEST,
    ALL_VEHICLE_VIEW_SUCCESS,
    ALL_VEHICLE_VIEW_FAIL,
    VEHICLE_CREATE_REQUEST,
    VEHICLE_CREATE_SUCCESS,
    VEHICLE_CREATE_FAIL,
    VEHICLE_UPDATE_REQUEST,
    VEHICLE_UPDATE_SUCCESS,
    VEHICLE_UPDATE_FAIL,
    VEHICLE_DELETE_REQUEST,
    VEHICLE_DELETE_SUCCESS,
    VEHICLE_DELETE_FAIL,
} from "../constants"

export const vehicleReducer = (
    state = {
        vehicle: {
            vehicle: null,
            allVehicle: null,
        },
        allBadges: null,
    },
    action
) => {
        switch (action.type) {

        // VEHICLE VIEW TABLE
        case VEHICLE_VIEW_REQUEST : 
            return {
                ...state,
                loading : true,
            };
        case VEHICLE_VIEW_SUCCESS :
            return {
                ...state,
                loading : false,
                vehicle: action.payload,
            };
        case VEHICLE_VIEW_FAIL :
            return {
                ...state,
                loading : false,
                error: action.payload,
            };
            
        case ALL_BADGES_VIEW_REQUEST : 
            return {
                ...state,
                loading : true,
            };
        case ALL_BADGES_VIEW_SUCCESS :
            return {
                ...state,
                loading : false,
                allBadges: action.payload,
            };
        case ALL_BADGES_VIEW_FAIL :
            return {
                ...state,
                loading : false,
                error: action.payload,
            };


        case ALL_VEHICLE_VIEW_REQUEST : 
            return {
                ...state,
                loading : true,
            };
        case ALL_VEHICLE_VIEW_SUCCESS :
            return {
                ...state,
                loading : false,
                vehicle: action.payload,
            };
        case ALL_VEHICLE_VIEW_FAIL :
            return {
                ...state,
                loading : false,
                error: action.payload,
            };

        // VEHICLE CREATE 
        case VEHICLE_CREATE_REQUEST :
            return {
                ...state,
                loading: true
            }
        case VEHICLE_CREATE_SUCCESS :
            return {
                ...state,
                loading: false,
                // vehicle: state.vehicle.map((x) =>
                //     x.id === action.payload.id 
                //     ? action.payload : x
                // ),
                // vehicle: [...state.vehicle, action.payload],
            }
        case VEHICLE_CREATE_FAIL :
            return {
                ...state,
                loading: false,
                error: action.payload,
            }

        // VEHICLE UPDATE
        case VEHICLE_UPDATE_REQUEST:
            return {
            ...state,
            loading: true,
            };
        case VEHICLE_UPDATE_SUCCESS:
            return {
            ...state,
            loading: false,
            // old code hata dya
            vehicle: state.vehicle.map((x) =>
                x.id == action.payload.id ? action.payload : x
                ),
            };
        case VEHICLE_UPDATE_FAIL:
            return {
            ...state,
            loading: false,
            error: action.payload,
            };
        // VEHICLE DELETE
        case VEHICLE_DELETE_REQUEST:
            return {
            ...state,
            loading: true,
            };
        case VEHICLE_DELETE_SUCCESS:
            return {
            ...state,
            loading: false,
            // old code hata dya
            vehicle: state.vehicle.filter((x) =>
                x.id !== action.payload.id),
            };
        case VEHICLE_DELETE_FAIL:
            return {
            ...state,
            loading: false,
            error: action.payload,
            };

        default:
            return state;
    }
}