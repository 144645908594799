/** @format */

import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateReservation } from "../../../actions/reservationAction";
import {
  getAllRoutesOfCompany,
  getAllRoutesOfCompanyV2,
  reAssignRoteAction,
  tripForDate,
  updateRouteById,
} from "../../../actions/routeActions";
import { notifySuccess } from "../../../components/toast";
import { allDriversAvailableForRoutes } from "../../../actions/userActions";

const AssignDriverModal = ({
  showDropDown,
  openDropDown,
  current,
  shift,
  selectedDate,
  index,
}) => {
  const auth = useSelector((state) => state.auth);
  const users = useSelector((state) => state.users);
  const routes = useSelector((state) => state.routes);
  const dispatch = useDispatch();

  const [values, setValues] = useState({
    _id: "",
    driverId: "",
    assignedDate: "",
    routeId: "",
  });
  useEffect(() => {
    setValues({
      ...values,
      _id: current.id,
      driverId: current?.driverId?.id,
      routeId: current?.id,
    });
  }, [current]);

  const handleChange = (fieldName) => (event) => {
    setValues({
      ...values,
      [fieldName]: event.target.value,
    });
  };

  // const routeData = {
  //   _id: values._id,
  //   driverId: values.driverId,
  //   assignedDate: moment().format().toString().substring(0, 19) + ".000Z",
  //   assigneeId: auth.user.id,
  //   driverBookingStatus: "Assigned",
  // };

  // const updateRouteData = {
  //   routeId: values.routeId,
  //   driverId: values.driverId,
  //   assignedDate: moment().format().toString().substring(0, 19) + ".000Z",
  //   assigneeId: auth.user.id,
  // };
  const handleSubmit = async (event) => {
    event.preventDefault();
    // console.log("first")

    // if (current.driverBookingStatus !== "Unassigned") {
    // console.log("Unassigned cond");
    // dispatch(
    //   reAssignRoteAction(
    //     {
    //       ...updateRouteData,
    //       companyId: auth.user.companyId.id,
    //       reassigned: true,
    //     },
    //     successHandler
    //   )
    // );

    dispatch(
      tripForDate(
        {
          companyId: current.companyId,
          driverId: values.driverId,
          routeId: values._id,
          // tripStartTime:
          //   moment(selectedDate).format().toString().substring(0, 19) +
          //   ".000Z",
          shiftTime:
            shift === "toggleValuesAM"
              ? "AM"
              : shift === "toggleValuesPM"
              ? "PM"
              : "MID",
          // // notComing: [customerId],
          tripStartTime:
            selectedDate.format().toString().substring(0, 19) + ".000Z",
        },
        shift
      )
    );
    // } else {
    // console.log("not Unassigned cond");
    // if (routeData.driverId !== "") {
    //   console.log("not Unassigned cond if");
    //   dispatch(updateRouteById(routeData, successHandler));
    // }
    // }
    openDropDown();
  };

  const successHandler = () => {
    // dispatch(getAllRoutesOfCompany({ companyId: auth.user.companyId.id, limit: routes.limit, page: routes.page}));
    dispatch(getAllRoutesOfCompanyV2({ companyId: auth.user.companyId.id }));
    dispatch(
      allDriversAvailableForRoutes({ companyId: auth.user.companyId.id })
    );
    // notifySuccess("")
  };

  // console.log("val==>", current);

  return (
    <>
      <div
        className={`modal fade ${showDropDown ? "show modal-y-axis" : "hide"}`}
        role="dialog"
        data-backdrop="static"
        data-keyboard="false" // To prevent closing with the keyboard
        onClick={(e) => {
          // Check if the click occurred outside the modal content
          if (e.target === e.currentTarget) {
            openDropDown(); // Call your close modal function
          }
        }}
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLongTitle">
                {" "}
                Select The Driver
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => openDropDown()}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group my-3">
                    <label
                      className="f-14 text-dark-grey mb-12"
                      data-label=""
                      htmlFor="cname"
                    >
                      Select the Driver
                      <sup className="text-danger f-12">*</sup>
                    </label>
                    <div className="select-picker">
                      <select
                        name="default_task_status"
                        id="default_task_status"
                        className="form-control select-picker"
                        style={{ height: "39px" }}
                        value={values.driverId}
                        onChange={handleChange("driverId")}
                      >
                        <option value=""> Select the Driver</option>
                        {users?.allDriversAvailableForRoutes?.length > 0 &&
                          users?.allDriversAvailableForRoutes?.map(
                            (current, i) => {
                              return (
                                <option value={current.id} key={i}>
                                  {" "}
                                  {current.firstName + " " + current.lastName}
                                </option>
                              );
                            }
                          )}
                        {/* <option value="2"> Mohamad Ahmed</option>
                        <option value="3"> James Smith</option>
                        <option value="4"> Stave</option> */}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn-secondary rounded f-14 p-2 mr-3"
                data-dismiss="modal"
                onClick={() => openDropDown()}
              >
                Close
              </button>
              {/* <button type="button" className="btn-primary rounded f-14 p-2 mr-3" onClick={handleSubmit}>Save changes</button> */}
              <button
                type="button"
                className="btn-primary rounded f-14 p-2 mr-3"
                onClick={handleSubmit}
              >
                Save changes
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default AssignDriverModal;
