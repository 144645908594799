/** @format */

import axios from "axios";
import React, { useEffect, useState } from "react";
// import logo2 from "../../assetsgeneral/images/head-logo.png"
// import logo2 from "../../assetsgeneral/images/CONNECT CARE TRANS-04.png"
// import logo2 from "../../assetsgeneral/images/CONNECT_CARE_TRANS-04small.jpg"
import logo2 from "../../assets/images/CONNECTCARE_WhiteLogo.png";
import { NavLink } from "react-router-dom";
import { ENVIRONMENT } from "../../constants";

const Footer = () => {
  const [year, setYear] = useState();
  const getYear = async () => {
    const { data } = await axios.get(
      `https://worldtimeapi.org/api/timezone/Asia/Karachi`
    );
    setYear(data.utc_datetime.substring(0, 4));
  };
  useEffect(() => {
    getYear();
  }, []);
  return (
    <>
      <div className="footer-section">
        <div className="container">
          <div className="row">
            <div className="col-md-4">
              <div
              // className="footer-logo"
              >
                <NavLink to="/">
                  <img
                    src={logo2}
                    id="footerlog"
                    style={{ maxWidth: "60% !important" }}
                  />
                  {/* <img src={logo2} id="footerlogo"/> */}
                </NavLink>
              </div>
            </div>
            <div className="col-md-4">
              <div className="footer-text">
                <h5>Quick Links</h5>
              </div>
              <div className="footer-menu">
                <ul>
                  <li>
                    <i className="fa fa-chevron-right" id="foot-icon"></i>
                    <a href="/privacy-policy">Privacy Policy</a>
                  </li>
                  <li>
                    <i className="fa fa-chevron-right" id="foot-icon"></i>
                    <a href="/contact-us">Contact Us</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-4">
              <div className="footer-text">
                <h5>Contact Us</h5>
              </div>
              <div className="footer-menu">
                <ul>
                  {ENVIRONMENT === "local" && (
                    <li>
                      <i className="fa fa-phone" id="foot-icon"></i>
                      <a href="#">(773) 340-4373</a>
                    </li>
                  )}
                  <li>
                    <i className="fa fa-envelope" id="foot-icon"></i>
                    <a href="#">Info@connectcaretrans.net</a>
                  </li>
                  {/* <li>
                    <i className="fa fa-globe" id="foot-icon"></i>
                    <a href="#">www.connectcaretrans.com</a>
                  </li> */}
                  {ENVIRONMENT === "local" && (
                    <li>
                      <i className="fa fa-map-marker" id="foot-icon"></i>
                      <a href="#">
                        5858 N Northwest Hwy Ste G, Chicago, IL 60631
                      </a>
                    </li>
                  )}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-bot">
        <div>
          <div className="footerb-text">
            <p>
              © Copyright Connect Care Trans {year}. All Rights Reserved.
              Developed By Iconic Tek
            </p>
          </div>
        </div>
      </div>
    </>
  );
};
export default Footer;
