/** @format */

import React from "react";
import { Routes, Route } from "react-router-dom";
import AdminDashboard from "./pages/adminPages/AdminDashboardPage";
import "./assets/css/all.min.css";
import "./assets/css/bootstrap-icons.css";
import "./assets/css/bootstrap-timepicker.min.css";
import "./assets/css/datepicker.min.css";
import "./assets/css/font-awesome.css";
import "./assets/css/font-awesome.min.css";
import "./assets/css/main.css";
import "./assets/css/main.css.map";
import "./assets/css/main.min.css";
import "./assets/css/select2.min.css";
import "./assets/css/simple-line-icons.css";
import "./assets/css/custom-css.css";
// import "./assets/css/login.css";
// import "./App.css";

// import "./assetsgeneral/css/font-awesome.css";
// import "./assetsgeneral/css/font-awesome.min.css";
// import "./assetsgeneral/css/style.css";
// import "./assetsgeneral/fonts/fontawesome-webfont.eot";
// import "./assetsgeneral/fonts/fontawesome-webfont.svg";
// import "./assetsgeneral/fonts/fontawesome-webfont.ttf";
// import "./assetsgeneral/fonts/fontawesome-webfont.woff";
// import "./assetsgeneral/fonts/fontawesome-webfont.woff2";

import AdminBookingPage from "./pages/adminPages/AdminBookingPage";
import AdminDispatchingPage from "./pages/adminPages/AdminDispatchingPage";
import AdminClientViewPage from "./pages/adminPages/AdminClientViewPage";
import AdminReportsPage from "./pages/adminPages/AdminReportsPage";
import AdminReportsPageV2 from "./pages/adminPages/AdminReportsPageV2";
import AdminDailyActivityReportPage from "./pages/adminPages/AdminDailyActivityReportPage";
import AdminTrackPage from "./pages/adminPages/AdminTrackPage";
import AdminSettingViewCompanyInfoPage from "./pages/adminPages/AdminSettingViewCompanyInfoPage";
import AdminSettingVehiclePage from "./pages/adminPages/AdminSettingVehiclePage";
import AdminSettingDriverPage from "./pages/adminPages/AdminSettingDriverPage";
import AdminSettingInvoicingPage from "./pages/adminPages/AdminSettingInvoicingPage";
import AdminSettingPaymentsPage from "./pages/adminPages/AdminSettingPaymentsPage";
import AdminUserPage from "./pages/adminPages/AdminUserPage";
import AdminPermissionCreatePermission from "./pages/adminPages/AdminPermissionCreatePermission";
import AdminPermissionPermissionPage from "./pages/adminPages/AdminPermissionPermissionPage";
import AdminAddUserPage from "./pages/adminPages/AdminAddUserPage";
import AdminBookingCalenderDriverPage from "./pages/adminPages/AdminBookingCalenderDriverPage";
import AdminBookingPastPage from "./pages/adminPages/AdminBookingPastPage";
import AdminBookingTodayPage from "./pages/adminPages/AdminBookingTodayBookingPage";
import AdminBookingFuturePage from "./pages/adminPages/AdminBookingFutureBookingPage";
import AdminUserViewPage from "./pages/adminPages/AdminUserViewPage";
import AdminDriverViewPage from "./pages/adminPages/AdminDriverViewPage";
import LoginPage from "./pages/loginPage/LoginPage";
import AdminClientAddComponentPage from "./pages/adminPages/AdminClientAddComponentPage";
import AdminClientEditComponentPage from "./pages/adminPages/AdminClientEditComponentPage";
import AdminAddVehiclePage from "./pages/adminPages/AdminAddVehiclePage";
import AdminAddDriver from "./pages/adminPages/AdminAddDriver";
import Demo from "./pages/Demo";
import Demo2 from "./pages/Demo2";
import ModalTesting from "./pages/adminPages/ModalTesting";
import AdminDispatchingSchedullingOrderPage from "./pages/adminPages/AdminDispatchingSchedullingOrderPage";
import { useSelector } from "react-redux";
import Unauthorized from "./pages/Unauthorized";
import DriverDashboardPage from "./pages/driverPages/DriverDashboardPage";
import DriverMyOrderPage from "./pages/driverPages/DriverMyOrderPage";
import DriverMyHistoryPage from "./pages/driverPages/DriverMyHistoryPage";
import DriverMyProfilePage from "./pages/driverPages/DriverMyProfilePage";
import Checkuser from "./pages/Checkuser";
import AdminRoutePage from "./pages/adminPages/AdminRoutesPage";
import AdminClientFullDetailPage from "./pages/adminPages/AdminClientFullDetailPage";
import AdminVehicleFullDetailPage from "./pages/adminPages/AdminVehicleFullDetailPage";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AdminSettingGenerateInvoicePage from "./pages/adminPages/AdminSettingGenerateInvoicePage";
import AdminAddRoutePage from "./pages/adminPages/AdminAddRoutePage";
import AdminOffHourSettingPage from "./pages/adminPages/AdminOffHourSettingPage";
import AdminRouteDetailsPage from "./pages/adminPages/AdminRouteDetailsPage";
import AdminEditRoutePage from "./pages/adminPages/AdminEditRoutePage";
import DriverRoutePage from "./pages/driverPages/DriverRoutePage";
import DriverRouteTodayRoutePage from "./pages/driverPages/DriverRouteTodayRoutePage";
// import DriverRouteActiveRoutePage from "./pages/driverPages/DriverRouteActiveRoutePage";
import DriverMyOrderTodayOrderPage from "./pages/driverPages/DriverMyOrderTodayOrderPage";
// import DriverMyOrderActiveOrderPage from "./pages/driverPages/DriverMyOrderActiveOrderPage";
import DriverNavigatePage from "./pages/driverPages/DriverNavigatePage";
import DriverNavigateRoutePage from "./pages/driverPages/DriverNavigateRoutePage";
import AdminCreateReturnRoutePage from "./pages/adminPages/AdminCreateReturnRoutePage";
import DriverRouteDetailsPage from "./pages/driverPages/DriverRouteDetailsPage";
import DriverRouteStartRide from "./pages/driverPages/DriverRouteStartRide";
import AdminRoutesLogPage from "./pages/adminPages/AdminRoutesPage/AdminRoutesLogPage";
// import Home from "./pages/landingWebsitePages/Home";
// import WhyUs from "./pages/landingWebsitePages/WhyUs";
// import Services from "./pages/landingWebsitePages/Services";
// import ContactUs from "./pages/landingWebsitePages/ContactUs";
// import Faq from "./pages/landingWebsitePages/Faqpage";
import AdminDispatchingPendingOrderPage from "./pages/adminPages/AdminDispatchingPendingOrderPage";
import DriverChangePasswordPage from "./pages/driverPages/DriverChangePasswordPage";
import Demo3 from "./pages/Demo3";
import AdminDispatchEditPage from "./pages/adminPages/AdminDispatchEditPage";
import AdminMessageComposePage from "./pages/adminPages/AdminMessageComposePage";
import AdminMessageInboxViewPage from "./pages/adminPages/AdminMessageInboxViewPage";
import AdminMessagingThreadPage from "./pages/adminPages/AdminMessagingThreadPage";
import AdminNotificationsPage from "./pages/adminPages/AdminNotificationsPage";
import PrivacyPolicyPage from "./pages/landingWebsitePages/PrivacyPolicyPage";
import AdminBookingView from "./pages/adminPages/AdminBookingView";
import AdminOtherSettingPage from "./pages/adminPages/AdminOtherSettingPage";
import AdminUserLogInActivitiesPage from "./pages/adminPages/AdminUserLogInActivitiesPage";
import AdminPermissionEditModalPage from "./pages/adminPages/AdminPermissionEditModalPage";
import ExternalCompanyClientsListPage from "./pages/externalCompanyPages/ExternalCompanyClientsListPage";
import ExternalCompanyRoutesListPage from "./pages/externalCompanyPages/ExternalCompanyRoutesListPage";
import ExternalCompanyUserCreatePage from "./pages/externalCompanyPages/ExternalCompanyUserCreatePage";
import ExternalCompanyViewRouteDetails from "./pages/externalCompanyPages/ExternalCompanyViewRouteDetails";
import ExternalCompanyChangePassword from "./pages/externalCompanyPages/ExternalCompanyChangePassword";
import ExternalCompanyMyProfilePage from "./pages/externalCompanyPages/ExternalCompanyMyProfilePage";
import ExternalCompanyTracking from "./pages/externalCompanyPages/ExternalCompanyTracking";
import Demo4 from "./pages/Demo4";
import AdminActiveUserPage from "./pages/adminPages/AdminActiveUserPage";
import AdminInactiveUserPage from "./pages/adminPages/AdminInactiveUserPage";
import AdminRouteRideAction from "./pages/adminPages/AdminRoutesPage/AdminRouteRideAction";
import AdminChatPage from "./pages/adminPages/AdminChatPage";
import DriverEarningPage from "./pages/driverPages/DriverEarningPage";
import AdminPassengerFullDetailPage from "./pages/adminPages/AdminPassengerFullDetailPage";
import AdminPassengerEditComponentPage from "./pages/adminPages/AdminPassengerEditComponentPage";
import AdminPassengerAddComponentPage from "./pages/adminPages/AdminPassengerAddComponentPage";
import AdminPassengerViewPage from "./pages/adminPages/AdminPassengerViewPage";
import AdminBookingRideActionByAdmin from "./pages/adminPages/AdminBookingRideActionByAdmin";
import AccountDetailsPage from "./pages/landingWebsitePages/AccountDetailsPage";
import AdminNextBillionAllPage from "./pages/adminPages/AdminNextBillionAllPage";
import AdminNextBillionIndividual from "./pages/adminPages/AdminNextBillionIndividual";
import SendDriverLocation from "./pages/SendDriverLocation";
import MapComponent from "./pages/MapComponent";
import { ENVIRONMENT } from "./constants";
import AdminSignalRouteViewPage from "./pages/adminPages/AdminRoutesPage/AdminSignalRouteViewPage";
import CallPopup from "./components/chatComponent/callPopup";
import AdminRoutePassengerViewPage from "./pages/adminPages/AdminRoutePassengerViewPage";
import AdminRoutePassengerAddComponentPage from "./pages/adminPages/AdminRoutePassengerAddComponentPage";
import AdminPetrackAllPage from "./pages/adminPages/AdminPetrackAllPage";
import AdminCarTrackPage from "./pages/adminPages/AdminCarTrackPage";
import AdminCarTrackDetailView from "./pages/adminPages/AdminCarTrackDetailView";
import AdminBookingOptimizePage from "./pages/adminPages/AdminBookingOptimizePage";
import AdminDriverLogInActivitiesPage from "./pages/adminPages/AdminDriverLogInActivitiesPage";
// import BookingOnlinePage from "./pages/landingWebsitePages/BookingOnlinePage";
// import AdminAccountingDriverSettlementPage from "./pages/adminPages/AdminAccountingDriverSettlementPage";
// import AdminAccountingDriverSettlementDetailPage from "./pages/adminPages/AdminAccountingDriverSettlementDetailPage";
// import AdminAccountingDriverSettlementAddPage from "./pages/adminPages/AdminAccountingDriverSettlementAddPage";
// import Faq from "./pages/landingWebsitePages/Faq";
import AdminRouteCalculatorReport from "./pages/adminPages/AdminReportPages/AdminRouteCalculatorReport";
import AdminDriverRouteTripReport from "./pages/adminPages/AdminReportPages/AdminDriverRouteTripReport";
import AdminAccountingOverviewPage from "./pages/adminPages/AdminAccountingOverviewPage";
import AdminAccountingDriverSettlementNewPage from "./pages/adminPages/AdminAccountingDriverSettlementNewPage";
import AdminAccountingDriverSettlementReport from "./pages/adminPages/AdminAccountingDriverSettlementReport";
import AdminPercentageReportPage from "./pages/adminPages/AdminPercentageReportPage";
import AdminProfitAndLossReportPage from "./pages/adminPages/AdminProfitAndLossReportPage";
import AdminAnnuallyDriverEarningsReportPage from "./pages/adminPages/AdminAnnuallyDriverEarningsReportPage";
import AdminNotePage from "./pages/adminPages/AdminNotePage";
import Demo7 from "./pages/Demo7";
import AdminRouteDistanceReportPage from "./pages/adminPages/AdminReportPages/AdminRouteDistanceReportPage";
import AdminRouteSummaryReportPage from "./pages/adminPages/AdminReportPages/AdminRouteSummaryReportPage";
import MapWithMovingMarker from "./pages/MapWithMovingMarker ";
// import Demo7 from "./pages/Demo7";

const App = () => {
  const auth = useSelector((state) => state.auth);

  return (
    <>
      <ToastContainer />
      <Routes>
        {/* <Route path="/" element={<Home />} /> */}
        {/* <Route path="/index" element={<Home />} />
        <Route path="/about-us" element={<WhyUs />} />
        <Route path="/services" element={<Services />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/booking" element={<BookingOnlinePage />} /> */}
        {/* <Route path="/faq" element={<Faq />} /> */}
        <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
        <Route path="/access-denied" element={<AccountDetailsPage />} />

        <Route path="/login" element={<LoginPage />} />
        <Route path="/" element={<LoginPage />} />
        <Route path="*" element={<Unauthorized />} />
        {auth && auth.user && !auth.user.isExternalCompany && (
          <>
            <Route path="/dashboard-admin" element={<AdminDashboard />} />
            <Route path="/dashboard-dispatcher" element={<AdminDashboard />} />
            <Route path="/dashboard-driver" element={<DriverDashboardPage />} />
            <Route path="/earnings" element={<DriverEarningPage />} />
            <Route path="/routes" element={<AdminRoutePage />} />
            <Route
              path="/view-route/:id"
              element={<AdminSignalRouteViewPage />}
            />
            <Route path="/weekly-routes" element={<DriverRoutePage />} />
            <Route path="/booking-order" element={<AdminBookingPage />} />
            <Route path="/today-bookings" element={<AdminBookingTodayPage />} />
            <Route
              path="/past-bookings"
              element={<AdminBookingPastPage />}
            />{" "}
            <Route path="/home" element={<AdminAccountingOverviewPage />} />
            <Route
              path="/driver-settlement-report"
              element={<AdminAccountingDriverSettlementReport />}
            />
            <Route
              path="/driver-settlement"
              element={<AdminAccountingDriverSettlementNewPage />}
            />
            {/* <Route
              path="/driver-settlement2"
              element={<AdminAccountingDriverSettlementPage />}
            /> */}
            {/* <Route
              path="/add-driver-settlement"
              element={<AdminAccountingDriverSettlementAddPage />}
            /> */}
            {/* <Route
              path="/driver-settlement/details/:id"
              element={<AdminAccountingDriverSettlementDetailPage />}
            /> */}
            <Route
              path="/scheduled-bookings"
              element={<AdminBookingFuturePage />}
            />
            <Route
              path="/optimize-bookings"
              element={<AdminBookingOptimizePage />}
            />
            {/* <Route path="/ride/:id" element={<AdminBookingRideActionByAdmin />} /> */}
            {/* <Route
                path="/calendar"
                element={<AdminBookingCalenderDriverPage />}
              /> */}
            <Route
              path="/requested-orders"
              element={<AdminDispatchingPage />}
            />
            <Route
              path="/pending-orders"
              element={<AdminDispatchingPendingOrderPage />}
            />
            <Route path="/booking/details/:id" element={<AdminBookingView />} />
            <Route
              path="/scheduled-orders"
              element={<AdminDispatchingSchedullingOrderPage />}
            />
            {/* <Route path="/clients" element={<AdminClientViewPage />} />
              <Route
                path="/create-client"
                element={<AdminClientAddComponentPage />}
              />
              <Route
                path="/edit-client"
                element={<AdminClientEditComponentPage />}
              /> */}
            <Route path="/passengers" element={<AdminPassengerViewPage />} />
            <Route
              path="/route-passengers"
              element={<AdminRoutePassengerViewPage />}
            />
            <Route
              path="/create-passenger"
              element={<AdminPassengerAddComponentPage />}
            />
            <Route
              path="/create-route-passenger"
              element={<AdminRoutePassengerAddComponentPage />}
            />
            <Route
              path="/edit-passenger"
              element={<AdminPassengerEditComponentPage />}
            />
            <Route path="/report" element={<AdminReportsPageV2 />} />
            <Route path="/report-old" element={<AdminReportsPage />} />
            <Route
              path="/route-calculator-report"
              element={<AdminRouteCalculatorReport />}
            />
            <Route
              path="/route-summary-report"
              element={<AdminRouteSummaryReportPage />}
            />
            {/* <Route
              path="/route-distance-report"
              element={<AdminRouteDistanceReportPage />}
            /> */}
            <Route
              path="/driver-route-trip-report"
              element={<AdminDriverRouteTripReport />}
            />
            <Route
              path="/daily-activity-report"
              element={<AdminDailyActivityReportPage />}
            />
            <Route
              path="/percentage-report"
              element={<AdminPercentageReportPage />}
            />
            <Route
              path="/profit-&-loss-report"
              element={<AdminPercentageReportPage />}
            />
            {/* <Route
              path="/profit-&-loss-report"
              element={<AdminProfitAndLossReportPage />}
            /> */}
            <Route
              path="/annually-driver-earnings-report"
              element={<AdminAnnuallyDriverEarningsReportPage />}
            />
            <Route path="/track" element={<AdminTrackPage />} />
            {/* <Route path="/tracking" element={<AdminCarTrackPage />} />
            <Route
              path="/tracking-detail/:id"
              element={<AdminCarTrackDetailView />}
            /> */}
            {/* <Route path="/track-all" element={<AdminNextBillionAllPage />} /> */}
            {/* <Route path="/petrack" element={<AdminPetrackAllPage />} /> */}
            {/* <Route
              path="/track-individual"
              element={<AdminNextBillionIndividual />}
            /> */}
            <Route path="/routes/add" element={<AdminAddRoutePage />} />
            <Route path="/route-log/:id" element={<AdminRoutesLogPage />} />
            <Route path="/ride-action/:id" element={<AdminRouteRideAction />} />
            <Route path="/routes/edit/:id" element={<AdminEditRoutePage />} />
            <Route
              path="/routes/create-return/:id"
              element={<AdminCreateReturnRoutePage />}
            />
            <Route
              path="/routes/details/:id"
              element={<AdminRouteDetailsPage />}
            />
            <Route
              path="/view-company-info"
              element={<AdminSettingViewCompanyInfoPage />}
            />
            <Route path="/vehicles" element={<AdminSettingVehiclePage />} />
            <Route path="/add-vehicle" element={<AdminAddVehiclePage />} />
            {/* <Route path="/team-table" element={<AdminSettingDriverPage />} /> */}
            {/* <Route path="/add-driver" element={<AdminAddDriver />} /> */}
            {/* <Route path="/driver-view" element={<AdminDriverViewPage />} /> */}
            <Route path="/invoices" element={<AdminSettingInvoicingPage />} />
            <Route
              path="/invoices/:id"
              element={<AdminSettingGenerateInvoicePage />}
            />
            <Route path="/payers" element={<AdminSettingPaymentsPage />} />
            <Route path="/off-hours" element={<AdminOffHourSettingPage />} />
            <Route path="/other-settings" element={<AdminOtherSettingPage />} />
            <Route path="/notes" element={<AdminNotePage />} />
            <Route
              path="/permission"
              element={<AdminPermissionPermissionPage />}
            />
            <Route
              path="/create-permission"
              element={<AdminPermissionCreatePermission />}
            />
            <Route
              path="/edit-permission/:id"
              element={<AdminPermissionEditModalPage />}
            />
            <Route path="/users" element={<AdminUserPage />} />
            {/* <Route path="/active-users" element={<AdminActiveUserPage />} /> */}
            {/* <Route path="/inactive-users" element={<AdminInactiveUserPage />} /> */}
            <Route path="/add-user" element={<AdminAddUserPage />} />
            <Route
              path="/user-login-activities"
              element={<AdminUserLogInActivitiesPage />}
            />
            <Route
              path="/driver-login-activities"
              element={<AdminDriverLogInActivitiesPage />}
            />
            <Route path="/user-view/:id" element={<AdminUserViewPage />} />
            {/* <Route path="/client-view/:id" element={<AdminClientFullDetailPage />} /> */}
            <Route
              path="/passenger-view/:id"
              element={<AdminPassengerFullDetailPage />}
            />
            <Route
              path="/vehicle-view/:id"
              element={<AdminVehicleFullDetailPage />}
            />
            <Route
              path="/all-notifications"
              element={<AdminNotificationsPage />}
            />
            <Route
              path="/compose-message"
              element={<AdminMessageComposePage />}
            />
            <Route path="/inbox" element={<AdminMessageInboxViewPage />} />
            <Route
              path="/messaging-thread/:id"
              element={<AdminMessagingThreadPage />}
            />
            <Route
              path="/my-scheduled-orders"
              element={<DriverMyOrderPage />}
            />
            <Route path="/my-history" element={<DriverMyHistoryPage />} />
            <Route path="/my-profile" element={<DriverMyProfilePage />} />
            <Route
              path="/today-route"
              element={<DriverRouteTodayRoutePage />}
            />
            <Route
              path="/today-order"
              element={<DriverMyOrderTodayOrderPage />}
            />
            <Route
              path="/navigate-reservation"
              element={<DriverNavigatePage />}
            />
            <Route
              path="/navigate-route"
              element={<DriverNavigateRoutePage />}
            />
            <Route
              path="/today-route/details/:id"
              element={<DriverRouteDetailsPage />}
            />
            <Route
              path="/today-route/start-ride/:id"
              element={<DriverRouteStartRide />}
            />
            <Route
              path="/change-password"
              element={<DriverChangePasswordPage />}
            />
            <Route
              path="/change-password"
              element={<DriverChangePasswordPage />}
            />
            <Route
              path="/dispatch-edit/:id"
              element={<AdminDispatchEditPage />}
            />
            <Route path="/chat" element={<AdminChatPage />} />
          </>
        )}
        {auth && auth.user && auth.user.isExternalCompany && (
          <>
            <Route
              path="/clients-list"
              element={<ExternalCompanyClientsListPage />}
            />
            <Route
              path="/create-client"
              element={<ExternalCompanyUserCreatePage />}
            />
            {/* <Route path="/routes-list" element={<ExternalCompanyRoutesListPage />} /> */}
            {/* <Route path="/routes/details/:id" element={<ExternalCompanyViewRouteDetails />} /> */}
            <Route
              path="/change-password"
              element={<ExternalCompanyChangePassword />}
            />
            <Route
              path="/my-profile"
              element={<ExternalCompanyMyProfilePage />}
            />
            <Route path="/track" element={<ExternalCompanyTracking />} />
          </>
        )}
        {auth &&
          auth.user &&
          (auth.user.role === "admin" || auth.user.jobTitle === "Admin") && (
            <>
              {/* <Route path="/dashboard-admin" element={<AdminDashboard />} /> */}

              {/* <Route path="/routes" element={<AdminRoutePage />} /> */}
            </>
          )}
        {auth &&
          auth.user &&
          auth.user.role === "user" &&
          auth.user.jobTitle === "Dispatcher" && (
            <>
              {/* <Route path="/dashboard-dispatcher" element={<AdminDashboard />} /> */}
              {/* <Route path="/booking-order" element={<AdminBookingPage />} />
            <Route path="/today-bookings" element={<AdminBookingTodayPage />} />
            <Route
              path="/past-bookings"
              element={<AdminBookingTomorrowPage />}
            />
            <Route
              path="/requested-orders"
              element={<AdminDispatchingPage />}
            />
            <Route
              path="/scheduled-orders"
              element={<AdminDispatchingSchedullingOrderPage />}
            />
            <Route path="/routes" element={<AdminRoutePage />} />
            <Route path="/routes/add" element={<AdminAddRoutePage />} />
            <Route path="/route-log/:id" element={<AdminRoutesLogPage />} />
            <Route path="/routes/edit/:id" element={<AdminEditRoutePage />} />
            <Route path="/routes/create-return/:id" element={<AdminCreateReturnRoutePage />} />
            <Route path="/routes/details/:id" element={<AdminRouteDetailsPage />} />
            <Route path="/my-profile" element={<DriverMyProfilePage />} /> */}
            </>
          )}
        {auth &&
          auth.user &&
          auth.user.role === "user" &&
          auth.user.jobTitle === "Driver" && (
            <>
              {/* <Route path="/dashboard-driver" element={<DriverDashboardPage />} /> */}
              {/* <Route path="/scheduled-orders" element={<DriverMyOrderPage />} />
              <Route path="/my-history" element={<DriverMyHistoryPage />} />
              <Route path="/my-profile" element={<DriverMyProfilePage />} /> */}
              {/* <Route path="/routes" element={<DriverRoutePage />} /> */}
              {/* <Route path="/today-route" element={<DriverRouteTodayRoutePage />} /> */}
              {/* <Route path="/active-route" element={<DriverRouteActiveRoutePage />} /> */}
              {/* <Route path="/today-order" element={<DriverMyOrderTodayOrderPage />} /> */}
              {/* <Route path="/active-order" element={<DriverMyOrderActiveOrderPage />} /> */}
              {/* <Route path="/navigate-reservation" element={<DriverNavigatePage />} />
              <Route path="/navigate-route" element={<DriverNavigateRoutePage />} />
              <Route path="/routes/details/:id" element={<DriverRouteDetailsPage />} />
              <Route path="/routes/start-ride/:id" element={<DriverRouteStartRide />} /> */}
            </>
          )}
        {/* <Route path="/addd" element={<ModalTesting />} /> */}
        {/* <Route path="/demo" element={<Demo />} /> */}
        {/* <Route path="/demo2" element={<Demo2 />} /> */}
        {/* <Route path="/demo3" element={<Demo3 />} /> */}
        {ENVIRONMENT === "local" && (
          <>
            <Route path="/demo4" element={<Demo4 />} />
            <Route
              path="/updateAllDriverLocations"
              element={<SendDriverLocation />}
            />
            <Route path="/checkcoordinates" element={<MapComponent />} />
            <Route path="/demo8" element={<MapWithMovingMarker />} />
          </>
        )}
        <Route path="/checkUser" element={<Checkuser />} />
        {/* <Route path="/demo7" element={<Demo7 />} /> */}
      </Routes>

      <CallPopup />
    </>
  );
};
export default App;
