/** @format */

import React, { useEffect, useState } from "react";
import AdminHeader from "../../components/adminComponents/adminHeaderSidebarTitleComponents/AdminHeader";
import AdminSidebar from "../../components/adminComponents/adminHeaderSidebarTitleComponents/AdminSidebar";
// import AdminDriverSettlement from "../../components/adminComponents/adminAccountingComponent/AdminDriverSettlement";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
// import AdminSearchByFilterComponentV2 from "../../components/adminComponents/adminDispatchingComponents/AdminSearchByFilterComponentV2";
import Select from "react-select";
import {
  // getFilterSettlements,
  getFilterSettlementsReports,
} from "../../actions/invoiceActions";
// import { fromTo } from "../../actions/reservationAction";
// import { tabAllDriverSettlementSearchFilter } from "../../actions/tabActions";
// import AdminDriverSettlementSearchFilter from "../../components/adminComponents/adminAccountingComponent/AdminDriverSettlementSearchFilter";
import { navigateUserFunc } from "../../actions/generalActions";
import AdminDriverSettlementReport from "../../components/adminComponents/adminAccountingComponent/AdminDriverSettlementReport";
import ExcelExport from "../../ExcelExport";

const AdminAccountingDriverSettlementReport = () => {
  const navigate = useNavigate();
  const [showFilter, setShowFilter] = useState(true);
  const auth = useSelector((state) => state.auth);
  const invoices = useSelector((state) => state.invoices);
  const dispatch = useDispatch();
  const myState = useSelector((state) => state.changeTheTab);
  const chicagoDateOptions = {
    timeZone: "America/Chicago",
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  };

  const chicagoDate = new Date().toLocaleDateString(
    "en-US",
    chicagoDateOptions
  );
  const currentDate = new Date(chicagoDate);
  // Adjust to get the Monday of the current week
  const firstDayOfWeek = new Date(currentDate);
  firstDayOfWeek.setDate(
    currentDate.getDate() - ((currentDate.getDay() + 6) % 7)
  );

  // Calculate the Sunday of the current week
  const lastDayOfWeek = new Date(firstDayOfWeek);
  lastDayOfWeek.setDate(firstDayOfWeek.getDate() + 6);

  // Format the dates to YYYY-MM-DD
  const weekFirst = `${firstDayOfWeek.getFullYear()}-${String(
    firstDayOfWeek.getMonth() + 1
  ).padStart(2, "0")}-${String(firstDayOfWeek.getDate()).padStart(2, "0")}`;
  const weekLast = `${lastDayOfWeek.getFullYear()}-${String(
    lastDayOfWeek.getMonth() + 1
  ).padStart(2, "0")}-${String(lastDayOfWeek.getDate()).padStart(2, "0")}`;

  const [values, setValues] = useState({
    companyId: "",
    driverId: "",
    driverName: "",
    batchNumber: "",
    monthFirst: weekFirst,
    monthLast: weekLast,
  });

  const handleChange = (fieldName) => (event) => {
    setValues({
      ...values,
      [fieldName]: event.target.value,
    });
  };

  const availableDrivers = useSelector(
    (state) => state.users.allDriversWithoutPagination
  );

  let orderSearch;
  // if (values.location !== ''){
  orderSearch = {
    companyId: auth.user.companyId.id,
    driverId: values.driverId,
    batchNumber: values.batchNumber,
    monthFirst: values.monthFirst + "T00:00:00.000Z",
    monthLast: values.monthLast + "T23:59:59.000Z",
  };

  const getMonthFirstAndLast = (year, month) => {
    const firstDay = moment(`${year}-${month}-01`).format("YYYY-MM-DD");
    const lastDay = moment(firstDay).endOf("month").format("YYYY-MM-DD");
    return { firstDay, lastDay };
  };

  // Map month names to month numbers
  const monthMap = {
    January: "01",
    February: "02",
    March: "03",
    April: "04",
    May: "05",
    June: "06",
    July: "07",
    August: "08",
    September: "09",
    October: "10",
    November: "11",
    December: "12",
  };

  const monthOptions = Object.keys(monthMap).map((month) => ({
    value: month,
    label: month,
  }));

  const handleClick = async (event) => {
    event.preventDefault();
    for (let key of Object.keys(orderSearch)) {
      if (orderSearch[key] === "") {
        delete orderSearch[key];
      }
    }

    // dispatch(tabAllDriverSettlementSearchFilter());
    dispatch(getFilterSettlementsReports(orderSearch));
    // dispatch(settlementData);
  };

  // const data = invoices?.reportData?.flatMap((item) =>
  //   item.reservations.map((reservation) => ({
  //     // ...item,
  //     // reservation: reservation,
  //     // const dateString = item.dateForReservation;
  //     // const [datePart, timePart] = dateString.split("T");
  //     // const [year, month, day] = datePart.split("-");
  //     // const formattedDate = `${month}/${day}/${year}`;
  //     // return {
  //     "Batch Number": item?.batchNumber,
  //     "Order Id": reservation?.orderId,
  //     // "Reservation Date": formattedDate,
  //     "Driver First Name": item?.driverId?.firstName,
  //     "Driver Last Name": item?.driverId?.lastName,
  //     "Passenger First Name": reservation?.customerId?.firstName,
  //     "Passenger Last Name": reservation?.customerId?.lastName,
  //     "Pick Up Address": reservation?.pickUpAddress?.address,
  //     "Drop Off Address": reservation?.dropOffAddress?.address,
  //     "Pick Up Time":
  //       (parseInt(reservation?.pickUpTime.substring(0, 2)) === 0
  //         ? 12
  //         : parseInt(reservation?.pickUpTime.substring(0, 2)) > 12
  //         ? parseInt(reservation?.pickUpTime.substring(0, 2)) - 12
  //         : parseInt(reservation?.pickUpTime.substring(0, 2))) +
  //       ":" +
  //       reservation?.pickUpTime.substring(3, 5) +
  //       (parseInt(reservation?.pickUpTime.substring(0, 2)) > 11 ? "pm" : "am"),

  //     "Drop Off Time":
  //       (parseInt(reservation?.dropOffTime.substring(0, 2)) === 0
  //         ? 12
  //         : parseInt(reservation?.dropOffTime.substring(0, 2)) > 12
  //         ? parseInt(reservation?.dropOffTime.substring(0, 2)) - 12
  //         : parseInt(reservation?.dropOffTime.substring(0, 2))) +
  //       ":" +
  //       reservation?.dropOffTime.substring(3, 5) +
  //       (parseInt(reservation?.dropOffTime.substring(0, 2)) > 11 ? "pm" : "am"),

  //     Status: reservation?.status,
  //     "Driver Amount": reservation?.fareAndDistance?.driverAmount
  //       ? parseFloat(reservation?.fareAndDistance?.driverAmount)
  //       : 0,
  //     // };
  //   }))
  // );
  const vehicle = useSelector((x) => x.vehicle);

  const extractVehicle = (no, cond) => {
    const vehicleFound = vehicle?.vehicle?.find((x) => x.id === no);
    if (!vehicleFound) return "-";
    if (cond === "assetNo") {
      return vehicleFound?.assetNo;
    }
    if (cond === "service") {
      return vehicleFound?.serviceType?.name;
    }
  };
  const data = invoices?.reportData?.map((item) => ({
    "Batch Number": item?.batchNumber,
    "Driver First Name": item?.driverId?.firstName,
    "Driver Last Name": item?.driverId?.lastName,
    " # of Trips": item?.reservations?.length,
    "Vehicle#": extractVehicle(item?.driverId?.vehicleId, "assetNo"),
    Service: extractVehicle(item?.driverId?.vehicleId, "service"),
    "Date Range":
      moment(item?.dateRange?.from).format("MMM D") +
      " - " +
      moment(item?.dateRange?.to).format("MMM D, YYYY"),
    "Weekly Earning": "$" + parseFloat(item?.netAmount).toFixed(2),
  }));

  const [exportData, setExportData] = useState([]);

  // const permission = useSelector((state) => state?.permission);
  useEffect(() => {
    if (
      auth?.user?.permissionId?.accounting?.isAllow === false ||
      auth?.user?.disable === true
    ) {
      navigateUserFunc(auth?.user, navigate);
    }
  }, []);
  useEffect(() => {
    for (let key of Object.keys(orderSearch)) {
      if (orderSearch[key] === "") {
        delete orderSearch[key];
      }
    }
    dispatch(getFilterSettlementsReports(orderSearch));
  }, []);

  return (
    <>
      <AdminHeader />
      <AdminSidebar />
      <div className="body-wrapper clearfix">
        <section className="main-container " id="fullscreen">
          <div className="px-4 py-2 border-top-0 emp-dashboard">
            <div className="d-block d-lg-flex d-md-flex justify-content-between action-bar mb-20 mt-3">
              <div
                id="table-actions"
                className="flex-grow-1 align-items-center"
              >
                <h4 className=" mb-0 f-21 text-capitalize font-weight-bold">
                  {" "}
                  Driver Settlement
                </h4>
              </div>
              <div
                className="btn-group mt-2 mt-lg-0 mt-md-0 ml-0 ml-lg-3 ml-md-3"
                role="group"
              >
                <div
                  className="btn-group me-2"
                  role="group"
                  aria-label="First group"
                >
                  {/* {reservation?.getReservationsByDateV2 ? (
                    <ExcelExport
                      excelData={data2}
                      fileName={`Booking Searched Report`}
                    />
                  ) : ( */}
                  <ExcelExport
                    excelData={data}
                    fileName={`Driver Settlement Report`}
                  />
                  {/* )} */}
                  <button
                    type="button"
                    className="btn btn-dark"
                    data-toggle="tooltip"
                    data-original-title="Filters"
                    onClick={() => setShowFilter(!showFilter)}
                  >
                    <i className="fa fa-filter"></i>{" "}
                  </button>
                  {/* <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => navigate("/add-driver-settlement")}
                  >
                    <i className="fas fa-plus-circle d-none d-sm-inline-block"></i>{" "}
                    Add Driver Settlement
                  </button> */}
                </div>
              </div>
            </div>

            {showFilter && (
              // <AdminSearchByFilterComponentV2 showFilter={showFilter} />
              <div
                className={`card-body p-0 bg-white b-shadow-4 rounded ${
                  showFilter ? "d-block" : "d-none"
                }`}
              >
                <div className="row p-2 px-4">
                  <div className="col-sm-3">
                    <div className="form-group">
                      <label
                        className="f-14 text-dark mb-12"
                        data-label=""
                        htmlFor="clnumber"
                      >
                        Driver Name
                      </label>
                      {/* <input
                          type="text"
                          className="form-control height-35 f-14"
                          placeholder="Driver Name"
                          id=""
                          autocomplete="off"
                          value={values.driverId}
                          onChange={handleChange("driverId")}
                        /> */}
                      <Select
                        className="basic-single"
                        classNamePrefix="select"
                        defaultValue={""}
                        value={availableDrivers
                          ?.map((x) => ({
                            label: `${x.firstName} ${x.lastName}`,
                            value: x.id,
                          }))
                          .find(
                            (customer) => customer.value === values?.driverId
                          )}
                        isDisabled={false}
                        isClearable={false}
                        isRtl={false}
                        isSearchable={true}
                        name="pickUp"
                        onChange={(e) => {
                          setValues({
                            ...values,
                            driverId: e.value,
                          });
                        }}
                        options={availableDrivers?.map((x) => ({
                          label: `${x.firstName} ${x.lastName} `,
                          value: x.id,
                        }))}
                      />
                    </div>
                  </div>
                  <div className="col-sm-2">
                    <div className="form-group">
                      <label
                        className="f-14 text-dark mb-12"
                        data-label=""
                        htmlFor="clnumber"
                      >
                        From
                      </label>
                      <input
                        type="date"
                        className="form-control height-35 f-14"
                        placeholder=""
                        id=""
                        autocomplete="off"
                        value={values.monthFirst}
                        onChange={handleChange("monthFirst")}
                      />
                    </div>
                  </div>
                  <div className="col-sm-2">
                    <div className="form-group">
                      <label
                        className="f-14 text-dark mb-12"
                        data-label=""
                        htmlFor="clnumber"
                      >
                        To
                      </label>
                      <input
                        type="date"
                        className="form-control height-35 f-14"
                        placeholder=""
                        id=""
                        autocomplete="off"
                        value={values.monthLast}
                        onChange={handleChange("monthLast")}
                      />
                    </div>
                  </div>
                  <div className="col-sm-2">
                    <div className="form-group">
                      <label
                        className="f-14 text-dark mb-12"
                        data-label=""
                        htmlFor="clnumber"
                      >
                        Batch#
                      </label>
                      <input
                        type="Batch#"
                        className="form-control height-35 f-14"
                        placeholder="Batch#"
                        id=""
                        autocomplete="off"
                        value={values.batchNumber}
                        onChange={handleChange("batchNumber")}
                      />
                    </div>
                  </div>
                  <div className="col-sm-3 mt-3">
                    <div className="d-grid mt-3">
                      <button className="btn btn-success" onClick={handleClick}>
                        Search
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {/* {myState.viewSettlement === "settlementFilter" ? (
              <>
                <AdminDriverSettlementSearchFilter setExportData={setExportData} />
              </>
            ) : (
              <AdminDriverSettlementReport 
              setExportData={setExportData} 
              />
            )} */}
            <AdminDriverSettlementReport setExportData={setExportData} />
          </div>
        </section>
      </div>
    </>
  );
};
export default AdminAccountingDriverSettlementReport;
