/** @format */

import axios from "axios";
import { notifyFailure, notifySuccess } from "../components/toast";
import {
  VEHICLE_VIEW_REQUEST,
  VEHICLE_VIEW_SUCCESS,
  VEHICLE_VIEW_FAIL,
  ALL_BADGES_VIEW_REQUEST,
  ALL_BADGES_VIEW_SUCCESS,
  ALL_BADGES_VIEW_FAIL,
  ALL_VEHICLE_VIEW_REQUEST,
  ALL_VEHICLE_VIEW_SUCCESS,
  ALL_VEHICLE_VIEW_FAIL,
  VEHICLE_CREATE_REQUEST,
  VEHICLE_CREATE_SUCCESS,
  VEHICLE_CREATE_FAIL,
  VEHICLE_UPDATE_REQUEST,
  VEHICLE_UPDATE_SUCCESS,
  VEHICLE_UPDATE_FAIL,
  VEHICLE_DELETE_REQUEST,
  VEHICLE_DELETE_SUCCESS,
  VEHICLE_DELETE_FAIL,
  SERVERADDRESS,
} from "../constants";

// ALL VEHICLE DISPLAY VIEW
export const getAllVehicle = (obj) => async (dispatch) => {
  dispatch({
    type: VEHICLE_VIEW_REQUEST,
  });
  try {
    const { data } = await axios.post(
      `${SERVERADDRESS}/v1/vehicle/getVehiclesByCompanyId`,
      obj
    );
    localStorage.setItem("vehicle", JSON.stringify(data));
    dispatch({
      type: VEHICLE_VIEW_SUCCESS,
      payload: data,
    });
  } catch (err) {
    // console.log(err)
    dispatch({
      type: VEHICLE_VIEW_FAIL,
      payload: err,
    });
  }
};
// ALL VEHICLE BADGE VIEW
export const allBadges = (obj) => async (dispatch) => {
  dispatch({
    type: ALL_BADGES_VIEW_REQUEST,
  });
  try {
    const { data } = await axios.get(
      `${SERVERADDRESS}/v1/badge/allBadges`,
      obj
    );
    localStorage.setItem("allBadges", JSON.stringify(data));
    dispatch({
      type: ALL_BADGES_VIEW_SUCCESS,
      payload: data,
    });
  } catch (err) {
    console.log(err);
    dispatch({
      type: ALL_BADGES_VIEW_FAIL,
      payload: err,
    });
  }
};

export const getAllVehiclesByCompanyId = (obj) => async (dispatch) => {
  dispatch({
    type: ALL_VEHICLE_VIEW_REQUEST,
  });
  try {
    const { data } = await axios.post(
      `${SERVERADDRESS}/v1/vehicle/getAllVehiclesByCompanyId`,
      obj
    );
    localStorage.setItem("vehicle", JSON.stringify(data));
    dispatch({
      type: ALL_VEHICLE_VIEW_SUCCESS,
      payload: data,
    });
  } catch (err) {
    // console.log(err)
    dispatch({
      type: ALL_VEHICLE_VIEW_FAIL,
      payload: err,
    });
  }
};

// ADD NEW VEHICLE
export const addVehicle = (obj, navigate) => async (dispatch) => {
  dispatch({
    type: VEHICLE_CREATE_REQUEST,
  });
  try {
    const { data } = await axios.post(
      `${SERVERADDRESS}/v1/vehicle/createVehicle`,
      obj
    );
    dispatch({
      type: VEHICLE_CREATE_SUCCESS,
      payload: data,
    });
    notifySuccess("Vehicle added successfully !");
    navigate("/vehicles");
  } catch (err) {
    // console.log(err)
    notifyFailure(err?.response?.data?.message);
    dispatch({
      type: VEHICLE_CREATE_FAIL,
      payload: err,
    });
  }
};

// Edit VEHICLE
export const editVehicleAction = (obj, handleSuccess) => async (dispatch) => {
  dispatch({
    type: VEHICLE_UPDATE_REQUEST,
  });
  try {
    const { data } = await axios.put(
      `${SERVERADDRESS}/v1/vehicle/updateVehicle`,
      obj
    );
    handleSuccess();
    notifySuccess("Updated successfully !");
    dispatch({
      type: VEHICLE_UPDATE_SUCCESS,
      payload: data,
    });
  } catch (err) {
    // console.log(err);
    notifyFailure(err?.response?.data?.message);
    dispatch({
      type: VEHICLE_UPDATE_FAIL,
      payload: err,
    });
  }
};

// Delete VEHICLE
export const deleteVehicleAction = (obj, handleSuccess) => async (dispatch) => {
  dispatch({
    type: VEHICLE_DELETE_REQUEST,
  });
  try {
    const { data } = await axios.put(
      `${SERVERADDRESS}/v1/vehicle/updateVehicle`,
      obj
    );
    // handleSuccess();
    // if (handleSuccess){
    //   handleSuccess();
    //   notifySuccess("Deleted successfully !");
    // }
    notifySuccess("Deleted successfully !");
    dispatch({
      type: VEHICLE_DELETE_SUCCESS,
      payload: data,
    });
  } catch (err) {
    // console.log(err);
    notifyFailure(err?.response?.data?.message);
    dispatch({
      type: VEHICLE_DELETE_FAIL,
      payload: err,
    });
  }
};
