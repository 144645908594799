/** @format */

import axios from "axios";
import {
  TRIP_VIEW_REQUEST,
  TRIP_VIEW_SUCCESS,
  TRIP_VIEW_FAIL,
  TRIP_CREATE_REQUEST,
  TRIP_CREATE_SUCCESS,
  TRIP_CREATE_FAIL,
  TRIP_MAP_DATA_REQUEST,
  TRIP_MAP_DATA_SUCCESS,
  TRIP_MAP_DATA_FAIL,
  TRIP_WAY_POINTS_REQUEST,
  TRIP_WAY_POINTS_SUCCESS,
  TRIP_WAY_POINTS_FAIL,
  SERVERADDRESS,
} from "../constants";

// ALL TRIP DISPLAY VIEW
export const getAllTrip = (obj) => async (dispatch) => {
  dispatch({
    type: TRIP_VIEW_REQUEST,
  });
  try {
    const { data } = await axios.post(
      `${SERVERADDRESS}/v1/trip/getTripsByCompanyId`,
      obj
    );
    localStorage.setItem("readTrip", JSON.stringify(data));
    dispatch({
      type: TRIP_VIEW_SUCCESS,
      payload: data,
    });
  } catch (err) {
    // console.log(err)
    dispatch({
      type: TRIP_VIEW_FAIL,
      payload: err,
    });
  }
};

// ADD NEW TRIP
export const addTrip = (obj, cb) => async (dispatch) => {
  dispatch({
    type: TRIP_CREATE_REQUEST,
  });
  try {
    const { data } = await axios.post(
      `${SERVERADDRESS}/v1/trip/createTrip`,
      obj
    );
    localStorage.setItem("tripCreate", JSON.stringify(data));
    dispatch({
      type: TRIP_CREATE_SUCCESS,
      payload: data,
    });
    if (cb) {
      cb();
    }
  } catch (err) {
    // console.log(err)
    dispatch({
      type: TRIP_CREATE_FAIL,
      payload: err,
    });
  }
};

// TRIP AND ROUTE TRIP MAP DATA
export const getAllTripAndRouteTripOfDrivers =
  (obj, cb) => async (dispatch) => {
    dispatch({
      type: TRIP_MAP_DATA_REQUEST,
    });
    try {
      const { data } = await axios.post(
        `${SERVERADDRESS}/v1/trip/getAllTripAndRouteTripOfDrivers`,
        obj
      );
      dispatch({
        type: TRIP_MAP_DATA_SUCCESS,
        payload: data,
      });
      if (cb) {
        cb();
      }
    } catch (err) {
      // console.log(err)
      dispatch({
        type: TRIP_MAP_DATA_FAIL,
        payload: err,
      });
    }
  };

// ALL TRIP DISPLAY VIEW
export const getTripWayPoints = (obj) => async (dispatch) => {
  dispatch({
    type: TRIP_WAY_POINTS_REQUEST,
  });
  try {
    const { data } = await axios.post(
      `${SERVERADDRESS}/v1/trip/getTripWayPoints`,
      obj
    );
    // localStorage.setItem("tripWayPoints", JSON.stringify(data));
    dispatch({
      type: TRIP_WAY_POINTS_SUCCESS,
      payload: data,
    });
  } catch (err) {
    // console.log(err)
    dispatch({
      type: TRIP_WAY_POINTS_FAIL,
      payload: err,
    });
  }
};

// // UPDATE TRIP
// export const updateTrip = (obj) => async ( dispatch ) => {
//     dispatch({
//         type: TRIP_UPDATE_REQUEST,
//     })
//     try {
//         const { data } = await axios.put(`${SERVERADDRESS}/v1/trip/updateTrip`, obj)
//         dispatch({
//             type: TRIP_UPDATE_SUCCESS,
//             payload: data,
//     })
// }
// catch (err) {
//     console.log(err)
//     dispatch({
//         type: TRIP_UPDATE_FAIL,
//         payload: err,
//     })
// }
// }
