import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { verifyPin } from "../../../actions/authActions";

const AdminPinModal = ({
  openPinDropDown,
  showPinDropDown,
  setPermission,
  permissions,
  currentPermission,
  tempPermission,
}) => {
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [values, setValues] = useState({ pin: "" });
  const [error, setError] = useState("");

  const handleChange = (fieldName) => (event) => {
    setValues({ ...values, [fieldName]: event.target.value });
  };
  // const successCB = () => {
  //   openPinDropDown();
  // };
  const successCB = () => {
    // Find the first permission without accounting?.isAllow
    // const newPermission = permissions.find(
    //   (p) => !p.permission.accounting?.isAllow
    // );
    // if (newPermission) {
    //   setPermission(newPermission.permission); // Update the permission
    // }
    setPermission(tempPermission);
    openPinDropDown(); // Close modal on success
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    const pinData = {
      companyId: auth.user.companyId.id,
      userId: auth.user.id,
      pin: values.pin,
    };

    try {
      await dispatch(verifyPin(pinData, successCB));
      // openPinDropDown(); // Close modal on success
    } catch (err) {
      setError("Incorrect PIN. Please try again."); // Show error on failure
    }
  };

  return (
    <>
      <div
        className={`modal fade ${
          showPinDropDown === true ? "show modal-y-axis" : "hide"
        }`}
        role="dialog"
        data-backdrop="static"
        data-keyboard="false" // To prevent closing with the keyboard
        onClick={(e) => {
          // Check if the click occurred outside the modal content
          if (e.target === e.currentTarget) {
            openPinDropDown(); // Call your close modal function
          }
        }}
      >
        <div
          className="modal-dialog modal-dialog-centered"
          role="document"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <div className="modal-content-pin">
            <form onSubmit={handleSubmit}>
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLongTitle">
                  {" "}
                </h5>
                <div className="col-md-8">
                  <div className="form-group my-3">
                    <label className="f-14 text-dark-grey mb-12">PIN</label>
                    <sup className="text-danger f-12">*</sup>
                    <input
                      type="number"
                      className="form-control height-35 f-14"
                      placeholder="****"
                      maxLength="4"
                      required
                      autoComplete="off"
                      value={values.pin}
                      onChange={(event) => {
                        const { value } = event.target;
                        if (value.length <= 4) {
                          handleChange("pin")(event);
                        }
                      }}
                    />
                  </div>
                </div>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => openPinDropDown()}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>

              <div className="modal-footer">
                <button
                  type="button"
                  className="btn-secondary rounded f-14 p-2 mr-3"
                  data-dismiss="modal"
                  onClick={() => openPinDropDown()}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="btn-primary rounded f-14 p-2 mr-3"
                >
                  Enter
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};
export default AdminPinModal;
