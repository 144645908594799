import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { updateService } from "../../../actions/serviceActions";

const AdminSettingVehicleAddNewServiceNameModal = ({
  showDropDown,
  openDropDown,
  values,
  setValues,
  serviceNames,
  setServiceNames,
}) => {
  const dispatch = useDispatch();
  const [val, setVal] = useState({
    newService: "",
  });
  const handleChange = (fieldName) => (event) => {
    setVal({
      ...val,
      [fieldName]: event.target.value,
    });
  };
  const handleSubmit = async (event) => {
    // event.preventDefault()
    if (val.newService !== "") {
      setServiceNames([...serviceNames, { fullName: val.newService }]);
      setValues({ ...values, ["fullName"]: val.newService });
      if (values?.serviceType) {
        dispatch(
          updateService({ _id: values.serviceType, fullName: val.newService })
        );
      } else {
        // console.log(typeof(values.type), typeof(values.type) == 'object', "values.type")
        if (typeof values.type == "object") {
          dispatch(
            updateService({ _id: values.type.id, fullName: val.newService })
          );
        } else {
          const sType = JSON.parse(values.type);
          dispatch(updateService({ _id: sType.id, fullName: val.newService }));
        }
      }
    }
    setVal({ newService: "" });
    openDropDown();
  };
  return (
    <>
      <div
        className={`modal fade ${showDropDown ? "show modal-y-axis" : "hide"}`}
        role="dialog"
        data-backdrop="static"
        data-keyboard="false" // To prevent closing with the keyboard
        onClick={(e) => {
          // Check if the click occurred outside the modal content
          if (e.target === e.currentTarget) {
            openDropDown(); // Call your close modal function
          }
        }}
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLongTitle">
                {" "}
                New Service Name
              </h5>
              <button
                type="button"
                id="openDropDown3"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => openDropDown()}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group my-3">
                    <label
                      className="f-14 text-dark-grey mb-12"
                      data-label=""
                      htmlFor="after_days"
                    >
                      Service Name
                    </label>
                    {/* <sup className="text-danger f-12">*</sup> */}
                    <input
                      type="text"
                      id="serviceNameAdd2"
                      className="form-control height-35 f-14"
                      placeholder="Enter Service Name"
                      autoComplete="false"
                      value={val.newService}
                      onChange={handleChange("newService")}
                      required
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn-secondary rounded f-14 p-2 mr-3"
                data-dismiss="modal"
                id="openDropDown"
                onClick={() => openDropDown()}
              >
                Close
              </button>
              <button
                type="button"
                id="openDropDown2"
                className="btn-primary rounded f-14 p-2 mr-3"
                onClick={handleSubmit}
              >
                Add
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminSettingVehicleAddNewServiceNameModal;
