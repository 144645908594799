import React from 'react'
import DriverEarningComponent from '../../components/driverComponents/driverEarning/DriverEarningComponent'
import AdminHeader from '../../components/adminComponents/adminHeaderSidebarTitleComponents/AdminHeader'
import AdminSidebar from '../../components/adminComponents/adminHeaderSidebarTitleComponents/AdminSidebar'

const DriverEarningPage = () => {
    return (
        <>
            <AdminHeader />
            <AdminSidebar />
            <div className="body-wrapper clearfix">
                <section className="main-container " id="fullscreen">
                    <div className="px-4 py-2 border-top-0 emp-dashboard">
                        <div className="d-lg-flex d-md-flex d-block py-4">
                            <div>
                                <h4 className=" mb-0 f-21 text-capitalize font-weight-bold">My Earnings</h4>
                            </div>
                        </div>
                        <DriverEarningComponent />
                        {/* {
                            auth?.user?.permissionId?.myOrder?.read === true ?
                            < />
                            :
                            <NotAllowed />
                        } */}
                    </div>
                </section>
            </div>
        </>
    )
}
export default DriverEarningPage