/** @format */

import React, { useState } from "react";

const AdminRouteLogTableRow = ({
  current,
  i,
  dropDownRow,
  setDropDownRow,
  routeType,
  showRoute,
}) => {
  const [showMoreVertDropDown, setshowMoreVertDropDown] = useState(false);
  const openMoreVertDropDown = () => {
    setshowMoreVertDropDown((showMoreVertDropDown) => !showMoreVertDropDown);
  };

  return (
    <>
      <tr
        id="row-20"
        role="row"
        className="odd border"
        key={i}
        onMouseLeave={() => setshowMoreVertDropDown(false)}
      >
        <td className="border-right">
          {current?.customerId?.firstName + " " + current?.customerId?.lastName}
        </td>
        {showRoute && (
          <td className="border-right">
            {routeType?.trip?.routeId?.routeNumber}{" "}
            {routeType?.trip?.routeId?.routeNumberReturn && "( Return )"}
          </td>
        )}

        <td className="border-right">
          {!current.customerCancelReason
            ? current.isComing === false ? "Not Coming"
            : current.isDone === true
              ? "Completed"
              : "In progress"
            : "No show"}
        </td>
        <td className="border-right">
          {current?.pickUpTime
            ? parseInt(current?.pickUpTime?.substring(11, 13)) === 0
              ? 12
              : parseInt(current?.pickUpTime?.substring(11, 13)) > 12
              ? parseInt(current?.pickUpTime?.substring(11, 13)) - 12
              : parseInt(current?.pickUpTime?.substring(11, 13))
            : " "}
          {current?.pickUpTime
            ? ":" + current?.pickUpTime?.substring(14, 16)
            : " "}
          {current?.pickUpTime
            ? parseInt(current?.pickUpTime?.substring(11, 13)) > 11
              ? "pm"
              : "am"
            : " "}
        </td>
        <td className="border-right">
          {current?.dropOffTime
            ? parseInt(current?.dropOffTime?.substring(11, 13)) === 0
              ? 12
              : parseInt(current?.dropOffTime?.substring(11, 13)) > 12
              ? parseInt(current?.dropOffTime?.substring(11, 13)) - 12
              : parseInt(current?.dropOffTime?.substring(11, 13))
            : " "}
          {current?.dropOffTime
            ? ":" + current?.dropOffTime?.substring(14, 16)
            : " "}
          {current?.dropOffTime
            ? parseInt(current?.dropOffTime?.substring(11, 13)) > 11
              ? "pm"
              : "am"
            : " "}
        </td>
        <td className="border-right">
          {/* Lat: {current?.pickUpLat?.toFixed(6)}, Long: {current?.pickUpLng?.toFixed(6)} */}
          <a
            href={`https://www.google.com/maps/search/?api=1&query=${current?.pickUpLat?.toFixed(
              6
            )},${current?.pickUpLng?.toFixed(6)}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {current?.pickUpLat
              ? "Lat: " + current?.pickUpLat?.toFixed(6) + ","
              : " "}{" "}
            {current?.pickUpLng
              ? "Long: " + current?.pickUpLng?.toFixed(6)
              : " "}
          </a>
        </td>
        {/* <td className="border-right">{current?.pickUpLng?.toFixed(6)}</td> */}
        <td className="border-right">
          {/* Lat: {current?.dropOffLat?.toFixed(6)}, Long: {current?.dropOffLng?.toFixed(6)} */}
          <a
            href={`https://www.google.com/maps/search/?api=1&query=${current?.dropOffLat?.toFixed(
              6
            )},${current?.dropOffLng?.toFixed(6)}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {current?.dropOffLat
              ? "Lat: " + current?.dropOffLat?.toFixed(6) + ","
              : " "}{" "}
            {current?.dropOffLng
              ? "Long: " + current?.dropOffLng?.toFixed(6)
              : " "}
          </a>
        </td>
        {/* <td className="border-right">{current?.dropOffLng?.toFixed(6)}</td> */}
        {/* <td className=" text-right pr-20">
                    <div className="task_view">
                        <div className="dropdown">
                            <Link
                                className="task_view_more d-flex align-items-center justify-content-center dropdown-toggle"
                                type="link"
                                id="dropdownMenuLink-20"
                                data-toggle="dropdown"
                                onClick={openMoreVertDropDown}
                                aria-haspopup="true"
                                aria-expanded="false"
                                to=""
                            >
                                <i className="icon-options-vertical icons"></i>
                            </Link>
                            <div
                                className={`dropdown-menu dropdown-menu-right ${showMoreVertDropDown
                                    ? "dropdown-menu dropdown-menu-right show"
                                    : "dropdown-menu dropdown-menu-right"}`}
                                aria-labelledby="dropdownMenuLink-20"
                                tabIndex="0"
                            >
                                <Link
                                    className="dropdown-item"
                                    to=""
                                    onClick={() => setModal({ open: true, data: current })}
                                >
                                    <i className="fa fa-edit"> Edit</i>
                                </Link>
                            </div>
                        </div>
                    </div>
                </td> */}
      </tr>
    </>
  );
};
export default AdminRouteLogTableRow;
