/** @format */

import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import { allCompanyUsers } from "../../../actions/userActions";
import AdminUserTableComponentRow from "./AdminUserTableComponentRow";
import { Link, NavLink, useLocation } from "react-router-dom";
import shift1 from "../../../assets/images/load.png";
import { paginationDataToShow } from "../../../actions/generalActions";
// import AdminUserEditComponent from "./AdminUserEditComponent";
// import RightModal from "../../RightModal";

const AdminUserTableComponent = () => {
  const auth = useSelector((state) => state.auth);
  const users = useSelector((state) => state.users);
  const [showMoreVertDropDown, setshowMoreVertDropDown] = useState(false);
  const openMoreVertDropDown = () => {
    setshowMoreVertDropDown((showMoreVertDropDown) => !showMoreVertDropDown);
  };

  const [modal, setModal] = useState({ open: false, data: null });
  const handleModal = (data) => {
    setModal({ open: !modal.open, data: data });
  };
  const locationUrl = useLocation();
  const queryParams = new URLSearchParams(locationUrl.search);
  const isOnlineParam = queryParams.get("online-drivers");
  const isOfflineParam = queryParams.get("offline-drivers");
  const isAllParam = queryParams.get("all-drivers");
  useEffect(() => {
    if (isOnlineParam == "true") {
      setSearchTerm("online drivers");
    }
    if (isOfflineParam == "true") {
      setSearchTerm("offline drivers");
    }
    if (isAllParam == "true") {
      setSearchTerm("all drivers");
    }
  }, []);
  const dispatch = useDispatch();
  const userData = async (e) => {
    dispatch(
      allCompanyUsers({
        companyId: auth?.user?.companyId?.id,
        // isClient: false
      })
    );
  };
  useEffect(() => {
    userData();
  }, []);

  const [currentPage, setCurrentPage] = useState(1);
  const [sortField, setSortField] = useState("firstName");
  const [sortOrder, setSortOrder] = useState("asc"); //asc, desc
  const [searchTerm, setSearchTerm] = useState("");
  const [limit, setLimit] = useState(10); // Set your default limit here

  // Function to handle pagination, sorting, and searching
  const handlePagination = (page, field, order, search, newLimit) => {
    let newPage;
    if (page < 1) {
      newPage = 1;
    } else if (page > paginatedData.totalPages) {
      newPage = paginatedData.totalPages;
    } else {
      newPage = page;
    }
    setCurrentPage(newPage);
    setSortField(field);
    setSortOrder(order);
    setSearchTerm(search);
    setLimit(newLimit);
  };

  const fullData = users?.users;
  // Use the pagination function to get the data for the current page
  const paginatedData = paginationDataToShow(
    limit,
    currentPage,
    fullData,
    sortField,
    sortOrder,
    searchTerm
  );

  // Function to handle sorting icon click
  const handleSortClick = (field, order) => {
    // Call handlePagination with sorting parameters
    handlePagination(currentPage, field, order, searchTerm, limit);
  };
  // Function to determine the color based on the current sorting order
  const getArrowColor = (field, direction) => {
    return sortField === field && sortOrder === direction
      ? "#000000"
      : "#A0A0A0";
  };
  // console.log(paginatedData, "paginatedData1");

  return (
    <>
      <div className="d-flex flex-column w-tables rounded mt-3 bg-white">
        <div className="d-flex ">
          <div className="flex-grow-1">
            <div className="dataTables_length d-flex" id="leads-table_length">
              <div className="p-2">
                <label>Show entries</label>
              </div>
              <label>
                <select
                  name="leads-table_length"
                  aria-controls="leads-table"
                  className="custom-select custom-select-sm form-control form-control-sm"
                  autoComplete="false"
                  value={limit} // Make sure to set the value attribute to the current limit
                  onChange={(e) =>
                    handlePagination(
                      1,
                      sortField,
                      sortOrder,
                      searchTerm,
                      parseInt(e.target.value)
                    )
                  }
                >
                  {[10, 25, 50, 100].map((current, i) => {
                    return (
                      <option value={current} key={i}>
                        {current}
                      </option>
                    );
                  })}
                </select>
              </label>
              <div
                id="table-actions"
                className="flex-grow-1 align-items-center"
              >
                <h4 className=" mb-0 f-21 text-capitalize font-weight-bold"></h4>
              </div>
              {/* <div className="btn-group mt-2 mt-lg-0 mt-md-0 ml-0 ml-lg-3 ml-md-3" role="group">
                <NavLink to="/users" type="button" className="btn-secondary rounded f-14 p-2 mr-3 float-left mb-2 activee" id="add-lead">
                  All Users
                </NavLink>
                <NavLink to="/active-users" type="button" className="btn-secondary rounded f-14 p-2 mr-3 float-left mb-2" id="add-lead">
                  Activer Users
                </NavLink>
                <NavLink to="/inactive-users" type="button" className="btn-secondary rounded f-14 p-2 mr-3 float-left mb-2" id="add-lead">
                    Inactive Users
                </NavLink>
              </div> */}
            </div>
          </div>
          <div className="task-search task-search-new task-search-new search-col-usertable d-flex py-1 px-lg-3 px-0 align-items-center">
            <form className="w-100 mr-1 mr-lg-0 mr-md-1 ml-md-1 ml-0 ml-lg-0" onSubmit={(e) => e.preventDefault()}>
              <div className="input-group bg-grey rounded">
                <div className="input-group-prepend">
                  <span className="input-group-text border-0 bg-additional-grey">
                    <i className="fa fa-search f-13 text-dark-grey"></i>
                  </span>
                </div>
                <input
                  type="text"
                  className="form-control f-14 p-1 border-additional-grey"
                  id="search-text-field"
                  placeholder="Start typing to search"
                  value={searchTerm}
                  onChange={(e) =>
                    handlePagination(
                      1,
                      sortField,
                      sortOrder,
                      e.target.value,
                      limit
                    )
                  }
                />
              </div>
            </form>
          </div>
        </div>
        <div
          id="leads-table_wrapper"
          className="dataTables_wrapper dt-bootstrap4 no-footer"
        >
          <div className="row">
            <div className="col-sm-12">
              <table
                className="table border w-100 dataTable no-footer"
                id="leads-table"
                role="grid"
                aria-describedby="leads-table_info"
                // style={{ width: "1065px" }}
                // style={{ overflowX: "auto", width: "1065px" }}
              >
                <thead className="card-header">
                  <tr role="row">
                    <th
                      title="Name"
                      className="sorting border-right align-table-header"
                      tabIndex="0"
                      aria-controls="leads-table"
                      rowSpan="1"
                      colSpan="1"
                      aria-label="Mobile: activate to sort column ascending"
                    >
                      Name <br />
                      <span className="right-sorting-new">
                        <i
                          className="fas fa-long-arrow-alt-up"
                          style={{
                            color: getArrowColor("firstName", "asc"),
                          }}
                          onClick={() => handleSortClick("firstName", "asc")}
                        ></i>
                        <i
                          className="fas fa-long-arrow-alt-down"
                          style={{
                            color: getArrowColor("firstName", "desc"),
                          }}
                          onClick={() => handleSortClick("firstName", "desc")}
                        ></i>
                      </span>
                    </th>
                    <th
                      title="Permission"
                      className="sorting border-right align-table-header"
                      tabIndex="0"
                      aria-controls="leads-table"
                      rowSpan="1"
                      colSpan="1"
                      aria-label="Lead Agent: activate to sort column ascending"
                    >
                      Permission <br />
                      <span className="right-sorting-new">
                        <i
                          className="fas fa-long-arrow-alt-up"
                          style={{
                            color: getArrowColor("permissionId.name", "asc"),
                          }}
                          onClick={() =>
                            handleSortClick("permissionId.name", "asc")
                          }
                        ></i>
                        <i
                          className="fas fa-long-arrow-alt-down"
                          style={{
                            color: getArrowColor("permissionId.name", "desc"),
                          }}
                          onClick={() =>
                            handleSortClick("permissionId.name", "desc")
                          }
                        ></i>
                      </span>
                    </th>
                    <th
                      title="Vehicle#"
                      className="sorting border-right align-table-header"
                      tabIndex="0"
                      aria-controls="leads-table"
                      rowSpan="1"
                      colSpan="1"
                      aria-label="Lead Agent: activate to sort column ascending"
                    >
                      Vehicle# <br />
                      <span className="right-sorting-new">
                        <i
                          className="fas fa-long-arrow-alt-up"
                          style={{
                            color: getArrowColor("vehicleId.assetNo", "asc"),
                          }}
                          onClick={() =>
                            handleSortClick("vehicleId.assetNo", "asc")
                          }
                        ></i>
                        <i
                          className="fas fa-long-arrow-alt-down"
                          style={{
                            color: getArrowColor("vehicleId.assetNo", "desc"),
                          }}
                          onClick={() =>
                            handleSortClick("vehicleId.assetNo", "desc")
                          }
                        ></i>
                      </span>
                    </th>
                    {/* <th
                      title="Address"
                      className="sorting border-right"
                      tabIndex="0"
                      aria-controls="leads-table"
                      rowSpan="1"
                      colSpan="1"
                      aria-label="Lead Agent: activate to sort column ascending"
                    >
                      Address
                    </th> */}
                    <th
                      title="City"
                      className="sorting border-right align-table-header"
                      tabIndex="0"
                      aria-controls="leads-table"
                      rowSpan="1"
                      colSpan="1"
                      aria-label="Lead Agent: activate to sort column ascending"
                    >
                      City <br />
                      <span className="right-sorting-new">
                        <i
                          className="fas fa-long-arrow-alt-up"
                          style={{ color: getArrowColor("city", "asc") }}
                          onClick={() => handleSortClick("city", "asc")}
                        ></i>
                        <i
                          className="fas fa-long-arrow-alt-down"
                          style={{ color: getArrowColor("city", "desc") }}
                          onClick={() => handleSortClick("city", "desc")}
                        ></i>
                      </span>
                    </th>
                    <th
                      title="State"
                      className="sorting border-right align-table-header"
                      tabIndex="0"
                      aria-controls="leads-table"
                      rowSpan="1"
                      colSpan="1"
                      aria-label="Lead Agent: activate to sort column ascending"
                    >
                      State <br />
                      <span className="right-sorting-new">
                        <i
                          className="fas fa-long-arrow-alt-up"
                          style={{ color: getArrowColor("state", "asc") }}
                          onClick={() => handleSortClick("state", "asc")}
                        ></i>
                        <i
                          className="fas fa-long-arrow-alt-down"
                          style={{ color: getArrowColor("state", "desc") }}
                          onClick={() => handleSortClick("state", "desc")}
                        ></i>
                      </span>
                    </th>
                    <th
                      title="Phone#"
                      className="sorting border-right align-table-header"
                      tabIndex="0"
                      aria-controls="leads-table"
                      rowSpan="1"
                      colSpan="1"
                      aria-label="Lead Agent: activate to sort column ascending"
                    >
                      Phone# <br />
                      <span className="right-sorting-new">
                        <i
                          className="fas fa-long-arrow-alt-up"
                          style={{ color: getArrowColor("phone", "asc") }}
                          onClick={() => handleSortClick("phone", "asc")}
                        ></i>
                        <i
                          className="fas fa-long-arrow-alt-down"
                          style={{ color: getArrowColor("phone", "desc") }}
                          onClick={() => handleSortClick("phone", "desc")}
                        ></i>
                      </span>
                    </th>
                    <th
                      title="Email"
                      className="sorting border-right align-table-header"
                      tabIndex="0"
                      aria-controls="leads-table"
                      rowSpan="1"
                      colSpan="1"
                      aria-label="Lead Agent: activate to sort column ascending"
                    >
                      Email <br />
                      <span className="right-sorting-new">
                        <i
                          className="fas fa-long-arrow-alt-up"
                          style={{ color: getArrowColor("email", "asc") }}
                          onClick={() => handleSortClick("email", "asc")}
                        ></i>
                        <i
                          className="fas fa-long-arrow-alt-down"
                          style={{ color: getArrowColor("email", "desc") }}
                          onClick={() => handleSortClick("email", "desc")}
                        ></i>
                      </span>
                    </th>
                    <th
                      title="Username"
                      className="sorting border-right align-table-header"
                      tabIndex="0"
                      aria-controls="leads-table"
                      rowSpan="1"
                      colSpan="1"
                      aria-label="Lead Agent: activate to sort column ascending"
                    >
                      Username <br />
                      <span className="right-sorting-new">
                        <i
                          className="fas fa-long-arrow-alt-up"
                          style={{ color: getArrowColor("username", "asc") }}
                          onClick={() => handleSortClick("username", "asc")}
                        ></i>
                        <i
                          className="fas fa-long-arrow-alt-down"
                          style={{ color: getArrowColor("username", "desc") }}
                          onClick={() => handleSortClick("username", "desc")}
                        ></i>
                      </span>
                    </th>
                    <th
                      title="User Status"
                      className="sorting border-right align-table-header"
                      tabIndex="0"
                      aria-controls="leads-table"
                      rowSpan="1"
                      colSpan="1"
                      aria-label="Lead Agent: activate to sort column ascending"
                    >
                      User Status <br />
                      <span className="right-sorting-new">
                        <i
                          className="fas fa-long-arrow-alt-up"
                          style={{ color: getArrowColor("disable", "asc") }}
                          onClick={() => handleSortClick("disable", "asc")}
                        ></i>
                        <i
                          className="fas fa-long-arrow-alt-down"
                          style={{ color: getArrowColor("disable", "desc") }}
                          onClick={() => handleSortClick("disable", "desc")}
                        ></i>
                      </span>
                    </th>
                    <th
                      title="Actions"
                      className="text-right pr-20 sorting_disabled border-right align-table-header"
                      rowSpan="1"
                      colSpan="1"
                      aria-label="Action"
                    >
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {users &&
                    users?.users &&
                    users?.users?.length > 0 &&
                    paginatedData?.results.map((current, i) => {
                      // if (current.delete === false) {
                      return (
                        <AdminUserTableComponentRow
                          current={current}
                          i={i}
                          key={i}
                          handleModal={handleModal}
                        />
                      );
                      // }
                    })}
                </tbody>
              </table>
              {users &&
                users?.users &&
                paginatedData?.results?.length === 0 && (
                  <div
                    className="dash-stats-list"
                    style={{ textAlign: "center" }}
                  >
                    <br />
                    <br />
                    <img
                      alt=""
                      src={shift1}
                      style={{ width: "145px", opacity: "0.5" }}
                    />
                    <br />
                    <p style={{ opacity: "0.5" }}>No Data Found</p>
                  </div>
                )}
              <div
                id="leads-table_processing"
                className="dataTables_processing card"
                style={{ display: "none" }}
              >
                Processing...
              </div>
            </div>
          </div>
          {users &&
            users?.users &&
            users?.users?.length > 0 &&
            paginatedData?.results?.length > 0 && (
              <div className="d-flex ">
                {
                  // users.users && (
                  users.loading === false ? (
                    <div className="flex-grow-1">
                      <div
                        className="dataTables_info"
                        id="leads-table_info"
                        role="status"
                        aria-live="polite"
                      >
                        Showing{" "}
                        {1 +
                          paginatedData.limit *
                            (paginatedData?.currentPageNumber - 1)}{" "}
                        to{" "}
                        {paginatedData.displayingResults +
                          paginatedData.limit *
                            (paginatedData?.currentPageNumber - 1)}{" "}
                        of {paginatedData.totalResults} entries
                      </div>
                    </div>
                  ) : (
                    <div className="flex-grow-1">
                      <div
                        className="dataTables_info"
                        id="leads-table_info"
                        role="status"
                        aria-live="polite"
                      >
                        Loading entries
                      </div>
                    </div>
                  )
                }
                <div className="search-col-usertable">
                  <div
                    className="dataTables_paginate paging_simple_numbers"
                    id="leads-table_paginate"
                  >
                    <ul className="pagination">
                      <li className="page-item">
                        <NavLink
                          className="page-link"
                          to="#"
                          aria-label="Previous"
                          // onClick={onClickPrev}
                          onClick={(e) =>
                            handlePagination(
                              currentPage - 1,
                              sortField,
                              sortOrder,
                              searchTerm,
                              limit
                            )
                          }
                        >
                          <span aria-hidden="true">Previous</span>
                          <span className="sr-only">Previous</span>
                        </NavLink>
                      </li>
                      {users &&
                        users?.users &&
                        paginatedData?.paginationArr &&
                        paginatedData?.paginationArr?.length > 0 &&
                        Array.from(
                          {
                            length:
                              paginatedData.endPage -
                              paginatedData.startPage +
                              1,
                          },
                          (_, i) => {
                            const current = paginatedData.startPage + i;
                            return paginatedData?.currentPageNumber ===
                              current ? (
                              <li className="page-item active" key={i}>
                                <Link
                                  to=""
                                  className="page-link"
                                  value={current}
                                >
                                  {current}
                                </Link>
                              </li>
                            ) : (
                              <li className="page-item" key={i}>
                                <Link
                                  className="page-link"
                                  value={current}
                                  onClick={(e) =>
                                    handlePagination(
                                      parseInt(current),
                                      sortField,
                                      sortOrder,
                                      searchTerm,
                                      limit
                                    )
                                  }
                                  to=""
                                >
                                  {current}
                                </Link>
                              </li>
                            );
                          }
                        )}
                      <li className="page-item">
                        <NavLink
                          className="page-link"
                          to="#"
                          aria-label="Next"
                          // onClick={onClickNext}
                          onClick={(e) =>
                            handlePagination(
                              currentPage + 1,
                              sortField,
                              sortOrder,
                              searchTerm,
                              limit
                            )
                          }
                        >
                          <span aria-hidden="true">Next</span>
                          <span className="sr-only">Next</span>
                        </NavLink>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            )}
          {/* <div>
              <div
                className="dataTables_paginate paging_simple_numbers"
                id="leads-table_paginate"
              >
                <ul className="pagination">
                  <li className="page-item">
                    <NavLink
                      className="page-link"
                      to=""
                      aria-label="Previous"
                      onClick={onClickPrev}
                    >
                      <span aria-hidden="true">Previous</span>
                      <span className="sr-only">Previous</span>
                    </NavLink>
                  </li>
                  {users.users &&
                    users.users.paginationArr &&
                    users.users.paginationArr.length > 0 &&
                    // users.users.paginationArr.map((current, i) => {
                    Array.from({ length: endPage - startPage + 1 }, (_, i) => {
                      const current = startPage + i;   
                      return currentPageNumber === current ? (
                        <li className="page-item active" key={i}>
                          <Link className="page-link" value={current} to="">
                            {current}
                          </Link>
                        </li>
                      ) : (
                        <li className="page-item" key={i}>
                          <Link
                            className="page-link"
                            to=""
                            value={current}
                            onClick={() => handleChangeData(current)}
                          >
                            {current}
                          </Link>
                        </li>
                      );
                    })}
                  <li className="page-item">
                    <NavLink
                      className="page-link"
                      to=""
                      aria-label="Next"
                      onClick={onClickNext}
                    >
                      <span aria-hidden="true">Next</span>
                      <span className="sr-only">Next</span>
                    </NavLink>
                  </li>
                </ul>
              </div>
            </div> */}
        </div>
      </div>
    </>
  );
};
export default AdminUserTableComponent;
