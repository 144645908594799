/** @format */

import React, { forwardRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { reservationReportSearch } from "../../../actions/kpiActions";
import { tabReportSearch } from "../../../actions/tabActions";
// import ExcelExport from '../../../ExcelExport';
import Select from "react-select";

const AdminReportSearchandFilter = () => {
  const auth = useSelector((state) => state.auth);
  const kpi = useSelector((state) => state.kpi);
  const dispatch = useDispatch();
  const [values, setValues] = useState({
    companyId: "",
    name: "",
    loadNumber: "",
    // date: '',
    from: "",
    to: "",
  });
  const [allDrivers, setallDrivers] = useState(false);
  const [states, setStates] = useState([]);
  const handleChange = (fieldName) => (event) => {
    setValues({
      ...values,
      [fieldName]: event.target.value,
    });
  };
  const availableDrivers = useSelector(
    (state) => state.users.allDriversWithoutPagination
  );
  const allStates = [
    { key: "completed", value: "Completed" },
    { key: "canceled", value: "Canceled" },
    { key: "noShow", value: "No Show" },
  ];
  const handleStates = (fieldName) => (event) => {
    if (event.target.checked) {
      if (!states.find((x) => x.key == fieldName)) {
        setStates([
          ...states,
          {
            key: fieldName,
            value: allStates.find((x) => x.key == fieldName).value,
          },
        ]);
      }
    } else {
      if (states.find((x) => x.key == fieldName)) {
        setStates(states.filter((x) => x.key !== fieldName));
      }
    }
  };
  // console.log("states=>", states);
  const [fromDate, setFromDate] = useState(
    new Date(moment().format("YYYY-MM-DD"))
  );
  const [toDate, setToDate] = useState(new Date(moment().format("YYYY-MM-DD")));
  const From = forwardRef(({ value, onClick }, ref) => (
    <div className="form-group form-focus focused" onClick={onClick} ref={ref}>
      <div className="cal-icon">
        <input
          className="form-control floating datetimepicker"
          type="text"
          value={value}
        />
      </div>
      <label className="focus-label">From</label>
    </div>
  ));
  const To = forwardRef(({ value, onClick }, ref) => (
    <div className="form-group form-focus focused" onClick={onClick} ref={ref}>
      <div className="cal-icon">
        <input
          className="form-control floating datetimepicker"
          type="text"
          value={value}
        />
      </div>
      <label className="focus-label">To</label>
    </div>
  ));
  let reportSearch;
  // if (values.location !== ''){
  reportSearch = {
    companyId: auth.user.companyId.id,
    // companyId: auth.user.companyId.id,
    name: values.name,
    loadNumber: values.loadNumber,
    // date: moment().format('YYYY-MM-DD'),
    from: values.from,
    // from: fromDate.toISOString().substring(0, 10),
    // to: toDate.toISOString().substring(0, 10),
    to: values.to,
  };
  // console.log(toDate.toISOString(), "toDate.toISOString()")
  // console.log(kpi, "djdjdjdjdjjd jddjjdjd jd dj dj djd jd dj")
  // console.log(values)

  const [reportTypeObj, setReportTypeObj] = useState([]);
  // const handleLeaveType = (fieldName) => (event) =>{
  // console.log(event.target.value)
  // const abc = JSON.parse(event.target.value)
  //     if (abc !== null){
  //         if (values.reportType.includes(abc.id) === false){
  //             console.log(abc)
  //             setReportTypeObj([...reportTypeObj,abc])
  //             setValues({
  //                 ...values, [fieldName]:
  //                 [...values.reportType, abc.id]
  //             })
  //         }
  //     }
  // }
  const handleClick = async (event) => {
    event.preventDefault();
    for (let key of Object.keys(reportSearch)) {
      if (reportSearch[key] === "") {
        delete reportSearch[key];
      }
      if (allDrivers) {
        delete reportSearch["name"];
      }
      // console.log(key,"54121421521", reportSearch[key])
    }

    dispatch(tabReportSearch());
    // dispatch(reservationReportSearch(reportSearch, arrReassign))
    const arr = states.map((x) => x.value);
    const obj = { ...reportSearch };
    if (arr.length > 0) {
      obj.status = arr;
    }
    dispatch(reservationReportSearch(obj));
  };

  return (
    <>
      <div className="d-flex flex-column w-tables rounded mt-3 bg-white">
        <div className="card-body">
          <div className="row align-item-center">
            <div className="col-md-12">
              <label
                className="f-16 font-weight-bold text-dark mb-12"
                data-label=""
                htmlFor="selDriver"
              >
                Driver
              </label>
            </div>
            <div className="col-md-12 mb-3">
              <div className="row align-item-center">
                <div className="col-lg-auto col-md-auto col-sm-12">
                  <div className="form-check">
                    <input
                      type="checkbox"
                      name="select_all_table"
                      className="form-check-input"
                      autocomplete="off"
                      onChange={(e) => setallDrivers(e.target.checked)}
                    />
                    <label
                      className="form-check-label pl-2 f-16 text-dark mx-1"
                      htmlFor="flexCheckChecked"
                    >
                      All Driver
                    </label>
                  </div>
                </div>
                <div className="col-lg col-md col-sm-12">
                  <div className="form-group">
                    <label
                      className="f-14 text-dark mb-12"
                      data-label=""
                      htmlFor="clnumber"
                    >
                      Search specific driver
                    </label>
                    {/* <input
                      type="text"
                      className="form-control height-35 f-14"
                      placeholder=""
                      id=""
                      autocomplete="off"
                      value={values.name}
                      onChange={handleChange("name")}
                      disabled={allDrivers}
                    /> */}
                    <Select
                      className="basic-single"
                      classNamePrefix="select"
                      defaultValue={""}
                      value={availableDrivers
                        ?.map((x) => ({
                          label: `${x.firstName} ${x.lastName}`,
                          value: x.id,
                        }))
                        .find(
                          (customer) => customer.value === values?.driverId
                        )}
                      isDisabled={false}
                      isClearable={false}
                      isRtl={false}
                      isSearchable={true}
                      name="pickUp"
                      onChange={(e) => {
                        setValues({
                          ...values,
                          name: e.value,
                        });
                      }}
                      options={availableDrivers?.map((x) => ({
                        label: `${x.firstName} ${x.lastName} `,
                        value: x.id,
                      }))}
                    />
                  </div>
                </div>
                <div className="col-lg col-md col-sm-12">
                  <div className="form-group">
                    <label
                      className="f-14 text-dark mb-12"
                      data-label=""
                      htmlFor="clnumber"
                    >
                      Date Range From
                    </label>
                    <input
                      type="date"
                      className="form-control height-35 f-14"
                      placeholder=""
                      id=""
                      autocomplete="off"
                      value={values.from}
                      onChange={handleChange("from")}
                    />
                  </div>
                </div>
                <div className="col-lg col-md col-sm-12">
                  <div className="form-group">
                    <label
                      className="f-14 text-dark mb-12"
                      data-label=""
                      htmlFor="clnumber"
                    >
                      Date Range To
                    </label>
                    <input
                      type="date"
                      className="form-control height-35 f-14"
                      placeholder=""
                      id=""
                      autocomplete="off"
                      value={values.to}
                      onChange={handleChange("to")}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-12 mb-3">
              <div className="row">
                <div className="col-lg-auto col-md-8 col-sm-12">
                  <label
                    className="f-18 text-dark mb-12"
                    data-label=""
                    htmlFor="clnumber"
                  >
                    States
                  </label>
                  <br />
                  <div className="form-check form-check-inline ">
                    <input
                      type="checkbox"
                      name="select_all_table"
                      className="form-check-input"
                      autocomplete="off"
                      onChange={handleStates("completed")}
                    />
                    <label
                      className="form-check-label pl-2 f-16 text-dark mx-1"
                      htmlFor="flexCheckChecked"
                    >
                      Completed
                    </label>
                  </div>
                  <div className="form-check form-check-inline ">
                    <input
                      type="checkbox"
                      name="select_all_table"
                      className="form-check-input"
                      autocomplete="off"
                      onChange={handleStates("canceled")}
                    />
                    <label
                      className="form-check-label pl-2 f-16 text-dark mx-1"
                      htmlFor="flexCheckChecked"
                    >
                      Cancelled
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      type="checkbox"
                      name="select_all_table"
                      className="form-check-input"
                      autocomplete="off"
                      onChange={handleStates("noShow")}
                    />
                    <label
                      className="form-check-label pl-2 f-16 text-dark mx-1"
                      htmlFor="flexCheckChecked"
                    >
                      No show
                    </label>
                  </div>
                </div>
                <div className="col">
                  <div className="mt-3 ">
                    <button
                      type="button"
                      className="btn btn-primary btn-sm px-5 "
                      onClick={handleClick}
                    >
                      {" "}
                      Search
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="row p-3">
        <div className="col-lg-3">
          <div className="form-group my-3">
            <label className="f-14 text-dark-grey mb-12" data-label="">
              Client Name
            </label>
            <input
              type="text"
              placeholder="Search Customer Name"
              className="form-control height-35 f-14"
              value={values.name}
              onChange={handleChange("name")}
            />
          </div>
        </div>
        <div className="col-lg-3">
          <div className="form-group my-3">
            <label className="f-14 text-dark-grey mb-12" data-label="">
              Load Number
            </label>
            <input
              type="number"
              placeholder="Search Load Number"
              value={values.loadNumber}
              onChange={handleChange("loadNumber")}
              className="form-control height-35 f-14"
            />
          </div>
        </div>
        <div className="col-lg-2">
          <div className="form-group my-3">
            <div className="d-flex">
              <div>
                <label
                  className="f-14 text-dark mb-12"
                  data-label=""
                  htmlFor="clnumber"
                >
                  From
                </label>
              </div>
            </div>
            <input
              type="date"
              className="form-control height-35 f-14"
              value={values.from}
              onChange={handleChange("from")}
              autoComplete="off"
            />
          </div>
        </div>
        <div className="col-lg-2">
          <div className="form-group my-3">
            <div className="d-flex">
              <div>
                <label
                  className="f-14 text-dark mb-12"
                  data-label=""
                  htmlFor="clnumber"
                >
                  To
                </label>
              </div>
            </div>
            <input
              type="date"
              className="form-control height-35 f-14"
              placeholder=""
              id="driver"
              min="0"
              value={values.to}
              onChange={handleChange("to")}
              autoComplete="off"
            />
          </div>
        </div>
        <div className="col-lg-2">
          <div className="form-group my-2">
            <br />
            <br />

            <button className="btn btn-success btn-block" onClick={handleClick}>
              {" "}
              Search{" "}
            </button>
          </div>
        </div>
      </div> */}
    </>
  );
};
export default AdminReportSearchandFilter;
