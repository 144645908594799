import moment from 'moment';
import React, { useState } from 'react'

const AdminUserLogInActivitiesRowComponent = ({
  current,
  i,
  dropDownRow,
  setDropDownRow,
  setModal,
}) => {
  const [showMoreVertDropDown, setshowMoreVertDropDown] = useState(false);
  const openMoreVertDropDown = () => {
    setshowMoreVertDropDown((showMoreVertDropDown) => !showMoreVertDropDown);
  };
  return (
    <>
      <tr
        id="row-20"
        role="row"
        className="odd border"
        key={i}
        onMouseLeave={() => setshowMoreVertDropDown(false)}
        >
        {/* <td className="border-right">{`${current?.firstName} ${current?.lastName}`}</td> */}
        {/* <td className="border-right">{`${current?.firstName} ${current?.lastName}`}</td> */}
        <td className="border-right">{current.country}</td>
        <td className="border-right">{current.state}</td>
        <td className="border-right">{current.ipAddress}</td>
        <td className="border-right">{current.deviceType}</td>
        <td className="border-right">{moment(current.loginTime).format('MM/DD/YYYY - h:mm a')}</td>
        <td className="border-right">{moment(current.logoutTime).format('MM/DD/YYYY - h:mm a')}</td>
        <td className="border-right">{current.loginDuration}</td>
      </tr>
    </>
  )
}
export default AdminUserLogInActivitiesRowComponent