/** @format */

import React, { useEffect } from "react";
import AdminHeader from "../../components/adminComponents/adminHeaderSidebarTitleComponents/AdminHeader";
import AdminSidebar from "../../components/adminComponents/adminHeaderSidebarTitleComponents/AdminSidebar";
// import AdminTrackAllDriverListCard from '../../components/adminComponents/adminTrackViewComponents/AdminTrackAllDriverListCard'
// import AdminTrackAllDriverMapView from '../../components/adminComponents/adminTrackViewComponents/AdminTrackAllDriverMapView'
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { navigateUserFunc } from "../../actions/generalActions";
import AdminDashboardTracking from "../../components/adminComponents/adminDashboardComponents/AdminDashboardCards";
import NotAllowed from "../../components/NotAllowed";
import { TRIP_RESET_WAY_POINTS } from "../../constants";

const AdminTrackPage = () => {
  let navigate = useNavigate();
  const auth = useSelector((state) => state?.auth);
  useEffect(() => {
    if (
      auth?.user?.permissionId?.track?.isAllow === false ||
      auth?.user?.disable === true
    ) {
      navigateUserFunc(auth?.user, navigate);
    }
  }, []);
  const locationUrl = useLocation();
  const dispatch = useDispatch();
  const reload =  () => {
    dispatch({
      type: TRIP_RESET_WAY_POINTS,
      payload: null,
    });
  };
  useEffect(()=>{reload()},[])
  return (
    <>
      {locationUrl && locationUrl.pathname === "/track" ? (
        <>
          <AdminHeader notShow />
          <AdminSidebar />
          <section className="main-container " id="fullscreen">
            {auth?.user?.permissionId?.track?.read === true ? (
              <AdminDashboardTracking />
            ) : (
              <NotAllowed />
            )}
          </section>
        </>
      ) : (
        <>
          <AdminHeader />
          <AdminSidebar />
          <div className="body-wrapper clearfix">
            <section className="main-container " id="fullscreen">
              <div className="px-4 py-2 border-top-0 emp-dashboard">
                <div className="d-lg-flex d-md-flex d-block py-4">
                  <div>
                    <h4 className=" mb-0 f-21 text-capitalize font-weight-bold">
                      Track Drivers
                    </h4>
                  </div>
                </div>
                {/* <AdminTrackAllDriverListCard /> */}
                {/* <AdminTrackAllDriverMapView /> */}
                {auth?.user?.permissionId?.track?.read === true ? (
                  <AdminDashboardTracking />
                ) : (
                  <NotAllowed />
                )}
              </div>
            </section>
          </div>
        </>
      )}
    </>
  );
};
export default AdminTrackPage;
