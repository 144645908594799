import React, { Fragment } from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";
import moment from "moment";

const borderColor = "#000";
const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    borderBottomColor: borderColor,
    // color: "#fff",
    borderBottomWidth: 1,
    alignItems: "center",
    // height: 24,
    textAlign: "center",
    fontStyle: "bold",
    flexGrow: 1,
    fontSize: 10,
    // backgroundColor: "#D12860",
  },
  no: {
    width: "10%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    padding: 4,
    textAlign: "left",
    fontSize: 12,fontSize: 10,
  },
  stop: {
    width: "10%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    padding: 4,
    fontSize: 12,
    textAlign: "left",fontSize: 10,
  },
  address: {
    width: "50%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    padding: 4,
    textAlign: "left",
    fontSize: 12,fontSize: 10,
  },
  date: {
    width: "15%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    padding: 4,
    textAlign: "left",fontSize: 10,
  },
  time: {
    width: "15%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    padding: 4,
    textAlign: "left",
    fontSize: 10,
  },
});

const InvoiceTableRow = ({ data }) => {
  const rows = data.map((item, index) => (
    <View style={styles.container} key={item._id}>
      <Text style={styles.no}>{index + 1}</Text>
      <Text style={styles.stop}> {index === 0 ? "Pickup" : "Dropoff"}</Text>
      <Text style={styles.address}>{item.address.address}</Text>
      <Text style={styles.date}>
        {moment(item.date.toString().substring(0, 10)).format("MM/DD/YYYY")}
      </Text>
      <Text style={styles.time}>{item.time}</Text>
    </View>
  ));
  return <Fragment>{rows}</Fragment>;
};

export default InvoiceTableRow;
