import React from 'react'
import AdminHeader from '../../components/adminComponents/adminHeaderSidebarTitleComponents/AdminHeader'
import AdminSidebar from '../../components/adminComponents/adminHeaderSidebarTitleComponents/AdminSidebar'
import ChangePasswordComponent from '../../components/adminComponents/adminHeaderSidebarTitleComponents/ChangePasswordComponent'

const ExternalCompanyChangePassword = () => {
    return (
        <>
            <AdminHeader />
            <AdminSidebar />
            <div className="body-wrapper clearfix">
                <section className="main-container " id="fullscreen">
                    <div className="px-4 py-2 border-top-0 emp-dashboard">
                        <div className="d-lg-flex d-md-flex d-block py-4">
                            <div>
                                <h4 className=" mb-0 f-21 text-capitalize font-weight-bold">
                                    Change Password
                                </h4>
                            </div>
                        </div>
                        <ChangePasswordComponent />
                    </div>
                </section>
            </div>
        </>
    )
}
export default ExternalCompanyChangePassword