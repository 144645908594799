import React from 'react'
import { useSelector } from 'react-redux';

const DriverDailyEarningComponent = () => {
    const auth = useSelector((state) => state.auth);
    const reservation = useSelector((state) => state.reservation);
    return (
        <>
            <div className="settings-box-custom rounded">
                <form
                    method="POST"
                    id="editSettings"
                    className="ajax-form"
                    autoComplete="off"
                >
                    <div className="s-b-inner s-b-notifications bg-white b-shadow-4 rounded">
                        <div className="s-b-n-header d-lg-flex justify-content-between card-header border-bottom-grey">
                            <div className="s-b-n-header" id="tabs">
                                This Day Earning
                                <h2 className="mb-0 p-20 f-21 font-weight-normal text-capitalize">
                                    <span>$ {reservation?.driverEarning?.dailyEarning?.earning ? reservation?.driverEarning?.dailyEarning?.earning.toFixed(2) : 0.0}</span>
                                </h2>
                            </div>
                        </div>
                        <div className="s-b-n-content">
                            <div className="tab-content" id="nav-tabContent">
                                <div className="tab-pane fade show active" id="nav-email" role="tabpanel" aria-labelledby="nav-email-tab">
                                    <div className="d-flex flex-wrap justify-content-between">
                                        <div className="col-lg-12 col-md-12 ntfcn-tab-content-left w-100 p-4 ">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="form-group my-3">
                                                        <label className="f-14 text-dark mb-12" data-label="" htmlFor="Name"> No. of trips</label>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group my-3">
                                                        <label className="f-14 text-dark mb-12" data-label="" htmlFor="Name"> {reservation?.driverEarning?.dailyEarning?.noOfTrips ? reservation?.driverEarning?.dailyEarning?.noOfTrips : 0} </label>
                                                    </div>
                                                </div>

                                                <div className="col-md-6">
                                                    <div className="form-group my-3">
                                                        <label className="f-14 text-dark mb-12" data-label="" htmlFor="Name"> Trip duration</label>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group my-3">
                                                        <label className="f-14 text-dark mb-12" data-label="" htmlFor="Name"> {reservation?.driverEarning?.dailyEarning?.distance ? reservation?.driverEarning?.dailyEarning?.distance.toFixed(2) : 0} mi</label>
                                                    </div>
                                                </div>
                                                {/* <div className="col-md-6">
                                                    <div className="form-group my-3">
                                                        <label className="f-14 text-dark mb-12" data-label="" htmlFor="Name"> Tips from rider</label>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group my-3">
                                                        <label className="f-14 text-dark mb-12" data-label="" htmlFor="Name"> $ 0.00 </label>
                                                    </div>
                                                </div> */}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="modal-footer">
                                    </div>
                                    <div className="col-lg-12 col-md-12 ntfcn-tab-content-left w-100 p-4 ">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group my-3">
                                                    <label className="f-14 text-dark mb-12" data-label="" htmlFor="Name"> Total Earnings</label>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group my-3">
                                                    <label className="f-14 text-dark mb-12" data-label="" htmlFor="Name"> $ {reservation?.driverEarning?.dailyEarning?.earning ? reservation?.driverEarning?.dailyEarning?.earning.toFixed(2) : 0.0} </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* {
myState.viewPayment === "baselocation" ? (
auth?.user?.permissionId?.settings?.read === true ?
<AdminSettingPaymentsBaseLocation />
:
<NotAllowed />
) : myState.viewPayment === "AddServices" ? (
auth?.user?.permissionId?.settings?.create === true ?
<AdminSettingPaymentsFare />
:
<NotAllowed />
) : myState.viewPayment === "fareList" ? (
auth?.user?.permissionId?.settings?.read === true ?
<AdminSettingPaymentsFareTable />
:
<NotAllowed />
) : (
<></>
)
} */}

                    </div>
                </form>
            </div>
        </>
    )
}
export default DriverDailyEarningComponent