/** @format */

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addVehicle } from "../../../actions/vehicleActions";
import { Link, useNavigate } from "react-router-dom";
// import moment from 'moment'
import AdminSettingVehicleAddNewServiceNameModal from "./AdminSettingVehicleAddNewServiceNameModal";
// import sign1 from "../../../assets/images/signwheel.jpg"
// import sign2 from "../../../assets/images/signrearfacing.jpg";
// import sign3 from "../../../assets/images/sign3.jpg";
// import sign4 from "../../../assets/images/sign4.jpg";
import Select from 'react-select';
import axios from "axios";
import { SERVERADDRESS } from "../../../constants";

const AdminSettingVehicleAddComponent = () => {
  const vehicle = useSelector((state) => state.vehicle);
  const auth = useSelector((state) => state.auth);
  const service = useSelector((state) => state.service);
  const [selectedValue, setSelectedValue] = useState([]);
  const {loading} = vehicle

  const navigate = useNavigate();
  const [values, setValues] = useState({
    assetNo: "",
    serviceType: "",
    vehicleOwnership: "Office",
    make: "",
    petrackImei: "",
    seats: "",
    model: "",
    year: "",
    vin: "",
    numberPlate: "",
    color: "",
    mileage: "",
    inspectionExpiration: "",
    insurancePolicyNumber: "",
    insuranceCompanyName: "",
    insuranceExpirationDate: "",
    brakesExpiration: "",
    badgeId: "",
  });
  const handleChange = (fieldName) => (event) => {
    setValues({
      ...values,
      [fieldName]: event.target.value,
    });
  };
  const [serviceNames, setServiceNames] = useState([]);
  const handleChangeType = (fieldName) => (event) => {
    const sNames = service?.service.find((x) => x.id === event.target.value);
    setValues({
      ...values,
      [fieldName]: event.target.value,
      ["fullName"]: sNames?.serviceName[0]?.fullName,
    });
    setServiceNames(sNames.serviceName);
  };
  // console.log(values, "<---value")
  // console.log(serviceNames, "sssssssssss")
  useEffect(() => {
    if (values.serviceType === "" || values.fullName === "") {
      setValues({
        ...values,
        ["serviceType"]: service?.service[0]?.id,
        ["fullName"]: service?.service[0]?.serviceName[0]?.fullName,
      });
      setServiceNames(service?.service[0]?.serviceName);
    }
  }, [service]);
  const dispatch = useDispatch();
  const vehicleData = {
    companyId: auth.user.companyId.id,
    // assignUserId: auth.user.id,
    // oper wala unlock krein ?????
    assetNo: values.assetNo,
    // serviceType: "63cb14f63f55723694dbd18b",
    serviceType: values.serviceType,
    serviceName: values.fullName,
    vehicleOwnership: values.vehicleOwnership,
    make: values.make,
    model: values.model,
    year: values.year,
    vin: values.vin,
    numberPlate: values.numberPlate,
    color: values.color,
    mileage: values.mileage,
    inspectionExpiration: values.inspectionExpiration,
    insurancePolicyNumber: values.insurancePolicyNumber,
    insuranceCompanyName: values.insuranceCompanyName,
    petrackImei: values.petrackImei,
    seats: values.seats,
    insuranceExpirationDate: values.insuranceExpirationDate,
    brakesExpiration: values.brakesExpiration,
    badgeId: selectedValue,
    // startDate: fromDate.toISOString(),
    // endDate: toDate.toISOString(),
    // status: values.status === "true" ? true : false ,
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    // console.log("first")
    for (let key of Object.keys(vehicleData)) {
      if (vehicleData[key] === "") {
        delete vehicleData[key];
      }
    }
    dispatch(addVehicle(vehicleData, navigate));
    // setValues({
    //     assetNo: '',
    //     serviceType: '',
    //     vehicleOwnership: '',
    //     make: '',
    //     model: '',
    //     year: '',
    //     vin: '',
    //     numberPlate: '',
    //     color: '',
    //     mileage: '',
    //     inspectionExpiration: '',
    //     insurancePolicyNumber: '',
    //     insuranceCompanyName: '',
    //     insuranceExpirationDate: '',
    //     brakesExpiration: '',
    // })
  };


  const onSelect = (selectedList, selectedItem) => {
    // setSelectedValue([...selectedList]);
    const selectedIds = selectedList.map(option => option.value);
    setSelectedValue(selectedIds);
  };
  // console.log(selectedValue)
  const onRemove = (selectedList, removedItem) => {
    setSelectedValue([...selectedList]);
    const remainingIds = selectedList.map(option => option.value);
    setSelectedValue(remainingIds);
  };
  const [ data, setData ] = useState([])
  const badgeData = async () => {
    const res = await axios.get(`${SERVERADDRESS}/v1/badge/allBadges`) 
    setData(res.data)
  }
  useEffect(() => {
    badgeData()
    // setValues(auth?.user?.companyId?.badgeId)
  },[])
  // const options = [
  //   { value: '1', label: <img src={sign1} alt="Option 1" style={{ width: '30px', height: '30px', marginRight: '10px', borderRadius: '50%' }} /> },
  //   { value: '2', label: <img src={sign2} alt="Option 2" style={{ width: '30px', height: '30px', marginRight: '10px', borderRadius: '50%' }} />  }, // Add the second option with its image
  //   { value: '3', label: <img src={sign3} alt="Option 3" style={{ width: '30px', height: '30px', marginRight: '10px', borderRadius: '50%' }} />  }, // Add the second option with its image
  //   { value: '4', label: <img src={sign4} alt="Option 4" style={{ width: '30px', height: '30px', marginRight: '10px', borderRadius: '50%' }} />  }, // Add the second option with its image
  // ];
  const options = data.map(badge => ({
    value: badge.id,
    label: (
      <div key={badge.id}>
        <img
          src={badge.badge}
          alt={`Badge ${badge.id}`}
          style={{ width: '30px', height: '30px', marginRight: '10px', borderRadius: '50%' }}
        />
      </div>
    ),
  }));
  const alphabets = [
    "a",
    "b",
    "c",
    "d",
    "e",
    "f",
    "g",
    "h",
    "i",
    "j",
    "k",
    "l",
    "m",
    "n",
    "o",
    "p",
    "q",
    "r",
    "s",
    "t",
    "u",
    "v",
    "w",
    "x",
    "y",
    "z",
    "A",
    "B",
    "C",
    "D",
    "E",
    "F",
    "G",
    "H",
    "I",
    "J",
    "K",
    "L",
    "M",
    "N",
    "O",
    "P",
    "Q",
    "R",
    "S",
    "T",
    "U",
    "V",
    "W",
    "X",
    "Y",
    "Z",
    "+",
    "-",
    "/",
    "*",
    "!",
    "@",
    "#",
    "$",
    "%",
    "^",
    "&",
    "_",
    "(",
    ")",
    ",",
    ".",
  ];
  // console.log(values, "values")
  const [showDropDown, setShowDropDown] = useState(false);
  const openDropDown = () => {
    setShowDropDown((showDropDown) => !showDropDown);
  };
  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className="d-flex flex-column w-tables rounded mt-3 bg-white px-4 pb-3">
          <div
            id="leads-table_wrapper"
            className="dataTables_wrapper dt-bootstrap4 no-footer"
          >
            <div className="row p-3">
              <div className="col-md-12 mt-4 mb-3">
                <div
                  id="table-actions"
                  className="flex-grow-1 align-items-center"
                >
                  <h4 className=" mb-0 f-21 text-capitalize font-weight-bold">
                    Basic
                  </h4>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="form-group my-3">
                  <label
                    className="f-14 text-dark-grey mb-12"
                    data-label=""
                    htmlFor="after_days"
                  >
                    Vehicle#
                  </label>
                  <sup className="text-danger f-12">*</sup>
                  <input
                    type="text"
                    className="form-control height-35 f-14"
                    placeholder=""
                    autoComplete="false"
                    value={values.assetNo}
                    required
                    onChange={handleChange("assetNo")}
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <label
                  className="f-14 text-dark-grey mb-12 mt-3"
                  data-label=""
                  htmlFor="default_task_status"
                >
                  {" "}
                  Type
                </label>
                {/* <sup className="text-danger f-12">*</sup>  */}
                <div className="form-group mb-0">
                  <div className="select-picker">
                    <select
                      className="form-control select-picker"
                      style={{ height: "39px" }}
                      value={values.vehicleOwnership}
                      onChange={handleChange("vehicleOwnership")}
                    >
                      <option value="Office"> Office</option>
                      <option value="Contractor"> Contractor</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="d-flex justify-content-between mt-3">
                  <div>
                    <div>
                      <label
                        className="f-14 text-dark-grey mb-12"
                        data-label=""
                        htmlFor="clnumber"
                      >
                        Service Type
                        <sup className="text-danger f-12">*</sup>
                      </label>
                    </div>
                  </div>
                  <div>
                    <Link
                      to=""
                      type="button"
                      data-toggle="modal"
                      data-target="#exampleModalCenter"
                    >
                      {/* <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" className="bi bi-plus-circle-fill fa-w-20 f-20 text-dark" viewBox="0 0 16 16">
                                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3v-3z"/>
                                        </svg> */}
                    </Link>
                  </div>
                </div>
                <div className="form-group mb-0">
                  <div className="select-picker">
                    <select
                      className="form-control select-picker"
                      style={{ height: "39px" }}
                      value={values.serviceType}
                      onChange={handleChangeType("serviceType")}
                      required
                    >
                      {service &&
                        service.service &&
                        service.service.length > 0 &&
                        service.service.map((current, i) => {
                          return (
                            <option value={current.id} key={i}>
                              {" "}
                              {current.name}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="d-flex justify-content-between mt-3">
                  <div className="col-sm-11" style={{ paddingLeft: "0px" }}>
                    <div>
                      <label
                        className="f-14 text-dark-grey mb-12"
                        data-label=""
                         htmlFor="clnumber"
                      >
                        Service Name
                        <sup className="text-danger f-12">*</sup>
                      </label>
                    </div>
                  </div>
                  <div className="col-sm-1">
                    <button
                      className="bg-button"
                      type="button"
                      data-toggle="modal"
                      data-target="#exampleModalCenter"
                      onClick={openDropDown}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        fill="currentColor"
                        className="bi bi-plus-circle-fill fa-w-20 f-20 text-dark"
                        viewBox="0 0 16 16"
                      >
                        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3v-3z" />
                      </svg>
                    </button>
                  </div>
                  <div>
                    <Link
                      to=""
                      type="button"
                      data-toggle="modal"
                      data-target="#exampleModalCenter"
                    ></Link>
                  </div>
                </div>
                <div className="form-group mb-0">
                  <div className="select-picker">
                    <select
                      className="form-control select-picker"
                      style={{ height: "39px" }}
                      value={values.fullName}
                      onChange={handleChange("fullName")}
                      required
                    >
                      {serviceNames.length > 0 &&
                        // serviceNames.serviceName > 0 &&
                        // serviceNames.serviceName.length > 0 &&
                        // serviceNames.serviceName.map((current, i) => {
                        serviceNames.map((current, i) => {
                          // console.log(current, "currentcurrent")
                          return (
                            <option value={current.fullName} key={i}>
                              {" "}
                              {current.fullName}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="d-flex justify-content-between mt-3">
                  <div className="col-sm-11" style={{ paddingLeft: "0px" }}>
                    <div>
                      <label
                        className="f-14 text-dark-grey mb-12"
                        data-label=""
                         htmlFor="clnumber"
                      >
                        Add Badge(s)
                        {/* <sup className="text-danger f-12">*</sup> */}
                      </label>
                    </div>
                  </div>
                  <div>
                    <Link
                      to=""
                      type="button"
                      data-toggle="modal"
                      data-target="#exampleModalCenter"
                    ></Link>
                  </div>
                </div>
                <div className="form-group mb-0">
                <Select
                    options={options}
                    onChange={onSelect} // Function will trigger on select event
                    onRemove={onRemove} // Function will trigger on remove event
                    displayValue="name" // Property name to display in the dropdown options
                    // value={selectedValue} // Preselected value to persist in dropdown
                    value={options.filter(option => selectedValue.includes(option.value))}
                    getOptionLabel={(option) => option.label} // Adjust based on your data structure
                    getOptionValue={(option) => option.value}
                    isMulti
                  />
                </div>
              </div>
              
              <div className="col-lg-6">
                <div className="form-group my-3">
                  <label
                    className="f-14 text-dark-grey mb-12"
                    data-label=""
                    htmlFor="after_days"
                  >
                    IMEI
                  </label>
                  {/* <sup className="text-danger f-12">*</sup> */}
                  <input
                    type="text"
                    className="form-control height-35 f-14"
                    placeholder=""
                    autoComplete="false"
                    onKeyDown={(e) => {
                      if (e.ctrlKey && e.code === "KeyV") {
                        return;
                      }
                      if (alphabets.includes(e.key)) {
                        e.preventDefault();
                      }
                    }}
                    value={values.petrackImei}
                    onChange={handleChange("petrackImei")}
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="form-group my-3">
                  <label
                    className="f-14 text-dark-grey mb-12"
                    data-label=""
                    htmlFor="after_days"
                  >
                    Seats
                  </label>
                  {/* <sup className="text-danger f-12">*</sup> */}
                  <input
                    type="text"
                    className="form-control height-35 f-14"
                    placeholder=""
                    autoComplete="false"
                    onKeyDown={(e) => {
                      if (e.ctrlKey && e.code === "KeyV") {
                        return;
                      }
                      if (alphabets.includes(e.key)) {
                        e.preventDefault();
                      }
                    }}
                    value={values.seats}
                    onChange={handleChange("seats")}
                  />
                </div>
              </div>
              <div className="col-md-12 mt-4 mb-3">
                <div
                  id="table-actions"
                  className="flex-grow-1 align-items-center"
                >
                  <h4 className=" mb-0 f-21 text-capitalize font-weight-bold">
                    Vehicle Information
                  </h4>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="form-group my-3">
                  <label
                    className="f-14 text-dark-grey mb-12"
                    data-label=""
                    htmlFor="after_days"
                  >
                    Make
                  </label>
                  {/* <sup className="text-danger f-12">*</sup> */}
                  <input
                    type="text"
                    className="form-control height-35 f-14"
                    placeholder=""
                    autoComplete="false"
                    value={values.make}
                    onChange={handleChange("make")}
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="form-group my-3">
                  <label
                    className="f-14 text-dark-grey mb-12"
                    data-label=""
                    htmlFor="after_days"
                  >
                    Model
                  </label>
                  {/* <sup className="text-danger f-12">*</sup> */}
                  <input
                    type="text"
                    className="form-control height-35 f-14"
                    placeholder=""
                    autoComplete="false"
                    value={values.model}
                    onChange={handleChange("model")}
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="form-group my-3">
                  <label
                    className="f-14 text-dark-grey mb-12"
                    data-label=""
                    htmlFor="after_days"
                  >
                    Year
                  </label>
                  {/* <sup className="text-danger f-12">*</sup> */}
                  <input
                    type="text"
                    className="form-control height-35 f-14"
                    placeholder=""
                    autoComplete="false"
                    value={values.year}
                    onChange={handleChange("year")}
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="form-group my-3">
                  <label
                    className="f-14 text-dark-grey mb-12"
                    data-label=""
                    htmlFor="after_days"
                  >
                    VIN
                  </label>
                  {/* <sup className="text-danger f-12">*</sup> */}
                  <input
                    type="text"
                    className="form-control height-35 f-14"
                    placeholder=""
                    autoComplete="false"
                    value={values.vin}
                    onChange={handleChange("vin")}
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="form-group my-3">
                  <label
                    className="f-14 text-dark-grey mb-12"
                    data-label=""
                    htmlFor="after_days"
                  >
                    Plate
                  </label>
                  {/* <sup className="text-danger f-12">*</sup> */}
                  <input
                    type="text"
                    className="form-control height-35 f-14"
                    placeholder=""
                    autoComplete="false"
                    value={values.numberPlate}
                    onChange={handleChange("numberPlate")}
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="form-group my-3">
                  <label
                    className="f-14 text-dark-grey mb-12"
                    data-label=""
                    htmlFor="after_days"
                  >
                    Color
                  </label>
                  {/* <sup className="text-danger f-12">*</sup> */}
                  <input
                    type="text"
                    className="form-control height-35 f-14"
                    placeholder=""
                    autoComplete="false"
                    value={values.color}
                    onChange={handleChange("color")}
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="form-group my-3">
                  <label
                    className="f-14 text-dark-grey mb-12"
                    data-label=""
                    htmlFor="after_days"
                  >
                    Mileage
                  </label>
                  {/* <sup className="text-danger f-12">*</sup> */}
                  <input
                    type="text"
                    className="form-control height-35 f-14"
                    placeholder=""
                    autoComplete="false"
                    value={values.mileage}
                    onChange={handleChange("mileage")}
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="form-group my-3">
                  <label
                    className="f-14 text-dark-grey mb-12"
                    data-label=""
                    htmlFor="after_days"
                  >
                    Vehicle Inspections Expiration
                  </label>
                  {/* <sup className="text-danger f-12">*</sup> */}
                  <input
                    type="date"
                    className="form-control height-35 f-14"
                    placeholder=""
                    autoComplete="false"
                    value={values.inspectionExpiration}
                    onChange={handleChange("inspectionExpiration")}
                  />
                </div>
              </div>
              <div className="col-md-12 mt-4 mb-3">
                <div
                  id="table-actions"
                  className="flex-grow-1 align-items-center"
                >
                  <h4 className=" mb-0 f-21 text-capitalize font-weight-bold">
                    Insurance
                  </h4>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="form-group my-3">
                  <label
                    className="f-14 text-dark-grey mb-12"
                    data-label=""
                    htmlFor="after_days"
                  >
                    Insurance Policy Number
                  </label>
                  {/* <sup className="text-danger f-12">*</sup> */}
                  <input
                    type="number"
                    className="form-control height-35 f-14"
                    placeholder=""
                    autoComplete="false"
                    value={values.insurancePolicyNumber}
                    onChange={handleChange("insurancePolicyNumber")}
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="form-group my-3">
                  <label
                    className="f-14 text-dark-grey mb-12"
                    data-label=""
                    htmlFor="after_days"
                  >
                    Insurance Company Name
                  </label>
                  {/* <sup className="text-danger f-12">*</sup> */}
                  <input
                    type="text"
                    className="form-control height-35 f-14"
                    placeholder=""
                    autoComplete="false"
                    value={values.insuranceCompanyName}
                    onChange={handleChange("insuranceCompanyName")}
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="form-group my-3">
                  <label
                    className="f-14 text-dark-grey mb-12"
                    data-label=""
                    htmlFor="after_days"
                  >
                    Insurance Expiration Date
                  </label>
                  {/* <sup className="text-danger f-12">*</sup> */}
                  <input
                    type="date"
                    className="form-control height-35 f-14"
                    placeholder=""
                    autoComplete="false"
                    value={values.insuranceExpirationDate}
                    onChange={handleChange("insuranceExpirationDate")}
                  />
                </div>
              </div>
              <div className="col-md-12 mt-4 mb-3">
                <div
                  id="table-actions"
                  className="flex-grow-1 align-items-center"
                >
                  <h4 className=" mb-0 f-21 text-capitalize font-weight-bold">
                    Brakes Expiration
                  </h4>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="form-group my-3">
                  <label
                    className="f-14 text-dark-grey mb-12"
                    data-label=""
                    htmlFor="after_days"
                  >
                    Brakes Expiration Date
                  </label>
                  {/* <sup className="text-danger f-12">*</sup> */}
                  <input
                    type="date"
                    className="form-control height-35 f-14"
                    placeholder=""
                    autoComplete="false"
                    value={values.brakesExpiration}
                    onChange={handleChange("brakesExpiration")}
                  />
                </div>
              </div>
              <div className="col-lg-12">
                <div
                  className="form-group "
                  style={{ marginTop: "1.5em !important" }}
                >
                  <button
                    type="submit"
                    className="btn-primary rounded f-14 p-2 mr-3"
                    disabled={loading}
                    // onClick={handleSubmit}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
      <AdminSettingVehicleAddNewServiceNameModal
        showDropDown={showDropDown}
        openDropDown={openDropDown}
        values={values}
        setValues={setValues}
        serviceNames={serviceNames}
        setServiceNames={setServiceNames}
      />
    </>
  );
};
export default AdminSettingVehicleAddComponent;
