import React, { useEffect } from 'react'
import AdminHeader from '../../components/adminComponents/adminHeaderSidebarTitleComponents/AdminHeader'
import AdminSidebar from '../../components/adminComponents/adminHeaderSidebarTitleComponents/AdminSidebar'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { navigateUserFunc } from '../../actions/generalActions'
import NotAllowed from '../../components/NotAllowed'
import AdminPermissionEditModal from '../../components/adminComponents/adminPermissionComponents/AdminPermissionEditModal'
// import AdminPermissionCreatePermissionComponent from '../../components/adminComponents/adminPermissionComponents/AdminPermissionCreatePermissionComponent'

const AdminPermissionEditModalPage = () => {

  return (
    <>
        <AdminHeader />
        <AdminSidebar />
        <div className="body-wrapper clearfix">
        <section className="main-container " id="fullscreen">
          <div className="px-4 py-2 border-top-0 emp-dashboard">
            <div className="d-lg-flex d-md-flex d-block py-4">
              <div>
                <h4 className=" mb-0 f-21 text-capitalize font-weight-bold">Update Permission</h4>
              </div>
            </div>
            {/* {
              auth?.user?.permissionId?.permission?.update === true ? */}
              <AdminPermissionEditModal />
              {/* :
              <NotAllowed />
            } */}
          </div>
        </section>
      </div>
    </>
  )
}
export default AdminPermissionEditModalPage