import React from 'react'
import DriverDashboardTable from '../driverDashboardComponents/DriverDashboardTable'

const DriverOrderTodayOrderComponent = () => {
  return (
    <>
      <DriverDashboardTable />
    </>
  )
}
export default DriverOrderTodayOrderComponent