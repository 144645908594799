import {
  // ADMIN_COMPANY_INFO_VIEW_REQUEST,
  // ADMIN_COMPANY_INFO_VIEW_SUCCESS,
  // ADMIN_COMPANY_INFO_VIEW_FAIL,
  EXTERNAL_COMPANIES_GET_REQUEST,
  EXTERNAL_COMPANIES_GET_SUCCESS,
  EXTERNAL_COMPANIES_GET_FAIL,
  // VEHICLE_CREATE_REQUEST,
  // VEHICLE_CREATE_SUCCESS,
  // VEHICLE_CREATE_FAIL,
  COMPANY_UPDATE_REQUEST,
  COMPANY_UPDATE_SUCCESS,
  COMPANY_UPDATE_FAIL,
} from "../constants";

export const adminCompanyInfoReducer = (
  state = {
    adminCompanyInfo: {
      adminCompanyInfo: null,
    },
  },
  action
) => {
  switch (action.type) {
    // ADMIN COMPANY INFO VIEW TABLE
    // case ADMIN_COMPANY_INFO_VIEW_REQUEST:
    //   return {
    //     ...state,
    //   };
    // case ADMIN_COMPANY_INFO_VIEW_SUCCESS:
    //   return {
    //     ...state,
    //     adminCompanyInfo: action.payload,
    //   };
    // case ADMIN_COMPANY_INFO_VIEW_FAIL:
    //   return {
    //     ...state,
    //     error: action.payload,
    //   };

    // VEHICLE CREATE
    // case VEHICLE_CREATE_REQUEST :
    // return {
    // ...state,
    // }
    // case VEHICLE_CREATE_SUCCESS :
    // return {
    // ...state,
    // vehicle: [...state.vehicle, action.payload],
    // }
    // case VEHICLE_CREATE_FAIL :
    // return {
    // ...state,
    // error: action.payload,
    // }

    default:
      return state;
  }
};

export const externalCompaniesReducer = (
  state = {
    list: [],
  },
  action
) => {
  switch (action.type) {
    // ADMIN COMPANY INFO VIEW TABLE
    case EXTERNAL_COMPANIES_GET_REQUEST:
      return {
        ...state,
      };
    case EXTERNAL_COMPANIES_GET_SUCCESS:
      return {
        ...state,
        list: action.payload,
      };
    case EXTERNAL_COMPANIES_GET_FAIL:
      return {
        ...state,
        error: action.payload,
      };


      // case COMPANY_UPDATE_REQUEST :
      //   return {
      //   ...state,
      // }
      // case COMPANY_UPDATE_SUCCESS :
      //   return {
      //     ...state,
      //     adminCompanyInfo: state.adminCompanyInfo.map 
      //     // adminCompanyInfo: state.adminCompanyInfo.map((x) => 
      //       // x.trip?.id === action.payload.id ? {adminCompanyInfo: x.adminCompanyInfo, trip: action.payload} : x
        
      //       // )
      //     // ]
      //     // todayReservationView: [action.payload.id]
      //     // readTrip: action.payload,
      // }
      // case COMPANY_UPDATE_FAIL :
      //   return {
      //     ...state,
      //     error: action.payload,
      // }

    default:
      return state;
  }
};
