import React from "react";
import ExcelImport from "../../../ExcelImport";
import AdminSidebar from "../../../components/adminComponents/adminHeaderSidebarTitleComponents/AdminSidebar";
import AdminHeader from "../../../components/adminComponents/adminHeaderSidebarTitleComponents/AdminHeader";

const AdminRouteCalculatorReport = () => {
  return (
    <>
      <AdminSidebar />
      <AdminHeader />
      {/* <div>AdminCalculatorReport</div> */}

      {/* <div className="col-12 col-lg-12"> */}
      <div className="body-wrapper clearfix">
        <section className="main-container " id="fullscreen">
          <div className="px-4 py-2 border-top-0 emp-dashboard">
            <div className="settings-box rounded">
              <div className="s-b-inner s-b-notifications bg-white b-shadow-4 rounded">
                <div className="s-b-n-header d-lg-flex justify-content-between card-header border-bottom-grey">
                  <div className="s-b-n-header" id="tabs">
                    <h2 className="mb-0 p-20 f-21 font-weight-normal text-capitalize">
                      <span>REPORTS</span>
                    </h2>
                  </div>
                </div>
                <ExcelImport />
              </div>
            </div>
          </div>
        </section>
      </div>
      {/* </div> */}
    </>
  );
};

export default AdminRouteCalculatorReport;
