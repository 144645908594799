import React from 'react'
import DriverWeeklyEarningComponent from './DriverWeeklyEarningComponent'
import DriverDailyEarningComponent from './DriverDailyEarningComponent'

const DriverEarningComponent = () => {
  return (
    <>
      <div className="row">
        <div className="col-md-6">
            <DriverWeeklyEarningComponent />
          {/* <div className="form-group my-3">
            <label className="f-14 text-dark mb-12" data-label="" htmlFor="Name"> Weekly Earnings</label>
          </div> */}
        </div>
        <div className="col-md-6">
            <DriverDailyEarningComponent />
          {/* <div className="form-group my-3">
            <label className="f-14 text-dark mb-12" data-label="" htmlFor="Name"> $ {reservation?.driverEarning?.weeklyEarning?.earning ? reservation?.driverEarning?.weeklyEarning?.earning.toFixed(2) : 0.0} </label>
          </div> */}
        </div>
      </div>
    </>
  )
}
export default DriverEarningComponent