/** @format */

import React, { useState } from "react";
import AdminBookingUploadExcelData from "../../../components/adminComponents/adminBookingComponents/AdminBookingUploadExcelData";
import axios from "axios";
// import { NavLink } from 'react-router-dom';
import XLSX from "sheetjs-style";
// import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';
import { SERVERADDRESS } from "../../../constants";
import Dropzone from "react-dropzone";
import ProgressBar from "react-bootstrap/ProgressBar";
import { notifyFailure, notifySuccess } from "../../../components/toast";
import { useSelector } from "react-redux";

function AdminRouteUploadFileModal({
  showDropDown,
  toggleUploadFileModal,
  setShowUploadFileModal,
}) {
  const [loading, setLoading] = useState(null);
  const [file, setFile] = useState(null);
  const [data, setData] = useState([]);
  const [progress, setProgress] = useState(0);
  const auth = useSelector((state) => state.auth);

  const handleSelectFile = (e) => {
    const acceptedFiles = e.target.files;
    if (acceptedFiles.length > 0) {
      const file = acceptedFiles[0];
      const allowedMimeTypes = [
        "application/vnd.ms-excel", // MIME type for .xls files
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", // MIME type for .xlsx files
      ];
      if (allowedMimeTypes.includes(file.type)) {
        // The dropped file has a valid MIME type for Excel files
        setFile(file);
      } else {
        // Display an error message or handle invalid file types
        // console.error("Invalid file type. Please upload an Excel file.");
        notifyFailure("Invalid file type. Please upload a .xls file.");
      }
    }
  };
  const handleFileUpload = async () => {
    try {
      setLoading(true);
      setProgress(0);
      setData([]);
      const reader = new FileReader();
      reader.onload = async (event) => {
        // console.log("If =>");
        const binaryData = event.target.result;
        const workbook = XLSX.read(binaryData, { type: "binary" });
        const worksheet = workbook.Sheets[workbook.SheetNames[0]];
        let dataImport = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
        dataImport.shift();
        dataImport = dataImport.filter((subArray) => subArray.length > 0);
        const groupedData = {};
        dataImport.forEach((item) => {
          const key = `${item[0]}`;
          if (!groupedData[key]) {
            groupedData[key] = [];
          }
          groupedData[key].push(item);
        });
        dataImport = Object.values(groupedData);
        let temp = [];
        // dataImport[0][15] = "63cb14f53f55723694dbd185"
        // for (let i = 0; i < dataImport.length ; i++){
        let index = 0;
        for (const e of dataImport) {
          let imp = dataImport[index];
          imp[0][15] = auth?.user?.companyId?.id;
          imp[0][16] = auth?.user?.id;
          const { data } = await axios.post(
            // `${SERVERADDRESS}/v2/route/importRoute`,
            `${SERVERADDRESS}/v3/route/importRoute`,
            { dataImport: imp }
          );
          temp.push(...data);
          setProgress(parseInt(((index + 1) / dataImport.length) * 100));
          index++;
          await new Promise((resolve) => setTimeout(resolve, 2000));
        }
        setLoading(false);
        setData(temp);
        setFile(null);
        notifySuccess("File Uploaded Successfully!");
        toggleUploadFileModal();
        setShowUploadFileModal(false);
        // dataImport[0][15] = auth?.user?.companyId?.id;
        // if (dataImport.length <= 25) {
        //   const { data } = await axios.post(
        //     `${SERVERADDRESS}/v1/route/importRoute`,
        //     { dataImport }
        //   );
        //   setLoading(false);
        //   setProgress(25);
        //   setData(data);
        //   // console.log("If =>");
        //   setFile(null);
        //   notifySuccess("File Uploaded Successfully!");
        //   toggleUploadFileModal();
        // } else {
        //   let temp = [];
        //   const result = new Array(Math.ceil(dataImport.length / 25))
        //     .fill()
        //     .map((_) => dataImport.splice(0, 25));
        //   for (let i = 0; i < result.length; i++) {
        //     let imp = result[i];
        //     imp[0][15] = auth?.user?.companyId?.id;
        //     const { data } = await axios.post(
        //       `${SERVERADDRESS}/v1/route/importRoute`,
        //       { dataImport: imp }
        //     );
        //     temp.push(...data);
        //     setProgress(parseInt(((i + 1) / result.length) * 100));
        //   }
        //   setLoading(false);
        //   setData(temp);
        //   // console.log("else =>");
        //   setFile(null);
        //   notifySuccess("File Uploaded Successfully!");
        //   toggleUploadFileModal();
        // }
      };
      reader.readAsBinaryString(file);
      // console.log("Handle File Upload =>");
    } catch (err) {
      // console.log(err);
      notifyFailure(err.response.data.message);
      setLoading(false);
    }
  };

  const handleClearFile = () => {
    setFile(null);
    setData([]);
  };

  return (
    <div
      className={`modal fade ${showDropDown === true ? "show" : "hide"}`}
      // className={`modal-backdrop fade ${openModal ? "show" : "hide"}`}

      // id="uploadDoc"
      // tabIndex="-1"
      role="dialog"
      // aria-labelledby="exampleModalCenterTitle"
      style={{ display: "block", paddingRight: "10px" }}
      // aria-modal="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLongTitle">
              {" "}
              Upload File
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={toggleUploadFileModal}
            >
              <span aria-hidden="true">x</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="row">
              <div className="col-md-12">
                <div className="form-group my-3">
                  <label
                    className="f-14 text-dark-grey mb-12"
                    data-label=""
                    htmlFor="cname"
                  >
                    Upload Your CSV / XLS File{" "}
                    <sup className="text-danger">*</sup>
                  </label>
                  <input
                    type="file"
                    className="input-group-text  f-14"
                    name=""
                    id=""
                    style={{ width: "100%" }}
                    autocomplete="off"
                    onChange={handleSelectFile}
                    // value={file}
                  />
                </div>
                {loading && (
                  <ProgressBar
                    now={progress}
                    min={0}
                    max={100}
                    variant="success"
                    animated={true}
                    striped={true}
                  />
                )}
              </div>
              {/* <AdminBookingUploadExcelData /> */}
              {/* <div className="col-md-12">
                <div className="text-start">
                  <button
                    type="button"
                    className="btn-primary rounded f-14 p-2 mr-3"
                  >
                    Save
                  </button>
                  <button
                    type="button"
                    className="btn-secondary rounded f-14 p-2 mr-3"
                  >
                    Reset
                  </button>
                </div>
              </div> */}
            </div>
          </div>
          <div className="modal-footer">
            <div className="col-md-12">
              <div className="text-end">
                <button
                  type="button"
                  className="btn-primary rounded f-14 p-2 mr-3"
                  onClick={handleFileUpload}
                  disabled={!file}
                >
                  Upload
                </button>
                {/* <button
                  type="button"
                  className="btn-secondary rounded f-14 p-2 mr-3"
                  onClick={handleClearFile}
                >
                  Reset
                </button> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdminRouteUploadFileModal;
