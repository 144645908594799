/** @format */

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { useNavigate } from "react-router-dom";
// import { getExternalCompanies } from "../../../actions/companyAction";
import {
  editClientAction,
  getAllDriverWithoutPagination,
} from "../../../actions/userActions";
import MyComponent from "../Map/Map";
import defaultimg from "../../../assets/images/profile.jpg";
import AWS from "aws-sdk";
import axios from "axios";
import {
  BUCKET_ACCESS_KEY_ID,
  BUCKET_BASE_URL,
  BUCKET_ENDPOINT,
  BUCKET_NAME,
  BUCKET_REGION,
  BUCKET_SECRET_KEY,
  SERVERADDRESS,
} from "../../../constants";
import AdminPinModal from "./AdminPinModal";

// const S3_BUCKET = 'ridetifyportal';
// // const REGION ='YOUR_DESIRED_REGION_HERE';

// AWS.config.update({
//     accessKeyId: 'AKIAULUGR4XCNQWUXPWO',
//     secretAccessKey: 'kYwCYRbw3x64+HtBl8YBsS1p/zDeivp0DHWbxhLY'
// })

// const myBucket = new AWS.S3({
//     params: { Bucket: S3_BUCKET},
//     // region: REGION,
// })

const S3_BUCKET = BUCKET_NAME;
// const REGION ='YOUR_DESIRED_REGION_HERE';

AWS.config.update({
  accessKeyId: BUCKET_ACCESS_KEY_ID,
  secretAccessKey: BUCKET_SECRET_KEY,
});

const myBucket = new AWS.S3({
  params: { Bucket: S3_BUCKET },
  region: BUCKET_REGION,
  endpoint: BUCKET_ENDPOINT,
  // region: REGION,
});

const AdminUserEditNewModal = ({ showDropDown, openDropDown, current }) => {
  const auth = useSelector((state) => state.auth);
  const users = useSelector((state) => state.users);
  const { loading } = users;
  const permission = useSelector((state) => state.permission);
  const externalCompanies = useSelector((state) => state.externalCompanies);
  const [awsURL, setAwsURL] = useState(null);
  const [progress, setProgress] = useState(0);
  const [selectedImage, setSelectedImage] = useState(defaultimg);

  const [values, setValues] = useState({
    id: "",
    // companyId: '',
    // userId: '',
    firstName: "",
    lastName: "",
    jobTitle: "",
    email: "",
    username: "",
    phone: "",
    address: "",
    city: "",
    state: "",
    zip: "",
    summary: "",
    password: "",
    permissionId: "",
    isDriver: "",
    disable: "",
    vehicleId: "",
    pinToken: "",
    from: "",
    to: "",
    // profilePic: '',
  });

  const handleChange = (fieldName) => (event) => {
    setValues({
      ...values,
      [fieldName]: event.target.value,
    });
  };
  const currentUser = async () => {
    try {
      setValues({
        id: "",
        // companyId: '',
        // userId: '',
        firstName: "",
        lastName: "",
        jobTitle: "",
        email: "",
        username: "",
        phone: "",
        address: "",
        city: "",
        state: "",
        zip: "",
        summary: "",
        password: "",
        disable: "",
        // profilePic: '',
      });
      setValues({
        // id: current.id,
        // companyId: current.companyId,
        id: current.id,
        firstName: current.firstName,
        lastName: current.lastName,
        email: current.email,
        jobTitle: current.jobTitle,
        username: current.username,
        phone: current.phone,
        address: current.address,
        city: current.city,
        state: current.state,
        zip: current.zip,
        summary: current.summary,
        // profilePic: current.profilePic,
        password: "",
        permissionId: current.permissionId,
        isDriver: current?.isDriver,
        disable: current?.disable,
        vehicleId: current?.vehicleId ? current?.vehicleId : "",
        isCompanyDriverForZero: current?.isCompanyDriverForZero
          ? current?.isCompanyDriverForZero?.toString()
          : "",
        ...(current?.availbilityTime && {
          from: current.availbilityTime.from,
          to: current.availbilityTime.to,
        }),
      });
      if (current.profilePic) {
        setSelectedImage(current.profilePic.fileUrl);
      }
      setIsDriver(current?.isDriver);
    } catch (error) {
      // console.log(error);
    }
  };

  useEffect(() => {
    currentUser();
  }, [current]);
  const dispatch = useDispatch();

  const handleSuccess = () => {
    openDropDown();
  };

  // console.log(values,"values")
  // console.log(current, "<current>");
  // console.log(values, "values");
  const handleChangePic = async (event) => {
    const today = new Date();
    const date = today.getTime();
    const params = {
      Bucket: S3_BUCKET,
      // Key: "data/"+auth.user.companyId.companyName+"/ProfilePic/"+auth.user.id+"/"+event.target.files[0].name,
      Key:
        "data/" +
        auth.user.companyId.companyName +
        "/ProfilePic/" +
        date +
        "/" +
        event.target.files[0].name,
      Body: event.target.files[0],
      ACL: "public-read",
    };
    var upload = myBucket
      .upload(params)
      .on("httpUploadProgress", (evt) => {
        setProgress(Math.round((evt.loaded / evt.total) * 100));
      })
      .promise();
    let url;
    await upload.then(function (data, err) {
      try {
        url = BUCKET_BASE_URL + data.Key;
        setAwsURL(url);
        // console.log(url)
      } catch (error) {
        // console.log(error, err, "ee");
      }
    });
    const { data } = await axios.post(
      `${SERVERADDRESS}/v1/fileupload/profilePicUpload`,
      {
        url: url,
        file: event.target.files[0].name,
        type: event.target.files[0].type,
        userId: auth.user.id,
        companyId: auth.user.companyId.id,
      }
    );
    setSelectedImage(url);
    dispatch(editClientAction({ userId: current.id, profilePic: data.id }));
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    if (userDataNew.password === "") {
      delete userDataNew.password;
    }
    if (userDataNew?.permissionId?.id) {
      delete userDataNew.permissionId;
    }
    if (userDataNew?.vehicleId === "") {
      delete userDataNew.vehicleId;
    }
    if (userDataNew?.vehicleId === "Unassigned") {
      delete userDataNew.vehicleId;
      userDataNew.removedVehicle = true;
    }
    if (userDataNew?.vehicleId?.id) {
      delete userDataNew.vehicleId;
    }
    if (values.address == current.address) {
      delete userDataNew.completeAddress;
    }
    for (let key of Object.keys(userDataNew)) {
      if (userDataNew[key] === "") {
        delete userDataNew[key];
      }
    }
    dispatch(
      editClientAction(userDataNew, handleSuccess, () => {
        if (userDataNew.isDriver === true) {
          dispatch(
            getAllDriverWithoutPagination({ companyId: auth.user.companyId.id })
          );
        }
      })
    );
    // setTimeout(() => {
    //   if (userDataNew.isDriver === true) {
    //     dispatch(
    //       getAllDriverWithoutPagination({ companyId: auth.user.companyId.id })
    //     );
    //   }
    // }, 1000);
    // setTimeout(() =>
    //     dispatch(editClientAction(userDataNew))
    // , 2000);

    // setValues({
    //   // id: '',
    //   // companyId: '',
    //   userId: '',
    //   firstName: '',
    //   lastName: '',
    //   jobTitle: '',
    //   username: '',
    //   phone: '',
    //   address: '',
    //   city: '',
    //   zip: '',
    //   summary: '',
    // })
  };

  // useEffect(() => {
  //   dispatch(getExternalCompanies({ companyId: auth.user.companyId.id }));
  // }, [auth]);
  let [selectedCompanyId, setSelectedCompanyId] = useState("");
  // const [data, setValues] = useState({});

  // useEffect(() => {
  //   if (auth.user !== null){
  //       // setValues({
  //           // address: auth.user.address,
  //           // state: auth.user.state,
  //           // pinCode: auth.user.pinCode,
  //           // phone: auth.user.phone,
  //       // })
  //       if (current.profilePic) {
  //           setSelectedImage(current.profilePic.fileUrl)
  //       }
  //     }
  //   }, [auth.user])

  const handlePhone = (fieldName) => (event) => {
    const numericValue = event.target.value.replace(/\D/g, "");
    let formattedValue = "";
    if (numericValue.length > 0) {
      formattedValue = `(${numericValue.slice(0, 3)}`;
    }
    if (numericValue.length > 3) {
      formattedValue += `)${numericValue.slice(3, 6)}`;
    }
    if (numericValue.length > 6) {
      formattedValue += `-${numericValue.slice(6, 10)}`;
    }
    setValues({
      ...values,
      [fieldName]: formattedValue,
    });
  };

  // const handlePhone = (fieldName) => (event) => {
  //   if (event.target.value.length === 1) {
  //     if (event.target.value !== "(") {
  //       setValues({
  //         ...values,
  //         [fieldName]: "(" + event.target.value,
  //       });
  //     }
  //   } else if (event.target.value.length === 4) {
  //     if (event.target.value !== ")") {
  //       setValues({
  //         ...values,
  //         [fieldName]: event.target.value + ")-",
  //       });
  //     }
  //   } else if (event.target.value.length === 9) {
  //     if (event.target.value !== "-") {
  //       setValues({
  //         ...values,
  //         [fieldName]: event.target.value + "-",
  //       });
  //     }
  //   } else {
  //     setValues({
  //       ...values,
  //       [fieldName]: event.target.value,
  //     });
  //   }
  // };
  // const alphabets = [
  //   "a",
  //   "b",
  //   "c",
  //   "d",
  //   "e",
  //   "f",
  //   "g",
  //   "h",
  //   "i",
  //   "j",
  //   "k",
  //   "l",
  //   "m",
  //   "n",
  //   "o",
  //   "p",
  //   "q",
  //   "r",
  //   "s",
  //   "t",
  //   "u",
  //   "v",
  //   "w",
  //   "x",
  //   "y",
  //   "z",
  //   "A",
  //   "B",
  //   "C",
  //   "D",
  //   "E",
  //   "F",
  //   "G",
  //   "H",
  //   "I",
  //   "J",
  //   "K",
  //   "L",
  //   "M",
  //   "N",
  //   "O",
  //   "P",
  //   "Q",
  //   "R",
  //   "S",
  //   "T",
  //   "U",
  //   "V",
  //   "W",
  //   "X",
  //   "Y",
  //   "Z",
  //   "+",
  //   "-",
  //   "/",
  //   "*",
  //   "!",
  //   "@",
  //   "#",
  //   "$",
  //   "%",
  //   "^",
  //   "&",
  //   "_",
  //   "(",
  //   ")",
  //   ",",
  //   ".",
  // ];

  //   const userData = {
  //     userId: data.id,
  //     firstName: data.firstName,
  //     lastName: data.lastName,
  //     // email: data.email,

  //     jobTitle: data.jobTitle,
  //     username: data.username,

  //     phone: data.phone,
  //     // workPhone: values2.workPhone,
  //     // otherPhone: values2.otherPhone,
  //     // clientWorkInformation: values2.clientWorkInformation,
  //     address: data.address,
  //     // state: values2.state,
  //     city: data.city,
  //     zip: data.zip,
  //     summary: data.summary,
  //   };
  //   dispatch(editClientAction(userData));
  // };
  const autoPassword = () => {
    let generatedPassword = Math.random().toString(36).substring(2, 9) + 1;
    setValues({
      ...values,
      ["password"]: generatedPassword,
    });
  };
  // console.log(values)
  const [isDriver, setIsDriver] = useState(false);
  const vehicle = useSelector((state) => state.vehicle);
  const userDataNew = {
    userId: values.id,
    firstName: values.firstName,
    lastName: values.lastName,
    email: values.email,
    jobTitle: values.jobTitle,
    username: values.username,
    phone: values.phone,
    address: values.address,
    city: values.city,
    state: values.state,
    zip: values.zip,
    summary: values.summary,
    // profilePic: values.profilePic,
    password: values.password,
    permissionId: values.permissionId,
    isDriver: isDriver,
    disable: values.disable,
    // vehicleId: values.vehicleId,
    vehicleId: isDriver === true ? values.vehicleId : "",
    isCompanyDriverForZero:
      isDriver === true ? values.isCompanyDriverForZero : "",
    ...(isDriver === true &&
      values.from &&
      values.to && {
        availbilityTime: {
          from: values.from,
          to: values.to,
        },
      }),
    completeAddress: {
      address: values.address,
      latitude: values.latitude,
      longitude: values.longitude,
      map: values.map,
      city: values.city,
      state: values.state,
      zip: values.zip,
    },
  };
  // console.log("values", values);
  // console.log("current", current);
  const [showPin, setShowPin] = useState(false);

  const [showPinDropDown, setShowPinDropDown] = useState(false);
  const [currentPermission, setCurrentPermission] = useState(
    permission?.permission?.[0]?.permission || {}
  );
  const [tempPermission, setTempPermission] = useState();
  const [previousPermissionId, setPreviousPermissionId] = useState(
    userDataNew.permissionId
  );

  // Function to check if a given permission ID should show the PIN field
  const checkPermissionForPin = (permissionId) => {
    const selectedPermission = permission?.permission?.find(
      (current) => current.permission.id === permissionId
    );
    setShowPin(selectedPermission?.permission?.accounting?.isAllow || false);
  };

  // Check initial permission value for showing PIN on component mount
  // useEffect(() => {
  //   if (values?.permissionId?.id) {
  //     checkPermissionForPin(values.permissionId.id);
  //   }
  // }, [values.permissionId.id, permission]);

  const handlePermissionChange = (event) => {
    const selectedPermissionId = event.target.value;
    const selectedPermission = permission?.permission?.find(
      (current) => current.permission.id === selectedPermissionId
    );

    if (selectedPermission?.permission?.accounting?.isAllow) {
      setPreviousPermissionId(values.permissionId.id); // Store the previous permission ID
      setShowPinDropDown(true); // Open the modal if accounting.isAllow
      setTempPermission(selectedPermission?.permission);
    } else {
      setCurrentPermission(selectedPermission?.permission); // Update the permission if needed
      setShowPinDropDown(false); // Close the modal if accounting.isAllow is false
      handleChange("permissionId")(event);
      if (
        selectedPermission?.permission?.myOrder?.isAllow ||
        selectedPermission?.permission?.myRoutes?.isAllow
      ) {
        setIsDriver(true);
      } else {
        setIsDriver(false);
      }
    }
  };

  const handlePinVerification = (isPinCorrect) => {
    if (isPinCorrect) {
      setCurrentPermission(tempPermission);
      handleChange("permissionId")({ target: { value: tempPermission.id } });
    } else {
      setCurrentPermission(
        permission?.permission?.find(
          (current) => current.permission.id === previousPermissionId
        )?.permission || {}
      );
    }
    setShowPinDropDown(false);
  };

  return (
    <>
      <div
        className={`modal fade ${showDropDown ? "show modal-y-axis" : "hide"}`}
        role="dialog"
        data-backdrop="static"
        data-keyboard="false" // To prevent closing with the keyboard
        onClick={(e) => {
          // Check if the click occurred outside the modal content
          if (e.target === e.currentTarget) {
            openDropDown(); // Call your close modal function
          }
        }}
      >
        <div
          className="modal-dialog modal-dialog-centered"
          role="document"
          style={{ top: "8%" }}
        >
          <form onSubmit={handleSubmit}>
            <div className="modal-content user-edit-modal">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLongTitle">
                  {" "}
                  Edit User
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => openDropDown()}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              {/* <br /> */}
              <div className="modal-body">
                {/* <div className="card-img" style={{borderRadius: "50%", margin: "0 auto 30px"}}> */}
                <div className="profile-img-wrap edit-img">
                  {selectedImage && (
                    <img
                      className="inline-block"
                      src={selectedImage}
                      alt="Profile Pic"
                    />
                  )}
                  {/* <img className="inline-block" src={img1} alt="" style={{ border: "solid 1px #616e80"}}/> */}
                  <div className="fileupload btn">
                    <span className="btn-text">edit</span>
                    <input
                      className="upload"
                      type="file"
                      accept=".jpg, .jpeg, .png"
                      onChange={handleChangePic}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-6">
                    <div className="form-group my-3">
                      <label
                        className="f-14 text-dark-grey mb-12"
                        data-label=""
                        htmlFor="after_days"
                      >
                        First Name
                      </label>
                      <sup className="text-danger f-12">*</sup>
                      <input
                        type="text"
                        className="form-control height-35 f-14"
                        placeholder="Enter Your First Name"
                        autoComplete="false"
                        value={values.firstName}
                        name="firstName"
                        onChange={handleChange("firstName")}
                        required
                        maxLength={35}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group my-3">
                      <label
                        className="f-14 text-dark-grey mb-12"
                        data-label=""
                        htmlFor="after_days"
                      >
                        Last Name
                      </label>
                      <sup className="text-danger f-12">*</sup>
                      <input
                        type="text"
                        className="form-control height-35 f-14"
                        placeholder="Enter Your Last Name"
                        autoComplete="false"
                        value={values.lastName}
                        name="lastName"
                        onChange={handleChange("lastName")}
                        required
                        maxLength={35}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group my-3">
                      <label
                        className="f-14 text-dark-grey mb-12"
                        data-label=""
                        htmlFor="after_days"
                      >
                        Email
                      </label>
                      <sup className="text-danger f-12">*</sup>
                      <input
                        type="Email"
                        className="form-control height-35 f-14"
                        placeholder="Enter Your Email"
                        autoComplete="false"
                        value={values.email}
                        name="email"
                        onChange={handleChange("email")}
                        required
                      />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group my-3">
                      <label
                        className="f-14 text-dark-grey mb-12"
                        data-label=""
                        htmlFor="after_days"
                      >
                        Phone Number
                      </label>
                      {/* <input
                        className="form-control height-35 f-14"
                        minLength="14"
                        maxLength="14"
                        // required
                        onKeyDown={(e) => {
                          if (e.ctrlKey && e.code === "KeyV") {
                            return;
                          }
                          if (alphabets.includes(e.key)) {
                            e.preventDefault();
                          }
                          if (
                            e.code === "Backspace" &&
                            values.phone.length === 6
                          ) {
                            setValues({
                              ...values,
                              phone: values.phone.substring(0, 4),
                            });
                          }
                          if (
                            e.code === "Backspace" &&
                            values.phone.length === 2
                          ) {
                            setValues({
                              ...values,
                              phone: "",
                            });
                          }
                          if (
                            e.code === "Backspace" &&
                            values.phone.length === 10
                          ) {
                            setValues({
                              ...values,
                              phone: values.phone.substring(0, 9),
                            });
                          }
                        }}
                        value={values.phone}
                        name="phone"
                        onChange={handlePhone("phone")}
                        placeholder="(123)-456-7890"
                      /> */}
                      <input
                        type="tel"
                        className="form-control height-35 f-14"
                        name="phone"
                        value={values.phone}
                        onChange={handlePhone("phone")}
                        placeholder="(123)456-7890"
                      />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group my-3">
                      <label
                        className="f-14 text-dark-grey mb-12"
                        data-label=""
                        htmlFor="after_days"
                      >
                        Permission
                      </label>
                      <sup className="text-danger f-12">*</sup>
                      <select
                        name="default_task_status"
                        className="form-control select-picker"
                        style={{ height: "39px" }}
                        autoComplete="false"
                        // value={values?.permissionId?.id}
                        // onChange={handleChange("permissionId")}
                        value={
                          values?.permissionId?.id
                            ? values?.permissionId?.id
                            : values?.permissionId
                        }
                        onChange={handlePermissionChange}
                      >
                        {permission?.permission?.length > 0 &&
                          permission?.permission?.map((current, i) => {
                            return (
                              <option value={current?.permission?.id} key={i}>
                                {" "}
                                {current?.permission?.name}
                              </option>
                            );
                          })}
                      </select>
                      {/* <select
                        name="default_task_status"
                        className="form-control select-picker"
                        style={{ height: "39px" }}
                        autoComplete="false"
                        value={values?.permissionId?.id}
                        onChange={handleChange("permissionId")}
                      >
                        {permission?.permission?.length > 0 &&
                          permission?.permission?.map((current, i) => {
                            return (
                              <option value={current?.permission?.id} key={i}>
                                {" "}
                                {current?.permission?.name}
                              </option>
                            );
                          })}
                      </select> */}
                    </div>
                  </div>
                  {/* {showPin && (
                    <div className="col-lg-6">
                      <div className="form-group my-3">
                        <label
                          className="f-14 text-dark-grey mb-12"
                          htmlFor="pin"
                        >
                          PIN
                        </label>
                        <sup className="text-danger f-12">*</sup>
                        <input
                          type="text"
                          className="form-control height-35 f-14"
                          placeholder="****"
                          autoComplete="off"
                          value={values.pin}
                          onChange={handleChange("pin")}
                        />
                      </div>
                    </div>
                  )} */}
                  <div className="col-lg-6">
                    <div className="form-group my-3">
                      <label
                        className="f-14 text-dark-grey mb-12"
                        data-label=""
                        htmlFor="after_days"
                      >
                        Username
                      </label>
                      <sup className="text-danger f-12">*</sup>
                      <input
                        type="text"
                        className="form-control height-35 f-14"
                        placeholder="Enter The Username"
                        autoComplete="false"
                        value={values.username}
                        name="username"
                        onChange={handleChange("username")}
                        required
                      />
                    </div>
                  </div>
                  {/* <div className="col-lg-6">
                    <div className="form-group my-3">
                      <label className="f-14 text-dark-grey mb-12">
                        Is this User Driver?
                      </label>
                      <div className="form-group mb-0">
                        <div className="select-picker">
                          <div className="btn-choice">
                            <label className="switch">
                              <input
                                type="checkbox"
                                className="switch-input"
                                id="toggle"
                                checked={isDriver}
                                onChange={() =>
                                  setIsDriver((isDriver) => !isDriver)
                                }
                              />
                              <span
                                className="switch-label"
                                data-on=""
                                data-off=""
                              ></span>
                              <span className="switch-handle"></span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <br />
                  </div> */}
                  {isDriver === true && (
                    <div className="col-lg-6">
                      <div className="form-group">
                        <label className="f-14 text-dark-grey mb-12">
                          Vehicle#
                        </label>
                        <sup className="text-danger f-12">*</sup>
                        <div className="select-picker">
                          <select
                            name="default_task_status"
                            className="form-control select-picker"
                            style={{ height: "39px" }}
                            // value={typeof(values?.vehicleId) === 'object' ? values?.vehicleId?.id : values?.vehicleId}
                            value={values?.vehicleId?.id}
                            onChange={handleChange("vehicleId")}
                          >
                            <option value="Unassigned"> Unassigned</option>
                            {vehicle?.vehicle?.length > 0 &&
                              vehicle?.vehicle?.map((current, i) => {
                                return (
                                  <option value={current?.id} key={i}>
                                    {" "}
                                    {current?.assetNo}
                                  </option>
                                );
                              })}
                          </select>
                        </div>
                      </div>
                    </div>
                  )}
                  {isDriver === true && (
                    <div className="col-lg-6">
                      <div className="form-group">
                        <label className="f-14 text-dark-grey mb-12">
                          Company Driver
                        </label>
                        <sup className="text-danger f-12">*</sup>
                        <div className="select-picker">
                          <select
                            name="default_task_status"
                            className="form-control select-picker"
                            style={{ height: "39px" }}
                            // value={typeof(values?.vehicleId) === 'object' ? values?.vehicleId?.id : values?.vehicleId}
                            value={values?.isCompanyDriverForZero?.toString()}
                            onChange={handleChange("isCompanyDriverForZero")}
                          >
                            <option value="false"> No</option>
                            <option value="true"> Yes</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  )}
                  {isDriver === true && (
                    <>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <label className="f-14 text-dark-grey mb-12">
                            Working Hours From
                          </label>
                          <input
                            type="time"
                            className="form-control height-35 f-14"
                            placeholder="Enter Hire Date"
                            value={values.from}
                            onChange={handleChange("from")}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <label className="f-14 text-dark-grey mb-12">
                            Working Hours To
                          </label>
                          <input
                            type="time"
                            className="form-control height-35 f-14"
                            placeholder="Enter Hire Date"
                            value={values.to}
                            onChange={handleChange("to")}
                          />
                        </div>
                      </div>
                    </>
                  )}

                  <div className="col-lg-6">
                    <div className="form-group">
                      <label className="f-14 text-dark-grey mb-12">
                        Status
                      </label>
                      <sup className="text-danger f-12">*</sup>
                      <div className="select-picker">
                        {current?.disable === false ? (
                          <select
                            className="form-control select-picker"
                            style={{ height: "39px" }}
                            value={values.disable}
                            onChange={handleChange("disable")}
                          >
                            <option value="false">Active</option>
                            <option value="true">InActive</option>
                          </select>
                        ) : (
                          <select
                            className="form-control select-picker"
                            style={{ height: "39px" }}
                            value={values.disable}
                            onChange={handleChange("disable")}
                          >
                            <option value="false">Active</option>
                            <option value="true">InActive</option>
                          </select>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="col-md-12">
                    <div className="form-group my-3">
                      <label
                        className="f-14 text-dark mb-12"
                        data-label=""
                        htmlFor="Name"
                      >
                        Street Address
                      </label>
                      {/* <sup className="text-danger f-12">*</sup> */}
                      <MyComponent
                        values2={values}
                        setValues2={setValues}
                        addressss={values.address}
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group my-3">
                      <label
                        className="f-14 text-dark mb-12"
                        data-label=""
                        htmlFor="City"
                      >
                        City
                      </label>
                      {/* <sup className="text-danger f-12">*</sup> */}
                      <input
                        type="text"
                        className="form-control height-35 f-14"
                        placeholder=""
                        value={values.city}
                        name="city"
                        onChange={handleChange("city")}
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group my-3">
                      <label
                        className="f-14 text-dark mb-12"
                        data-label=""
                        htmlFor="cname"
                      >
                        State
                      </label>
                      {/* <sup className="text-danger f-12">*</sup> */}
                      <input
                        type="text"
                        className="form-control height-35 f-14"
                        placeholder=""
                        value={values.state}
                        name="state"
                        onChange={handleChange("state")}
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group my-3">
                      <label
                        className="f-14 text-dark mb-12"
                        data-label=""
                        htmlFor="Zipcode"
                      >
                        Zip Code
                      </label>
                      {/* <sup className="text-danger f-12">*</sup> */}
                      <input
                        type="text"
                        className="form-control height-35 f-14"
                        placeholder=""
                        value={values.zip}
                        name="zip"
                        onChange={handleChange("zip")}
                      />
                    </div>
                  </div>
                  <div className="col-md-8">
                    <div className="form-group my-3">
                      <label
                        className="f-14 text-dark mb-12"
                        data-label=""
                        htmlFor="Zipcode"
                      >
                        Password
                      </label>
                      {/* <sup className="text-danger f-12">*</sup> */}
                      <div className="row" style={{ marginLeft: "0.1rem" }}>
                        <input
                          type="text"
                          className="form-control height-35 f-14"
                          placeholder="Must contain atleast 1 number and letter. (min length: 8)"
                          value={values.password}
                          name="password"
                          onChange={handleChange("password")}
                          style={{ width: "70%" }}
                        />
                        &nbsp;
                        <button
                          type="button"
                          className="btn-warning rounded f-14 p-2 mr-3"
                          // style={{position:"relative"}}
                          style={{ width: "20%" }}
                          onClick={autoPassword}
                        >
                          Autogenerate
                        </button>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-12"></div>
                  <br />
                  <div className="col-lg-12">
                    <div
                      className="form-group "
                      style={{ marginTop: "1.5em !important" }}
                    >
                      <button
                        type="submit"
                        className="btn-primary rounded f-14 p-2 mr-3"
                        disabled={loading}
                        // onClick={handleSubmit}
                      >
                        Update
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      {/* <AdminSettingPaymentAddCompanyModal */}
      {/* {...{ showModal, setShowModal, setSelectedCompanyId }} */}
      {/* /> */}
      {showPinDropDown && (
        <AdminPinModal
          openPinDropDown={() => setShowPinDropDown(false)}
          showPinDropDown={showPinDropDown}
          setPermission={handlePinVerification}
          permissions={permission?.permission}
          currentPermission={currentPermission}
          tempPermission={tempPermission}
        />
      )}
    </>
  );
};
export default AdminUserEditNewModal;
