/** @format */

import React, { useState } from "react";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, NavLink } from "react-router-dom";
import {
  allBadges,
  getAllVehicle,
  getAllVehiclesByCompanyId,
} from "../../../actions/vehicleActions";
import AdminSettingVehicleTableRow from "./AdminSettingVehicleTableRow";
import RightModal from "../../RightModal";
import AdminSettingVehicleEditComponent from "./AdminSettingVehicleEditComponent";
import shift1 from "../../../assets/images/load.png";
import { paginationDataToShow } from "../../../actions/generalActions";

const AdminSettingVehicleTable = () => {
  const auth = useSelector((state) => state.auth);
  const vehicle = useSelector((state) => state.vehicle);
  const [showMoreVertDropDown, setshowMoreVertDropDown] = useState(false);
  const openMoreVertDropDown = () => {
    setshowMoreVertDropDown((showMoreVertDropDown) => !showMoreVertDropDown);
  };

  const [modal, setModal] = useState({ open: false, data: null });
  const handleModal = (data) => {
    setModal({ open: !modal.open, data: data });
  };

  const dispatch = useDispatch();
  // const vehicleData = async (e) => {
  //   dispatch(
  //     getAllVehicle({
  //       companyId: auth?.user?.companyId?.id,
  //       // limit: limit,
  //       // page: currentPageNumber,
  //     })
  //   );
  // };

  const allVehicleData = async (e) => {
    dispatch(
      getAllVehiclesByCompanyId({ companyId: auth?.user?.companyId?.id })
    );
  };
  const allBadgesData = async () => {
    dispatch(allBadges({ companyId: auth.user.companyId.id }));
  };
  useEffect(() => {
    // vehicleData();
    allVehicleData();
    allBadgesData();
  }, []);

  const [currentPage, setCurrentPage] = useState(1);
  const [sortField, setSortField] = useState("assetNo");
  const [sortOrder, setSortOrder] = useState("desc"); //asc, desc
  const [searchTerm, setSearchTerm] = useState("");
  const [limit, setLimit] = useState(25); // Set your default limit here

  // Function to handle pagination, sorting, and searching
  const handlePagination = (page, field, order, search, newLimit) => {
    let newPage;
    if (page < 1) {
      newPage = 1;
    } else if (page > paginatedData.totalPages) {
      newPage = paginatedData.totalPages;
    } else {
      newPage = page;
    }
    setCurrentPage(newPage);
    setSortField(field);
    setSortOrder(order);
    setSearchTerm(search);
    setLimit(newLimit);
  };

  const fullData = vehicle?.vehicle;
  // Use the pagination function to get the data for the current page
  const paginatedData = paginationDataToShow(
    limit,
    currentPage,
    fullData,
    sortField,
    sortOrder,
    searchTerm
  );
  // Function to handle sorting icon click
  const handleSortClick = (field, order) => {
    // Call handlePagination with sorting parameters
    handlePagination(currentPage, field, order, searchTerm, limit);
  };
  // Function to determine the color based on the current sorting order
  const getArrowColor = (field, direction) => {
    return sortField === field && sortOrder === direction
      ? "#000000"
      : "#A0A0A0";
  };
  return (
    <>
      <div className="d-flex flex-column w-tables rounded mt-3 bg-white">
        <div className="d-flex ">
          <div className="flex-grow-1">
            <div className="dataTables_length d-flex" id="leads-table_length">
              <div className="p-2">
                <label>Show entries</label>
              </div>
              <label>
                <select
                  name="leads-table_length"
                  aria-controls="leads-table"
                  className="custom-select custom-select-sm form-control form-control-sm"
                  autoComplete="false"
                  value={limit} // Make sure to set the value attribute to the current limit
                  onChange={(e) =>
                    handlePagination(
                      1,
                      sortField,
                      sortOrder,
                      searchTerm,
                      parseInt(e.target.value)
                    )
                  }
                >
                  {[10, 25, 50, 100].map((current, i) => {
                    return (
                      <option value={current} key={i}>
                        {current}
                      </option>
                    );
                  })}
                </select>
              </label>
            </div>
          </div>
          <div className="task-search task-search-new d-flex py-1 px-lg-3 px-0 align-items-center">
            <form className="w-100 mr-1 mr-lg-0 mr-md-1 ml-md-1 ml-0 ml-lg-0" onSubmit={(e) => e.preventDefault()}>
              <div className="input-group bg-grey rounded">
                <div className="input-group-prepend">
                  <span className="input-group-text border-0 bg-additional-grey">
                    <i className="fa fa-search f-13 text-dark-grey"></i>
                  </span>
                </div>
                <input
                  type="text"
                  className="form-control f-14 p-1 border-additional-grey"
                  id="search-text-field"
                  placeholder="Start typing to search"
                  value={searchTerm}
                  onChange={(e) =>
                    handlePagination(
                      1,
                      sortField,
                      sortOrder,
                      e.target.value,
                      limit
                    )
                  }
                />
              </div>
            </form>
          </div>
        </div>
        <div
          id="leads-table_wrapper"
          className="dataTables_wrapper dt-bootstrap4 no-footer"
        >
          <div className="row">
            <div className="col-sm-12">
              <table
                className="table border w-100 dataTable no-footer"
                id="leads-table"
                role="grid"
                aria-describedby="leads-table_info"
                style={{ overflowX: "auto" }}
              >
                <thead className="card-header">
                  <tr role="row">
                    <th
                      title="Vehicle#"
                      className="sorting border-right align-table-header"
                      tabIndex="0"
                      aria-controls="leads-table"
                      rowSpan="1"
                      colSpan="1"
                      aria-label="Mobile: activate to sort column ascending"
                    >
                      Vehicle# <br />
                      <span className="right-sorting-new">
                        <i
                          className="fas fa-long-arrow-alt-up"
                          style={{ color: getArrowColor("assetNo", "asc") }}
                          onClick={() => handleSortClick("assetNo", "asc")}
                        ></i>
                        <i
                          className="fas fa-long-arrow-alt-down"
                          style={{ color: getArrowColor("assetNo", "desc") }}
                          onClick={() => handleSortClick("assetNo", "desc")}
                        ></i>
                      </span>
                    </th>
                    <th
                      title="Type"
                      className="sorting_disabled border-right align-table-header"
                      rowSpan="1"
                      colSpan="1"
                      aria-label="Next Follow Up"
                    >
                      Type <br />
                      <span className="right-sorting-new">
                        <i
                          className="fas fa-long-arrow-alt-up"
                          style={{
                            color: getArrowColor("vehicleOwnership", "asc"),
                          }}
                          onClick={() =>
                            handleSortClick("vehicleOwnership", "asc")
                          }
                        ></i>
                        <i
                          className="fas fa-long-arrow-alt-down"
                          style={{
                            color: getArrowColor("vehicleOwnership", "desc"),
                          }}
                          onClick={() =>
                            handleSortClick("vehicleOwnership", "desc")
                          }
                        ></i>
                      </span>
                    </th>
                    <th
                      title="Service"
                      className="sorting_disabled border-right align-table-header min-width-orderId"
                      rowSpan="1"
                      colSpan="1"
                      aria-label="Next Follow Up"
                    >
                      Service
                      {/* Type  */} <br />
                      <span className="right-sorting-new">
                        <i
                          className="fas fa-long-arrow-alt-up"
                          style={{
                            color: getArrowColor("serviceType.name", "asc"),
                          }}
                          onClick={() =>
                            handleSortClick("serviceType.name", "asc")
                          }
                        ></i>
                        <i
                          className="fas fa-long-arrow-alt-down"
                          style={{
                            color: getArrowColor("serviceType.name", "desc"),
                          }}
                          onClick={() =>
                            handleSortClick("serviceType.name", "desc")
                          }
                        ></i>
                      </span>
                    </th>
                    {/* <th title="Model Type" className="sorting border-right" tabIndex="0" aria-controls="leads-table" rowSpan="1" colSpan="1" aria-label="Lead Agent: activate to sort column ascending">
                      Model Type
                    </th> */}
                    <th
                      title="Make"
                      className="sorting border-right align-table-header"
                      tabIndex="0"
                      aria-controls="leads-table"
                      rowSpan="1"
                      colSpan="1"
                      aria-label="Lead Agent: activate to sort column ascending"
                    >
                      Make <br />
                      <span className="right-sorting-new">
                        <i
                          className="fas fa-long-arrow-alt-up"
                          style={{ color: getArrowColor("make", "asc") }}
                          onClick={() => handleSortClick("make", "asc")}
                        ></i>
                        <i
                          className="fas fa-long-arrow-alt-down"
                          style={{ color: getArrowColor("make", "desc") }}
                          onClick={() => handleSortClick("make", "desc")}
                        ></i>
                      </span>
                    </th>
                    <th
                      title="Model"
                      className="sorting border-right align-table-header"
                      tabIndex="0"
                      aria-controls="leads-table"
                      rowSpan="1"
                      colSpan="1"
                      aria-label="Lead Agent: activate to sort column ascending"
                    >
                      Model <br />
                      <span className="right-sorting-new">
                        <i
                          className="fas fa-long-arrow-alt-up"
                          style={{ color: getArrowColor("model", "asc") }}
                          onClick={() => handleSortClick("model", "asc")}
                        ></i>
                        <i
                          className="fas fa-long-arrow-alt-down"
                          style={{ color: getArrowColor("model", "desc") }}
                          onClick={() => handleSortClick("model", "desc")}
                        ></i>
                      </span>
                    </th>
                    <th
                      title="Year"
                      className="sorting border-right align-table-header"
                      tabIndex="0"
                      aria-controls="leads-table"
                      rowSpan="1"
                      colSpan="1"
                      aria-label="Lead Agent: activate to sort column ascending"
                    >
                      Year <br />
                      <span className="right-sorting-new">
                        <i
                          className="fas fa-long-arrow-alt-up"
                          style={{ color: getArrowColor("year", "asc") }}
                          onClick={() => handleSortClick("year", "asc")}
                        ></i>
                        <i
                          className="fas fa-long-arrow-alt-down"
                          style={{ color: getArrowColor("year", "desc") }}
                          onClick={() => handleSortClick("year", "desc")}
                        ></i>
                      </span>
                    </th>
                    <th
                      title="VIN"
                      className="sorting border-right align-table-header"
                      tabIndex="0"
                      aria-controls="leads-table"
                      rowSpan="1"
                      colSpan="1"
                      aria-label="Lead Agent: activate to sort column ascending"
                    >
                      VIN <br />
                      <span className="right-sorting-new">
                        <i
                          className="fas fa-long-arrow-alt-up"
                          style={{ color: getArrowColor("vin", "asc") }}
                          onClick={() => handleSortClick("vin", "asc")}
                        ></i>
                        <i
                          className="fas fa-long-arrow-alt-down"
                          style={{ color: getArrowColor("vin", "desc") }}
                          onClick={() => handleSortClick("vin", "desc")}
                        ></i>
                      </span>
                    </th>
                    <th
                      title="Plate"
                      className="sorting border-right align-table-header"
                      tabIndex="0"
                      aria-controls="leads-table"
                      rowSpan="1"
                      colSpan="1"
                      aria-label="Lead Agent: activate to sort column ascending"
                    >
                      Plate <br />
                      <span className="right-sorting-new">
                        <i
                          className="fas fa-long-arrow-alt-up"
                          style={{ color: getArrowColor("numberPlate", "asc") }}
                          onClick={() => handleSortClick("numberPlate", "asc")}
                        ></i>
                        <i
                          className="fas fa-long-arrow-alt-down"
                          style={{
                            color: getArrowColor("numberPlate", "desc"),
                          }}
                          onClick={() => handleSortClick("numberPlate", "desc")}
                        ></i>
                      </span>
                    </th>
                    <th
                      title="Mileage"
                      className="sorting border-right align-table-header"
                      tabIndex="0"
                      aria-controls="leads-table"
                      rowSpan="1"
                      colSpan="1"
                      aria-label="Lead Agent: activate to sort column ascending"
                    >
                      Mileage <br />
                      <span className="right-sorting-new">
                        <i
                          className="fas fa-long-arrow-alt-up"
                          style={{ color: getArrowColor("mileage", "asc") }}
                          onClick={() => handleSortClick("mileage", "asc")}
                        ></i>
                        <i
                          className="fas fa-long-arrow-alt-down"
                          style={{ color: getArrowColor("mileage", "desc") }}
                          onClick={() => handleSortClick("mileage", "desc")}
                        ></i>
                      </span>
                    </th>
                    <th
                      title="Badge(s)"
                      className="sorting border-right align-table-header"
                      tabIndex="0"
                      aria-controls="leads-table"
                      rowSpan="1"
                      colSpan="1"
                      aria-label="Lead Agent: activate to sort column ascending"
                    >
                      Badge(s){" "}
                      {/* <span className="right-sorting-new">
                        <i
                          className="fas fa-long-arrow-alt-up"
                          style={{ color: getArrowColor("color", "asc") }}
                          onClick={() => handleSortClick("color", "asc")}
                        ></i>
                        <i
                          className="fas fa-long-arrow-alt-down"
                          style={{ color: getArrowColor("color", "desc") }}
                          onClick={() => handleSortClick("color", "desc")}
                        ></i>
                      </span> */}
                    </th>
                    <th
                      title="Actions"
                      className="text-right pr-20 sorting_disabled border-right align-table-header"
                      rowSpan="1"
                      colSpan="1"
                      aria-label="Action"
                    >
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {vehicle &&
                    vehicle?.vehicle &&
                    vehicle?.vehicle?.length > 0 &&
                    paginatedData?.results.map((current, i) => {
                      // if (current.delete === false) {
                      return (
                        <AdminSettingVehicleTableRow
                          current={current}
                          i={i}
                          key={i}
                          handleModal={handleModal}
                        />
                      );
                      // }
                    })}
                </tbody>
              </table>
              {vehicle &&
                vehicle.vehicle &&
                paginatedData?.results?.length === 0 && (
                  <div
                    className="dash-stats-list"
                    style={{ textAlign: "center" }}
                  >
                    <br />
                    <br />
                    <img
                      alt=""
                      src={shift1}
                      style={{ width: "145px", opacity: "0.5" }}
                    />
                    <br />
                    <p style={{ opacity: "0.5" }}>No Data Found</p>
                  </div>
                )}
              <div
                id="leads-table_processing"
                className="dataTables_processing card"
                style={{ display: "none" }}
              >
                Processing...
              </div>
            </div>
          </div>
          {vehicle &&
            vehicle?.vehicle &&
            vehicle?.vehicle?.length > 0 &&
            paginatedData?.results?.length > 0 && (
              <div className="d-flex ">
                {vehicle.loading === false ? (
                  <div className="flex-grow-1">
                    <div
                      className="dataTables_info"
                      id="leads-table_info"
                      role="status"
                      aria-live="polite"
                    >
                      Showing{" "}
                      {1 +
                        paginatedData.limit *
                          (paginatedData?.currentPageNumber - 1)}{" "}
                      to{" "}
                      {paginatedData.displayingResults +
                        paginatedData.limit *
                          (paginatedData?.currentPageNumber - 1)}{" "}
                      of {paginatedData.totalResults} entries
                    </div>
                  </div>
                ) : (
                  <div className="flex-grow-1">
                    <div
                      className="dataTables_info"
                      id="leads-table_info"
                      role="status"
                      aria-live="polite"
                    >
                      Loading entries
                    </div>
                  </div>
                )}
                <div>
                  <div
                    className="dataTables_paginate paging_simple_numbers"
                    id="leads-table_paginate"
                  >
                    <ul className="pagination">
                      <li className="page-item">
                        <NavLink
                          className="page-link"
                          to="#"
                          aria-label="Previous"
                          onClick={(e) =>
                            handlePagination(
                              currentPage - 1,
                              sortField,
                              sortOrder,
                              searchTerm,
                              limit
                            )
                          }
                        >
                          <span aria-hidden="true">Previous</span>
                          <span className="sr-only">Previous</span>
                        </NavLink>
                      </li>
                      {vehicle &&
                        vehicle?.vehicle &&
                        paginatedData?.paginationArr &&
                        paginatedData?.paginationArr?.length > 0 &&
                        Array.from(
                          {
                            length:
                              paginatedData.endPage -
                              paginatedData.startPage +
                              1,
                          },
                          (_, i) => {
                            const current = paginatedData?.startPage + i;
                            return paginatedData?.currentPageNumber ===
                              current ? (
                              <li className="page-item active" key={i}>
                                <Link
                                  to=""
                                  className="page-link"
                                  value={current}
                                >
                                  {current}
                                </Link>
                              </li>
                            ) : (
                              <li className="page-item" key={i}>
                                <Link
                                  className="page-link"
                                  value={current}
                                  onClick={(e) =>
                                    handlePagination(
                                      parseInt(current),
                                      sortField,
                                      sortOrder,
                                      searchTerm,
                                      limit
                                    )
                                  }
                                  to=""
                                >
                                  {current}
                                </Link>
                              </li>
                            );
                          }
                        )}
                      <li className="page-item">
                        <NavLink
                          className="page-link"
                          to="#"
                          aria-label="Next"
                          onClick={(e) =>
                            handlePagination(
                              currentPage + 1,
                              sortField,
                              sortOrder,
                              searchTerm,
                              limit
                            )
                          }
                        >
                          <span aria-hidden="true">Next</span>
                          <span className="sr-only">Next</span>
                        </NavLink>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            )}
          {/* <div>
              <div
                className="dataTables_paginate paging_simple_numbers"
                id="leads-table_paginate"
              >
                <ul className="pagination">
                  <li className="page-item">
                    <NavLink
                      className="page-link"
                      to="#"
                      aria-label="Previous"
                      onClick={onClickPrev}
                    >
                      <span aria-hidden="true">Previous</span>
                      <span className="sr-only">Previous</span>
                    </NavLink>
                  </li>
                  {vehicle.vehicle &&
                    vehicle.vehicle.paginationArr &&
                    vehicle.vehicle.paginationArr.length > 0 &&
                    // vehicle.vehicle.paginationArr.map((current, i) => {
                    Array.from({ length: endPage - startPage + 1 }, (_, i) => {
                      const current = startPage + i;
                      return currentPageNumber === current ? (
                        <li className="page-item active" key={i}>
                          <Link to="" className="page-link" value={current}>
                            {current}
                          </Link>
                        </li>
                      ) : (
                        <li className="page-item" key={i}>
                          <Link
                            to=""
                            className="page-link"
                            value={current}
                            onClick={() => handleChangeData(current)}
                          >
                            {current}
                          </Link>
                        </li>
                      );
                    })}
                  <li className="page-item">
                    <NavLink
                      className="page-link"
                      to="#"
                      aria-label="Next"
                      onClick={onClickNext}
                    >
                      <span aria-hidden="true">Next</span>
                      <span className="sr-only">Next</span>
                    </NavLink>
                  </li>
                </ul>
              </div>
            </div> */}
        </div>
      </div>
      <RightModal open={modal.open} handleModal={handleModal}>
        {modal.open && (
          <AdminSettingVehicleEditComponent
            state={modal.data}
            handleModal={handleModal}
          />
        )}
      </RightModal>
    </>
  );
};
export default AdminSettingVehicleTable;
