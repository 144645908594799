/** @format */

import {
  GET_ROUTES_REQUEST,
  GET_ROUTES_SUCCESS,
  GET_ROUTES_FAIL,
  GET_ROUTES_REQUEST_V2,
  GET_ROUTES_SUCCESS_V2,
  GET_ROUTES_FAIL_V2,
  ROUTE_CREATE_REQUEST,
  ROUTE_CREATE_SUCCESS,
  ROUTE_CREATE_FAIL,
  ROUTE_TODAY_ORDER_VIEW_REQUEST,
  ROUTE_TODAY_ORDER_VIEW_SUCCESS,
  ROUTE_TODAY_ORDER_VIEW_FAIL,
  ROUTE_DRIVER_STATUS_UPDATE_REQUEST,
  ROUTE_DRIVER_STATUS_UPDATE_SUCCESS,
  ROUTE_DRIVER_STATUS_UPDATE_FAIL,
  ROUTE_DETAILS_SUCCESS,
  SELECT_ROUTE,
  ROUTE_UPDATE_SUCCESS,
  ROUTETRIP_CREATE_SUCCESS,
  ROUTETRIP_UPDATE_REQUEST,
  ROUTETRIP_UPDATE_SUCCESS,
  ROUTETRIP_UPDATE_FAIL,
  ROUTE_AND_TRIP_REQUEST,
  ROUTE_AND_TRIP_SUCCESS,
  ROUTE_AND_TRIP_FAIL,
  ROUTE_AND_TRIP_REQUEST_V2,
  ROUTE_AND_TRIP_SUCCESS_V2,
  ROUTE_AND_TRIP_FAIL_V2,
  ROUTE_ACTIVE_ORDER_VIEW_REQUEST,
  ROUTE_ACTIVE_ORDER_VIEW_SUCCESS,
  ROUTE_ACTIVE_ORDER_VIEW_FAIL,
  ALL_ROUTE_LOG_VIEW_REQUEST,
  ALL_ROUTE_LOG_VIEW_SUCCESS,
  ALL_ROUTE_LOG_VIEW_FAIL,
  ROUTE_DETAILS_REQUEST,
  UPDATE_UNASSIGNED_ROUTE_REQUEST,
  UPDATE_UNASSIGNED_ROUTE_SUCCESS,
  UPDATE_UNASSIGNED_ROUTE_FAIL,
  EXTERNAL_COMPANY_ROUTES_VIEW_REQUEST,
  EXTERNAL_COMPANY_ROUTES_VIEW_SUCCESS,
  EXTERNAL_COMPANY_ROUTES_VIEW_FAIL,
  ROUTETRIP_CREATE_SUCCESS_MID,
  ROUTETRIP_CREATE_SUCCESS_PM,
  ROUTETRIP_CREATE_SUCCESS_AM,
  TRIPFORDATE_CREATE_SUCCESS_AM,
  TRIPFORDATE_CREATE_SUCCESS_PM,
  TRIPFORDATE_CREATE_SUCCESS_MID,
  TRIPFORDATE_CREATE_REQUEST,
  TRIPFORDATE_CREATE_FAIL,
} from "../constants";

export const routesReducer = (
  state = {
    list: [],
    routes: [],
    loading: true,
    activeRoute: null,
    todayRoute: null,
    markedLocationRoute: null,
    routeDetails: null,
    routeRequest: null,
    routeLog: null,
    externalCompanyRoutes: null,
  },

  action
) => {
  switch (action.type) {
    // ROUTE VIEW TABLE
    case GET_ROUTES_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_ROUTES_SUCCESS:
      return {
        ...state,
        loading: false,
        // routeDetails: null,
        ...action.payload,
      };
    case GET_ROUTES_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    // ROUTE VIEW TABLE V2 WITHOUT PAGINATION
    case GET_ROUTES_REQUEST_V2:
      return {
        ...state,
        loading: true,
      };
    case GET_ROUTES_SUCCESS_V2:
      return {
        ...state,
        loading: false,
        // routeDetails: null,
        routes: action.payload,
      };
    case GET_ROUTES_FAIL_V2:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case ROUTE_TODAY_ORDER_VIEW_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ROUTE_TODAY_ORDER_VIEW_SUCCESS:
      return {
        ...state,
        loading: false,
        todayRoute: action.payload,
      };
    case ROUTE_TODAY_ORDER_VIEW_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case ROUTE_ACTIVE_ORDER_VIEW_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ROUTE_ACTIVE_ORDER_VIEW_SUCCESS:
      return {
        ...state,
        loading: false,
        routeRequest: action.payload,
      };
    case ROUTE_ACTIVE_ORDER_VIEW_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    // ROUTE CREATE
    case ROUTE_CREATE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ROUTE_CREATE_SUCCESS:
      return {
        ...state,
        loading: false,
        list: [...state.list, action.payload],
      };

    // ROUTE DETAILS

    case ROUTE_DETAILS_REQUEST:
      return {
        ...state,

        loading: true,
      };

    case ROUTE_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        routeDetails: action.payload,
      };
    case SELECT_ROUTE:
      return {
        ...state,
        loading: false,
        routeDetails: action.payload,
      };
    case ROUTE_CREATE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case ROUTE_DRIVER_STATUS_UPDATE_REQUEST:
      return {
        ...state,
      };
    case ROUTE_DRIVER_STATUS_UPDATE_SUCCESS:
      return {
        ...state,
        routeRequest: {
          ...state.routeRequest,
          results: state.routeRequest.results
            .map((x) =>
              x.id === action.payload.routeId &&
              action.payload.driverBookingStatus === "Assigned"
                ? {
                    ...x,
                    driverBookingStatus: "Assigned",
                    isAssigned: true,
                    status: "Scheduled",
                  }
                : x
            )
            .filter(
              (x) =>
                !(
                  x.id === action.payload.routeId &&
                  action.payload.driverBookingStatus === "Unassigned"
                )
            ),
        },
      };
    case ROUTE_DRIVER_STATUS_UPDATE_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case ROUTE_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        routes: action.payload.delete
          ? state.routes.filter((x) => x.id != action.payload.id)
          : state.routes.map((x) =>
              x.id == action.payload.id ? action.payload : x
            ),
      };

    case UPDATE_UNASSIGNED_ROUTE_REQUEST:
      return {
        ...state,
      };
    case UPDATE_UNASSIGNED_ROUTE_SUCCESS:
      return {
        ...state,
        loading: false,
        list: state.list.map((x) =>
          x.id == action.payload.id ? action.payload : x
        ),
      };
    case UPDATE_UNASSIGNED_ROUTE_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case "SET_ACTIVE_ROUTE":
      return {
        ...state,
        activeRoute: action.payload,
      };
    case ROUTETRIP_CREATE_SUCCESS:
      return {
        ...state,
        loading: true,
        todayRoute: {
          ...state.todayRoute,
          results: state.todayRoute.results.map((x) =>
            x.route?.id === action.payload.routeId
              ? { route: x.route, trip: action.payload }
              : x
          ),
        },
        // todayRoute: state.todayRoute.map((x) =>
        //   x.route?.id === action.payload.routeId ? {route: x.route, trip: action.payload} : x
        // )
      };
    case ROUTETRIP_CREATE_SUCCESS_AM:
      return {
        ...state,
        loading: false,
        routeDetails: {
          ...state.routeDetails,
          tripAM: action.payload,
        },
      };
    case ROUTETRIP_CREATE_SUCCESS_PM:
      return {
        ...state,
        loading: false,
        routeDetails: {
          ...state.routeDetails,
          tripPM: action.payload,
        },
      };
    case ROUTETRIP_CREATE_SUCCESS_MID:
      return {
        ...state,
        loading: false,
        routeDetails: {
          ...state.routeDetails,
          tripMID: action.payload,
        },
      };
    // TRIP UPDATE
    case TRIPFORDATE_CREATE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case TRIPFORDATE_CREATE_SUCCESS_AM:
      return {
        ...state,
        loading: false,
        routeDetails: {
          ...state.routeDetails,
          tripAM: action.payload,
        },
      };
    case TRIPFORDATE_CREATE_SUCCESS_PM:
      return {
        ...state,
        loading: false,
        routeDetails: {
          ...state.routeDetails,
          tripPM: action.payload,
        },
      };
    case TRIPFORDATE_CREATE_SUCCESS_MID:
      return {
        ...state,
        loading: false,
        routeDetails: {
          ...state.routeDetails,
          tripMID: action.payload,
        },
      };
    case TRIPFORDATE_CREATE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case "MARKED_LOCATIONROUTE":
      return {
        ...state,
        markedLocationRoute: action.payload,
      };

    // TRIP UPDATE
    case ROUTETRIP_UPDATE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ROUTETRIP_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        todayRoute: {
          ...state.todayRoute,
          ...(state.todayRoute.length > 0 && {
            results: state.todayRoute.results.map((x) =>
              x.trip?.id === action.payload.trip.id
                ? { route: action.payload.route, trip: action.payload.trip }
                : x
            ),
          }),
        },
        routeDetails: {
          ...state.routeDetails,
          // trip:
          //   state.routeDetails.trip.id === action.payload.id
          //     ? action.payload
          //     : state.routeDetails.trip,
          tripAM:
            state?.routeDetails?.tripAM?.id === action?.payload?.id
              ? action.payload
              : state.routeDetails?.tripAM,
          tripPM:
            state?.routeDetails?.tripPM?.id === action?.payload?.id
              ? action.payload
              : state.routeDetails?.tripPM,
          tripMID:
            state?.routeDetails?.tripMID?.id === action?.payload?.id
              ? action.payload
              : state.routeDetails?.tripMID,
        },
        // state.todayRoute.map((x) =>
        //   x.trip?.id === action.payload.trip.id ? {route: action.payload.route, trip: action.payload.trip} : x
        // ),
        // routeDetails: action.payload
        // ]
        // todayReservationView: [action.payload.id]
        // readTrip: action.payload,
      };
    case ROUTETRIP_UPDATE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    // ROUTE AND TRIP BY ID
    case ROUTE_AND_TRIP_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ROUTE_AND_TRIP_SUCCESS:
      return {
        ...state,
        loading: false,
        routeDetails: action.payload,
      };
    case ROUTE_AND_TRIP_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    // ROUTE AND TRIP BY ID
    case ROUTE_AND_TRIP_REQUEST_V2:
      return {
        ...state,
        loading: true,
        // routeDetails: null,
      };
    case ROUTE_AND_TRIP_SUCCESS_V2:
      return {
        ...state,
        loading: false,
        routeDetails: action.payload,
      };
    case ROUTE_AND_TRIP_FAIL_V2:
      return {
        ...state,
        error: action.payload,
      };
    case ROUTE_ACTIVE_ORDER_VIEW_REQUEST:
      return {
        ...state,
      };
    case ROUTE_ACTIVE_ORDER_VIEW_SUCCESS:
      return {
        ...state,
        routeLog: action.payload,
      };
    case ROUTE_ACTIVE_ORDER_VIEW_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    // ROUTE LOG TABLE
    case ALL_ROUTE_LOG_VIEW_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ALL_ROUTE_LOG_VIEW_SUCCESS:
      return {
        ...state,
        loading: false,
        routeLog: action.payload,
      };
    case ALL_ROUTE_LOG_VIEW_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case EXTERNAL_COMPANY_ROUTES_VIEW_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case EXTERNAL_COMPANY_ROUTES_VIEW_SUCCESS:
      return {
        ...state,
        loading: false,
        externalCompanyRoutes: action.payload,
      };
    case EXTERNAL_COMPANY_ROUTES_VIEW_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};
