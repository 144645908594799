/** @format */

import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import {
  declinedCall,
  popupClose,
  popupOpen,
  receivedCall,
} from "../../actions/chatActions";
import { useDispatch, useSelector } from "react-redux";
// import ringtune from "../../assets/music/notifications-sound-2.mp3";
import ringtune from "../../assets/music/Iphone.mp3";
import moment from "moment";
import defaultImg from "../../assets/images/profile.jpg";
import groupIcon from "../../assets/images/undraw_People_re_8spw.png";
import {
  ICameraVideoTrack,
  IMicrophoneAudioTrack,
  IAgoraRTCClient,
  IAgoraRTCRemoteUser,
} from "agora-rtc-sdk-ng/esm";

import {
  VERSION,
  createClient,
  createCameraVideoTrack,
  createMicrophoneAudioTrack,
  onCameraChanged,
  onMicrophoneChanged,
} from "agora-rtc-sdk-ng/esm";

onCameraChanged((device) => {
  // console.log("onCameraChanged: ", device);
});
onMicrophoneChanged((device) => {
  // console.log("onMicrophoneChanged: ", device);
});

const client = createClient({
  mode: "rtc",
  codec: "vp8",
});
let audioTrack;
let videoTrack;

function CallPopup({ callStart }) {
  const [callAccepted, setCallAccepted] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [callDetails, setCallDetails] = useState(false);
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const sockets = useSelector((state) => state.sockets);
  const { socketInstance } = sockets;
  const chats = useSelector((state) => state.chats);
  const { callPopup, selectedChat } = chats;
  const [audio] = useState(new Audio(ringtune));
  // const caller = selectedChat?.users?.find((obj) => obj?.id === auth?.user?.id);
  const userInfo = selectedChat?.users?.find(
    (obj) => obj?.id != auth?.user?.id
  );
  // selectedChat?.users?.latestMessage?.sender?.id === auth?.user?.id
  // const userInfo = selectedChat?.users?.
  // selectedChat?.users?.find(
  //   (obj) => obj?.id !== auth?.user?.id
  // );
  useEffect(() => {
    if (socketInstance) {
      socketInstance?.on("incoming-call", (obj) => {
        // setShowPopup(true);
        dispatch(popupOpen());
        setCallDetails(obj);
        // if (audio.paused || audio.currentTime >= audio.duration) {
        //   // Start playing the audio on loop when the call is incoming
        //   audio.loop = true;

        //   // Play the audio in response to a user interaction event
        //   document.addEventListener("click", playAudioHandler);
        // }
        // Start playing the audio when the call is incoming
        audio.pause();
        audio.currentTime = 0;
        audio.play();
      });
      socketInstance?.on("received-call", (obj) => {
        //
        // console.log("received-call", obj);
        setCallAccepted(true);
        dispatch(receivedCall(obj));
      });
      socketInstance?.on("declined-call", (obj) => {
        //
        dispatch(declinedCall(obj));
      });
    }
    // Clean up the socket event listener when the component is unmounted
    return () => {
      if (socketInstance) {
        socketInstance.off("incoming-call");
        // socketInstance.off("incoming");
        // socketInstance.off("received-call");
        // socketInstance.off("declined-call");
      }
    };
  }, [socketInstance]);
  useEffect(() => {
    if (callPopup) {
      setShowPopup(true);
      // playAudioHandler();
    }
  }, [callPopup]); // Re-run the effect when callAccepted changes

  useEffect(() => {
    // Set a timer for 30 seconds to close the popup
    const timerId = setTimeout(() => {
      // Close the popup only if the call is not accepted
      if (!callAccepted) {
        closePopup();
        dispatch(popupClose());
        audio.pause();
        audio.currentTime = 0;
      }
    }, 30000);

    // Clean up the timer when the component is unmounted
    return () => {
      clearTimeout(timerId);
    };
  }, [callAccepted, showPopup]); // Re-run the effect when callAccepted changes

  const acceptCall = async () => {
    // Handle logic for accepting the call
    setCallAccepted(true);
    audio.pause();
    audio.currentTime = 0;
    await joinAndPublishAudio(callDetails?.token, callDetails?.chatId?.id);
    socketInstance.emit("receive-call", {
      _id: callDetails?.messageId,
      // room: callDetails?.chatId?.id,
      receiver: auth?.user?.id,
    });
  };

  const rejectCall = () => {
    // Handle logic for rejecting the call

    // Close the popup when the call is rejected
    closePopup();
    audio.pause();
    audio.currentTime = 0;
    socketInstance.emit("decline-call", {
      _id: callDetails?.messageId,
      // room: callDetails?.chatId?.id,
      receiver: auth?.user?.id,
    });
  };

  const endCall = () => {
    // Handle logic for rejecting the call

    // Close the popup when the call is rejected
    closePopup();
    leaveChannel();
  };

  const closePopup = () => {
    // Close the popup and update the state
    setShowPopup(false);
  };

  // // Function to play the audio
  // const playAudioHandler = () => {
  //   audio.play().catch((error) => {
  //     console.error("Error playing audio:", error);
  //   });

  //   // Remove the click event listener after the first user interaction
  //   document.removeEventListener("click", playAudioHandler);
  // };
  // Render the popup only if showPopup is true

  // Agora Setup
  const [isAudioOn, setIsAudioOn] = useState(false);
  const [isVideoOn, setIsVideoOn] = useState(false);
  const [isAudioPubed, setIsAudioPubed] = useState(false);
  const [isVideoPubed, setIsVideoPubed] = useState(false);
  const [isVideoSubed, setIsVideoSubed] = useState(false);
  const [isJoined, setIsJoined] = useState(false);
  const channel = useRef("");
  const appid = useRef("");
  const token = useRef("");
  const turnOnCamera = async (flag) => {
    flag = flag === undefined ? !isVideoOn : flag;
    setIsVideoOn(flag);

    if (videoTrack) {
      return videoTrack.setEnabled(flag);
    }
    videoTrack = await createCameraVideoTrack();
    videoTrack.play("camera-video");
  };
  const turnOnMicrophone = async (flag) => {
    flag = flag === undefined ? !isAudioOn : flag;
    setIsAudioOn(flag);

    if (audioTrack) {
      return audioTrack.setEnabled(flag);
    }

    audioTrack = await createMicrophoneAudioTrack();
    // audioTrack.play();
  };
  const joinAndPublishAudio = async (token, channelName) => {
    if (!channel.current) {
      channel.current = "react-room";
    }

    if (isJoined) {
      await leaveChannel();
    }

    client.on("user-published", onUserPublish);

    // await client.join(
    //   appid.current,
    //   channel.current,
    //   token.current || null,
    //   null
    // );
    // console.log(token, "token", channelName);
    await client.join(
      "747e7634e8124c7da9bc8dde4a68b178",
      channelName,
      token,
      null
    );
    setIsJoined(true);
    // console.log("user on call hurray");
    // Publish audio
    await turnOnMicrophone(true);

    await client.publish(audioTrack);
    setIsAudioPubed(true);
  };

  const leaveChannel = async () => {
    setIsJoined(false);
    setIsAudioPubed(false);
    setIsVideoPubed(false);

    await client.leave();
    client.close();
  };

  const onUserPublish = async (user, mediaType) => {
    if (mediaType === "video") {
      const remoteTrack = await client.subscribe(user, mediaType);
      remoteTrack.play("remote-video");
      setIsVideoSubed(true);
    }
    if (mediaType === "audio") {
      const remoteTrack = await client.subscribe(user, mediaType);
      remoteTrack.play();
    }
  };

  const publishVideo = async () => {
    await turnOnCamera(true);

    if (!isJoined) {
      await joinAndPublishAudio();
    }
    await client.publish(videoTrack);
    setIsVideoPubed(true);
  };

  const publishAudio = async () => {
    await turnOnMicrophone(true);

    if (!isJoined) {
      await joinAndPublishAudio();
    }

    await client.publish(audioTrack);
    setIsAudioPubed(true);
  };

  // console.log(callDetails, "calldetails");
  return showPopup ? (
    <div className="call-popup">
      <div className="header">
        Voice Call - <span>{moment().format("LT")}</span>
      </div>
      <div className="d-flex flex-column align-items-center">
        <div className="my-2">
          <img
            src={
              selectedChat?.latestMessage?.sender?.id === auth?.user?.id
                ? selectedChat?.isGroup
                  ? groupIcon
                  : userInfo?.profilePic
                  ? userInfo?.profilePic.fileUrl
                  : defaultImg
                : selectedChat?.isGroup
                ? groupIcon
                : callDetails?.sender?.profilePic
                ? callDetails?.sender?.profilePic.fileUrl
                : defaultImg
            }
            alt="User Image"
            className="chat-avatar-img rounded-circle"
            width={80}
          />
        </div>
        <div>
          <h6 className="">
            {selectedChat?.latestMessage?.sender?.id == auth?.user?.id
              ? selectedChat?.isGroup
                ? selectedChat?.chatName
                : userInfo?.firstName + " " + userInfo?.lastName
              : selectedChat?.isGroup
              ? selectedChat?.chatName
              : callDetails?.sender?.firstName +
                " " +
                callDetails?.sender?.lastName}{" "}
            {selectedChat?.latestMessage?.meta?.callStatus === "Accepted"
              ? "On Call"
              : "Calling"}
          </h6>
        </div>
      </div>
      {!callAccepted &&
        selectedChat?.latestMessage?.sender?.id != auth?.user?.id && (
          <div className="d-flex gap-4">
            <button className="btn btn-success mr-2" onClick={acceptCall}>
              Accept
            </button>
            <button className="btn btn-danger" onClick={rejectCall}>
              Reject
            </button>
          </div>
        )}
      {(selectedChat?.latestMessage?.meta?.callStatus === "Accepted" ||
        callAccepted) && (
        <div className="d-flex gap-4">
          <button className="btn btn-danger" onClick={endCall}>
            End
          </button>
        </div>
      )}
      <div></div>
    </div>
  ) : null;
}

export default CallPopup;
