/** @format */

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useParams } from "react-router-dom";
import {
  getRouteById,
  getRouteandTripByIdV2,
  undoRouteTrip,
  updateRouteTrip,
} from "../../../actions/routeActions";
import StudentsList from "../AdminRouteDetailsPage/StudentsList";
import moment from "moment";
import { notifyFailure } from "../../../components/toast";
import { addRouteTrip } from "../../../actions/routeTripActions";
// import LoadingComponent from "../../../components/LoadingComponent";
import axios from "axios";
import { SERVERADDRESS } from "../../../constants";
import UpdatingModal from "../../../components/UpdatingModal";
// import RidePatternTable from "./RidePatternTable";
// import LoadingComponent from "../../../components/LoadingComponent";

const AdminRouteRideActionComponent = () => {
  const dispatch = useDispatch();
  const params = useParams();

  const routes = useSelector((state) => state.routes);
  const auth = useSelector((state) => state.auth);
  const service = useSelector((state) => state.service);

  const { loading, routeDetails } = routes;

  useEffect(() => {
    dataLoaded(moment(today).format("YYYY-MM-DD"));
    dispatch(
      getRouteandTripByIdV2({
        _id: params.id,
        today: moment(today).format("YYYY-MM-DD"),
      })
    );
  }, []);
  const dataLoaded = async (date) => {
    const { data } = await axios.post(
      `${SERVERADDRESS}/v1/route/getRouteandTripByIdV2`,
      {
        _id: params.id,
        today: date,
      }
    );
    if (data) {
      // let arr = [];
      // data?.route.customerDetails.forEach((e) => {
      //   arr.push({
      //     pick:
      //       data?.trip === null
      //         ? false
      //         : data?.trip?.customersDetails?.some(
      //             (item) =>
      //               item?.customerId == e?.customerId?.id &&
      //               item.hasOwnProperty("pickUpTime") &&
      //               !item.hasOwnProperty("customerCancelReason")
      //           ),
      //     noshow:
      //       data?.trip === null
      //         ? false
      //         : data?.trip?.customersDetails?.some(
      //             (item) =>
      //               item?.customerId == e?.customerId?.id &&
      //               item.hasOwnProperty("customerCancelReason")
      //           ),
      //     drop:
      //       data?.trip === null
      //         ? false
      //         : data?.trip?.customersDetails?.some(
      //             (item) =>
      //               item?.customerId == e?.customerId?.id &&
      //               item.hasOwnProperty("dropOffTime") &&
      //               !item.hasOwnProperty("customerCancelReason")
      //           ),
      //   });
      // });
      // setToggleValues(arr);
      let arrAM = [];
      let arrPM = [];
      let arrMID = [];
      data?.route.customerDetailsAM.forEach((e) => {
        arrAM.push({
          pick:
            data?.tripAM === null
              ? false
              : data?.tripAM?.customersDetails?.some(
                  (item) =>
                    item?.customerId == e?.customerId?.id &&
                    item.hasOwnProperty("pickUpTime") &&
                    !item.hasOwnProperty("customerCancelReason")
                ),
          noshow:
            data?.tripAM === null
              ? false
              : data?.tripAM?.customersDetails?.some(
                  (item) =>
                    item?.customerId == e?.customerId?.id &&
                    item.hasOwnProperty("customerCancelReason")
                ),
          drop:
            data?.tripAM === null
              ? false
              : data?.tripAM?.customersDetails?.some(
                  (item) =>
                    item?.customerId == e?.customerId?.id &&
                    item.hasOwnProperty("dropOffTime") &&
                    !item.hasOwnProperty("customerCancelReason")
                ),
        });
      });
      data?.route.customerDetailsPM.forEach((e) => {
        arrPM.push({
          pick:
            data?.tripPM === null
              ? false
              : data?.tripPM?.customersDetails?.some(
                  (item) =>
                    item?.customerId == e?.customerId?.id &&
                    item.hasOwnProperty("pickUpTime") &&
                    !item.hasOwnProperty("customerCancelReason")
                ),
          noshow:
            data?.tripPM === null
              ? false
              : data?.tripPM?.customersDetails?.some(
                  (item) =>
                    item?.customerId == e?.customerId?.id &&
                    item.hasOwnProperty("customerCancelReason")
                ),
          drop:
            data?.tripPM === null
              ? false
              : data?.tripPM?.customersDetails?.some(
                  (item) =>
                    item?.customerId == e?.customerId?.id &&
                    item.hasOwnProperty("dropOffTime") &&
                    !item.hasOwnProperty("customerCancelReason")
                ),
        });
      });
      data?.route.customerDetailsMID.forEach((e) => {
        arrMID.push({
          pick:
            data?.tripMID === null
              ? false
              : data?.tripMID?.customersDetails?.some(
                  (item) =>
                    item?.customerId == e?.customerId?.id &&
                    item.hasOwnProperty("pickUpTime") &&
                    !item.hasOwnProperty("customerCancelReason")
                ),
          noshow:
            data?.tripMID === null
              ? false
              : data?.tripMID?.customersDetails?.some(
                  (item) =>
                    item?.customerId == e?.customerId?.id &&
                    item.hasOwnProperty("customerCancelReason")
                ),
          drop:
            data?.tripMID === null
              ? false
              : data?.tripMID?.customersDetails?.some(
                  (item) =>
                    item?.customerId == e?.customerId?.id &&
                    item.hasOwnProperty("dropOffTime") &&
                    !item.hasOwnProperty("customerCancelReason")
                ),
        });
      });
      setToggleValuesAM(arrAM);
      setToggleValuesPM(arrPM);
      setToggleValuesMID(arrMID);
    }
  };
  const [toggleValues, setToggleValues] = useState([]);
  const [toggleValuesAM, setToggleValuesAM] = useState([]);
  const [toggleValuesPM, setToggleValuesPM] = useState([]);
  const [toggleValuesMID, setToggleValuesMID] = useState([]);
  useEffect(() => {
    if (routeDetails) {
      // let arr = [];
      // routeDetails.route.customerDetails.forEach((e) => {
      //   arr.push({
      //     pick:
      //       routes?.routeDetails?.trip === null
      //         ? false
      //         : routes?.routeDetails?.trip?.customersDetails?.some(
      //             (item) =>
      //               item?.customerId == e?.customerId?.id &&
      //               item.hasOwnProperty("pickUpTime") &&
      //               !item.hasOwnProperty("customerCancelReason")
      //           ),
      //     noshow:
      //       routes?.routeDetails?.trip === null
      //         ? false
      //         : routes?.routeDetails?.trip?.customersDetails?.some(
      //             (item) =>
      //               item?.customerId == e?.customerId?.id &&
      //               item.hasOwnProperty("customerCancelReason")
      //           ),
      //     drop:
      //       routes?.routeDetails?.trip === null
      //         ? false
      //         : routes?.routeDetails?.trip?.customersDetails?.some(
      //             (item) =>
      //               item?.customerId == e?.customerId?.id &&
      //               item.hasOwnProperty("dropOffTime") &&
      //               !item.hasOwnProperty("customerCancelReason")
      //           ),
      //   });
      // });
      // setToggleValues(arr);
      let arrAM = [];
      let arrPM = [];
      let arrMID = [];
      routeDetails.route.customerDetailsAM.forEach((e) => {
        arrAM.push({
          pick:
            routes?.routeDetails?.tripAM === null
              ? false
              : routes?.routeDetails?.tripAM?.customersDetails?.some(
                  (item) =>
                    item?.customerId == e?.customerId?.id &&
                    item.hasOwnProperty("pickUpTime") &&
                    !item.hasOwnProperty("customerCancelReason")
                ),
          noshow:
            routes?.routeDetails?.tripAM === null
              ? false
              : routes?.routeDetails?.tripAM?.customersDetails?.some(
                  (item) =>
                    item?.customerId == e?.customerId?.id &&
                    item.hasOwnProperty("customerCancelReason")
                ),
          drop:
            routes?.routeDetails?.tripAM === null
              ? false
              : routes?.routeDetails?.tripAM?.customersDetails?.some(
                  (item) =>
                    item?.customerId == e?.customerId?.id &&
                    item.hasOwnProperty("dropOffTime") &&
                    !item.hasOwnProperty("customerCancelReason")
                ),
        });
      });
      routeDetails.route.customerDetailsPM.forEach((e) => {
        arrPM.push({
          pick:
            routes?.routeDetails?.tripPM === null
              ? false
              : routes?.routeDetails?.tripPM?.customersDetails?.some(
                  (item) =>
                    item?.customerId == e?.customerId?.id &&
                    item.hasOwnProperty("pickUpTime") &&
                    !item.hasOwnProperty("customerCancelReason")
                ),
          noshow:
            routes?.routeDetails?.tripPM === null
              ? false
              : routes?.routeDetails?.tripPM?.customersDetails?.some(
                  (item) =>
                    item?.customerId == e?.customerId?.id &&
                    item.hasOwnProperty("customerCancelReason")
                ),
          drop:
            routes?.routeDetails?.tripPM === null
              ? false
              : routes?.routeDetails?.tripPM?.customersDetails?.some(
                  (item) =>
                    item?.customerId == e?.customerId?.id &&
                    item.hasOwnProperty("dropOffTime") &&
                    !item.hasOwnProperty("customerCancelReason")
                ),
        });
      });
      routeDetails.route.customerDetailsMID.forEach((e) => {
        arrMID.push({
          pick:
            routes?.routeDetails?.tripMID === null
              ? false
              : routes?.routeDetails?.tripMID?.customersDetails?.some(
                  (item) =>
                    item?.customerId == e?.customerId?.id &&
                    item.hasOwnProperty("pickUpTime") &&
                    !item.hasOwnProperty("customerCancelReason")
                ),
          noshow:
            routes?.routeDetails?.tripMID === null
              ? false
              : routes?.routeDetails?.tripMID?.customersDetails?.some(
                  (item) =>
                    item?.customerId == e?.customerId?.id &&
                    item.hasOwnProperty("customerCancelReason")
                ),
          drop:
            routes?.routeDetails?.tripMID === null
              ? false
              : routes?.routeDetails?.tripMID?.customersDetails?.some(
                  (item) =>
                    item?.customerId == e?.customerId?.id &&
                    item.hasOwnProperty("dropOffTime") &&
                    !item.hasOwnProperty("customerCancelReason")
                ),
        });
      });
      setToggleValuesAM(arrAM);
      setToggleValuesPM(arrPM);
      setToggleValuesMID(arrMID);
    }
  }, [routeDetails]);
  // console.log(toggleValues, "toggleValues")

  const updatePick = (id, customerId) => {
    dispatch(
      updateRouteTrip({
        _id: id,
        customerId: customerId,
        arrivedTime: moment().format().toString().substring(0, 19) + ".000Z",
        pickUpTime: moment().format().toString().substring(0, 19) + ".000Z",
        pickUpLat: 0,
        pickUpLng: 0,
      })
    );
  };
  const undoPick = (id, customerId) => {
    dispatch(
      undoRouteTrip({
        _id: id,
        customerId: customerId,
        undoActions: "pick",
      })
    );
  };

  const handleTogglePick = (i, id, userId) => {
    setToggleValues((prevArr) => [
      ...prevArr.slice(0, i),
      { ...prevArr[i], pick: !prevArr[i].pick },
      ...prevArr.slice(i + 1),
    ]);

    // setTimeout(()=>{
    toggleValues[i].pick === false
      ? updatePick(id, userId)
      : undoPick(id, userId);
    // },200)
  };

  const [todayDate, setTodayDate] = useState(
    moment().format("dddd, DD MMMM, YYYY")
  );

  const [today, setToday] = useState(moment());
  const [currentDaily, setCurrentDaily] = useState(0);
  const onClickNextDaily = async () => {
    // console.log(currentDaily)
    setCurrentDaily(currentDaily + 1);
    // console.log(currentDaily+1)
    setToday(moment().add(currentDaily + 1, "days"));
    dataLoaded(
      moment()
        .add(currentDaily + 1, "days")
        .format("YYYY-MM-DD")
    );
    // dispatch(getShiftAllUsers({companyId: auth.user.companyId, date: moment().add(currentDaily + 1, 'days').format('YYYY-MM-DD')}))
    dispatch(
      getRouteandTripByIdV2({
        _id: params.id,
        today: moment()
          .add(currentDaily + 1, "days")
          .format("YYYY-MM-DD"),
      })
    );
  };
  const onClickPrevDaily = () => {
    // console.log(currentDaily)
    setCurrentDaily(currentDaily - 1);
    // console.log(currentDaily-1)
    setToday(moment().add(currentDaily - 1, "days"));
    dataLoaded(
      moment()
        .add(currentDaily - 1, "days")
        .format("YYYY-MM-DD")
    );
    // dispatch(getShiftAllUsers({companyId: auth.user.companyId, date: moment().add(currentDaily - 1, 'days').format('YYYY-MM-DD')}))
    dispatch(
      getRouteandTripByIdV2({
        _id: params.id,
        today: moment()
          .add(currentDaily - 1, "days")
          .format("YYYY-MM-DD"),
      })
    );
  };

  return (
    <>
      {loading && routes?.routeDetails?.route?.id && <UpdatingModal />}

      {routes?.routeDetails?.route?.id && (
        <>
          <div className="d-flex flex-column w-tables rounded mt-3 bg-white">
            {/* <h5 className="p-2">Route# {routeDetails?.route?.routeNumber } {routeDetails?.route?.routeNumberReturn && "( Return )"}</h5>
                <p className="p-1">Driver Name: {routeDetails?.route?.driverId?.firstName || routeDetails?.route?.driverId?.lastName ? routeDetails?.route?.driverId?.firstName +" "+ routeDetails?.route?.driverId?.lastName  : "Driver is not assigned "} </p> */}
            <div className="row">
              <div className="col-lg-4">
                {/* for space */}
                <h5 className="p-2">
                  Route# {routeDetails?.route?.routeNumber}{" "}
                  {routeDetails?.route?.routeNumberReturn && "( Return )"}
                </h5>
                <p className="p-1">
                  <strong>Driver Name: </strong>
                  {routeDetails?.route?.driverId?.firstName ||
                  routeDetails?.route?.driverId?.lastName
                    ? routeDetails?.route?.driverId?.firstName +
                      " " +
                      routeDetails?.route?.driverId?.lastName
                    : "Driver is not assigned "}{" "}
                </p>
              </div>
              <div className="col-lg-3">
                <div className=" " id="leads-table_paginate">
                  <ul className="row" style={{ marginTop: "30px" }}>
                    {/* <li className="page-item">
                                    <NavLink
                                    className="page-link"
                                    to="#"
                                    onClick={onClickPrevDaily}
                                    aria-label="Previous"
                                    style={{
                                        // borderRadius: "50%", 
                                        // padding: "0.5rem 0.75rem",
                                        marginLeft: "-1px",
                                        // lineHeight: "1.25",
                                        color: "#1d82f5"
                                    }}
                                    >
                                    
                                    <span aria-hidden="true">{"<"}</span>
                                    <span className="sr-only">{"<"}</span>
                                    </NavLink>
                                </li> */}

                    <button
                      className="btn"
                      onClick={onClickPrevDaily}
                      aria-label="Previous"
                      style={{
                        color: "#1d82f5",
                        borderRadius: "50%",
                        border: "1px solid #e8eef3",
                        height: "35px",
                        alignItems: "center",
                        justifyContent: "center",
                        fontSize: "13px",
                      }}
                    >
                      <span aria-hidden="true">{"<"}</span>
                      <span className="sr-only">{"<"}</span>
                    </button>

                    <h6 className="p-2 py-2">
                      {moment(today).format("ddd, DD MMMM, YYYY")}{" "}
                    </h6>

                    {/* <li className="page-item">
                                    <NavLink
                                    className="page-link"
                                    to="#"
                                    onClick={onClickNextDaily}
                                    aria-label="Next"
                                    style={{
                                        // borderRadius: "50%", 
                                        // padding: "0.5rem 0.75rem",
                                        marginLeft: "-1px",
                                        // lineHeight: "1.25",
                                        color: "#1d82f5"
                                    }}
                                    >
                                        {">"}
                                    <span aria-hidden="true">{">"}</span>
                                    <span className="sr-only">{">"}</span>
                                    </NavLink>
                                </li> */}
                    <button
                      className="btn"
                      onClick={onClickNextDaily}
                      aria-label="Next"
                      style={{
                        color: "#1d82f5",
                        borderRadius: "50%",
                        border: "1px solid #e8eef3",
                        height: "35px",
                        alignItems: "center",
                        justifyContent: "center",
                        fontSize: "13px",
                      }}
                    >
                      <span aria-hidden="true">{">"}</span>
                      <span className="sr-only">{">"}</span>
                    </button>
                  </ul>
                </div>
              </div>

              <div className="col-lg-2">{/* for space */}</div>

              <div className="col-lg-2">
                <div className="form-group my-2">
                  {/* <button className="btn btn-success btn-block"
                            // onClick={handleClick}
                            // startRide ? startRide : Inprogress
                            > Start Ride </button> */}
                  {routes?.routeDetails?.trip?.isCompleted === true ? (
                    <button
                      type="button"
                      className="btn btn-success"
                      style={{
                        cursor: "default",
                        width: "100%",
                        marginTop: "20px",
                      }}
                    >
                      Completed
                    </button>
                  ) : routes?.routeDetails?.trip !== null ? (
                    <>
                      <div className="row">
                        <button
                          type="button"
                          className="btn btn-info"
                          style={{ cursor: "default", marginTop: "20px" }}
                        >
                          In Progress
                        </button>
                        &nbsp;
                        {/* {
                                        routes?.routeDetails?.route?.customerDetails.length === routes?.routeDetails?.trip?.customersDetails?.length &&
                                        routes?.routeDetails?.trip?.customersDetails?.some(e => !e.isDone) ?
                                        <button type="button" className="btn btn-warning" disabled style={{cursor:"not-allowed"}}>Ride End</button>
                                        :
                                        <button type="button" className="btn btn-warning" 
                                            onClick={()=> dispatch(updateRouteTrip({
                                                _id: routes?.routeDetails?.trip?.id,
                                                isCompleted: true
                                            }))}
                                        >Ride End</button>
                                    } */}
                        {routes?.routeDetails?.route?.customerDetails.length ===
                          routes?.routeDetails?.trip?.customersDetails
                            ?.length &&
                        routes?.routeDetails?.trip?.customersDetails?.every(
                          (e) => e.isDone
                        ) ? (
                          <button
                            type="button"
                            className="btn btn-warning"
                            style={{ marginTop: "20px" }}
                            onClick={() =>
                              dispatch(
                                updateRouteTrip({
                                  _id: routes?.routeDetails?.trip?.id,
                                  isCompleted: true,
                                })
                              )
                            }
                          >
                            Ride End
                          </button>
                        ) : (
                          <button
                            type="button"
                            className="btn btn-warning"
                            disabled
                            style={{ cursor: "not-allowed", marginTop: "20px" }}
                          >
                            Ride End
                          </button>
                        )}
                      </div>
                    </>
                  ) : routes?.routeDetails?.trip === null ? (
                    <button
                      type="button"
                      className="btn btn-primary"
                      style={{
                        cursor: "default",
                        width: "100%",
                        marginTop: "20px",
                      }}
                      onClick={() => {
                        routes?.routeDetails?.route?.driverBookingStatus !==
                        "Assigned"
                          ? notifyFailure("Driver not Assigned")
                          : dispatch(
                              addRouteTrip({
                                companyId: auth.user.companyId.id,
                                driverId:
                                  routes?.routeDetails?.route?.driverId?.id,
                                routeId: params.id,
                                tripStartTime:
                                  moment()
                                    .format()
                                    .toString()
                                    .substring(0, 19) + ".000Z",
                              })
                            );
                        setTimeout(() => {
                          dataLoaded();
                          dispatch(
                            getRouteandTripByIdV2({
                              _id: params.id,
                              today: moment(today).format("YYYY-MM-DD"),
                            })
                          );
                        }, 1500);
                      }}
                    >
                      Start
                    </button>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>

            <div
              id="leads-table_wrapper"
              className="dataTables_wrapper dt-bootstrap4 no-footer"
            >
              <div className="row">
                <div className="col-sm-12">
                  {/* <StudentsList /> */}
                  <table
                    className="table border w-100 dataTable no-footer"
                    id="leads-table"
                    role="grid"
                    aria-describedby="leads-table_info"
                    style={{ overflowX: "auto" }}
                  >
                    <thead className="card-header">
                      <tr role="row">
                        <th
                          title="Passenger Name"
                          className="sorting border-right"
                          tabIndex="0"
                          aria-controls="leads-table"
                          rowSpan="1"
                          colSpan="1"
                          aria-label="Mobile: activate to sort column ascending"
                        >
                          Passenger Name
                        </th>
                        <th
                          title="Payer"
                          className="sorting border-right"
                          tabIndex="0"
                          aria-controls="leads-table"
                          rowSpan="1"
                          colSpan="1"
                          aria-label="Mobile: activate to sort column ascending"
                        >
                          Payer
                        </th>
                        <th
                          title="Pickup Time"
                          className="sorting border-right"
                          tabIndex="0"
                          aria-controls="leads-table"
                          rowSpan="1"
                          colSpan="1"
                          aria-label="Mobile: activate to sort column ascending"
                          style={{ width: "13%" }}
                        >
                          Pickup Time
                        </th>
                        <th
                          title="Pickup Address"
                          className="sorting border-right"
                          tabIndex="0"
                          aria-controls="leads-table"
                          rowSpan="1"
                          colSpan="1"
                          aria-label="Lead Agent: activate to sort column ascending"
                        >
                          Pickup Address
                        </th>
                        <th
                          title="Dropoff Time"
                          className="sorting border-right"
                          tabIndex="0"
                          aria-controls="leads-table"
                          rowSpan="1"
                          colSpan="1"
                          aria-label="Mobile: activate to sort column ascending"
                          style={{ width: "9%" }}
                        >
                          Dropoff Time
                        </th>
                        <th
                          title="Dropoff Address"
                          className="sorting border-right"
                          tabIndex="0"
                          aria-controls="leads-table"
                          rowSpan="1"
                          colSpan="1"
                          aria-label="Lead Agent: activate to sort column ascending"
                        >
                          Dropoff Address
                        </th>

                        <th
                          title="Location Name"
                          className="sorting border-right"
                          tabIndex="0"
                          aria-controls="leads-table"
                          rowSpan="1"
                          colSpan="1"
                          aria-label="Lead Agent: activate to sort column ascending"
                        >
                          Location Name
                        </th>
                        <th
                          title="Note"
                          className="sorting border-right"
                          tabIndex="0"
                          aria-controls="leads-table"
                          rowSpan="1"
                          colSpan="1"
                          aria-label="Lead Agent: activate to sort column ascending"
                        >
                          Note
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {routes?.routeDetails?.route?.customerDetails?.map(
                        (current, i) => {
                          const currentTask =
                            routes?.routeDetails?.route?.ridePattern.find(
                              (item) =>
                                item.taskDone === false &&
                                item.customerId.id == current?.customerId?.id
                            );
                          const currentTask2 =
                            routes?.routeDetails?.route?.ridePattern.filter(
                              (item) => item.taskDone === false
                            );
                          const checkValue =
                            routes?.routeDetails?.trip?.customersDetails.find(
                              (item) =>
                                item?.customerId == current?.customerId?.id
                            );
                          let pick =
                            routes?.routeDetails?.trip === null
                              ? false
                              : routes?.routeDetails?.trip?.customersDetails?.some(
                                  (item) =>
                                    item?.customerId ==
                                      current?.customerId?.id &&
                                    item.hasOwnProperty("pickUpTime") &&
                                    !item.hasOwnProperty("customerCancelReason")
                                );
                          let noshow =
                            routes?.routeDetails?.trip === null
                              ? false
                              : routes?.routeDetails?.trip?.customersDetails?.some(
                                  (item) =>
                                    item?.customerId ==
                                      current?.customerId?.id &&
                                    item.hasOwnProperty("customerCancelReason")
                                );
                          let drop =
                            routes?.routeDetails?.trip === null
                              ? false
                              : routes?.routeDetails?.trip?.customersDetails?.some(
                                  (item) =>
                                    item?.customerId ==
                                      current?.customerId?.id &&
                                    item.hasOwnProperty("dropOffTime") &&
                                    !item.hasOwnProperty("customerCancelReason")
                                );
                          // console.log(pick, noshow, drop, "pick, noshow, drop")
                          return (
                            <tr role="row" className="odd border" key={i}>
                              <td className="border-right">
                                <div>
                                  <p className="m-0">
                                    {current?.customerId?.firstName}{" "}
                                    {current?.customerId?.lastName}
                                  </p>
                                </div>
                              </td>
                              <td className="border-right">
                                {current?.customerId?.externalCompanyId ? (
                                  <p className="m-0">
                                    {
                                      current?.customerId?.externalCompanyId
                                        ?.name
                                    }
                                  </p>
                                ) : (
                                  "Individual"
                                )}
                              </td>
                              <td className="border-right">
                                <b>Time:</b>{" "}
                                {moment(current.pickUpTime, "hh:mm").format(
                                  " hh:mm a"
                                )}
                                <div className="row">
                                  <div className="col-6">Pickup</div>
                                  <div className="col-6">No Show</div>
                                </div>
                                <div className="row">
                                  <div className="col-6">
                                    {/* <div className="btn-choice"> */}
                                    <label className="switch">
                                      <input
                                        type="checkbox"
                                        checked={toggleValues[i]?.pick}
                                        // checked={
                                        //     checkValue ?
                                        //     (checkValue?.customerCancelReason || !checkValue?.pickUpTime ) ?
                                        //     false : true
                                        //     : false
                                        // }
                                        onChange={() => {
                                          routes?.routeDetails?.trip &&
                                            // handleTogglePick(i, routes?.routeDetails?.trip?.id, current?.customerId?.id)
                                            toggleValues[i].noshow === false &&
                                            setToggleValues((prevArr) => [
                                              ...prevArr.slice(0, i),
                                              {
                                                ...prevArr[i],
                                                pick: !prevArr[i].pick,
                                              },
                                              ...prevArr.slice(i + 1),
                                            ]);
                                          //   console.log(...toggleValues.slice(0, i), { ...toggleValues[i], pick: !toggleValues[i].pick }, ...toggleValues.slice(i + 1), "toggleValues")
                                          // checkValue &&
                                          // (checkValue?.customerCancelReason || !checkValue?.pickUpTime ) &&
                                          // noshow === false &&
                                          // pick === false &&
                                          toggleValues[i].noshow === false &&
                                          toggleValues[i].pick === false
                                            ? dispatch(
                                                updateRouteTrip({
                                                  _id: routes?.routeDetails
                                                    ?.trip?.id,
                                                  customerId:
                                                    current?.customerId?.id,
                                                  arrivedTime:
                                                    moment()
                                                      .format()
                                                      .toString()
                                                      .substring(0, 19) +
                                                    ".000Z",
                                                  pickUpTime:
                                                    moment()
                                                      .format()
                                                      .toString()
                                                      .substring(0, 19) +
                                                    ".000Z",
                                                  pickUpLat: 0,
                                                  pickUpLng: 0,
                                                })
                                              )
                                            : // updatePick(routes?.routeDetails?.trip?.id, current?.customerId?.id)
                                              toggleValues[i].noshow ===
                                                false &&
                                              dispatch(
                                                undoRouteTrip({
                                                  _id: routes?.routeDetails
                                                    ?.trip?.id,
                                                  customerId:
                                                    current?.customerId?.id,
                                                  undoActions: "pick",
                                                })
                                              );
                                          // undoPick(routes?.routeDetails?.trip?.id, current?.customerId?.id)
                                          // setTimeout(
                                          //     () =>
                                          //     {
                                          //         // checkValue &&
                                          //         // (checkValue?.customerCancelReason || !checkValue?.pickUpTime ) &&
                                          //         noshow === false &&
                                          //         pick === false &&
                                          //         dispatch(updateRouteTrip({
                                          //             _id: routes?.routeDetails?.trip?.id,
                                          //             customerId: current?.customerId?.id,
                                          //             pickUpTime: moment().format().toString().substring(0,19)+".000Z",
                                          //             pickUpLat: 0,
                                          //             pickUpLng: 0,
                                          //         }))
                                          //     }
                                          // ,2000);
                                        }}
                                        className="switch-input"
                                        id="toggle"
                                      />
                                      <span
                                        className="switch-label"
                                        data-on=""
                                        data-off=""
                                      ></span>
                                      <span className="switch-handle"></span>
                                    </label>
                                    {/* </div> */}
                                  </div>
                                  <div className="col-6">
                                    {/* No Show */}
                                    {/* <div className="btn-choice"> */}
                                    <label className="switch">
                                      <input
                                        type="checkbox"
                                        checked={toggleValues[i]?.noshow}
                                        // checked={
                                        //     checkValue ?
                                        //     checkValue?.customerCancelReason ? true : false
                                        //     : false
                                        // }
                                        onChange={() => {
                                          routes?.routeDetails?.trip &&
                                            toggleValues[i].pick === false &&
                                            setToggleValues((prevArr) => [
                                              ...prevArr.slice(0, i),
                                              {
                                                ...prevArr[i],
                                                noshow: !prevArr[i].noshow,
                                              },
                                              ...prevArr.slice(i + 1),
                                            ]);
                                          toggleValues[i].noshow === false
                                            ? toggleValues[i].pick === false &&
                                              dispatch(
                                                updateRouteTrip({
                                                  _id: routes?.routeDetails
                                                    ?.trip?.id,
                                                  customerId:
                                                    current?.customerId?.id,
                                                  customerCancelReason:
                                                    "No Show",
                                                  arrivedTime:
                                                    moment()
                                                      .format()
                                                      .toString()
                                                      .substring(0, 19) +
                                                    ".000Z",
                                                  pickUpTime:
                                                    moment()
                                                      .format()
                                                      .toString()
                                                      .substring(0, 19) +
                                                    ".000Z",
                                                  pickUpLat: 0,
                                                  pickUpLng: 0,
                                                  dropOffTime:
                                                    moment()
                                                      .format()
                                                      .toString()
                                                      .substring(0, 19) +
                                                    ".000Z",
                                                  dropOffLat: 0,
                                                  dropOffLng: 0,
                                                })
                                              )
                                            : toggleValues[i].pick === false &&
                                              dispatch(
                                                undoRouteTrip({
                                                  _id: routes?.routeDetails
                                                    ?.trip?.id,
                                                  customerId:
                                                    current?.customerId?.id,
                                                  undoActions: "noshow",
                                                })
                                              );
                                          // old logic below code working uncomment from top to bottom
                                          // currentTask2.length > 2 ?
                                          // // checkValue &&
                                          // // !checkValue?.pickUpTime &&
                                          // // noshow === false &&
                                          // // pick === false &&
                                          // toggleValues[i].noshow === false ?
                                          // toggleValues[i].pick === false &&
                                          // dispatch(updateRouteTrip({
                                          //     _id: routes?.routeDetails?.trip?.id,
                                          //     customerId: current?.customerId?.id,
                                          //     customerCancelReason: "No Show",
                                          //     arrivedTime: moment().format().toString().substring(0,19)+".000Z",
                                          //     pickUpTime: moment().format().toString().substring(0,19)+".000Z",
                                          //     pickUpLat: 0,
                                          //     pickUpLng: 0,
                                          //     dropOffTime: moment().format().toString().substring(0,19)+".000Z",
                                          //     dropOffLat: 0,
                                          //     dropOffLng: 0,
                                          // }))
                                          // :
                                          // toggleValues[i].pick === false &&
                                          // dispatch(undoRouteTrip({
                                          //     _id: routes?.routeDetails?.trip?.id,
                                          //     customerId: current?.customerId?.id,
                                          //     undoActions: 'noshow',
                                          // }))
                                          // :
                                          // // checkValue &&
                                          // // !checkValue?.pickUpTime &&
                                          // // noshow === false &&
                                          // // pick === false &&
                                          // toggleValues[i].noshow === false ?
                                          // toggleValues[i].pick === false &&
                                          // dispatch(updateRouteTrip({
                                          //     _id: routes?.routeDetails?.trip?.id,
                                          //     customerId: current?.customerId?.id,
                                          //     customerCancelReason: "No Show",
                                          //     arrivedTime: moment().format().toString().substring(0,19)+".000Z",
                                          //     pickUpTime: moment().format().toString().substring(0,19)+".000Z",
                                          //     // tripStatus: "Completed",
                                          //     pickUpLat: 0,
                                          //     pickUpLng: 0,
                                          //     dropOffTime: moment().format().toString().substring(0,19)+".000Z",
                                          //     // tripStatus: "Completed",
                                          //     dropOffLat: 0,
                                          //     dropOffLng: 0,
                                          //     isCompleted: true
                                          // }))
                                          // :
                                          // toggleValues[i].pick === false &&
                                          // dispatch(undoRouteTrip({
                                          //     _id: routes?.routeDetails?.trip?.id,
                                          //     customerId: current?.customerId?.id,
                                          //     undoActions: 'noshow',
                                          // }))
                                        }}
                                        className="switch-input"
                                        id="toggle"
                                      />
                                      <span
                                        className="switch-label"
                                        data-on=""
                                        data-off=""
                                      ></span>
                                      <span className="switch-handle"></span>
                                    </label>
                                  </div>
                                  {/* </div> */}
                                </div>
                              </td>
                              <td className="border-right">
                                <div>
                                  <p className="m-0">
                                    {current.pickUpAddress?.address}
                                  </p>
                                  <p className="m-0">
                                    @{" "}
                                    {moment(current.pickUpTime, "hh:mm").format(
                                      " hh:mm a"
                                    )}
                                  </p>
                                </div>
                              </td>
                              <td className="border-right">
                                <b>Time:</b>{" "}
                                {moment(current.dropOffTime, "hh:mm").format(
                                  "hh:mm a"
                                )}
                                <div className="row">
                                  <div className="col-6">Dropoff</div>
                                </div>
                                {/* <div className="btn-choice"> */}
                                <label className="switch">
                                  <input
                                    type="checkbox"
                                    checked={toggleValues[i]?.drop}
                                    // checked={
                                    //     checkValue ?
                                    //     (checkValue?.customerCancelReason || !checkValue?.dropOffTime ) ?
                                    //     false : true
                                    //     : false
                                    // }
                                    onChange={() => {
                                      routes?.routeDetails?.trip &&
                                        toggleValues[i].pick === true &&
                                        toggleValues[i].noshow === false &&
                                        setToggleValues((prevArr) => [
                                          ...prevArr.slice(0, i),
                                          {
                                            ...prevArr[i],
                                            drop: !prevArr[i].drop,
                                          },
                                          ...prevArr.slice(i + 1),
                                        ]);
                                      toggleValues[i].drop === false
                                        ? toggleValues[i].pick === true &&
                                          toggleValues[i].noshow === false &&
                                          dispatch(
                                            updateRouteTrip({
                                              _id: routes?.routeDetails?.trip
                                                ?.id,
                                              customerId:
                                                current?.customerId?.id,
                                              dropOffTime:
                                                moment()
                                                  .format()
                                                  .toString()
                                                  .substring(0, 19) + ".000Z",
                                              dropOffLat: 0,
                                              dropOffLng: 0,
                                            })
                                          )
                                        : toggleValues[i].pick === true &&
                                          toggleValues[i].noshow === false &&
                                          dispatch(
                                            undoRouteTrip({
                                              _id: routes?.routeDetails?.trip
                                                ?.id,
                                              customerId:
                                                current?.customerId?.id,
                                              undoActions: "dropoff",
                                            })
                                          );
                                      // old logic below code working uncomment from top to bottom
                                      // // checkValue &&
                                      // // (checkValue?.customerCancelReason || !checkValue?.dropOffTime ) ?
                                      // currentTask2.length > 1 ?
                                      // // checkValue &&
                                      // // (checkValue?.customerCancelReason || !checkValue?.dropOffTime ) &&
                                      // // noshow === false &&
                                      // // drop === false &&
                                      // toggleValues[i].drop === false ?
                                      // toggleValues[i].pick === true &&
                                      // toggleValues[i].noshow === false &&
                                      // dispatch(updateRouteTrip({
                                      //     _id: routes?.routeDetails?.trip?.id,
                                      //     customerId: current?.customerId?.id,
                                      //     dropOffTime: moment().format().toString().substring(0,19)+".000Z",
                                      //     dropOffLat: 0,
                                      //     dropOffLng: 0
                                      // }))
                                      // :
                                      // toggleValues[i].pick === true &&
                                      // toggleValues[i].noshow === false &&
                                      // dispatch(undoRouteTrip({
                                      //     _id: routes?.routeDetails?.trip?.id,
                                      //     customerId: current?.customerId?.id,
                                      //     undoActions: 'dropoff',
                                      // }))
                                      // :
                                      // // checkValue &&
                                      // // (checkValue?.customerCancelReason || !checkValue?.dropOffTime ) &&
                                      // // noshow === false &&
                                      // // drop === false &&
                                      // toggleValues[i].drop === false ?
                                      // toggleValues[i].pick === true &&
                                      // toggleValues[i].noshow === false &&
                                      // dispatch(updateRouteTrip({
                                      //     _id: routes?.routeDetails?.trip?.id,
                                      //     customerId: current?.customerId?.id,
                                      //     dropOffTime: moment().format().toString().substring(0,19)+".000Z",
                                      //     dropOffLat: 0,
                                      //     dropOffLng: 0,
                                      //     isCompleted: true
                                      // }))
                                      // :
                                      // toggleValues[i].pick === true &&
                                      // toggleValues[i].noshow === false &&
                                      // dispatch(undoRouteTrip({
                                      //     _id: routes?.routeDetails?.trip?.id,
                                      //     customerId: current?.customerId?.id,
                                      //     undoActions: 'dropoff',
                                      // }))
                                      // // :
                                      // // console.log("")
                                    }}
                                    className="switch-input"
                                    id="toggle"
                                  />
                                  <span
                                    className="switch-label"
                                    data-on=""
                                    data-off=""
                                  ></span>
                                  <span className="switch-handle"></span>
                                </label>
                                {/* </div> */}
                              </td>
                              <td className="border-right">
                                <div>
                                  <p className="m-0">
                                    {current.dropOffAddress?.address}
                                  </p>
                                  <p className="m-0">
                                    @{" "}
                                    {moment(
                                      current.dropOffTime,
                                      "hh:mm"
                                    ).format("hh:mm a")}
                                  </p>
                                </div>
                              </td>

                              <td className="border-right">
                                {current?.schoolName}
                              </td>
                              <td className="border-right">
                                {current.dispatchNotes}
                              </td>
                            </tr>
                          );
                        }
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};
export default AdminRouteRideActionComponent;
