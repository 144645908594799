import React from 'react'
import disable from "../../../assets/images/disabledaccount-removebg-preview.png"
import { Link, NavLink, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import { userLogout } from '../../../actions/authActions';


const WebsiteAccountDetailComponent = () => {
  const auth = useSelector((state) => state.auth);

  const dispatch = useDispatch();
  let navigate = useNavigate();
  const navigateUser = () => {
    navigate("/login");
  };
  const logout = async (e) => {
    dispatch(
      userLogout({ refreshToken: auth.tokens.refresh.token }, navigateUser)
    );
  };
  return (
    <div className="container">
      <div className="row contact-area justify-content-center">
        <div className="col-lg-8 col-md-8 col-sm-8 order-1 order-lg-1">
          <div className="pricing-6-item mt-30">
            <div className="section-title-6 text-center">
              <img className="icom-email" src={disable} alt="" style={{ maxWidth: "15%" }} />
              <br />
              <br />
              {
                auth?.user?.disable === true ?
                <>
                  <h3 className="title">Account Disabled !</h3>
                  <p>Your Account Has Been Disabled, Contact Admin For Details.</p>
                  <div className="contact-form">
                    <br />
                    <Link to="" onClick={logout} className="btn-secondary rounded activee" 
                      style={{ textAlign: "centre" }}>
                      Back to Home
                    </Link> &nbsp;
                  </div>
                </>
                :
                auth.user.isDriver === true ?
                <>
                  <h3 className="title">Alert !</h3>
                  <p>Your Account Is Driver Account, Please Login From The Mobile App.</p>
                  <div className="contact-form">
                    <br />
                    <Link to="" onClick={logout} className="btn-secondary rounded activee" style={{ textAlign: "centre" }}>Back to Home</Link> &nbsp;
                  </div>
                </>
                :
                <></>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default WebsiteAccountDetailComponent