import moment from "moment";
import React from "react";
import { useSelector } from "react-redux";

function PayerRidePatternTable() {
  const routes = useSelector((state) => state.routes);
  const service = useSelector((state) => state.service);
  const auth = useSelector((state) => state.auth);
  const { routeDetails } = routes;

  return (
    <table
      className="table border w-100 dataTable no-footer"
      id="leads-table"
      role="grid"
      aria-describedby="leads-table_info"
      style={{ overflowX: "auto" }}
    >
      <thead className="card-header">
        <tr role="row">
          <th
            title="S.no"
            className="sorting border-right"
            tabIndex="0"
            aria-controls="leads-table"
            rowSpan="1"
            colSpan="1"
            aria-label="Mobile: activate to sort column ascending"
          >
            S.no
          </th>{" "}
          <th
            title="Action"
            className="sorting border-right"
            tabIndex="0"
            aria-controls="leads-table"
            rowSpan="1"
            colSpan="1"
            aria-label="Mobile: activate to sort column ascending"
          >
            Action
          </th>
          <th
            title="Passenger Name"
            className="sorting border-right"
            tabIndex="0"
            aria-controls="leads-table"
            rowSpan="1"
            colSpan="1"
            aria-label="Mobile: activate to sort column ascending"
          >
            Passenger Name
          </th>
          <th
            title="Address"
            className="sorting border-right"
            tabIndex="0"
            aria-controls="leads-table"
            rowSpan="1"
            colSpan="1"
            aria-label="Lead Agent: activate to sort column ascending"
          >
            Address
          </th>
          <th
            title="Time"
            className="sorting border-right"
            tabIndex="0"
            aria-controls="leads-table"
            rowSpan="1"
            colSpan="1"
            aria-label="Lead Agent: activate to sort column ascending"
          >
            Time
          </th>
          <th
            title="Notes"
            className="sorting border-right"
            tabIndex="0"
            aria-controls="leads-table"
            rowSpan="1"
            colSpan="1"
            aria-label="Lead Agent: activate to sort column ascending"
          >
            Notes
          </th>
          {/* <th
            title="Status"
            className="sorting border-right"
            tabIndex="0"
            aria-controls="leads-table"
            rowSpan="1"
            colSpan="1"
            aria-label="Lead Agent: activate to sort column ascending"
          >
            Days
          </th> */}
        </tr>
      </thead>
      <tbody>
        {routeDetails?.ridePattern?.map((current, i) => {
          return current?.customerId?.externalCompanyId === auth?.user?.externalCompanyId && (
            <tr role="row" className="odd border" key={i}>
              <td className="border-right">{i + 1}</td>
              <td className="border-right">{current?.routeType}</td>
              <td className="border-right">
                <div>
                  <p className="m-0">
                    {current.customerId?.firstName} {current.customerId?.lastName}
                  </p>
                </div>
              </td>

              <td className="border-right">
                <div>
                  <p className="m-0">
                    {current.routeType == "Pick"
                      ? current.pickUpAddress?.address
                      : current.dropOffAddress?.address}
                  </p>
                  <p className="m-0">
                    {moment(current.pickUpDate).format("DD MMMM, YYYY")} @{" "}
                    {moment(current.pickUpTime, "hh:mm").format(" hh:mm a")}
                  </p>
                </div>
              </td>
              <td className="border-right">
                {current.routeType == "Pick"
                  ? moment(current.pickUpTime, "hh:mm").format(" hh:mm a")
                  : moment(current.dropOffTime, "hh:mm").format(" hh:mm a")}
              </td>
              {/* <td className="border-right">
                {current?.fareAndDistance?.totalMileage} miles
              </td> */}
              {/* <td className="border-right">
                {current?.daysOfWeek.map(
                  (x) => x.active && <span> {x.dayName.slice(0, 3)},</span>
                )}
              </td> */}

              <td className="border-right">{current.dispatchNotes}</td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

export default PayerRidePatternTable;
