import { CLOSE_SIDEBAR, OPEN_SIDEBAR, TOGGLE_SIDEBAR } from "../constants";

const initialState = {
  open: false,
};

export const sidebarReducer = (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_SIDEBAR:
      return {
        ...state,
        open: !state.open,
      };
    case OPEN_SIDEBAR:
      return {
        ...state,
        open: true,
      };
    case CLOSE_SIDEBAR:
      return {
        ...state,
        open: false,
      };
    default:
      return state;
  }
};
