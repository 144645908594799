/** @format */

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AdminBookingAddClientModal from "../../../components/adminComponents/adminBookingComponents/AdminBookingAddClientModal";
import moment from "moment";
import AdminBookingAddDropPick from "../../../components/adminComponents/adminBookingComponents/AdminBookingAddDropPick";
import { clientSearchFilter } from "../../../actions/kpiActions";
import { getLocationsByUserId } from "../../../actions/locationActions";
// import mapboxgl from "mapbox-gl";
import { Link, useNavigate, useParams } from "react-router-dom";
import AdminBookingReturnTrip from "../../../components/adminComponents/adminBookingComponents/AdminBookingReturnTrip";
// import { Accordion } from "react-bootstrap";
import Accordion from "react-bootstrap/Accordion";
import PassengerAccordionComponent from "./PassengerRouteAccordionComponent";
import ArrangeOrderModal from "./ArrangeOrderModal";
// import { destination } from "@turf/turf";
import {
  createRoute,
  getRouteById,
  updateRouteById,
} from "../../../actions/routeActions";
import {
  getAllStudents,
  getRoutePassengersByCompanyId,
} from "../../../actions/userActions";
import ArrangeOrderModalV2 from "./ArrangeOrderModalV2";
import { notifyFailure } from "../../../components/toast";
import Select from "react-select";

// import AdminBookingReturnTrip from './AdminBookingReturnTrip'

const pickObj = {
  pickUp: "",
  pickUpDate: "",
  pickUpTime: "",
  dropOff: "",
  dropOffDate: "",
  dropOffTime: "",
};

const dropObj = {
  pickUp: "",
  pickUpDate: "",
  pickUpTime: "",
  dropOff: "",
  dropOffDate: "",
  dropOffTime: "",
};

const passengerRouteObj = {
  customerId: "",
  type: "",
  // capacity: "1",

  daysOfWeek: [
    { day: 1, dayName: "Monday", active: true },
    { day: 2, dayName: "Tuesday", active: true },
    { day: 3, dayName: "Wednesday", active: true },
    { day: 4, dayName: "Thursday", active: true },
    { day: 5, dayName: "Friday", active: true },
    { day: 6, dayName: "Saturday", active: false },
    { day: 7, dayName: "Sunday", active: false },
  ],
};

const AdminEditRouteForm = ({ current, i }) => {
  const [driverIdForEdit, setDriverIdForEdit] = useState("");
  const params = useParams();
  const directionsService = new window.google.maps.DirectionsService();

  // mapboxgl.accessToken =
  //   "pk.eyJ1IjoiaWNvbmljdGVrIiwiYSI6ImNsZW9oZXlsOTAyZmQzcGw4ejA2Zzk2bmYifQ.StmuWRfUB_3kU8yI5fISxQ";

  const auth = useSelector((state) => state.auth);
  const reservation = useSelector((state) => state.reservation);
  const kpi = useSelector((state) => state.kpi);
  const location = useSelector((state) => state.location);
  const fare = useSelector((state) => state.fare);
  const users = useSelector((state) => state.users);

  const routes = useSelector((state) => state.routes);
  const { routeDetails } = routes;

  const availableDrivers = useSelector(
    (state) => state.users.allDriversAvailableForRoutes
  );

  let navigate = useNavigate();

  const [showDropDown, setshowDropDown] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [calculationFinished, setCalculationFinished] = useState(false);
  const [submitFlag, setSubmitFlag] = useState(false);

  const [isRidePatternArranged, setIsRidePatternArranged] = useState(true);

  const [selectedRoute, setSelectedRoute] = useState(null);
  const [ridePattern, setRidePattern] = useState([]);
  const [ridePatternWithDistance, setRidePatternWithDistance] = useState([]);

  const [customerDetailsArr, setCustomerDetailsArr] = useState([
    passengerRouteObj,
  ]);

  const [customerDetailsAM, setCustomerDetailsAM] = useState([
    // passengerRouteObj,
  ]);
  const [customerDetailsMID, setCustomerDetailsMID] = useState([
    // passengerRouteObj,
  ]);
  const [customerDetailsPM, setCustomerDetailsPM] = useState([
    // passengerRouteObj,
  ]);
  const [passengerRouteWithDistanceArr, setPassengerRouteWithDistanceArr] =
    useState([]);

  const openDropDown = () => {
    setshowDropDown((showDropDown) => !showDropDown);
  };
  const [values, setValues] = useState({});
  // const [driverList, setDriverList] = useState([]);

  // useEffect(() => {
  //   if (values?.driverId) {
  //     setDriverList([values?.driverId, ...availableDrivers]);
  //   } else {
  //     setDriverList(availableDrivers);
  //   }
  // }, [availableDrivers, values]);
  // console.log(values, "valuesvaluesvalues");
  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

  const dispatch = useDispatch();

  let newArr = [];
  let newArrReturn = [];
  let distanceFromBaseLocation = 0;
  let distanceFromPickToDropOff = 0;
  let distanceFromBaseLocationReturn = 0;
  let distanceFromPickToDropOffReturn = 0;
  let totalMileage = 0;
  let totalMileageReturn = 0;

  const handleSubmit = async (event) => {
    event.preventDefault();

    // beforeSubmit();
    // dispatch(
    //   createRoute(
    //     {
    //       companyId: "63cb14f53f55723694dbd185",
    //       routeNumber: values.routeNumber,
    //       bookingDate: new Date().toISOString,
    //       customerDetails: passengerRouteWithDistanceArr,
    //       dateForReservation: `${customerDetailsArr[0].pickUpDate} ${customerDetailsArr[0].pickUpTime}`
    //     },
    //     successHandler
    //   )
    // );

    newArr = [];
    newArrReturn = [];
    distanceFromBaseLocation = 0;
    distanceFromPickToDropOff = 0;
    distanceFromBaseLocationReturn = 0;
    distanceFromPickToDropOffReturn = 0;
    totalMileage = 0;
    totalMileageReturn = 0;
  };

  const submitRouteHandler = async (event) => {
    // event.preventDefault();
    // beforeSubmit();

    // console.log(
    //   "submit==>",

    // );

    // if (!isRidePatternArranged) {
    //   notifyFailure("Please arrange ride pattern first!");
    //   setShowModal(true);
    //   return;
    // }

    // if (!calculationFinished) {
    //   notifyFailure("Please wait until ride pattern calculation is finished!");
    //   setSubmitFlag(true);
    //   return;
    // }

    submitRoute();
    // console.log("ride pattern=>", ridePattern);
  };

  const submitRoute = () => {
    setSubmitFlag(false);
    const totalAmount = customerDetailsArr.reduce(function (
      previousValue,
      currentValue
    ) {
      return previousValue + currentValue?.fareAndDistance?.rideFare;
    },
    0);
    const chicagoDateOptions = {
      timeZone: "America/Chicago",
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    };

    const chicagoDate = new Date().toLocaleDateString(
      "en-US",
      chicagoDateOptions
    );
    const parts = chicagoDate.split("/");
    const formattedChicagoDate = `${parts[2]}-${parts[0]}-${parts[1]}`;
    const amShift = customerDetailsAM.map((x) => {
      // console.log(
      //   x,
      //   !x.dateRange || x?.dateRange?.length === 0,
      //   !x?.dateRange,
      //   x?.dateRange?.length === 0,
      //   x?.dateRange,
      //   x?.dateRange?.length,
      //   "check"
      // );
      return !x.dateRange || x.dateRange.length === 0
        ? { ...x, dateRange: [{ start: formattedChicagoDate }] }
        : x;
    });
    const pmShift = customerDetailsPM.map((x) => {
      return !x.dateRange || x.dateRange.length === 0
        ? { ...x, dateRange: [{ start: formattedChicagoDate }] }
        : x;
    });
    const midShift = customerDetailsMID.map((x) => {
      return !x.dateRange || x.dateRange.length === 0
        ? { ...x, dateRange: [{ start: formattedChicagoDate }] }
        : x;
    });
    const routeData = {
      _id: values.id,
      customerDetailsAM: amShift,
      customerDetailsMID: pmShift,
      customerDetailsPM: midShift,
      driverId: values.driverId ? values.driverId.id : "",
      assigneeId: values.driverId ? auth.user.id : "",
      assignedDate: moment().format().toString().substring(0, 19) + ".000Z",
      // customerDetails: customerDetailsArr.map((x) => ({
      //   ...x,
      //   customerId: x?.customerId?.id,
      // })),
      // ridePattern: ridePatternWithDistance,
      // ridePattern: ridePattern.map((x) => ({
      //   ...x,
      //   customerId:
      //     typeof x.customerId == "object" ? x?.customerId?.id : x?.customerId,
      //   type: x.type?.id,

      //   routeNumber: values.routeNumber,
      //   // routeNumberReturn: `return-${values.routeNumber}`,
      //   // customerDetails: passengerRouteWithDistanceArr,

      //   // ridePattern:
      //   //   orignalRidePattern.length > 0
      //   //     ? orignalRidePatternWithDistance
      //   //     : tempOrignalRouteArr,
      //   ridePattern: ridePatternWithDistance,
      //   bookingDate: new Date().toISOString(),
      //   totalAmount: customerDetailsArr.reduce(function (
      //     previousValue,
      //     currentValue
      //   ) {
      //     return previousValue + currentValue?.fareAndDistance?.rideFare;
      //   },
      //   0),
      //   isReturnRoute: customerDetailsArr.find((z) => z.returnRoute)
      //     ? true
      //     : false,
      //   totalMileage: customerDetailsArr.reduce(function (
      //     previousValue,
      //     currentValue
      //   ) {
      //     return previousValue + currentValue?.fareAndDistance?.totalMileage;
      //   },
      //   0),
      //   totalDrivenMileage: ridePatternWithDistance.reduce(function (
      //     previousValue,
      //     currentValue
      //   ) {
      //     return previousValue + currentValue?.distanceFromLastPoint;
      //   },
      //   0),
      // })),
      // ridePattern: ridePatternWithDistance.flatMap(),
      // .map((x, i) => ({
      //   ...x,
      //   customerDetails: customerDetailsArr[i],
      // })),
      totalAmount: totalAmount ? totalAmount : 0,

      // totalMileage: customerDetailsArr.reduce(function (
      //   previousValue,
      //   currentValue
      // ) {
      //   return previousValue + currentValue?.fareAndDistance?.totalMileage;
      // },
      // 0),
      // totalDrivenMileage: ridePatternWithDistance.reduce(function (
      //   previousValue,
      //   currentValue
      // ) {
      //   return previousValue + currentValue?.distanceFromLastPoint;
      // },
      // 0),
    };
    // if (routeData.driverId == "") {
    //   delete routeData.driverId;
    // }
    if (routeData.assigneeId == "") {
      delete routeData.assigneeId;
    }
    if (selectedRoute?.driverId?.id == routeData?.driverId) {
      delete routeData.driverId;
      delete routeData.assignedDate;
    }
    if (driverIdForEdit && routeData?.driverId == driverIdForEdit) {
      delete routeData.driverId;
    } else {
      if (
        (routeData?.driverId === "" || routeData?.driverId === undefined) &&
        driverIdForEdit !== ""
      ) {
        delete routeData.driverId;
        routeData.removeDriver = true;
        routeData.assigneeId = auth.user.id;
      }
      routeData.assignedDate =
        moment().format().toString().substring(0, 19) + ".000Z";
    }
    dispatch(updateRouteById(routeData, successHandler));

    // createRoute(
    //   {
    //     companyId: auth.user.companyId.id,
    //     routeNumber: values.routeNumber,
    //     customerDetails: passengerRouteWithDistanceArr,
    //     dateForReservation: moment(
    //       `${customerDetailsArr[0].pickUpDate} ${customerDetailsArr[0].pickUpTime}`
    //     ).toISOString(),
    //     bookingDate: new Date().toISOString(),
    //     totalAmount: 41.25,
    //   },
    //   successHandler
    // )
    // newArr = [];
    // newArrReturn = [];
    // distanceFromBaseLocation = 0;
    // distanceFromPickToDropOff = 0;
    // distanceFromBaseLocationReturn = 0;
    // distanceFromPickToDropOffReturn = 0;
    // totalMileage = 0;
    // totalMileageReturn = 0;
  };

  const successHandler = () => {
    navigate("/routes");
  };

  useEffect(() => {
    if (calculationFinished && submitFlag) submitRoute();
  }, [calculationFinished, submitFlag]);

  // console.log("dat==>", customerDetailsArr);

  const [mil, setMil] = useState(0);

  useEffect(() => {
    dispatch(getRouteById(params.id));
    // dispatch(
    //   getAllStudents({
    //     companyId: auth.user.companyId.id,
    //   })
    // );
    dispatch(
      getRoutePassengersByCompanyId({
        companyId: auth.user.companyId.id,
      })
    );
  }, []);

  useEffect(() => {
    if (routeDetails?.route) {
      setValues(routeDetails?.route);
      setDriverIdForEdit(
        routeDetails?.driverId
          ? routeDetails?.driverId?.id
          : routeDetails?.route?.driverId?.id
          ? routeDetails?.route?.driverId?.id
          : ""
      );
      setSelectedRoute(routeDetails?.route);
      // setCustomerDetailsArr(
      //   routeDetails?.route?.customerDetails.map((x) => ({
      //     ...x,
      //     // customerId: x?.customerId,
      //   }))
      // );
      setCustomerDetailsAM(
        routeDetails?.route?.customerDetailsAM
          .filter((detail) => {
            const isInRange =
              (!detail.dateRange[detail.dateRange.length - 1]?.end &&
                detail.dateRange.some(
                  ({ start }) =>
                    new Date(start).toISOString().split("T")[0] +
                      "T00:00:00.000Z" <=
                    formattedChicagoDate + "T00:00:00.000Z"
                )) ||
              (detail.dateRange[detail.dateRange.length - 1]?.end &&
                detail.dateRange.some(
                  ({ start, end }) =>
                    new Date(start).toISOString().split("T")[0] +
                      "T00:00:00.000Z" <=
                      formattedChicagoDate + "T00:00:00.000Z" &&
                    new Date(end).toISOString().split("T")[0] +
                      "T00:00:00.000Z" >
                      formattedChicagoDate + "T00:00:00.000Z"
                ));
            return isInRange;
          })
          .map((x) => ({
            ...x,
            // customerId: x?.customerId,
          }))
      );

      setCustomerDetailsMID(
        routeDetails?.route?.customerDetailsMID
          .filter((detail) => {
            const isInRange =
              (!detail.dateRange[detail.dateRange.length - 1]?.end &&
                detail.dateRange.some(
                  ({ start }) =>
                    new Date(start).toISOString().split("T")[0] +
                      "T00:00:00.000Z" <=
                    formattedChicagoDate + "T00:00:00.000Z"
                )) ||
              (detail.dateRange[detail.dateRange.length - 1]?.end &&
                detail.dateRange.some(
                  ({ start, end }) =>
                    new Date(start).toISOString().split("T")[0] +
                      "T00:00:00.000Z" <=
                      formattedChicagoDate + "T00:00:00.000Z" &&
                    new Date(end).toISOString().split("T")[0] +
                      "T00:00:00.000Z" >
                      formattedChicagoDate + "T00:00:00.000Z"
                ));
            return isInRange;
          })
          .map((x) => ({
            ...x,
            // customerId: x?.customerId,
          }))
      );

      setCustomerDetailsPM(
        routeDetails?.route?.customerDetailsPM
          .filter((detail) => {
            const isInRange =
              (!detail.dateRange[detail.dateRange.length - 1]?.end &&
                detail.dateRange.some(
                  ({ start }) =>
                    new Date(start).toISOString().split("T")[0] +
                      "T00:00:00.000Z" <=
                    formattedChicagoDate + "T00:00:00.000Z"
                )) ||
              (detail.dateRange[detail.dateRange.length - 1]?.end &&
                detail.dateRange.some(
                  ({ start, end }) =>
                    new Date(start).toISOString().split("T")[0] +
                      "T00:00:00.000Z" <=
                      formattedChicagoDate + "T00:00:00.000Z" &&
                    new Date(end).toISOString().split("T")[0] +
                      "T00:00:00.000Z" >
                      formattedChicagoDate + "T00:00:00.000Z"
                ));
            return isInRange;
          })
          .map((x) => ({
            ...x,
            // customerId: x?.customerId,
          }))
      );
      // setRidePattern(
      //   routeDetails?.route?.ridePattern.map((x) => ({
      //     ...x,
      //     customerId: x?.customerId?.id,
      //     customerDetails: { ...x.customerDetails, customerId: x?.customerId },
      //     type: x.type?.id,
      //   }))
      // );

      // setRidePattern(
      //   routeDetails?.route?.ridePattern.map((x) => ({
      //     ...x,
      //     customerId: x?.customerId?.id,
      //     // customerDetails: { ...x.customerDetails, customerId: x?.customerId },
      //     type: x.type?.id,
      //   }))
      // );
      // setIsRidePatternArranged(false);
    } else {
      // console.log(routeDetails, "routeDetailsrouteDetails");
      setValues(routeDetails);
      setDriverIdForEdit(
        routeDetails?.driverId
          ? routeDetails?.driverId?.id
          : routeDetails?.route?.driverId?.id
          ? routeDetails?.route?.driverId?.id
          : ""
      );
      setSelectedRoute(routeDetails);
      setCustomerDetailsAM(
        routeDetails?.customerDetailsAM
          .filter((detail) => {
            const isInRange =
              (!detail.dateRange[detail.dateRange.length - 1]?.end &&
                detail.dateRange.some(
                  ({ start }) =>
                    new Date(start).toISOString().split("T")[0] +
                      "T00:00:00.000Z" <=
                    formattedChicagoDate + "T00:00:00.000Z"
                )) ||
              (detail.dateRange[detail.dateRange.length - 1]?.end &&
                detail.dateRange.some(
                  ({ start, end }) =>
                    new Date(start).toISOString().split("T")[0] +
                      "T00:00:00.000Z" <=
                      formattedChicagoDate + "T00:00:00.000Z" &&
                    new Date(end).toISOString().split("T")[0] +
                      "T00:00:00.000Z" >
                      formattedChicagoDate + "T00:00:00.000Z"
                ));
            return isInRange;
          })
          .map((x) => ({
            ...x,
            // customerId: x?.customerId,
          }))
      );
      setCustomerDetailsMID(
        routeDetails?.customerDetailsMID
          .filter((detail) => {
            const isInRange =
              (!detail.dateRange[detail.dateRange.length - 1]?.end &&
                detail.dateRange.some(
                  ({ start }) =>
                    new Date(start).toISOString().split("T")[0] +
                      "T00:00:00.000Z" <=
                    formattedChicagoDate + "T00:00:00.000Z"
                )) ||
              (detail.dateRange[detail.dateRange.length - 1]?.end &&
                detail.dateRange.some(
                  ({ start, end }) =>
                    new Date(start).toISOString().split("T")[0] +
                      "T00:00:00.000Z" <=
                      formattedChicagoDate + "T00:00:00.000Z" &&
                    new Date(end).toISOString().split("T")[0] +
                      "T00:00:00.000Z" >
                      formattedChicagoDate + "T00:00:00.000Z"
                ));
            return isInRange;
          })
          .map((x) => ({
            ...x,
            // customerId: x?.customerId,
          }))
      );
      setCustomerDetailsPM(
        routeDetails?.customerDetailsPM
          .filter((detail) => {
            const isInRange =
              (!detail.dateRange[detail.dateRange.length - 1]?.end &&
                detail.dateRange.some(
                  ({ start }) =>
                    new Date(start).toISOString().split("T")[0] +
                      "T00:00:00.000Z" <=
                    formattedChicagoDate + "T00:00:00.000Z"
                )) ||
              (detail.dateRange[detail.dateRange.length - 1]?.end &&
                detail.dateRange.some(
                  ({ start, end }) =>
                    new Date(start).toISOString().split("T")[0] +
                      "T00:00:00.000Z" <=
                      formattedChicagoDate + "T00:00:00.000Z" &&
                    new Date(end).toISOString().split("T")[0] +
                      "T00:00:00.000Z" >
                      formattedChicagoDate + "T00:00:00.000Z"
                ));
            return isInRange;
          })
          .map((x) => ({
            ...x,
            // customerId: x?.customerId,
          }))
      );
    }
  }, [routeDetails]);
  // useEffect(() => {
  //   setIsRidePatternArranged(false);
  // }, [customerDetailsArr.customerId]);

  // console.log("cust det arr==>", customerDetailsArr);
  // const calculateMileageAndTime = async (data) => {
  //   setCalculationFinished(false);
  //   let drivenMileage = 0;

  //   // console.log("cust det arr==>", customerDetailsArr);

  //   // let lastTime = data[0].time;
  //   let lastTime = customerDetailsArr.find(
  //     (x) => x?.customerId?.id === data[0].customerId
  //   )?.pickUpTime;

  //   let arr = [
  //     {
  //       // customerDetails: {
  //       //   ...data[0].customerDetails,
  //       //   customerId: data[0].customerId,
  //       // },
  //       customerDetails: {
  //         ...customerDetailsArr.find(
  //           (x) => x?.customerId?.id === data[0]?.customerId
  //         ),
  //         customerId: data[0].customerId,
  //       },
  //       customerId: data[0].customerId,

  //       distanceFromLastPoint: 0,
  //       routeType: data[0].routeType,
  //       address: data[0].address,
  //       time: lastTime,
  //       timeFromLastPoint: `0 mins`,
  //     },
  //   ];

  //   let eta = { hour: 0, mins: 0 };

  //   let estimatedTime;

  //   for (let i = 1; i < data.length; i++) {
  //     if (data[i - 1].address?.address == data[i].address?.address) {
  //       arr.push({
  //         // customerDetails: data[i].customerDetails,
  //         // customerDetails: {
  //         //   ...data[i].customerDetails,
  //         //   customerId: data[i].customerId,
  //         // },
  //         customerDetails: {
  //           ...customerDetailsArr.find(
  //             (x) => x?.customerId?.id === data[i]?.customerId
  //           ),
  //           customerId: data[i].customerId,
  //         },
  //         customerId: data[i].customerId,

  //         distanceFromLastPoint: 0,
  //         routeType: data[i].routeType,
  //         address: data[i].address,
  //         time: lastTime,
  //         timeFromLastPoint: data[i].timeFromLastPoint,
  //       });
  //     } else {
  //       await directionsService
  //         .route({
  //           origin: {
  //             query: data[i - 1]?.address?.address,
  //           },
  //           destination: {
  //             query: data[i]?.address?.address,
  //           },
  //           travelMode: window.google.maps.TravelMode.DRIVING,
  //         })
  //         .then((response) => {
  //           console.log("response2", response);
  //           drivenMileage = parseFloat(
  //             response.routes[0].legs[0].distance.text
  //               .replace(/,/g, "")
  //               .split(" mi")[0]
  //           );

  //           let oldDate = new Date("2023-04-20T" + lastTime + ":00.000Z");

  //           // yahan se new logic
  //           if (response.routes[0].legs[0].duration.text.length > 7) {
  //             const timeString = response.routes[0].legs[0].duration.text;
  //             const hourRegex = /(\d+)\s*hour/;
  //             const minuteRegex = /(\d+)\s*min/;

  //             const hourMatch = hourRegex.exec(timeString);
  //             const minuteMatch = minuteRegex.exec(timeString);

  //             const hours = hourMatch ? parseInt(hourMatch[1]) : 0;
  //             const minutes = minuteMatch ? parseInt(minuteMatch[1]) : 0;

  //             eta.hour = hours;
  //             eta.mins = minutes;

  //             let aaa = (hours * 60 + minutes) * 60 * 1000;

  //             estimatedTime = new Date(aaa + new Date(oldDate).getTime())
  //               .toISOString()
  //               .slice(11, 16);

  //             arr.push({
  //               // customerDetails: data[i].customerDetails,
  //               // customerId: data[i].customerId,

  //               // customerDetails: {
  //               //   ...data[i].customerDetails,
  //               //   customerId: data[i].customerId,
  //               // },
  //               customerDetails: {
  //                 ...customerDetailsArr.find(
  //                   (x) => x?.customerId?.id === data[i]?.customerId
  //                 ),
  //                 customerId: data[i].customerId,
  //               },
  //               customerId: data[i].customerId,

  //               distanceFromLastPoint: drivenMileage,
  //               timeFromLastPoint: `${aaa / 60000} mins`,
  //               routeType: data[i].routeType,
  //               address: data[i].address,
  //               time: estimatedTime,
  //             });
  //             // setCustomerDetailsArr(
  //             //   customerDetailsArr.map((x, i) =>
  //             //     i === index
  //             //       ? {
  //             //           ...x,
  //             //           dropOffTime,
  //             //         }
  //             //       : x
  //             //   )
  //             // );
  //           } else {
  //             eta.mins = parseInt(
  //               response.routes[0].legs[0].duration.text.split(" mins")[0]
  //             );

  //             let aaa =
  //               parseInt(
  //                 response.routes[0].legs[0].duration.text.split(" mins")[0]
  //               ) *
  //               60 *
  //               1000;

  //             estimatedTime = new Date(aaa + new Date(oldDate).getTime())
  //               .toISOString()
  //               .slice(11, 16);

  //             arr.push({
  //               // customerDetails: customerDetailsArr[i],
  //               // customerId: data[i].customerId,

  //               // customerDetails: {
  //               //   ...data[i].customerDetails,
  //               //   customerId: data[i].customerId,
  //               // },
  //               customerDetails: {
  //                 ...customerDetailsArr.find(
  //                   (x) => x?.customerId?.id === data[i]?.customerId
  //                 ),
  //                 customerId: data[i].customerId,
  //               },
  //               customerId: data[i].customerId,

  //               distanceFromLastPoint: drivenMileage,
  //               routeType: data[i].routeType,
  //               address: data[i].address,
  //               time: estimatedTime,
  //               timeFromLastPoint: `${aaa / 60000} mins`,
  //             });

  //             // setCustomerDetailsArr(
  //             //   customerDetailsArr.map((x, i) =>
  //             //     i === index
  //             //       ? {
  //             //           ...x,
  //             //           dropOffTime: new Date(aaa + new Date(oldDate).getTime())
  //             //             .toISOString()
  //             //             .slice(11, 16),
  //             //         }
  //             //       : x
  //             //   )
  //             // );
  //           }
  //         });
  //       lastTime = estimatedTime;
  //     }
  //   }
  //   // console.log("mileage arr orignal==>", arr, orignalRidePattern);

  //   // console.log("ride patt in==>", data, arr);
  //   setRidePatternWithDistance(arr);
  //   setCalculationFinished(true);
  // };

  // useEffect(() => {
  //   if (ridePattern.length > 0) {
  //     calculateMileageAndTime(ridePattern);
  //     // console.log("chala");
  //   }
  // }, [
  //   ridePattern,
  //   customerDetailsArr,
  //   // customerDetailsArr.pickUpTime,
  //   // customerDetailsArr.pickUpAddress,
  //   // customerDetailsArr.dropOffAddress,
  //   // customerDetailsArr.daysOfWeek,
  // ]);

  // useEffect(() => {

  //   calculateFareFrontEnd();

  // }, [customerDetailsArr]);

  // console.log("ride patt d==>", ridePattern, ridePatternWithDistance);

  // console.log("cust det==>", customerDetailsArr);

  // console.log("selectedRoute=>", customerDetailsAM);

  // const calculateFareAndDistanceAM = async () => {
  //   let tempPassengerRouteArr = [];
  //   for (let i = 0; i < customerDetailsAM.length; i++) {
  //     const data = customerDetailsAM[i];
  //     let obj = {};
  //     console.log("obj 1 ==>", obj);
  //     // console.log("calculateFareFrontEnd")

  //     const abc = JSON.parse(data?.type);
  //     // customerDetailsArr.forEach((e) => {

  //     //   newArr.push(e?.address?.address);
  //     // });

  //     // distanceFromBaseLocation = await calculateDistance(
  //     //   location.baseLocation.address,
  //     //   data.pickUpAddress.address
  //     // );

  //     await directionsService
  //       .route({
  //         origin: {
  //           query: location.baseLocation.address,
  //         },
  //         destination: {
  //           query: data.pickUpAddress.address,
  //         },
  //         travelMode: window.google.maps.TravelMode.DRIVING,
  //       })
  //       .then((response) => {
  //         console.log("response", response);
  //         distanceFromBaseLocation = parseFloat(
  //           response.routes[0].legs[0].distance.text
  //             .replace(/,/g, "")
  //             .split(" mi")[0]
  //         );
  //       })
  //       .catch((e) => {
  //         console.log("Directions request failed due to ", e);
  //         // window.alert("Directions request failed due to ", e)
  //       });

  //     obj.distanceFromBaseLocation = distanceFromBaseLocation;
  //     console.log("obj 2 ==>", obj);

  //     // distanceFromPickToDropOff = await calculateDistance(
  //     //   data.pickUpAddress.address,
  //     //   data.dropOffAddress.address
  //     // );

  //     await directionsService
  //       .route({
  //         origin: {
  //           query: data.pickUpAddress.address,
  //         },
  //         destination: {
  //           query: data.dropOffAddress.address,
  //         },
  //         travelMode: window.google.maps.TravelMode.DRIVING,
  //       })
  //       .then((response) => {
  //         console.log("response", response);
  //         distanceFromPickToDropOff = parseFloat(
  //           response.routes[0].legs[0].distance.text
  //             .replace(/,/g, "")
  //             .split(" mi")[0]
  //         );
  //       })
  //       .catch((e) => {
  //         console.log("Directions request failed due to ", e);
  //         // window.alert("Directions request failed due to ", e)
  //       });

  //     obj.distanceFromPickToDropOff = distanceFromPickToDropOff;
  //     console.log("obj 3 ==>", obj);

  //     // console.log(distanceFromPickToDropOff, "distanceFromPickToDropOff")

  //     totalMileage = obj.totalMileage;
  //     obj.totalMileage =
  //       obj.distanceFromBaseLocation + obj.distanceFromPickToDropOff;

  //     let fares = fare.fare;

  //     let baseFare;
  //     let rideFare;
  //     let companyFare = [];
  //     let serviceFare = [];
  //     let offHourFee = 0;

  //     const customer = users?.allClients.find((x) => x.id == data.customerId);

  //     console.log("customer==>", customer, data);

  //     if (customer.isCompany === false) {
  //       fares = fare.fare[0].details;
  //       fares.forEach((e) => {
  //         if (e.serviceId.id === abc.id) {
  //           serviceFare.push(e);
  //         }
  //       });
  //     } else {
  //       // Yahn msla hai

  //       fares.forEach((e) => {
  //         // console.log(e,e.business.id === customer.externalCompanyId, e.business.id,customer.externalCompanyId)
  //         if (e.business.id === customer.externalCompanyId) {
  //           companyFare.push(...e.details);
  //         }
  //       });

  //       companyFare.forEach((e) => {
  //         if (e.serviceId.id === abc.id) {
  //           serviceFare.push(e);
  //         }
  //       });
  //     }
  //     serviceFare.sort((a, b) => a.totalMileageAllows - b.totalMileageAllows);
  //     let _fare;

  //     for (i = 0; i < serviceFare.length; i++) {
  //       if (totalMileage <= serviceFare[i].totalMileageAllows) {
  //         _fare = serviceFare[i];
  //         // console.log(_fare)
  //         break;
  //       }
  //     }

  //     if (!_fare) {
  //       _fare = serviceFare[serviceFare.length - 1];
  //     }
  //     obj.fare = _fare;

  //     console.log("obj fare==>", obj);
  //     if (distanceFromBaseLocation <= _fare.deadHeadThreshold) {
  //       baseFare = _fare.flatBaseFare;
  //       obj.baseFare = baseFare;
  //       obj.baseFareStructure = {
  //         values: { flatBaseFare: _fare.flatBaseFare },
  //         valuesFormula: "flatBaseFare",
  //       };
  //     } else {
  //       baseFare =
  //         _fare.flatBaseFare + _fare.baseFarePerMile * distanceFromBaseLocation;
  //       obj.baseFare = baseFare;
  //       obj.baseFareStructure = {
  //         values: {
  //           flatBaseFare: _fare.flatBaseFare,
  //           baseFarePerMile: _fare.baseFarePerMile,
  //           distanceFromBaseLocation: distanceFromBaseLocation,
  //         },
  //         valuesFormula:
  //           "flatBaseFare + (baseFarePerMile * distanceFromBaseLocation)",
  //       };
  //     }

  //     if (distanceFromPickToDropOff <= _fare.totalMileageAllows) {
  //       // console.log(distanceFromPickToDropOff, "distanceFromPickToDropOff")
  //       rideFare = distanceFromPickToDropOff * _fare.farePerMile;
  //       obj.rideFare = rideFare;
  //       obj.rideFareStructure = {
  //         values: {
  //           distanceFromPickToDropOff: distanceFromPickToDropOff,
  //           farePerMile: _fare.farePerMile,
  //         },
  //         valuesFormula: "distanceFromPickToDropOff * farePerMile",
  //       };
  //     } else {
  //       rideFare = distanceFromPickToDropOff * _fare.longDistancePerMile;
  //       obj.rideFare = rideFare;
  //       obj.rideFareStructure = {
  //         values: {
  //           distanceFromPickToDropOff: distanceFromPickToDropOff,
  //           longDistancePerMile: _fare.longDistancePerMile,
  //         },
  //         valuesFormula: "distanceFromPickToDropOff * longDistancePerMile",
  //       };
  //     }

  //     console.log("obj last==>", obj);
  //     tempPassengerRouteArr.push({
  //       ...data,
  //       type: JSON.parse(data.type)?.id,
  //       fareAndDistance: obj,
  //     });
  //   }
  //   setPassengerRouteWithDistanceArr(tempPassengerRouteArr);
  // };

  // For Initial
  // useEffect(() => {
  //   if (
  //     customerRoute.customerId &&
  //     customerRoute.type &&
  //     customerRoute.pickUpAddress &&
  //     customerRoute.dropOffAddress &&
  //     customerRoute.pickUpTime
  //   ) {
  //     calculateFareAndDistance({
  //       pickUpAddress: customerRoute.pickUpAddress,
  //       dropOffAddress: customerRoute.dropOffAddress,
  //       field: "fareAndDistance",
  //       pickUpTimeField: "pickUpTime",
  //       dropOffTimeField: "dropOffTime",
  //     });
  //   }
  // }, [
  //   customerRoute.customerId,
  //   customerRoute.typ,
  //   customerRoute.pickUpAddress,
  //   customerRoute.dropOffAddress,
  //   customerRoute.pickUpTime,
  // ]);

  // // For AM
  // useEffect(() => {
  //   calculateFareAndDistance("AM");
  // }, [customerDetailsAM]);

  // // PM
  // useEffect(() => {
  //   calculateFareAndDistance("PM");
  // }, [customerDetailsPM]);

  // // MID
  // useEffect(() => {
  //   calculateFareAndDistance("MID");
  // }, [customerDetailsMID]);
  const chicagoDateOptions = {
    timeZone: "America/Chicago",
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  };
  const chicagoDate = new Date().toLocaleDateString(
    "en-US",
    chicagoDateOptions
  );
  const parts = chicagoDate.split("/");
  const formattedChicagoDate = `${parts[2]}-${parts[0]}-${parts[1]}`;
  return (
    <>
      {/* {values?.status === "In Progress" && (
        <div className="alert alert-danger" role="alert">
          <i className="bi bi-exclamation-triangle-fill"></i> &nbsp; This Route in
          Progress, Can't be Editable.
        </div>
      )} */}

      {selectedRoute && (
        <div className="content-wrapper">
          <div className="d-flex flex-column w-tables rounded mt-3 bg-white">
            <div
              id="leads-table_wrapper"
              className="dataTables_wrapper dt-bootstrap4 no-footer p-3"
            >
              <form onSubmit={handleSubmit}>
                <div className="bg-white  text-capitalize d-flex justify-content-between  border-bottom-grey pb-3 px-2">
                  <h4 className="f-18 f-w-500 mb-0">Edit Route</h4>
                </div>
                <div className=" bg-white border-0 text-capitalize d-flex justify-content-between  pb-3 pt-3 px-2">
                  <h4 className="f-18 f-w-500 mb-0">Basic info</h4>
                </div>
                <div className="row">
                  <div className="col-xl-8 col-lg-12 col-md-12 e-d-info-notices">
                    <div className="row">
                      <div className="col-sm-4 pl-4">
                        <div className="form-group my-3">
                          <div className="d-flex">
                            <div>
                              <label
                                className="f-14 text-dark mb-12"
                                data-label=""
                                htmlFor="default_task_status"
                              >
                                Route Number
                              </label>
                            </div>
                          </div>
                          <div className="dropdown bootstrap-select form-control select-picker">
                            <input
                              type="text"
                              className="form-control height-35 f-14"
                              placeholder="Route Number"
                              value={`${values.routeNumber} ${
                                values.routeNumberReturn ? "( Return )" : ""
                              }`}
                              name="routeNumber"
                              onChange={handleChange}
                              disabled
                            />
                          </div>
                        </div>
                      </div>

                      <div className="col-sm-4 pl-4">
                        <div className="form-group my-3">
                          <div className="d-flex justify-content-between">
                            <div className="d-flex">
                              <div>
                                <label
                                  className="f-14 text-dark mb-12"
                                  data-label=""
                                  htmlFor="clnumber"
                                >
                                  Assign Driver
                                </label>
                              </div>
                              {/* <div className="p-1">
                                <sup className="text-danger f-12">*</sup>
                              </div> */}
                            </div>
                          </div>
                          <Select
                            className="basic-single"
                            classNamePrefix="select"
                            defaultValue={""}
                            value={[
                              // {
                              //   label: `${values?.driverId?.firstName} ${values?.driverId?.lastName} `,
                              //   value: values?.driverId?.id,
                              // },
                              ...(selectedRoute?.driverId
                                ? [
                                    {
                                      label: `${selectedRoute?.driverId?.firstName} ${selectedRoute?.driverId?.lastName} `,
                                      firstName: `${selectedRoute?.driverId?.firstName}`,
                                      lastName: `${selectedRoute?.driverId?.lastName}`,
                                      value: selectedRoute?.driverId?.id,
                                    },
                                  ]
                                : []),
                              ...availableDrivers?.map((x) => ({
                                label: `${x.firstName} ${x.lastName}`,
                                firstName: `${x.firstName}`,
                                lastName: `${x.lastName}`,
                                value: x.id,
                              })),
                            ].find(
                              (customer) =>
                                customer.value == values?.driverId?.id
                            )}
                            // value={driverList
                            //   ?.map((x) => ({
                            //     label: `${x.firstName} ${x.lastName}`,
                            //     value: x?.id,
                            //   }))
                            //   .find(
                            //     (customer) =>
                            //       customer?.value?.id == values?.driverId?.id
                            //   )}
                            isDisabled={false}
                            // isLoading={searchingClients}
                            isClearable={false}
                            isRtl={false}
                            isSearchable={true}
                            // required={true}
                            // name="pickUp"
                            onChange={(e) => {
                              setValues({
                                ...values,
                                // driverId: e.value,
                                driverId: {
                                  firstName: e.firstName,
                                  lastName: e.lastName,
                                  id: e.value,
                                },
                              });
                            }}
                            options={[
                              // {
                              //   label: `${values?.driverId?.firstName} ${values?.driverId?.lastName} `,
                              //   value: values?.driverId?.id,
                              // },
                              { label: "(Choose Driver)", value: "" },
                              ...(selectedRoute?.driverId
                                ? [
                                    {
                                      label: `${selectedRoute?.driverId?.firstName} ${selectedRoute?.driverId?.lastName} `,
                                      firstName: `${selectedRoute?.driverId?.firstName}`,
                                      lastName: `${selectedRoute?.driverId?.lastName}`,
                                      value: selectedRoute?.driverId?.id,
                                    },
                                  ]
                                : []),
                              ...availableDrivers?.map((x) => ({
                                label: `${x.firstName} ${x.lastName} `,
                                firstName: `${x.firstName}`,
                                lastName: `${x.lastName}`,
                                value: x.id,
                              })),
                            ]}
                            // options={driverList?.map((x) => ({
                            //   label: `${x.firstName} ${x.lastName} `,
                            //   value: x.id,
                            // }))}
                            // options={{
                            //   label: `${x.firstName} ${x.lastName} `,
                            //   value: x.id,
                            // }}
                          />
                        </div>
                      </div>

                      {/* <div className="col-sm-4 pl-4">
                      <div className="form-group my-3">
                        <div className="d-flex justify-content-between">
                          <div className="d-flex">
                            <div>
                              <label
                                className="f-14 text-dark mb-12"
                                data-label=""
                                htmlFor="clnumber"
                              >
                                Customer Name
                              </label>
                            </div>
                            <div className="p-2">
                              <sup className="text-danger f-12">*</sup>
                            </div>
                          </div>
                          <div>
                            <button
                              className="bg-button"
                              type="button"
                              data-toggle="modal"
                              data-target="#exampleModalCenter"
                              onClick={openDropDown}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                fill="currentColor"
                                className="bi bi-plus-circle-fill fa-w-20 f-20 text-dark"
                                viewBox="0 0 16 16"
                              >
                                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3v-3z" />
                              </svg>
                            </button>
                          </div>
                        </div>
                        <input
                          type="text"
                          className="form-control height-35 f-14"
                          placeholder="Search Customer Name..."
                          value={values.name}
                          onChange={handleChange("name")}
                          required
                        />
                        <br />
                        {displayClient === true && (
                          <div style={{ marginTop: "-22px" }}>
                            <ul className="ul-css">
                              {kpi.searchedCustomer &&
                              kpi.searchedCustomer.length > 0 ? (
                                kpi.searchedCustomer.map((current, i) => {
                                  return (
                                    <li
                                      className="li-css"
                                      style={{ cursor: "pointer" }}
                                      key={i}
                                      onClick={() =>
                                        onClickCustomer(
                                          JSON.stringify({
                                            id: current.id,
                                            firstName: current.firstName,
                                            lastName: current.lastName,
                                            isCompany: current.isCompany,
                                            externalCompanyId:
                                              current.externalCompanyId
                                                ? current.externalCompanyId
                                                : "",
                                          })
                                        )
                                      }
                                    >
                                      {current.firstName +
                                        " " +
                                        current.lastName}
                                    </li>
                                  );
                                })
                              ) : (
                                <li className="li-css" key={"emp"}>
                                  No data
                                </li>
                              )}
                            </ul>
                          </div>
                        )}
                      </div>
                    </div> */}
                    </div>
                  </div>
                </div>
                {customerDetailsAM.length === 0 && (
                  <>
                    <div
                      className="row"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setCustomerDetailsAM([
                          ...customerDetailsAM,
                          passengerRouteObj,
                        ]);
                      }}
                    >
                      <div className="col-12">
                        <div className="bg-gray border-0 text-capitalize d-flex justify-content-between pb-3 pt-3 px-2">
                          <h4 className="f-18 f-w-500 mb-0">Add AM Shift</h4>
                        </div>
                      </div>
                    </div>
                    <br />
                  </>
                )}
                {customerDetailsAM.length > 0 && (
                  <>
                    <div className="row">
                      <div className="col-12">
                        <div className=" bg-gray border-0 text-capitalize d-flex justify-content-between  pb-3 pt-3 px-2">
                          <h4 className="f-18 f-w-500 mb-0">AM Shift</h4>
                        </div>
                      </div>
                    </div>
                    <PassengerAccordionComponent
                      values={values}
                      setValues={setValues}
                      handleChange={handleChange}
                      customerDetailsArr={customerDetailsAM}
                      setCustomerDetailsArr={setCustomerDetailsAM}
                      passengerRouteObj={passengerRouteObj}
                      dropObj={dropObj}
                      setIsRidePatternArranged={setIsRidePatternArranged}
                    />
                  </>
                )}
                {customerDetailsMID.length === 0 && (
                  <>
                    <div
                      className="row"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setCustomerDetailsMID([
                          ...customerDetailsMID,
                          passengerRouteObj,
                        ]);
                      }}
                    >
                      <div className="col-12">
                        <div className="bg-gray border-0 text-capitalize d-flex justify-content-between pb-3 pt-3 px-2">
                          <h4 className="f-18 f-w-500 mb-0">Add MID Shift</h4>
                        </div>
                      </div>
                    </div>
                    <br />
                  </>
                )}
                {customerDetailsMID.length > 0 && (
                  <>
                    <div className="row">
                      <div className="col-12">
                        <div className=" bg-gray border-0 text-capitalize d-flex justify-content-between  pb-3 pt-3 px-2">
                          <h4 className="f-18 f-w-500 mb-0">MID Shift</h4>
                        </div>
                      </div>
                    </div>
                    <PassengerAccordionComponent
                      values={values}
                      setValues={setValues}
                      handleChange={handleChange}
                      customerDetailsArr={customerDetailsMID}
                      setCustomerDetailsArr={setCustomerDetailsMID}
                      passengerRouteObj={passengerRouteObj}
                      dropObj={dropObj}
                      setIsRidePatternArranged={setIsRidePatternArranged}
                    />
                  </>
                )}
                {customerDetailsPM.length === 0 && (
                  <>
                    <div
                      className="row"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setCustomerDetailsPM([
                          ...customerDetailsPM,
                          passengerRouteObj,
                        ]);
                      }}
                    >
                      <div className="col-12">
                        <div className="bg-gray border-0 text-capitalize d-flex justify-content-between pb-3 pt-3 px-2">
                          <h4 className="f-18 f-w-500 mb-0">Add PM Shift</h4>
                        </div>
                      </div>
                    </div>
                    <br />
                  </>
                )}
                {customerDetailsPM.length > 0 && (
                  <>
                    <div className="row">
                      <div className="col-12">
                        <div className=" bg-gray border-0 text-capitalize d-flex justify-content-between  pb-3 pt-3 px-2">
                          <h4 className="f-18 f-w-500 mb-0">PM Shift</h4>
                        </div>
                      </div>
                    </div>
                    <PassengerAccordionComponent
                      values={values}
                      setValues={setValues}
                      handleChange={handleChange}
                      customerDetailsArr={customerDetailsPM}
                      setCustomerDetailsArr={setCustomerDetailsPM}
                      passengerRouteObj={passengerRouteObj}
                      dropObj={dropObj}
                      setIsRidePatternArranged={setIsRidePatternArranged}
                    />
                  </>
                )}
                <div className="row">
                  <div className="col-12 col-md-6 pl-4">
                    {/* <div className="form-group my-5">
                      {true ? (
                        <button
                          type="button"
                          className="btn-primary rounded f-12 p-2 mr-3 "
                          onClick={() => setShowModal(true)}
                          disabled={values?.status === "In Progress"}
                        >
                          Arrange Order
                        </button>
                      ) : (
                        <></>
                      )}
                    </div> */}
                  </div>{" "}
                  <div className="col-12 col-md-6 pl-4">
                    <div className="form-group my-5 text-right">
                      {true ? (
                        <button
                          type="submit"
                          className="btn-primary rounded f-12 p-2 mr-3 "
                          onClick={submitRouteHandler}
                          // disabled={values?.status === "In Progress"}
                        >
                          Update
                        </button>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                </div>{" "}
              </form>
            </div>
          </div>
        </div>
      )}

      {/* <AdminBookingAddDropPick
        showDropLoc={showDropLoc}
        openDropLoc={openDropLoc}
        values={values}
        // stopArr={stopArr}
        // setStopArr={setStopArr}
      /> */}

      {showModal && (
        <ArrangeOrderModalV2
          showModal={showModal}
          setShowModal={setShowModal}
          selectedRoute={selectedRoute}
          setSelectedRoute={setSelectedRoute}
          ridePattern={ridePattern}
          setRidePattern={setRidePattern}
          customerDetailsArr={customerDetailsArr}
          setCustomerDetailsArr={setCustomerDetailsArr}
          isRidePatternArranged={isRidePatternArranged}
          setIsRidePatternArranged={setIsRidePatternArranged}
        />
      )}
    </>
  );
};
export default AdminEditRouteForm;
