/** @format */

import moment from "moment";
import React, { useEffect, useState } from "react";
import LoadingComponentRideModal from "../../LoadingComponentRideModal";
import { SERVERADDRESS } from "../../../constants";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import {
  getReservationById,
  undoInprogressAction,
  undoStartAction,
} from "../../../actions/reservationAction";

const AdminBookingTodayBookingRideLogModal = ({
  showRideLogDropDown,
  openRideLogDropDown,
  current,
  i,
}) => {
  const reservation = useSelector((state) => state.reservation);
  const auth = useSelector((state) => state.auth);
  const [reqData, setReqData] = useState();
  const dataLoaded = async () => {
    dispatch(
      getReservationById({
        _id: current?._id,
      })
    );
    const { data } = await axios.post(
      `${SERVERADDRESS}/v1/reservation/reservationDetails`,
      {
        _id: current?._id,
      }
    );
    setReqData(data);
  };
  // useEffect(() => {
  //   dataLoaded();
  // }, [current]);
  useEffect(() => {
    if (showRideLogDropDown) {
      dataLoaded();
    }
  }, [showRideLogDropDown]);

  // console.log(reqData, "reqData");
  const dispatch = useDispatch();
  const handleUndoAction = async (undoAction) => {
    try {
      const { data } = await axios.put(`${SERVERADDRESS}/v1/trip/undoTrip`, {
        _id: reqData?.trip?.id,
        undoActions: undoAction,
        dispatcherId: auth.user.id,
      });
      if (undoAction === "start") {
        setReqData({ ...reqData, trip: {} });
        dispatch(undoStartAction(data));
      } else {
        setReqData({ ...reqData, trip: data });
        dispatch(undoInprogressAction(data));
      }
    } catch (error) {
      // Handle network or other errors
      console.error("Error performing undo action:", error);
    }
  };

  const lastDispatchNote = reservation?.reservationDetails?.actions
    ? reservation?.reservationDetails?.actions
        ?.slice()
        ?.reverse()
        ?.find((action) => action.action === "DispatchNote")
    : "";
  const utcDate = lastDispatchNote
    ? new Date(lastDispatchNote?.time)
    : new Date();
  const chicagoDate = new Date(
    utcDate.toLocaleString("en-US", {
      timeZone: "America/Chicago",
    })
  );
  const chicagoDateTimeString = chicagoDate.toLocaleString("en-US");
  return (
    <>
      <div
        className={`modal fade ${
          showRideLogDropDown ? "show modal-y-axis" : "hide"
        }`}
        role="dialog"
        data-backdrop="static"
        data-keyboard="false" // To prevent closing with the keyboard
        onClick={(e) => {
          // Check if the click occurred outside the modal content
          if (e.target === e.currentTarget) {
            openRideLogDropDown(); // Call your close modal function
          }
        }}
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header bg-additional-grey">
              <h5 className="modal-title" id="exampleModalLongTitle">
                {" "}
                Ride Logs
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => openRideLogDropDown()}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              {!reqData && <LoadingComponentRideModal />}
              {reqData && (
                <>
                  <div className="row">
                    <div className="col-md-5">
                      <div className="form-group ">
                        <p className="f-18 text-dark mb-12" htmlFor="Name">
                          Assigning Details:
                        </p>
                      </div>
                    </div>
                    <table
                      className="table border dataTable no-footer"
                      id="leads-table"
                      role="grid"
                      aria-describedby="leads-table_info"
                      style={{
                        overflowX: "auto",
                        width: "95%",
                        margin: "auto",
                      }}
                    >
                      <thead className="card-header">
                        <tr role="row">
                          <th
                            title="Assignee's Name"
                            className="sorting border-right"
                            tabIndex="0"
                            aria-controls="leads-table"
                            rowSpan="1"
                            colSpan="1"
                            aria-label="Mobile: activate to sort column ascending"
                          >
                            Assignee's Name
                          </th>
                          <th
                            title="Assigned On"
                            className="sorting_disabled border-right"
                            rowSpan="1"
                            colSpan="1"
                            aria-label="Next Follow Up"
                          >
                            Assigned On
                          </th>
                          <th
                            title="Assigned to"
                            className="sorting_disabled border-right"
                            rowSpan="1"
                            colSpan="1"
                            aria-label="Next Follow Up"
                          >
                            Assigned to
                          </th>
                          <th
                            title="Response Date"
                            className="sorting_disabled border-right"
                            rowSpan="1"
                            colSpan="1"
                            aria-label="Next Follow Up"
                          >
                            Response Date
                          </th>
                          <th
                            title="Status"
                            className="sorting_disabled border-right"
                            rowSpan="1"
                            colSpan="1"
                            aria-label="Next Follow Up"
                          >
                            Status
                          </th>
                          <th
                            title="Reason to Reject"
                            className="sorting_disabled border-right"
                            rowSpan="1"
                            colSpan="1"
                            aria-label="Next Follow Up"
                          >
                            Reason to Reject
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {reqData?.resStatus &&
                          reqData?.resStatus.length > 0 &&
                          reqData?.resStatus.map((e, i) => {
                            return (
                              <tr role="row" className="odd border" key={i}>
                                <td className="border-right">
                                  {e?.assigneeId?.firstName +
                                    " " +
                                    e?.assigneeId?.lastName}
                                </td>
                                <td className="border-right">
                                  {moment(
                                    e?.assignedDate?.substring(0, 10)
                                  ).format("MM/DD/YYYY")}
                                </td>
                                <td className="border-right">
                                  {e?.driverId?.firstName +
                                    " " +
                                    e?.driverId?.lastName}
                                </td>
                                <td className="border-right">
                                  {moment(
                                    e?.responseDate?.substring(0, 10)
                                  ).format("MM/DD/YYYY")}
                                </td>
                                <td className="border-right">
                                  {e?.driverBookingStatus === "Assigned"
                                    ? "Accepeted"
                                    : e?.driverBookingStatus === "Pending"
                                    ? "Pending"
                                    : "Rejected"}
                                </td>
                                <td className="border-right">
                                  {e?.declineReason}
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </div>
                  {/* <div className="row border-bottom-grey">
                  <div className="col-md-5">
                    <div className="form-group ">
                      <p className="f-18 text-dark mb-12" htmlFor="Name">
                        {" "}
                        Assigning Details:
                      </p>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group ">
                      {reqData?.resStatus &&
                        reqData?.resStatus.length > 0 &&
                        reqData?.resStatus.map((e, i) => {
                          return (
                            <p className="f-18 text-dark mb-12" htmlFor="Name">
                              &bull; Admin assigned this ride to {e.driverId.firstName + " " + e.driverId.lastName} on{" "}
                              {moment(e.assignedDate).format("MM/DD/YYYY")}. The
                              Driver has{" "}
                              {e.driverBookingStatus === "Assigned"
                                ? "Accepeted"
                                : `Rejected due to reason: ${e.declineReason}`}{" "}
                              on {moment(e.responseDate).format("MM/DD/YYYY")}.
                            </p>
                          );
                        })}
                    </div>
                  </div>
                </div> */}
                  <br />
                  {reservation?.reservationDetails?.dispatchNotes && (
                    <div className="row ">
                      <table
                        className="table border dataTable no-footer"
                        id="leads-table"
                        role="grid"
                        aria-describedby="leads-table_info"
                        style={{
                          overflowX: "auto",
                          width: "95%",
                          margin: "auto",
                        }}
                      >
                        <thead className="card-header">
                          <tr role="row">
                            <th
                              title="Dispatch Notes"
                              className="sorting border-right"
                              tabIndex="0"
                              aria-controls="leads-table"
                              rowSpan="1"
                              colSpan="1"
                              aria-label="Mobile: activate to sort column ascending"
                            >
                              Dispatch Notes
                            </th>
                            <th
                              title="Dispatch Notes"
                              className="sorting border-right"
                              tabIndex="0"
                              aria-controls="leads-table"
                              rowSpan="1"
                              colSpan="1"
                              aria-label="Mobile: activate to sort column ascending"
                            >
                              Created By
                            </th>
                            <th
                              title="Dispatch Notes"
                              className="sorting border-right"
                              tabIndex="0"
                              aria-controls="leads-table"
                              rowSpan="1"
                              colSpan="1"
                              aria-label="Mobile: activate to sort column ascending"
                            >
                              Time
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr role="row" className="odd border">
                            <td className="border-right">
                              {reservation?.reservationDetails?.dispatchNotes}
                            </td>
                            <td className="">
                              {lastDispatchNote
                                ? lastDispatchNote?.details?.split(" has")[0]
                                : ""}
                            </td>
                            <td className="">
                              {lastDispatchNote ? chicagoDateTimeString : ""}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  )}
                  {current?.cancelReason && (
                    <div className="row ">
                      <table
                        className="table border dataTable no-footer"
                        id="leads-table"
                        role="grid"
                        aria-describedby="leads-table_info"
                        style={{
                          overflowX: "auto",
                          width: "95%",
                          margin: "auto",
                        }}
                      >
                        <thead className="card-header">
                          <tr role="row">
                            <th
                              title="Cancel Reason"
                              className="sorting border-right"
                              tabIndex="0"
                              aria-controls="leads-table"
                              rowSpan="1"
                              colSpan="1"
                              aria-label="Mobile: activate to sort column ascending"
                            >
                              Cancel Reason
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr role="row" className="odd border">
                            <td className="border-right">
                              {current?.cancelReason}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  )}
                  <br />
                  <div className="row border-bottom-grey">
                    <div className="col-md-5">
                      <div className="form-group ">
                        <p className="f-18 text-dark mb-12" htmlFor="Name">
                          Trip Details:{" "}
                          <i
                            className="fas fa-undo-alt f-12"
                            title="Undo Action"
                            style={{ cursor: "pointer" }}
                            onClick={() => handleUndoAction("start")}
                          ></i>
                        </p>
                      </div>
                    </div>

                    <table
                      className="table border dataTable no-footer"
                      id="leads-table"
                      role="grid"
                      aria-describedby="leads-table_info"
                      style={{
                        overflowX: "auto",
                        width: "95%",
                        margin: "auto",
                      }}
                    >
                      <thead className="card-header">
                        <tr role="row">
                          <th
                            title="Driver Intial Point to Pickup Point Time"
                            className="sorting border-right"
                            tabIndex="0"
                            aria-controls="leads-table"
                            rowSpan="1"
                            colSpan="1"
                            aria-label="Mobile: activate to sort column ascending"
                          >
                            Driver Intial Point to Pickup Point Time
                          </th>
                          <th
                            title="Driver Arrived At Pickup Point"
                            className="sorting_disabled border-right"
                            rowSpan="1"
                            colSpan="1"
                            aria-label="Next Follow Up"
                          >
                            Driver Arrived At Pickup Point{" "}
                            <i
                              className="fas fa-undo-alt f-12"
                              title="Undo Action"
                              style={{ cursor: "pointer" }}
                              onClick={() => handleUndoAction("arrived")}
                            ></i>
                          </th>
                          <th
                            title="Driver Waiting Time"
                            className="sorting_disabled border-right"
                            rowSpan="1"
                            colSpan="1"
                            aria-label="Next Follow Up"
                          >
                            Driver Waiting Time
                          </th>
                          {current?.status?.toLowerCase() === "no show" ? (
                            <th
                              title="No Show Time"
                              className="sorting_disabled border-right"
                              rowSpan="1"
                              colSpan="1"
                              aria-label="Next Follow Up"
                            >
                              No Show Time{" "}
                              <i
                                className="fas fa-undo-alt f-12"
                                title="Undo Action"
                                style={{ cursor: "pointer" }}
                                onClick={() => handleUndoAction("noshow")}
                              ></i>
                            </th>
                          ) : (
                            <>
                              <th
                                title="Ride Started With Customer Time"
                                className="sorting_disabled border-right"
                                rowSpan="1"
                                colSpan="1"
                                aria-label="Next Follow Up"
                              >
                                Ride Started With Customer Time
                              </th>
                              <th
                                title="Dropoff Time"
                                className="sorting_disabled border-right"
                                rowSpan="1"
                                colSpan="1"
                                aria-label="Next Follow Up"
                              >
                                Dropoff Time
                              </th>
                              <th
                                title="Trip Duration Time"
                                className="sorting_disabled border-right"
                                rowSpan="1"
                                colSpan="1"
                                aria-label="Next Follow Up"
                              >
                                Trip Duration Time
                              </th>
                            </>
                          )}
                          {current?.status?.toLowerCase() === "no show" ? (
                            <th
                              title="No Show Coordinates"
                              className="sorting_disabled border-right"
                              rowSpan="1"
                              colSpan="1"
                              aria-label="Next Follow Up"
                            >
                              No Show Coordinates
                            </th>
                          ) : (
                            <>
                              <th
                                title="Pickup Coordinates"
                                className="sorting_disabled border-right"
                                rowSpan="1"
                                colSpan="1"
                                aria-label="Next Follow Up"
                              >
                                Pickup Coordinates{" "}
                                <i
                                  className="fas fa-undo-alt f-12"
                                  title="Undo Action"
                                  style={{ cursor: "pointer" }}
                                  onClick={() => handleUndoAction("pick")}
                                ></i>
                              </th>
                              {/* <th
                    title="Pickup Latitude"
                    className="sorting_disabled border-right"
                    rowSpan="1"
                    colSpan="1"
                    aria-label="Next Follow Up"
                  >
                    Pickup Latitude
                  </th> */}
                              <th
                                title="Dropoff Coordinates"
                                className="sorting_disabled border-right"
                                rowSpan="1"
                                colSpan="1"
                                aria-label="Next Follow Up"
                              >
                                Dropoff Coordinates{" "}
                                <i
                                  className="fas fa-undo-alt f-12"
                                  title="Undo Action"
                                  style={{ cursor: "pointer" }}
                                  onClick={() => handleUndoAction("dropoff")}
                                ></i>
                              </th>
                              {/* <th
                    title="Dropoff Latitude"
                    className="sorting_disabled border-right"
                    rowSpan="1"
                    colSpan="1"
                    aria-label="Next Follow Up"
                  >
                    Dropoff Latitude
                  </th> */}
                            </>
                          )}
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          role="row"
                          className="odd border"
                          // onMouseLeave={() => setshowMoreVertDropDown(false)}
                        >
                          {reqData?.trip?.tripStartTime ? (
                            <td className="border-right">
                              {moment(
                                reqData?.trip?.tripStartTime?.substring(0, 10)
                              ).format("MM/DD/YYYY")}{" "}
                              {/* {reqData?.trip?.tripStartTime?.substring(11, 16)} */}
                              {parseInt(
                                reqData?.trip?.tripStartTime?.substring(11, 13)
                              ) === 0
                                ? 12
                                : parseInt(
                                    reqData?.trip?.tripStartTime?.substring(
                                      11,
                                      13
                                    )
                                  ) > 12
                                ? parseInt(
                                    reqData?.trip?.tripStartTime?.substring(
                                      11,
                                      13
                                    )
                                  ) - 12
                                : parseInt(
                                    reqData?.trip?.tripStartTime?.substring(
                                      11,
                                      13
                                    )
                                  )}
                              {":" +
                                reqData?.trip?.tripStartTime?.substring(14, 16)}
                              {parseInt(
                                reqData?.trip?.tripStartTime?.substring(11, 13)
                              ) > 11
                                ? "pm"
                                : "am"}
                            </td>
                          ) : (
                            <td className="border-right"></td>
                          )}
                          {reqData?.trip?.arrivedTime ? (
                            <td className="border-right">
                              {moment(
                                reqData?.trip?.arrivedTime?.substring(0, 10)
                              ).format("MM/DD/YYYY")}{" "}
                              {parseInt(
                                reqData?.trip?.arrivedTime?.substring(11, 13)
                              ) === 0
                                ? 12
                                : parseInt(
                                    reqData?.trip?.arrivedTime?.substring(
                                      11,
                                      13
                                    )
                                  ) > 12
                                ? parseInt(
                                    reqData?.trip?.arrivedTime?.substring(
                                      11,
                                      13
                                    )
                                  ) - 12
                                : parseInt(
                                    reqData?.trip?.arrivedTime?.substring(
                                      11,
                                      13
                                    )
                                  )}
                              {":" +
                                reqData?.trip?.arrivedTime?.substring(14, 16)}
                              {parseInt(
                                reqData?.trip?.arrivedTime?.substring(11, 13)
                              ) > 11
                                ? "pm"
                                : "am"}
                            </td>
                          ) : (
                            <td className="border-right"></td>
                          )}
                          <td className="border-right">
                            {reqData?.trip?.waitingTimePickUp}
                          </td>
                          {current?.status?.toLowerCase() === "no show" ? (
                            <td className="border-right">
                              <a
                                href={`https://www.google.com/maps/search/?api=1&query=${reqData?.trip?.pickUpLat?.toFixed(
                                  6
                                )},${reqData?.trip?.pickUpLng?.toFixed(6)}`}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {reqData?.trip?.pickUpLat
                                  ? "Lat :" +
                                    reqData?.trip?.pickUpLat?.toFixed(6) +
                                    ","
                                  : " "}{" "}
                                {reqData?.trip?.pickUpLng
                                  ? "Long: " +
                                    reqData?.trip?.pickUpLng?.toFixed(6)
                                  : " "}
                              </a>
                            </td>
                          ) : (
                            <>
                              {reqData?.trip?.pickUpTime ? (
                                <td className="border-right">
                                  {moment(
                                    reqData?.trip?.pickUpTime?.substring(0, 10)
                                  ).format("MM/DD/YYYY")}{" "}
                                  {parseInt(
                                    reqData?.trip?.pickUpTime?.substring(11, 13)
                                  ) === 0
                                    ? 12
                                    : parseInt(
                                        reqData?.trip?.pickUpTime?.substring(
                                          11,
                                          13
                                        )
                                      ) > 12
                                    ? parseInt(
                                        reqData?.trip?.pickUpTime?.substring(
                                          11,
                                          13
                                        )
                                      ) - 12
                                    : parseInt(
                                        reqData?.trip?.pickUpTime?.substring(
                                          11,
                                          13
                                        )
                                      )}
                                  {":" +
                                    reqData?.trip?.pickUpTime?.substring(
                                      14,
                                      16
                                    )}
                                  {parseInt(
                                    reqData?.trip?.pickUpTime?.substring(11, 13)
                                  ) > 11
                                    ? "pm"
                                    : "am"}
                                </td>
                              ) : (
                                <td className="border-right"></td>
                              )}
                              {reqData?.trip?.dropOffTime ? (
                                <td className="border-right">
                                  {moment(
                                    reqData?.trip?.dropOffTime?.substring(0, 10)
                                  ).format("MM/DD/YYYY")}{" "}
                                  {parseInt(
                                    reqData?.trip?.dropOffTime?.substring(
                                      11,
                                      13
                                    )
                                  ) === 0
                                    ? 12
                                    : parseInt(
                                        reqData?.trip?.dropOffTime?.substring(
                                          11,
                                          13
                                        )
                                      ) > 12
                                    ? parseInt(
                                        reqData?.trip?.dropOffTime?.substring(
                                          11,
                                          13
                                        )
                                      ) - 12
                                    : parseInt(
                                        reqData?.trip?.dropOffTime?.substring(
                                          11,
                                          13
                                        )
                                      )}
                                  {":" +
                                    reqData?.trip?.dropOffTime?.substring(
                                      14,
                                      16
                                    )}
                                  {parseInt(
                                    reqData?.trip?.dropOffTime?.substring(
                                      11,
                                      13
                                    )
                                  ) > 11
                                    ? "pm"
                                    : "am"}
                                </td>
                              ) : (
                                <td className="border-right"></td>
                              )}
                              <td className="border-right">
                                {reqData?.trip?.tripDuration}
                              </td>
                            </>
                          )}
                          {current?.status?.toLowerCase() === "no show" ? (
                            <td className="border-right">
                              <a
                                href={`https://www.google.com/maps/search/?api=1&query=${reqData?.trip?.pickUpLat?.toFixed(
                                  6
                                )},${reqData?.trip?.pickUpLng?.toFixed(6)}`}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {reqData?.trip?.pickUpLat
                                  ? "Lat :" +
                                    reqData?.trip?.pickUpLat?.toFixed(6) +
                                    ","
                                  : " "}{" "}
                                {reqData?.trip?.pickUpLng
                                  ? "Long: " +
                                    reqData?.trip?.pickUpLng?.toFixed(6)
                                  : " "}
                              </a>
                            </td>
                          ) : (
                            <>
                              <td className="border-right">
                                <a
                                  href={`https://www.google.com/maps/search/?api=1&query=${reqData?.trip?.pickUpLat?.toFixed(
                                    6
                                  )},${reqData?.trip?.pickUpLng?.toFixed(6)}`}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  {reqData?.trip?.pickUpLat
                                    ? "Lat :" +
                                      reqData?.trip?.pickUpLat?.toFixed(6) +
                                      ","
                                    : " "}{" "}
                                  {reqData?.trip?.pickUpLng
                                    ? "Long: " +
                                      reqData?.trip?.pickUpLng?.toFixed(6)
                                    : " "}
                                </a>
                              </td>
                              <td className="border-right">
                                <a
                                  href={`https://www.google.com/maps/search/?api=1&query=${reqData?.trip?.dropOffLat?.toFixed(
                                    6
                                  )},${reqData?.trip?.dropOffLng?.toFixed(6)}`}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  {reqData?.trip?.dropOffLat
                                    ? "Lat :" +
                                      reqData?.trip?.dropOffLat?.toFixed(6) +
                                      ","
                                    : " "}{" "}
                                  {reqData?.trip?.dropOffLng
                                    ? "Long: " +
                                      reqData?.trip?.dropOffLng?.toFixed(6)
                                    : " "}
                                </a>
                              </td>
                            </>
                          )}
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </>
              )}
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn-primary rounded f-14 p-2 mr-3"
                onClick={() => openRideLogDropDown()}
              >
                OK
              </button>
            </div>
            {/* <div className="modal-footer">
              <button type="button" className="btn-secondary rounded f-14 p-2 mr-3" data-dismiss="modal" onClick={() => openRideLogDropDown()}>Close</button>
              <button type="button" className="btn-primary rounded f-14 p-2 mr-3">Save changes</button>
          </div> */}
          </div>
        </div>
      </div>
    </>
  );
};
export default AdminBookingTodayBookingRideLogModal;
