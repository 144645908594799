/** @format */

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink } from "react-router-dom";
import { tabInvoicing, tabDriverPay } from "../../../actions/tabActions";
import NotAllowed from "../../../components/NotAllowed";
import InvoicingCards from "./InvoicingCards";
import InvoicingTable from "./InvoicingTable";
import DriverPayTable from "./DriverPayTable";
import ExcelExport from "../../../ExcelExport";
import moment from "moment";
import { getAllReservationV2 } from "../../../actions/reservationAction";

const InvoicingTab = () => {
  const myState = useSelector((state) => state.changeTheTab);
  const service = useSelector((state) => state.service);
  const invoices = useSelector((state) => state.invoices);
  const dispatch = useDispatch();
  const auth = useSelector((x) => x.auth);
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });

  // const data2 = invoices?.searchedData?.map((item, i) => {
  //   const utcDate = new Date(item.reservationId.dateForReservation);
  //   const chicagoDate = new Date(
  //     utcDate.toLocaleString("en-US", {
  //       timeZone: "America/Chicago",
  //     })
  //   );
  //   const chicagoDateTimeString = chicagoDate.toLocaleString("en-US");
  //   const dateString = item.reservationId.dateForReservation;
  //   // Split the date string by the "T" character
  //   const [datePart, timePart] = dateString.split("T");
  //   // Split the date part into year, month, and day
  //   const [year, month, day] = datePart.split("-");
  //   // Arrange the date in MM/DD/YYYY format
  //   const formattedDate = `${month}/${day}/${year}`;
  //   return {
  //     chicagoDateTimeString,
  //     chicagoDate,
  //     utcDate,
  //     original: item.reservationId.dateForReservation,
  //     original2: new Date(item.reservationId.dateForReservation)
  //       .toLocaleString("en-US")
  //       .split(",")[0],
  //     formattedDate,
  //   };
  // });
  // console.log("datadatadatadatadata", data2);

  // const data = invoices?.searchedData?.map((item) => ({
  //   "Order Id": item.reservationId.orderId,
  //   "Load Number": item.reservationId.loadNumber,
  //   "Driver First Name": item.reservationId.driverId?.firstName,
  //   "Driver Last Name": item.reservationId.driverId?.lastName,
  //   "Passenger First Name": item.reservationId.customerId?.firstName,
  //   "Passenger Last Name": item.reservationId.customerId?.lastName,
  //   "Reservation Date": new Date(item.reservationId.dateForReservation)
  //     .toLocaleString("en-US")
  //     .split(",")[0],
  //   "Pick Up Address": item.reservationId.pickUpAddress?.address,
  //   // "Pick Up Time": item.reservationId.pickUpTime,
  //   "Pick Up Time":
  //     (parseInt(item.reservationId.pickUpTime.substring(0, 2)) === 0
  //       ? 12
  //       : parseInt(item.reservationId.pickUpTime.substring(0, 2)) > 12
  //       ? parseInt(item.reservationId.pickUpTime.substring(0, 2)) - 12
  //       : parseInt(item.reservationId.pickUpTime.substring(0, 2))) +
  //     ":" +
  //     item.reservationId.pickUpTime.substring(3, 5) +
  //     (parseInt(item.reservationId.pickUpTime.substring(0, 2)) > 11
  //       ? "pm"
  //       : "am"),

  //   "Drop Off Address": item.reservationId.dropOffAddress?.address,
  //   // "Drop Off Time": item.reservationId.dropOffTime,
  //   "Drop Off Time":
  //     (parseInt(item.reservationId.dropOffTime.substring(0, 2)) === 0
  //       ? 12
  //       : parseInt(item.reservationId.dropOffTime.substring(0, 2)) > 12
  //       ? parseInt(item.reservationId.dropOffTime.substring(0, 2)) - 12
  //       : parseInt(item.reservationId.dropOffTime.substring(0, 2))) +
  //     ":" +
  //     item.reservationId.dropOffTime.substring(3, 5) +
  //     (parseInt(item.reservationId.dropOffTime.substring(0, 2)) > 11
  //       ? "pm"
  //       : "am"),

  //   "Driver Amount": item.reservationId.fareAndDistance?.driverAmount
  //     ? parseFloat(item?.reservationId.fareAndDistance?.driverAmount)
  //     : 0,
  //   Status: item.reservationId.status,

  //   // "Customer First Name": item.customerId?.firstName,
  //   // "Customer Last Name": item.customerId?.lastName,
  // }));

  const data = invoices?.searchedData?.map((item) => {
    const dateString = item.reservationId.dateForReservation;
    // Split the date string by the "T" character
    const [datePart, timePart] = dateString.split("T");
    // Split the date part into year, month, and day
    const [year, month, day] = datePart.split("-");
    // Arrange the date in MM/DD/YYYY format
    const formattedDate = `${month}/${day}/${year}`;
    return {
      "Order Id": item.reservationId.orderId,
      "Load Number": item.reservationId.loadNumber,
      "Driver First Name": item.reservationId.driverId?.firstName,
      "Driver Last Name": item.reservationId.driverId?.lastName,
      "Passenger First Name": item.reservationId.customerId?.firstName,
      "Passenger Last Name": item.reservationId.customerId?.lastName,
      "Reservation Date": formattedDate,
      "Pick Up Address": item.reservationId.pickUpAddress?.address,
      // "Pick Up Time": item.reservationId.pickUpTime,
      "Pick Up Time":
        (parseInt(item.reservationId.pickUpTime.substring(0, 2)) === 0
          ? 12
          : parseInt(item.reservationId.pickUpTime.substring(0, 2)) > 12
          ? parseInt(item.reservationId.pickUpTime.substring(0, 2)) - 12
          : parseInt(item.reservationId.pickUpTime.substring(0, 2))) +
        ":" +
        item.reservationId.pickUpTime.substring(3, 5) +
        (parseInt(item.reservationId.pickUpTime.substring(0, 2)) > 11
          ? "pm"
          : "am"),

      "Drop Off Address": item.reservationId.dropOffAddress?.address,
      // "Drop Off Time": item.reservationId.dropOffTime,
      "Drop Off Time":
        (parseInt(item.reservationId.dropOffTime.substring(0, 2)) === 0
          ? 12
          : parseInt(item.reservationId.dropOffTime.substring(0, 2)) > 12
          ? parseInt(item.reservationId.dropOffTime.substring(0, 2)) - 12
          : parseInt(item.reservationId.dropOffTime.substring(0, 2))) +
        ":" +
        item.reservationId.dropOffTime.substring(3, 5) +
        (parseInt(item.reservationId.dropOffTime.substring(0, 2)) > 11
          ? "pm"
          : "am"),

      "Driver Amount": item.reservationId.fareAndDistance?.driverAmount
        ? parseFloat(item?.reservationId.fareAndDistance?.driverAmount)
        : 0,
      Status: item.reservationId.status,

      // "Customer First Name": item.customerId?.firstName,
      // "Customer Last Name": item.customerId?.lastName,
    };
  });
  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
      <div className="">
        <div
          className="mobile-close-overlay w-100 h-100"
          id="close-settings-overlay"
        />
        <Link to="" className="d-block d-lg-none close-it" id="close-settings">
          <svg
            className="svg-inline--fa fa-times fa-w-11"
            aria-hidden="true"
            focusable="false"
            data-prefix="fa"
            data-icon="times"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 352 512"
            data-fa-i2svg=""
          >
            <path
              fill="currentColor"
              d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"
            ></path>
          </svg>
        </Link>

        {myState.viewInvoicing === "theinvoicing" ? (
          <>
            {windowSize.width >= 992 ? (
              <>
                <div className="row align-items-center">
                  <div className="col">
                    <div className="col-7 mitbsnav">
                      <ul className="nav nav-pills nav-justified">
                        <li className="nav-item active">
                          <NavLink
                            to="/invoices"
                            onClick={() => dispatch(tabInvoicing())}
                            className=""
                          >
                            Invoicing
                          </NavLink>
                        </li>
                        <li className="nav-item">
                          <NavLink
                            to="/invoices"
                            onClick={() => dispatch(tabDriverPay())}
                            className=""
                          >
                            Driver Pay
                          </NavLink>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <></>
            )}
          </>
        ) : myState.viewInvoicing === "driverpay" ? (
          <>
            {windowSize.width >= 992 ? (
              <>
                <div className="row align-items-center">
                  <div className="col">
                    <div className="col-7 mitbsnav">
                      <ul className="nav nav-pills nav-justified">
                        <li className="nav-item ">
                          <NavLink
                            to="/invoices"
                            onClick={() => dispatch(tabInvoicing())}
                            className=""
                          >
                            Invoicing
                          </NavLink>
                        </li>
                        <li className="nav-item active">
                          <NavLink
                            to="/invoices"
                            onClick={() => dispatch(tabDriverPay())}
                            className=""
                          >
                            Driver Pay
                          </NavLink>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <ExcelExport
                    excelData={data}
                    fileName={`Driver Pay Report`}
                  />
                </div>
              </>
            ) : (
              <></>
            )}
          </>
        ) : (
          <></>
        )}
        <br />

        {myState.viewInvoicing === "theinvoicing" ? (
          auth?.user?.permissionId?.settings?.read === true ? (
            <>
              <InvoicingCards cardDetails={invoices?.cardDetails} />
              <InvoicingTable />
            </>
          ) : (
            <NotAllowed />
          )
        ) : myState.viewInvoicing === "driverpay" ? (
          auth?.user?.permissionId?.settings?.create === true ? (
            <>
              <InvoicingCards cardDetails={invoices?.cardDetails} />
              <DriverPayTable />
            </>
          ) : (
            <NotAllowed />
          )
        ) : (
          <></>
        )}
      </div>
    </>
  );
};
export default InvoicingTab;
