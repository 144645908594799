/** @format */

import React from "react";
import moment from "moment";

const ViewAllAdminNotificationsRow = ({ item, i }) => {
  // console.log("item", item);
  return (
    <>
      <tr key={i}>
        <td className="subject">
          <p className="noti-details">
            <span className="avatarr">
              <img
                className="inline-block"
                alt=""
                src={
                  item?.driverId?.profilePic
                    ? item?.driverId?.profilePic?.fileUrl
                    : require("../../../assets/images/profile.jpg")
                }
                style={{
                  objectFit: "fill",
                  height: "100%",
                  borderRadius: "50%",
                }}
              />
            </span>
            <span className="noti-title">
              {item.driverId.firstName + " " + item.driverId.lastName}{" "}
            </span>{" "}
            has{" "}
            {item.driverBookingStatus === "Assigned" ? "Accepeted" : "Rejected"}{" "}
            <span className="noti-title">
              {item?.reservationId
                ? " Order No. " +
                  item?.reservationId?.orderId +
                  " for date " +
                  moment(item?.reservationId?.dateForReservation)
                    .format("MM-DD-YYYY")
                    .toString()
                    .substring(0, 10)
                : "Route No. " + item?.routeId?.routeNumber}
            </span>
          </p>
          <p className="noti-time" style={{ margin: "0px 0px 0px 50px" }}>
            <span className="notification-time">
              {moment().format("DD-MM-YYYY") ===
              moment(item.responseDate).format("DD-MM-YYYY")
                ? moment(item.responseDate).format("LT")
                : moment(item.responseDate).format("DD MMM")}
            </span>
          </p>
        </td>
      </tr>
    </>
  );
};
export default ViewAllAdminNotificationsRow;
