import axios from "axios";
import {
    NOTE_CREATE_REQUEST,
    NOTE_CREATE_SUCCESS,
    NOTE_CREATE_FAIL,
    NOTE_VIEW_REQUEST,
    NOTE_VIEW_SUCCESS,
    NOTE_VIEW_FAIL,
    SERVERADDRESS,
} from "../constants"
import { notifyFailure, notifySuccess } from "../components/toast";

// ADD NEW NOTE
export const createNote = (obj, successCB) => async ( dispatch ) => {
    dispatch({
        type: NOTE_CREATE_REQUEST,
    })
    try {
        const { data } = await axios.post(`${SERVERADDRESS}/v1/note/createNote`, obj)
        dispatch({
            type: NOTE_CREATE_SUCCESS,
            payload: data,
        })
        // navigateInbox()
        if (successCB) {
            successCB(data);
          }
          notifySuccess("Note Added Successfully !");
    }
    catch (err) {
        // console.log(err)
        notifyFailure(err.response.data.message);
        dispatch({
            type: NOTE_CREATE_FAIL,
            payload: err,
        })
    }
}

export const getNotesByCompanyId = (obj) => async (dispatch) => {
    dispatch({
        type: NOTE_VIEW_REQUEST,
    });
    try {
        const { data } = await axios.post(`${SERVERADDRESS}/v1/note/getNotesByCompanyId`, obj);
        localStorage.setItem("note", JSON.stringify(data));
        dispatch({
            type: NOTE_VIEW_SUCCESS,
            payload: data,
        });
    }
    catch (err) {
        dispatch({
            type: NOTE_VIEW_FAIL,
            payload: err,
        });
    }
};
