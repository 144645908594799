/** @format */

import React from "react";
import AdminHeader from "../../components/adminComponents/adminHeaderSidebarTitleComponents/AdminHeader";
import AdminSidebar from "../../components/adminComponents/adminHeaderSidebarTitleComponents/AdminSidebar";
import AdminDailyActivityReportTable from "../../components/adminComponents/adminReportComponents/AdminDailyActivityReportTable";
import AdminDailyActivityReportSearchTable from "../../components/adminComponents/adminReportComponents/AdminDailyActivityReportSearchTable";
import AdminReportSearchandFilterV2 from "../../components/adminComponents/adminReportComponents/AdminReportSearchandFilterV2";

function AdminDailyActivityReportPage() {
  return (
    <>
      <AdminHeader />
      <AdminSidebar />
      <div className="body-wrapper clearfix">
        <section className="main-container " id="fullscreen">
          <div
            className="preloader-container justify-content-center align-items-center"
            style={{ display: "none" }}
          >
            <div
              className="spinner-border"
              role="status"
              aria-hidden="true"
            ></div>
          </div>
          <div className="page-title d-block d-lg-none">
            <div className="page-heading">
              <h2 className="mb-0 pr-3 text-dark f-18 font-weight-bold">
                Dashboard
                <span className="text-lightest f-12 f-w-500 ml-2">
                  <a href="dispatching-table.html" className="text-lightest">
                    {" "}
                    Dispatching Table{" "}
                  </a>{" "}
                  • Dashboard
                </span>
              </h2>
            </div>
          </div>
          <div className="px-4 py-2 border-top-0 emp-dashboard">
            <div className="d-block d-lg-flex d-md-flex justify-content-between action-bar mb-20 mt-3">
              <div
                id="table-actions"
                className="flex-grow-1 align-items-center"
              >
                <h4 className=" mb-0 f-21 text-capitalize font-weight-bold">
                  Daily Activity Reports{" "}
                </h4>
              </div>
            </div>
            <AdminReportSearchandFilterV2 />

            {/* <AdminDailyActivityReportTable /> */}

            <AdminDailyActivityReportSearchTable />
          </div>
        </section>
      </div>
    </>
  );
}

export default AdminDailyActivityReportPage;
