/** @format */

import axios from "axios";
import {
  SERVERADDRESS,
  CHATS_GET_FAIL,
  CHATS_GET_REQUEST,
  CHATS_GET_SUCCESS,
  CHAT_SELECT_SUCCESS,
  GROUP_CREATE_FAIL,
  GROUP_CREATE_REQUEST,
  GROUP_CREATE_SUCCESS,
  MESSAGE_GET_FAIL,
  MESSAGE_GET_REQUEST,
  MESSAGE_GET_SUCCESS,
  MESSAGE_SEND_FAIL,
  MESSAGE_SEND_REQUEST,
  MESSAGE_SEND_SUCCESS,
  READ_CHAT_FAIL,
  READ_CHAT_REQUEST,
  READ_CHAT_SUCCESS,
  READ_MESSAGES_FAIL,
  READ_MESSAGES_REQUEST,
  READ_MESSAGES_SUCCESS,
  CALL_SEND_REQUEST,
  CALL_SEND_SUCCESS,
  CALL_SEND_FAIL,
  CUSTOMER_IS_READY_MESSAGE_REQUEST,
  CUSTOMER_IS_READY_MESSAGE_SUCCESS,
  CUSTOMER_IS_READY_MESSAGE_FAIL,
  POPUP_OFF,
  POPUP_ON,
  CALL_RECEIVED,
  CALL_DECLINED,
} from "../constants";
// import { toast } from "react-hot-toast";
import { notifyFailure, notifySuccess } from "../components/toast";

// Get All Chats
export const getChatsAction = (obj) => async (dispatch) => {
  dispatch({
    type: CHATS_GET_REQUEST,
  });
  try {
    const { data } = await axios.post(`${SERVERADDRESS}/v1/chats/user`, obj);
    dispatch({
      type: CHATS_GET_SUCCESS,
      payload: data,
    });
  } catch (err) {
    // console.log(err);
    dispatch({
      type: CHATS_GET_FAIL,
      payload: err,
    });
  }
};

// Create Group
export const createGroupAction = (obj, successHandler) => async (dispatch) => {
  dispatch({
    type: GROUP_CREATE_REQUEST,
  });
  try {
    const { data } = await axios.post(`${SERVERADDRESS}/v1/chats/group`, obj);
    dispatch({
      type: GROUP_CREATE_SUCCESS,
      payload: data,
    });
    dispatch({
      type: CHATS_GET_SUCCESS,
      payload: data,
    });
    // notifySuccess("Group Created Successfully!");
    dispatch(getChatsAction(obj));
    // dispatch({ type: CHAT_SELECT_SUCCESS, payload: data });
    successHandler && successHandler(data);
  } catch (err) {
    // console.log(err);
    // notifyFailure(err.response.data.message);

    dispatch({
      type: GROUP_CREATE_FAIL,
      payload: err,
    });
  }
};

// Create Chat
export const createChatAction = (obj, successHandler) => async (dispatch) => {
  dispatch({
    type: GROUP_CREATE_REQUEST,
  });
  try {
    const { data } = await axios.post(`${SERVERADDRESS}/v1/chats`, {
      userId: obj.user.id,
      company: obj.companyId,
      latestMessage: null,
      receiverId: obj.receiverId,
    });
    dispatch({
      type: GROUP_CREATE_SUCCESS,
      payload: data,
    });
    dispatch(getChatsAction({ userId: obj.user.id, companyId: obj.companyId }));

    // dispatch(
    //   sendMessageAction(
    //     {
    //       sender: obj.user,
    //       chatId: data,
    //       message: obj.message,
    //       dateTime: new Date(),
    //     },
    //     successHandler
    //   )
    // );
  } catch (err) {
    // console.log(err);
    dispatch({
      type: GROUP_CREATE_FAIL,
      payload: err,
    });
  }
};

// Create/Send Message
export const sendMessageAction = (obj, successHandler) => async (dispatch) => {
  dispatch({
    type: MESSAGE_SEND_REQUEST,
  });
  // console.log(sendMessageAction, "sendMessageAction");
  try {
    if (!obj?.chatId?.acceptedUser) {
      await axios.post(`${SERVERADDRESS}/v1/chats/acceptedChat`, {
        userId: obj.sender.id,
        chatId: obj.chatId.id,
      });
    }
    const { data } = await axios.post(`${SERVERADDRESS}/v1/messages`, {
      ...obj,
      senderId: obj.sender.id,
      chatId: obj.chatId.id,
    });
    // console.log(obj, "obj");
    dispatch({
      type: MESSAGE_SEND_SUCCESS,
      payload: obj,
    });
    if (successHandler && typeof successHandler === "function") {
      successHandler();
    }
    // successHandler();
  } catch (err) {
    // console.log(err, "errr");
    if (err?.response?.data?.message === "Dispatcher already in the Chat") {
      notifyFailure(err?.response?.data?.message);
    }
    dispatch({
      type: MESSAGE_SEND_FAIL,
      payload: err,
    });
  }
};

// Create/Send Message
export const noShowAction = (obj, successHandler) => async (dispatch) => {
  dispatch({
    type: MESSAGE_SEND_REQUEST,
  });
  // console.log(sendMessageAction, "sendMessageAction");
  try {
    if (!obj?.chatId?.acceptedUser) {
      await axios.post(`${SERVERADDRESS}/v1/chats/acceptedChat`, {
        userId: obj.sender.id,
        chatId: obj.chatId.id,
      });
      // dispatch({1
      //   type: MODIFY_SELECT_ACCEPTED,
      //   payload: obj.sender.id,
      // });
    }
    const { data } = await axios.post(
      `${SERVERADDRESS}/v1/messages/noShowAction`,
      {
        ...obj,
        // senderId: obj.sender.id,
        chatId: obj.chatId.id,
      }
    );
    // console.log(obj, "obj");
    dispatch({
      type: MESSAGE_SEND_SUCCESS,
      payload: obj,
    });
    if (successHandler && typeof successHandler === "function") {
      successHandler();
    }
    // successHandler();
  } catch (err) {
    console.log(err);
    if (err?.response?.data?.message === "Dispatcher already in the Chat") {
      notifyFailure(err?.response?.data?.message);
    }
    dispatch({
      type: MESSAGE_SEND_FAIL,
      payload: err,
    });
  }
};

// Get Single Chat Messages
export const getMessagesAction =
  (chatId, userId, noload) => async (dispatch) => {
    // console.log(noload, "noload");
    if (!noload) {
      // console.log("chala noload");
      dispatch({
        type: MESSAGE_GET_REQUEST,
      });
    }
    try {
      const { data } = await axios.get(
        `${SERVERADDRESS}/v1/messages/${chatId}`
      );
      dispatch({
        type: MESSAGE_GET_SUCCESS,
        payload: data,
      });
      // dispatch(readMessagesAction(chatId, userId));
      dispatch(readChatAction(chatId, userId));
    } catch (err) {
      // console.log(err);
      dispatch({
        type: MESSAGE_GET_FAIL,
        payload: err,
      });
    }
  };

// Read Messages Action
export const readMessagesAction = (chatId, readerId) => async (dispatch) => {
  dispatch({
    type: READ_MESSAGES_REQUEST,
  });
  try {
    const { data } = await axios.patch(
      `${SERVERADDRESS}/v1/messages/${chatId}`,
      {
        readerId,
      }
    );
    dispatch({
      type: READ_MESSAGES_SUCCESS,
      payload: data,
    });
    // toast.success("Group Created Successfully!");
    // dispatch(getChatsAction(obj));
    // dispatch({ type: CHAT_SELECT_SUCCESS, payload: data });
    // successHandler();
  } catch (err) {
    // console.log(err);
    // toast.error(err.response.data.message);
    dispatch({
      type: READ_MESSAGES_FAIL,
      payload: err,
    });
  }
};

// Read Chat Action
export const readChatAction = (chatId, readerId) => async (dispatch) => {
  dispatch({
    type: READ_CHAT_REQUEST,
  });
  try {
    const { data } = await axios.patch(`${SERVERADDRESS}/v1/chats/${chatId}`, {
      readerId,
    });
    dispatch({
      type: READ_CHAT_SUCCESS,
      payload: data,
    });
    // toast.success("Group Created Successfully!");
    // dispatch(getChatsAction(obj));
    // dispatch({ type: CHAT_SELECT_SUCCESS, payload: data });
    // successHandler();
  } catch (err) {
    // console.log(err);
    // toast.error(err.response.data.message);
    dispatch({
      type: READ_CHAT_FAIL,
      payload: err,
    });
  }
};

// Create/Send Call
export const startCall =
  (obj, socketInstance, joinAndPublishAudio) => async (dispatch) => {
    dispatch({
      type: CALL_SEND_REQUEST,
    });
    try {
      const { data } = await axios.post(
        `${SERVERADDRESS}/v1/messages/startCall`,
        {
          ...obj,
          senderId: obj.sender.id,
          chatId: obj.chatId.id,
        }
      );
      dispatch({
        type: CALL_SEND_SUCCESS,
        payload: obj,
      });
      // if (successHandler && typeof successHandler === "function") {
      //   successHandler();
      // }
      // console.log(joinAndPublishAudio);
      await joinAndPublishAudio(data.tokenA, obj.chatId.id);
      socketInstance.emit("start-call", {
        sender: {
          firstName: obj.sender.firstName,
          lastName: obj.sender.lastName,
          id: obj.sender.id,
          profilePic: {
            fileUrl: obj.sender.profilePic.fileUrl,
          },
        },
        chatId: obj.chatId,
        message: obj.message,
        dateTime: obj.dateTime,
        messageId: data._id,
        token: data.tokenA,
      });
      // successHandler();
    } catch (err) {
      // console.log(err);
      dispatch({
        type: CALL_SEND_FAIL,
        payload: err,
      });
    }
  };

// Close Popup Condition
export const popupClose = () => (dispatch) => {
  dispatch({
    type: POPUP_OFF,
  });
};

// Open Popup Condition
export const popupOpen = () => (dispatch) => {
  dispatch({
    type: POPUP_ON,
  });
};

// Call Received Condition
export const receivedCall = (obj) => (dispatch) => {
  dispatch({
    type: CALL_RECEIVED,
    payload: obj,
  });
};

// Call Declined Condition
export const declinedCall = (obj) => (dispatch) => {
  dispatch({
    type: CALL_DECLINED,
    payload: obj,
  });
};

export const resetState = () => {
  return {
    type: "RESET_STATE",
  };
};

// Create/Send Message
export const customerIsReadyMessage = (obj, successHandler) => async (dispatch) => {
  dispatch({
    type: MESSAGE_SEND_REQUEST,
  });
  // console.log(sendMessageAction, "sendMessageAction");
  try {
    if (!obj?.chatId?.acceptedUser) {
      await axios.post(`${SERVERADDRESS}/v1/chats/acceptedChat`, {
        userId: obj.sender.id,
        chatId: obj.chatId.id,
      });
      // dispatch({1
      //   type: MODIFY_SELECT_ACCEPTED,
      //   payload: obj.sender.id,
      // });
    }
    const { data } = await axios.post(
      `${SERVERADDRESS}/v1/messages/customerReady`,
      {
        ...obj,
        // senderId: obj.sender.id,
        chatId: obj.chatId.id,
      }
    );
    // console.log(obj, "obj");
    dispatch({
      type: MESSAGE_SEND_SUCCESS,
      payload: obj,
    });
    if (successHandler && typeof successHandler === "function") {
      successHandler();
    }
    // successHandler();
  } catch (err) {
    // console.log(err);
    if (err?.response?.data?.message === "Dispatcher already in the Chat") {
      notifyFailure(err?.response?.data?.message);
    }
    dispatch({
      type: MESSAGE_SEND_FAIL,
      payload: err,
    });
  }
};