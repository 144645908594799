import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getRoutesByUserId, updateRouteDriverStatus } from "../../../actions/routeActions";
import DriverRouteDeclineModal from "./DriverRouteDeclineModal";
// import AssignDriverModal from "./AssignDriverModal"; 

const DriverRouteComponentRow = ({current, i, dropDownRow, setDropDownRow}) => {
  const [showDeclineDropDown, setshowDeclineDropDown] = useState(false);
  const openDeclineDropDown = () => {
    setshowDeclineDropDown((showDeclineDropDown) => !showDeclineDropDown);
  };
  const [showMoreVertDropDown, setshowMoreVertDropDown] = useState(false);
  const openMoreVertDropDown = () => {
    setshowMoreVertDropDown((showMoreVertDropDown) => !showMoreVertDropDown);
  };
  const dispatch = useDispatch()
  const navigate = useNavigate();
  const routes = useSelector((state) => state.routes);
  const [ values, setValues ] = useState({
    routeId: '',
    responseDate: '',
    driverBookingStatus: '',
    declineReason: '',
    // seenByAdmin: '',
    seenByDriver: '',
  })
  const routeData = {
    routeId: values.routeId,
    responseDate: moment().format().toString().substring(0,19)+".000Z",
    driverBookingStatus: values.driverBookingStatus,
    declineReason: values.declineReason,
    // seenByAdmin: '',
    seenByDriver: true,
  }
  useEffect(() => {
    setValues({
      ...values,
      routeId: current.id
    })
  }, [current])
  const [apiHit, setApiHit] = useState(false)
  const handleChange = (fieldName) => (event) =>{
    setValues({
      ...values, [fieldName]:
      event.target.value
    })
    if (event.target.value === "Assigned"){
      setApiHit((apiHit) => !apiHit)
    }
    if (event.target.value === "Unassigned") {
        openDeclineDropDown();
    }
  }
  const handleSubmit = async () => {
    if (routeData.driverBookingStatus !== ""){
      for (let key of Object.keys(routeData)) {
        if(routeData[key] === ''){
          delete routeData[key]
        }
      }
      dispatch(updateRouteDriverStatus(routeData))
      setTimeout(()=> {
        dispatch(
          getRoutesByUserId({ 
          driverId: auth.user.id,
          limit: routes.routeRequest.limit,
          page: routes.routeRequest.page,
          })
        );
      },2000)
    }
    setshowDeclineDropDown(false)
  }
  useEffect(() => {
    handleSubmit()
  }, [apiHit])
  const auth = useSelector((x) => x.auth);

  return (
    <>
        <tr
        role="row"
        className="odd border"
        key={i}
        onMouseLeave={() => setshowMoreVertDropDown(false)}
      >
        <td className="border-right">
          <b>
            {current?.routeNumber} {current.routeNumberReturn && "( Return )"}
          </b>
        </td>
        <td className="border-right">{current?.customerDetails?.length}</td>
        <td className="border-right">
          <button
            className="btn btn-sm btn-primary"
            onClick={() => navigate(`/today-route/details/${current.id}`)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-eye"
              viewBox="0 0 16 16"
            >
              <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z" />
              <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z" />
            </svg>{" "}
            </button>
        </td>
        <td className="border-right"> {current.driverBookingStatus} </td>
        {/* <td className="border-right">
          <div className="select-picker">
            {
              current.driverBookingStatus === 'Assigned' ?
              <button type="button" className="btn btn-success" style={{cursor:"default", margin: "auto", width: "100%"}} >Accepted</button>
              :
              auth?.user?.permissionId?.myRoutes?.update === true &&
              <select className="form-control select-picker assign_role" style={{ height: '35px' }} value={values.driverBookingStatus} onChange={handleChange('driverBookingStatus')}>
                <option value="">New Request</option>
                <option value="Assigned">Accept</option>
                <option value="Unassigned">Decline</option>
              </select>
            }
          </div>
        </td> */}
      </tr>
      <DriverRouteDeclineModal showDeclineDropDown={showDeclineDropDown} openDeclineDropDown={openDeclineDropDown} values={values} setValues={setValues} current={current} i={i} handleSubmit={handleSubmit} handleChange={handleChange}/>
    </>
  )
}
export default DriverRouteComponentRow