import moment from "moment";
import React from "react";
import { useSelector } from "react-redux";

function StudentsList() {
  const routes = useSelector((state) => state.routes);
  const service = useSelector((state) => state.service);

  const { routeDetails } = routes;
  return (
    <table
      className="table border w-100 dataTable no-footer"
      id="leads-table"
      role="grid"
      aria-describedby="leads-table_info"
      style={{ overflowX: "auto" }}
    >
      <thead className="card-header">
        <tr role="row">
          <th
            title="Passenger Name"
            className="sorting border-right"
            tabIndex="0"
            aria-controls="leads-table"
            rowSpan="1"
            colSpan="1"
            aria-label="Mobile: activate to sort column ascending"
          >
            Passenger Name
          </th>
          <th
            title="Payer"
            className="sorting border-right"
            tabIndex="0"
            aria-controls="leads-table"
            rowSpan="1"
            colSpan="1"
            aria-label="Mobile: activate to sort column ascending"
          >
            Payer
          </th>
          <th
            title="School"
            className="sorting border-right"
            tabIndex="0"
            aria-controls="leads-table"
            rowSpan="1"
            colSpan="1"
            aria-label="Mobile: activate to sort column ascending"
          >
            School
          </th>
          <th
            title="Type"
            className="sorting_disabled border-right"
            rowSpan="1"
            colSpan="1"
            aria-label="Next Follow Up"
          >
            Type
          </th>
          <th
            title="Pickup"
            className="sorting border-right"
            tabIndex="0"
            aria-controls="leads-table"
            rowSpan="1"
            colSpan="1"
            aria-label="Lead Agent: activate to sort column ascending"
          >
            Pickup
          </th>
          <th
            title="Dropoff"
            className="sorting border-right"
            tabIndex="0"
            aria-controls="leads-table"
            rowSpan="1"
            colSpan="1"
            aria-label="Lead Agent: activate to sort column ascending"
          >
            Dropoff
          </th>

          <th
            title="Mileage"
            className="sorting border-right"
            tabIndex="0"
            aria-controls="leads-table"
            rowSpan="1"
            colSpan="1"
            aria-label="Lead Agent: activate to sort column ascending"
          >
            Mileage
          </th>
          <th
            title="Days"
            className="sorting border-right"
            tabIndex="0"
            aria-controls="leads-table"
            rowSpan="1"
            colSpan="1"
            aria-label="Lead Agent: activate to sort column ascending"
          >
            Days
          </th>
          <th
            title="Notes"
            className="sorting border-right"
            tabIndex="0"
            aria-controls="leads-table"
            rowSpan="1"
            colSpan="1"
            aria-label="Lead Agent: activate to sort column ascending"
          >
            Notes
          </th>
          {/* <th
                      title="Action"
                      className="text-right pr-20 sorting_disabled border-right"
                      rowSpan="1"
                      colSpan="1"
                      aria-label="Action"
                    >
                      Actions
                    </th> */}
        </tr>
      </thead>
      <tbody>
        {routeDetails?.customerDetails?.map((current, i) => {
          return (
            <tr role="row" className="odd border" key={i}>
              <td className="border-right">
                <div>
                  <p className="m-0">
                    {current?.customerId?.firstName} {current?.customerId?.lastName}
                  </p>
                </div>
              </td>
              <td className="border-right">
                {current?.customerId?.externalCompanyId ? (
                  <p className="m-0">
                    {current?.customerId?.externalCompanyId?.name}
                  </p>
                ) : (
                  "Individual"
                )}
              </td>
              <td className="border-right">{current?.schoolName}</td>
              <td className="border-right">
                {service.service &&
                  service.service.length > 0 &&
                  service.service.find((x) => x.id == current.type)?.name}
              </td>
              <td className="border-right">
                <div>
                  <p className="m-0">{current.pickUpAddress?.address}</p>
                  <p className="m-0">
                    {moment(current.pickUpDate).format("DD MMMM, YYYY")} @{" "}
                    {moment(current.pickUpTime, "hh:mm").format(" hh:mm a")}
                  </p>
                </div>
              </td>
              <td className="border-right">
                <div>
                  <p className="m-0">{current.dropOffAddress?.address}</p>
                  <p className="m-0">
                    {moment(current.dropOffDate).format("DD MMMM, YYYY")} @{" "}
                    {moment(current.dropOffTime, "hh:mm").format("hh:mm a")}
                  </p>
                </div>
              </td>
              <td className="border-right">
                {current?.fareAndDistance?.totalMileage} miles
              </td>
              <td className="border-right">
                {current?.daysOfWeek.map(
                  (x) => x.active && <span> {x.dayName.slice(0, 3)},</span>
                )}
              </td>

              <td className="border-right">{current.dispatchNotes}</td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

export default StudentsList;
