/** @format */

import moment from "moment";
import React, { useState } from "react";
// import Accordion from "react-bootstrap/Accordion";
import { useDispatch, useSelector } from "react-redux";
import { notifyFailure, notifySuccess } from "../../components/toast";
import { SERVERADDRESS } from "../../constants";
import axios from "axios";
import {
  createMultipleSettlement,
  updateSettlementSuccess,
} from "../../actions/invoiceActions";

const AdminAccountingModalDriverSettlement = ({
  showDropDown,
  openDropDown,
  current,
  from,
  to,
}) => {
  const [detail, setDetail] = useState(false);
  const invoices = useSelector((state) => state.invoices);
  const { loading } = invoices;
  const auth = useSelector((state) => state.auth);
  const openDetail = () => {
    setDetail((detail) => !detail);
  };
  const dispatch = useDispatch();
  const chicagoDateOptions = {
    timeZone: "America/Chicago",
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  };

  const chicagoDate = new Date().toLocaleDateString(
    "en-US",
    chicagoDateOptions
  );
  const parts = chicagoDate.split("/");
  const formattedChicagoDate = `${parts[2]}-${parts[0]}-${parts[1]}`;
  const generate = async () => {
    let data = [];
    data = invoices?.notPaid;
    let arr = [];
    if (data.length > 0) {
      data.forEach((e) => {
        arr.push({
          driverId: e.driverId.id,
          delete: false,
          reservations: e.reservation.map((bookingItem) => bookingItem.id),
          generatedBy: auth.user.id,
          payerId: auth.user.id,
          companyId: auth.user.companyId.id,
          grossPay: parseFloat(e.totalPay.toFixed(2)),
          netAmount: parseFloat(e.totalPay.toFixed(2)),
          dateIssue: formattedChicagoDate,
          datePaid: formattedChicagoDate,
          status: "Paid",
          dateRange: {
            from: moment(from).format("YYYY-MM-DD"),
            to: moment(to).format("YYYY-MM-DD"),
          },
          batchNumber: e.batchNumber,
        });
      });
    }
    // try
    dispatch(
      createMultipleSettlement(
        {
          uploadData: arr,
        },
        successCB
      )
    );
    // {
    //   const { data } = await axios.post(
    //     `${SERVERADDRESS}/v1/settlement/createMultipleSettlement`,
    //     {
    //       // companyId: auth.user.companyId.id,
    //       uploadData: arr,
    //     }
    //   );

    // notifySuccess("Settlement created successfully !");
    // } catch (err) {
    // console.log(err);
    // notifyFailure(err?.response?.data?.message);
    // }
  };
  const successCB = (data) => {
    dispatch(updateSettlementSuccess(data));
    openDropDown();
  };
  const [openAccordionIndex, setOpenAccordionIndex] = useState(null);

  const openDetail2 = (index) => {
    setOpenAccordionIndex(openAccordionIndex === index ? null : index);
  };
  return (
    <>
      {/* <div
        id="generatedData"
        className="modal fade"
        tabIndex="-1"
        role="dialog"
        aria-hidden="true"
      >
        <div className="modal-dialog  modal-xl">
          <div className="modal-content"> */}
      <div
        className={`modal fade ${showDropDown ? "show modal-y-axis" : "hide"}`}
        role="dialog"
        data-backdrop="static"
        data-keyboard="false" // To prevent closing with the keyboard
        onClick={(e) => {
          // Check if the click occurred outside the modal content
          if (e.target === e.currentTarget) {
            openDropDown(); // Call your close modal function
          }
        }}
      >
        <div
          className="modal-dialog modal-dialog-centered"
          role="document"
          style={{ top: "8%" }}
        >
          {/* <form onSubmit={handleSubmit}> */}
          <div className="modal-content user-edit-modal">
            <div className="modal-header">
              <div className="d-flex align-items-center">
                <h5 className="modal-title" id="modelHeading">
                  Weekly Reports{" "}
                </h5>
                <span className="f-16 mx-3">
                  {" "}
                  {moment(from).format("MMM D")}
                  {" - "}
                  {moment(to).format("MMM D, YYYY")}
                  {/* Jun 17 – Jun 23, 2024 */}
                </span>
              </div>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => openDropDown()}
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="">
                <div className="card-body p-2">
                  <div
                    id="leads-table_wrapper"
                    className="dataTables_wrapper dt-bootstrap4 no-footer"
                  >
                    <div className="row">
                      <div className="col-sm-12">
                        <table
                          className="table border w-100 dataTable no-footer"
                          id="leads-table"
                          role="grid"
                          aria-describedby="leads-table_info"
                          style={{ overflowX: "auto" }}
                        >
                          <thead className="card-header">
                            <tr role="row">
                              <th
                                title="Batch#"
                                className="sorting border-right"
                                tabIndex="0"
                                aria-controls="leads-table"
                                rowspan="1"
                                colspan="1"
                                aria-label="Mobile: activate to sort column ascending"
                              >
                                Batch#
                              </th>
                              <th
                                title="Driver Name"
                                className="sorting_disabled border-right"
                                rowspan="1"
                                colspan="1"
                                aria-label="Next Follow Up"
                              >
                                Driver Name
                              </th>
                              <th
                                title="#ofTrips"
                                className="sorting border-right"
                                tabIndex="0"
                                aria-controls="leads-table"
                                rowspan="1"
                                colspan="1"
                                aria-label="Lead Agent: activate to sort column ascending"
                              >
                                # of Trips
                              </th>
                              <th
                                title="Date Issue"
                                className="sorting border-right"
                                tabIndex="0"
                                aria-controls="leads-table"
                                rowspan="1"
                                colspan="1"
                                aria-label="Lead Agent: activate to sort column ascending"
                              >
                                Date Issue
                              </th>
                              <th
                                title="Weekly Earning"
                                className="sorting border-right"
                                tabIndex="0"
                                aria-controls="leads-table"
                                rowspan="1"
                                colspan="1"
                                aria-label="Lead Agent: activate to sort column ascending"
                              >
                                Weekly Earning
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {invoices?.notPaid?.length > 0 &&
                              invoices?.notPaid?.map((current, i) => {
                                const isOpen = openAccordionIndex === i;
                                return (
                                  <>
                                    {/* <React.Fragment key={i}> */}

                                    <tr
                                      id={`row-${i}`}
                                      role="row"
                                      className="odd border"
                                      data-toggle="collapse"
                                      data-target={`#accordion-${i}`}
                                      aria-expanded={isOpen}
                                      aria-controls={`accordion-${i}`}
                                      onClick={() => openDetail2(i)}
                                    >
                                      {/* <tr
                                      id="row-20"
                                      role="row"
                                      className="odd border"
                                      // data-toggle="collapse"
                                      data-target="#accordion1"
                                      aria-expanded="false"
                                      aria-controls="accordion1"
                                      onClick={openDetail}
                                    > */}
                                      <td className="border-right">
                                        <strong>{current.batchNumber}</strong>{" "}
                                      </td>
                                      <td className="border-right">
                                        {current.driverId.firstName +
                                          " " +
                                          current.driverId.lastName}
                                      </td>
                                      <td className="border-right">
                                        {current.reservation.length}
                                      </td>
                                      <td className="border-right">
                                        {chicagoDate}
                                      </td>
                                      <td className="border-right">
                                        $
                                        {current.totalPay === 0
                                          ? "0.00"
                                          : current.totalPay
                                          ? current.totalPay.toFixed(2)
                                          : ""}
                                      </td>
                                    </tr>
                                    {current.reservation?.length > 0 &&
                                      current.reservation?.map((e, ind) => {
                                        return (
                                          <>
                                            <tr
                                              // id="accordion1"
                                              key={ind}
                                              className={`collapsing hiddenRow ${
                                                isOpen ? "" : "hide"
                                              }`}
                                            >
                                              <td colspan="5" className="p-1">
                                                <div className="table table-responsive">
                                                  <table className="invoice-table table border table-center mb-0 ">
                                                    <thead className="card-header">
                                                      <tr>
                                                        <th className=" border-right">
                                                          Order ID
                                                        </th>
                                                        <th className=" border-right">
                                                          Batch#
                                                        </th>
                                                        <th className=" border-right">
                                                          Driver Name
                                                        </th>
                                                        <th className=" border-right">
                                                          Passenger
                                                        </th>
                                                        <th className=" border-right">
                                                          Pickup - Drop Off
                                                        </th>
                                                        <th className=" border-right">
                                                          Driver Amount{" "}
                                                        </th>
                                                        <th>Status</th>
                                                      </tr>
                                                    </thead>
                                                    <tbody>
                                                      <tr>
                                                        <td className=" border-right">
                                                          <a
                                                            href="#"
                                                            target="_blank"
                                                          >
                                                            {e.orderId}{" "}
                                                          </a>
                                                        </td>
                                                        <td className=" border-right">
                                                          {current.batchNumber}
                                                        </td>
                                                        <td className=" border-right">
                                                          {current.driverId
                                                            .firstName +
                                                            " " +
                                                            current.driverId
                                                              .lastName}
                                                        </td>
                                                        <td className=" border-right">
                                                          {e?.customerId
                                                            ?.firstName +
                                                            " " +
                                                            e?.customerId
                                                              ?.lastName}
                                                        </td>
                                                        <td className="border-right w-30">
                                                          <div className="d-flex justify-content-between">
                                                            <div>
                                                              <div>
                                                                <p className="f-12 font-medium mb-0">
                                                                  <strong>
                                                                    {
                                                                      e
                                                                        ?.pickUpAddress
                                                                        ?.address
                                                                    }
                                                                  </strong>{" "}
                                                                </p>
                                                              </div>
                                                              <div>
                                                                <time>
                                                                  {" "}
                                                                  {moment(
                                                                    e?.pickUpDate?.substring(
                                                                      0,
                                                                      10
                                                                    )
                                                                  ).format(
                                                                    "MM/DD/YYYY"
                                                                  )}{" "}
                                                                  -{" "}
                                                                  {parseInt(
                                                                    e?.pickUpTime?.substring(
                                                                      0,
                                                                      2
                                                                    )
                                                                  ) === 0
                                                                    ? 12
                                                                    : parseInt(
                                                                        e?.pickUpTime?.substring(
                                                                          0,
                                                                          2
                                                                        )
                                                                      ) > 12
                                                                    ? parseInt(
                                                                        e?.pickUpTime?.substring(
                                                                          0,
                                                                          2
                                                                        )
                                                                      ) - 12
                                                                    : parseInt(
                                                                        e?.pickUpTime?.substring(
                                                                          0,
                                                                          2
                                                                        )
                                                                      )}
                                                                  {":" +
                                                                    e?.pickUpTime?.substring(
                                                                      3,
                                                                      5
                                                                    )}
                                                                  {parseInt(
                                                                    e?.pickUpTime?.substring(
                                                                      0,
                                                                      2
                                                                    )
                                                                  ) > 11
                                                                    ? "pm"
                                                                    : "am"}
                                                                </time>
                                                              </div>
                                                            </div>
                                                            <div className="mt-4 px-4">
                                                              <div className="hidden sm:block">
                                                                <svg
                                                                  xmlns="http://www.w3.org/2000/svg"
                                                                  width="50"
                                                                  height="8"
                                                                  viewBox="0 0 40 6"
                                                                  fill="none"
                                                                >
                                                                  <path
                                                                    d="M40 3L35 0.113249V5.88675L40 3ZM0 3.5H35.5V2.5H0V3.5Z"
                                                                    fill="black"
                                                                  ></path>
                                                                </svg>
                                                              </div>
                                                              <p className="text-center font-medium">
                                                                {/* <span>{e.distanceFromBaseLocation} {e.distanceFromPickToDropOff} {e.totalMileage} mi</span> */}
                                                                <span>
                                                                  {e?.fareAndDistance?.distanceFromPickToDropOff?.toFixed(
                                                                    2
                                                                  )}{" "}
                                                                  mi
                                                                </span>
                                                              </p>
                                                            </div>
                                                            <div>
                                                              <div>
                                                                <p className="f-12 font-medium mb-0">
                                                                  <strong>
                                                                    {
                                                                      e
                                                                        ?.dropOffAddress
                                                                        ?.address
                                                                    }
                                                                  </strong>
                                                                </p>
                                                              </div>
                                                              <div>
                                                                <time>
                                                                  {" "}
                                                                  {moment(
                                                                    e?.dropOffDate?.substring(
                                                                      0,
                                                                      10
                                                                    )
                                                                  ).format(
                                                                    "MM/DD/YYYY"
                                                                  )}{" "}
                                                                  -{" "}
                                                                  {parseInt(
                                                                    e?.dropOffTime?.substring(
                                                                      0,
                                                                      2
                                                                    )
                                                                  ) === 0
                                                                    ? 12
                                                                    : parseInt(
                                                                        e?.dropOffTime?.substring(
                                                                          0,
                                                                          2
                                                                        )
                                                                      ) > 12
                                                                    ? parseInt(
                                                                        e?.dropOffTime?.substring(
                                                                          0,
                                                                          2
                                                                        )
                                                                      ) - 12
                                                                    : parseInt(
                                                                        e?.dropOffTime?.substring(
                                                                          0,
                                                                          2
                                                                        )
                                                                      )}
                                                                  {":" +
                                                                    e?.dropOffTime?.substring(
                                                                      3,
                                                                      5
                                                                    )}
                                                                  {parseInt(
                                                                    e?.dropOffTime?.substring(
                                                                      0,
                                                                      2
                                                                    )
                                                                  ) > 11
                                                                    ? "pm"
                                                                    : "am"}
                                                                </time>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </td>

                                                        <td className=" border-right">
                                                          $
                                                          {parseFloat(
                                                            e?.fareAndDistance
                                                              ?.driverAmount
                                                          )?.toFixed(2)}
                                                        </td>
                                                        <td>{e.status}</td>
                                                      </tr>
                                                    </tbody>
                                                    {/* <tfoot className="card-header">
                                                      <tr>
                                                        <td colspan="4"></td>
                                                        <td className="text-right border-right">
                                                          Total{" "}
                                                        </td>
                                                        <td colspan="2">
                                                          $100
                                                        </td>
                                                      </tr>
                                                    </tfoot> */}
                                                  </table>
                                                </div>
                                              </td>
                                            </tr>
                                          </>
                                        );
                                      })}
                                  </>
                                  // </React.Fragment>
                                );
                              })}
                          </tbody>
                          <tfoot className="card-header">
                            <tr role="row">
                              <td colspan="3"></td>
                              <td className="border-right">
                                <strong>Total Earnings</strong>
                              </td>
                              <td colspan="3">
                                <strong>
                                  $
                                  {invoices?.notPaid?.length > 0 &&
                                    parseFloat(
                                      invoices?.notPaid?.reduce(
                                        (sum, order) => sum + order.totalPay,
                                        0
                                      )
                                    ).toFixed(2)}
                                </strong>
                              </td>
                            </tr>
                          </tfoot>
                          {/* {invoices &&
                            invoices?.notPaid &&
                            // invoices?.notPaid?.future &&
                            invoices?.notPaid?.length === 0 && (
                              <>
                                <div
                                  className="dash-stats-list"
                                  style={{ textAlign: "center" }}
                                >
                                  <br />
                                  <br />
                                  <p style={{ opacity: "0.5" }}>
                                    No Data Found
                                  </p>
                                </div>
                              </>
                            )} */}
                        </table>
                        {invoices &&
                          invoices?.notPaid &&
                          // invoices?.notPaid?.future &&
                          invoices?.notPaid?.length === 0 && (
                            <>
                              <div
                                className="dash-stats-list"
                                style={{ textAlign: "center" }}
                              >
                                {/* <br /> */}
                                <br />
                                <p style={{ opacity: "0.5" }}>
                                  This week's settlements have already been
                                  generated.
                                </p>
                              </div>
                            </>
                          )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn-cancel border rounded mr-3"
                data-dismiss="modal"
                onClick={() => openDropDown()}
              >
                Close
              </button>

              <button
                type="button"
                className="btn btn-primary  btn-generate"
                onClick={generate}
                disabled={invoices?.notPaid?.length === 0 || loading}
              >
                {" "}
                Generate <i className="fa fa-paper-plane"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default AdminAccountingModalDriverSettlement;
