import React from "react";
import { View, StyleSheet } from "@react-pdf/renderer";
import TableHeader from "./TableHead";
import TableRow from "./TableRow";
// import InvoiceTableFooter from "./TableFooter";

const styles = StyleSheet.create({
  tableContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
    marginTop: 16,
    borderWidth: 1,
    borderColor: "#000",
    width: "92%",
  },
});

const RideHistoryTable = ({ data }) => (
  <View style={styles.tableContainer}>
    <TableHeader />
    <TableRow data={data?.reservationId?.rideHistory} />
    {/* <InvoiceTableFooter
      items={invoice.items}
      total={invoice.total}
      subTotal={invoice.subTotal}
      shippingFee={invoice.shippingFee}
      tax={invoice.tax}
    /> */}
  </View>
);

export default RideHistoryTable;
