/** @format */

import React, { useEffect, useState } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import AdminTodayOrderComponent from "../../components/adminComponents/adminDispatchingComponents/AdminTodayOrderComponent";
import AdminHeader from "../../components/adminComponents/adminHeaderSidebarTitleComponents/AdminHeader";
import AdminSidebar from "../../components/adminComponents/adminHeaderSidebarTitleComponents/AdminSidebar";
import { useDispatch, useSelector } from "react-redux";
import { navigateUserFunc } from "../../actions/generalActions";
import AdminSearchByFilterComponent from "../../components/adminComponents/adminDispatchingComponents/AdminSearchByFilterComponent";
import AdminOrderSearchTable from "../../components/adminComponents/adminDispatchingComponents/AdminOrderSearchTable";
import {
  tabAssignedDispatching,
  tabPendingDispatching,
  tabUnassignedDispatching,
} from "../../actions/tabActions";
import AdminPendingOrderComponent from "../../components/adminComponents/adminDispatchingComponents/AdminPendingOrderComponent";
import AdminSchedullingOrderComponent from "../../components/adminComponents/adminDispatchingComponents/AdminSchedullingOrderComponent";
import AdminSearchByFilterComponentV2 from "../../components/adminComponents/adminDispatchingComponents/AdminSearchByFilterComponentV2";
import { getReservationsSchedule } from "../../actions/reservationAction";
import moment from "moment";

const AdminDispatchingPage = () => {
  const auth = useSelector((state) => state?.auth);
  const myState = useSelector((state) => state.changeTheTab);
  const dispatch = useDispatch();
  let navigate = useNavigate();

  const [showFilter, setShowFilter] = useState(false);

  // console.log("show filter==>", showFilter);

  useEffect(() => {
    if (
      auth?.user?.permissionId?.dispatching?.isAllow === false ||
      auth?.user?.disable === true
    ) {
      navigateUserFunc(auth?.user, navigate);
    }
  }, []);

  const reservationsScheduleData = async (e) => {
    dispatch(
      getReservationsSchedule({
        companyId: auth.user.companyId.id,
        today: moment().format("YYYY-MM-DD"),
        assigned: "unassigned",
        limit: 10,
        page: 1,
      })
    );
  };

  // console.log(
  //   "update==>",
  //   myState.viewDispatching,
  //   myState.viewDispatching === "viewAssignedDispatching "
  // );

  return (
    <>
      <AdminHeader />
      <AdminSidebar />
      <div className="body-wrapper clearfix">
        <section className="main-container " id="fullscreen">
          <div className="px-4 py-2 border-top-0 emp-dashboard">
            <div className="d-block d-lg-flex d-md-flex justify-content-between action-bar mb-20 mt-3">
              <div id="table-actions" className="flex-grow-1 align-items-center">
                <h4 className=" mb-0 f-21 text-capitalize font-weight-bold">
                  {" "}
                  All Reservation{" "}
                </h4>
              </div>
              <div
                className="btn-group mt-2 mt-lg-0 mt-md-0 ml-0 ml-lg-3 ml-md-3"
                role="group"
              >
                <div
                  className="btn-group me-2"
                  role="group"
                  aria-label="First group"
                >
                  <button
                    type="button"
                    className="btn btn-success"
                    data-toggle="tooltip"
                    data-original-title="Refresh"
                    onClick={reservationsScheduleData}
                  >
                    <i className="fas fa-sync"></i>
                  </button>
                  <button
                    type="button"
                    className="btn btn-dark"
                    data-toggle="tooltip"
                    data-original-title="Filters"
                    onClick={() => setShowFilter(!showFilter)}
                  >
                    <i className="fa fa-filter"></i>{" "}
                  </button>
                  {/* <button
                    type="button"
                    className="btn btn-info"
                    style={{ backgroundColor: "#14737D" }}
                    data-toggle="tooltip"
                    data-original-title="Upload File"
                  >
              
                    <i
                      className="fas fa-upload"
                      type="button"
                      data-toggle="modal"
                      data-target="#uploadDoc"
                    ></i>{" "}
                  </button> */}
                  {/* <button
                    type="button"
                    className="btn btn-info"
                    data-toggle="tooltip"
                    data-original-title="Export Excel File"
                  >
           
                    <i className="fas fa-file-excel"></i>{" "}
                  </button> */}
                  <Link
                    type="button"
                    className="btn btn-info d-flex align-items-center"
                    to=""
                    onClick={() => dispatch(tabPendingDispatching())}
                  >
                    Pending Orders{" "}
                    <i
                      className="fa fa-eye"
                      style={{ fontSize: "22px", marginLeft: "5px" }}
                    ></i>
                  </Link>{" "}
                  <Link
                    type="button"
                    className="btn btn-info d-flex align-items-center"
                    to=""
                    style={{ background: "#1b6744" }}
                    onClick={() => dispatch(tabUnassignedDispatching())}
                  >
                    Unassigned Orders{" "}
                    <i
                      className="fa fa-eye"
                      style={{ fontSize: "22px", marginLeft: "5px" }}
                    ></i>
                  </Link>
                  <Link
                    type="button"
                    className="btn btn-info d-flex align-items-center"
                    to=""
                    onClick={() => dispatch(tabAssignedDispatching())}
                  >
                    Assigned Orders{" "}
                    <i
                      className="fa fa-eye"
                      style={{ fontSize: "22px", marginLeft: "5px" }}
                    ></i>
                  </Link>
                  <Link to="" type="button" className="btn btn-primary">
                    Create{" "}
                    <i className="fas fa-plus-circle d-none d-sm-inline-block"></i>{" "}
                  </Link>
                </div>
              </div>
            </div>

            {showFilter && (
              <AdminSearchByFilterComponentV2 showFilter={showFilter} />
            )}
            {/* <div className="d-block d-lg-flex d-md-flex justify-content-between action-bar mb-20 mt-3">
              <div
                id="table-actions"
                className="flex-grow-1 align-items-center"
              >
                <h4 className=" mb-0 f-21 text-capitalize font-weight-bold">
                  {" "}
                  Dispatching{" "}
                </h4>
              </div>
              <div
                className="btn-group mt-2 mt-lg-0 mt-md-0 ml-0 ml-lg-3 ml-md-3"
                role="group"
              >
                <NavLink
                  to="/requested-orders"
                  type="button"
                  className={`btn-secondary rounded f-14 p-2 mr-3 float-left mb-2 mb-lg-0 mb-md-0 ${myState.viewDispatching !== "dateSearch" ? 'activee' : ''}`}
                  id="add-lead"
                  onClick={() => dispatch(tabUnassignedDispatching())}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-eye"
                    viewBox="0 0 16 16"
                  >
                    <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z" />
                    <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z" />
                  </svg>{" "}
                  Unassigned Orders
                </NavLink>
                <NavLink
                  to="/pending-orders"
                  type="button"
                  className="btn-secondary rounded f-14 p-2 mr-3 float-left mb-2 mb-lg-0 mb-md-0"
                  id="add-lead"
                  onClick={() => dispatch(tabPendingDispatching())}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-eye"
                    viewBox="0 0 16 16"
                  >
                    <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z" />
                    <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z" />
                  </svg>{" "}
                  Pending Orders
                </NavLink>
                <NavLink
                  to="/scheduled-orders"
                  type="button"
                  className="btn-secondary rounded f-14 p-2 mr-3 float-left mb-2 mb-lg-0 mb-md-0"
                  id="add-lead"
                  onClick={() => dispatch(tabAssignedDispatching())}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-eye"
                    viewBox="0 0 16 16"
                  >
                    <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z" />
                    <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z" />
                  </svg>{" "}
                  Assigned Orders
                </NavLink>
              </div>
            </div> */}

            {/* <AdminSearchByFilterComponent /> */}
            {myState.viewDispatching === "dateSearch" ? (
              <>
                {/* <AdminSearchByFilterComponent /> */}
                <AdminOrderSearchTable />
              </>
            ) : myState.viewDispatching === "viewUnassignedDispatching" ? (
              <>
                {/* <AdminSearchByFilterComponent /> */}
                <AdminTodayOrderComponent />
              </>
            ) : myState.viewDispatching === "viewPendingDispatching" ? (
              <>
                {/* <AdminSearchByFilterComponent /> */}
                <AdminPendingOrderComponent />
              </>
            ) : myState.viewDispatching === "viewAssignedDispatching" ? (
              <>
                {/* <AdminSearchByFilterComponent /> */}
                <AdminSchedullingOrderComponent />
              </>
            ) : (
              <>
                {/* <AdminSearchByFilterComponent /> */}
                <AdminTodayOrderComponent />
              </>
            )}
          </div>
        </section>
      </div>
    </>
  );
};
export default AdminDispatchingPage;
