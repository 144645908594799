import axios from "axios";
import { notifyFailure, notifySuccess } from "../components/toast";
import {
    SERVICE_VIEW_REQUEST,
    SERVICE_VIEW_SUCCESS,
    SERVICE_VIEW_FAIL,
    SERVICE_UPDATE_REQUEST,
    SERVICE_UPDATE_SUCCESS,
    SERVICE_UPDATE_FAIL,
    SERVERADDRESS,
} from "../constants"

// ALL SERVICE LIST 
export const getServicesByCompanyId = (obj) => async (dispatch) => {
    dispatch({
        type: SERVICE_VIEW_REQUEST,
    });
    try {
        const { data } = await axios.post(`${SERVERADDRESS}/v1/service/getServicesByCompanyId`, obj);
        localStorage.setItem("service", JSON.stringify(data));
        dispatch({
            type: SERVICE_VIEW_SUCCESS,
            payload: data,
        });
    }
    catch (err) {
        dispatch({
            type: SERVICE_VIEW_FAIL,
            payload: err,
        });
    }
};

// Edit SERVICE
export const updateService = (obj) => async (dispatch) => {
    dispatch({
      type: SERVICE_UPDATE_REQUEST,
    });
    try {
      const { data } = await axios.put(
        `${SERVERADDRESS}/v1/service/updateService`,
        obj
      );
      // handleSuccess();
      dispatch({
        type: SERVICE_UPDATE_SUCCESS,
        payload: data,
      });
      notifySuccess("Service Name Added successfully !");
    } catch (err) {
      // console.log(err);
      notifyFailure(err?.response?.data?.message);
      dispatch({
        type: SERVICE_UPDATE_FAIL,
        payload: err,
      });
    }
  };
