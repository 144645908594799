/** @format */

import React, { useState } from "react";

const AdminBookingPriceDetailModal = ({
  showDetailDropDown,
  openDetailDropDown,
  fareDetails,
  useHardCodedFares,
  toogleHardCoded,
  hardCodedFares,
  handleHardCodedFares,
  recalculate,
  handleUsePrecalculatedMilesTrue,
  handleUsePrecalculatedMilesFalse,
  useDirectFare,
  toogleDirectFare,
  directFare,
  handleDirectFare,
  fareDirectlyCalculate,
  useFlatDriverRate,
  toogleFlatDriverRate,
  flatDriverRate,
  handleFlatDriverRate,
  flatDriverRateRecalculate,
  handleVeryDirectFare,
  handleUpdateValue,
}) => {
  // console.log("hardCodedFares=>", hardCodedFares);
  // const [values, setValues] = useState({
  //     flatBaseFare: "",
  //     farePerMile: "",
  //     deadHeadThreshold: "",
  //     baseFarePerMile: "",
  //     totalMileageAllows: "",
  //     longDistancePerMile: "",
  //     offHourFee: "",
  //     noShowFee: "",
  //     cancelationFee: "",
  // });
  const alphabets = [
    "a",
    "b",
    "c",
    "d",
    "e",
    "f",
    "g",
    "h",
    "i",
    "j",
    "k",
    "l",
    "m",
    "n",
    "o",
    "p",
    "q",
    "r",
    "s",
    "t",
    "u",
    "v",
    "w",
    "x",
    "y",
    "z",
    "A",
    "B",
    "C",
    "D",
    "E",
    "F",
    "G",
    "H",
    "I",
    "J",
    "K",
    "L",
    "M",
    "N",
    "O",
    "P",
    "Q",
    "R",
    "S",
    "T",
    "U",
    "V",
    "W",
    "X",
    "Y",
    "Z",
    "+",
    "-",
    "/",
    "*",
    "!",
    "@",
    "#",
    "$",
    "%",
    "^",
    "&",
    "_",
    "(",
    ")",
  ];

  return (
    <>
      <div
        className={`modal fade ${
          showDetailDropDown ? "show modal-y-axis" : "hide"
        }`}
        role="dialog"
        data-backdrop="static"
        data-keyboard="false" // To prevent closing with the keyboard
        onClick={(e) => {
          // Check if the click occurred outside the modal content
          if (e.target === e.currentTarget) {
            openDetailDropDown(); // Call your close modal function
          }
        }}
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header bg-additional-grey">
              <h5 className="modal-title" id="exampleModalLongTitle">
                {" "}
                Pricing Details
              </h5>
              <button
                type="button"
                id="button1"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => openDetailDropDown()}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <br />
            {/* <div className="modal-body">
                            <div className="row">
                                <div className="col-md-3">
                                    <div className="form-group my-3">
                                        <label
                                            className="f-14 text-dark-grey mb-12"
                                            data-label=""
                                            htmlFor="after_days"
                                        >
                                            HQ to Pickup
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control height-35 f-14"
                                            placeholder="Enter Your First Name"
                                            autoComplete="false"
                                            value={fareDetails?.distanceFromBaseLocation + " mi"}
                                            required />
                                    </div>
                                </div>

                                <div className="col-md-3">
                                    <div className="form-group my-3">
                                        <label
                                            className="f-14 text-dark-grey mb-12"
                                            data-label=""
                                            htmlFor="after_days"
                                        >
                                            Ride Distance
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control height-35 f-14"
                                            placeholder="Enter Your Last Name"
                                            autoComplete="false"
                                            value={fareDetails?.distanceFromPickToDropOff + " mi"}
                                            required />
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group my-3">
                                        <label
                                            className="f-14 text-dark-grey mb-12"
                                            data-label=""
                                            htmlFor="after_days"
                                        >
                                            Base Fare
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control height-35 f-14"
                                            placeholder="Enter Your First Name"
                                            autoComplete="false"
                                            value={"$ " + fareDetails?.baseFare.toFixed(2)}
                                            required />
                                    </div>
                                </div>

                                <div className="col-md-3">
                                    <div className="form-group my-3">
                                        <label
                                            className="f-14 text-dark-grey mb-12"
                                            data-label=""
                                            htmlFor="after_days"
                                        >
                                            Ride Fare
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control height-35 f-14"
                                            placeholder="Enter Your Last Name"
                                            autoComplete="false"
                                            value={"$ " + fareDetails?.rideFare.toFixed(2)}
                                            required />
                                    </div>
                                </div>
                                
                                <div className="col-md-3">
                                    <div className="form-group my-3">
                                        <label
                                            className="f-14 text-dark-grey mb-12"
                                            data-label=""
                                            htmlFor="after_days"
                                        >
                                            Driver Amount
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control height-35 f-14"
                                            placeholder="Enter Your Last Name"
                                            autoComplete="false"
                                            value={"$ " + fareDetails?.driverAmount}
                                            required />
                                    </div>
                                </div>

                                <div className="col-md-3">
                                    <div className="form-group my-3">
                                        <label
                                            className="f-14 text-dark-grey mb-12"
                                            data-label=""
                                            htmlFor="after_days"
                                        >
                                            OffHour Fee
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control height-35 f-14"
                                            placeholder="Enter Your Last Name"
                                            autoComplete="false"
                                            value={fareDetails?.offHourFee ? "$ "+fareDetails?.offHourFee : "$ "+0}
                                            required />
                                    </div>
                                </div>

                                <div className="col-md-3">
                                    <div className="form-group my-3">
                                        <label
                                            className="f-14 text-dark-grey mb-12"
                                            data-label=""
                                            htmlFor="after_days"
                                        >
                                            Company Amount
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control height-35 f-14"
                                            placeholder="Enter Your Last Name"
                                            autoComplete="false"
                                            value={"$ " + fareDetails?.companyAmount}
                                            required />
                                    </div>
                                </div>

                                <div className="col-md-3">
                                    <div className="form-group my-3">
                                        <label
                                            className="f-14 text-dark-grey mb-12"
                                            data-label=""
                                            htmlFor="after_days"
                                        >
                                            Total Amount
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control height-35 f-14"
                                            placeholder="Enter Your Last Name"
                                            autoComplete="false"
                                            value={"$ " + fareDetails?.amount}
                                            required />
                                    </div>
                                </div>

                            </div>
                        </div> */}
            <table
              className="table border dataTable no-footer"
              id="leads-table"
              role="grid"
              aria-describedby="leads-table_info"
              style={{ overflowX: "auto", width: "95%", margin: "auto" }}
            >
              <thead className="card-header">
                <tr role="row">
                  <th
                    title="HQ to Pickup"
                    className="sorting border-right"
                    tabIndex="0"
                    aria-controls="leads-table"
                    rowSpan="1"
                    colSpan="1"
                    aria-label="Mobile: activate to sort column ascending"
                  >
                    HQ to Pickup
                  </th>
                  <th
                    title="Ride Distance"
                    className="sorting_disabled border-right"
                    rowSpan="1"
                    colSpan="1"
                    aria-label="Next Follow Up"
                  >
                    Ride Distance
                  </th>
                  <th
                    title="Est. Distance"
                    className="sorting_disabled border-right"
                    rowSpan="1"
                    colSpan="1"
                    aria-label="Next Follow Up"
                  >
                    Est. Distance
                  </th>
                  <th
                    title="Client Determining Mileage"
                    className="sorting_disabled border-right"
                    rowSpan="1"
                    colSpan="1"
                    aria-label="Next Follow Up"
                  >
                    Client Determining Mileage
                  </th>
                  <th
                    title="Base Fare"
                    className="sorting border-right"
                    tabIndex="0"
                    aria-controls="leads-table"
                    rowSpan="1"
                    colSpan="1"
                    aria-label="Lead Agent: activate to sort column ascending"
                  >
                    Base Fare
                  </th>
                  <th
                    title="Ride Fare"
                    className="sorting border-right"
                    tabIndex="0"
                    aria-controls="leads-table"
                    rowSpan="1"
                    colSpan="1"
                    aria-label="Lead Agent: activate to sort column ascending"
                  >
                    Ride Fare
                  </th>
                  <th
                    title="Driver Amount"
                    className="sorting border-right"
                    tabIndex="0"
                    aria-controls="leads-table"
                    rowSpan="1"
                    colSpan="1"
                    aria-label="Lead Agent: activate to sort column ascending"
                  >
                    Driver Amount
                  </th>
                  {/* <th
                    title="OffHour Fee"
                    className="sorting border-right"
                    tabIndex="0"
                    aria-controls="leads-table"
                    rowSpan="1"
                    colSpan="1"
                    aria-label="Lead Agent: activate to sort column ascending"
                  >
                    OffHour Fee
                  </th> */}
                  {/* <th
                    title="Company Amount"
                    className="sorting border-right"
                    tabIndex="0"
                    aria-controls="leads-table"
                    rowSpan="1"
                    colSpan="1"
                    aria-label="Lead Agent: activate to sort column ascending"
                  >
                    Company Amount
                  </th> */}
                  <th
                    title="Total Amount"
                    className="sorting border-right"
                    tabIndex="0"
                    aria-controls="leads-table"
                    rowSpan="1"
                    colSpan="1"
                    aria-label="Lead Agent: activate to sort column ascending"
                  >
                    Total Amount
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  role="row"
                  className="odd border"
                  // onMouseLeave={() => setshowMoreVertDropDown(false)}
                >
                  <td className="border-right">
                    {fareDetails?.apiDistanceFromBaseLocation
                      ? fareDetails?.apiDistanceFromBaseLocation + " mi"
                      : fareDetails?.distanceFromBaseLocation + " mi"}
                  </td>
                  <td className="border-right">
                    {fareDetails?.apiDistanceFromPickToDropOff
                      ? fareDetails?.apiDistanceFromPickToDropOff + " mi"
                      : fareDetails?.distanceFromPickToDropOff + " mi"}
                    {/* <span
                      style={{
                        color: "blue",
                        // borderBottom: "3px double",
                        marginLeft: "10px",
                        cursor: "pointer",
                      }}
                      onClick={handleUsePrecalculatedMilesFalse}
                    >
                      {" "}
                      use
                    </span> */}
                  </td>
                  <td
                    className="border-right"
                    // style={{ display: "flex" }}
                  >
                    {fareDetails?.precalculatedMiles
                      ? fareDetails?.precalculatedMiles + " mi"
                      : "-"}
                    {/* <input
                      type="text"
                      className="form-control height-35 f-14"
                      style={{ width: "70%" }}
                      // placeholder="Enter Load Fee"
                      // id="Name"
                      onKeyDown={(e) => {
                        if (e.ctrlKey && e.code === "KeyV") {
                          return;
                        }
                        if (alphabets.includes(e.key)) {
                          e.preventDefault();
                        }
                      }}
                      name="precalculatedMiles"
                      // defaultValue={fareDetails?.distanceFromPickToDropOff}
                      value={hardCodedFares?.precalculatedMiles}
                      onChange={handleHardCodedFares("precalculatedMiles")}
                    /> */}
                    {/* <span
                      style={{
                        // color: "blue",
                        // // borderBottom: "3px double",
                        // marginLeft: "10px",
                        // cursor: "pointer",
                        // marginTop: "0.6rem",
                        color: "blue",
                        // borderBottom: "3px double",
                        marginLeft: "10px",
                        cursor: "pointer",
                      }}
                      onClick={handleUsePrecalculatedMilesTrue}
                    >
                      {" "}
                      use
                    </span> */}
                  </td>
                  <td className="border-right">
                    {fareDetails?.avgDistanceFromPickToDropOff
                      ? fareDetails?.avgDistanceFromPickToDropOff + " mi"
                      : "-"}
                  </td>
                  <td className="border-right">
                    {"$ " +
                      (typeof fareDetails?.baseFare === "number"
                        ? fareDetails.baseFare.toFixed(2)
                        : "0.00")}
                  </td>
                  <td className="border-right">
                    {"$ " +
                      (typeof fareDetails?.rideFare === "number"
                        ? fareDetails.rideFare.toFixed(2)
                        : "0.00")}
                  </td>
                  <td className="border-right">
                    {"$ " +
                      (typeof fareDetails?.driverAmount === "number"
                        ? fareDetails.driverAmount.toFixed(2)
                        : "0.00")}
                  </td>
                  {/* <td className="border-right">
                    {fareDetails?.offHourFee
                      ? "$" + fareDetails?.offHourFee
                      : "$" + 0}
                  </td> */}
                  {/* <td className="border-right">
                    {fareDetails?.companyAmount
                      ? "$ " + fareDetails?.companyAmount?.toFixed(2)
                      : "$" + 0}
                  </td> */}
                  <td className="border-right">
                    {"$" +
                      (typeof fareDetails?.amount === "number"
                        ? fareDetails.amount.toFixed(2)
                        : "0.00")}
                  </td>
                </tr>
              </tbody>
            </table>
            <div className="row" style={{ width: "95%", margin: "auto" }}>
              <div className="col-md-3">
                <div className="form-group my-2">
                  <div className="d-flex">
                    <div>
                      <label
                        className="f-14 text-dark mb-12"
                        data-label=""
                        htmlFor="clnumber"
                      >
                        Add Custom Pricing
                      </label>
                    </div>
                  </div>
                  <div className="btn-choice">
                    <label className="switch">
                      <input
                        type="checkbox"
                        className="switch-input"
                        id="toggle"
                        checked={useHardCodedFares}
                        onChange={() => toogleHardCoded()}
                      />
                      <span
                        className="switch-label"
                        data-on=""
                        data-off=""
                      ></span>
                      <span className="switch-handle"></span>
                    </label>
                  </div>
                </div>
              </div>
              {/* <div className="col-md-3">
                <div className="form-group my-3">
                  <div className="d-flex">
                    <div>
                      <label
                        className="f-14 text-dark mb-12"
                        data-label=""
                        htmlFor="clnumber"
                      >
                        Flat Rate
                      </label>
                    </div>
                  </div>
                  <div className="btn-choice">
                    <label className="switch">
                      <input
                        type="checkbox"
                        className="switch-input"
                        id="toggle"
                        checked={useDirectFare}
                        onClick={() => toogleDirectFare()}
                      />
                      <span
                        className="switch-label"
                        data-on=""
                        data-off=""
                      ></span>
                      <span className="switch-handle"></span>
                    </label>
                  </div>
                </div>
              </div> */}
              <div className="col-md-3">
                <div className="form-group my-2">
                  <div className="d-flex">
                    <div>
                      <label
                        className="f-14 text-dark mb-12"
                        data-label=""
                        htmlFor="clnumber"
                      >
                        Driver Flat Rate
                      </label>
                    </div>
                  </div>
                  <div className="btn-choice">
                    <label className="switch">
                      <input
                        type="checkbox"
                        className="switch-input"
                        id="toggle2"
                        checked={useFlatDriverRate}
                        onChange={() => toogleFlatDriverRate()}
                      />
                      <span
                        className="switch-label"
                        data-on=""
                        data-off=""
                      ></span>
                      <span className="switch-handle"></span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
            {useHardCodedFares === true && (
              <div className="row" style={{ width: "95%", margin: "auto" }}>
                <div className="col-md-6">
                  <div className="form-group ">
                    <div className="d-flex">
                      <div>
                        <label
                          className="f-14 text-dark mb-12"
                          data-label=""
                          htmlFor="clnumber"
                        >
                          <strong>Load Fee: </strong>
                        </label>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <input
                            type="text"
                            id="fare1"
                            className="form-control height-35 f-14 pricing-modal-input"
                            disabled
                            value={"$"}
                          />
                          <input
                            type="text"
                            className="form-control height-35 f-14"
                            id="fare2"
                            onKeyDown={(e) => {
                              if (e.ctrlKey && e.code === "KeyV") {
                                return;
                              }
                              if (alphabets.includes(e.key)) {
                                e.preventDefault();
                              }
                            }}
                            name="flatBaseFare"
                            value={hardCodedFares?.flatBaseFare}
                            onChange={handleHardCodedFares("flatBaseFare")}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group ">
                    <div className="d-flex">
                      <div>
                        <label
                          className="f-14 text-dark mb-12"
                          data-label=""
                          htmlFor="clnumber"
                        >
                          <strong>Ride Fare: </strong>
                          {/* {fareDetails?.rideFareStructure?.valuesFormula ==
                        "distanceFromPickToDropOff * farePerMile"
                          ? fareDetails?.rideFareStructure?.values
                              ?.distanceFromPickToDropOff +
                            "mi*" +
                            "$" +
                            fareDetails?.rideFareStructure?.values
                              ?.farePerMile +
                            "=" +
                            "$" +
                            fareDetails?.rideFare
                          : fareDetails?.baseFareStructure?.valuesFormula ==
                            "distanceFromPickToDropOff * longDistancePerMile"
                          ? fareDetails?.rideFareStructure?.values
                              ?.distanceFromPickToDropOff +
                            "mi*" +
                            "$" +
                            fareDetails?.rideFareStructure?.values
                              ?.longDistancePerMile +
                            "=" +
                            "$" +
                            fareDetails?.rideFare
                          : "$0"} */}
                        </label>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <input
                            type="text"
                            id="fare3"
                            className="form-control height-35 f-14 pricing-modal-input"
                            disabled
                            value={"$"}
                          />
                          <input
                            type="text"
                            className="form-control height-35 f-14 pricing-modal-input3"
                            id="fare4"
                            onKeyDown={(e) => {
                              if (e.ctrlKey && e.code === "KeyV") {
                                return;
                              }
                              if (alphabets.includes(e.key)) {
                                e.preventDefault();
                              }
                            }}
                            name="farePerMile"
                            value={hardCodedFares?.farePerMile}
                            onChange={handleHardCodedFares("farePerMile")}
                          />
                          <input
                            type="text"
                            id="fare5"
                            className="form-control height-35 f-14 pricing-modal-input2"
                            disabled
                            value={"X"}
                          />
                          <input
                            type="text"
                            className="form-control height-35 f-14 pricing-modal-input3"
                            id="fare6"
                            onKeyDown={(e) => {
                              if (e.ctrlKey && e.code === "KeyV") {
                                return;
                              }
                              if (alphabets.includes(e.key)) {
                                e.preventDefault();
                              }
                            }}
                            name="precalculatedMiles"
                            value={hardCodedFares?.distanceFromPickToDropOff}
                            onChange={handleHardCodedFares(
                              "distanceFromPickToDropOff"
                            )}
                            // onClick={handleUsePrecalculatedMilesTrue}
                          />
                          <input
                            type="text"
                            id="fare7"
                            className="form-control height-35 f-14 pricing-modal-input2"
                            disabled
                            value={"mi"}
                          />
                          <input
                            type="text"
                            id="fare8"
                            className="form-control height-35 f-14 pricing-modal-input2"
                            disabled
                            value={"="}
                          />
                          <input
                            type="text"
                            id="fare9"
                            className="form-control height-35 f-14 pricing-modal-input2"
                            disabled
                            value={"$"}
                          />
                          <input
                            type="text"
                            id="fare10"
                            className="form-control height-35 f-14 pricing-modal-input3"
                            disabled
                            value={fareDetails?.rideFare}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group ">
                    <div className="d-flex">
                      <div>
                        <label
                          className="f-14 text-dark mb-12"
                          data-label=""
                          htmlFor="clnumber"
                        >
                          <strong>Additional: </strong>
                        </label>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <input
                            type="text"
                            id="fare11"
                            className="form-control height-35 f-14 pricing-modal-input"
                            disabled
                            value={"$"}
                          />
                          <input
                            type="text"
                            id="fare12"
                            className="form-control height-35 f-14 pricing-modal-input3"
                            onKeyDown={(e) => {
                              if (e.ctrlKey && e.code === "KeyV") {
                                return;
                              }
                              if (alphabets.includes(e.key)) {
                                e.preventDefault();
                              }
                            }}
                            name="baseFarePerMile"
                            value={hardCodedFares?.baseFarePerMile}
                            onChange={handleHardCodedFares("baseFarePerMile")}
                          />
                          <input
                            type="text"
                            id="fare13"
                            className="form-control height-35 f-14 pricing-modal-input2"
                            disabled
                            value={"X"}
                          />
                          {/* <input
                            type="text"
                            id="Name"
                            className="form-control height-35 f-14 pricing-modal-input3"
                            onKeyDown={(e) => {
                              if (e.ctrlKey && e.code === "KeyV") {
                                return;
                              }
                              if (alphabets.includes(e.key)) {
                                e.preventDefault();
                              }
                            }}
                            name="distanceFromBaseLocation"
                            value={hardCodedFares?.distanceFromBaseLocation}
                            onChange={handleHardCodedFares(
                              "distanceFromBaseLocation"
                            )}
                            // onClick={handleUsePrecalculatedMilesTrue}
                          /> */}
                          <div className="input-wrapper">
                            <input
                              type="text"
                              id="fare14"
                              className="form-control height-35 f-14 pricing-modal-input4"
                              onKeyDown={(e) => {
                                if (e.ctrlKey && e.code === "KeyV") {
                                  return;
                                }
                                if (alphabets.includes(e.key)) {
                                  e.preventDefault();
                                }
                              }}
                              name="distanceFromBaseLocation"
                              value={hardCodedFares?.distanceFromBaseLocation}
                              onChange={handleHardCodedFares(
                                "distanceFromBaseLocation"
                              )}
                            />
                            <label htmlFor="fare14" className="label22">
                              Deadhead
                            </label>
                          </div>
                          {/* <div className="input-wrapper">
                            <input
                              type="text"
                              id="inputField"
                              className="form-control height-35 f-14 pricing-modal-input3"
                              required
                            />
                            <label htmlFor="inputField" className="label22">
                              Your Label
                            </label>
                          </div> */}
                          {/* <sup
                            className="f-13"
                            style={{
                              marginBottom: "3rem",
                              marginLeft: "-3.6rem",
                              color: "grey",
                            }}
                          >
                            deadhead
                          </sup> */}
                          <input
                            type="text"
                            id="fare15"
                            className="form-control height-35 f-14 pricing-modal-input2"
                            disabled
                            value={"mi"}
                          />
                          <input
                            type="text"
                            id="fare16"
                            className="form-control height-35 f-14 pricing-modal-input2"
                            disabled
                            value={"="}
                          />
                          <input
                            type="text"
                            id="fare17"
                            className="form-control height-35 f-14 pricing-modal-input2"
                            disabled
                            value={"$"}
                          />
                          <input
                            type="text"
                            id="fare18"
                            className="form-control height-35 f-14 pricing-modal-input3"
                            disabled
                            value={fareDetails?.deadheadFare}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group ">
                    <div className="d-flex">
                      <div>
                        <label
                          className="f-14 text-dark mb-12"
                          data-label=""
                          htmlFor="clnumber"
                        >
                          <strong>Driver Pay: </strong>
                        </label>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <input
                            type="text"
                            className="form-control height-35 f-14 pricing-modal-input3"
                            id="fare19"
                            onKeyDown={(e) => {
                              if (e.ctrlKey && e.code === "KeyV") {
                                return;
                              }
                              if (alphabets.includes(e.key)) {
                                e.preventDefault();
                              }
                            }}
                            name="baseFarePerMile"
                            value={hardCodedFares?.driverPercentage}
                            onChange={handleHardCodedFares("driverPercentage")}
                          />
                          <input
                            type="text"
                            id="fare20"
                            className="form-control height-35 f-14 pricing-modal-input2"
                            disabled
                            value={"%"}
                          />
                          <input
                            type="text"
                            id="fare21"
                            className="form-control height-35 f-14 pricing-modal-input2"
                            disabled
                            value={"="}
                          />
                          <input
                            type="text"
                            id="fare22"
                            className="form-control height-35 f-14 pricing-modal-input2"
                            disabled
                            value={"$"}
                          />
                          <input
                            type="text"
                            id="fare23"
                            className="form-control height-35 f-14 pricing-modal-input3"
                            disabled
                            value={fareDetails?.driverAmount}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className="col-md-5">
                  <div className="form-group ">
                    <div className="d-flex">
                      <div>
                        <label
                          className="f-14 text-dark mb-12"
                          data-label=""
                          htmlFor="clnumber"
                        >
                          <strong>Company Pay: </strong>
                        </label>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <input
                            type="text"
                            className="form-control height-35 f-14 pricing-modal-input2"
                            disabled
                            value={"$"}
                          />
                          <input
                            type="text"
                            className="form-control height-35 f-14"
                            disabled
                            value={fareDetails?.companyAmount}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}
                {/* <div className="col-md-5">
                  <div className="form-group ">
                    <div className="d-flex">
                      <div>
                        <label
                          className="f-14 text-dark mb-12"
                          data-label=""
                          htmlFor="clnumber"
                        >
                          <strong>Total: </strong>
                        </label>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <input
                            type="text"
                            className="form-control height-35 f-14 pricing-modal-input2"
                            disabled
                            value={"$"}
                          />
                          <input
                            type="text"
                            className="form-control height-35 f-14"
                            disabled
                            value={fareDetails?.amount}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}
                <div className="col-md-2">
                  <button
                    type="button"
                    id="button2"
                    className="btn-primary rounded f-14 p-2 my-3"
                    style={{ marginTop: "3rem" }}
                    onClick={() => handleVeryDirectFare()}
                  >
                    Recalculate
                  </button>
                </div>
              </div>
            )}
            {/* <div className="row" style={{ width: "95%", margin: "auto" }}>
              <div className="col-md-3">
                <div className="form-group my-3">
                  <div className="d-flex">
                    <div>
                      <label
                        className="f-14 text-dark mb-12"
                        data-label=""
                        htmlFor="clnumber"
                      >
                        Add Custom Pricing
                      </label>
                    </div>
                  </div>
                  <div className="btn-choice">
                    <label className="switch">
                      <input
                        type="checkbox"
                        className="switch-input"
                        id="toggle"
                        checked={useHardCodedFares}
                        onClick={() => toogleHardCoded()}
                      />
                      <span
                        className="switch-label"
                        data-on=""
                        data-off=""
                      ></span>
                      <span className="switch-handle"></span>
                    </label>
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group my-3">
                  <div className="d-flex">
                    <div>
                      <label
                        className="f-14 text-dark mb-12"
                        data-label=""
                        htmlFor="clnumber"
                      >
                        Flat Rate
                      </label>
                    </div>
                  </div>
                  <div className="btn-choice">
                    <label className="switch">
                      <input
                        type="checkbox"
                        className="switch-input"
                        id="toggle"
                        checked={useDirectFare}
                        onClick={() => toogleDirectFare()}
                      />
                      <span
                        className="switch-label"
                        data-on=""
                        data-off=""
                      ></span>
                      <span className="switch-handle"></span>
                    </label>
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group my-3">
                  <div className="d-flex">
                    <div>
                      <label
                        className="f-14 text-dark mb-12"
                        data-label=""
                        htmlFor="clnumber"
                      >
                        Driver Flat Rate
                      </label>
                    </div>
                  </div>
                  <div className="btn-choice">
                    <label className="switch">
                      <input
                        type="checkbox"
                        className="switch-input"
                        id="toggle"
                        checked={useFlatDriverRate}
                        onClick={() => toogleFlatDriverRate()}
                      />
                      <span
                        className="switch-label"
                        data-on=""
                        data-off=""
                      ></span>
                      <span className="switch-handle"></span>
                    </label>
                  </div>
                </div>
              </div>
            </div> */}
            {/* {useHardCodedFares === true && (
              <div className="row" style={{ width: "95%", margin: "auto" }}>
                <div className="col-md-3">
                  <div className="form-group my-3">
                    <label
                      className="f-14 text-dark mb-12"
                      data-label=""
                      htmlFor="Name"
                    >
                      {" "}
                      Load Fee
                    </label>
                    <input
                      type="text"
                      className="form-control height-35 f-14"
                      placeholder="Enter Load Fee"
                      id="Name"
                      onKeyDown={(e) => {
                        if (e.ctrlKey && e.code === "KeyV") {
                          return;
                        }
                        if (alphabets.includes(e.key)) {
                          e.preventDefault();
                        }
                      }}
                      name="flatBaseFare"
                      value={hardCodedFares?.flatBaseFare}
                      onChange={handleHardCodedFares("flatBaseFare")}
                    />
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="form-group my-3">
                    <label
                      className="f-14 text-dark mb-12"
                      data-label=""
                      htmlFor="Name"
                    >
                      {" "}
                      Standard Mileage Rate
                    </label>
                    <input
                      type="text"
                      className="form-control height-35 f-14"
                      placeholder="Enter Standard Mileage Rate"
                      id="Name"
                      onKeyDown={(e) => {
                        if (e.ctrlKey && e.code === "KeyV") {
                          return;
                        }
                        if (alphabets.includes(e.key)) {
                          e.preventDefault();
                        }
                      }}
                      name="farePerMile"
                      value={hardCodedFares?.farePerMile}
                      onChange={handleHardCodedFares("farePerMile")}
                    />
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="form-group my-3">
                    <label
                      className="f-14 text-dark mb-12"
                      data-label=""
                      htmlFor="Name"
                    >
                      {" "}
                      Dead Head Threshold
                    </label>
                    <input
                      type="text"
                      className="form-control height-35 f-14"
                      placeholder="Enter Dead Head Threshold"
                      id="Name"
                      onKeyDown={(e) => {
                        if (e.ctrlKey && e.code === "KeyV") {
                          return;
                        }
                        if (alphabets.includes(e.key)) {
                          e.preventDefault();
                        }
                      }}
                      name="deadHeadThreshold"
                      value={hardCodedFares?.deadHeadThreshold}
                      onChange={handleHardCodedFares("deadHeadThreshold")}
                    />
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="form-group my-3">
                    <label
                      className="f-14 text-dark mb-12"
                      data-label=""
                      htmlFor="Name"
                    >
                      {" "}
                      Deadhead Mileage Rate
                    </label>
                    <input
                      type="text"
                      className="form-control height-35 f-14"
                      placeholder="Enter Deadhead Mileage Rate"
                      id="Name"
                      onKeyDown={(e) => {
                        if (e.ctrlKey && e.code === "KeyV") {
                          return;
                        }
                        if (alphabets.includes(e.key)) {
                          e.preventDefault();
                        }
                      }}
                      name="baseFarePerMile"
                      value={hardCodedFares?.baseFarePerMile}
                      onChange={handleHardCodedFares("baseFarePerMile")}
                    />
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="form-group my-3">
                    <label
                      className="f-14 text-dark mb-12"
                      data-label=""
                      htmlFor="Name"
                    >
                      {" "}
                      Long Distance Threshold
                    </label>
                    <input
                      type="text"
                      className="form-control height-35 f-14"
                      placeholder="Enter Long Distance Threshold"
                      id="Name"
                      onKeyDown={(e) => {
                        if (e.ctrlKey && e.code === "KeyV") {
                          return;
                        }
                        if (alphabets.includes(e.key)) {
                          e.preventDefault();
                        }
                      }}
                      name="totalMileageAllows"
                      value={hardCodedFares?.totalMileageAllows}
                      onChange={handleHardCodedFares("totalMileageAllows")}
                    />
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="form-group my-3">
                    <label
                      className="f-14 text-dark mb-12"
                      data-label=""
                      htmlFor="Name"
                    >
                      {" "}
                      Long Distance Per Mile
                    </label>
                    <input
                      type="text"
                      className="form-control height-35 f-14"
                      placeholder="Enter Long Distance Per Mile"
                      id="Name"
                      onKeyDown={(e) => {
                        if (e.ctrlKey && e.code === "KeyV") {
                          return;
                        }
                        if (alphabets.includes(e.key)) {
                          e.preventDefault();
                        }
                      }}
                      name="longDistancePerMile"
                      value={hardCodedFares?.longDistancePerMile}
                      onChange={handleHardCodedFares("longDistancePerMile")}
                    />
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="form-group my-3">
                    <label
                      className="f-14 text-dark mb-12"
                      data-label=""
                      htmlFor="Name"
                    >
                      {" "}
                      Off-hours Fee
                    </label>
                    <input
                      type="text"
                      className="form-control height-35 f-14"
                      placeholder="Enter Off-hours Fee"
                      id="Name"
                      onKeyDown={(e) => {
                        if (e.ctrlKey && e.code === "KeyV") {
                          return;
                        }
                        if (alphabets.includes(e.key)) {
                          e.preventDefault();
                        }
                      }}
                      name="offHourFee"
                      value={hardCodedFares?.offHourFee}
                      onChange={handleHardCodedFares("offHourFee")}
                    />
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="form-group my-3">
                    <label
                      className="f-14 text-dark mb-12"
                      data-label=""
                      htmlFor="Name"
                    >
                      {" "}
                      No-show Fee
                    </label>
                    <input
                      type="text"
                      className="form-control height-35 f-14"
                      placeholder="Enter No-show Fee"
                      id="Name"
                      onKeyDown={(e) => {
                        if (e.ctrlKey && e.code === "KeyV") {
                          return;
                        }
                        if (alphabets.includes(e.key)) {
                          e.preventDefault();
                        }
                      }}
                      name="noShowFee"
                      value={hardCodedFares?.noShowFee}
                      onChange={handleHardCodedFares("noShowFee")}
                    />
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="form-group my-3">
                    <label
                      className="f-14 text-dark mb-12"
                      data-label=""
                      htmlFor="Name"
                    >
                      {" "}
                      Cancelation Fee
                    </label>
                    <input
                      type="text"
                      className="form-control height-35 f-14"
                      placeholder="Enter Cancelation Fee"
                      id="Name"
                      onKeyDown={(e) => {
                        if (e.ctrlKey && e.code === "KeyV") {
                          return;
                        }
                        if (alphabets.includes(e.key)) {
                          e.preventDefault();
                        }
                      }}
                      name="cancelationFee"
                      value={hardCodedFares?.cancelationFee}
                      onChange={handleHardCodedFares("cancelationFee")}
                    />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group my-3">
                    <label
                      className="f-14 text-dark mb-12"
                      data-label=""
                      htmlFor="Name"
                    >
                      {" "}
                      Additional Charges
                    </label>
                    <input
                      type="text"
                      className="form-control height-35 f-14"
                      placeholder="Enter Cancelation Fee"
                      id="Name"
                      onKeyDown={(e) => {
                        if (e.ctrlKey && e.code === "KeyV") {
                          return;
                        }
                        if (alphabets.includes(e.key)) {
                          e.preventDefault();
                        }
                      }}
                      name="additionalCharges"
                      value={hardCodedFares?.additionalCharges}
                      onChange={handleHardCodedFares("additionalCharges")}
                    />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group my-3">
                    <label
                      className="f-14 text-dark mb-12"
                      data-label=""
                      htmlFor="Name"
                    >
                      {" "}
                      Deduction
                    </label>
                    <input
                      type="text"
                      className="form-control height-35 f-14"
                      placeholder="Enter Cancelation Fee"
                      id="Name"
                      onKeyDown={(e) => {
                        if (e.ctrlKey && e.code === "KeyV") {
                          return;
                        }
                        if (alphabets.includes(e.key)) {
                          e.preventDefault();
                        }
                      }}
                      name="deduction"
                      value={hardCodedFares?.deduction}
                      onChange={handleHardCodedFares("deduction")}
                    />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group my-3">
                    <label
                      className="f-14 text-dark mb-12"
                      data-label=""
                      htmlFor="Name"
                    >
                      &nbsp;
                    </label>
                    <br />
                    <button
                      type="button"
                      className="btn-primary rounded f-14 p-2 mr-3"
                      onClick={() => recalculate()}
                    >
                      Recalculate
                    </button>
                  </div>
                </div>
              </div>
            )} */}

            {useDirectFare === true && (
              <div className="row" style={{ width: "95%", margin: "auto" }}>
                <div className="col-md-3">
                  <div className="form-group my-3">
                    <label
                      className="f-14 text-dark mb-12"
                      data-label=""
                      htmlFor="Name"
                    >
                      {" "}
                      Flat Rate Amount
                    </label>
                    {/* <sup className="text-danger f-12">*</sup> */}
                    <input
                      type="text"
                      className="form-control height-35 f-14"
                      placeholder="Enter Flat Rate"
                      id="fare24"
                      onKeyDown={(e) => {
                        if (e.ctrlKey && e.code === "KeyV") {
                          return;
                        }
                        if (alphabets.includes(e.key)) {
                          e.preventDefault();
                        }
                      }}
                      name="directFare"
                      value={directFare}
                      onChange={handleDirectFare}
                    />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group my-3">
                    <label
                      className="f-14 text-dark mb-12"
                      data-label=""
                      htmlFor="Name"
                    >
                      &nbsp;
                    </label>
                    <br />
                    <button
                      type="button"
                      id="button3"
                      className="btn-primary rounded f-14 p-2 mr-3"
                      onClick={() => fareDirectlyCalculate()}
                    >
                      Recalculate
                    </button>
                  </div>
                </div>
              </div>
            )}
            {useFlatDriverRate === true && (
              <div className="row" style={{ width: "95%", margin: "auto" }}>
                <div className="col-md-3">
                  <div className="form-group my-3">
                    <label
                      className="f-14 text-dark mb-12"
                      data-label=""
                      htmlFor="Name"
                    >
                      {" "}
                      Driver Flat Rate Amount
                    </label>
                    {/* <sup className="text-danger f-12">*</sup> */}
                    <input
                      type="text"
                      className="form-control height-35 f-14"
                      placeholder="Enter Flat Rate"
                      id="fare25"
                      onKeyDown={(e) => {
                        if (e.ctrlKey && e.code === "KeyV") {
                          return;
                        }
                        if (alphabets.includes(e.key)) {
                          e.preventDefault();
                        }
                      }}
                      name="directFare"
                      value={flatDriverRate}
                      onChange={handleFlatDriverRate}
                    />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group my-3">
                    <label
                      className="f-14 text-dark mb-12"
                      data-label=""
                      htmlFor="Name"
                    >
                      &nbsp;
                    </label>
                    <br />
                    <button
                      type="button"
                      id="button4"
                      className="btn-primary rounded f-14 p-2 mr-3"
                      onClick={() => flatDriverRateRecalculate()}
                    >
                      Recalculate
                    </button>
                  </div>
                </div>
              </div>
            )}

            <div className="modal-footer">
              {!handleUpdateValue && (
                <button
                  type="button"
                  id="button5"
                  className="btn-primary rounded f-14 p-2 mr-3"
                  onClick={() => openDetailDropDown()}
                >
                  OK
                </button>
              )}
              {handleUpdateValue && (
                <button
                  type="button"
                  id="button6"
                  className="btn-primary rounded f-14 p-2 mr-3"
                  onClick={() => handleUpdateValue()}
                >
                  OK
                </button>
              )}{" "}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default React.memo(AdminBookingPriceDetailModal);
