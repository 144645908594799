import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { navigateUserFunc } from "../../actions/generalActions";
import AdminHeader from "../../components/adminComponents/adminHeaderSidebarTitleComponents/AdminHeader";
import AdminSidebar from "../../components/adminComponents/adminHeaderSidebarTitleComponents/AdminSidebar";
import AdminBookingViewComponent from "../../components/adminComponents/adminDispatchingComponents/AdminBookingViewComponent";
import NotAllowed from "../../components/NotAllowed";

const AdminBookingView = () => {
  let navigate = useNavigate();
  let { state } = useLocation();
  const reservation = useSelector((state) => state.reservation);
  const auth = useSelector((state) => state.auth);
  useEffect(() => {
    if ((auth?.user?.permissionId?.booking?.isAllow === false) || (auth?.user?.disable === true)){
      navigateUserFunc(auth?.user, navigate)
    }
  },[])
  return (
    <>
      <AdminHeader />
      <AdminSidebar />
      <div className="body-wrapper clearfix">
        <section className="main-container " id="fullscreen">
          <div className="px-4 py-2 border-top-0 emp-dashboard">
            <div className="d-block d-lg-flex d-md-flex justify-content-between action-bar mb-20 mt-3">
              <div
                id="table-actions"
                className="flex-grow-1 align-items-center"
              >
                <h4 className=" mb-0 f-21 text-capitalize font-weight-bold">
                  {" "}
                  Booking Details - Order No. {state?.orderId ? state?.orderId : reservation?.reservationDetails?.orderId }
                </h4>
              </div>
              <div
                className="btn-group mt-2 mt-lg-0 mt-md-0 ml-0 ml-lg-3 ml-md-3"
                role="group"
              ></div>
            </div>
            {
              auth?.user?.permissionId?.booking?.read === true ?
              <AdminBookingViewComponent data={state}/>
              :
              <NotAllowed />
            }
          </div>
        </section>
      </div>
    </>
  );
};

export default AdminBookingView;
