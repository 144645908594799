/** @format */

import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import { getTodayReservationsByUserId } from "../../../actions/reservationAction";
import DriverDashboardTableRow from "./DriverDashboardTableRow";
import { Link, NavLink } from "react-router-dom";
import moment from "moment";
import shift1 from "../../../assets/images/load.png";
const DriverDashboardTable = () => {
  const reservation = useSelector((state) => state.reservation);
  const auth = useSelector((state) => state.auth);
  const [showMoreVertDropDown, setshowMoreVertDropDown] = useState(false);
  const openMoreVertDropDown = () => {
    setshowMoreVertDropDown((showMoreVertDropDown) => !showMoreVertDropDown);
  };
  const dispatch = useDispatch();
  const todayReservationViewData = async (e) => {
    dispatch(
      getTodayReservationsByUserId({
        driverId: auth.user.id,
        today: moment().format("YYYY-MM-DD"),
        limit: limit,
        page: currentPageNumber,
      })
    );
  };

  // console.log(reservation, "djdjdjjdjjdjdjdjdjdjdjdjdjdjdjdjdjdjd")

  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const [limit, setLimit] = useState(10);
  const handleLimit = async (event) => {
    setLimit(parseInt(event.target.value));
    dispatch(
      getTodayReservationsByUserId({
        driverId: auth.user.id,
        today: moment().format("YYYY-MM-DD"),
        // today: "2023-04-18",
        limit: event.target.value,
        page: 1,
      })
    );
    setCurrentPageNumber(1);
  };

  const onClickNext = async () => {
    let i = currentPageNumber;
    i++;
    if (i <= reservation.todayReservationView.totalPages) {
      setCurrentPageNumber(currentPageNumber + 1);
      dispatch(
        getTodayReservationsByUserId({
          driverId: auth.user.id,
          today: moment().format("YYYY-MM-DD"),
          //   today: "2023-04-18",
          limit: limit,
          page: currentPageNumber + 1,
        })
      );
    }
  };

  const onClickPrev = async () => {
    let i = currentPageNumber;
    i--;
    if (i >= 1) {
      setCurrentPageNumber(currentPageNumber - 1);
      dispatch(
        getTodayReservationsByUserId({
          driverId: auth.user.id,
          today: moment().format("YYYY-MM-DD"),
          //   today: "2023-04-18",
          limit: limit,
          page: currentPageNumber - 1,
        })
      );
    }
  };

  const handleChangeData = async (pageNo) => {
    setCurrentPageNumber(pageNo);
    dispatch(
      getTodayReservationsByUserId({
        driverId: auth.user.id,
        today: moment().format("YYYY-MM-DD"),
        // today: "2023-04-18",
        limit: limit,
        page: pageNo,
      })
    );
  };

  useEffect(() => {
    todayReservationViewData();
  }, []);

  const totalPages = reservation?.todayReservationView?.paginationArr?.length;
  const maxPagesToShow = 5;
  let startPage = 1;
  let endPage = totalPages;

  if (totalPages > maxPagesToShow) {
    const middle = Math.floor(maxPagesToShow / 2);
    if (currentPageNumber <= middle) {
      startPage = 1;
    } else if (currentPageNumber >= totalPages - middle) {
      startPage = totalPages - maxPagesToShow + 1;
    } else {
      startPage = currentPageNumber - middle;
    }
    endPage = Math.min(startPage + maxPagesToShow - 1, totalPages);
  }

  return (
    <>
      <div className="d-flex flex-column w-tables rounded mt-3 bg-white">
        <div className="d-flex border">
          <div className="flex-grow-1">
            <div className="dataTables_length d-flex" id="leads-table_length">
              <div className="p-2">
                <label>Show entries</label>
              </div>
              <label>
                <select
                  name="leads-table_length"
                  aria-controls="leads-table"
                  className="custom-select custom-select-sm form-control form-control-sm"
                  // className="select"
                  autoComplete="false"
                  onChange={handleLimit}
                >
                  {[10, 25, 50, 100].map((current, i) => {
                    return (
                      <option value={current} key={i}>
                        {current}
                      </option>
                    );
                  })}
                </select>
              </label>
            </div>
          </div>
        </div>
        <div
          id="leads-table_wrapper"
          className="dataTables_wrapper dt-bootstrap4 no-footer"
        >
          <div className="row">
            <div className="col-sm-12">
              <table
                className="table border w-100 dataTable no-footer"
                id="leads-table"
                role="grid"
                aria-describedby="leads-table_info"
                style={{ overflowX: "auto" }}
              >
                <thead className="card-header">
                  <tr role="row">
                    <th
                      title="Order ID"
                      className="sorting border-right"
                      tabIndex="0"
                      aria-controls="leads-table"
                      rowSpan="1"
                      colSpan="1"
                      aria-label="Mobile: activate to sort column ascending"
                    >
                      Order ID
                    </th>
                    <th
                      title="Passenger Name"
                      className="sorting_disabled border-right"
                      rowSpan="1"
                      colSpan="1"
                      aria-label="Next Follow Up"
                    >
                      Passenger Name
                    </th>
                    <th
                      title="Phone"
                      className="sorting border-right"
                      tabIndex="0"
                      aria-controls="leads-table"
                      rowSpan="1"
                      colSpan="1"
                      aria-label="Lead Agent: activate to sort column ascending"
                    >
                      Phone
                    </th>
                    <th
                      title="Vehicle#"
                      className="sorting border-right"
                      tabIndex="0"
                      aria-controls="leads-table"
                      rowSpan="1"
                      colSpan="1"
                      aria-label="Lead Agent: activate to sort column ascending"
                    >
                      Vehicle#
                    </th>
                    <th
                      title="Pickup - Drop Off"
                      className="sorting border-right w-30"
                      tabIndex="0"
                      aria-controls="leads-table"
                      rowSpan="1"
                      colSpan="1"
                      aria-label="Lead Agent: activate to sort column ascending"
                    >
                      Pickup - Drop Off
                    </th>
                    <th
                      title="Date and time"
                      className="sorting border-right"
                      tabIndex="0"
                      aria-controls="leads-table"
                      rowSpan="1"
                      colSpan="1"
                      aria-label="Lead Agent: activate to sort column ascending"
                    >
                      Date and time
                    </th>
                    <th
                      title="Navigate"
                      className="sorting border-right"
                      tabIndex="0"
                      aria-controls="leads-table"
                      rowSpan="1"
                      colSpan="1"
                      aria-label="Lead Agent: activate to sort column ascending"
                    >
                      Navigate
                    </th>
                    {/* <th title="Status" className="sorting border-right" tabIndex="0" aria-controls="leads-table" rowSpan="1" colSpan="1" aria-label="Lead Agent: activate to sort column ascending">
                                            Status
                                        </th> */}
                    <th
                      title="Actions"
                      className="text-right pr-20 sorting_disabled border-right"
                      rowSpan="1"
                      colSpan="1"
                      aria-label="Action"
                    >
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {reservation &&
                    reservation.todayReservationView &&
                    reservation.todayReservationView.results &&
                    reservation.todayReservationView.results.length > 0 &&
                    reservation.todayReservationView.results.map(
                      (current, i) => {
                        // if (current.delete === false) {
                        return (
                          <DriverDashboardTableRow
                            current={current}
                            i={i}
                            key={i}
                          />
                        );
                        // }
                      }
                    )}
                </tbody>
              </table>
              {reservation &&
                reservation.todayReservationView &&
                reservation.todayReservationView.results &&
                reservation.todayReservationView.results.length === 0 && (
                  <div
                    className="dash-stats-list"
                    style={{ textAlign: "center" }}
                  >
                    <br />
                    <br />
                    <img
                      alt=""
                      src={shift1}
                      style={{ width: "145px", opacity: "0.5" }}
                    />
                    <br />
                    <p style={{ opacity: "0.5" }}>No Reservations Found</p>
                  </div>
                )}
              <div
                id="leads-table_processing"
                className="dataTables_processing card"
                style={{ display: "none" }}
              >
                Processing...
              </div>
            </div>
          </div>
          {reservation &&
            reservation.todayReservationView &&
            reservation.todayReservationView.results &&
            reservation.todayReservationView.results.length > 0 && (
              <div className="d-flex border">
                {reservation.loading === false ? (
                  <div className="flex-grow-1">
                    <div
                      className="dataTables_info"
                      id="leads-table_info"
                      role="status"
                      aria-live="polite"
                    >
                      Showing {1 + limit * (currentPageNumber - 1)} to{" "}
                      {reservation?.todayReservationView?.displayingResults +
                        limit * (currentPageNumber - 1)}{" "}
                      of {reservation?.todayReservationView?.totalResults}{" "}
                      entries
                    </div>
                  </div>
                ) : (
                  <div className="flex-grow-1">
                    <div
                      className="dataTables_info"
                      id="leads-table_info"
                      role="status"
                      aria-live="polite"
                    >
                      Loading entries
                    </div>
                  </div>
                )}
                <div>
                  <div
                    className="dataTables_paginate paging_simple_numbers"
                    id="leads-table_paginate"
                  >
                    <ul className="pagination">
                      <li className="page-item">
                        <NavLink
                          className="page-link"
                          to="#"
                          aria-label="Previous"
                          onClick={onClickPrev}
                        >
                          <span aria-hidden="true">Previous</span>
                          <span className="sr-only">Previous</span>
                        </NavLink>
                      </li>
                      {reservation.todayReservationView &&
                        reservation.todayReservationView.paginationArr &&
                        reservation.todayReservationView.paginationArr.length >
                          0 &&
                        // reservation.todayReservationView.paginationArr.map((current, i) => {
                        Array.from(
                          { length: endPage - startPage + 1 },
                          (_, i) => {
                            const current = startPage + i;
                            return currentPageNumber === current ? (
                              <li className="page-item active" key={i}>
                                <Link
                                  to=""
                                  className="page-link"
                                  value={current}
                                >
                                  {current}
                                </Link>
                              </li>
                            ) : (
                              <li className="page-item" key={i}>
                                <Link
                                  className="page-link"
                                  value={current}
                                  onClick={() => handleChangeData(current)}
                                  to=""
                                >
                                  {current}
                                </Link>
                              </li>
                            );
                          }
                        )}
                      <li className="page-item">
                        <NavLink
                          className="page-link"
                          to="#"
                          aria-label="Next"
                          onClick={onClickNext}
                        >
                          <span aria-hidden="true">Next</span>
                          <span className="sr-only">Next</span>
                        </NavLink>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            )}
        </div>
      </div>
    </>
  );
};
export default DriverDashboardTable;
