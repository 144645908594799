/** @format */

import React, { useEffect, useState } from "react";
import { InfoWindowF, Marker } from "@react-google-maps/api";
import ProgressBar from "react-bootstrap/ProgressBar";
import img1 from "../assets/images/Frame4.png";

const Demo5 = ({ coordinates, icon, stop }) => {
  const [currentVal, setCurrentVal] = useState({
    lat: coordinates?.lat ? coordinates?.lat : 0,
    lng: coordinates?.lng ? coordinates?.lng : 0,
  });
  const [newVal, setNewVal] = useState({
    lat: "",
    lng: "",
  });
  useEffect(() => {
    if (currentVal.lat === "" && currentVal.lng === "") {
      setCurrentVal({
        lat: coordinates.lat,
        lng: coordinates.lng,
      });
    }
    if (currentVal.lat !== "" && currentVal.lng !== "") {
      if (
        currentVal.lat !== coordinates.lat &&
        currentVal.lng !== coordinates.lng
      ) {
        setNewVal({
          lat: coordinates.lat,
          lng: coordinates.lng,
        });
      }
    }
  }, [coordinates]);
  // var numDeltas = 100;
  // var delay = 10; //milliseconds
  // var i = 0;
  // var deltaLat;
  // var deltaLng;
  // const transition = () => {
  //     if ((newVal.lat !== '') && (newVal.lng !== '')){
  //         i = 0;
  //         deltaLat = parseFloat((newVal.lat-currentVal.lat)/numDeltas);
  //         deltaLng = parseFloat((newVal.lng-currentVal.lng)/numDeltas);
  //         moveMarker();
  //     }
  // }
  // const moveMarker = () => {
  //     // position[0] += deltaLat;
  //     // position[1] += deltaLng;
  //     setCurrentVal(
  //         {
  //             lat: parseFloat(currentVal.lat + deltaLat),
  //             lng: parseFloat(currentVal.lng + deltaLng)
  //         }
  //     )
  //     if(i!=numDeltas){
  //         i++;
  //         setTimeout(moveMarker, delay);
  //     }
  // }
  // useEffect(()=> {
  //     transition()
  // }, [coordinates])
  const [deltaCoordinates, setDeltaCoordinates] = useState({
    lat: 0,
    lng: 0,
  });

  useEffect(() => {
    // setTimeout(() => {
    //     if (currentVal.lat !== newVal.lat){
    //     setInterval(() => {
    //     let cLat = (newVal.lat-currentVal.lat)/100
    //     let cLng = (newVal.lng-currentVal.lng)/100
    //     console.log(newVal.lat,currentVal.lat, "newVal.lat,currentVal.lat")
    //     setCurrentVal(
    //         {
    //         lat: currentVal.lat += cLat,
    //         lng: currentVal.lng += cLng
    //         }
    //     )
    //     }
    //     , 10)
    //     }
    // }
    // , 5000)
    if (currentVal.lat !== coordinates.lat) {
      if (newVal.lat !== "" && newVal.lng !== "") {
        setTimeout(() => {
          setDeltaCoordinates({
            lat: parseFloat((newVal.lat - currentVal.lat) / 10), // phele 100 likha tha for smoothing
            lng: parseFloat((newVal.lng - currentVal.lng) / 10), // phele 100 likha tha for smoothing
          });
          // let cLat = parseFloat((newVal.lat-currentVal.lat)/100)
          // let cLng = parseFloat((newVal.lng-currentVal.lng)/100)
          // console.log(cLat, cLng, "=======")
          // setCurrentVal((x)=> ({
          //     lat: x.lat += cLat,
          //     lng: x.lng += cLng
          // })
          // )
        }, 10);
      }
    }
  }, [coordinates]);
  useEffect(() => {
    // if (stop?.driverId?.id == "64f26dded291b894f64c2749") {
    //   console.log(
    //     Math.abs(deltaCoordinates.lat) >= 0.1,
    //     Math.abs(newVal.lat - currentVal.lat) >= 0.1,
    //     Math.abs(deltaCoordinates.lat),
    //     Math.abs(newVal.lat - currentVal.lat),
    //     newVal.lat,
    //     currentVal.lat,
    //     Math.abs(deltaCoordinates.lng) >= 0.1,
    //     Math.abs(newVal.lng - currentVal.lng) >= 0.1,
    //     Math.abs(deltaCoordinates.lng),
    //     Math.abs(newVal.lng - currentVal.lng),
    //     newVal.lng,
    //     currentVal.lng,
    //     "hhhhhhhhhhhhhhhhhhhhhhh"
    //   );
    // }
    setCurrentVal((x) => ({
      lat:
        Math.abs(newVal.lat - currentVal.lat) >= 0.1
          ? newVal.lat
          : (x.lat += deltaCoordinates.lat),
      lng:
        Math.abs(newVal.lng - currentVal.lng) >= 0.1
          ? newVal.lng
          : (x.lng += deltaCoordinates.lng),
    }));
  }, [deltaCoordinates]);
  // console.log(deltaCoordinates,currentVal, newVal, "=======")
  const [openPopup, setOpenPopup] = useState(false);
  // console.log(stop, "stop")
  return (
    <Marker
      label={{
        text:
          // " (" +
          // stop?.driverId?.vehicleId?.assetNo +
          // ") " +
          stop?.driverId?.firstName + " " + stop?.driverId?.lastName,
        color: "#fff",
        className: "labels2",
      }}
      icon={icon}
      position={currentVal}
      onMouseOver={() => setOpenPopup(true)}
      onMouseOut={() => setOpenPopup(false)}
      onClick={() => setOpenPopup(true)}
    >
      {openPopup && stop?.tripId && (
        <InfoWindowF
        // position={{
        //   lat: location.coordinates[0],
        //   lng: location.coordinates[1],
        // }}
        // onCloseClick={() => setOpenPopup(false)}
        >
          <div>
            <p style={{ top: "15px", position: "absolute" }}>
              <span style={{ color: "lightskyblue", fontWeight: "bold" }}>
                {stop?.driverId?.vehicleId?.assetNo +
                  " - " +
                  stop?.driverId?.firstName +
                  " " +
                  stop?.driverId?.lastName}
              </span>
            </p>
            <p style={{ top: "35px", position: "absolute" }}>
              <span style={{ fontWeight: "bold" }}>Order #</span>{" "}
              {stop?.tripId?.reservationId?.orderId}
            </p>
            <p style={{ top: "55px", position: "absolute" }}>
              <span style={{ fontWeight: "bold" }}>Customer: </span>
              {stop?.tripId?.reservationId?.customerId?.firstName +
                " " +
                stop?.tripId?.reservationId?.customerId?.lastName}
            </p>
            <p style={{ padding: "25px 0 0 0" }}>
              <span style={{ fontWeight: "bold" }}>Coordinates: </span>
              {currentVal.lat + "," + currentVal.lng}
            </p>
          </div>
        </InfoWindowF>
        // <InfoWindowF
        // // position={{
        // //   lat: location.coordinates[0],
        // //   lng: location.coordinates[1],
        // // }}
        // // onCloseClick={() => setOpenPopup(false)}
        // >
        //   <div>
        //     <h3>Order # {stop?.tripId?.reservationId?.orderId}</h3>
        //     <h5>
        //       Customer:{" "}
        //       {stop?.tripId?.reservationId?.customerId?.firstName +
        //         " " +
        //         stop?.tripId?.reservationId?.customerId?.lastName}
        //     </h5>
        //     <p>
        //       Status: In Progress <br />
        //     </p>
        //   </div>
        // </InfoWindowF>
      )}
      {openPopup && stop?.routeTripId && (
        <InfoWindowF>
          <div>
            <p style={{ top: "15px", position: "absolute" }}>
              <span style={{ color: "lightskyblue", fontWeight: "bold" }}>
                {stop?.driverId?.vehicleId?.assetNo +
                  " - " +
                  stop?.driverId?.firstName +
                  " " +
                  stop?.driverId?.lastName}
              </span>
            </p>
            <p style={{ top: "35px", position: "absolute" }}>
              <span style={{ fontWeight: "bold" }}>Route #</span>{" "}
              {stop?.routeTripId?.routeId?.routeNumber}
            </p>
            <p style={{ top: "55px", position: "absolute" }}>
              <span style={{ fontWeight: "bold" }}>Shift: </span>
              {stop?.routeTripId?.routeId?.routeNumberReturn ? "PM" : "AM"}
            </p>
            <p style={{ padding: "25px 0 0 0" }}>
              <span style={{ fontWeight: "bold" }}>Coordinates: </span>
              {currentVal.lat + "," + currentVal.lng}
            </p>
          </div>
        </InfoWindowF>
        // <InfoWindowF>
        //   <div>
        //     <h3>
        //       Route # {stop?.routeTripId?.routeId?.routeNumber}
        //       {/* {stop?.routeTripId?.routeId?.routeNumberReturn && "( Return )"} */}
        //     </h3>
        //     <h5>
        //       Shift:{" "}
        //       {stop?.routeTripId?.routeId?.routeNumberReturn ? "PM" : "AM"}
        //     </h5>
        //     {/* <h5>{stop?.routeTripId?.routeId?.routeNumberReturn ? "PM" : "AM"}</h5> */}
        //     <p>
        //       Status: In Progress <br />
        //       {/* Speed:{" "}
        //       {stop?.speed ? stop?.speed : 0 + "mph"} */}
        //     </p>
        //     {/* <img
        //       data-toggle="tooltip"
        //       src={img1}
        //       style={{ maxHeight: "27px" }}
        //     />
        //     <i class="fa fa-flag-checkered"></i>
        //     <ProgressBar
        //       now={50}
        //       min={0}
        //       max={100}
        //       variant="success"
        //       animated={true}
        //       // striped={true}
        //     /> */}
        //   </div>
        // </InfoWindowF>
      )}
      {openPopup && !stop?.tripId && !stop?.routeTripId && (
        <InfoWindowF>
          <div>
            <p style={{ top: "15px", position: "absolute", width: "auto" }}>
              <span style={{ color: "lightskyblue", fontWeight: "bold" }}>
                {stop?.driverId?.vehicleId?.assetNo +
                  " - " +
                  stop?.driverId?.firstName +
                  " " +
                  stop?.driverId?.lastName}
              </span>
            </p>
            <p>
              <span style={{ fontWeight: "bold" }}>Coordinates: </span>
              {currentVal.lat + "," + currentVal.lng}
            </p>
          </div>
        </InfoWindowF>
        // <InfoWindowF>
        //   <div>
        //     <h6>No ride in Progress</h6>
        //   </div>
        // </InfoWindowF>
      )}
    </Marker>
  );
};

export default Demo5;
