import React from "react";
import ChatComponent from "../../../components/chatComponent";
import AdminHeader from "../../../components/adminComponents/adminHeaderSidebarTitleComponents/AdminHeader";
import AdminSidebar from "../../../components/adminComponents/adminHeaderSidebarTitleComponents/AdminSidebar";
import { NavLink } from "react-router-dom";

function AdminChatPage() {
  return (
    <>
      <AdminHeader />
      <AdminSidebar /> 
        <div className="body-wrapper clearfix">
          <section className="main-container " id="fullscreen">
            <div className="py-2 border-top-0 emp-dashboard">
              <div className="d-lg-flex d-md-flex d-block ">
                {/* <div>
                  <h4 className=" mb-0 f-21 text-capitalize font-weight-bold">Track Drivers</h4>
                </div> */}
              </div>
              <ChatComponent />
            </div>
          </section>
        </div>
        {/* <div className="main-wrapper">
          <div className="page-wrapper chat-page " style={{ height: "84vh" }}>
            <ChatComponent />
          </div>
        </div> */}
    </>
  );
}

export default AdminChatPage;
