/** @format */

import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import {
  getAllReservationV2,
  getPastReservationsByCompanyId,
  getPastReservationsByCompanyIdV3,
} from "../../../actions/reservationAction";
import moment from "moment";
import AdminBookingPastBookingRow from "./AdminBookingPastBookingRow";
import { Link, NavLink, useLocation } from "react-router-dom";
import NotAllowed from "../../NotAllowed";
import shift1 from "../../../assets/images/load.png";
import {
  formArray,
  paginationDataToShow,
} from "../../../actions/generalActions";
import LoadingComponent from "../../LoadingComponent";
import LoadingComponentRideModal from "../../LoadingComponentRideModal";
// import { paginationDataToShow } from "../../PaginationCustom";

const AdminBookingPastBooking = ({ setExportData }) => {
  const auth = useSelector((state) => state.auth);
  const reservation = useSelector((state) => state.reservation);
  const { loading } = reservation;
  const [showMoreVertDropDown, setshowMoreVertDropDown] = useState(false);
  const openMoreVertDropDown = () => {
    setshowMoreVertDropDown((showMoreVertDropDown) => !showMoreVertDropDown);
  };
  const dispatch = useDispatch();

  const paginationDataToShow2 = (
    limit,
    pageNumber,
    data,
    sortField,
    sortOrder = "asc",
    searchData = null,
    totalNumber
  ) => {
    const entriesToShow = limit;

    // console.log(
    //   limit,
    //   pageNumber,
    //   data,
    //   sortField,
    //   sortOrder,
    //   searchData,
    //   searchData.length,
    //   "sortField"
    // );
    // Search
    let searchedData = [];
    searchedData = data;
    // if (searchData !== null && searchData !== '') {
    //     const searchValue = searchData.toLowerCase();
    //     searchedData = searchedData.filter(item =>
    //         Object.values(item).some(value => value.toString().toLowerCase().includes(searchValue))
    //     );
    // }

    if (searchData !== null && searchData !== "") {
      const searchValue = searchData?.toString().toLowerCase().trim();
      let searchFields = [
        //booking
        "orderId",
        "loadNumber",
        "specialNumber",
        "driverId",
        "assetId",
        "pickUpTime",
        "dropOffTime",
        "customerId",
        "pickUpAddress",
        "pickUpDate",
        "dropOffAddress",
        "type",
        "serviceName",
        "status",
        //passenger
        "uniqueId",
        "firstName",
        "lastName",
        "email",
        "phone",
        "notes",
        //petracking tracking table
        //route table peh kaam
        //vehicle
        "assetNo",
        "vehicleOwnership",
        "serviceType",
        "make",
        "model",
        "year",
        "vin",
        "numberPlate",
        "mileage",
        //invoicing peh kaam
        "reservationId",
        //driver pay peh kaam
        "reservationId",
        // user
        "permissionId",
        "vehicleId",
        "state",
        "username",
        // settlement
        "batchNumber",
        "orderId",
        "netAmount",
        "reservations",
        "reservations[0]",
        // "reservations[0].assetId.assetNo",
        "datePaid",
        // "status",
      ];

      if (searchValue === "not started") {
        // Special case: Search for "requested" or "scheduled" in the status field
        // const requestedResults = searchedData?.filter(
        //   (item) => item.status?.toLowerCase() === "requested" || []
        // );

        // const scheduledResults = searchedData?.filter(
        //   (item) => item.status?.toLowerCase() === "scheduled"
        // );
        const requestedResults = (searchedData || []).filter(
          (item) => item?.status?.toLowerCase() === "requested"
        );

        const scheduledResults = (searchedData || []).filter(
          (item) => item?.status?.toLowerCase() === "scheduled"
        );

        searchedData = [...requestedResults, ...scheduledResults];
      } else if (searchValue === "not accepted") {
        const requestedResults = (searchedData || []).filter(
          (item) =>
            item?.status?.toLowerCase() === "requested" && item?.driverId
        );
        searchedData = [...requestedResults];
      } else if (searchValue === "unassigned") {
        const requestedResults = (searchedData || []).filter(
          (item) => !item?.driverId
        );
        searchedData = [...requestedResults];
      } else if (
        searchValue == "0.0" ||
        searchValue == "0." ||
        searchValue == "0.00" ||
        searchValue == "$0.0" ||
        searchValue == "$0" ||
        searchValue == "$0." ||
        searchValue == "$0.00"
      ) {
        const requestedResults = (searchedData || []).filter(
          (item) => item?.fareAndDistance?.driverAmount == 0
        );
        searchedData = [...requestedResults];
      } else if (searchValue == "not billable") {
        const requestedResults = (searchedData || []).filter(
          (item) => item?.notBillable === true
        );
        searchedData = [...requestedResults];
      } else if (searchValue == "online drivers") {
        const requestedResults = (searchedData || []).filter(
          (item) =>
            item?.isDriver === true &&
            item?.isOnline === true &&
            item?.disable === false &&
            item?.delete === false
        );
        searchedData = [...requestedResults];
      } else if (searchValue == "offline drivers") {
        const requestedResults = (searchedData || []).filter(
          (item) =>
            item?.isDriver === true &&
            item?.isOnline === false &&
            item?.disable === false &&
            item?.delete === false
        );
        searchedData = [...requestedResults];
      } else if (searchValue == "all drivers") {
        const requestedResults = (searchedData || []).filter(
          (item) => item?.isDriver === true
        );
        searchedData = [...requestedResults];
      } else if (searchValue == "active") {
        const requestedResults = (searchedData || []).filter(
          (item) => item?.disable === false
        );
        searchedData = [...requestedResults];
      } else if (searchValue == "inactive") {
        const requestedResults = (searchedData || []).filter(
          (item) => item?.disable === true
        );
        searchedData = [...requestedResults];
      } else if (
        searchValue == "n/a" ||
        searchValue == "n/" ||
        searchValue == "n"
      ) {
        const requestedResults = (searchedData || []).filter(
          (item) => !item?.datePaid
        );

        searchedData = [...requestedResults];
      }
      // else if (/^\d{2}\/\d{2}\/\d{4}$/.test(searchValue)) {
      //   // If searchValue matches the date format "MM/DD/YYYY"
      //   // Convert searchValue to the format "YYYY-MM-DD" for comparison
      //   let searchDate = searchValue.split("/");
      //   searchDate = searchDate[2] - searchDate[0] - searchDate[1];
      //   const requestedResults = (searchedData || []).filter(
      //     (item) => item.datePaid === searchDate
      //   );
      //   searchedData = [...requestedResults];
      // }
      else {
        searchedData = searchedData?.filter((item) => {
          // return Object.keys(item).some((key) => {
          //   const value = item[key];
          return searchFields.some((field) => {
            const value = item[field];
            if (Array.isArray(value)) {
              // Handle array of objects
              return value.some((arrayItem) =>
                Object.values(arrayItem).some((nestedValue) => {
                  // Handle each property within the object
                  // if (Array.isArray(nestedValue)) {
                  //   // Handle the 'weekdays' array
                  //   return nestedValue.some((dayItem) => {
                  //     if (
                  //       dayItem.dayName &&
                  //       dayItem.dayName.toLowerCase().includes(searchValue)
                  //     ) {
                  //       // Check if 'active' property is true
                  //       return dayItem.active === true;
                  //     }
                  //     return false;
                  //   });
                  // } else
                  if (typeof nestedValue === "object" && nestedValue !== null) {
                    // Convert object to a string representation that makes sense for your search
                    const objectString = Object.values(nestedValue).join(" ");
                    return objectString.toLowerCase().includes(searchValue);
                  } else {
                    // console.log(
                    //   nestedValue,
                    //   /^[0-9a-fA-F]{24}$/.test(nestedValue),
                    //   "nestedValue"
                    // );
                    if (!/^[0-9a-fA-F]{24}$/.test(nestedValue)) {
                      return nestedValue
                        ?.toString()
                        .toLowerCase()
                        .includes(searchValue);
                    }
                  }
                })
              );
            } else if (
              // (key === "driverId" || key === "customerId") &&
              field === "datePaid" &&
              value
            ) {
              // If searching in the "user" or "customer" field, concatenate firstName and lastName
              let subStr = value?.substring(0, 10);
              let parts = subStr?.split("-");
              let displayDate = `${parts[1]}/${parts[2]}/${parts[0]}`;
              return (
                displayDate.toLowerCase().includes(searchValue) ||
                displayDate.toLowerCase().indexOf(searchValue) === 0
              );
            } else if (
              // (key === "driverId" || key === "customerId") &&
              (field === "driverId" || field === "customerId") &&
              typeof value === "object" &&
              value !== null
            ) {
              // If searching in the "user" or "customer" field, concatenate firstName and lastName
              const userFullName =
                `${value.firstName} ${value.lastName}`.trim();
              return (
                userFullName.toLowerCase().includes(searchValue) ||
                userFullName.toLowerCase().indexOf(searchValue) === 0
              );
            } else if (
              // key === "reservationId" &&
              field === "reservationId" &&
              typeof value === "object" &&
              value !== null
            ) {
              const userFullName =
                `${value?.customerId?.firstName} ${value?.customerId?.lastName}`.trim();
              let cond =
                userFullName.toLowerCase().includes(searchValue) ||
                userFullName.toLowerCase().indexOf(searchValue) === 0;
              if (!cond) {
                const driverFullName =
                  `${value?.driverId?.firstName} ${value?.driverId?.lastName}`.trim();
                cond =
                  driverFullName.toLowerCase().includes(searchValue) ||
                  driverFullName.toLowerCase().indexOf(searchValue) === 0;
              }
              if (!cond) {
                const check = `${value?.orderId}`.trim();
                cond =
                  check.toLowerCase().includes(searchValue) ||
                  check.toLowerCase().indexOf(searchValue) === 0;
              }
              if (!cond) {
                const check = `${value?.loadNumber}`.trim();
                cond =
                  check.toLowerCase().includes(searchValue) ||
                  check.toLowerCase().indexOf(searchValue) === 0;
              }
              if (!cond) {
                const check = `${value?.specialNumber}`.trim();
                cond =
                  check.toLowerCase().includes(searchValue) ||
                  check.toLowerCase().indexOf(searchValue) === 0;
              }
              if (!cond) {
                const check = `${value?.pickUpAddress?.address}`.trim();
                cond =
                  check.toLowerCase().includes(searchValue) ||
                  check.toLowerCase().indexOf(searchValue) === 0;
              }
              if (!cond) {
                const check = `${value?.dropOffAddress?.address}`.trim();
                cond =
                  check.toLowerCase().includes(searchValue) ||
                  check.toLowerCase().indexOf(searchValue) === 0;
              }
              if (!cond) {
                const check = `${value?.status}`.trim();
                cond =
                  check.toLowerCase().includes(searchValue) ||
                  check.toLowerCase().indexOf(searchValue) === 0;
              }
              if (!cond) {
                const check = `${value?.assetId?.assetNo}`.trim();
                cond =
                  check.toLowerCase().includes(searchValue) ||
                  check.toLowerCase().indexOf(searchValue) === 0;
              }
              return cond;
            } else if (typeof value === "object" && value !== null) {
              // Handle nested properties
              return Object.values(value).some((nestedValue) =>
                nestedValue?.toString().toLowerCase().includes(searchValue)
              );
            } else {
              // Handle non-nested properties
              // if (key === "firstName") {
              if (field === "firstName") {
                const userFullName =
                  `${item.firstName} ${item.lastName}`.trim();
                return (
                  userFullName.toLowerCase().includes(searchValue) ||
                  userFullName.toLowerCase().indexOf(searchValue) === 0
                );
              } else {
                // if (field !== "id" || field !== "_id") {
                return value?.toString().toLowerCase().includes(searchValue);
                // }
              }
            }
          });
        });
      }
    }
    // console.log(searchedData, "searchedData");

    const sortedData =
      searchedData?.length > 0 &&
      searchedData?.sort((a, b) => {
        const comparableAValue = getComparableValue(a, sortField);
        const comparableBValue = getComparableValue(b, sortField);

        // Handle cases where the nested property is not present
        if (comparableAValue === null) {
          return sortOrder === "asc" ? -1 : 1;
        }
        if (comparableBValue === null) {
          return sortOrder === "asc" ? 1 : -1;
        }

        // Compare the values
        if (
          typeof comparableAValue === "number" &&
          typeof comparableBValue === "number"
        ) {
          return sortOrder === "asc"
            ? comparableAValue - comparableBValue
            : comparableBValue - comparableAValue;
        } else if (
          typeof comparableAValue === "string" &&
          typeof comparableBValue === "string"
        ) {
          return sortOrder === "asc"
            ? comparableAValue.localeCompare(comparableBValue)
            : comparableBValue.localeCompare(comparableAValue);
        } else {
          return sortOrder === "asc"
            ? comparableAValue - comparableBValue
            : comparableBValue - comparableAValue;
        }
      });

    // console.log(sortedData, "sortedData");

    const totalPages = Math.ceil(
      (searchData !== "" ? sortedData.length : totalNumber) / entriesToShow
    );
    const currentPage = pageNumber ? pageNumber : 1;
    const newData = sortedData;
    // sortedData.length > 0
    //   ? sortedData?.slice(
    //       currentPage * entriesToShow - entriesToShow,
    //       currentPage * entriesToShow
    //     )
    //   : [];

    // Calculate startPage and endPage
    const maxPagesToShow = 5;
    let startPage = 1;
    let endPage = totalPages;

    if (totalPages > maxPagesToShow) {
      const middle = Math.floor(maxPagesToShow / 2);
      if (currentPage <= middle) {
        startPage = 1;
      } else if (currentPage >= totalPages - middle) {
        startPage = totalPages - maxPagesToShow + 1;
      } else {
        startPage = currentPage - middle;
      }
      endPage = Math.min(startPage + maxPagesToShow - 1, totalPages);
    }
    // const paginationArr = Array.from({ length: totalPages }, (_, i) => i + 1);
    const paginationArr = Array.from(
      { length: endPage - startPage + 1 },
      (_, i) => i + startPage
    );
    const obj = {
      displayingResults: newData?.length,
      limit: entriesToShow,
      currentPageNumber: currentPage,
      paginationArr,
      results: newData,
      totalPages,
      totalResults: sortedData?.length,
      completeData: sortedData,
      startPage,
      endPage,
    };
    // console.log(obj, "obj,objobjobjobjobjobjobjobj");
    return obj;
  };
  const getComparableValue = (obj, field) => {
    const dotIndex = field.indexOf(".");

    if (dotIndex !== -1) {
      const beforeDot = field.slice(0, dotIndex);
      const afterDot = field.slice(dotIndex + 1);

      if (obj[beforeDot] && typeof obj[beforeDot] === "object") {
        return getComparableValue(obj[beforeDot], afterDot);
      } else {
        // Handle the case where the nested property is not present
        return null;
      }
    } else {
      if (field === "assetNo" || field === "routeNumber" || field === "vin") {
        return parseInt(obj[field]);
      } else {
        return obj[field];
      }
    }
  };
  const reservationData = async (e) => {
    // console.log("ddddddddddd");
    const chicagoDateOptions = {
      timeZone: "America/Chicago",
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    };

    const chicagoDate = new Date().toLocaleDateString(
      "en-US",
      chicagoDateOptions
    );
    const parts = chicagoDate.split("/");
    const formattedChicagoDate = `${parts[2]}-${parts[0]}-${parts[1]}`;

    // dispatch(
    //   getAllReservationV2(
    //     {
    //       companyId: auth.user.companyId.id,
    //       today: formattedChicagoDate,
    //       isToday: false,
    //     },
    //     tempData
    //   )
    // );
    // dispatch(
    //   getPastReservationsByCompanyId({
    //     companyId: auth.user.companyId.id,
    //     today: formattedChicagoDate,
    //   })
    // );
    dispatch(
      getPastReservationsByCompanyIdV3(
        {
          limit: limit,
          page: currentPage,
          companyId: auth.user.companyId.id,
          today: formattedChicagoDate,
        }
        // tempData
      )
    );
  };

  const tempData = (data) => {
    // setFullData(data?.previous);
    setFullData(data);
  };

  // const reservationData = async (e) => {
  //   dispatch(
  //     getAllReservationV2({
  //       companyId: auth.user.companyId.id,
  //       today: moment().format("YYYY-MM-DD"),
  //       isToday: false,
  //     })
  //   );
  // };

  useEffect(() => {
    reservationData();
  }, []);

  // State to manage pagination, sorting, and limit
  const [currentPage, setCurrentPage] = useState(1);
  const [sortField, setSortField] = useState("orderId");
  const [sortOrder, setSortOrder] = useState("desc"); //asc, desc
  const [searchTerm, setSearchTerm] = useState("");
  const [limit, setLimit] = useState(50); // Set your default limit here
  // const [paginatedData, setPaginatedData] = useState({});

  // Function to handle pagination, sorting, and searching
  const handlePagination = (page, field, order, search, newLimit) => {
    let newPage;
    if (page < 1) {
      newPage = 1;
    } else if (page > paginatedData.totalPages) {
      newPage = paginatedData.totalPages;
    } else {
      newPage = page;
    }
    setCurrentPage(newPage);
    setSortField(field);
    setSortOrder(order);
    setSearchTerm(search);
    setLimit(newLimit);
  };

  // old logic
  // // const fullData = reservation?.reservation?.previous;
  // // // Use the pagination function to get the data for the current page
  // // const paginatedData = paginationDataToShow(
  // //   limit,
  // //   currentPage,
  // //   fullData,
  // //   sortField,
  // //   sortOrder,
  // //   searchTerm
  // // );

  // useEffect(() => {
  //   const fullData = reservation?.reservation?.previous;
  //   // Use the pagination function to get the data for the current page
  //   setPaginatedData(
  //     paginationDataToShow(
  //       limit,
  //       currentPage,
  //       fullData,
  //       sortField,
  //       sortOrder,
  //       searchTerm
  //     )
  //   );
  // }, [
  //   limit,
  //   currentPage,
  //   sortField,
  //   sortOrder,
  //   searchTerm,
  //   reservation?.reservation,
  // ]);

  // console.log("paginated data==>", paginatedData);
  const [fullData, setFullData] = useState(reservation?.reservation?.previous);
  // const [paginationArr, setPaginationArr] = useState([]);
  // const [startPage, setStartPage] = useState(1);
  // const [endPage, setEndPage] = useState(reservation?.pastDetails?.totalPages);
  // const maxPagesToShow = 5;

  const newPaginatedArray = formArray(
    reservation?.pastDetails?.totalPages,
    currentPage
  );

  // useEffect(() => {
  //   const totalPages = reservation?.pastDetails?.totalPages;
  //   let st = 1;
  //   let en = reservation?.pastDetails?.totalPages;
  //   if (reservation?.pastDetails?.totalPages > maxPagesToShow) {
  //     const middle = Math.floor(maxPagesToShow / 2);
  //     if (currentPage <= middle) {
  //       st = 1;
  //       setStartPage(1);
  //     } else if (currentPage >= totalPages - middle) {
  //       st = totalPages - maxPagesToShow + 1;
  //       setStartPage(totalPages - maxPagesToShow + 1);
  //     } else {
  //       st = currentPage - middle;
  //       setStartPage(currentPage - middle);
  //     }
  //     en = Math.min(startPage + maxPagesToShow - 1, totalPages);
  //     setEndPage(Math.min(startPage + maxPagesToShow - 1, totalPages));
  //   }
  //   setPaginationArr(Array.from({ length: en - st + 1 }, (v, i) => i + st));
  // }, [startPage, endPage, currentPage]);
  useEffect(() => {
    setFullData(reservation?.reservation?.previous);
    setPaginatedData(
      paginationDataToShow2(
        limit,
        currentPage,
        reservation?.reservation?.previous,
        sortField,
        sortOrder,
        searchTerm,
        reservation?.pastDetails?.totalResults
      )
    );
    setExportData(reservation?.reservation?.previous);
    // console.log(
    //   reservation?.reservation?.previous,
    //   "reservation?.reservation?.previous"
    // );
    // paginationDataToShow2(
    //   limit,
    //   currentPage,
    //   reservation?.reservation?.previous,
    //   sortField,
    //   sortOrder,
    //   searchTerm,
    //   reservation?.pastDetails?.totalResults
    // );
  }, [
    reservation,
    reservation?.reservation?.previous,
    limit,
    currentPage,
    fullData,
    sortField,
    sortOrder,
    searchTerm,
  ]);
  // const paginationArr = Array.from(
  //   { length: reservation?.pastDetails?.totalPages },
  //   (v, i) => i + 1
  // );
  // Use the pagination function to get the data for the current page
  const [paginatedData, setPaginatedData] = useState(
    paginationDataToShow2(
      limit,
      currentPage,
      reservation?.reservation?.previous,
      sortField,
      sortOrder,
      searchTerm,
      reservation?.pastDetails?.totalResults
    )
  );

  // useEffect(() => {
  //   setPaginatedData(
  //     paginationDataToShow(
  //       limit,
  //       currentPage,
  //       fullData,
  //       sortField,
  //       sortOrder,
  //       searchTerm
  //     )
  //   );
  // }, [
  //   limit,
  //   currentPage,
  //   fullData,
  //   sortField,
  //   sortOrder,
  //   searchTerm,
  //   fullData,
  // ]);

  // useEffect(() => {
  //   if (paginatedData.results) {
  //     setExportData(paginatedData.results);
  //   }
  //   // Use the pagination function to get the data for the current page
  // }, [paginatedData]);
  // Function to handle sorting icon click
  const handleSortClick = (field, order) => {
    // Call handlePagination with sorting parameters
    handlePagination(currentPage, field, order, searchTerm, limit);
  };
  // Function to determine the color based on the current sorting order
  const getArrowColor = (field, direction) => {
    return sortField === field && sortOrder === direction
      ? "#000000"
      : "#A0A0A0";
  };
  // console.log(paginatedData, "paginatedData1");

  const locationUrl = useLocation();
  const queryParams = new URLSearchParams(locationUrl.search);
  const isCancelParam = queryParams.get("cancel");
  const isNoshowParam = queryParams.get("noshow");
  const isActiveParam = queryParams.get("active");
  const isCompletedParam = queryParams.get("completed");
  const isNotStartedParam = queryParams.get("notstarted");
  // console.log( "iscancelparam --> ", isCancelParam);

  useEffect(() => {
    if (isCancelParam == "true") {
      setSearchTerm("Canceled");
    }
    if (isNoshowParam == "true") {
      setSearchTerm("No Show");
    }
    if (isActiveParam == "true") {
      setSearchTerm("In Progress");
    }
    if (isCompletedParam == "true") {
      setSearchTerm("Completed");
    }
    if (isNotStartedParam == "true") {
      setSearchTerm("not started");
    }
  }, []);

  const [hideAssign, setHideAssign] = useState(false);
  const [completeData, setCompleteData] = useState([]);
  const openHideAssign = () => {
    if (hideAssign === false) {
      setCompleteData(fullData);
      setFullData(fullData.filter((item) => !item?.driverId));
    } else {
      setFullData(completeData);
    }
    setHideAssign((hideAssign) => !hideAssign);
  };
  const handleLimit = async (event) => {
    setLimit(parseInt(event.target.value));
    // dispatch(
    //   getPastReservationsByCompanyIdV3(
    //     { companyId: auth?.user?.companyId?.id,
    //       today: moment().format('YYYY-MM-DD'),
    //       isToday: false,
    //       limit: event.target.value,
    //       page: 1,
    //     })
    // );
    const chicagoDateOptions = {
      timeZone: "America/Chicago",
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    };

    const chicagoDate = new Date().toLocaleDateString(
      "en-US",
      chicagoDateOptions
    );
    const parts = chicagoDate.split("/");
    const formattedChicagoDate = `${parts[2]}-${parts[0]}-${parts[1]}`;
    dispatch(
      getPastReservationsByCompanyIdV3(
        {
          limit: event.target.value,
          page: 1,
          companyId: auth.user.companyId.id,
          today: formattedChicagoDate,
        }
        // tempData
      )
    );
    setCurrentPage(1);
  };

  const onClickNext = async () => {
    let i = currentPage;
    i++;
    const chicagoDateOptions = {
      timeZone: "America/Chicago",
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    };

    const chicagoDate = new Date().toLocaleDateString(
      "en-US",
      chicagoDateOptions
    );
    const parts = chicagoDate.split("/");
    const formattedChicagoDate = `${parts[2]}-${parts[0]}-${parts[1]}`;
    if (i <= reservation.pastDetails.totalPages) {
      setCurrentPage(currentPage + 1);
      dispatch(
        getPastReservationsByCompanyIdV3({
          companyId: auth?.user?.companyId?.id,
          today: moment().format("YYYY-MM-DD"),
          // isToday: false,
          limit: limit,
          page: currentPage + 1,
        })
      );
    }
  };
  const onClickPrev = async () => {
    let i = currentPage;
    i--;
    const chicagoDateOptions = {
      timeZone: "America/Chicago",
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    };

    const chicagoDate = new Date().toLocaleDateString(
      "en-US",
      chicagoDateOptions
    );
    const parts = chicagoDate.split("/");
    const formattedChicagoDate = `${parts[2]}-${parts[0]}-${parts[1]}`;
    if (i >= 1) {
      setCurrentPage(currentPage - 1);
      dispatch(
        getPastReservationsByCompanyIdV3({
          companyId: auth?.user?.companyId?.id,
          today: moment().format("YYYY-MM-DD"),
          // isToday: false,
          limit: limit,
          page: currentPage - 1,
        })
      );
    }
  };
  const handleChangeData = async (pageNo) => {
    setCurrentPage(pageNo);
    dispatch(
      getPastReservationsByCompanyIdV3({
        companyId: auth?.user?.companyId?.id,
        today: moment().format("YYYY-MM-DD"),
        // isToday: false,
        limit: limit,
        page: pageNo,
      })
    );
  };

  return auth?.user?.permissionId?.booking?.read === true ? (
    <>
      {loading && !reservation?.reservation && (
        // <LoadingComponentRideModal loadingTable />
        <LoadingComponentRideModal />
      )}
      {reservation?.reservation && (
        <>
          <div className="d-flex flex-column w-tables rounded mt-3 bg-white">
            <div className="d-flex ">
              <div className="flex-grow-1">
                <div
                  className="dataTables_length d-flex"
                  id="leads-table_length"
                >
                  <div className="p-2">
                    <label>Show entries</label>
                  </div>
                  <label>
                    <select
                      name="leads-table_length"
                      aria-controls="leads-table"
                      className="custom-select custom-select-sm form-control form-control-sm"
                      autoComplete="false"
                      // onChange={handlePagination("newLimit")}
                      value={limit} // Make sure to set the value attribute to the current limit
                      onChange={(e) => {
                        handlePagination(
                          1,
                          sortField,
                          sortOrder,
                          searchTerm,
                          parseInt(e.target.value)
                        );
                        handleLimit();
                      }}
                    >
                      {[10, 25, 50, 100].map((current, i) => {
                        return (
                          <option value={current} key={i}>
                            {current}
                          </option>
                        );
                      })}
                    </select>
                  </label>
                </div>
              </div>
              {/* <div className="col-md-2 d-flex align-items-center justify-content-center">
                <div className="form-check">
                  <input
                    type="checkbox"
                    checked={hideAssign}
                    onClick={openHideAssign}
                  />
                  <label
                    className="form-check-label pl-2 f-14 text-dark mx-1"
                    htmlFor="flexCheckDefault"
                  >
                    Hide Assign
                  </label>
                </div>
              </div> */}
              <div className="task-search task-search-new search-col-table d-flex  py-1 px-lg-3 px-0 align-items-center">
                <form
                  className="w-100 mr-1 mr-lg-0 mr-md-1 ml-md-1 ml-0 ml-lg-0"
                  onSubmit={(e) => e.preventDefault()}
                >
                  <div className="input-group bg-grey rounded">
                    <div className="input-group-prepend">
                      <span className="input-group-text border-0 bg-additional-grey">
                        <i className="fa fa-search f-13 text-dark-grey"></i>
                      </span>
                    </div>
                    <input
                      type="text"
                      className="form-control f-14 p-1 border-additional-grey"
                      id="search-text-field"
                      placeholder="Start typing to search"
                      value={searchTerm}
                      onChange={(e) =>
                        handlePagination(
                          1,
                          sortField,
                          sortOrder,
                          e.target.value,
                          limit
                        )
                      }
                    />
                  </div>
                </form>
              </div>
            </div>
            <div
              id="leads-table_wrapper"
              className="dataTables_wrapper dt-bootstrap4 no-footer"
            >
              <div className="row">
                <div className="col-sm-12">
                  <table
                    className="table border w-100 dataTable no-footer"
                    id="leads-table"
                    role="grid"
                    aria-describedby="leads-table_info"
                    style={{ overflowX: "auto" }}
                  >
                    <thead className="card-header">
                      <tr role="row">
                        <th
                          title="Order ID"
                          className="sorting border-right align-table-header min-width-orderId"
                          tabIndex="0"
                          aria-controls="leads-table"
                          rowSpan="1"
                          colSpan="1"
                          aria-label="Mobile: activate to sort column ascending"
                        >
                          Order ID <br />
                          <span className="right-sorting-new">
                            <i
                              className="fas fa-long-arrow-alt-up"
                              style={{ color: getArrowColor("orderId", "asc") }}
                              onClick={() => handleSortClick("orderId", "asc")}
                            ></i>
                            <i
                              className="fas fa-long-arrow-alt-down"
                              style={{
                                color: getArrowColor("orderId", "desc"),
                              }}
                              onClick={() => handleSortClick("orderId", "desc")}
                            ></i>
                          </span>
                        </th>

                        <th
                          title="Load#"
                          className="sorting border-right align-table-header"
                          tabIndex="0"
                          aria-controls="leads-table"
                          rowSpan="1"
                          colSpan="1"
                          aria-label="Mobile: activate to sort column ascending"
                        >
                          Load# <br />
                          <span className="right-sorting-new">
                            <i
                              className="fas fa-long-arrow-alt-up"
                              style={{
                                color: getArrowColor("loadNumber", "asc"),
                              }}
                              onClick={() =>
                                handleSortClick("loadNumber", "asc")
                              }
                            ></i>
                            <i
                              className="fas fa-long-arrow-alt-down"
                              style={{
                                color: getArrowColor("loadNumber", "desc"),
                              }}
                              onClick={() =>
                                handleSortClick("loadNumber", "desc")
                              }
                            ></i>
                          </span>
                        </th>
                        <th
                          title="Driver Name"
                          className="sorting border-right align-table-header min-width-orderId"
                          tabIndex="0"
                          aria-controls="leads-table"
                          rowSpan="1"
                          colSpan="1"
                          aria-label="Mobile: activate to sort column ascending"
                        >
                          Driver Name
                          <span className="right-sorting-new">
                            <i
                              className="fas fa-long-arrow-alt-up"
                              style={{
                                color: getArrowColor(
                                  "driverId.firstName",
                                  "asc"
                                ),
                              }}
                              onClick={() =>
                                handleSortClick("driverId.firstName", "asc")
                              }
                            ></i>
                            <i
                              className="fas fa-long-arrow-alt-down"
                              style={{
                                color: getArrowColor(
                                  "driverId.firstName",
                                  "desc"
                                ),
                              }}
                              onClick={() =>
                                handleSortClick("driverId.firstName", "desc")
                              }
                            ></i>
                          </span>
                        </th>

                        <th
                          title="Vehicle#"
                          className="sorting border-right align-table-header"
                          rowSpan="1"
                          colSpan="1"
                          aria-label="Next Follow Up"
                        >
                          Vehicle# <br />
                          <span className="right-sorting-new">
                            <i
                              className="fas fa-long-arrow-alt-up"
                              style={{
                                color: getArrowColor("assetId.assetNo", "asc"),
                              }}
                              onClick={() =>
                                handleSortClick("assetId.assetNo", "asc")
                              }
                            ></i>
                            <i
                              className="fas fa-long-arrow-alt-down"
                              style={{
                                color: getArrowColor("assetId.assetNo", "desc"),
                              }}
                              onClick={() =>
                                handleSortClick("assetId.assetNo", "desc")
                              }
                            ></i>
                          </span>
                        </th>
                        <th
                          title="Time"
                          className="sorting border-right align-table-header"
                          tabIndex="0"
                          aria-controls="leads-table"
                          rowSpan="1"
                          colSpan="1"
                          aria-label="Lead Agent: activate to sort column ascending"
                        >
                          Time <br />
                          <span className="right-sorting-new">
                            <i
                              className="fas fa-long-arrow-alt-up"
                              style={{
                                color: getArrowColor("pickUpDate", "asc"),
                              }}
                              onClick={() =>
                                handleSortClick("pickUpDate", "asc")
                              }
                            ></i>
                            <i
                              className="fas fa-long-arrow-alt-down"
                              style={{
                                color: getArrowColor("pickUpDate", "desc"),
                              }}
                              onClick={() =>
                                handleSortClick("pickUpDate", "desc")
                              }
                            ></i>
                          </span>
                        </th>
                        <th
                          title="Passengers"
                          className="sorting border-right align-table-header"
                          tabIndex="0"
                          aria-controls="leads-table"
                          rowSpan="1"
                          colSpan="1"
                          aria-label="Lead Agent: activate to sort column ascending"
                        >
                          Passenger{" "}
                          {/* <span className="right-sorting-new">
                        <i
                          className="fas fa-long-arrow-alt-up"
                          style={{
                            color: getArrowColor("customerId.firstName", "asc"),
                          }}
                          onClick={() =>
                            handleSortClick("customerId.firstName", "asc")
                          }
                        ></i>
                        <i
                          className="fas fa-long-arrow-alt-down"
                          style={{
                            color: getArrowColor(
                              "customerId.firstName",
                              "desc"
                            ),
                          }}
                          onClick={() =>
                            handleSortClick("customerId.firstName", "desc")
                          }
                        ></i>
                      </span> */}
                        </th>
                        <th
                          title="Pickup - Drop Off"
                          className="sorting border-right w-30 align-table-header"
                          tabIndex="0"
                          aria-controls="leads-table"
                          rowSpan="1"
                          colSpan="1"
                          aria-label="Lead Agent: activate to sort column ascending"
                        >
                          Pickup - Drop Off{" "}
                          {/* <span className="right-sorting-new">
                        <i
                          className="fas fa-long-arrow-alt-up"
                          style={{
                            color: getArrowColor("pickUpDate", "asc"),
                          }}
                          onClick={() => handleSortClick("pickUpDate", "asc")}
                        ></i>
                        <i
                          className="fas fa-long-arrow-alt-down"
                          style={{
                            color: getArrowColor("pickUpDate", "desc"),
                          }}
                          onClick={() => handleSortClick("pickUpDate", "desc")}
                        ></i>
                      </span> */}
                        </th>
                        <th
                          title="Vehicle Type"
                          className="sorting border-right align-table-header"
                          tabIndex="0"
                          aria-controls="leads-table"
                          rowSpan="1"
                          colSpan="1"
                          aria-label="Lead Agent: activate to sort column ascending"
                        >
                          Vehicle Type{" "}
                          <span className="right-sorting-new">
                            <i
                              className="fas fa-long-arrow-alt-up"
                              style={{
                                color: getArrowColor("type.name", "asc"),
                              }}
                              onClick={() =>
                                handleSortClick("type.name", "asc")
                              }
                            ></i>
                            <i
                              className="fas fa-long-arrow-alt-down"
                              style={{
                                color: getArrowColor("type.name", "desc"),
                              }}
                              onClick={() =>
                                handleSortClick("type.name", "desc")
                              }
                            ></i>
                          </span>
                        </th>
                        <th
                          title="Driver Amount"
                          className="sorting border-right align-table-header"
                          tabIndex="0"
                          aria-controls="leads-table"
                          rowSpan="1"
                          colSpan="1"
                          aria-label="Lead Agent: activate to sort column ascending"
                        >
                          Driver Amount <br />
                          {/* <span className="right-sorting-new">
                        <i
                          className="fas fa-long-arrow-alt-up"
                          style={{ color: getArrowColor("totalAmount", "asc") }}
                          onClick={() => handleSortClick("totalAmount", "asc")}
                        ></i>
                        <i
                          className="fas fa-long-arrow-alt-down"
                          style={{
                            color: getArrowColor("totalAmount", "desc"),
                          }}
                          onClick={() => handleSortClick("totalAmount", "desc")}
                        ></i>
                      </span> */}
                        </th>
                        <th
                          title="Status"
                          className="sorting border-right align-table-header"
                          tabIndex="0"
                          aria-controls="leads-table"
                          rowSpan="1"
                          colSpan="1"
                          aria-label="Lead Agent: activate to sort column ascending"
                        >
                          Status <br />
                          <span className="right-sorting-new">
                            <i
                              className="fas fa-long-arrow-alt-up"
                              style={{ color: getArrowColor("status", "asc") }}
                              onClick={() => handleSortClick("status", "asc")}
                            ></i>
                            <i
                              className="fas fa-long-arrow-alt-down"
                              style={{ color: getArrowColor("status", "desc") }}
                              onClick={() => handleSortClick("status", "desc")}
                            ></i>
                          </span>
                        </th>
                        <th
                          title="Action"
                          className="text-right pr-20 sorting_disabled border-right align-table-header"
                          rowSpan="1"
                          colSpan="1"
                          aria-label="Action"
                        >
                          Actions
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {reservation &&
                        reservation?.reservation &&
                        reservation?.reservation?.previous &&
                        reservation?.reservation?.previous?.length > 0 &&
                        paginatedData?.results?.length > 0 &&
                        // reservation.reservation.previous.map((current, i) => {
                        paginatedData?.results?.map((current, i) => {
                          return (
                            <AdminBookingPastBookingRow
                              current={current}
                              i={i}
                              key={i}
                              fullData={fullData}
                              setFullData={setFullData}
                            />
                          );
                        })}
                    </tbody>
                  </table>
                  {reservation &&
                    reservation?.reservation &&
                    reservation?.reservation?.previous &&
                    reservation?.reservation?.previous?.length === 0 && (
                      // paginatedData?.results?.length === 0 &&
                      <div
                        className="dash-stats-list"
                        style={{ textAlign: "center" }}
                      >
                        <br />
                        <br />
                        <img
                          alt=""
                          src={shift1}
                          style={{ width: "145px", opacity: "0.5" }}
                        />
                        <br />
                        <p style={{ opacity: "0.5" }}>No Data Found</p>
                      </div>
                    )}
                  <div
                    id="leads-table_processing"
                    className="dataTables_processing card"
                    style={{ display: "none" }}
                  >
                    Processing...
                  </div>
                </div>
              </div>
              {reservation &&
                reservation?.reservation &&
                reservation?.reservation?.previous &&
                reservation?.reservation?.previous?.length > 0 && (
                  // paginatedData?.results?.length > 0 &&
                  <div className="d-flex">
                    {reservation.loading === false ? (
                      <div className="flex-grow-1">
                        <div
                          className="dataTables_info"
                          id="leads-table_info"
                          role="status"
                          aria-live="polite"
                        >
                          Showing{" "}
                          {1 +
                            paginatedData.limit *
                              (paginatedData?.currentPageNumber - 1)}{" "}
                          to{" "}
                          {paginatedData.displayingResults +
                            paginatedData.limit *
                              (paginatedData?.currentPageNumber - 1)}{" "}
                          of{" "}
                          {searchTerm !== ""
                            ? paginatedData.totalResults
                            : reservation?.pastDetails?.totalResults}{" "}
                          entries
                          {/* Showing{" "}
                          {1 +
                            paginatedData.limit *
                              (paginatedData?.currentPageNumber - 1)}{" "}
                          to{" "}
                          {paginatedData.displayingResults +
                            paginatedData.limit *
                              (paginatedData?.currentPageNumber - 1)}{" "}
                          of {paginatedData.totalResults} entries */}
                          {/* Showing {1 + limit * (currentPage - 1)} to{" "}
                          {reservation?.pastDetails?.displayingResults +
                            limit * (currentPage - 1)}{" "}
                          of {reservation?.pastDetails?.totalResults} entries */}
                        </div>
                      </div>
                    ) : (
                      <div className="flex-grow-1">
                        <div
                          className="dataTables_info"
                          id="leads-table_info"
                          role="status"
                          aria-live="polite"
                        >
                          Loading entries
                        </div>
                      </div>
                    )}
                    <div className="search-col-table">
                      <div
                        className="dataTables_paginate paging_simple_numbers"
                        id="leads-table_paginate"
                      >
                        <ul className="pagination">
                          <li className="page-item">
                            <NavLink
                              className="page-link"
                              to="#"
                              aria-label="Previous"
                              // onClick={onClickPrev}
                              onClick={(e) => {
                                handlePagination(
                                  currentPage - 1,
                                  sortField,
                                  sortOrder,
                                  searchTerm,
                                  limit
                                );
                                onClickPrev();
                              }}
                            >
                              <span aria-hidden="true">Previous</span>
                              <span className="sr-only">Previous</span>
                            </NavLink>
                          </li>
                          {reservation &&
                            reservation?.reservation &&
                            reservation?.reservation?.previous &&
                            reservation?.reservation?.previous?.length > 0 &&
                            // newPaginatedArray?.paginationArr &&
                            // newPaginatedArray?.paginationArr.length > 0 &&
                            paginatedData?.paginationArr &&
                            paginatedData?.paginationArr.length > 0 &&
                            // paginationArr?.length > 0 &&
                            // reservation.reservation.previous.paginationArr.map((current, i) => {
                            // reservation.reservation.previous.paginationArr.slice(startIndex, endIndex).map((current, i) => {
                            // paginationArr.map((current, i) => {
                            //   })
                            Array.from(
                              {
                                length:
                                  paginatedData?.endPage -
                                  paginatedData?.startPage +
                                  1,
                              },
                              (_, i) => {
                                const current = paginatedData?.startPage + i;
                                return paginatedData?.currentPageNumber ===
                                  current ? (
                                  <li className="page-item active" key={i}>
                                    <Link
                                      to=""
                                      className="page-link"
                                      value={current}
                                    >
                                      {current}
                                    </Link>
                                  </li>
                                ) : (
                                  <li className="page-item" key={i}>
                                    <Link
                                      className="page-link"
                                      value={current}
                                      onClick={(e) => {
                                        handlePagination(
                                          parseInt(current),
                                          sortField,
                                          sortOrder,
                                          searchTerm,
                                          limit
                                        );
                                        handleChangeData(parseInt(current));
                                      }}
                                      to=""
                                    >
                                      {current}
                                    </Link>
                                  </li>
                                );
                              }
                            )}
                          <li className="page-item">
                            <NavLink
                              className="page-link"
                              to="#"
                              aria-label="Next"
                              // onClick={onClickNext}
                              onClick={(e) => {
                                handlePagination(
                                  currentPage + 1,
                                  sortField,
                                  sortOrder,
                                  searchTerm,
                                  limit
                                );
                                onClickNext();
                              }}
                            >
                              <span aria-hidden="true">Next</span>
                              <span className="sr-only">Next</span>
                            </NavLink>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                )}
            </div>
          </div>
        </>
      )}
    </>
  ) : (
    <>
      <NotAllowed />
    </>
  );
};
export default AdminBookingPastBooking;
