/** @format */

// import moment from "moment";
import React, { useEffect, useState } from "react";
// import LoadingComponentRideModal from "../../LoadingComponentRideModal";
// import { SERVERADDRESS } from "../../../constants";
// import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import {
  getReservationById,
  // undoInprogressAction,
  // undoStartAction,
  updateReservation2,
} from "../../../actions/reservationAction";

const AdminAddDispatchNoteModal = ({
  addDispatchNote,
  openAddDispatchNote,
  current,
  i,
}) => {
  const reservation = useSelector((state) => state.reservation);
  const { loading } = reservation;

  const { reservationDetails } = reservation;
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [values, setValues] = useState({
    dispatchNotes: "",
    actions: "",
  });
  // const dataLoaded = async () => {
  //   const id = current._id ? current._id : current.id;
  //   dispatch(
  //     getReservationById({
  //       _id: id,
  //     })
  //   );
  // };
  useEffect(() => {
    setValues(current);
    // dataLoaded();
  }, [addDispatchNote, current]);
  // useEffect(() => {
  //   if (reservationDetails) {
  //     setValues(reservationDetails);
  //   }
  // }, [reservationDetails]);
  const handleChange = (fieldName) => (event) => {
    setValues({
      ...values,
      [fieldName]: event.target.value,
    });
  };
  const handleUpdate = () => {
    const id = values._id ? values._id : values.id;
    dispatch(
      updateReservation2(
        {
          dispatcherId: auth.user.id,
          dispatchNotes: values?.dispatchNotes,
          actions: values?.actions,
          _id: id,
        },
        openAddDispatchNote
      )
    );
  };
  return (
    <>
      <div
        className={`modal fade ${
          addDispatchNote ? "show modal-y-axis" : "hide"
        }`}
        role="dialog"
        data-backdrop="static"
        data-keyboard="false" // To prevent closing with the keyboard
        onClick={(e) => {
          // Check if the click occurred outside the modal content
          if (e.target === e.currentTarget) {
            openAddDispatchNote(); // Call your close modal function
          }
        }}
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header bg-additional-grey">
              <h5 className="modal-title" id="exampleModalLongTitle">
                {" "}
                Add Dispatch Note
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => openAddDispatchNote()}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              {
                <>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-group ">
                        <p className="f-18 text-dark mb-12" htmlFor="Name">
                          Dispatch Notes:
                        </p>
                      </div>
                      <div className="dropdown bootstrap-select form-control select-picker">
                        <textarea
                          rows="2"
                          cols="50"
                          className="form-control f-14"
                          value={values?.dispatchNotes}
                          onChange={handleChange("dispatchNotes")}
                        >
                          {" "}
                        </textarea>
                      </div>
                    </div>
                  </div>
                </>
              }
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn-primary rounded f-14 p-2 mr-3"
                disabled={loading}
                onClick={() => handleUpdate()}
              >
                Add
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default AdminAddDispatchNoteModal;
