import {
  SERVICE_VIEW_REQUEST,
  SERVICE_VIEW_SUCCESS,
  SERVICE_VIEW_FAIL,
  SERVICE_UPDATE_REQUEST,
  SERVICE_UPDATE_SUCCESS,
  SERVICE_UPDATE_FAIL,
} from "../constants";

export const serviceReducer = (
  state = {
    service: {
      service: null,
    },
  },
  action
) => {
  switch (action.type) {
    // SERVICE LIST
    case SERVICE_VIEW_REQUEST:
      return {
        ...state,
      };
    case SERVICE_VIEW_SUCCESS:
      return {
        ...state,
        service: action.payload,
      };
    case SERVICE_VIEW_FAIL:
      return {
        ...state,
        error: action.payload,
      };
      // SERVICE UPDATE
      case SERVICE_UPDATE_REQUEST:
        return {
        ...state,
        };
      case SERVICE_UPDATE_SUCCESS:
        return {
          ...state,
          service: state.service.map((x) =>
            x.id === action.payload.id 
            ? action.payload : x
          ),
        };
      case SERVICE_UPDATE_FAIL:
        return {
          ...state,
          error: action.payload,
        };

    default:
      return state;
  }
};
