import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import AdminHeader from '../../components/adminComponents/adminHeaderSidebarTitleComponents/AdminHeader'
import AdminSidebar from '../../components/adminComponents/adminHeaderSidebarTitleComponents/AdminSidebar'
import MessageViewComponent from '../../components/adminComponents/adminHeaderSidebarTitleComponents/MessageViewComponent'
import { getMailByMailId } from '../../actions/mailActions'

const AdminMessagingThreadPage = () => {
  const params = useParams();
  const dispatch = useDispatch()
  const navigate = useNavigate();
  useEffect(() => {
      if (params.id) {
          // console.log("dispatch")
          dispatch(getMailByMailId({mailId: params.id}, navigate))
      } 
  }, [params.id]);
  return (
    <>
      <AdminHeader />
      <AdminSidebar />
      <div className="body-wrapper clearfix">
        <section className="main-container " id="fullscreen">
          <div className="px-4 py-2 border-top-0 emp-dashboard">
            <div className="d-block d-lg-flex d-md-flex justify-content-between action-bar mb-20 mt-3">
              <div id="table-actions" className="flex-grow-1 align-items-center">
                <h4 className=" mb-0 f-21 text-capitalize font-weight-bold"> Messaging Thread </h4>
              </div>
            </div>
            <MessageViewComponent />
          </div>
        </section>
      </div>
    </>
  )
}
export default AdminMessagingThreadPage