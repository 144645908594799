import React, { useEffect, useState } from "react";
import { GoogleMap, useJsApiLoader } from "@react-google-maps/api";
import { Marker } from "@react-google-maps/api";
import io from "socket.io-client";
// import { SERVERADDRESS } from '../../../constants';
// import onGoingRide from "../../assets/images/Untitled-1-02.png"
// import notWorking from "../../../assets/images/Untitled-1-01.png"
// import newhomeicon from "../../../assets/images/home.png"
import onGoingRide from "../../assets/images/Frame 6.png";

// import { useParams } from 'react-router-dom';
import axios from "axios";
import { useSelector } from "react-redux";
import Demo5 from "../../pages/Demo5";
import { SOCKET_SERVER_ADDRESS } from "../../constants";
// import CustomMarker from './CustomMarker';
const containerStyle = {
  width: "100%",
  height: "80vh",
};

// const socket = io('http://localhost:5000')
// const socket = io(SOCKET_SERVER_ADDRESS)

const ExternalCompanyTrackingMap = () => {
  const auth = useSelector((state) => state.auth);
  const location = useSelector((state) => state.location);
  const sockets = useSelector((state) => state.sockets);
  const { socketConnected, socketInstance } = sockets;
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    // googleMapsApiKey: "AIzaSyDv5TZ4_il1sukhMFSFChda0UHcL0js0Zg"
    googleMapsApiKey: "AIzaSyC653TecaJSucmBmqXPr9twuP_jG55NACA",
  });
  // const params = useParams()
  const [center, setCenter] = useState({
    lat: 0,
    lng: 0,
  });
  const [places, setPlaces] = useState([]);
  const [map, setMap] = React.useState(null);
  const [coordinates, setCoordinates] = useState({
    lat: 0,
    lng: 0,
  });
  const [cc, setCC] = useState(0);
  const [trackingData, setTrackingData] = useState([]);
  useEffect(() => {
    if (!socketConnected) return;
    socketInstance.emit(
      "user-location-payer",
      { companyId: auth.user.companyId.id },
      (response) => {
        setTrackingData(response.status);
        // setCoordinates({
        //     lat: response.status.latitude,
        //     lng: response.status.longitude,
        // });
        // console.log(response.status, "useeddecr")
        setHqPoint(response.baseLoc);
        return () => {
          socketInstance.off("user-location-payer");
        };
      }
    );
  }, [cc, coordinates.lat, coordinates.lng]);
  useEffect(() => {
    setCurrentLocation();
  }, [location]);
  let bb = 0;
  useEffect(() => {
    setInterval(() => setCC(bb++), 2000);
  }, []);
  const [hqPoint, setHqPoint] = useState([]);
  const setCurrentLocation = async () => {
    setCenter({
      lat: location.baseLocation.latitude,
      lng: location.baseLocation.longitude,
    });
  };
  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  // var iconPin = {
  //     url: notWorking,
  //     scaledSize: new window.google.maps.Size(40, 40),
  // };
  var movingPin = {
    url: onGoingRide,
    scaledSize: new window.google.maps.Size(40, 40),
  };
  // var hqIcon = {
  //     url: newhomeicon,
  //     scaledSize: new window.google.maps.Size(40, 40),
  // }
  const onClickDetails = (i) => {
    // console.log(i, "details hain bhai")
  };
  return isLoaded ? (
    <>
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        zoom={15}
        onUnmount={onUnmount}
        options={{ scrollwheel: true }}
      >
        {/* {
                hqPoint.length > 0 &&
                hqPoint.map((stop, i) => {
                return(
                <>
                <Marker
                    key={i}
                    label={{text:"Headquarter", color: "#fff" , className: 'labels2'}}
                    icon={hqIcon}
                    position={{
                        lat: stop.latitude,
                        lng: stop.longitude
                    }}
                />
                </>
                )
            })
            } */}
        {
          // (allData === true || onGoing === true) &&
          trackingData.length > 0 &&
            trackingData.map((stop, i) => {
              return (
                // stop.onGoingRide === true &&
                <>
                  {/* <Marker
                        key={i}
                        label={{text:" ("+ stop.driverId.vehicleId.assetNo +") "+stop.driverId.firstName + " " + stop.driverId.lastName, color: "#fff" , className: 'labels2'}}
                        icon={movingPin}
                        position={{lat: stop.latitude,
                            lng: stop.longitude}}
                    /> */}
                  <Demo5
                    coordinates={{ lat: stop.latitude, lng: stop.longitude }}
                    icon={movingPin}
                    stop={stop}
                  />
                </>
              );
            })
        }
        {/* {
            (allData === true || notOnTrip === true) &&
            trackingData.length > 0 &&
            trackingData.map((stop, i) => {
                return(
                    stop.onGoingRide === false &&
                    <>
                    <Marker
                        key={i}
                        // title='ssssssssssss'
                        label={{text:" ("+ stop.driverId.vehicleId.assetNo +") "+stop.driverId.firstName + " " + stop.driverId.lastName, color: "#fff" , className: 'labels2'}}
                        icon={iconPin}
                        position={{lat: stop.latitude,
                            lng: stop.longitude}}
                            // onClick={()=>onClickDetails(stop)}
                            // onMouseOver={()=>onClickDetails(stop)}
                    />
                    </>
                )
            })
        } */}
      </GoogleMap>
    </>
  ) : (
    <></>
  );
};

export default ExternalCompanyTrackingMap;
