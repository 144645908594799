/** @format */

import React from "react";
import { toast, ToastContainer } from "react-toastify";

export const notifySuccess = (msg) => {
  toast.success(msg, {
    theme: "colored",
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
  return <ToastContainer />;
};

export const notifyFailure = (msg) => {
  toast.error(msg, {
    theme: "colored",
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
  return <ToastContainer />;
};

export const notifyAutoLogout = (msg) => {
  toast.warning("session timeout", {
    theme: "colored",
    position: "top-center",
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
  return <ToastContainer />;
};

export const notifyPasswordNotMatch = (msg) => {
  toast.warning("Password, don't match", {
    theme: "colored",
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
  return <ToastContainer />;
};

export const rideNotification = (msg, isVisible) => {
  toast.success(msg, {
    // theme: "colored",
    // position: "top-right",
    // autoClose: 5000,
    // hideProgressBar: true,
    // closeOnClick: true,
    // pauseOnHover: true,
    // draggable: true,
    // progress: undefined,
    position: "top-center",
    autoClose: 10000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
    // transition: Bounce,
  });
  return <ToastContainer />;
  // return isVisible && <ToastContainer />;
};
