import React, { useState, useEffect } from 'react';
import { useJsApiLoader, GoogleMap, LoadScript, Marker, DirectionsService, DirectionsRenderer } from '@react-google-maps/api';

const containerStyle = {
  width: '98%',
  height: '98%',
};

// const center = {
//   lat: 37.7749,  // Starting latitude
//   lng: -122.4194, // Starting longitude
// };

// const targetPosition = {
//   lat: 37.7849,  // Ending latitude
//   lng: -122.4294, // Ending longitude
// };

const options = {
  mapTypeControl: false,
  scrollwheel: true,
};

const RouteMap = ({center, targetPosition}) => {
  console.log(center, targetPosition, "center, targetPosition")
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyC653TecaJSucmBmqXPr9twuP_jG55NACA",
    // libraries: ["places"],
  });
  const [position, setPosition] = useState(center);
  const [directions, setDirections] = useState(null);
  const [currentStep, setCurrentStep] = useState(0);
  const [path, setPath] = useState([]);

  useEffect(() => {
    if (directions && directions.routes.length) {
      const routePath = directions.routes[0].overview_path;
      setPath(routePath);
      setPosition(routePath[0]);  // Start at the first point
    }
  }, [directions]);

  useEffect(() => {
    if (path.length > 0) {
      const intervalId = setInterval(() => {
        setCurrentStep((prevStep) => {
          const nextStep = prevStep + 1;
          if (nextStep >= path.length) {
            clearInterval(intervalId);  // Stop animation when path is complete
            return prevStep;
          }
          setPosition(path[nextStep]);
          return nextStep;
        });
      }, 100);  // Adjust the interval time for speed
      return () => clearInterval(intervalId);
    }
  }, [path]);

  const handleDirectionsCallback = (response) => {
    if (response !== null && response.status === 'OK') {
      setDirections(response);
    }
  };
  if (!isLoaded) {
    return <div>Loading...</div>;
  }

  return (
      <GoogleMap mapContainerStyle={containerStyle} center={center} zoom={14}
            options={{mapTypeControl: false, scrollwheel: true, fullscreenControl: false, streetView: false, }}
            >
        <DirectionsService
          options={{
            origin: center,
            destination: targetPosition,
            travelMode: 'DRIVING',
          }}
          callback={handleDirectionsCallback}
        />
        {/* Render Directions */}
        {directions && <DirectionsRenderer directions={directions} />}
        {/* Marker that follows the path */}
        {/* <Marker position={position} /> */}
      </GoogleMap>
  );
};

export default RouteMap;
