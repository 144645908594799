/** @format */

import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createNote } from "../../../actions/noteActions";
import { useNavigate } from "react-router-dom";

const AdminNotesAddModal = ({
  showDropDown,
  openDropDown,
  current,
  setFullData,
}) => {
  const auth = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const sockets = useSelector((state) => state.sockets);

  const { socketInstance } = sockets;
  const chicagoDateOptions = {
    timeZone: "America/Chicago",
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  };

  const chicagoDate = new Date().toLocaleDateString(
    "en-US",
    chicagoDateOptions
  );
  const parts = chicagoDate.split("/");
  const formattedChicagoDate = `${parts[2]}-${parts[0]}-${parts[1]}`;
  const [values, setValues] = useState({
    // companyId: "63cb14f53f55723694dbd185",
    title: "",
    noteText: "",
    date: "",
    createdBy: "",
  });

  const noteData = {
    companyId: auth.user.companyId.id,
    title: values.title,
    noteText: values.noteText,
    date: formattedChicagoDate,
    createdBy: auth.user.id,
  };

  // const successHandler = (studentId) => {
  //   setSelectedStudent((prev) => ({ ...prev, studentId }));
  //   openDropDown();
  // };

  const handleChange = (fieldName) => (event) => {
    setValues({
      ...values,
      [fieldName]: event.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    openDropDown();
    for (let key of Object.keys(noteData)) {
      if (noteData[key] === "") {
        delete noteData[key];
      }
    }
    dispatch(createNote(noteData, successCB));
    setValues({
      title: "",
      noteText: "",
      date: "",
      createdBy: "",
    });
  };

  const successCB = (updatedData) => {
    socketInstance?.emit("send-reminder-note", updatedData);
  };

  return (
    <>
      <div
        className={`modal fade ${showDropDown ? "show modal-y-axis" : "hide"}`}
        role="dialog"
        data-backdrop="static"
        data-keyboard="false" // To prevent closing with the keyboard
        onClick={(e) => {
          // Check if the click occurred outside the modal content
          if (e.target === e.currentTarget) {
            openDropDown(); // Call your close modal function
          }
        }}
      >
        <div
          className="modal-dialog modal-dialog-centered"
          role="document"
          style={{ top: "8%" }}
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLongTitle">
                {" "}
                Add Notes
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => openDropDown()}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-lg-6">
                  <div className="form-group my-3">
                    <label
                      className="f-14 text-dark-grey mb-12"
                      data-label=""
                      htmlFor="after_days"
                    >
                      Title
                    </label>
                    {/* <sup className="text-danger f-12">*</sup> */}
                    <input
                      type="text"
                      className="form-control height-35 f-14"
                      placeholder="Enter The Title"
                      autoComplete="false"
                      name="title"
                      value={values.title}
                      onChange={handleChange("title")}
                      //   required
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-group my-3">
                    <label
                      className="f-14 text-dark-grey mb-12"
                      data-label=""
                      htmlFor="after_days"
                    >
                      Note
                    </label>
                    {/* <sup className="text-danger f-12">*</sup> */}
                    <input
                      type="text"
                      className="form-control height-35 f-14"
                      placeholder="Enter Text"
                      autoComplete="false"
                      name="noteText"
                      value={values.noteText}
                      onChange={handleChange("noteText")}
                      //   required
                    />
                  </div>
                </div>
              </div>

              {/* <div className="col-lg-12"></div> */}
              <br />
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn-secondary rounded f-14 p-2 mr-3"
                  data-dismiss="modal"
                  onClick={() => openDropDown()}
                >
                  Close
                </button>
                <button
                  type="submit"
                  className="btn-primary rounded f-14 p-2 mr-3"
                  onClick={handleSubmit}
                >
                  Submit
                </button>
              </div>
              {/* </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default AdminNotesAddModal;
