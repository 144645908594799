/** @format */

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import {
//   createEnternalCompany,
//   getExternalCompanies,
// } from "../../../actions/companyAction";
import {
  getFaresByCompanyId,
  getPrivatesFaresByCompanyId,
  updateFareAction,
} from "../../../actions/fareActions";

const alphabets = [
  "a",
  "b",
  "c",
  "d",
  "e",
  "f",
  "g",
  "h",
  "i",
  "j",
  "k",
  "l",
  "m",
  "n",
  "o",
  "p",
  "q",
  "r",
  "s",
  "t",
  "u",
  "v",
  "w",
  "x",
  "y",
  "z",
  "A",
  "B",
  "C",
  "D",
  "E",
  "F",
  "G",
  "H",
  "I",
  "J",
  "K",
  "L",
  "M",
  "N",
  "O",
  "P",
  "Q",
  "R",
  "S",
  "T",
  "U",
  "V",
  "W",
  "X",
  "Y",
  "Z",
  "+",
  "-",
  "/",
  "*",
  "!",
  "@",
  "#",
  "$",
  "%",
  "^",
  "&",
  "_",
  "(",
  ")",
];

const AdminSettingPaymentEditFareModal = ({
  modal,
  setModal,
  setSelectedCompanyId,
}) => {
  const auth = useSelector((x) => x.auth);
  const service = useSelector((state) => state.service);
  const externalCompanies = useSelector((state) => state.externalCompanies);

  const [values, setValues] = useState({
    companyId: auth.user.companyId.id,
    name: "",
    email: "",
    phone: "",
    address: "",
    city: "",
    zipCode: "",
    driverPercentage: "",
  });

  const dispatch = useDispatch();
  // const clientData = {
  //   companyId: "63cb14f53f55723694dbd185",
  //   password: "abAl145dd",
  //   firstName: values2.firstName,
  //   lastName: values2.lastName,
  //   email: values2.email,
  //   role: "user",
  //   phone: values2.phone,
  //   workPhone: values2.workPhone,
  //   otherPhone: values2.otherPhone,
  //   clientWorkInformation: values2.clientWorkInformation,
  //   address: values2.address,
  //   // state: values2.state,
  //   city: values2.city,
  //   zip: values2.zip,
  //   summary: values2.summary,
  //   isClient: true,
  // };
  const handleSubmit = async (event) => {
    event.preventDefault();
    // console.log("first")
    // for (let key of Object.keys(clientData)) {
    //   if (clientData[key] === "") {
    //     delete clientData[key];
    //   }
    // }
    // console.log("submit==>", values);
    const tempValues = { ...values, _id: values.id };
    const { id, isCompany, companyId, serviceId, ...rest } = tempValues;
    dispatch(updateFareAction(rest, successHandler));
    // setValues2({
    //   companyId: "",
    //   firstName: "",
    //   lastName: "",
    //   email: "",
    //   password: "",
    //   phone: "",
    //   workPhone: "",
    //   otherPhone: "",
    //   role: "",
    //   clientWorkInformation: "",
    //   address: "",
    //   // state: '',
    //   city: "",
    //   zip: "",
    //   summary: "",
    //   isClient: true,
    // });
  };

  // useEffect(() => {
  //   dispatch(getExternalCompanies({ companyId: auth.user.companyId.id }));
  // }, [auth]);

  const successHandler = () => {
    setModal({ open: false });
    dispatch(getFaresByCompanyId({ companyId: auth.user.companyId.id }));
    dispatch(getPrivatesFaresByCompanyId({ companyId: auth.user.companyId.id }));
    // console.log("data aya==>", companyData);
    setValues({
      companyId: auth.user.companyId.id,
      name: "",
      email: "",
      phone: "",
      address: "",
      city: "",
      zipCode: "",
      driverPercentage: "",
    });
  };
  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

  useEffect(() => {
    if (modal.data) setValues(modal.data);
  }, [modal.data]);

  // console.log("values==>", values);
  return (
    <>
      <div
        className={`modal fade ${modal.open ? "show modal-y-axis" : "hide"}`}
        role="dialog"
        data-backdrop="static"
        data-keyboard="false" // To prevent closing with the keyboard
        onClick={(e) => {
          // Check if the click occurred outside the modal content
          if (e.target === e.currentTarget) {
            setModal({ open: false }); // Call your close modal function
          }
        }}
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLongTitle">
                {" "}
                Edit Fare
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                // onClick={() => openDropDown()}
                onClick={() => setModal({ open: false })}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <div className="modal-body">
              <div className="d-flex flex-wrap justify-content-between">
                <div className="col-lg-12 col-md-12 ntfcn-tab-content-left w-100 p-4 ">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group my-3">
                        <label
                          className="f-14 text-dark mb-12"
                          data-label=""
                          htmlFor="Name"
                        >
                          {" "}
                          Name
                        </label>
                        <sup className="text-danger f-12">*</sup>
                        <input
                          type="text"
                          className="form-control height-35 f-14"
                          placeholder="Enter Your Name"
                          name="name"
                          value={values.name}
                          onChange={handleChange}
                        />
                      </div>
                    </div>

                    {/* <div className="col-md-6">
                      <div className="form-group my-3">
                        <label
                          className="f-14 text-dark mb-12"
                          data-label=""
                          htmlFor="Name"
                        >
                          {" "}
                          Flat Base Fare
                        </label>
                        <sup className="text-danger f-12">*</sup>
                        <div className="select-picker">
                          <select
                            className="form-control select-picker"
                            style={{ height: "39px", fontSize: "14px" }}
                            name="isFlatBaseFare"
                            value={values.isFlatBaseFare}
                            onChange={handleChange}
                          >
                            <option value="false" key={"st"}>
                              {" "}
                              No
                            </option>
                            <option value="true" key={"st"}>
                              {" "}
                              Yes
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>
                    {values.isFlatBaseFare === true && (
                      <div className="col-md-6">
                        <div className="form-group my-3">
                          <label
                            className="f-14 text-dark mb-12"
                            data-label=""
                            htmlFor="Name"
                          >
                            {" "}
                            Flat Fare Price
                          </label>
                          <sup className="text-danger f-12">*</sup>
                          <input
                            type="text"
                            className="form-control height-35 f-14"
                            placeholder="Enter Flat Fare Price"
                            id="Name"
                            onKeyDown={(e) => {
                        if (e.ctrlKey && e.code === 'KeyV') {
                          return;
                        }
                              if (alphabets.includes(e.key)) {
                                e.preventDefault();
                              }
                            }}
                            name="flatBaseFare"
                            value={values.flatBaseFare}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                    )} */}
                    {/* <div className="col-md-6">
                        <div className="form-group my-3">
                          <label
                            className="f-14 text-dark mb-12"
                            data-label=""
                            htmlFor="Name"
                          >
                            {" "}
                            Fare Per Mile
                          </label>
                          <sup className="text-danger f-12">*</sup>
                          <input
                            type="text"
                            className="form-control height-35 f-14"
                            placeholder="Enter Fare Per Mile"
                            id="Name"
                            onKeyDown={(e) => {
                        if (e.ctrlKey && e.code === 'KeyV') {
                          return;
                        }
                              if (alphabets.includes(e.key)) {
                                e.preventDefault();
                              }
                            }}
                            name="farePerMile"
                            value={values.farePerMile}
                            onChange={handleChange}
                          />
                        </div>
                      </div> */}

                    <div className="col-md-6">
                      <div className="form-group my-3">
                        <label
                          className="f-14 text-dark mb-12"
                          data-label=""
                          htmlFor="Name"
                        >
                          {" "}
                          Load Fee
                        </label>
                        {/* <sup className="text-danger f-12">*</sup> */}
                        <input
                          type="text"
                          className="form-control height-35 f-14"
                          placeholder="Enter Load Fee"
                          id="Name"
                          onKeyDown={(e) => {
                            if (e.ctrlKey && e.code === "KeyV") {
                              return;
                            }
                            if (alphabets.includes(e.key)) {
                              e.preventDefault();
                            }
                          }}
                          name="flatBaseFare"
                          value={values.flatBaseFare}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group my-3">
                        <label
                          className="f-14 text-dark mb-12"
                          data-label=""
                          htmlFor="Name"
                        >
                          {" "}
                          Standard Mileage Rate
                        </label>
                        {/* <sup className="text-danger f-12">*</sup> */}
                        <input
                          type="text"
                          className="form-control height-35 f-14"
                          placeholder="Enter Standard Mileage Rate"
                          id="Name"
                          onKeyDown={(e) => {
                            if (e.ctrlKey && e.code === "KeyV") {
                              return;
                            }
                            if (alphabets.includes(e.key)) {
                              e.preventDefault();
                            }
                          }}
                          name="farePerMile"
                          value={values.farePerMile}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group my-3">
                        <label
                          className="f-14 text-dark mb-12"
                          data-label=""
                          htmlFor="Name"
                        >
                          {" "}
                          Dead Head Threshold
                        </label>
                        {/* <sup className="text-danger f-12">*</sup> */}
                        <input
                          type="text"
                          className="form-control height-35 f-14"
                          placeholder="Enter Dead Head Threshold"
                          id="Name"
                          onKeyDown={(e) => {
                            if (e.ctrlKey && e.code === "KeyV") {
                              return;
                            }
                            if (alphabets.includes(e.key)) {
                              e.preventDefault();
                            }
                          }}
                          name="deadHeadThreshold"
                          value={values.deadHeadThreshold}
                          onChange={handleChange}
                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="form-group my-3">
                        <label
                          className="f-14 text-dark mb-12"
                          data-label=""
                          htmlFor="Name"
                        >
                          {" "}
                          Deadhead Mileage Rate
                        </label>
                        {/* <sup className="text-danger f-12">*</sup> */}
                        <input
                          type="text"
                          className="form-control height-35 f-14"
                          placeholder="Enter Deadhead Mileage Rate"
                          id="Name"
                          onKeyDown={(e) => {
                            if (e.ctrlKey && e.code === "KeyV") {
                              return;
                            }
                            if (alphabets.includes(e.key)) {
                              e.preventDefault();
                            }
                          }}
                          name="baseFarePerMile"
                          value={values.baseFarePerMile}
                          onChange={handleChange}
                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="form-group my-3">
                        <label
                          className="f-14 text-dark mb-12"
                          data-label=""
                          htmlFor="Name"
                        >
                          {" "}
                          Long Distance Threshold
                        </label>
                        {/* <sup className="text-danger f-12">*</sup> */}
                        <input
                          type="text"
                          className="form-control height-35 f-14"
                          placeholder="Enter Long Distance Threshold"
                          id="Name"
                          onKeyDown={(e) => {
                            if (e.ctrlKey && e.code === "KeyV") {
                              return;
                            }
                            if (alphabets.includes(e.key)) {
                              e.preventDefault();
                            }
                          }}
                          name="totalMileageAllows"
                          value={values.totalMileageAllows}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group my-3">
                        <label
                          className="f-14 text-dark mb-12"
                          data-label=""
                          htmlFor="Name"
                        >
                          {" "}
                          Long Distance Per Mile
                        </label>
                        {/* <sup className="text-danger f-12">*</sup> */}
                        <input
                          type="text"
                          className="form-control height-35 f-14"
                          placeholder="Enter Long Distance Per Mile"
                          id="Name"
                          onKeyDown={(e) => {
                            if (e.ctrlKey && e.code === "KeyV") {
                              return;
                            }
                            if (alphabets.includes(e.key)) {
                              e.preventDefault();
                            }
                          }}
                          name="longDistancePerMile"
                          value={values.longDistancePerMile}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group my-3">
                        <label
                          className="f-14 text-dark mb-12"
                          data-label=""
                          htmlFor="Name"
                        >
                          {" "}
                          Off-hours Fee
                        </label>
                        {/* <sup className="text-danger f-12">*</sup> */}
                        <input
                          type="text"
                          className="form-control height-35 f-14"
                          placeholder="Enter Off-hours Fee"
                          id="Name"
                          onKeyDown={(e) => {
                            if (e.ctrlKey && e.code === "KeyV") {
                              return;
                            }
                            if (alphabets.includes(e.key)) {
                              e.preventDefault();
                            }
                          }}
                          name="offHourFee"
                          value={values.offHourFee}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group my-3">
                        <label
                          className="f-14 text-dark mb-12"
                          data-label=""
                          htmlFor="Name"
                        >
                          {" "}
                          No-show Fee
                        </label>
                        {/* <sup className="text-danger f-12">*</sup> */}
                        <input
                          type="text"
                          className="form-control height-35 f-14"
                          placeholder="Enter No-show Fee"
                          id="Name"
                          onKeyDown={(e) => {
                            if (e.ctrlKey && e.code === "KeyV") {
                              return;
                            }
                            if (alphabets.includes(e.key)) {
                              e.preventDefault();
                            }
                          }}
                          name="noShowFee"
                          value={values.noShowFee}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group my-3">
                        <label
                          className="f-14 text-dark mb-12"
                          data-label=""
                          htmlFor="Name"
                        >
                          {" "}
                          Cancelation Window
                        </label>
                        {/* <sup className="text-danger f-12">*</sup> */}
                        <input
                          type="text"
                          className="form-control height-35 f-14"
                          placeholder="Enter Cancelation Window"
                          id="Name"
                          onKeyDown={(e) => {
                            if (e.ctrlKey && e.code === "KeyV") {
                              return;
                            }
                            if (alphabets.includes(e.key)) {
                              e.preventDefault();
                            }
                          }}
                          name="cancelationWindow"
                          value={values.cancelationWindow}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group my-3">
                        <label
                          className="f-14 text-dark mb-12"
                          data-label=""
                          htmlFor="Name"
                        >
                          {" "}
                          Cancelation Fee
                        </label>
                        {/* <sup className="text-danger f-12">*</sup> */}
                        <input
                          type="text"
                          className="form-control height-35 f-14"
                          placeholder="Enter Cancelation Fee"
                          id="Name"
                          onKeyDown={(e) => {
                            if (e.ctrlKey && e.code === "KeyV") {
                              return;
                            }
                            if (alphabets.includes(e.key)) {
                              e.preventDefault();
                            }
                          }}
                          name="cancelationFee"
                          value={values.cancelationFee}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group my-3">
                        <label
                          className="f-14 text-dark mb-12"
                          data-label=""
                          htmlFor="Name"
                        >
                          {" "}
                          Driver Percentage
                        </label>
                        <sup className="text-danger f-12">*</sup>
                        <input
                          type="text"
                          className="form-control height-35 f-14"
                          placeholder="Enter Cancelation Fee"
                          id="Name"
                          onKeyDown={(e) => {
                            if (e.ctrlKey && e.code === "KeyV") {
                              return;
                            }
                            if (alphabets.includes(e.key)) {
                              e.preventDefault();
                            }
                          }}
                          name="driverPercentage"
                          value={values.driverPercentage}
                          onChange={handleChange}
                          required
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="row">
                <div className="col-md-6">
                  <div className="form-group my-3">
                    <label
                      className="f-14 text-dark-grey mb-12"
                      data-label=""
                      htmlFor="after_days"
                    >
                      Company Name
                    </label>
                    <sup className="text-danger f-12">*</sup>
                    <input
                      type="text"
                      className="form-control height-35 f-14"
                      placeholder="Enter Your First Name"
                      autoComplete="false"
                      value={values.name}
                      name="name"
                      onChange={handleChange}
                    />
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group my-3">
                    <label
                      className="f-14 text-dark-grey mb-12"
                      data-label=""
                      htmlFor="after_days"
                    >
                      Email
                    </label>
                    <sup className="text-danger f-12">*</sup>
                    <input
                      type="Email"
                      className="form-control height-35 f-14"
                      placeholder="Enter Your Email"
                      autoComplete="false"
                      value={values.email}
                      name="email"
                      onChange={handleChange}
                    />
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group my-3">
                    <label
                      className="f-14 text-dark-grey mb-12"
                      data-label=""
                      htmlFor="after_days"
                    >
                      Phone
                    </label>
                    <sup className="text-danger f-12">*</sup>
                    <input
                      type="tel"
                      className="form-control height-35 f-14"
                      placeholder="Enter Your Primary phone Number"
                      autoComplete="false"
                      value={values.phone}
                      name="phone"
                      onChange={handleChange}
                    />
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group my-3">
                    <label
                      className="f-14 text-dark-grey mb-12"
                      data-label=""
                      htmlFor="after_days"
                    >
                      Address
                    </label>
                    <sup className="text-danger f-12">*</sup>
                    <input
                      type="text"
                      className="form-control height-35 f-14"
                      placeholder="Enter Your Address"
                      autoComplete="false"
                      value={values.address}
                      name="address"
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group my-3">
                    <label
                      className="f-14 text-dark-grey mb-12"
                      data-label=""
                      htmlFor="after_days"
                    >
                      City
                    </label>
                    <sup className="text-danger f-12">*</sup>
                    <input
                      type="text"
                      className="form-control height-35 f-14"
                      placeholder="Enter Your City"
                      autoComplete="false"
                      value={values.city}
                      name="city"
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group my-3">
                    <label
                      className="f-14 text-dark-grey mb-12"
                      data-label=""
                      htmlFor="after_days"
                    >
                      Zip
                    </label>
                    <sup className="text-danger f-12">*</sup>
                    <input
                      type="text"
                      className="form-control height-35 f-14"
                      placeholder="Enter Your Zip"
                      autoComplete="false"
                      value={values.zipCode}
                      name="zipCode"
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </div> */}
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn-secondary rounded f-14 p-2 mr-3"
                data-dismiss="modal"
                onClick={() => setModal({ open: false })}
              >
                Close
              </button>
              <button
                type="sunmit"
                className="btn-primary rounded f-14 p-2 mr-3"
                onClick={handleSubmit}
              >
                Update
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default AdminSettingPaymentEditFareModal;
