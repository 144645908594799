import React, { useEffect } from 'react'
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { navigateUserFunc } from '../actions/generalActions';

const Unauthorized = () => {
  let navigate = useNavigate();
  const auth = useSelector((state) => state.auth);
  useEffect(() => {
    if (auth?.user !== null){
      navigateUserFunc(auth?.user, navigate)
      // navigate("/dashboard");
      // if (auth?.user?.permissionId?.dashboard?.isAllow === true) {
      //   navigate('/dashboard-admin')
      // }
      // else if (auth?.user?.permissionId?.dashboardDriver?.isAllow === true) {
      //   navigate('/dashboard-driver')
      // }
      // else if (auth?.user?.permissionId?.dashboardDispatcher?.isAllow === true) {
      //   navigate('/dashboard-dispatcher')
      // }
    }
    else {
      navigate("/login");
    }
  },[])
  return (
    <div>Unauthorized</div>
  )
}

export default Unauthorized