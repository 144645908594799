import {
    SHIFT_VIEW_REQUEST,
    SHIFT_VIEW_SUCCESS,
    SHIFT_VIEW_FAIL,
    SHIFT_CREATE_REQUEST,
    SHIFT_CREATE_SUCCESS,
    SHIFT_CREATE_FAIL,
} from "../constants"

export const shiftReducer = (
    state = {
        shift: {
            shift: null,
        }
    },
    action
) => {
        switch (action.type) {

        // SHIFT VIEW TABLE
        case SHIFT_VIEW_REQUEST : 
            return {
                ...state,
            };
        case SHIFT_VIEW_SUCCESS :
            return {
                ...state,
                shift: action.payload,
            };
        case SHIFT_VIEW_FAIL :
            return {
                ...state,
                error: action.payload,
            };

        // SHIFT CREATE 
        case SHIFT_CREATE_REQUEST :
            return {
                ...state,
            }
        case SHIFT_CREATE_SUCCESS :
            return {
                ...state,
                shift: [...state.shift, action.payload],
            }
        case SHIFT_CREATE_FAIL :
            return {
                ...state,
                error: action.payload,
            }

        default:
            return state;
    }
}