/** @format */

import {
  // RESERVATION_VIEW_REQUEST,
  // RESERVATION_VIEW_SUCCESS,
  // RESERVATION_VIEW_FAIL,
  // RESERVATION_CREATE_REQUEST,
  // RESERVATION_CREATE_SUCCESS,
  // RESERVATION_CREATE_FAIL,
  // RESERVATION_UPDATE_REQUEST,
  // RESERVATION_UPDATE_SUCCESS,
  // RESERVATION_UPDATE_FAIL,
  // DRIVER_RESERVATION_REQUEST,
  // DRIVER_RESERVATION_SUCCESS,
  // DRIVER_RESERVATION_FAIL,
  // RESERVATION_VIEW_SCHEDULE_REQUEST,
  // RESERVATION_VIEW_SCHEDULE_SUCCESS,
  // RESERVATION_VIEW_SCHEDULE_FAIL,
  // RESERVATION_DRIVER_STATUS_UPDATE_REQUEST,
  // RESERVATION_DRIVER_STATUS_UPDATE_SUCCESS,
  // RESERVATION_DRIVER_STATUS_UPDATE_FAIL,
  // DRIVER_TODAY_RESERVATION_VIEW_REQUEST,
  // DRIVER_TODAY_RESERVATION_VIEW_SUCCESS,
  // DRIVER_TODAY_RESERVATION_VIEW_FAIL,
  // DRIVER_RIDE_UPDATE_REQUEST,
  // DRIVER_RIDE_UPDATE_SUCCESS,
  // DRIVER_RIDE_UPDATE_FAIL,
  GET_INVOICES_REQUEST,
  GET_INVOICES_SUCCESS,
  GET_INVOICES_FAIL,
  GET_INVOICES_REQUEST_V2,
  GET_INVOICES_SUCCESS_V2,
  GET_INVOICES_FAIL_V2,
  GET_SEARCHED_INVOICES_REQUEST,
  GET_SEARCHED_INVOICES_SUCCESS,
  GET_SEARCHED_INVOICES_FAIL,
  VIEW_SETTLEMENT_REQUEST,
  VIEW_SETTLEMENT_SUCCESS,
  VIEW_SETTLEMENT_FAIL,
  VIEW_PAGE_SETTLEMENT_REQUEST,
  VIEW_PAGE_SETTLEMENT_SUCCESS,
  VIEW_PAGE_SETTLEMENT_FAIL,
  UPDATE_SETTLEMENT_REQUEST,
  UPDATE_SETTLEMENT_SUCCESS,
  UPDATE_SETTLEMENT_FAIL,
  DELETE_SETTLEMENT_REQUEST,
  DELETE_SETTLEMENT_SUCCESS,
  DELETE_SETTLEMENT_FAIL,
  SETTLEMENT_SEARCH_VIEW_REQUEST,
  SETTLEMENT_SEARCH_VIEW_SUCCESS,
  SETTLEMENT_SEARCH_VIEW_FAIL,
  CREATE_SETTLEMENT_REQUEST,
  CREATE_SETTLEMENT_SUCCESS,
  CREATE_SETTLEMENT_FAIL,
  SEARCHED_SETTLEMENT_UPDATED_SUCCESS,
} from "../constants";

export const invoicesReducer = (
  state = {
    loading: false,
    invoices: [],
    cardDetails: [],
    settlement: null,
    settlementDetails: null,
    searchedSettlement: null,
    reportData: [],
    notPaid: [],
    overviewTable: [],
  },
  action
) => {
  switch (action.type) {
    // RESERVATION VIEW TABLE
    case GET_INVOICES_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_INVOICES_SUCCESS:
      return {
        ...state,
        loading: false,
        invoices: action.payload.invoices,
        cardDetails: action.payload.cardDetails,
      };
    case GET_INVOICES_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    // RESERVATION VIEW TABLE WITHOUT PAGINATION
    case GET_INVOICES_REQUEST_V2:
      return {
        ...state,
        loading: true,
      };
    case GET_INVOICES_SUCCESS_V2:
      return {
        ...state,
        loading: false,
        invoices: action.payload.invoices,
        cardDetails: action.payload.cardDetails,
        driverCardDetails: action.payload.driverCardDetails,
      };
    case GET_INVOICES_FAIL_V2:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case GET_SEARCHED_INVOICES_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_SEARCHED_INVOICES_SUCCESS:
      return {
        ...state,
        loading: false,
        searchedData: action.payload,
      };
    case GET_SEARCHED_INVOICES_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case VIEW_SETTLEMENT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case VIEW_SETTLEMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        settlement: action.payload,
      };
    case VIEW_SETTLEMENT_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case VIEW_PAGE_SETTLEMENT_REQUEST:
      return {
        ...state,
        loading: true,
        settlementDetails: null,
      };
    case VIEW_PAGE_SETTLEMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        settlementDetails: action.payload,
      };
    case VIEW_PAGE_SETTLEMENT_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case UPDATE_SETTLEMENT_REQUEST:
      return {
        ...state,
        loading: true,
        // settlementDetails: null,
      };
    case UPDATE_SETTLEMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        settlement: state.settlement.map((x) =>
          x.id == action.payload.id ? action.payload : x
        ),
      };
    case UPDATE_SETTLEMENT_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case DELETE_SETTLEMENT_REQUEST:
      return {
        ...state,
        loading: true,
        // settlementDetails: null,
      };
    case DELETE_SETTLEMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        settlement: state?.settlement?.filter(
          (x) => x.id !== action.payload.id
        ),
      };
    case DELETE_SETTLEMENT_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    // SEARCH DRIVER SETTLEMENT DATA
    case SETTLEMENT_SEARCH_VIEW_REQUEST:
      return {
        ...state,
        // searchedSettlement: [],
        loading: true,
      };
    case SETTLEMENT_SEARCH_VIEW_SUCCESS:
      return {
        ...state,
        searchedSettlement: action.payload,
        loading: false,
      };
    case SETTLEMENT_SEARCH_VIEW_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

       // SEARCH DRIVER REPORT SETTLEMENT DATA
    case "SETTLEMENTREPORTS_SEARCH_VIEW_REQUEST":
      return {
        ...state,
        reportData: [],
        loading: true,
      };
    case "SETTLEMENTREPORTS_SEARCH_VIEW_SUCCESS":
      return {
        ...state,
        reportData: action.payload,
        loading: false,
      };
    case "SETTLEMENTREPORTS_SEARCH_VIEW_FAIL":
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

      // NOTPAID DRIVER SETTLEMENT DATA
    case "NOTPAID_VIEW_REQUEST":
      return {
        ...state,
        notPaid: [],
        loading: true,
      };
    case "NOTPAID_VIEW_SUCCESS":
      return {
        ...state,
        notPaid: action.payload,
        loading: false,
      };
    case "NOTPAID_VIEW_FAIL":
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    // OVERVIEW TABLE SETTLEMENT DATA
    case "OVERVIEW_TABLE_VIEW_REQUEST":
      return {
        ...state,
        // overviewTable: [],
        loading: true,
      };
    case "OVERVIEW_TABLE_VIEW_SUCCESS":
      return {
        ...state,
        overviewTable: action.payload,
        loading: false,
      };
    case "OVERVIEW_TABLE_VIEW_FAIL":
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case CREATE_SETTLEMENT_REQUEST : 
      return {
          ...state,
          loading: true,
      };
    case CREATE_SETTLEMENT_SUCCESS :
      return {
          ...state,
          loading: false,
          // settlement: [action.payload, ...state.settlement ],
      };
    case CREATE_SETTLEMENT_FAIL :
      return {
          ...state,
          loading: false,
          error: action.payload,
      };

      case SEARCHED_SETTLEMENT_UPDATED_SUCCESS:
        const updatedSettlements = action.payload.reduce((acc, newItem) => {
          const index = acc.findIndex(item => item.id === newItem.id);
          if (index !== -1) {
              acc[index] = newItem; // Update the existing item
          } else {
              acc.unshift(newItem); // Add new item to the beginning
          }
          return acc;
      }, [...state.searchedSettlement]); // Copy the existing state

      return {
          ...state,
          loading: false,
          searchedSettlement: updatedSettlements
      };
    default:
      return state;
  }
};
