import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { navigateUserFunc } from '../../actions/generalActions'
import AdminHeader from '../../components/adminComponents/adminHeaderSidebarTitleComponents/AdminHeader'
import AdminSidebar from '../../components/adminComponents/adminHeaderSidebarTitleComponents/AdminSidebar'
import AdminOtherSettingsComponent from '../../components/adminComponents/adminSettingComponents/AdminOtherSettingsComponent'

const AdminOtherSettingPage = () => {
    let navigate = useNavigate();
    // const auth = useSelector((state) => state?.auth);
    // useEffect(() => {
    //   if (auth?.user?.permissionId?.settings?.isAllow === false){
    //     navigateUserFunc(auth?.user, navigate)
    //   }
    // },[])
  return (
    <>
        <AdminHeader />
        <AdminSidebar />
        <div className="body-wrapper clearfix">
            <section className="main-container " id="fullscreen">
            <div className="px-4 py-2 border-top-0 emp-dashboard">
                <div className="d-lg-flex d-md-flex d-block py-4">
                <div>
                    <h4 className=" mb-0 f-21 text-capitalize font-weight-bold">Other Settings</h4>
                </div>
                </div>
                {/* {
                (auth?.user?.permissionId?.settings?.create === true || auth?.user?.permissionId?.settings?.update === true) ?
                <AdminSettingOffHourComponent />
                :
                <NotAllowed />
                } */}
                <AdminOtherSettingsComponent />
            </div>
            </section>
        </div>
    </>
  )
}

export default AdminOtherSettingPage