/** @format */

import React, { useEffect, useState } from "react";
import moment from "moment";
import { Link, useNavigate } from "react-router-dom";

const DriverPayRow = ({ current, i, dropDownRow, setDropDownRow }) => {
  const navigate = useNavigate();
  const [showMoreVertDropDown, setshowMoreVertDropDown] = useState(false);
  const openMoreVertDropDown = () => {
    setshowMoreVertDropDown((showMoreVertDropDown) => !showMoreVertDropDown);
  };
  return (
    <>
      <tr
        role="row"
        className="odd border"
        key={i}
        onMouseLeave={() => setshowMoreVertDropDown(false)}
      >
        <td className="border-right">{current?.reservationId?.orderId}</td>
        <td className="border-right">
          {current?.reservationId?.loadNumber}
          <i data-toggle="tooltip"></i>
        </td>
        <td className="border-right">
          <strong>
            {current?.reservationId?.driverId?.firstName}{" "}
            {current?.reservationId?.driverId?.lastName}
          </strong>
          {/* <br />
          {current?.reservationId?.driverId?.phone}
          <br /> */}
        </td>
        <td className="border-right">
          <strong>
            {current?.reservationId?.customerId?.firstName}{" "}
            {current?.reservationId?.customerId?.lastName}
          </strong>
          {/* <br />
          {current?.reservationId?.customerId?.phone}
          <br /> */}
        </td>
        <td className="border-right w-30">
          <div className="d-flex justify-content-between">
            <div>
              <div>
                <p className="f-12 font-medium mb-0">
                  <strong>
                    {current?.reservationId?.pickUpAddress?.address}
                  </strong>{" "}
                </p>
              </div>
              <div>
                <time>
                  {" "}
                  {moment(
                    current?.reservationId?.pickUpDate?.substring(0, 10)
                  ).format("MM/DD/YYYY")}{" "}
                  -{" "}
                  {parseInt(
                    current?.reservationId?.pickUpTime.substring(0, 2)
                  ) === 0
                    ? 12
                    : parseInt(
                        current?.reservationId?.pickUpTime.substring(0, 2)
                      ) > 12
                    ? parseInt(
                        current?.reservationId?.pickUpTime.substring(0, 2)
                      ) - 12
                    : parseInt(
                        current?.reservationId?.pickUpTime.substring(0, 2)
                      )}
                  {":" + current?.reservationId?.pickUpTime.substring(3, 5)}
                  {parseInt(
                    current?.reservationId?.pickUpTime.substring(0, 2)
                  ) > 11
                    ? "pm"
                    : "am"}
                </time>
              </div>
            </div>
            <div className="mt-4 px-4">
              <div className="hidden sm:block">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="50"
                  height="8"
                  viewBox="0 0 40 6"
                  fill="none"
                >
                  <path
                    d="M40 3L35 0.113249V5.88675L40 3ZM0 3.5H35.5V2.5H0V3.5Z"
                    fill="black"
                  ></path>
                </svg>
              </div>
              <p className="text-center font-medium">
                <span>
                  {current?.reservationId?.distanceFromPickToDropOff?.toFixed(
                    2
                  )}{" "}
                  mi
                </span>
              </p>
            </div>
            <div>
              <div>
                <p className="f-12 font-medium mb-0">
                  <strong>
                    {current?.reservationId?.dropOffAddress?.address}
                  </strong>
                </p>
              </div>
              <div>
                <time>
                  {" "}
                  {moment(
                    current?.reservationId?.dropOffDate?.substring(0, 10)
                  ).format("MM/DD/YYYY")}{" "}
                  -{" "}
                  {parseInt(
                    current?.reservationId?.dropOffTime.substring(0, 2)
                  ) === 0
                    ? 12
                    : parseInt(
                        current?.reservationId?.dropOffTime.substring(0, 2)
                      ) > 12
                    ? parseInt(
                        current?.reservationId?.dropOffTime.substring(0, 2)
                      ) - 12
                    : parseInt(
                        current?.reservationId?.dropOffTime.substring(0, 2)
                      )}
                  {":" + current?.reservationId?.dropOffTime.substring(3, 5)}
                  {parseInt(
                    current?.reservationId?.dropOffTime.substring(0, 2)
                  ) > 11
                    ? "pm"
                    : "am"}
                </time>
              </div>
            </div>
          </div>
        </td>
        <td className="border-right">
          <strong>
            $
            {current?.reservationId?.fareAndDistance?.driverAmount
              ? parseFloat(
                  current?.reservationId?.fareAndDistance?.driverAmount
                ).toFixed(2)
              : 0}
          </strong>
          <i data-toggle="tooltip"></i>
        </td>
        <td className="border-right">{current?.reservationId?.status}</td>
        {/* <td className="border-right">
          <button
            onClick={() =>
              navigate(`/invoices/${current?.reservationId?.orderId}`, {
                state: current,
              })
            }
            className="btn btn-sm btn-success"
          >
            View Invoice
          </button>
        </td> */}
      </tr>
    </>
  );
};
export default DriverPayRow;
