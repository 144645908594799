/** @format */

import React from "react";

function LoadingComponentRideModal({ loadingTable }) {
  return (
    <div
      className="preloader-container-new d-flex justify-content-center align-items-center"
      // style={loadingTable && { background: "#F2F4F7", height: "60vh" }}
    >
      <div className="spinner-border" role="status" aria-hidden="true"></div>
    </div>
  );
}
export default LoadingComponentRideModal;
