/** @format */

import { paginationDataToShow } from "../actions/generalActions";
import {
  RESERVATION_VIEW_REQUEST,
  RESERVATION_VIEW_SUCCESS,
  RESERVATION_VIEW_FAIL,
  RESERVATION_PAST_VIEW_REQUEST,
  RESERVATION_PAST_VIEW_SUCCESS,
  RESERVATION_PAST_VIEW_FAIL,
  RESERVATION_FUTURE_VIEW_REQUEST,
  RESERVATION_FUTURE_VIEW_SUCCESS,
  RESERVATION_FUTURE_VIEW_FAIL,
  RESERVATION_TODAY_VIEW_REQUEST,
  RESERVATION_TODAY_VIEW_SUCCESS,
  RESERVATION_TODAY_VIEW_FAIL,
  RESERVATION_VIEW_REQUEST_V2,
  RESERVATION_VIEW_SUCCESS_V2,
  RESERVATION_VIEW_FAIL_V2,
  RESERVATION_CREATE_REQUEST,
  RESERVATION_CREATE_SUCCESS,
  RESERVATION_CREATE_FAIL,
  RESERVATION_UPDATE_REQUEST,
  RESERVATION_UPDATE_SUCCESS,
  RESERVATION_UPDATE_FAIL,
  RESERVATION_DELETE_REQUEST,
  RESERVATION_DELETE_SUCCESS,
  RESERVATION_DELETE_FAIL,
  RESERVATION_COMPLETE_DELETE_REQUEST,
  RESERVATION_COMPLETE_DELETE_SUCCESS,
  RESERVATION_COMPLETE_DELETE_FAIL,
  RESERVATION_ORDERS_SEARCH_REQUEST,
  RESERVATION_ORDERS_SEARCH_SUCCESS,
  RESERVATION_ORDERS_SEARCH_FAIL,
  RESERVATION_ORDERS_SEARCH_REQUEST_V2,
  RESERVATION_ORDERS_SEARCH_SUCCESS_V2,
  RESERVATION_ORDERS_SEARCH_FAIL_V2,
  DRIVER_RESERVATION_REQUEST,
  DRIVER_RESERVATION_SUCCESS,
  DRIVER_RESERVATION_FAIL,
  RESERVATION_VIEW_SCHEDULE_REQUEST,
  RESERVATION_VIEW_SCHEDULE_SUCCESS,
  RESERVATION_VIEW_SCHEDULE_FAIL,
  RESERVATION_VIEW_SCHEDULE_REQUEST_V2,
  RESERVATION_VIEW_SCHEDULE_SUCCESS_V2,
  RESERVATION_VIEW_SCHEDULE_FAIL_V2,
  RESERVATION_DRIVER_STATUS_UPDATE_REQUEST,
  RESERVATION_DRIVER_STATUS_UPDATE_SUCCESS,
  RESERVATION_DRIVER_STATUS_UPDATE_FAIL,
  RESERVATION_DRIVER_STATUS_BY_USERID_REQUEST,
  RESERVATION_DRIVER_STATUS_BY_USERID_SUCCESS,
  RESERVATION_DRIVER_STATUS_BY_USERID_FAIL,
  DRIVER_EARNING_REQUEST,
  DRIVER_EARNING_SUCCESS,
  DRIVER_EARNING_FAIL,
  DRIVER_TODAY_RESERVATION_VIEW_REQUEST,
  DRIVER_TODAY_RESERVATION_VIEW_SUCCESS,
  DRIVER_TODAY_RESERVATION_VIEW_FAIL,
  DRIVER_RIDE_UPDATE_REQUEST,
  DRIVER_RIDE_UPDATE_SUCCESS,
  DRIVER_RIDE_UPDATE_FAIL,
  DRIVER_PAST_RESERVATION_VIEW_REQUEST,
  DRIVER_PAST_RESERVATION_VIEW_SUCCESS,
  DRIVER_PAST_RESERVATION_VIEW_FAIL,
  TRIP_CREATE_REQUEST,
  TRIP_CREATE_SUCCESS,
  RESERVATION_REQUEST,
  RESERVATION_SUCCESS,
  RESERVATION_FAIL,
  RESERVATION_UPDATE_REQUEST_ST,
  RESERVATION_UPDATE_SUCCESS_ST,
  RESERVATION_UPDATE_FAIL_ST,
} from "../constants";

export const reservationReducer = (
  state = {
    reservation: {
      reservation: null,
      pastDetails: null,
      // today: null,
      // previous: null,
      // assigned: null,
      // unassigned: null,
      // pending: null,
      // newRequest: null,
      // history: null,
      pastHistory: null,
      reservationsSchedule: null,
      todayReservationView: null,
      markedLocation: null,
      reservationRequest: null,
      userNotificationList: null,
      driverEarning: null,
      getReservationsByDate: null,
      driverBookingStatus: null,
      reservationDetails: null,
      from: null,
      to: null,
      loading: false,
    },
  },
  action
) => {
  switch (action.type) {
    // RESERVATION VIEW TABLE
    // case RESERVATION_VIEW_REQUEST:
    //   return {
    //     ...state,
    //     loading: true,
    //   };
    // case RESERVATION_VIEW_SUCCESS:
    //   return {
    //     ...state,
    //     loading: false,
    //     reservation: action.payload,
    //     // today: action.payload.today,
    //     // previous: action.payload.previous,
    //   };
    // case RESERVATION_VIEW_FAIL:
    //   return {
    //     ...state,
    //     loading: false,
    //     error: action.payload,
    //   };

    // RESERVATION VIEW TABLE
    case RESERVATION_VIEW_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case RESERVATION_VIEW_SUCCESS:
      return {
        ...state,
        loading: false,
        reservation: action.payload,
        // today: action.payload.today,
        // previous: action.payload.previous,
        // previous: paginationDataToShow(5, 1, action.payload.previous.results),
      };
    case RESERVATION_VIEW_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    // RESERVATION VIEW TABLE
    case RESERVATION_VIEW_REQUEST_V2:
      return {
        ...state,
        loading: true,
      };
    case RESERVATION_VIEW_SUCCESS_V2:
      return {
        ...state,
        loading: false,
        reservation: action.payload,
        // today: action.payload.today,
        // previous: action.payload.previous,
        // previous: paginationDataToShow(5, 1, action.payload.previous.results),
      };
    case RESERVATION_VIEW_FAIL_V2:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    // RESERVATION VIEW TABLE
    case RESERVATION_PAST_VIEW_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case RESERVATION_PAST_VIEW_SUCCESS:
      return {
        ...state,
        loading: false,
        reservation: { ...state.reservation, previous: action.payload.results },
        pastDetails: action.payload,
      };
    case RESERVATION_PAST_VIEW_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case RESERVATION_FUTURE_VIEW_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case RESERVATION_FUTURE_VIEW_SUCCESS:
      return {
        ...state,
        loading: false,
        reservation: { ...state.reservation, future: action.payload },
      };
    case RESERVATION_FUTURE_VIEW_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    // RESERVATION VIEW TABLE
    case RESERVATION_TODAY_VIEW_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case RESERVATION_TODAY_VIEW_SUCCESS:
      return {
        ...state,
        loading: false,
        reservation: { ...state.reservation, today: action.payload },
      };
    case RESERVATION_TODAY_VIEW_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    // RESERVATION CREATE
    case RESERVATION_CREATE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case RESERVATION_CREATE_SUCCESS:
      return {
        ...state,
        loading: false
        // reservationsSchedule: {...state.reservationsSchedule, unassigned:[action.payload, ...state.reservationsSchedule.unassigned]},
        // reservationsSchedule: {
        //   ...state.reservationsSchedule,
        //   unassigned: {
        //     ...state.unassigned,
        //     results: state.unassigned.results.map((x) =>
        //       x._id === action.payload.id ? action.payload : x
        //     ),
        //   },
        //   assigned: {
        //     ...state.assigned,
        //     results: state.assigned.results.map((x) =>
        //       x._id === action.payload.id ? action.payload : x
        //     ),
        //   },
        // },
        // unassigned: {
        //   ...state.unassigned,
        //   results: [action.payload, ...state.unassigned.results],
        // },
      };
    case RESERVATION_CREATE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    // case RESERVATION_UPDATE_REQUEST:
    //   return {
    //     ...state,
    //   };
    // case RESERVATION_UPDATE_SUCCESS:
    //   return {
    //     ...state,
    //     // policyTable: [...state.policyTable, action.payload],
    //     // reservation: {...state.reservation, results:state.reservation.results.map((x) =>
    //     //     x.id === action.payload.id ? action.payload : x
    //     // )},
    //     reservationsSchedule: {
    //       ...state.reservationsSchedule,
    //       unassigned: {
    //         ...state.unassigned,
    //         results: state.unassigned.results.map((x) =>
    //           x._id === action.payload.id ? action.payload : x
    //         ),
    //       },
    //       assigned: {
    //         ...state.assigned,
    //         results: state.assigned.results.map((x) =>
    //           x._id === action.payload.id ? action.payload : x
    //         ),
    //       },
    //     },
    //     unassigned: {
    //       ...state.unassigned,
    //       results: state.unassigned.results.map((x) =>
    //         x._id === action.payload.id ? action.payload : x
    //       ),
    //     },
    //     assigned: {
    //       ...state.assigned,
    //       results: state.assigned.results.map((x) =>
    //         x._id === action.payload.id ? action.payload : x
    //       ),
    //     },
    //     // reservation: action.payload
    //   };
    // case RESERVATION_UPDATE_FAIL:
    //   return {
    //     ...state,
    //     error: action.payload,
    //   };
    // case RESERVATION_DELETE_REQUEST:
    //   return {
    //     ...state,
    //   };
    // case RESERVATION_DELETE_SUCCESS:
    //   return {
    //     ...state,
    //     reservationsSchedule: {
    //       ...state.reservationsSchedule,
    //       unassigned: {
    //         ...state.unassigned,
    //         results: state.unassigned.results.filter(
    //           (x) => x._id !== action.payload.id
    //         ),
    //       },
    //       assigned: {
    //         ...state.assigned,
    //         results: state.assigned.results.filter(
    //           (x) => x._id !== action.payload.id
    //         ),
    //       },
    //     },
    //     unassigned: {
    //       ...state.unassigned,
    //       results: state.unassigned.results.filter(
    //         (x) => x._id !== action.payload.id
    //       ),
    //     },
    //     assigned: {
    //       ...state.assigned,
    //       results: state.assigned.results.filter(
    //         (x) => x._id !== action.payload.id
    //       ),
    //     },
    //     reservation: {
    //       ...state.reservationsSchedule,
    //       today: {
    //         ...state.today,
    //         results: state.today.results.filter(
    //           (x) => x._id !== action.payload.id
    //         ),
    //       },
    //     },
    //     today: {
    //       ...state.today,
    //       results: state.today.results.filter(
    //         (x) => x._id !== action.payload.id
    //       ),
    //     },
    //     // reservation: action.payload
    //     reservation: {
    //       ...state.reservationsSchedule,
    //       today: {
    //         ...state.today,
    //         results: state.today.results.filter(
    //           (x) => x._id !== action.payload.id
    //         ),
    //       },
    //     },
    //     today: {
    //       ...state.today,
    //       results: state.today.results.filter(
    //         (x) => x._id !== action.payload.id
    //       ),
    //     },
    //   };
    // case RESERVATION_DELETE_FAIL:
    //   return {
    //     ...state,
    //     error: action.payload,
    //   };

    // RESERVATION VIEW TABLE
    // case DRIVER_RESERVATION_REQUEST :
    //     return {
    //         ...state,
    //     };
    // case DRIVER_RESERVATION_SUCCESS :
    //     return {
    //         ...state,
    //         newRequest: action.payload.newRequest,
    //         history: action.payload.history,
    //     };
    // case DRIVER_RESERVATION_FAIL :
    //     return {
    //         ...state,
    //         error: action.payload,
    //     };
    // case DRIVER_RESERVATION_REQUEST:
    //   return {
    //     ...state,
    //     loading: true,
    //   };
    // case DRIVER_RESERVATION_SUCCESS:
    //   return {
    //     ...state,
    //     loading: false,
    //     reservationRequest: action.payload,
    //   };
    // case DRIVER_RESERVATION_FAIL:
    //   return {
    //     ...state,
    //     loading: false,
    //     error: action.payload,
    //   };
    case RESERVATION_UPDATE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case RESERVATION_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        reservation: {
          ...state.reservation,
          today: state.reservation.today.map((x) =>
            x._id === action.payload.id ? { ...action.payload, _id: x._id } : x
          ),
          future: state.reservation.future.map((x) =>
            x._id === action.payload.id ? { ...action.payload, _id: x._id } : x
          ),
          previous: state.reservation.previous.map((x) =>
            x._id === action.payload.id || x.id === action.payload.id
              ? { ...action.payload, _id: x._id }
              : x
          ),
        },
        // ...state,
        getReservationsByDateV2: state?.getReservationsByDateV2?.map((x) =>
          x._id === action.payload.id ? { ...action.payload, _id: x._id } : x
        ),

        // reservation: {...state.reservation, results:state.reservation.results.map((x) =>
        //     x.id === action.payload.id ? action.payload : x
        // )},

        // old
        // reservationsSchedule: {
        //     ...state.reservationsSchedule,
        //     unassigned: {
        //         ...state.unassigned,
        //         results: state.unassigned.results.map((x) =>
        //             x._id === action.payload.id ? action.payload : x
        //         )
        //     },
        //     assigned: {
        //         ...state.assigned,
        //         results: state.assigned.results.map((x) =>
        //             x._id === action.payload.id ? action.payload : x
        //         )
        //     },
        // },
        // unassigned: {
        //     ...state.unassigned,
        //     results: state.unassigned.results.map((x) =>
        //         x._id === action.payload.id ? action.payload : x
        //     )
        // },
        // assigned: {
        //     ...state.assigned,
        //     results: state.assigned.results.map((x) =>
        //         x._id === action.payload.id ? action.payload : x
        //     )
        // },

        // reservationsSchedule: {
        //   ...state.reservationsSchedule,
        //   unassigned: state.unassigned.map((x) =>
        //     x._id === action.payload.id ? action.payload : x
        //   ),
        //   assigned: state.assigned.map((x) =>
        //     x._id === action.payload.id ? action.payload : x
        //   ),
        //   pending: state.pending.map((x) =>
        //     x._id === action.payload.id ? action.payload : x
        //   ),
        // },

        // unassigned: state.unassigned.map((x) =>
        //   x._id === action.payload.id ? action.payload : x
        // ),
        // assigned: state.assigned.map((x) =>
        //   x._id === action.payload.id ? action.payload : x
        // ),
        // pending: state.pending.map((x) =>
        //   x._id === action.payload.id ? action.payload : x
        // ),
        // assigned: state.assigned.map((x) =>
        //     x._id === action.payload.id ? action.payload : x
        // ),

        // reservation: action.payload
      };
    case RESERVATION_UPDATE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case RESERVATION_UPDATE_REQUEST_ST:
      return {
        ...state,
        loading: true
      };
    case RESERVATION_UPDATE_SUCCESS_ST:
      return {
        ...state,
        loading: false,
        reservation: {
          ...state.reservation,
          today: state.reservation.today.map((x) =>
            x._id === action.payload.reservationId
              ? {
                  ...x,
                  driverId: action.payload.driverId,
                  status: "Requested",
                  _id: x._id,
                }
              : x
          ),
          future: state.reservation.future.map((x) =>
            x._id === action.payload.reservationId
              ? {
                  ...x,
                  driverId: action.payload.driverId,
                  status: "Requested",
                  _id: x._id,
                }
              : x
          ),
          previous: state.reservation.previous.map((x) =>
            x._id === action.payload.reservationId
              ? {
                  ...x,
                  driverId: action.payload.driverId,
                  status: "Requested",
                  _id: x._id,
                }
              : x
          ),
        },
      };
    case RESERVATION_UPDATE_FAIL_ST:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case RESERVATION_DELETE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case RESERVATION_DELETE_SUCCESS:
      return {
        ...state,
        loading: false,
        // reservationsSchedule: {
        //     ...state.reservationsSchedule,
        //     unassigned: {
        //         ...state.unassigned,
        //         results: state.unassigned.results.filter((x) => x._id !== action.payload.id)
        //     },
        //     assigned: {
        //         ...state.assigned,
        //         results: state.assigned.results.filter((x) => x._id !== action.payload.id)
        //     },
        // },
        // unassigned: {
        //     ...state.unassigned,
        //     results: state.unassigned.results.filter((x) => x._id !== action.payload.id)
        // },
        // assigned: {
        //     ...state.assigned,
        //     results: state.assigned.results.filter((x) => x._id !== action.payload.id)
        // },
        // reservation: {
        //   ...state.reservation,
        //   today: state.today.filter((x) => x._id !== action.payload.id),
        // },
        // reservation: {
        //   ...state.reservation,
        //   today: state.reservation.today.filter(
        //     (x) => x._id !== action.payload.id
        //   ),
        //   future: state.reservation.future.filter(
        //     (x) => x._id !== action.payload.id
        //   ),
        //   previous: state.reservation.previous.filter(
        //     (x) => x._id !== action.payload.id
        //   ),
        // },
        // reservation: {
        //   ...state.reservation,
        //   today: state.reservation.today.map((x) =>
        //     x._id === action.payload.id ? action.payload : x
        //   ),
        //   future: state.reservation.future.map((x) =>
        //     x._id === action.payload.id ? action.payload : x
        //   ),
        //   previous: state.reservation.previous.map((x) =>
        //     x._id === action.payload.id ? action.payload : x
        //   ),
        // },
        reservation: {
          ...state.reservation,
          today: state?.reservation?.today?.map((x) =>
            x._id === action.payload.id || x.id === action.payload.id
              ? { ...action.payload, _id: x._id }
              : x
          ),
          future: state?.reservation?.future?.map((x) =>
            x._id === action.payload.id || x.id === action.payload.id
              ? { ...action.payload, _id: x._id }
              : x
          ),
          previous: state?.reservation?.previous?.map((x) =>
            x._id === action.payload.id || x.id === action.payload.id
              ? { ...action.payload, _id: x._id }
              : x
          ),
        },
        // ...state,
        getReservationsByDateV2: state?.getReservationsByDateV2?.map((x) =>
          x._id === action.payload.id ? { ...action.payload, _id: x._id } : x
        ),

        // today: state.today.filter((x) => x._id !== action.payload.id),
        // reservation: {
        //     ...state.reservationsSchedule,
        //     today: {
        //         ...state.today,
        //         results: state.today.results.filter((x) => x._id !== action.payload.id)
        //     },
        // },

        // old
        // reservationsSchedule: {
        //     ...state.reservationsSchedule,
        //     unassigned: {
        //         ...state.unassigned,
        //         results: state.unassigned.results.filter((x) => x._id !== action.payload.id)
        //     },
        //     assigned: {
        //         ...state.assigned,
        //         results: state.assigned.results.filter((x) => x._id !== action.payload.id)
        //     },
        // },
        // unassigned: {
        //     ...state.unassigned,
        //     results: state.unassigned.results.filter((x) => x._id !== action.payload.id)
        // },
        // assigned: {
        //     ...state.assigned,
        //     results: state.assigned.results.filter((x) => x._id !== action.payload.id)
        // },
        // reservation: {
        //     ...state.reservationsSchedule,
        //     today: {
        //         ...state.today,
        //         results: state.today.results.filter((x) => x._id !== action.payload.id)
        //     },
        // },
        // today: {
        //     ...state.today,
        //     results: state.today.results.filter((x) => x._id !== action.payload.id)
        // },
        // reservation: {
        //     ...state.reservationsSchedule,
        //     today: {
        //         ...state.today,
        //         results: state.today.results.filter((x) => x._id !== action.payload.id)
        //     },
        // },
        // today: {
        //     ...state.today,
        //     results: state.today.results.filter((x) => x._id !== action.payload.id)
        // },
      };
    case RESERVATION_DELETE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case RESERVATION_COMPLETE_DELETE_REQUEST:
      return {
        ...state,
      };
    case RESERVATION_COMPLETE_DELETE_SUCCESS:
      return {
        ...state,
        reservation: {
          ...state.reservation,
          today: state?.reservation?.today?.filter(
            (x) => x._id === action.payload.id || x.id === action.payload.id
          ),
          future: state?.reservation?.future?.filter(
            (x) => x._id === action.payload.id || x.id === action.payload.id
          ),
          previous: state?.reservation?.previous?.filter(
            (x) => x._id === action.payload.id || x.id === action.payload.id
          ),
        },
        // ...state,
        getReservationsByDateV2: state?.getReservationsByDateV2?.filter(
          (x) => x._id !== action.payload.id
        ),
      };
    case RESERVATION_COMPLETE_DELETE_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    // RESERVATION VIEW TABLE
    // case DRIVER_RESERVATION_REQUEST :
    //     return {
    //         ...state,
    //     };
    // case DRIVER_RESERVATION_SUCCESS :
    //     return {
    //         ...state,
    //         newRequest: action.payload.newRequest,
    //         history: action.payload.history,
    //     };
    // case DRIVER_RESERVATION_FAIL :
    //     return {
    //         ...state,
    //         error: action.payload,
    //     };
    case DRIVER_RESERVATION_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case DRIVER_RESERVATION_SUCCESS:
      return {
        ...state,
        loading: false,
        reservationRequest: action.payload,
      };
    case DRIVER_RESERVATION_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case RESERVATION_VIEW_SCHEDULE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case RESERVATION_VIEW_SCHEDULE_SUCCESS:
      return {
        ...state,
        loading: false,
        reservationsSchedule: action.payload,
        assigned: action.payload.assigned,
        unassigned: action.payload.unassigned,
        pending: action.payload.pending,
      };
    case RESERVATION_VIEW_SCHEDULE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    // RESERVATION_VIEW_SCHEDULE_REQUEST_V2
    case RESERVATION_VIEW_SCHEDULE_REQUEST_V2:
      return {
        ...state,
        loading: true,
      };
    case RESERVATION_VIEW_SCHEDULE_SUCCESS_V2:
      return {
        ...state,
        loading: false,
        reservationsSchedule: action.payload,
        assigned: action.payload.assigned,
        unassigned: action.payload.unassigned,
        pending: action.payload.pending,
      };
    case RESERVATION_VIEW_SCHEDULE_FAIL_V2:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case RESERVATION_VIEW_SCHEDULE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case RESERVATION_VIEW_SCHEDULE_SUCCESS:
      return {
        ...state,
        loading: false,
        reservationsSchedule: action.payload,
        assigned: action.payload.assigned,
        unassigned: action.payload.unassigned,
        pending: action.payload.pending,
      };
    case RESERVATION_VIEW_SCHEDULE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case "SET_NEWREQUEST":
      return {
        ...state,
        newRequest: action.payload,
      };

    // RESERVATION STATUS UPDATE BY RESERVATION ID
    case RESERVATION_DRIVER_STATUS_UPDATE_REQUEST:
      return {
        ...state,
      };
    case RESERVATION_DRIVER_STATUS_UPDATE_SUCCESS:
      return {
        ...state,
        reservationRequest: {
          ...state.reservationRequest,
          results: state.reservationRequest.results
            .map((x) =>
              x.id === action.payload.reservationId &&
              action.payload.driverBookingStatus === "Assigned"
                ? {
                    ...x,
                    driverBookingStatus: "Assigned",
                    isAssigned: true,
                    status: "Scheduled",
                  }
                : x
            )
            .filter(
              (x) =>
                !(
                  x.id === action.payload.reservationId &&
                  action.payload.driverBookingStatus === "Unassigned"
                )
            ),
        },
      };
    case RESERVATION_DRIVER_STATUS_UPDATE_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    // // ADMIN RESERVATION ORDERS SEARCH
    // case RESERVATION_ORDERS_SEARCH_REQUEST:
    //   return {
    //     ...state,
    //     loading: true,
    //   };
    // case RESERVATION_ORDERS_SEARCH_SUCCESS:
    //   return {
    //     ...state,
    //     loading: false,
    //     getReservationsByDate: action.payload,
    //   };
    // case RESERVATION_ORDERS_SEARCH_FAIL:
    //   return {
    //     ...state,
    //     loading: false,
    //     error: action.payload,
    //   };

    // ADMIN RESERVATION ORDERS SEARCH
    case RESERVATION_ORDERS_SEARCH_REQUEST_V2:
      return {
        ...state,
        loading: true,
        getReservationsByDateV2: null,
      };
    case RESERVATION_ORDERS_SEARCH_SUCCESS_V2:
      return {
        ...state,
        loading: false,
        getReservationsByDateV2: action.payload,
      };
    case RESERVATION_ORDERS_SEARCH_FAIL_V2:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case "SET_RESERVATIONS":
      return {
        ...state,
        reservation: action.payload,
        assigned: action.payload.assigned,
        unassigned: action.payload.unassigned,
      };

    // RESERVATION VIEW TABLE
    case DRIVER_TODAY_RESERVATION_VIEW_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case DRIVER_TODAY_RESERVATION_VIEW_SUCCESS:
      return {
        ...state,
        loading: false,
        todayReservationView: action.payload,
      };
    case DRIVER_TODAY_RESERVATION_VIEW_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    // TRIP UPDATE
    case DRIVER_RIDE_UPDATE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case DRIVER_RIDE_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        reservationDetails: {
          ...state.reservationDetails,
          status:
            action.payload.tripStatus === "Completed"
              ? "Completed"
              : action.payload.tripCancel === "No Show"
              ? "No Show"
              : action.payload.tripStatus === "Towards Customer"
              ? "In Progress"
              : "In Progress",
          //  x.status,
          tripStatus: action.payload.tripStatus,
          trip: action.payload,
        },
        todayReservationView: {
          ...state.todayReservationView,
          ...(state.todayReservationView && {
            results:
              state.todayReservationView.results &&
              state.todayReservationView.results.length > 0 &&
              state.todayReservationView.results.map((x) =>
                x.trip?.id === action.payload.id
                  ? { reservation: x.reservation, trip: action.payload }
                  : x
              ),
          }),
        },
        // today: {
        //   ...state.reservation.today,
        //   ...(state.reservation.today && {
        //     results: state.reservation.today.results.map((x) =>
        //       x?._id === action.payload.reservationId
        //         ? { ...x, tripStatus: "Towards Customer", trip: action.payload }
        //         : x
        //     ),
        //   }),
        // },
        // previous: {
        //   ...state.reservation.previous,
        //   ...(state.reservation.previous && {
        //     results: state.reservation.previous.results.map((x) =>
        //       x?._id === action.payload.reservationId
        //         ? { ...x, tripStatus: "Towards Customer", trip: action.payload }
        //         : x
        //     ),
        //   }),
        // },

        reservation: {
          ...state.reservation,
          today: state.reservation.today.map((x) =>
            x?._id === action.payload.reservationId
              ? {
                  ...x,
                  // tripStatus: "Towards Customer",
                  status:
                    action.payload.tripStatus === "Completed"
                      ? "Completed"
                      : action.payload.tripCancel === "No Show"
                      ? "No Show"
                      : action.payload.tripStatus === "Towards Customer"
                      ? "In Progress"
                      : "In Progress",
                  //  x.status ,
                  tripStatus: action.payload.tripStatus,
                  trip: action.payload,
                }
              : x
          ),
          previous: state.reservation.previous.map((x) =>
            x?._id === action.payload.reservationId
              ? {
                  ...x,
                  // tripStatus: "Towards Customer",
                  status:
                    action.payload.tripStatus === "Completed"
                      ? "Completed"
                      : action.payload.tripCancel === "No Show"
                      ? "No Show"
                      : action.payload.tripStatus === "Towards Customer"
                      ? "In Progress"
                      : "In Progress",
                  //  x.status ,
                  tripStatus: action.payload.tripStatus,
                  trip: action.payload,
                }
              : x
          ),
          future: state.reservation.future.map((x) =>
            x?._id === action.payload.reservationId
              ? {
                  ...x,
                  // tripStatus: "Towards Customer",
                  status:
                    action.payload.tripStatus === "Completed"
                      ? "Completed"
                      : action.payload.tripCancel === "No Show"
                      ? "No Show"
                      : action.payload.tripStatus === "Towards Customer"
                      ? "In Progress"
                      : "In Progress",
                  //  x.status ,
                  tripStatus: action.payload.tripStatus,
                  trip: action.payload,
                }
              : x
          ),
        },
        // reservation: {
        //   ...state.reservation,
        //   today: {
        //     ...state.reservation.today,
        //     ...(state.reservation.today && {
        //       results: state.reservation.today.results.map((x) =>
        //         x?._id === action.payload.reservationId
        //           ? {
        //               ...x,
        //               tripStatus: "Towards Customer",
        //               trip: action.payload,
        //             }
        //           : x
        //       ),
        //     }),
        //   },
        //   previous: {
        //     ...state.reservation.previous,
        //     ...(state.reservation.previous && {
        //       results: state.reservation.previous.results.map((x) =>
        //         x?._id === action.payload.reservationId
        //           ? {
        //               ...x,
        //               tripStatus: "Towards Customer",
        //               trip: action.payload,
        //             }
        //           : x
        //       ),
        //     }),
        //   },
        // },
        // ]
        // todayReservationView: [action.payload.id]
        // readTrip: action.payload,
      };
    case DRIVER_RIDE_UPDATE_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    case TRIP_CREATE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case TRIP_CREATE_SUCCESS:
      return {
        ...state,
        loading: false,
        reservationDetails: {
          ...state.reservationDetails,
          status: "In Progress",
          tripStatus: action.payload.tripStatus,
          trip: action.payload,
        },
        todayReservationView: {
          ...state.todayReservationView,
          ...(state.todayReservationView && {
            results:
              state.todayReservationView.results &&
              state.todayReservationView.results.length > 0 &&
              state.todayReservationView.results.map((x) =>
                x.reservation?.id === action.payload.reservationId
                  ? { reservation: x.reservation, trip: action.payload }
                  : x
              ),
          }),
        },
        // today: {
        //   ...state.reservation.today,
        //   ...(state.reservation.today && {
        //     results: state.reservation.today.results.map((x) =>
        //       x?._id === action.payload.reservationId
        //         ? { ...x, tripStatus: "Towards Customer", trip: action.payload }
        //         : x
        //     ),
        //   }),
        // },
        // previous: {
        //   ...state.reservation.previous,
        //   ...(state.reservation.previous && {
        //     results: state.reservation.previous.results.map((x) =>
        //       x?._id === action.payload.reservationId
        //         ? { ...x, tripStatus: "Towards Customer", trip: action.payload }
        //         : x
        //     ),
        //   }),
        // },

        reservation: {
          ...state.reservation,
          today: state.reservation.today.map((x) =>
            x?._id === action.payload.reservationId
              ? {
                  ...x,
                  status: "In Progress",
                  tripStatus: "Towards Customer",
                  trip: action.payload,
                }
              : x
          ),
          previous: state.reservation.previous.map((x) =>
            x?._id === action.payload.reservationId
              ? {
                  ...x,
                  status: "In Progress",
                  tripStatus: "Towards Customer",
                  trip: action.payload,
                }
              : x
          ),
          future: state.reservation.future.map((x) =>
            x?._id === action.payload.reservationId
              ? {
                  ...x,
                  status: "In Progress",
                  tripStatus: "Towards Customer",
                  trip: action.payload,
                }
              : x
          ),
        },
        // reservation: {
        //   ...state.reservation,
        //   today: {
        //     ...state.reservation.today,
        //     ...(state.reservation.today && {
        //       results: state.reservation.today.results.map((x) =>
        //         x?._id === action.payload.reservationId
        //           ? {
        //               ...x,
        //               tripStatus: "Towards Customer",
        //               trip: action.payload,
        //             }
        //           : x
        //       ),
        //     }),
        //   },
        //   previous: {
        //     ...state.reservation.previous,
        //     ...(state.reservation.previous && {
        //       results: state.reservation.previous.results.map((x) =>
        //         x?._id === action.payload.reservationId
        //           ? {
        //               ...x,
        //               tripStatus: "Towards Customer",
        //               trip: action.payload,
        //             }
        //           : x
        //       ),
        //     }),
        //   },
        // },
        // today: {...state.today,
        //     results: state.today.results.map((x) =>
        //         x?.id === action.payload.reservationId ? {...x, trip:action.payload} : x
        //     )},
        // previous: {...state.previous,
        //         results:state.previous.results.map((x) =>
        //         x?.id === action.payload.reservationId ? {...x, trip:action.payload} : x
        //     )},
        // reservation: {
        //     ...state.reservation,
        //     today: {...state.today,
        //         results: state.today.results.map((x) =>
        //             x?.id === action.payload.reservationId ? {...x, trip:action.payload} : x
        //         )},
        //     previous: {...state.previous,
        //         results:state.previous.results.map((x) =>
        //         x?.id === action.payload.reservationId ? {...x, trip:action.payload} : x
        //     )},
        // },
      };
    case "MARKED_LOCATION":
      return {
        ...state,
        markedLocation: action.payload,
      };

    case DRIVER_PAST_RESERVATION_VIEW_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case DRIVER_PAST_RESERVATION_VIEW_SUCCESS:
      return {
        ...state,
        loading: false,
        pastHistory: action.payload,
      };
    case DRIVER_PAST_RESERVATION_VIEW_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case RESERVATION_DRIVER_STATUS_BY_USERID_REQUEST:
      return {
        ...state,
      };
    case RESERVATION_DRIVER_STATUS_BY_USERID_SUCCESS:
      return {
        ...state,
        userNotificationList: action.payload,
      };
    case RESERVATION_DRIVER_STATUS_BY_USERID_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case DRIVER_EARNING_REQUEST:
      return {
        ...state,
      };
    case DRIVER_EARNING_SUCCESS:
      return {
        ...state,
        driverEarning: action.payload,
      };
    case DRIVER_EARNING_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    case "SELECT_DATE":
      return {
        ...state,
        from: action.payload.from,
        to: action.payload.to,
        driverBookingStatus: action.payload.driverBookingStatus
          ? action.payload.driverBookingStatus
          : "",
      };

    // RESERVATION AND TRIP BY ID
    case RESERVATION_REQUEST:
      return {
        ...state,
        loading: true,
        reservationDetails: null,
      };
    case RESERVATION_SUCCESS:
      return {
        ...state,
        loading: false,
        reservationDetails: action.payload,
      };
    case RESERVATION_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case "UNDO_START_ACTION":
      return {
        ...state,
        reservation: {
          ...state.reservation,
          today: state.reservation.today.map((x) =>
            x._id === action.payload.reservationId
              ? {
                  ...x,
                  status: "Scheduled",
                }
              : x
          ),
          future: state.reservation.future.map((x) =>
            x._id === action.payload.reservationId
              ? {
                  ...x,
                  status: "Scheduled",
                }
              : x
          ),
          previous: state.reservation.previous.map((x) =>
            x._id === action.payload.reservationId
              ? {
                  ...x,
                  status: "Scheduled",
                }
              : x
          ),
        },
        reservationDetails: {
          ...state.reservationDetails,
          status: "Scheduled",
          // tripStatus: action.payload.tripStatus,
          // trip: action.payload,
          trip: null,
        },
      };
    case "UNDO_ARRIVED_ACTION":
      return {
        ...state,
        reservation: {
          ...state.reservation,
          today: state.reservation.today.map((x) =>
            x._id === action.payload.reservationId
              ? {
                  ...x,
                  status: "In Progress",
                }
              : x
          ),
          future: state.reservation.future.map((x) =>
            x._id === action.payload.reservationId
              ? {
                  ...x,
                  status: "In Progress",
                }
              : x
          ),
          previous: state.reservation.previous.map((x) =>
            x._id === action.payload.reservationId
              ? {
                  ...x,
                  status: "In Progress",
                }
              : x
          ),
        },
        reservationDetails: {
          ...state.reservationDetails,
          status: "In Progress",
          trip: action.payload,
        },
      };
    default:
      return state;
  }
};
