/** @format */

import moment from "moment";
import React from "react";
import { useSelector } from "react-redux";
import { Link, NavLink } from "react-router-dom";

const NotificationDropDown = ({ data }) => {
  const auth = useSelector((state) => state.auth);
  // console.log("data-->", data);
  return (
    <>
      {auth.user.jobTitle === "Driver" && (
        <div
          className="dropdown-menu dropdown-menu-right notification-dropdown shadow-lg py-0 bg-additional-grey"
          tabIndex="0"
        >
          <div className="d-flex px-3 justify-content-between align-items-center border-bottom-grey py-1 bg-white">
            <div className="___class_+?50___">
              <div className="f-14 mb-0 text-dark f-w-500">
                <h4>Notifications</h4>
              </div>
            </div>
          </div>
          <div id="notification-list"></div>
          <ul className="notification-list">
            {data.map((item, index) => {
              return (
                <li
                  className="notification-message"
                  key={index}
                  style={{ width: "100%" }}
                >
                  <NavLink
                    to={`${
                      item?.reservationId
                        ? "/my-scheduled-orders"
                        : "/weekly-routes"
                    }`}
                  >
                    <div className="media">
                      <span className="avatar">
                        <img
                          alt=""
                          src={require("../../../assets/images/profile.jpg")}
                        />
                      </span>
                      <div className="media-body">
                        <p className="noti-details">
                          <span className="noti-title">
                            {item?.reservationId
                              ? "Order No. " + item?.reservationId?.orderId
                              : "Route No. " + item?.routeId?.routeNumber}
                          </span>{" "}
                          has been assigned to you by Admin
                          <span className="noti-title"> </span>
                        </p>
                        <p className="noti-time">
                          <span className="notification-time">
                            {moment().format("DD-MM-YYYY") ===
                            moment(item.assignedDate?.substring(0, 10)).format(
                              "DD-MM-YYYY"
                            )
                              ? // parseInt(item.assignedDate.toString().substring(11,13)) > 11 ? item.assignedDate.toString().substring(11,16)+" pm" : item.assignedDate.toString().substring(11,16)+' am'
                                parseInt(
                                  item?.assignedDate?.substring(11, 13)
                                ) === 0
                                ? 12
                                : parseInt(
                                    item?.assignedDate?.substring(11, 13)
                                  ) >
                                  12 +
                                    ":" +
                                    item?.assignedDate?.substring(14, 16) +
                                    (parseInt(
                                      item?.assignedDate?.substring(11, 13)
                                    ) > 11
                                      ? " pm"
                                      : " am")
                                ? parseInt(
                                    item?.assignedDate?.substring(11, 13)
                                  ) -
                                  12 +
                                  ":" +
                                  item?.assignedDate?.substring(14, 16) +
                                  (parseInt(
                                    item?.assignedDate?.substring(11, 13)
                                  ) > 11
                                    ? " pm"
                                    : " am")
                                : parseInt(
                                    item?.assignedDate?.substring(11, 13)
                                  ) +
                                  ":" +
                                  item?.assignedDate?.substring(14, 16) +
                                  (parseInt(
                                    item?.assignedDate?.substring(11, 13)
                                  ) > 11
                                    ? " pm"
                                    : " am")
                              : moment(
                                  item.assignedDate?.substring(0, 10)
                                ).format("DD MMM")}
                            {/* {item.time} mins ago */}
                          </span>
                        </p>
                      </div>
                    </div>
                  </NavLink>
                </li>
              );
            })}
          </ul>
          <div className="d-flex px-3 justify-content-between align-items-center border-bottom-grey py-1 bg-white">
            <div className="___class_+?50___"></div>
          </div>
          <div
            className="topnav-dropdown-footer bg-white"
            style={{
              display: "block",
              textAlign: "center",
              paddingBottom: "10px",
              paddingTop: "3px",
            }}
          >
            <Link to="/all-notifications">View all Notifications</Link>
            {" | "}
            <Link to="#">Clear Notifications</Link>
          </div>
        </div>
      )}
      {auth.user.jobTitle === "Admin" && (
        <div
          className="dropdown-menu dropdown-menu-right notification-dropdown shadow-lg py-0 bg-additional-grey"
          tabIndex="0"
        >
          <div className="d-flex px-3 justify-content-between align-items-center border-bottom-grey py-1 bg-white">
            <div className="___class_+?50___">
              <div className="f-14 mb-0 text-dark f-w-500">
                <h4>Notifications</h4>
              </div>
            </div>
          </div>
          <div id="notification-list"></div>
          <ul className="notification-list">
            {data.length === 0 && (
              <p className="text-center my-5">
                You don't have any notifications yet!{" "}
              </p>
            )}
            {data.map((item, index) => {
              return (
                <li
                  className="notification-message"
                  key={index}
                  style={{ width: "100%" }}
                >
                  <NavLink
                    to={`${
                      item?.reservationId ? "/requested-orders" : "/routes"
                    }`}
                  >
                    <div className="media">
                      <span className="avatarr">
                        <img
                          alt=""
                          className="inline-block"
                          src={
                            item?.driverId?.profilePic
                              ? item?.driverId?.profilePic?.fileUrl
                              : require("../../../assets/images/profile.jpg")
                          }
                          style={{
                            objectFit: "fill",
                            height: "100%",
                            borderRadius: "50%",
                          }}
                        />
                      </span>
                      <div className="media-body">
                        <p className="noti-details">
                          <span className="noti-title">
                            {item.driverId.firstName +
                              " " +
                              item.driverId.lastName}{" "}
                          </span>{" "}
                          has{" "}
                          {item.driverBookingStatus === "Assigned" ? (
                            <>
                              Accepted
                              <span className="noti-title">
                                {item?.reservationId
                                  ? " Order No. " +
                                    item?.reservationId?.orderId +
                                    " for date " +
                                    moment(
                                      item?.reservationId?.dateForReservation
                                    )
                                      .format("MM-DD-YYYY")
                                      .toString()
                                      .substring(0, 10)
                                  : "Route No. " +
                                    item?.routeId?.routeNumber}{" "}
                              </span>
                            </>
                          ) : (
                            <>
                              <strong>Rejected</strong>
                              <span className="noti-title">
                                {item?.reservationId
                                  ? " Order No. " +
                                    item?.reservationId?.orderId +
                                    " for date " +
                                    moment(
                                      item?.reservationId?.dateForReservation
                                    )
                                      .format("MM-DD-YYYY")
                                      .toString()
                                      .substring(0, 10)
                                  : "Route No. " +
                                    item?.routeId?.routeNumber}{" "}
                                {/* .substring(0, 10) +" because "+item.declineReason
                                    : "Route No. " + item?.routeId?.routeNumber +"because "+item.declineReason}{" "} */}
                              </span>
                            </>
                          )}
                        </p>
                        <p className="noti-time">
                          <span className="notification-time">
                            {item?.responseDate
                              ? moment().format("DD-MM-YYYY") ===
                                moment(
                                  item.responseDate?.toString().substring(0, 10)
                                ).format("DD-MM-YYYY")
                                ? // moment(item.responseDate).format('LT')
                                  // parseInt(item.assignedDate.toString().substring(11,13)) > 11 ? item.assignedDate.toString().substring(11,16)+" pm" : item.assignedDate.toString().substring(11,16)+' am'
                                  parseInt(
                                    item?.responseDate?.substring(11, 13)
                                  ) === 0
                                  ? 12
                                  : parseInt(
                                      item?.responseDate?.substring(11, 13)
                                    ) >
                                    12 +
                                      ":" +
                                      item?.responseDate?.substring(14, 16) +
                                      (parseInt(
                                        item?.responseDate?.substring(11, 13)
                                      ) > 11
                                        ? " pm"
                                        : " am")
                                  ? parseInt(
                                      item?.responseDate?.substring(11, 13)
                                    ) -
                                    12 +
                                    ":" +
                                    item?.responseDate?.substring(14, 16) +
                                    (parseInt(
                                      item?.responseDate?.substring(11, 13)
                                    ) > 11
                                      ? " pm"
                                      : " am")
                                  : parseInt(
                                      item?.responseDate?.substring(11, 13)
                                    ) +
                                    ":" +
                                    item?.responseDate?.substring(14, 16) +
                                    (parseInt(
                                      item?.responseDate?.substring(11, 13)
                                    ) > 11
                                      ? " pm"
                                      : " am")
                                : // + ":"+item?.responseDate?.substring(14, 16)+parseInt(item?.responseDate?.substring(11, 13)) > 11 ? "pm" : "am"
                                  moment(
                                    item?.responseDate?.substring(0, 10)
                                  ).format("DD MMM")
                              : moment().format("DD-MM-YYYY") ===
                                moment(
                                  item.endDate?.toString().substring(0, 10)
                                ).format("DD-MM-YYYY")
                              ? // moment(item.endDate).format('LT')
                                // parseInt(item.assignedDate.toString().substring(11,13)) > 11 ? item.assignedDate.toString().substring(11,16)+" pm" : item.assignedDate.toString().substring(11,16)+' am'
                                parseInt(item?.endDate?.substring(11, 13)) === 0
                                ? 12
                                : parseInt(item?.endDate?.substring(11, 13)) >
                                  12 +
                                    ":" +
                                    item?.endDate?.substring(14, 16) +
                                    (parseInt(
                                      item?.endDate?.substring(11, 13)
                                    ) > 11
                                      ? " pm"
                                      : " am")
                                ? parseInt(item?.endDate?.substring(11, 13)) -
                                  12 +
                                  ":" +
                                  item?.endDate?.substring(14, 16) +
                                  (parseInt(item?.endDate?.substring(11, 13)) >
                                  11
                                    ? " pm"
                                    : " am")
                                : parseInt(item?.endDate?.substring(11, 13)) +
                                  ":" +
                                  item?.endDate?.substring(14, 16) +
                                  (parseInt(item?.endDate?.substring(11, 13)) >
                                  11
                                    ? " pm"
                                    : " am")
                              : // + ":"+item?.endDate?.substring(14, 16)+parseInt(item?.endDate?.substring(11, 13)) > 11 ? "pm" : "am"
                                moment(item?.endDate?.substring(0, 10)).format(
                                  "DD MMM"
                                )}
                            {/* {moment().format('DD-MM-YYYY') === moment(item.responseDate[item.responseDate.length-1].timestamps).format('DD-MM-YYYY') ? moment(item.responseDate[item.responseDate.length-1].timestamps).format('LT') : moment(item.responseDate[item.responseDate.length-1].timestamps).format('DD MMM')} */}
                          </span>
                        </p>
                      </div>
                    </div>
                  </NavLink>
                </li>
              );
            })}
          </ul>
          <div className="d-flex px-3 justify-content-between align-items-center border-bottom-grey">
            <div className="___class_+?50___"></div>
          </div>
          <div
            className="topnav-dropdown-footer bg-white"
            style={{
              display: "block",
              textAlign: "center",
              paddingBottom: "10px",
              paddingTop: "3px",
            }}
          >
            <Link to="/all-notifications">View all Notifications</Link>
            {" | "}
            <Link to="#">Clear Notifications</Link>
          </div>
        </div>
      )}
    </>
  );
};

export default NotificationDropDown;
