/** @format */

import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import moment from "moment";
import defaultImg from "../../../assets/images/profile.jpg";
import Loader from "../../Loader";
import MessageItem from "../../chatComponent/messageItem";
import {
  getMessagesAction,
  sendMessageAction,
} from "../../../actions/chatActions";
import LoadingComponentRideModal from "../../LoadingComponentRideModal";
import { setReservationData } from "../../../actions/reservationAction";
import groupIcon from "../../../assets/images/undraw_People_re_8spw.png";

const AdminBookingGroupMessageRow = ({
  reservation,
  auth,
  showGroupMessageDropDown,
  openGroupMessageDropDown,
  current,
}) => {
  const [message, setMessage] = useState("");
  const [isTyping, setIsTyping] = useState(false);
  const chats = useSelector((state) => state.chats);
  const sockets = useSelector((state) => state.sockets);
  const users = useSelector((state) => state.users);

  let currentSender = null;
  let lastDate = null;
  const { socketConnected, socketInstance } = sockets;
  const [showDeclineDropDown, setshowDeclineDropDown] = useState(false);
  const openDeclineDropDown = (resWork) => {
    setshowDeclineDropDown((showDeclineDropDown) => !showDeclineDropDown);
    setStatus(resWork);
  };

  const [values2, setValues2] = useState({
    _id: "",
  });

  const noShowData = {
    _id: values2._id,
    tripCancel: "No Show",
    pickUpTime: moment().format().toString().substring(0, 19) + ".000Z",
    pickUpLat: reservation?.reservationDetails?.pickUpAddress?.latitude,
    pickUpLng: reservation?.reservationDetails?.pickUpAddress?.longitude,
    dropOffTime: moment().format().toString().substring(0, 19) + ".000Z",
    dropOffLat: reservation?.reservationDetails?.dropOffAddress?.latitude,
    dropOffLng: reservation?.reservationDetails?.dropOffAddress?.longitude,
    isCompleted: false,
  };

  useEffect(() => {
    if (reservation?.reservationDetails?.trip) {
      setValues2({
        ...values2,
        _id: reservation?.reservationDetails?.trip?.id,
      });
    }
  }, [reservation]);
  const dispatch = useDispatch();
  const [reason, setReason] = useState("");
  const handleReasonChange = (event) => {
    setReason(event.target.value);
  };
  const [status, setStatus] = useState();

  const nonDriverIds = users.users
    .filter((user) => user.isDriver === false)
    .map((user) => user.id);

  const driverId = reservation?.reservationDetails?.driverId?.id;

  const messageGroupData = [
    ...nonDriverIds,
    ...(driverId !== undefined ? [driverId] : []),
  ];

  //   const [isTyping, setIsTyping] = useState(false);

  const [chatModal, setChatModal] = useState(false);
  const [create, setCreate] = useState(false);
  const handleChat = () => {
    if (chatModal === true) {
      setCreate(false);
    }
    if (chatModal === false) {
      setCreate(true);
    }
    setChatModal(!chatModal);
  };
  const [selectedValue, setSelectedValue] = useState([]);
  const onSelect = (selectedList, selectedItem) => {
    setSelectedValue(selectedList);
  };
  const onRemove = (selectedList, removedItem) => {
    setSelectedValue(selectedList);
  };

  useEffect(() => {
    if (socketInstance) {
      socketInstance.on("typing", (user) => setIsTyping(user));
      socketInstance.on("stop typing", (user) => setIsTyping(false));
    }
    if (socketInstance && chats.selectedChat) {
      dispatch(getMessagesAction(chats.selectedChat.id, auth.user.id));

      socketInstance.emit("join room", chats.selectedChat.id);
    }
  }, [chats.selectedChat, socketInstance]);
  useEffect(() => {
    if (chats.selectedChat) {
      // console.log("kkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkk");
      dispatch(getMessagesAction(chats.selectedChat.id, auth.user.id));
    }
  }, []);
  // useEffect(() => {
  //   if (socketInstance) {
  //     socketInstance.on("typing", (user) => setIsTyping(user));
  //     socketInstance.on("stop typing", (user) => setIsTyping(false));
  //   }
  //   if (socketInstance && chats.selectedChat) {
  //     if (
  //       chats?.messages?.list?.length > 0 &&
  //       chats?.messages?.list[0]?.chatId?.id === chats?.selectedChat?.id
  //     ) {
  //       dispatch(getMessagesAction(chats.selectedChat.id, auth.user.id, true));
  //     } else {
  //       dispatch(getMessagesAction(chats.selectedChat.id, auth.user.id));
  //     }

  //     socketInstance.emit("join room", chats.selectedChat.id);
  //   }
  // }, [chats.selectedChat, socketInstance]);

  const [typing, setTyping] = useState(false);

  const messageHandler = (e) => {
    setMessage(e.target.value);

    if (!socketConnected) return;
    if (!typing) {
      setTyping(true);
      socketInstance.emit("typing", {
        room: chats.selectedChat.id,
        user: {
          id: auth.user.id,
          name: auth.user.firstName + " " + auth.user.lastName,
          ...(auth?.user?.profilePic?.fileUrl && {
            picUrl: auth.user.profilePic.fileUrl,
          }),
        },
        users: chats.selectedChat.users,
      });
    }
    let lastTime = new Date().getTime();
    var time = 3000;
    setTimeout(() => {
      var timeNow = new Date().getTime();
      var timeDiff = timeNow - lastTime;
      if (timeDiff >= time) {
        socketInstance.emit("stop typing", {
          room: chats.selectedChat.id,
          user: {
            id: auth.user.id,
            name: auth.user.firstName + " " + auth.user.lastName,
          },
          users: chats.selectedChat.users,
        });
        setTyping(false);
      }
    }, time);
  };

  const sendMessage = (e) => {
    e.preventDefault();
    const dateTime = new Date();
    // console.log(message, "messagemessage");
    if (message !== "") {
      dispatch(
        sendMessageAction(
          {
            sender: auth.user,
            chatId: chats.selectedChat,
            message: message,
            dateTime,
          },
          () => {
            socketInstance.emit("new message", {
              sender: {
                firstName: auth.user.firstName,
                lastName: auth.user.lastName,
                id: auth.user.id,
                ...(auth?.user?.profilePic?.fileUrl && {
                  profilePic: { fileUrl: auth.user.profilePic.fileUrl },
                }),
              },
              // chatId: chats.selectedChat,
              chatId: {
                ...chats.selectedChat,
                reservationId: chats?.selectedChat?.reservationId?.id
                  ? chats.selectedChat.reservationId.id
                  : chats.selectedChat.reservationId,
              },
              message: message,
              dateTime,
            });
            if (!chats?.selectedChat?.acceptedUser) {
              dispatch(getMessagesAction(chats.selectedChat.id, auth.user.id));
            }
          }
        )
      );
      setMessage("");
    }
  };
  const receiver = chats?.selectedChat?.users?.find(
    (x) => x.id !== auth.user.id
  );

  return (
    <>
      <div
        className={`modal fade ${
          showGroupMessageDropDown === true ? "show modal-y-axis" : "hide"
        }`}
        // className={`modal fade show modal-y-axis`}
        role="dialog"
        data-backdrop="static"
        data-keyboard="false"
        onClick={(e) => {
          if (e.target === e.currentTarget) {
            openGroupMessageDropDown(); // Call your close modal function
          }
        }}
      >
        <div
          className="modal-dialog modal-dialog-centered modal-dialog-msg"
          role="document"
          style={{ maxWidth: "800px !important" }}
        >
          <div className="modal-content-message-chat">
            {/* <form> */}
            <div className="">
              <div className="">
                <div className="chat-window b-shadow-4">
                  {/* <div className="chat-window b-shadow-4" style={{ height: "80vh"}}> */}
                  <div
                    className="chat-cont-right"
                    style={{ flex: "0 0 100%", maxWidth: "100%" }}
                  >
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                      style={{
                        marginRight: "14px",
                        marginTop: "10px",
                      }}
                      onClick={() => openGroupMessageDropDown()}
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                    <>
                      <>
                        <div className="chat-header">
                          <br />
                          <div className="d-flex">
                            <div className="media-img-wrap">
                              <div
                                className={`avatar ${
                                  !chats?.selectedChat?.isGroup
                                    ? receiver?.isOnline
                                      ? "avatar-online"
                                      : !receiver?.isOnline
                                      ? "avatar-offline"
                                      : ""
                                    : ""
                                }`}
                              >
                                {/* <img src="images/profiles/avatar-02.jpg" alt="User Image" className="chat-avatar-img rounded-circle"/> */}
                                <img
                                  src={
                                    chats?.selectedChat?.isGroup
                                      ? groupIcon
                                      : receiver?.profilePic
                                      ? receiver?.profilePic.fileUrl
                                      : defaultImg
                                  }
                                  alt="avatar"
                                  className="rounded-circle d-flex align-self-center me-3-wala-new shadow-1-strong object-fit-cover inline-block"
                                  width="40"
                                  height="40"
                                />
                              </div>
                            </div>
                            <div className="media-body">
                              <div className="user-name">
                                {chats?.selectedChat?.isGroup
                                  ? chats?.selectedChat?.chatName
                                  : receiver
                                  ? `${receiver.firstName} ${receiver.lastName}`
                                  : ""}
                              </div>
                              <div className="user-status">{`${
                                !chats?.selectedChat?.isGroup
                                  ? receiver?.isOnline
                                    ? "online"
                                    : !receiver?.isOnline
                                    ? "offline"
                                    : ""
                                  : chats?.selectedChat?.users?.length +
                                    " members"
                              }`}</div>
                            </div>
                          </div>
                          {/* <div className="chat-options">
                            <button
                              onClick={startCalling}
                              className={isJoined ? "button-on" : ""}
                            >
                              <i className="material-icons fa fa-phone"></i>
                            </button>
                          </div> */}
                        </div>
                        <div className="chat-body">
                          <div className="chat-scroll2">
                            <ul
                              className="list-unstyled"
                              style={{
                                overflowY: "auto",
                                height: "330px",
                                // minHeight: windowSize.height,
                              }}
                            >
                              {" "}
                              {chats?.messages?.loading ? (
                                <LoadingComponentRideModal />
                              ) : (
                                // chats?.messages?.loading === false &&
                                chats?.messages?.list?.length === 0 && (
                                  <p className="text-center bg-white p-3">
                                    No messages in the chat, start with sending
                                    a message!
                                  </p>
                                )
                              )}
                              {/* {chats.messages.loading && (
                                <LoadingComponentRideModal />
                              )} */}
                              {chats.messages.list.map((chatMessage) => {
                                const date = moment(chatMessage.dateTime);
                                const currentDate = moment(date);
                                const formattedDate =
                                  currentDate.format("MMM DD, YYYY"); // Customize the format as needed
                                const isToday = currentDate.isSame(
                                  moment(),
                                  "day"
                                );
                                let displayDate = isToday
                                  ? "Today"
                                  : moment(formattedDate).format("ll");
                                const renderDate = lastDate !== displayDate;
                                lastDate = displayDate;
                                let isSameSender =
                                  currentSender === chatMessage?.sender?.id;
                                if (renderDate) {
                                  isSameSender = false;
                                }
                                if (!isSameSender) {
                                  currentSender = chatMessage?.sender?.id;
                                }
                                return (
                                  <MessageItem
                                    chatMessage={chatMessage}
                                    renderDate={renderDate}
                                    displayDate={displayDate}
                                    isSameSender={isSameSender}
                                    noShowData={noShowData}
                                  />
                                );
                              })}
                              {isTyping &&
                                chats?.selectedChat?.id == isTyping.room && (
                                  <li className="media d-flex received">
                                    <div
                                      className="avatar"
                                      style={{ backgroundColor: "#f5f5f6" }}
                                    >
                                      <img
                                        src={
                                          isTyping?.user?.picUrl
                                            ? isTyping?.user?.picUrl !== "null"
                                              ? isTyping?.user?.picUrl
                                              : defaultImg
                                            : defaultImg
                                        }
                                        alt="User Image"
                                        className="chat-avatar-img rounded-circle centralized-avatar"
                                      />
                                    </div>
                                    <div className="media-body">
                                      {isTyping &&
                                        chats?.selectedChat?.id ==
                                          isTyping.room && (
                                          <div style={{ fontSize: "12px" }}>
                                            {isTyping?.user?.name}
                                          </div>
                                        )}
                                      <div className="msg-box">
                                        <div>
                                          <div className="msg-typing">
                                            <span></span>
                                            <span></span>
                                            <span></span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </li>
                                )}
                            </ul>
                          </div>
                        </div>
                        <div className="chat-footer">
                          <form className="mb-3 mx-2" onSubmit={sendMessage}>
                            <div className="input-group">
                              <div className="input-group-prepend"></div>
                              <input
                                type="text"
                                className="input-msg-send form-control height-35"
                                placeholder="Aa"
                                onChange={messageHandler}
                                // onClick={handleInputClick}
                                // onChange={(e) => setMessage(e.target.value)}
                                value={message}
                                required
                                disabled={
                                  !chats?.selectedChat?.acceptedUser ||
                                  chats?.selectedChat?.acceptedUser ===
                                    auth.user.id
                                    ? false
                                    : true
                                }
                              />
                              <div
                                className="input-group-append"
                                style={{ zIndex: 0 }}
                              >
                                <button
                                  type="submit"
                                  disabled={
                                    !chats?.selectedChat?.acceptedUser ||
                                    chats?.selectedChat?.acceptedUser ===
                                      auth.user.id
                                      ? false
                                      : true
                                  }
                                  className="btn msg-send-btn height-35"
                                >
                                  <i className="fas fa-paper-plane"></i>
                                </button>
                              </div>
                            </div>
                          </form>
                        </div>
                      </>
                    </>
                  </div>
                </div>
              </div>
            </div>
            {/* </form> */}
          </div>
        </div>
      </div>
    </>
  );
};
export default AdminBookingGroupMessageRow;

{
  /* <div className="modal-footer">
    <button
        type="button"
        className="btn-secondary rounded f-14 p-2 mr-3"
        data-dismiss="modal"
    >
        Close
    </button>
    <button
        type="submit"
        className="btn-primary rounded f-14 p-2 mr-3"
    >
        Submit
    </button>

</div> */
}
