import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { deleteVehicleAction } from '../../../actions/vehicleActions';

const AdminSettingVehicleDeleteModal = ({showDeleteDropDown, openDeleteDropDown, current, index}) => {
    const auth = useSelector((state) => state.auth);
    const vehicle = useSelector((state) => state.vehicle);
    const {loading} = vehicle

    const [values, setValues] =useState({
        _id: '',
        delete: '',
      })
      useEffect(()=>{
        setValues({...values, _id: current?.id})
      },[current])
      const handleChange = (fieldName) => (event) => {
        setValues({
            ...values,
            [fieldName]: event.target.value,
        });
    };
    const data = {
        _id: values._id,
        delete: true,
      }
      const dispatch = useDispatch()
      const handleSumbit = async (event) => {
        event.preventDefault()
        dispatch(deleteVehicleAction(data))
        // setTimeout(
        //   () => 
        // 2000
        // );
        openDeleteDropDown()
      }
    return (
        <>
            <div
                className={`modal fade ${showDeleteDropDown === true ? "show modal-y-axis" : "hide"}`}
                role="dialog"
                data-backdrop="static"
                data-keyboard="false" // To prevent closing with the keyboard
                onClick={(e) => {
                // Check if the click occurred outside the modal content
                if (e.target === e.currentTarget) {
                    openDeleteDropDown(); // Call your close modal function
                }
                }}
            >
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <form
                            onSubmit={handleSumbit}
                        >
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLongTitle">
                                    {" "}
                                    Delete Vehicle
                                </h5>
                                <button
                                    type="button"
                                    className="close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                    onClick={() => openDeleteDropDown()}
                                >
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body" style={{ width: "50% !important" }}>
                                <div className="row">
                                    <div className="col-md-12">
                                        <p>Are you sure you want to delele? </p>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    className="btn-secondary rounded f-14 p-2 mr-3"
                                    data-dismiss="modal"
                                    disabled={loading}
                                    onClick={() => openDeleteDropDown()}
                                >
                                    Cancel
                                </button>
                                <button
                                    type="submit"
                                    className="btn-primary rounded f-14 p-2 mr-3"
                                    disabled={loading}
                                >
                                    Yes
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}
export default AdminSettingVehicleDeleteModal