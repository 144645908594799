import {
GET_CONTACTUS_REQUEST,
GET_CONTACTUS_SUCCESS,
GET_CONTACTUS_FAIL,
GET_CONTACTUS_BY_ID_REQUEST,
GET_CONTACTUS_BY_ID_SUCCESS,
GET_CONTACTUS_BY_ID_FAIL,
CREATE_CONTACTUS_MSG_REQUEST,
CREATE_CONTACTUS_MSG_SUCCESS,
CREATE_CONTACTUS_MSG_FAIL,
} from "../constants"

export const contactusReducer = (
    state = {
        contactus: {
            contactusTable: null,
            contactusTableModal: null,
        }
    },
    action
) => {
        switch (action.type) {

        case GET_CONTACTUS_REQUEST : 
            return {
                ...state,
            };
        case GET_CONTACTUS_SUCCESS :
            return {
                ...state,
                contactusTable : action.payload,
            };
        case GET_CONTACTUS_FAIL :
            return {
                ...state,
                error: action.payload,
            };

            
        case GET_CONTACTUS_BY_ID_REQUEST : 
            return {
                ...state,
            };
        case GET_CONTACTUS_BY_ID_SUCCESS :
            return {
                ...state,
                contactusTableModal : action.payload,
            };
        case GET_CONTACTUS_BY_ID_FAIL :
            return {
                ...state,
                error: action.payload,
            };


        case CREATE_CONTACTUS_MSG_REQUEST :
            return {
                ...state,
            }
        case CREATE_CONTACTUS_MSG_SUCCESS :
            return {
                ...state,
                contactusTable: action.payload,
            }
        case CREATE_CONTACTUS_MSG_FAIL :
            return {
                ...state,
                error: action.payload,
            }


        default:
            return state;
    }
}