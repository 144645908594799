const initialState = {
  viewPayment: "baselocation",
  viewReport: "basicReportRow",
  viewDispatching: "viewUnassignedDispatching",
  viewNavBar: "home",
  viewAide: "viewFullReport",
  viewInvoicing: "theinvoicing",
  viewSettlement: "tabIndividualSettlement",
  viewPermission: "creatingAdminOrDispatcher",
  viewActiveRoute: "activeRoute"
};

export const changeTheTab = (state = initialState, action) => {
  switch (action.type) {
    case "TAB_BASELOCATION":
      return {
        ...state,
        viewPayment: "baselocation",
      };
    case "TAB_ADDSERVICES":
      return {
        ...state,
        viewPayment: "AddServices",
      };
    case "TAB_INVOICING":
      return {
        ...state,
        viewInvoicing: "theinvoicing",
      };
    case "TAB_DRIVERPAY":
      return {
        ...state,
        viewInvoicing: "driverpay",
      };
    case "TAB_AMBULATORY":
      return {
        ...state,
        viewPayment: "Ambulatory",
      };
    case "TAB_WHEELCHAIR":
      return {
        ...state,
        viewPayment: "Wheelchair",
      };
    case "TAB_STRETCHER":
      return {
        ...state,
        viewPayment: "Stretcher",
      };
    case "TAB_FARELIST":
      return {
        ...state,
        viewPayment: "fareList",
      };

    case "TAB_BASIC_REPORT_ROW":
      return {
        ...state,
        viewReport: "basicReportRow",
      };
    case "TAB_REPORT_SEARCH":
      return {
        ...state,
        viewReport: "basicReportSearch",
      };

    case "TAB_CALCULATOR_REPORT_ROW":
      return {
        ...state,
        viewReport: "calculatorReportRow",
      };

    case "TAB_DRIVER_ROUTE_TRIP":
      return {
        ...state,
        viewReport: "driverRouteTrip",
      };

    case "TAB_ROUTE_DISTANCE_REPORT":
      return {
        ...state,
        viewReport: "routeDistanceReport",
      };

    case "TAB_UNASSIGNED_DISPATCHING":
      return {
        ...state,
        viewDispatching: "viewUnassignedDispatching",
      };
    case "TAB_PENDING_DISPATCHING":
      return {
        ...state,
        viewDispatching: "viewPendingDispatching",
      };
    case "TAB_ASSIGNED_DISPATCHING":
      return {
        ...state,
        viewDispatching: "viewAssignedDispatching",
      };
    case "TAB_ORDERS_SEARCH":
      return {
        ...state,
        viewDispatching: "dateSearch",
      };

    case "TAB_FULL_REPORT":
      return {
        ...state,
        viewAide: "viewFullReport",
      };
    case "TAB_AIDE_REPORT":
      return {
        ...state,
        viewAide: "viewAideReport",
      };
    case "TAB_WITHOUT_AIDE_REPORT":
      return {
        ...state,
        viewAide: "viewWithoutAideReport",
      };

    // general website tab actions
    case "TAB_HOME":
      return {
        ...state,
        viewNavBar: "home",
      };
    case "TAB_WHYUS":
      return {
        ...state,
        viewNavBar: "whyus",
      };
    case "TAB_SERVICES":
      return {
        ...state,
        viewNavBar: "services",
      };

    case "TAB_FAQPAGE":
      return {
        ...state,
        viewNavBar: "faq",
      };
    case "TAB_CONTACTUS":
      return {
        ...state,
        viewNavBar: "contactus",
      };
    case "TAB_SIGNIN":
      return {
        ...state,
        viewNavBar: "signin",
      };

      case "TAB_INDIVIDUAL_SETTLEMENT":
        return {
          ...state,
          viewSettlement: "tabIndividualSettlement",
        };
      case "TAB_ALL_SETTLEMENT":
        return {
          ...state,
          viewSettlement: "tabAllSettlement",
        };

      case "TAB_SETTLEMENT_SEARCH_FILTER":
      return {
        ...state,
        viewSettlement: "settlementFilter",
      };
      case "TAB_CREATING_ADMIN_OR_DISPATCHER":
        return {
          ...state,
          viewPermission: "creatingAdminOrDispatcher",
        };
      case "TAB_CREATING_DRIVER":
        return {
          ...state,
          viewPermission: "creatingDriver",
        };
      case "TAB_CREATING_ACCOUNTANT":
        return {
          ...state,
          viewPermission: "creatingAccountant",
        };

      case "TAB_ACTIVE_ROUTES":
        return {
          ...state,
          viewActiveRoute: "activeRoute",
        };
      case "TAB_NON_ACTIVE_ROUTES":
        return {
          ...state,
          viewActiveRoute: "nonActiveRoute",
        };
  

    default:
      return state;
  }
};
