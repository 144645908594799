import axios from "axios";
import {
    LOCATION_VIEW_REQUEST,
    LOCATION_VIEW_SUCCESS,
    LOCATION_VIEW_FAIL,
    LOCATION_CREATE_REQUEST,
    LOCATION_CREATE_SUCCESS,
    LOCATION_CREATE_FAIL,
    LOCATION_BY_USER_REQUEST,
    LOCATION_BY_USER_SUCCESS,
    LOCATION_BY_USER_FAIL,
    BASE_LOCATION_REQUEST,
    BASE_LOCATION_SUCCESS,
    BASE_LOCATION_FAIL,
    LOCATION_UPDATE_REQUEST,
    LOCATION_UPDATE_SUCCESS,
    LOCATION_UPDATE_FAIL,
    SERVERADDRESS,
} from "../constants"

// ALL LOCATION DISPLAY VIEW 
export const getAllLocation = (obj) => async (dispatch) => {
    dispatch({
        type: LOCATION_VIEW_REQUEST,
    });
    try {
        const { data } = await axios.post(`${SERVERADDRESS}/v1/location/getLocationsByCompanyId`, obj);
        localStorage.setItem("location", JSON.stringify(data));
        dispatch({
            type: LOCATION_VIEW_SUCCESS,
            payload: data,
        });
    }
    catch (err) {
        // console.log(err)
        dispatch({
            type: LOCATION_VIEW_FAIL,
            payload: err,
        });
    }
};

// ADD NEW LOCATION
export const addLocation = (obj) => async ( dispatch ) => {
    dispatch({
        type: LOCATION_CREATE_REQUEST,
    })
    try {
        const { data } = await axios.post(`${SERVERADDRESS}/v1/location/createLocation`, obj)
        // getLocationsByUserId({userId: data.userId})
    dispatch({
        type: LOCATION_CREATE_SUCCESS,
        payload: data,
    })
}
catch (err) {
    // console.log(err)
    dispatch({
        type: LOCATION_CREATE_FAIL,
        payload: err,
    })
}
}

// ALL LOCATION OF A USER 
export const getLocationsByUserId = (obj) => async (dispatch) => {
    dispatch({
        type: LOCATION_BY_USER_REQUEST,
    });
    try {
        const { data } = await axios.post(`${SERVERADDRESS}/v1/location/getLocationsByUserId`, obj);
        localStorage.setItem("searchedLocation", JSON.stringify(data));
        dispatch({
            type: LOCATION_BY_USER_SUCCESS,
            payload: data,
        });
    }
    catch (err) {
        // console.log(err)
        dispatch({
            type: LOCATION_BY_USER_FAIL,
            payload: err,
        });
    }
};


// BASE LOCATION
export const getBaseLocation = (obj) => async (dispatch) => {
    dispatch({
        type: BASE_LOCATION_REQUEST,
    });
    try {
        const { data } = await axios.post(`${SERVERADDRESS}/v1/location/getBaseLocation`, obj);
        localStorage.setItem("baseLocation", JSON.stringify(data));
        dispatch({
            type: BASE_LOCATION_SUCCESS,
            payload: data,
        });
    }
    catch (err) {
        // console.log(err)
        dispatch({
            type: BASE_LOCATION_FAIL,
            payload: err,
        });
    }
};

// UPDATE LOCATION
export const updateLocation = (obj) => async (dispatch) => {
    dispatch({
        type: LOCATION_UPDATE_REQUEST,
    });
    try {
        const { data } = await axios.put(`${SERVERADDRESS}/v1/location/updateLocation`, obj);
        // localStorage.setItem("baseLocation", JSON.stringify(data));
        localStorage.setItem("baseLocation", JSON.stringify(data));
        dispatch({
            type: LOCATION_UPDATE_SUCCESS,
            payload: data,
        });
    }
    catch (err) {
        // console.log(err)
        dispatch({
            type: LOCATION_UPDATE_FAIL,
            payload: err,
        });
    }
};
