/** @format */

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getExternalCompanies } from "../../../actions/companyAction";
import {
  // addClient,
  // addStudentAction,
  addRoutePassengerAction,
} from "../../../actions/userActions";
import AdminSettingPaymentAddCompanyModal from "../../../components/adminComponents/adminSettingComponents/AdminSettingPaymentAddCompanyModal";
import { useNavigate } from "react-router-dom";

const AdminRoutePassengerAddComponent = ({
  showDropDown,
  openDropDown,
  setSelectedStudent,
  values,
  setValues,
  booking,
}) => {
  let [showModal, setShowModal] = useState();
  // let [selectedCompany, setSelectedCompany] = useState();
  const externalCompanies = useSelector((state) => state.externalCompanies);
  const auth = useSelector((state) => state.auth);
  const students = useSelector((state) => state.students);
  const { loading } = students;

  useEffect(() => {
    dispatch(getExternalCompanies({ companyId: auth.user.companyId.id }));
  }, [auth]);

  let [selectedCompanyId, setSelectedCompanyId] = useState("");
  const [values2, setvalues2] = useState({
    // companyId: "63cb14f53f55723694dbd185",
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    isRouteUser: true,
    // workPhone: '',
    // otherPhone: '',
    // clientWorkInformation: '',
    address: "",
    // state: '',
    city: "",
    zip: "",
    type: "",
    notes: "",
    externalCompanyId: "",
  });

  const dispatch = useDispatch();

  const studentData = {
    companyId: auth.user.companyId.id,
    firstName: values2.firstName,
    lastName: values2.lastName,
    email: values2.email,
    phone: values2.phone,
    notes: values2.notes,
    isRouteUser: true,
    address: values2.address,
    // state: values2.state,
    city: values2.city,
    zip: values2.zip,
    isCompany: values2.type === "Company" ? true : false,
    externalCompanyId: values2.type === "Company" ? selectedCompanyId : "",
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    for (let key of Object.keys(studentData)) {
      if (studentData[key] === "") {
        delete studentData[key];
      }
    }
    if (booking) {
      dispatch(
        addRoutePassengerAction(studentData, values, setValues, openDropDown)
      );
    } else {
      dispatch(addRoutePassengerAction(studentData, successHandler));
    }
  };

  // const submitHandler = (e) => {
  //   e.preventDefault();
  //   dispatch(addStudentAction(studentData, successHandler));
  // };
  const navigate = useNavigate();

  const successHandler = (studentId) => {
    // setSelectedStudent((prev) => ({ ...prev, studentId }));
    // openDropDown();
    navigate("/route-passengers");
  };

  const handleChange = (fieldName) => (event) => {
    setvalues2({
      ...values2,
      [fieldName]: event.target.value,
    });
  };

  // const handlePhone = (fieldName) => (event) => {
  //   if (event.target.value.length === 1) {
  //     if (event.target.value !== "(") {
  //       setvalues2({
  //         ...values2,
  //         [fieldName]: "(" + event.target.value,
  //       });
  //     }
  //   } else if (event.target.value.length === 4) {
  //     if (event.target.value !== ")") {
  //       setvalues2({
  //         ...values2,
  //         [fieldName]: event.target.value + ")-",
  //       });
  //     }
  //   } else if (event.target.value.length === 9) {
  //     if (event.target.value !== "-") {
  //       setvalues2({
  //         ...values2,
  //         [fieldName]: event.target.value + "-",
  //       });
  //     }
  //   } else {
  //     setvalues2({
  //       ...values2,
  //       [fieldName]: event.target.value,
  //     });
  //   }
  // };
  // const alphabets = [
  //   "a",
  //   "b",
  //   "c",
  //   "d",
  //   "e",
  //   "f",
  //   "g",
  //   "h",
  //   "i",
  //   "j",
  //   "k",
  //   "l",
  //   "m",
  //   "n",
  //   "o",
  //   "p",
  //   "q",
  //   "r",
  //   "s",
  //   "t",
  //   "u",
  //   "v",
  //   "w",
  //   "x",
  //   "y",
  //   "z",
  //   "A",
  //   "B",
  //   "C",
  //   "D",
  //   "E",
  //   "F",
  //   "G",
  //   "H",
  //   "I",
  //   "J",
  //   "K",
  //   "L",
  //   "M",
  //   "N",
  //   "O",
  //   "P",
  //   "Q",
  //   "R",
  //   "S",
  //   "T",
  //   "U",
  //   "V",
  //   "W",
  //   "X",
  //   "Y",
  //   "Z",
  //   "+",
  //   "-",
  //   "/",
  //   "*",
  //   "!",
  //   "@",
  //   "#",
  //   "$",
  //   "%",
  //   "^",
  //   "&",
  //   "_",
  //   "(",
  //   ")",
  //   ",",
  //   ".",
  // ];

  const handlePhone = (fieldName) => (event) => {
    const numericValue = event.target.value.replace(/\D/g, "");
    let formattedValue = "";
    if (numericValue.length > 0) {
      formattedValue = `(${numericValue.slice(0, 3)}`;
    }
    if (numericValue.length > 3) {
      formattedValue += `)${numericValue.slice(3, 6)}`;
    }
    if (numericValue.length > 6) {
      formattedValue += `-${numericValue.slice(6, 10)}`;
    }
    setvalues2({
      ...values2,
      [fieldName]: formattedValue,
    });
  };

  return (
    <>
      <div className="d-flex flex-column w-tables rounded mt-3 bg-white px-4 pb-3">
        <div
          id="leads-table_wrapper"
          className="dataTables_wrapper dt-bootstrap4 no-footer"
        >
          <form onSubmit={handleSubmit}>
            <div className="modal-body">
              <div className="row p-3">
                <div className="col-md-6">
                  <div className="form-group my-3">
                    <label
                      className="f-14 text-dark-grey mb-12"
                      data-label=""
                      htmlFor="after_days"
                    >
                      First Name
                    </label>
                    <sup className="text-danger f-12">*</sup>
                    <input
                      type="text"
                      className="form-control height-35 f-14"
                      placeholder="Enter Your First Name"
                      autoComplete="false"
                      value={values2.firstName}
                      onChange={handleChange("firstName")}
                      required
                      maxLength={35}
                    />
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group my-3">
                    <label
                      className="f-14 text-dark-grey mb-12"
                      data-label=""
                      htmlFor="after_days"
                    >
                      Last Name
                    </label>
                    {/* <sup className="text-danger f-12">*</sup> */}
                    <input
                      type="text"
                      className="form-control height-35 f-14"
                      placeholder="Enter Your Last Name"
                      name="lastName"
                      autoComplete="false"
                      value={values2.lastName}
                      onChange={handleChange("lastName")}
                      // required
                      maxLength={35}
                    />
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group my-3">
                    <label
                      className="f-14 text-dark-grey mb-12"
                      data-label=""
                      htmlFor="after_days"
                    >
                      Email
                    </label>
                    {/* <sup className="text-danger f-12">*</sup> */}
                    <input
                      type="Email"
                      className="form-control height-35 f-14"
                      placeholder="Enter Your Email"
                      autoComplete="false"
                      value={values2.email}
                      onChange={handleChange("email")}
                      // required
                    />
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group my-3">
                    <label
                      className="f-14 text-dark-grey mb-12"
                      data-label=""
                      htmlFor="after_days"
                    >
                      Phone Number
                    </label>
                    {/* <sup className="text-danger f-12">*</sup> */}
                    {/* <input
                      type="tel"
                      className="form-control height-35 f-14"
                      minLength="14"
                      maxLength="14"
                      // required
                      onKeyDown={(e) => {
                        if (e.ctrlKey && e.code === "KeyV") {
                          return;
                        }
                        if (alphabets.includes(e.key)) {
                          e.preventDefault();
                        }
                        if (
                          e.code === "Backspace" &&
                          values2.phone.length === 6
                        ) {
                          setvalues2({
                            ...values2,
                            phone: values2.phone.substring(0, 4),
                          });
                        }
                        if (
                          e.code === "Backspace" &&
                          values2.phone.length === 2
                        ) {
                          setvalues2({
                            ...values2,
                            phone: "",
                          });
                        }
                        if (
                          e.code === "Backspace" &&
                          values2.phone.length === 10
                        ) {
                          setvalues2({
                            ...values2,
                            phone: values2.phone.substring(0, 9),
                          });
                        }
                      }}
                      name="phone"
                      value={values2.phone}
                      onChange={handlePhone("phone")}
                      placeholder="(123)-456-7890"
                    /> */}
                    <input
                      type="tel"
                      className="form-control height-35 f-14"
                      name="phone"
                      value={values2.phone}
                      onChange={handlePhone("phone")}
                      placeholder="(123)456-7890"
                    />
                  </div>
                </div>
                <div className="col-md-12">
                  <label htmlFor="allowed_file_types">Note: </label>
                  <textarea
                    type="text"
                    name="allowed_file_types"
                    id="allowed_file_types"
                    placeholder="Enter The Note"
                    className="form-control f-14"
                    autoComplete="false"
                    value={values2.notes}
                    onChange={handleChange("notes")}
                  ></textarea>
                </div>
              </div>
              <div className="col-lg-12">
                <div
                  className="form-group "
                  style={{ marginTop: "1.5em !important" }}
                >
                  <br />
                  <button
                    type="submit"
                    className="btn-primary rounded f-14 p-2 mr-3"
                    disabled={loading}
                    // disabled={values2.firstName.length < 1}
                    // onClick={handleSubmit}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      <AdminSettingPaymentAddCompanyModal
        {...{ showModal, setShowModal, setSelectedCompanyId }}
      />
    </>
  );
};
export default AdminRoutePassengerAddComponent;
