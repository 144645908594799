import React from 'react'

const ViewAllNotificationsRow = ({item, i}) => {

  return (
    <>
        {/* <tr className={`${seenTrueStatus === true ? ' unread clickable-row ' : 'clickable-row '}`} data-href="mail-view.html" key={i}> */}
        <tr key={i}>
            {/* <tr className={`${seenTrueStatus === true ? ' unread clickable-row ' : 'clickable-row '}`} data-href="mail-view.html" key={i}> */}
                <td className="subject">
            {/* <NavLink to={`/messaging-thread/${item.id}`} style={{ color: "#000000" }}>
                {item.messages[item.messages.length - 1].message}
            </NavLink> */}
                <p className="noti-details">
                    <span className="noti-title">{item?.reservationId ? "Order No. "+item?.reservationId?.orderId : "Route No. "+item?.routeId?.routeNumber}</span> has been assigned to you by Admin<span className="noti-title"> </span></p>
            </td>
        </tr>
    </>
  )
}
export default ViewAllNotificationsRow