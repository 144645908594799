/** @format */

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { deleteReservationCompletely, undoCancelReservation } from "../../../actions/reservationAction";
import axios from "axios";
import { SERVERADDRESS } from "../../../constants";
import { notifySuccess } from "../../toast";

const AdminUndoCancelRide = ({
  showUndoDropDown,
  openUndoDropDown,
  current,
  index,
  assigned,
  fullData,
  setFullData,
}) => {
  const auth = useSelector((state) => state.auth);
  const reservation = useSelector((state) => state.reservation);
  const [values, setValues] = useState({
    _id: "",
    delete: "",
  });
  useEffect(() => {
    setValues({ ...values, _id: current?.id });
  }, [current]);
  const dataLoad = {
    _id: current._id ? current._id : current.id,
    completelyDelete: true,
    dispatcherId: auth.user.id,
  };

  const dispatch = useDispatch();

  const handleSubmit = async (event) => {
    event.preventDefault();
    dispatch(undoCancelReservation({_id: current?._id ? current?._id : current?.id,
      dispatcherId: auth?.user?.id,
    }, successCB));
    // notifySuccess("Reservation Deleted");
    successCB();
 
  };
  const successCB = (updatedData) => {
    setFullData((prevData) => {
      const updatedIndex = prevData.findIndex((item) => {
        return (
          item?._id === updatedData?.id || (item?.id && item?.id === updatedData?.id)
        );
      });

      if (updatedIndex !== -1) {
        const newData = [...prevData];
        newData[updatedIndex] = { ...updatedData, _id: updatedData?.id };
        return newData;
      }
      return prevData;
    });
    openUndoDropDown();
  };
  return (
    <>
      <div
        className={`modal fade ${
          showUndoDropDown === true ? "show modal-y-axis" : "hide"
        }`}
        role="dialog"
        data-backdrop="static"
        data-keyboard="false" // To prevent closing with the keyboard
        onClick={(e) => {
          // Check if the click occurred outside the modal content
          if (e.target === e.currentTarget) {
            openUndoDropDown(); // Call your close modal function
          }
        }}
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <form onSubmit={handleSubmit}>
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLongTitle">
                  {" "}
                  Undo Canceled
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => openUndoDropDown()}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body" style={{ width: "50% !important" }}>
                <div className="row">
                  <div className="col-md-12">
                    <p>Are you sure you want to undo canceled action? </p>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn-secondary rounded f-14 p-2 mr-3"
                  data-dismiss="modal"
                  onClick={() => openUndoDropDown()}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="btn-primary rounded f-14 p-2 mr-3"
                >
                  Yes
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};
export default AdminUndoCancelRide;
