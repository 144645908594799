import axios from "axios";
import {
  FARE_VIEW_REQUEST,
  FARE_VIEW_SUCCESS,
  FARE_VIEW_FAIL,
  FARE_PRIVATE_VIEW_REQUEST,
  FARE_PRIVATE_VIEW_SUCCESS,
  FARE_PRIVATE_VIEW_FAIL,
  FARE_CREATE_REQUEST,
  FARE_CREATE_SUCCESS,
  FARE_CREATE_FAIL,
  SERVERADDRESS,
  FARE_UPDATE_SUCCESS,
  FARE_UPDATE_REQUEST,
  FARE_UPDATE_FAIL,
  FARE_DELETE_SUCCESS,
  FARE_DELETE_REQUEST,
  FARE_DELETE_FAIL,
} from "../constants";
import { notifySuccess, notifyFailure } from "../components/toast";

// ALL FARE DISPLAY VIEW
export const getFaresByCompanyId = (obj) => async (dispatch) => {
  dispatch({
    type: FARE_VIEW_REQUEST,
  });
  try {
    const { data } = await axios.post(
      `${SERVERADDRESS}/v1/fare/getFaresByCompanyId `,
      obj
    );
    localStorage.setItem("fare", JSON.stringify(data));
    dispatch({
      type: FARE_VIEW_SUCCESS,
      payload: data,
    });
  } catch (err) {
    // console.log(err);
    dispatch({
      type: FARE_VIEW_FAIL,
      payload: err,
    });
  }
};

// ALL FARE_PRIVATE DISPLAY VIEW
export const getPrivatesFaresByCompanyId = (obj) => async (dispatch) => {
  dispatch({
    type: FARE_PRIVATE_VIEW_REQUEST,
  });
  try {
    const { data } = await axios.post(
      `${SERVERADDRESS}/v1/fare/getPrivatesFaresByCompanyId `,
      obj
    );
    localStorage.setItem("privateFare", JSON.stringify(data));
    dispatch({
      type: FARE_PRIVATE_VIEW_SUCCESS,
      payload: data,
    });
  } catch (err) {
    // console.log(err);
    dispatch({
      type: FARE_PRIVATE_VIEW_FAIL,
      payload: err,
    });
  }
};

// ADD NEW FARE
export const createFare = (obj) => async (dispatch) => {
  dispatch({
    type: FARE_CREATE_REQUEST,
  });
  try {
    const { data } = await axios.post(
      `${SERVERADDRESS}/v1/fare/createFare`,
      obj
    );
    notifySuccess("Fare added sucessfully !");
    dispatch({
      type: FARE_CREATE_SUCCESS,
      payload: data,
    });
  } catch (err) {
    // console.log(err);
    notifyFailure(err.response.data.message);
    dispatch({
      type: FARE_CREATE_FAIL,
      payload: err,
    });
  }
};
// UPDATE FARE
export const updateFareAction = (obj, successHandler) => async (dispatch) => {
  dispatch({
    type: FARE_UPDATE_REQUEST,
  });
  try {
    const { data } = await axios.put(
      `${SERVERADDRESS}/v1/fare/updateFare`,
      obj
    );
    notifySuccess("Fare updated sucessfully !");
    successHandler();
    dispatch({
      type: FARE_UPDATE_SUCCESS,
      payload: data,
    });
  } catch (err) {
    // console.log(err);
    notifyFailure(err.response.data.message);
    dispatch({
      type: FARE_UPDATE_FAIL,
      payload: err,
    });
  }
};

// DELETE FARE
export const deleteFare = (obj, successHandler) => async (dispatch) => {
  dispatch({
    type: FARE_DELETE_REQUEST,
  });
  try {
    const { data } = await axios.put(
      `${SERVERADDRESS}/v1/fare/updateFare`,
      obj
    );
    notifySuccess("Fare Deleted sucessfully !");
    successHandler();
    dispatch({
      type: FARE_DELETE_SUCCESS,
      payload: data,
    });
  } catch (err) {
    // console.log(err);
    notifyFailure(err.response.data.message);
    dispatch({
      type: FARE_DELETE_FAIL,
      payload: err,
    });
  }
};
