import React from 'react'
import ExternalCompanyTrackingMap from './ExternalCompanyTrackingMap'

const ExternalCompanyTrackingComponent = () => {
  return (
    <>
      <div className="col-xl-12 col-lg-12 col-md-12 e-d-info-notices">
          <div className="row mb-3 mt-xl-0 mt-lg-4 mt-md-4 mt-4">
              <div className="col-md-12 mt-md-4">
                  <div className="card">
                      <div className="card-header justify-content-between">
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-map" viewBox="0 0 16 16">
                              <path fillRule="evenodd" d="M15.817.113A.5.5 0 0 1 16 .5v14a.5.5 0 0 1-.402.49l-5 1a.502.502 0 0 1-.196 0L5.5 15.01l-4.902.98A.5.5 0 0 1 0 15.5v-14a.5.5 0 0 1 .402-.49l5-1a.5.5 0 0 1 .196 0L10.5.99l4.902-.98a.5.5 0 0 1 .415.103zM10 1.91l-4-.8v12.98l4 .8V1.91zm1 12.98 4-.8V1.11l-4 .8v12.98zm-6-.8V1.11l-4 .8v12.98l4-.8z" />
                          </svg>
                          <span className="f-18 text-dark mx-1">Live Tracking</span>
                      </div>
                      <div className="row">
                          <div className="col-md-12">
                              <div className="row mt-3 pb-3">
                              </div>
                          </div>
                      </div>
                      <div className="card-body border-top">
                          <ExternalCompanyTrackingMap />
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </>
  )
}

export default ExternalCompanyTrackingComponent
