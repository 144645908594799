/** @format */

import React, { useEffect, useState } from "react";
import moment from "moment/moment";
// import AdminDispatchAssignModalComponent from "./AdminDispatchAssignModalComponent";
import AdminDispatchingTodayOrderRideLogModal from "./AdminDispatchingTodayOrderRideLogModal";
import { Link, useNavigate } from "react-router-dom";
// import { deleteReservation } from "../../../actions/reservationAction";
import { useSelector } from "react-redux";
import AdminCancelBooking from "./AdminCancelBooking";

const AdminPendingOrderRowComponent = ({
  current,
  i,
  handleModal,
  dropDownRow,
  setDropDownRow,
}) => {
  const [showMoreVertDropDown, setshowMoreVertDropDown] = useState(false);
  const openMoreVertDropDown = () => {
    setshowMoreVertDropDown((showMoreVertDropDown) => !showMoreVertDropDown);
  };
  const [showRideLogDropDown, setshowRideLogDropDown] = useState(false);
  const openRideLogDropDown = () => {
    setshowRideLogDropDown((showRideLogDropDown) => !showRideLogDropDown);
  };
  const [values, setValues] = useState({
    status: "Unassigned",
  });
  useEffect(() => {
    setValues({
      status: current.driverBookingStatus,
    });
  }, [current]);
  const [showDropDown, setshowDropDown] = useState(false);
  const openDropDown = () => {
    setshowDropDown((showDropDown) => !showDropDown);
  };
  const [showReassignedModal, setShowReassignedModal] = useState(false);
  const openShowReassignedModal = () => {
    setShowReassignedModal((showReassignedModal) => !showReassignedModal);
  };
  const handleChange = (fieldName) => (event) => {
    if (event.target.value === "Assigned") {
      setValues({
        ...values,
        [fieldName]: "Unassigned",
      });
    }
    if (event.target.value === "Assigned") {
      openDropDown();
    }
  };
  const [condition, setCondition] = useState("normal");
  const returnTrip = () => {
    setCondition("reverse");
    openShowReassignedModal();
  };
  const copyTrip = () => {
    setCondition("normal");
    openShowReassignedModal();
  };
  const navigate = useNavigate();
  const [showDeclineDropDown, setshowDeclineDropDown] = useState(false);
  const openDeclineDropDown = () => {
    setshowDeclineDropDown((showDeclineDropDown) => !showDeclineDropDown);
  };
  const auth = useSelector((state) => state.auth);

  return (
    <>
      <tr
        role="row"
        className="odd border"
        key={i}
        onMouseLeave={() => setshowMoreVertDropDown(false)}
      >
        <td className="border-right">{current?.orderId}</td>
        <td className="border-right">
          {current?.specialNumber
            ? current.specialNumber
            : current?.loadNumber
            ? current.loadNumber
            : ""}
        </td>
        <td className="border-right">
          {current?.driverId?.firstName} {current?.driverId?.lastName}
        </td>
        {/* <td className="border-right">{current?.assetId?.assetNo}</td> */}
        <td className="border-right">
          <strong>Pickup:</strong>{" "}
          {parseInt(current?.pickUpTime?.substring(0, 2)) === 0
            ? 12
            : parseInt(current?.pickUpTime?.substring(0, 2)) > 12
            ? parseInt(current?.pickUpTime?.substring(0, 2)) - 12
            : parseInt(current?.pickUpTime?.substring(0, 2))}
          {":" + current?.pickUpTime?.substring(3, 5)}
          {parseInt(current?.pickUpTime?.substring(0, 2)) > 11 ? "pm" : "am"}
          <br />
          <strong>Dropoff:</strong>{" "}
          {parseInt(current.dropOffTime?.substring(0, 2)) === 0
            ? 12
            : parseInt(current?.dropOffTime?.substring(0, 2)) > 12
            ? parseInt(current?.dropOffTime?.substring(0, 2)) - 12
            : parseInt(current?.dropOffTime?.substring(0, 2))}
          {":" + current?.dropOffTime?.substring(3, 5)}
          {parseInt(current?.dropOffTime?.substring(0, 2)) > 11 ? "pm" : "am"}
          <br />
          {/* 1 hrs 5 mint */}
          <strong>ETA:</strong> {current?.fareAndDistance?.estimatedTime}
        </td>
        <td className="border-right">
          <strong>
            {current?.customerId?.firstName} {current?.customerId?.lastName}
          </strong>
          <br />
          {current?.customerId?.phone}
          <br />
        </td>
        <td className="border-right w-30">
          <div className="d-flex justify-content-between">
            <div>
              <div>
                <p className="f-12 font-medium mb-0">
                  <strong>{current?.pickUpAddress?.address}</strong>{" "}
                </p>
                {/* <p className="f-12 mb-0">IL 60609, USA</p> */}
              </div>
              <div>
                <time>
                  {" "}
                  {moment(current?.pickUpDate?.substring(0, 10)).format(
                    "MM/DD/YYYY"
                  )}{" "}
                  -{" "}
                  {parseInt(current?.pickUpTime?.substring(0, 2)) === 0
                    ? 12
                    : parseInt(current?.pickUpTime?.substring(0, 2)) > 12
                    ? parseInt(current?.pickUpTime?.substring(0, 2)) - 12
                    : parseInt(current?.pickUpTime?.substring(0, 2))}
                  {":" + current?.pickUpTime?.substring(3, 5)}
                  {parseInt(current?.pickUpTime?.substring(0, 2)) > 11
                    ? "pm"
                    : "am"}
                </time>
              </div>
            </div>
            <div className="mt-4 px-4">
              <div className="hidden sm:block">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="50"
                  height="8"
                  viewBox="0 0 40 6"
                  fill="none"
                >
                  <path
                    d="M40 3L35 0.113249V5.88675L40 3ZM0 3.5H35.5V2.5H0V3.5Z"
                    fill="black"
                  ></path>
                </svg>
              </div>
              <p className="text-center font-medium">
                {/* <span>{current.distanceFromBaseLocation} {current.distanceFromPickToDropOff} {current.totalMileage} mi</span> */}
                <span>{current?.distanceFromPickToDropOff} mi</span>
              </p>
            </div>
            <div>
              <div>
                <p className="f-12 font-medium mb-0">
                  <strong>{current?.dropOffAddress?.address}</strong>
                </p>
                {/* <p className="f-12 mb-0">IL 60623</p> */}
              </div>
              <div>
                <time>
                  {" "}
                  {moment(current?.dropOffDate?.substring(0, 10)).format(
                    "MM/DD/YYYY"
                  )}{" "}
                  -{" "}
                  {parseInt(current?.dropOffTime?.substring(0, 2)) === 0
                    ? 12
                    : parseInt(current?.dropOffTime?.substring(0, 2)) > 12
                    ? parseInt(current?.dropOffTime?.substring(0, 2)) - 12
                    : parseInt(current?.dropOffTime?.substring(0, 2))}
                  {":" + current?.dropOffTime?.substring(3, 5)}
                  {parseInt(current?.dropOffTime?.substring(0, 2)) > 11
                    ? "pm"
                    : "am"}
                </time>
              </div>
            </div>
          </div>
        </td>
        <td className="border-right">{current?.type?.name}</td>
        {/* <td className="border-right"><strong>$787.45</strong> */}
        <td className="border-right">
          <strong>${current?.totalAmount?.toFixed(2)}</strong>
          {/* <i data-toggle="tooltip" className="fa fa-info-circle fa-w-16 text-info"></i> */}
          <i data-toggle="tooltip"></i>
        </td>{" "}
        <td className="border-right">
          <strong>${current?.fareAndDistance?.driverAmount?.toFixed(2)}</strong>
        </td>
        <td className="border-right">
          {current?.status === "Requested" ? "Pending" : "Pending"}
        </td>
        {/* 
        <td className="border-right">
          <div className="select-picker">
            {values && values.status && values.status !== "Pending" ? (
              <select
                className="form-control select-picker assign_role"
                style={{ height: "35px" }}
                value={values.status}
                onChange={handleChange("status")}
              >
                <option value="Unassigned">Unassigned</option>
                <option value="Assigned">Assigned</option>
                <option value="Pending">Pending</option>
              </select>
            ) : (
              <select
                className="form-control select-picker assign_role"
                style={{ height: "35px" }}
                value={values.status}
                onChange={handleChange("status")}
                disabled
              >
                <option value="Unassigned">Unassigned</option>
                <option value="Assigned">Assigned</option>
                <option value="Pending">Pending</option>
              </select>
            )}
          </div>
        </td> */}
        <td className=" text-right pr-20">
          <div className="task_view">
            <div className="dropdown">
              <Link
                className="task_view_more d-flex align-items-center justify-content-center dropdown-toggle"
                type="link"
                data-toggle="dropdown"
                onClick={openMoreVertDropDown}
                aria-haspopup="true"
                aria-expanded="false"
              >
                <i className="icon-options-vertical icons"></i>
              </Link>
              <div
                className={`dropdown-menu dropdown-menu-right ${
                  showMoreVertDropDown
                    ? "dropdown-menu dropdown-menu-right show"
                    : "dropdown-menu dropdown-menu-right"
                }`}
                aria-labelledby="dropdownMenuLink-20"
              >
                <button
                  onClick={() =>
                    navigate(`/booking/details/${current._id}`, {
                      state: current,
                    })
                  }
                  className="dropdown-item"
                >
                  <i className="fa fa-eye"> View</i>
                </button>
                {auth?.user?.permissionId?.dispatching?.update === true && (
                  <Link
                    className="dropdown-item"
                    to={`/dispatch-edit/${current._id}`}
                    // onClick={() => handleModal(current)}
                  >
                    <i className="fa fa-edit"> Edit</i>
                  </Link>
                )}
                {auth?.user?.permissionId?.booking?.create === true && (
                  <Link
                    className="dropdown-item"
                    to={`/dispatch-edit/${current._id}?copy=true`}
                  >
                    <i className="fa fa-copy"> Copy</i>
                  </Link>
                )}
                {auth?.user?.permissionId?.dispatching?.delete === true && (
                  <Link
                    className="dropdown-item delete-table-row"
                    to=""
                    data-id="20"
                    onClick={openDeclineDropDown}
                    // onClick={() => reservationDelete(current?._id)}
                  >
                    <i className="fa fa-trash"> Cancel</i>
                  </Link>
                )}
                <Link
                  className="dropdown-item delete-table-row"
                  to=""
                  onClick={openRideLogDropDown}
                  data-id="20"
                >
                  <i className="fa fa-address-card-o"> Assigning Details</i>
                </Link>
              </div>
            </div>
          </div>
        </td>
      </tr>
      <AdminDispatchingTodayOrderRideLogModal
        showRideLogDropDown={showRideLogDropDown}
        openRideLogDropDown={openRideLogDropDown}
        current={current}
        index={i}
      />
      {/* <AdminDispatchAssignModalComponent
        showDropDown={showDropDown}
        openDropDown={openDropDown}
        current={current}
        index={i}
      /> */}
      <AdminCancelBooking
        showDeclineDropDown={showDeclineDropDown}
        openDeclineDropDown={openDeclineDropDown}
        current={current}
        index={i}
        assigned={"pending"}
      />
    </>
  );
};
export default AdminPendingOrderRowComponent;
