import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { routeReportSearchByDriverId } from '../../../actions/kpiActions';
import Select from "react-select";

const AdminDriverRouteTripReportSearchandFilter = () => {
    // const auth = useSelector((state) => state.auth);
    const users = useSelector((state) => state.users);
    // const kpi = useSelector((state) => state.kpi);
    const dispatch = useDispatch()
    const [values, setValues] = useState({
        driverId: '',
        routeNumber: '',
        from:'',
        to:'',
    })
    const handleChange = (fieldName) => (event) => {
        setValues({
            ...values, [fieldName]:
                event.target.value
        })
    }
    
    let reportSearch;
    reportSearch = {
        driverId: values.driverId,
        routeNumber: values.routeNumber,
        from: values.from,
        to:values.to,
    }

    const handleClick = (event) => {
        event.preventDefault()
        for (let key of Object.keys(reportSearch)) {
            if(reportSearch[key] === '' ){
                delete reportSearch[key]
            }
        }
        // dispatch(tabReportSearch())
        dispatch(routeReportSearchByDriverId(reportSearch))
    }
    return (
        <>
            {/* <form onSubmit={handleClick}> */}
            <div className="row p-3">
                <div className="col-lg-3">
                    <div className="form-group my-3">
                        <label className="f-14 text-dark mb-12" data-label="">Driver Name</label>
                        {/* <input type="text" placeholder="Search Customer Name" className="form-control height-35 f-14"
                        value={values.name} onChange={handleChange('name')}/> */}
                        <Select
                            className="basic-single"
                            classNamePrefix="select"
                            
                            defaultValue={""}
                            value={users?.allDriversWithoutPagination
                            ?.map((x) => ({
                                label: `${x.firstName} ${x.lastName}`,
                                value: x.id,
                            }))
                            .find(
                                (customer) => customer.value === values?.driverId
                            )
                            }
                            isDisabled={false}
                            isClearable={false}
                            isRtl={false}
                            isSearchable={true}
                            // required={true}
                            // name="pickUp"
                            onChange={(e) =>
                                {
                                    setValues({
                                        ...values,
                                        driverId: e.value,
                                    })
                                }
                            }
                            options={users?.allDriversWithoutPagination?.map((x) => ({
                            label: `${x.firstName} ${x.lastName} `,
                            value: x.id,
                            }))}
                        />
                    </div>
                </div>
                <div className="col-lg-3">
                    <div className="form-group my-3">
                        <label className="f-14 text-dark-grey mb-12" data-label="">Route Number</label>
                        <input type="number" placeholder="Search Route Number"
                        value={values.routeNumber} onChange={handleChange('routeNumber')}
                        className="form-control height-35 f-14" />
                    </div>
                </div>
                <div className="col-lg-2">
                    <div className="form-group my-3">
                        <div className="d-flex">
                            <div>
                                <label className="f-14 text-dark mb-12" data-label="" htmlFor="clnumber">From</label>
                            </div>
                        </div>
                        <input type="date" className="form-control height-35 f-14" 
                        value={values.from} onChange={handleChange('from')}
                        autoComplete="off" />
                    </div>
                </div>
                <div className="col-lg-2">
                    <div className="form-group my-3">
                        <div className="d-flex">
                            <div>
                                <label className="f-14 text-dark mb-12" data-label="" htmlFor="clnumber">To</label>
                            </div>
                        </div>
                        <input type="date" className="form-control height-35 f-14" placeholder="" id="driver" min="0" 
                        value={values.to} onChange={handleChange('to')}
                        autoComplete="off" />
                    </div>
                </div>
                <div className="col-lg-2">
                    <div className="form-group my-2">
                        {/* <label className="f-14 text-dark-grey mb-12" data-label="" ></label> */}
                        <br /><br />
                        {/* <ExcelExport excelData={data} fileName={`Report ${moment().format('DD-MM-YYYY')}`}/> */}
                        <button className="btn btn-success btn-block" 
                        // type="submit"
                        onClick={handleClick}
                        > Search </button>
                    </div>
                </div>
            </div>
            {/* </form> */}
        </>
    )
}
export default AdminDriverRouteTripReportSearchandFilter