/** @format */

import React, { useState } from "react";
import moment from "moment";
import { updateReservation } from "../../../actions/reservationAction";
import { useDispatch, useSelector } from "react-redux";

const AdminOptimizeBookingRow = ({ current, i }) => {
  const [isUpdate, setIsUpdate] = useState(false);
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const service = useSelector((state) => state.service);
  const sockets = useSelector((state) => state.sockets);
  const { socketInstance } = sockets;
  const extraFunc = () => {
    setIsUpdate(true);
  };
  const handleUpdate = () => {
    const assignDriverData = {
      _id: current?.booking.id,
      driverId: current?.suggestedDriver?._id,
      assigneeId: auth.user.id,
      assignedDate: moment().format().toString().substring(0, 19) + ".000Z",
      dispatcherId: auth.user.id,
    };
    dispatch(updateReservation(assignDriverData, extraFunc, true));
    socketInstance.emit("new-ride-req-send", {
      _id: assignDriverData._id,
      driverId: assignDriverData.driverId,
    });
    setIsUpdate(true);
  };
  // Assume this is your data containing booking types
  // const bookingTypes = [
  //   { id: "63cb14f63f55723694dbd189", name: "Ambulatory" },
  //   { id: "63cb14f63f55723694dbd18b", name: "Wheelchair" },
  //   // Add more booking types as needed
  // ];

  // Mapping object for booking type IDs to names
  const bookingTypeMap = {};
  service?.service?.forEach((type) => {
    bookingTypeMap[type.id] = type.name;
  });
  // console.log(bookingTypeMap, "bookingTypeMap",)

  const routeTripD = current?.distanceMiles
    ? (current?.distanceMiles?.toFixed(2) / 25).toString().split(".")
    : 0.0;
  const routeTripDHrs = routeTripD[0];
  let routeTripDMin;
  let routeTripDMinStr = "0." + routeTripD[1];
  routeTripDMinStr = parseFloat(routeTripDMinStr);
  routeTripDMin = routeTripDMinStr;
  const routeTripD2 = routeTripDMin * 60;
  const routeTripDMin2 = routeTripD2.toString().split(".");
  let displayTime = routeTripDHrs + "h " + routeTripDMin2[0] + "m";
  return (
    <>
      <tr role="row" className="odd border" key={i}>
        {/* <tr role="row" className="odd border" key={i} onMouseLeave={() => setshowMoreVertDropDown(false)}> */}
        <td className="border-right">{current?.booking?.orderId}</td>
        <td className="border-right">
          {current?.replacingDriver?.firstName}{" "}
          {current?.replacingDriver?.lastName}
        </td>
        <td className="border-right">
          {current?.suggestedDriver?.firstName}{" "}
          {current?.suggestedDriver?.lastName}
          {current?.suggestedDriver && !isUpdate && (
            <button
              className="btn-success"
              style={{ marginLeft: "10px", width: "10%" }}
              onClick={handleUpdate}
            >
              {" "}
              <i className="fa fa-check"></i>{" "}
            </button>
          )}
          <br />
          {current?.distanceMiles || current?.distanceMiles === 0
            ? current?.distanceMiles?.toFixed(2) + "mi" + " ETA: " + displayTime
            : ""}
        </td>
        {/* <td className="border-right">
          {bookingTypeMap[current?.suggestedDriver?.vehicleId?.serviceType]}
        </td> */}
        <td className="border-right">
          {/* {bookingTypeMap[current?.suggestedDriver?.vehicleId?.serviceType]} */}
          {bookingTypeMap[current?.booking?.type] + " "}
          {current?.booking?.serviceName && (
            <i
              className="fa fa-exclamation-circle fa-w-16 text-info"
              title={current?.booking?.serviceName}
              data-toggle="modal"
              data-target="#myDriver"
              style={{ cursor: "pointer" }}
            ></i>
          )}{" "}
        </td>
        <td className="border-right w-30">
          <div className="d-flex justify-content-between">
            <div>
              <div>
                <p className="f-12 font-medium mb-0">
                  <strong>{current?.booking?.pickUpAddress?.address}</strong>{" "}
                </p>
              </div>
              <div>
                <time>
                  {" "}
                  {moment(current?.booking?.pickUpDate).format(
                    "MM/DD/YYYY"
                  )} -{" "}
                  {parseInt(current?.booking?.pickUpTime.substring(0, 2)) === 0
                    ? 12
                    : parseInt(current?.booking?.pickUpTime.substring(0, 2)) >
                      12
                    ? parseInt(current?.booking?.pickUpTime.substring(0, 2)) -
                      12
                    : parseInt(current?.booking?.pickUpTime.substring(0, 2))}
                  {":" + current?.booking?.pickUpTime.substring(3, 5)}
                  {parseInt(current?.booking?.pickUpTime.substring(0, 2)) > 11
                    ? "pm"
                    : "am"}
                </time>
              </div>
            </div>
            <div className="mt-4 px-4">
              <div className="hidden sm:block">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="50"
                  height="8"
                  viewBox="0 0 40 6"
                  fill="none"
                >
                  <path
                    d="M40 3L35 0.113249V5.88675L40 3ZM0 3.5H35.5V2.5H0V3.5Z"
                    fill="black"
                  ></path>
                </svg>
              </div>
              <p className="text-center font-medium">
                <span>
                  {current?.booking?.distanceFromPickToDropOff?.toFixed(2)} mi
                  {/* {current?.booking?.totalMileage} mi */}
                </span>
              </p>
            </div>
            <div>
              <div>
                <p className="f-12 font-medium mb-0">
                  <strong>{current?.booking?.dropOffAddress?.address}</strong>
                </p>
              </div>
              <div>
                <time>
                  {" "}
                  {moment(current?.booking?.dropOffDate).format(
                    "MM/DD/YYYY"
                  )} -{" "}
                  {parseInt(current?.booking?.dropOffTime.substring(0, 2)) === 0
                    ? 12
                    : parseInt(current?.booking?.dropOffTime.substring(0, 2)) >
                      12
                    ? parseInt(current?.booking?.dropOffTime.substring(0, 2)) -
                      12
                    : parseInt(current?.booking?.dropOffTime.substring(0, 2))}
                  {":" + current?.booking?.dropOffTime.substring(3, 5)}
                  {parseInt(current?.booking?.dropOffTime.substring(0, 2)) > 11
                    ? "pm"
                    : "am"}
                </time>
              </div>
            </div>
          </div>
        </td>
        <td>{current?.routeToShow}</td>
        {/* <td className="border-right w-30">
          <div className="d-flex justify-content-between">
            <div>
              <div>
                <p className="f-12 font-medium mb-0">
                  <strong>
                    {current?.suggestedDriver?.completeAddress?.address}
                  </strong>
                </p>
              </div>
            </div>
          </div>
        </td> */}
      </tr>
    </>
  );
};
export default AdminOptimizeBookingRow;
