/** @format */

import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import { getAllUser } from "../../../actions/userActions";
import defaultimg from "../../../assets/images/profile.jpg";
import { NavLink, useLocation } from "react-router-dom";
import AdminUserEditNewModal from "./AdminUserEditNewModal";

const AdminUserViewComponents = () => {
  const { state } = useLocation();
  const users = useSelector((state) => state.users);
  const auth = useSelector((state) => state.auth);
  const [selectedImage, setSelectedImage] = useState(defaultimg);

  const dispatch = useDispatch();
  const userData = async (e) => {
    dispatch(
      getAllUser({ companyId: auth.user.companyId.id, isClient: false })
    );
  };
  useEffect(() => {
    userData();
  }, []);

  useEffect(() => {
    if (auth.user !== null) {
      // setValues({
      // address: auth.user.address,
      // state: auth.user.state,
      // pinCode: auth.user.pinCode,
      // phone: auth.user.phone,
      // })
      if (state.profilePic) {
        setSelectedImage(state.profilePic.fileUrl);
      }
    }
  }, [auth.user]);

  const [showDropDown, setshowDropDown] = useState(false);
  const openDropDown = () => {
    setshowDropDown((showDropDown) => !showDropDown);
  };
  return (
    <>
      <div className="row emp-dash-detail">
        <div className="col-xl-12 col-md-6 mb-4 mb-lg-0">
          <div className="card border-0 b-shadow-4">
            <div className="card-horizontal align-items-center">
              {/* <div className="card-img" style={{borderRadius: "50%"}}>
                                <img className="" src={img1} alt="" />
                            </div> */}
              <div className="modal-body">
                {/* <div className="card-img" style={{borderRadius: "50%", margin: "0 auto 30px"}}> */}
                <div
                  className="profile-img-wrap edit-img"
                  style={{ margin: "0 auto 30px" }}
                >
                  {selectedImage && (
                    <img
                      className="inline-block"
                      src={selectedImage}
                      alt="Profile Pic"
                    />
                  )}
                  {/* <img className="inline-block" src={defaultimg} alt="" style={{ border: "solid 1px #616e80"}}/> */}
                  {/* <div className="fileupload btn">
                                        <span className="btn-text">edit</span>
                                        <input className="upload" type="file" accept='.jpg, .jpeg, .png' 
                                            // onChange={handleChangePic} 
                                        />
                                    </div> */}
                </div>
              </div>
              <div className="card-body border-0 pl-0">
                <div className="row">{/* row kam ki? */}</div>
                {auth?.user?.permissionId?.user?.update === true && (
                  <a className="edit-icon" onClick={openDropDown}>
                    <i className="fa fa-pencil"></i>
                  </a>
                )}
                <div className="row p-3">
                  <div className="col-lg-6">
                    <div className="col-12 px-0 pb-3 d-lg-flex d-md-flex d-block">
                      <p className="mb-0  f-14 text-capitalize">
                        <strong>Full Name: </strong>
                        {state?.firstName} {state?.lastName}
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    {/* <div className="pro-edit"> */}
                    {/* <a className="edit-icon" onClick={openDropDown}>
                                                <i className="fa fa-pencil"></i>
                                            </a> */}
                    {/* </div> */}
                    <div className="col-12 px-0 pb-3 d-lg-flex d-md-flex d-block">
                      <p className="mb-0 f-14 text-capitalize">
                        <strong>Phone: </strong>
                        {state?.phone}
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="col-12 px-0 pb-3 d-lg-flex d-md-flex d-block">
                      <p className="mb-0  f-14 w-30 text-capitalize">
                        <strong>Permission: </strong>
                        {state?.permissionId?.name}
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    {/* <div className="col-12 px-0 pb-3 d-lg-flex d-md-flex d-block">
                                            <p className="mb-0 f-14 text-capitalize"><strong>Phone: {" "}</strong>{state?.phone}</p>
                                        </div> */}
                    <div className="col-12 px-0 pb-3 d-lg-flex d-md-flex d-block">
                      <p className="mb-0  f-14 text-capitalize">
                        <strong>Email: </strong>
                        {state?.email}
                      </p>
                    </div>
                  </div>

                  {/* <div className="col-lg-6">
                                        <div className="col-12 px-0 pb-3 d-lg-flex d-md-flex d-block">
                                            <p className="mb-0  f-14 w-30 text-capitalize"><strong>User Type: {" "}</strong>{state?.role}</p>
                                        </div>
                                    </div> */}
                  <div className="col-lg-6">
                    {/* <div className="col-12 px-0 pb-3 d-lg-flex d-md-flex d-block">
                                            <p className="mb-0  f-14 text-capitalize"><strong>Email: {" "}</strong>{state?.email}</p>
                                        </div> */}
                    <div className="col-12 px-0 pb-3 d-lg-flex d-md-flex d-block">
                      <p className="mb-0  f-14 text-capitalize">
                        <strong>Address: </strong>
                        {state?.address}
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="col-12 px-0 pb-3 d-lg-flex d-md-flex d-block">
                      <p className="mb-0  f-14 text-capitalize">
                        <strong>Username: </strong>
                        {state?.username}
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="col-12 px-0 pb-3 d-lg-flex d-md-flex d-block">
                      <p className="mb-0  f-14 text-capitalize">
                        <strong>City: </strong>
                        {state?.city}
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="col-12 px-0 pb-3 d-lg-flex d-md-flex d-block">
                      <p className="mb-0  f-14 text-capitalize">
                        <strong>Last login at: </strong>-----
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="col-12 px-0 pb-3 d-lg-flex d-md-flex d-block">
                      <p className="mb-0  f-14 text-capitalize">
                        <strong>State: </strong>
                        {state?.state}
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="col-12 px-0 pb-3 d-lg-flex d-md-flex d-block">
                      <p className="mb-0  f-14 text-capitalize">
                        <strong>Hours Logged: </strong>-----
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="col-12 px-0 pb-3 d-lg-flex d-md-flex d-block">
                      <p className="mb-0  f-14 text-capitalize">
                        <strong>Is this User Driver: </strong>
                        {state?.isDriver === true ? "Yes" : "No"}
                      </p>
                    </div>
                  </div>
                  {state?.isDriver === true && (
                    <div className="col-lg-12">
                      <div className="col-12 px-0 pb-3 d-lg-flex d-md-flex d-block">
                        <p className="mb-0  f-14 text-capitalize">
                          <strong>Vehicle No: </strong>
                          {state?.vehicleId?.assetNo
                            ? state?.vehicleId?.assetNo
                            : "-"}
                        </p>
                      </div>
                    </div>
                  )}
                  {state?.isDriver === true && (
                    <div className="col-lg-12">
                      <div className="col-12 px-0 pb-3 d-lg-flex d-md-flex d-block">
                        <p className="mb-0  f-14 text-capitalize">
                          <strong>Company User: </strong>
                          {state?.isCompanyDriverForZero === true
                            ? "Yes"
                            : "No"}
                        </p>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <AdminUserEditNewModal
        current={state}
        showDropDown={showDropDown}
        openDropDown={openDropDown}
      />
    </>
  );
};
export default AdminUserViewComponents;

{
  /* <p className="f-13 font-weight-normal text-dark-grey mb-0"> Job Title: {" "} {state?.jobTitle} </p> */
}
{
  /* <p className="card-text f-12 ">Last login at -- </p> */
}
{
  /* <div className="card-footer bg-white border-top-grey pl-0">                                     */
}
{
  /* <div className="d-flex flex-wrap justify-content-between"> */
}

{
  /* <span>
            <label className="f-11 text-dark-grey mb-12 text-capitalize" htmlFor="usr">Administrator</label>
            <p className="mb-0 f-18 f-w-500">{state?.jobTitle}</p>
        </span> */
}

{
  /* <span>
            <label className="f-11 text-dark-grey mb-12 text-capitalize" htmlFor="usr">Hours Logged</label>
            <p className="mb-0 f-18 f-w-500">13</p>
        </span> */
}

{
  /* </div> */
}
{
  /* </div> */
}

{
  /* <div className="col-xl-6 col-md-6 mb-4 mb-lg-0"> */
}
{
  /* <div className="card bg-white border-0 b-shadow-4 mb-4"> */
}
{
  /* <div className="card-header bg-white border-0 text-capitalize d-flex justify-content-between p-20">
            <h4 className="f-18 f-w-500 mb-0">Profile Info</h4>
        </div>
        <div className="card-body ">
            
            <div className="col-12 px-0 pb-3 d-lg-flex d-md-flex d-block">
                <p className="mb-0  f-14 w-30 text-capitalize">Full Name</p>
                <p className="mb-0 text-dark-grey f-14 w-70 text-wrap">{state.firstName} {state.lastName}</p>
            </div>
            <div className="col-12 px-0 pb-3 d-lg-flex d-md-flex d-block">
                <p className="mb-0  f-14 w-30 text-capitalize">Email</p>
                <p className="mb-0 text-dark-grey f-14 w-70 text-wrap">{state.email}	</p>
            </div>
            <div className="col-12 px-0 pb-3 d-lg-flex d-md-flex d-block">
                <p className="mb-0  f-14 w-30 text-capitalize">Phone Number</p>
                <p className="mb-0 text-dark-grey f-14 w-70 text-wrap">{state.phone}</p>
            </div>
            <div className="col-12 px-0 pb-3 d-lg-flex d-md-flex d-block">
                <p className="mb-0 f-14 w-30 text-capitalize">User Type</p>
                <p className="mb-0 text-dark-grey f-14 w-70 text-wrap">{state.role}</p>
            </div> <div className="col-12 px-0 pb-3 d-lg-flex d-md-flex d-block">
                <p className="mb-0  f-14 w-30 text-capitalize">Department</p>
                <p className="mb-0 text-dark-grey f-14 w-70 text-wrap">{state.jobTitle}</p>
            </div>
            <div className="col-12 px-0 pb-3 d-lg-flex d-md-flex d-block">
                <p className="mb-0  f-14 w-30 text-capitalize">Address</p>
                <p className="mb-0 text-dark-grey f-14 w-70 text-wrap">{state.address}</p>
            </div>
            <div className="col-12 px-0 pb-3 d-lg-flex d-md-flex d-block">
                <p className="mb-0  f-14 w-30 text-capitalize">City</p>
                <p className="mb-0 text-dark-grey f-14 w-70 text-wrap">{state.city}	</p>
            </div>
            <div className="col-12 px-0 pb-3 d-lg-flex d-md-flex d-block">
                <p className="mb-0  f-14 w-30 text-capitalize">State</p>
                <p className="mb-0 text-dark-grey f-14 w-70 text-wrap">{state.state}</p>
            </div>
            <div className="col-12 px-0 pb-3 d-lg-flex d-md-flex d-block">
                <p className="mb-0  f-14 w-30 text-capitalize">Username</p>
                <p className="mb-0 text-dark-grey f-14 w-70 text-wrap">{state.username}</p>
            </div>
        </div> */
}
{
  /* </div> */
}

{
  /* <div className="card bg-white border-0 b-shadow-4 mb-4">
        <div className="card-header bg-white border-0 text-capitalize d-flex justify-content-between p-20">
            <h4 className="f-18 f-w-500 mb-0">Appreciation</h4>
        </div>
        <div className="card-body ">
            <p className="f-14 text-dark-grey">No record found.</p>
        </div>
    </div> */
}
{
  /* <div className="card bg-white border-0 b-shadow-4 mb-4">
        <div className="card-body ">
            <div className="d-flex justify-content-between">
                <div className="col-6">
                    <p className="f-14 text-dark-grey">Reporting To</p>
                    --
                </div>
                <div className="col-6">
                    <p className="f-14 text-dark-grey">Reporting Team</p>
                    --
                </div>
            </div>
        </div>
    </div> */
}
{
  /* <div className="card">
        <div className="card-header">
            <h2 className="f-18 f-w-500 mb-0">Tasks</h2>
        </div>
        <div className="card-body" style={{alignSelf: "center"}}>
            <canvas id="sales-chart-c" className="mt-2" style={{width: "250px", height: "250px"}}></canvas>
        </div>
    </div> */
}
{
  /* </div> */
}
