import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { navigateUserFunc } from '../../actions/generalActions'
import { getAllUser } from '../../actions/userActions'
import AdminHeader from '../../components/adminComponents/adminHeaderSidebarTitleComponents/AdminHeader'
import AdminSidebar from '../../components/adminComponents/adminHeaderSidebarTitleComponents/AdminSidebar'
import AdminUserViewComponents from '../../components/adminComponents/adminUserComponents/AdminUserViewComponents'
import NotAllowed from '../../components/NotAllowed'

const AdminUserViewPage = () => {
    // const params = useParams();
    // const dispatch = useDispatch()
    // const navigate = useNavigate();
    // useEffect(() => {
    //     if (params.id) {
    //         // console.log("dispatch")
    //         dispatch(getuserby({id: params.id}, navigate))
    //     } 
    // }, [params.id]);
    let navigate = useNavigate();
    const auth = useSelector((state) => state?.auth);
    useEffect(() => {
        if ((auth?.user?.permissionId?.user?.isAllow === false) || (auth?.user?.disable === true)){
            navigateUserFunc(auth?.user, navigate)
        }
    },[])
    return (
        <>
            <AdminHeader />
            <AdminSidebar />
            <div className="body-wrapper clearfix">
                <section className="main-container " id="fullscreen">
                    <div className="px-4 py-2 border-top-0 emp-dashboard">
                        <div className="d-lg-flex d-md-flex d-block py-4">
                            <div>
                                <h4 className=" mb-0 f-21 text-capitalize font-weight-bold">User Details</h4>
                            </div>
                        </div>
                        {
                            auth?.user?.permissionId?.user?.read === true ?
                            <AdminUserViewComponents />
                            :
                            <NotAllowed />
                        }
                    </div>
                </section>
            </div>
        </>
    )
}
export default AdminUserViewPage