/** @format */

import React from "react";
import {
  getMessagesAction,
  sendMessageAction,
} from "../../actions/chatActions";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { useState } from "react";
import MessageItem from "./messageItem";
import { useRef } from "react";
import { MESSAGE_RECEIVE_SUCCESS } from "../../constants";
import defaultImg from "../../assets/images/profile.jpg";
import groupIcon from "../../assets/images/undraw_People_re_8spw.png";
import Loader from "../Loader";

// let activeChat;
function MessageArea() {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const chats = useSelector((state) => state.chats);

  const sockets = useSelector((state) => state.sockets);

  const { socketConnected, socketInstance } = sockets;
  const scrollRef = useRef();

  const [message, setMessage] = useState("");
  const [typing, setTyping] = useState(false);
  // const [isTyping, setIsTyping] = useState(false);

  const messageHandler = (e) => {
    setMessage(e.target.value);

    if (!socketConnected) return;
    if (!typing) {
      setTyping(true);
      socketInstance.emit("typing", {
        room: chats.selectedChat.id,
        user: {
          id: auth.user.id,
          name: auth.user.firstName + " " + auth.user.lastName,
        },
      });
    }
    let lastTime = new Date().getTime();
    var time = 3000;
    setTimeout(() => {
      var timeNow = new Date().getTime();
      var timeDiff = timeNow - lastTime;
      if (timeDiff >= time) {
        socketInstance.emit("stop typing", {
          room: chats.selectedChat.id,
          user: {
            id: auth.user.id,
            name: auth.user.firstName + " " + auth.user.lastName,
          },
        });
        setTyping(false);
      }
    }, time);
  };

  const sendMessage = (e) => {
    e.preventDefault();
    const dateTime = new Date();
    // const messageData = {
    //   senderId: auth.user.id,
    //   chatId: chats.selectedChat.id,
    //   message: message,
    //   dateTime,
    // };

    dispatch(
      sendMessageAction({
        sender: auth.user,
        chatId: chats.selectedChat,
        message: message,
        dateTime,
      })
    );
    setMessage("");
    socketInstance.emit("new message", {
      sender: {
        firstName: auth.user.firstName,
        lastName: auth.user.lastName,
        id: auth.user.id,
        ...(auth?.user?.profilePic?.fileUrl && {
          profilePic: { fileUrl: auth.user.profilePic.fileUrl },
        }),
      },
      chatId: chats.selectedChat,
      message: message,
      dateTime,
    });
  };

  // useEffect(() => {
  //   if (chats.selectedChat) {
  //     socket.on("typing", (user) => setIsTyping(user));
  //     socket.on("stop typing", (user) => setIsTyping(false));
  //     dispatch(getMessagesAction(chats.selectedChat.id));
  //     socket.emit("join room", chats.selectedChat.id);

  //     // socket.on("message recieved", (newMessageRecieved) => {
  //     //   console.log("message received==>", newMessageRecieved);

  //     //   dispatch({
  //     //     type: MESSAGE_RECEIVE_SUCCESS,
  //     //     payload: newMessageRecieved,
  //     //   });
  //     //   const lastChildElement = scrollRef.current?.lastElementChild;
  //     //   lastChildElement?.scrollIntoView({
  //     //     behavior: "smooth",
  //     //     block: "end",
  //     //     bottom: 0,
  //     //   });
  //     //   // if (
  //     //   //   (!selectedChatCompare || selectedChatCompare._id) !==
  //     //   //   newMessageRecieved.chatId._id
  //     //   // ) {
  //     //   //   if (!notifications.includes(newMessageRecieved)) {
  //     //   //     dispatch(setNotifications([newMessageRecieved, ...notifications]));
  //     //   //   }
  //     //   // } else {
  //     //   //   setMessages([...messages, newMessageRecieved]);
  //     //   // }
  //     //   // dispatch(fetchChats());
  //     // });
  //   }
  // }, [chats.selectedChat]);

  useEffect(() => {
    // socket.on("message recieved", (newMessageRecieved) => {
    //   console.log("message received==>", newMessageRecieved);

    //   dispatch({
    //     type: MESSAGE_RECEIVE_SUCCESS,
    //     payload: newMessageRecieved,
    //   });

    //   // if (
    //   //   (!selectedChatCompare || selectedChatCompare._id) !==
    //   //   newMessageRecieved.chatId._id
    //   // ) {
    //   //   if (!notifications.includes(newMessageRecieved)) {
    //   //     dispatch(setNotifications([newMessageRecieved, ...notifications]));
    //   //   }
    //   // } else {
    //   //   setMessages([...messages, newMessageRecieved]);
    //   // }
    //   // dispatch(fetchChats());
    // });
    const lastChildElement = scrollRef.current?.lastElementChild;
    lastChildElement?.scrollIntoView({
      behavior: "smooth",
      block: "end",
      bottom: 0,
    });
  }, [chats.messages]);

  const receiver = chats?.selectedChat?.users?.find(
    (x) => x.id !== auth.user.id
  );

  return (
    <div
      className="col-md-6 col-lg-7 col-xl-8 d-flex flex-column justify-content-between p-0 m-0"
      style={{ background: "#1F2937" }}
    >
      <div className="w-100 bg-gray p-2 mb-2">
        <div className="d-flex flex-row align-items-center w-100">
          <img
            src={
              chats?.selectedChat?.isGroup
                ? groupIcon
                : receiver?.profilePic
                ? receiver?.profilePic.fileUrl
                : defaultImg
            }
            // src={chat.isGroup ? "" : receiver.image}

            alt="avatar"
            className="rounded-circle d-flex align-self-center me-3-wala-new shadow-1-strong object-fit-cover"
            width="40"
            height="40"
          />
          <div className="pt-1">
            <p className="fw-bold mb-0">
              {chats?.selectedChat?.isGroup
                ? chats?.selectedChat?.chatName
                : receiver
                ? `${receiver?.firstName} ${receiver?.lastName}`
                : ""}
            </p>
            <p className="small text-muted">
              <span className="text-success fw-bold">
                {chats?.selectedChat?.users?.length} members
              </span>
            </p>
          </div>
        </div>
      </div>
      {/* {isTyping && <p className="bg-white">{isTyping.name} is typing</p>} */}

      {/* {true && <p className="bg-white">{"Shayan"} is typing</p>} */}
      {/* <div> */}
      <ul
        ref={scrollRef}
        className="list-unstyled mx-3 relative"
        style={{ overflowY: "auto" }}
      >
        {" "}
        {chats?.messages.loading ? (
          <Loader />
        ) : (
          chats?.messages?.list.length === 0 && (
            <p className="text-center bg-white p-3">
              No messages in the chat, start with sending a message!
            </p>
          )
        )}
        {chats?.messages.list.map((chatMessage) => {
          return <MessageItem chatMessage={chatMessage} />;
        })}
        {/* <li className="d-flex justify-content-end mb-1">
          <div className="card w-75">
            <div className="card-header d-flex justify-content-between p-2">
              <p className="fw-bold mb-0">Lara Croft</p>
              <p className="text-muted small mb-0">
                <i className="far fa-clock"></i> 13 mins ago
              </p>
            </div>
            <div className="card-body p-2">
              <p className="mb-0">
                Sed ut perspiciatis unde omnis iste natus error sit voluptatem
                accusantium doloremque laudantium.
              </p>
            </div>
          </div>
          <img
            src="https://mdbcdn.b-cdn.net/img/Photos/Avatars/avatar-5.webp"
            alt="avatar"
            className="rounded-circle d-flex align-self-start ms-3 shadow-1-strong"
            width="40"
          />
        </li>*/}
        {/* <li className="d-flex justify-content-end gap-2 mb-1">
          <div className="card w-75">
            <div className="card-header d-flex justify-content-between p-2">
              <p className="fw-bold mb-0">Lara Croft</p>
              <p className="text-muted small mb-0">
                <i className="far fa-clock"></i> 13 mins ago
              </p>
            </div>
            <div className="card-body p-2">
              <p className="mb-0">
                Sed ut perspiciatis unde omnis iste natus error sit voluptatem
                accusantium doloremque laudantium.
              </p>
            </div>
          </div>
          <img
            src="https://mdbcdn.b-cdn.net/img/Photos/Avatars/avatar-5.webp"
            alt="avatar"
            className="rounded-circle d-flex align-self-start  shadow-1-strong"
            width="40"
          />
        </li> */}
        {/* <li ref={scrollRef}></li> */}
      </ul>

      <form className="mb-3 mx-2" onSubmit={sendMessage}>
        <span className="bg-white d-flex align-items-center gap-2">
          <textarea
            className="form-control"
            id="textAreaExample2"
            rows="4"
            onChange={messageHandler}
            value={message}
            required
            placeholder="Message..."
          ></textarea>
          {/* <label className="form-label" htmlFor="textAreaExample2">
            Message
          </label> */}
          <button type="submit" className="btn btn-primary mx-2">
            Send
          </button>
        </span>
      </form>
      {/* </div> */}
    </div>
  );
}

export default MessageArea;
