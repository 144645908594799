/** @format */

import moment from "moment";
import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
// import { BUCKET_BASE_URL } from "../../constants";
import defaultImg from "../../assets/images/profile.jpg";
import groupIcon from "../../assets/images/undraw_People_re_8spw.png";

function ChatItem({ chat, receiver, selectChat, isTyping }) {
  const chats = useSelector((state) => state.chats);
  const auth = useSelector((state) => state.auth);

  const timeNow = new Date();
  const messageTime = new Date(chat.latestMessage?.dateTime);
  const secondsDiff = Math.round((timeNow - messageTime) / 1000);
  const hoursDiff = Math.round(secondsDiff / 60 / 60);

  let time = "";
  if (secondsDiff < 60) {
    time = "Just Now";
  } else {
    if (secondsDiff < 600) {
      time = Math.round(secondsDiff / 60) + " mins ago";
    } else {
      if (hoursDiff > 24) {
        time = moment(messageTime).format("ddd, hh:mm a");
      } else {
        // console.log("messageTIme==>", messageTime);
        time = moment(messageTime).format("hh:mm a");
      }
    }
  }
  //   console.log("first==>", timeNow, "second==>", messageTime, secondsDiff);

  return (
    <li
      key={chat.id}
      className="border-bottom w-100"
      // className="media d-flex"
      // style={{
      //   backgroundColor: chats.selectedChat?.id === chat.id ? "#eee" : "",
      // }}
    >
      {/* <Link
        to=""
        onClick={() => selectChat(chat)}
        className="d-flex justify-content-between w-100"
      >
        <div className="d-flex flex-row w-80 avatar-online">
          <img
            src={
              chat.isGroup
                ? groupIcon
                : receiver?.profilePic
                ? receiver?.profilePic?.fileUrl
                : defaultImg
            }
            // src={chat.isGroup ? "" : receiver.image}

            alt="avatar"
            className="rounded-circle d-flex align-self-center me-3-wala-new shadow-1-strong object-fit-cover"
            width="40"
            height="40"
          />
          <div className="pt-1">
            <p className="fw-bold mb-0">
              {chat.isGroup
                ? chat.chatName
                : `${receiver.firstName} ${receiver.lastName}`}
            </p>
            <p className="small text-muted">
              {isTyping && chat.id === isTyping.room ? (
                <span className="text-success fw-bold">
                  {isTyping.user.name} is typing...
                </span>
              ) : chat.latestMessage ? (
                chat?.latestMessage?.message?.length > 40 ? (
                  chat.latestMessage.message.slice(0, 40) + "...."
                ) : (
                  chat.latestMessage.message
                )
              ) : (
                ""
              )}
            </p>
          </div>
        </div>
        <div className="pt-1">
          <p className="small text-muted mb-1">{chat.latestMessage ? time : ""}</p> */}

      {/* <span className="badge bg-danger float-end">
            {" "}
            {chats.list.length -
              notifications.list.filter((x) =>
                x.readBy.find((y) => y.readerId == auth.user.id)
              ).length}
          </span> */}
      {/* </div>
      </Link> */}

      <Link
        to=""
        onClick={() => selectChat(chat)}
        className={`media d-flex ${
          chats?.selectedChat?.id === chat.id ? "read-chat active" : ""
        }`}
      >
        <div className="media-img-wrap">
          <div
            className={`avatar ${
              !chat?.isGroup
                ? receiver?.isOnline
                  ? "avatar-online"
                  : !receiver?.isOnline
                  ? "avatar-offline"
                  : ""
                : ""
            }`}
          >
            <img
              src={
                chat?.isGroup
                  ? groupIcon
                  : receiver?.profilePic
                  ? receiver?.profilePic?.fileUrl
                  : defaultImg
              }
              alt="User Image"
              className="chat-avatar-img rounded-circle inline-block"
            />
          </div>
        </div>
        <div className="media-body">
          <div>
            <div className="user-name">
              {chat?.isGroup
                ? chat?.chatName
                : `${receiver?.firstName} ${receiver?.lastName}`}
            </div>
            <div className="user-last-chat">
              {isTyping &&
              auth?.user?.id != isTyping?.user?.id &&
              chat?.id === isTyping.room ? (
                <span className="text-success fw-bold">
                  {isTyping?.user?.name} is typing...
                </span>
              ) : chat?.latestMessage ? (
                chat?.latestMessage?.message?.length > 20 ? (
                  chat.latestMessage?.message.slice(0, 20) + "...."
                ) : (
                  chat?.latestMessage?.message
                )
              ) : (
                ""
              )}{" "}
            </div>
          </div>
          <div>
            <div className="last-chat-time block">
              {chat?.latestMessage ? time : ""}
            </div>
            {/* {
              // chat.latestMessage?.sender?.id !== auth?.user?.id &&
              chat?.readBy?.length === 0 ||
                (!chat?.readBy?.some(
                  (item) => item.readerId === auth?.user?.id
                ) && (
                  <div className="badge badge-success badge-pill">&nbsp;</div>
                ))
            } */}
            {chat?.unreadCount &&
              chat?.unreadCount?.length > 0 &&
              chat.unreadCount
                .filter((x) => x.userId === auth.user.id)
                .map((val) => {
                  return (
                    val.number > 0 && (
                      <div className="badge badge-success badge-pill">
                        {val.number}
                      </div>
                    )
                  );
                })}
          </div>
        </div>
      </Link>
    </li>
  );
}
export default ChatItem;