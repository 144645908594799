/** @format */

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import defaultimg from "../../../assets/images/profile.jpg";
// import { editClientAction } from "../../../actions/userActions";
import moment from "moment";
import {
  deleteSettlement,
  updateSettlement,
} from "../../../actions/invoiceActions";

const AdminDiverSettlementOverviewRow = ({
  current,
  i,
  handleModal,
  dropDownRow,
  setDropDownRow,
}) => {
  const [showMoreVertDropDown, setshowMoreVertDropDown] = useState(false);
  const openMoreVertDropDown = () => {
    setshowMoreVertDropDown((showMoreVertDropDown) => !showMoreVertDropDown);
  };
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [selectedImage, setSelectedImage] = useState(defaultimg);
  const [showDropDown, setshowDropDown] = useState(false);
  const [showDeleteDropDown, setshowDeleteDropDown] = useState(false);
  const openDropDown = () => {
    setshowDropDown((showDropDown) => !showDropDown);
  };
  const openDeleteDropDown = () => {
    setshowDeleteDropDown((showDeleteDropDown) => !showDeleteDropDown);
  };
  const invoices = useSelector((state) => state.invoices);
  const auth = useSelector((x) => x.auth);
  const vehicle = useSelector((x) => x.vehicle);
  const vehicleFound = vehicle?.vehicle?.find(
    (x) => x.id === current?.driverId?.vehicleId
  );
  const update = () => {
    const chicagoDateOptions = {
      timeZone: "America/Chicago",
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    };

    const chicagoDate = new Date().toLocaleDateString(
      "en-US",
      chicagoDateOptions
    );
    const parts = chicagoDate.split("/");
    const formattedChicagoDate = `${parts[2]}-${parts[0]}-${parts[1]}`;
    dispatch(
      updateSettlement({
        _id: current._id ? current._id : current.id,
        status: "Paid",
        payerId: auth.user.id,
        datePaid: formattedChicagoDate,
      })
    );
  };
  const deleteSet = () => {
    dispatch(
      deleteSettlement({
        _id: current.id,
        delete: true,
      })
    );
  };
  let subStr;
  let parts;
  let displayDate;
  let orderSearch;
  const chicagoDateOptions = {
    timeZone: "America/Chicago",
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  };

  const chicagoDate = new Date().toLocaleDateString(
    "en-US",
    chicagoDateOptions
  );
  const currentDate = new Date(chicagoDate);

  // Adjust to get the Monday of the current week
  const firstDayOfWeek = new Date(currentDate);
  firstDayOfWeek.setDate(
    currentDate.getDate() - ((currentDate.getDay() + 6) % 7)
  );

  // Calculate the Sunday of the current week
  const lastDayOfWeek = new Date(firstDayOfWeek);
  lastDayOfWeek.setDate(firstDayOfWeek.getDate() + 6);

  // Format the dates to YYYY-MM-DD
  const weekFirst = `${firstDayOfWeek.getFullYear()}-${String(
    firstDayOfWeek.getMonth() + 1
  ).padStart(2, "0")}-${String(firstDayOfWeek.getDate()).padStart(2, "0")}`;
  const weekLast = `${lastDayOfWeek.getFullYear()}-${String(
    lastDayOfWeek.getMonth() + 1
  ).padStart(2, "0")}-${String(lastDayOfWeek.getDate()).padStart(2, "0")}`;
  if (current?.datePaid) {
    subStr = current?.datePaid?.substring(0, 10);
    parts = subStr?.split("-");
    displayDate = `${parts[1]}/${parts[2]}/${parts[0]}`;
  }
  const indexValue = invoices?.settlement?.findIndex(
    (obj) => obj.id == current.id
  );

  const allOrderIds = current?.reservations
    ?.map((order) => order.orderId)
    .join(", ");
  const [showAllOrders, setShowAllOrders] = useState(false);
  const handleMoreHover = () => {
    setShowAllOrders(true);
  };
  const handleMoreLeave = () => {
    setShowAllOrders(true);
    setTimeout(() => {
      setShowAllOrders(false);
    }, 10000);
  };

  const [showAllOrdersComp, setShowAllOrdersComp] = useState(false);
  const handleMoreHoverComp = () => {
    setShowAllOrdersComp(true);
  };
  const handleMoreLeaveComp = () => {
    setShowAllOrdersComp(true);
    setTimeout(() => {
      setShowAllOrdersComp(false);
    }, 10000);
  };

  return (
    <>
      <tr
        id="row-20"
        role="row"
        className="odd border"
        key={i}
        onMouseLeave={() => setshowMoreVertDropDown(false)}
      >
        <td className="border-right text-14-new">
          {`${current?.driverId?.firstName} ${current?.driverId?.lastName}`}
        </td>
        <td className="border-right text-14-new">
          {`${vehicleFound?.assetNo}`}
        </td>
        <td className="border-right text-14-new">
          {`${vehicleFound?.serviceType?.name}`}
        </td>
        <td className="border-right text-14-new">
          {
            <div
              className="text-primary"
              onMouseEnter={handleMoreHover}
              onMouseLeave={handleMoreLeave}
              title={allOrderIds}
            >
              {current?.reservation?.length}
            </div>
          }
        </td>
        <td className="border-right text-14-new">
          {moment(weekFirst).format("MMM D") +
            " - " +
            moment(weekLast).format("MMM D, YYYY")}
        </td>
        <td className="border-right text-14-new">
          ${parseFloat(current?.totalPay).toFixed(2)}
        </td>
      </tr>
    </>
  );
};
export default AdminDiverSettlementOverviewRow;
