// import * as FileSaver from 'file-saver';
import axios from "axios";
import { useState } from "react";
// import { NavLink } from 'react-router-dom';
import XLSX from "sheetjs-style";
// import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';
import { SERVERADDRESS } from "./constants";
import Dropzone from "react-dropzone";
import ProgressBar from "react-bootstrap/ProgressBar";
import ExcelExport from "./ExcelExport";
import moment from "moment";
import { notifyFailure } from "./components/toast";

const ExcelImport = () => {
  const [loading, setLoading] = useState(null);
  const [file, setFile] = useState(null);
  const [data, setData] = useState([]);
  const [progress, setProgress] = useState(0);

  const handleFileUpload = () => {
    setProgress(0);
    setLoading(true);
    setData([]);
    const reader = new FileReader();
    reader.onload = async (event) => {
      const binaryData = event.target.result;
      const workbook = XLSX.read(binaryData, { type: "binary" });
      const worksheet = workbook.Sheets[workbook.SheetNames[0]];
      let dataImport = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
      // console.log(dataImport, "dataImport")
      dataImport.shift();
      // console.log(dataImport, "datadata");

      // const { data } = await axios.post(
      //   `${SERVERADDRESS}/v1/kpi/routeCalculatorReport`,
      //   { dataImport },
      //   {
      //     onDownloadProgress: (progressEvent) => {
      //       const progress = 50 + (progressEvent.loaded / progressEvent.total) * 50;
      //       console.log(progress);
      //       setProgress(progress);
      //     },
      //   }
      // );
      dataImport = dataImport.filter((subArray) => subArray.length > 0);
      // console.log(dataImport, "dataImport.length")

      if (dataImport.length <= 100) {
        const { data } = await axios.post(
          `${SERVERADDRESS}/v1/kpi/routeCalculatorReport`,
          { dataImport }
        );
        setLoading(false);
        // console.log(dataImport, data, "ddddddfdkk");
        setProgress(100);
        setData(data);
      } else {
        let temp = [];
        const result = new Array(Math.ceil(dataImport.length / 100))
          .fill()
          .map((_) => dataImport.splice(0, 100));
        // console.log(result, "ddddddfdkk");
        for (let i = 0; i < result.length; i++) {
          let imp = result[i];
          const { data } = await axios.post(
            `${SERVERADDRESS}/v1/kpi/routeCalculatorReport`,
            { dataImport: imp }
          );
          // setPartialData([...partialData, data])
          // setPartialData(partialData => [...partialData, ...data])
          temp.push(...data);
          // let per = (i+1)/result.length
          // console.log(temp, "perperper", (i+1)/result.length, i+1,result.length)
          // console.log(per, i+1,result.length, "ddddddfdkk");
          setProgress(parseInt(((i + 1) / result.length) * 100));
        }
        setLoading(false);
        setData(temp);
        // console.log(result, data, "ddddddfdkk2");
      }
    };
    reader.readAsBinaryString(file);
  };
  const handleClearFile = () => {
    setFile(null);
    setData([]);
  };

  const exportData = data?.map((item) => ({
    "Route #": item?.routeNo,
    "First Name": item?.firstName,
    "Last Name": item?.lastName,
    "Pick Up Address": item?.pickUpAddress,
    "Drop Off Address": item?.dropOffAddress,
    "Order Mileage": item?.orderMilage,
    "Min Distance By Google": item?.min,
    "Max Distance By Google": item?.max,
    "Avg Distance of all the Routes By Google": item?.avg,
  }));

  // console.log("dat==>", data)
  return (
    <div className="m-4">
      <br />

      <Dropzone
        onDrop={(acceptedFiles) => {
          if (acceptedFiles.length > 0) {
            const file = acceptedFiles[0];
            const allowedMimeTypes = [
              "application/vnd.ms-excel", // MIME type for .xls files
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", // MIME type for .xlsx files
            ];
            if (allowedMimeTypes.includes(file.type)) {
              // The dropped file has a valid MIME type for Excel files
              setFile(file);
            } else {
              // Display an error message or handle invalid file types
              // console.error("Invalid file type. Please upload an Excel file.");
              notifyFailure("Invalid file type. Please upload a .xls file.");
            }
          }
        }}
        accept=".xls"
      >
        {({ getRootProps, getInputProps }) => (
          <section className="bg-grey p-3 py-5 cursor-pointer">
            <div className=" text-center " {...getRootProps()}>
              <div className="mb-2">
                <i className="fa fa-upload" style={{ fontSize: "36px" }}></i>
              </div>
              <input {...getInputProps()} />
              <p>Drag 'n' drop report here, or click to select report</p>
            </div>
          </section>
        )}
      </Dropzone>
      {file && (
        <div className="bg-grey p-3 my-3">
          <div className="w-50">
            {/* Percentage : {progress}% */}
            {loading && (
              <ProgressBar
                className="w-100"
                animated
                now={progress}
                label={`${progress}%`}
                style={{ backgroundColor: "#fff" }}
              />
            )}
            <div className="d-flex justify-content-between mt-3">
              <div className="d-flex align-items-center">
                <i
                  className="fa fa-file-text m-1"
                  style={{ fontSize: "28px" }}
                ></i>

                <p className="mb-0">
                  {loading ? "Uploading file" : "Selected file"}{" "}
                  <b>{file.name}</b>{" "}
                </p>
              </div>

              {loading ? (
                <span className="btn btn-danger" onClick={handleClearFile}>
                  Cancel
                </span>
              ) : (
                <span className="btn btn-success" onClick={handleFileUpload}>
                  Upload{" "}
                </span>
              )}
            </div>
          </div>
        </div>
      )}

      {/* <br />
      <br /> */}

      {file !== null && data.length > 0 && (
        <div className="d-flex flex-column w-tables rounded mt-3 bg-white">
          <div className="d-flex border">
            <div className="flex-grow-1">
              <div className="dataTables_length d-flex" id="leads-table_length">
                <div className="col-md-12 text-right">
                  <ExcelExport
                    excelData={exportData}
                    fileName={`Calculate Report ${moment().format(
                      "MM/DD/YYYY"
                    )}`}
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            id="leads-table_wrapper"
            className="dataTables_wrapper dt-bootstrap4 no-footer"
          >
            {/* <ExcelExport
              excelData={data}
              fileName={`Report ${moment().format("DD-MM-YYYY")}`}
            /> */}

            <div className="row">
              <div className="col-sm-12">
                <table
                  className="table border w-100 dataTable no-footer"
                  id="leads-table"
                  role="grid"
                  aria-describedby="leads-table_info"
                  style={{ overflowX: "auto" }}
                >
                  <thead className="card-header">
                    <tr role="row">
                      <th
                        title="Route No"
                        className="sorting border-right"
                        tabIndex="0"
                        aria-controls="leads-table"
                        rowspan="1"
                        colspan="1"
                        aria-label="Mobile: activate to sort column ascending"
                      >
                        Route No
                      </th>
                      <th
                        title="Pick Up Address"
                        className="sorting_disabled border-right"
                        rowspan="1"
                        colspan="1"
                        aria-label="Next Follow Up"
                      >
                        Pick Up Address
                      </th>
                      <th
                        title="Drop Off Address"
                        className="sorting border-right"
                        tabIndex="0"
                        aria-controls="leads-table"
                        rowspan="1"
                        colspan="1"
                        aria-label="Lead Agent: activate to sort column ascending"
                      >
                        Drop Off Address
                      </th>
                      <th
                        title="OrderMilage"
                        className="sorting border-right"
                        tabIndex="0"
                        aria-controls="leads-table"
                        rowspan="1"
                        colspan="1"
                        aria-label="Lead Agent: activate to sort column ascending"
                      >
                        Order Milage
                      </th>
                      <th
                        title="Min"
                        className="sorting border-right"
                        tabIndex="0"
                        aria-controls="leads-table"
                        rowspan="1"
                        colspan="1"
                        aria-label="Lead Agent: activate to sort column ascending"
                      >
                        Min
                      </th>
                      <th
                        title="Max"
                        className="sorting border-right"
                        tabIndex="0"
                        aria-controls="leads-table"
                        rowspan="1"
                        colspan="1"
                        aria-label="Lead Agent: activate to sort column ascending"
                      >
                        Max
                      </th>
                      <th
                        title="Avg"
                        className="sorting border-right"
                        tabIndex="0"
                        aria-controls="leads-table"
                        rowspan="1"
                        colspan="1"
                        aria-label="Lead Agent: activate to sort column ascending"
                      >
                        Avg
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {data?.map((row, i) => (
                      <tr key={i} className="odd border">
                        {/* {row?.map((cell, j) => (
                          <td className="border-right" key={`${i}-${j}`}>
                            {cell}
                          </td>
                        ))} */}
                        <td className="border-right">{row.routeNo}</td>
                        <td className="border-right">{row.pickUpAddress}</td>
                        <td className="border-right">{row.dropOffAddress}</td>
                        <td className="border-right">{row.orderMilage}</td>
                        <td className="border-right">{row.min}</td>
                        <td className="border-right">{row.max}</td>
                        <td className="border-right">{row.avg}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <div
                  id="leads-table_processing"
                  className="dataTables_processing card"
                  style={{ display: "none" }}
                >
                  Processing...
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
  {
    /* code for generating table and its rows on top */
  }
  {
    /* {data.length > 0 && (
      <table>
        <tbody>
          {data?.map((row, i) => (
            <tr key={i}>
              {row?.map((cell, j) => (
                <td key={`${i}-${j}`}>{cell}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    )} */
  }
};

export default ExcelImport;
