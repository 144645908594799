/** @format */

import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { updateCompany } from "../../../actions/authActions";
// import { getAdminCompanyInfo } from '../../../actions/companyAction'

const AdminSettingOffHourComponent = () => {
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const [values, setValues] = useState({
    Monday: "",
    Tuesday: "",
    Wednesday: "",
    Thursday: "",
    Friday: "",
    Saturday: "",
    Sunday: "",
    from: "",
    to: "",
  });

  useEffect(() => {
    if (auth.user.companyId !== null) {
      setValues({
        Monday: auth.user.companyId.availability.Monday,
        Tuesday: auth.user.companyId.availability.Tuesday,
        Wednesday: auth.user.companyId.availability.Wednesday,
        Thursday: auth.user.companyId.availability.Thursday,
        Friday: auth.user.companyId.availability.Friday,
        Saturday: auth.user.companyId.availability.Saturday,
        Sunday: auth.user.companyId.availability.Sunday,
        from: auth?.user?.companyId?.offHours?.from,
        to: auth?.user?.companyId?.offHours?.to,
      });
    }
  }, [auth.user.companyId]);

  const handleChange = (fieldName) => (event) => {
    setValues({
      ...values,
      [fieldName]: event.target.value,
    });
  };
  const adminCompInfoData = {
    companyId: auth.user.companyId.id,
    availability: {
      Monday: values.Monday,
      Tuesday: values.Tuesday,
      Wednesday: values.Wednesday,
      Thursday: values.Thursday,
      Friday: values.Friday,
      Saturday: values.Saturday,
      Sunday: values.Sunday,
    },
    offHours: {
      from: values.from,
      to: values.to,
    },
  };
  // console.log(values)
  const handleSubmit = async (event) => {
    event.preventDefault();
    // console.log("first")
    dispatch(updateCompany(adminCompInfoData));
  };
  return (
    <>
      <div className="d-flex flex-column w-tables rounded mt-3 bg-white px-4 pb-3">
        <div
          id="leads-table_wrapper"
          className="dataTables_wrapper dt-bootstrap4 no-footer"
        >
          <div className="row p-3">
            <div className="col-lg-12">
              <div className="form-group my-3">
                <label
                  className="f-14 text-dark-grey mb-12"
                  data-label=""
                  htmlFor="after_days"
                >
                  Working Days
                </label>
              </div>
            </div>
            <div className="table-responsive m-t-15">
              <table className="table table-striped custom-table">
                <thead>
                  <tr>
                    <th className="text-center">Mon</th>
                    <th className="text-center">Tue</th>
                    <th className="text-center">Wed</th>
                    <th className="text-center">Thu</th>
                    <th className="text-center">Fri</th>
                    <th className="text-center">Sat</th>
                    <th className="text-center">Sun</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="text-center">
                      <input
                        id="workingday1"
                        type="checkbox"
                        checked={values.Monday}
                        onChange={() =>
                          setValues({ ...values, Monday: !values.Monday })
                        }
                      />
                    </td>
                    <td className="text-center">
                      <input
                        id="workingday2"
                        type="checkbox"
                        checked={values.Tuesday}
                        onChange={() =>
                          setValues({ ...values, Tuesday: !values.Tuesday })
                        }
                      />
                    </td>
                    <td className="text-center">
                      <input
                        id="workingday3"
                        type="checkbox"
                        checked={values.Wednesday}
                        onChange={() =>
                          setValues({ ...values, Wednesday: !values.Wednesday })
                        }
                      />
                    </td>
                    <td className="text-center">
                      <input
                        id="workingday4"
                        type="checkbox"
                        checked={values.Thursday}
                        onChange={() =>
                          setValues({ ...values, Thursday: !values.Thursday })
                        }
                      />
                    </td>
                    <td className="text-center">
                      <input
                        id="workingday5"
                        type="checkbox"
                        checked={values.Friday}
                        onChange={() =>
                          setValues({ ...values, Friday: !values.Friday })
                        }
                      />
                    </td>
                    <td className="text-center">
                      <input
                        id="workingday6"
                        type="checkbox"
                        checked={values.Saturday}
                        onChange={() =>
                          setValues({ ...values, Saturday: !values.Saturday })
                        }
                      />
                    </td>
                    <td className="text-center">
                      <input
                        id="workingday7"
                        type="checkbox"
                        checked={values.Sunday}
                        onChange={() =>
                          setValues({ ...values, Sunday: !values.Sunday })
                        }
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="col-lg-3">
              <div className="form-group my-3">
                <label
                  className="f-14 text-dark-grey mb-12"
                  data-label=""
                  htmlFor="after_days"
                >
                  Off Hours From
                </label>
                <input
                  type="time"
                  className="form-control height-35 f-14"
                  placeholder=""
                  id="driver"
                  min="0"
                  value={values.from}
                  onChange={handleChange("from")}
                />
              </div>
            </div>
            <div className="col-lg-3">
              <div className="form-group my-3">
                <label
                  className="f-14 text-dark-grey mb-12"
                  data-label=""
                  htmlFor="after_days"
                >
                  Off Hours To
                </label>
                <input
                  type="time"
                  className="form-control height-35 f-14"
                  placeholder=""
                  id="driver2"
                  min="0"
                  value={values.to}
                  onChange={handleChange("to")}
                />
              </div>
            </div>
            <div className="col-lg-12">
              <div
                className="form-group "
                style={{ marginTop: "1.5em !important" }}
              >
                <button
                  type="button"
                  className="btn-primary rounded f-14 p-2 mr-3"
                  onClick={handleSubmit}
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default AdminSettingOffHourComponent;
