import React, { useEffect, useState } from 'react';
import axios from 'axios';

const MapComponent = () => {
  useEffect(() => {
    const coordinates = [24.91204725078819, 67.12937183690529]; // Example coordinates

    // Find the nearest road within a certain radius using Overpass API
    axios
    .get(`https://nominatim.openstreetmap.org/reverse?lat=${coordinates[0]}&lon=${coordinates[1]}&format=json`)
    .then(response => {
      const address = response.data.display_name;
// console.log(response, "response")
      // Check if the coordinates lie on a road using Overpass API
      axios
        .get(`https://overpass-api.de/api/interpreter?data=[out:json];way(around:10,${coordinates[0]},${coordinates[1]});out;`)
        .then(overpassResponse => {
          const isOnRoad = overpassResponse.data.elements.length > 0;

          if (isOnRoad) {
            // console.log(`Coordinates (${coordinates[0]}, ${coordinates[1]}) are on the road: ${address}`);
          } else {
            // console.log(`Coordinates (${coordinates[0]}, ${coordinates[1]}) are not on a road.`);
            
            // Find the nearest road within a certain radius
            // You can implement your logic to find the nearest road here
          }
        })
        .catch(error => console.error('Error checking if on road:', error));
    })
    .catch(error => console.error('Error fetching address:', error));
  }, []);

  // Function to calculate Haversine distance between two sets of coordinates
  const haversine = ([lat1, lon1], [lat2, lon2]) => {
    const R = 6371; // Earth radius in kilometers
    const dLat = (lat2 - lat1) * (Math.PI / 180);
    const dLon = (lon2 - lon1) * (Math.PI / 180);
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(lat1 * (Math.PI / 180)) * Math.cos(lat2 * (Math.PI / 180)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const distance = R * c;

    return distance;
  };
  const [nP, setNp] = useState()
  // Function to find the nearest point on a road segment to a given set of coordinates
  const findNearestPoint = (targetCoordinates, roadCoordinates) => {
    let nearestPoint = roadCoordinates[0];
    let minDistance = haversine(targetCoordinates, nearestPoint);

    for (const coord of roadCoordinates) {
      const distance = haversine(targetCoordinates, coord);
      if (distance < minDistance) {
        minDistance = distance;
        nearestPoint = coord;
      }
    }
    setNp(nearestPoint)
    // console.log(nearestPoint, "nearestPoint")
    return nearestPoint;
  };

  return <div>
    original:   
    new points: {nP}
  </div>;
};

export default MapComponent;