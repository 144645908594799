/** @format */

import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import AdminTodayOrderComponentRow from "./AdminTodayOrderComponentRow";
import { Link, NavLink } from "react-router-dom";
import RightModal from "../../RightModal";
import AdminTodayOrderEditComponent from "./AdminTodayOrderEditComponent";
import moment from "moment";
import NotAllowed from "../../NotAllowed";
import shift1 from "../../../assets/images/load.png";
// import { getReservationsScheduleV2 } from "../../../actions/reservationAction";
import { paginationDataToShow } from "../../../actions/generalActions";

// import io from "socket.io-client"
// import { useDispatch, useSelector } from 'react-redux'
// import { setReservations } from '../../../actions/reservationAction'
// const socket = io('http://localhost:5000')

const AdminTodayOrderComponent = () => {
  const auth = useSelector((state) => state.auth);
  const reservation = useSelector((state) => state.reservation);
  const [showMoreVertDropDown, setshowMoreVertDropDown] = useState(false);
  const openMoreVertDropDown = () => {
    setshowMoreVertDropDown((showMoreVertDropDown) => !showMoreVertDropDown);
  };

  const [modal, setModal] = useState({ open: false, data: null });
  const handleModal = (data) => {
    setModal({ open: !modal.open, data: data });
  };

  const dispatch = useDispatch();
  // const reservationsScheduleData = async (e) => {
  //   dispatch(
  //     getReservationsScheduleV2({
  //       companyId: auth.user.companyId.id,
  //       today: moment().format("YYYY-MM-DD"),
  //       assigned: "unassigned",
  //     })
  //   );
  // };

  // const [currentPageNumber, setCurrentPageNumber] = useState(1);

  // useEffect(() => {
  //   reservationsScheduleData();
  // }, []);
  // useEffect(() => {
  //   setCurrentPageNumber(reservation?.unassigned?.page)
  // }, [reservation?.unassigned]);

  // State to manage pagination, sorting, and limit
  const [currentPage, setCurrentPage] = useState(1);
  const [sortField, setSortField] = useState("orderId");
  const [sortOrder, setSortOrder] = useState("desc"); //asc, desc
  const [searchTerm, setSearchTerm] = useState("");
  const [limit, setLimit] = useState(10); // Set your default limit here

  // Function to handle pagination, sorting, and searching
  const handlePagination = (page, field, order, search, newLimit) => {
    let newPage;
    if (page < 1) {
      newPage = 1;
    } else if (page > paginatedData.totalPages) {
      newPage = paginatedData.totalPages;
    } else {
      newPage = page;
    }
    setCurrentPage(newPage);
    setSortField(field);
    setSortOrder(order);
    setSearchTerm(search);
    setLimit(newLimit);
  };

  const fullData = reservation?.unassigned;
  // Use the pagination function to get the data for the current page
  const paginatedData = paginationDataToShow(
    limit,
    currentPage,
    fullData,
    sortField,
    sortOrder,
    searchTerm
  );

  // Function to handle sorting icon click
  const handleSortClick = (field, order) => {
    // Call handlePagination with sorting parameters
    handlePagination(currentPage, field, order, searchTerm, limit);
  };
  // Function to determine the color based on the current sorting order
  const getArrowColor = (field, direction) => {
    return sortField === field && sortOrder === direction
      ? "#000000"
      : "#A0A0A0";
  };
  // console.log(paginatedData, "paginatedData1");

  return auth?.user?.permissionId?.dispatching?.read === true ? (
    <>
      <div className="d-flex flex-column w-tables rounded mt-3 bg-white">
        <div className="d-flex border">
          <div className="flex-grow-1">
            <div className="dataTables_length d-flex" id="leads-table_length">
              <div className="p-2">
                <label>Show entries</label>
              </div>
              <label>
                <select
                  name="leads-table_length"
                  aria-controls="leads-table"
                  className="custom-select custom-select-sm form-control form-control-sm"
                  autoComplete="false"
                  value={limit} // Make sure to set the value attribute to the current limit
                  onChange={(e) =>
                    handlePagination(
                      1,
                      sortField,
                      sortOrder,
                      searchTerm,
                      parseInt(e.target.value)
                    )
                  }
                >
                  {[10, 25, 50, 100].map((current, i) => {
                    return (
                      <option value={current} key={i}>
                        {current}
                      </option>
                    );
                  })}
                </select>
              </label>
            </div>
          </div>
          <div className="task-search task-search-new d-flex  py-1 px-lg-3 px-0 border-right-grey align-items-center">
            <form className="w-100 mr-1 mr-lg-0 mr-md-1 ml-md-1 ml-0 ml-lg-0" onSubmit={(e) => e.preventDefault()}>
              <div className="input-group bg-grey rounded">
                <div className="input-group-prepend">
                  <span className="input-group-text border-0 bg-additional-grey">
                    <i className="fa fa-search f-13 text-dark-grey"></i>
                  </span>
                </div>
                <input
                  type="text"
                  className="form-control f-14 p-1 border-additional-grey"
                  id="search-text-field"
                  placeholder="Start typing to search"
                  value={searchTerm}
                  onChange={(e) =>
                    handlePagination(
                      1,
                      sortField,
                      sortOrder,
                      e.target.value,
                      limit
                    )
                  }
                />
              </div>
            </form>
          </div>
        </div>
        <div
          id="leads-table_wrapper"
          className="dataTables_wrapper dt-bootstrap4 no-footer"
        >
          <div className="row">
            <div className="col-sm-12">
              <table
                className="table border w-100 dataTable no-footer"
                id="leads-table"
                role="grid"
                aria-describedby="leads-table_info"
                style={{ overflowX: "auto" }}
              >
                <thead className="card-header">
                  <tr role="row">
                    <th
                      title="Order ID"
                      className="sorting border-right"
                      tabIndex="0"
                      aria-controls="leads-table"
                      rowSpan="1"
                      colSpan="1"
                      aria-label="Mobile: activate to sort column ascending"
                    >
                      Order ID{" "}
                      <span className="right-sorting-new">
                        <i
                          className="fas fa-long-arrow-alt-up"
                          style={{ color: getArrowColor("orderId", "asc") }}
                          onClick={() => handleSortClick("orderId", "asc")}
                        ></i>
                        <i
                          className="fas fa-long-arrow-alt-down"
                          style={{ color: getArrowColor("orderId", "desc") }}
                          onClick={() => handleSortClick("orderId", "desc")}
                        ></i>
                      </span>
                    </th>

                    <th
                      title="Load#"
                      className="sorting border-right"
                      tabIndex="0"
                      aria-controls="leads-table"
                      rowSpan="1"
                      colSpan="1"
                      aria-label="Mobile: activate to sort column ascending"
                    >
                      Load#{" "}
                      <span className="right-sorting-new">
                        <i
                          className="fas fa-long-arrow-alt-up"
                          style={{ color: getArrowColor("loadNumber", "asc") }}
                          onClick={() => handleSortClick("loadNumber", "asc")}
                        ></i>
                        <i
                          className="fas fa-long-arrow-alt-down"
                          style={{ color: getArrowColor("loadNumber", "desc") }}
                          onClick={() => handleSortClick("loadNumber", "desc")}
                        ></i>
                      </span>
                    </th>
                    {/* <th
                      title="Driver Name"
                      className="sorting border-right"
                      tabIndex="0"
                      aria-controls="leads-table"
                      rowSpan="1"
                      colSpan="1"
                      aria-label="Mobile: activate to sort column ascending"
                    >
                      Driver Name{" "}
                      <span className="right-sorting-new">
                        <i
                          className="fas fa-long-arrow-alt-up"
                          style={{ color: getArrowColor("driverId.firstName", "asc") }}
                          onClick={() => handleSortClick("driverId.firstName", "asc")}
                        ></i>
                        <i
                          className="fas fa-long-arrow-alt-down"
                          style={{ color: getArrowColor("driverId.firstName", "desc") }}
                          onClick={() => handleSortClick("driverId.firstName", "desc")}
                          ></i>
                      </span>
                    </th> */}

                    <th
                      title="Time"
                      className="sorting border-right"
                      tabIndex="0"
                      aria-controls="leads-table"
                      rowSpan="1"
                      colSpan="1"
                      aria-label="Lead Agent: activate to sort column ascending"
                    >
                      Time{" "}
                      {/* <span className="right-sorting-new">
                        <i
                          className="fas fa-long-arrow-alt-up"
                          style={{
                            color: getArrowColor("pickUpTime", "asc"),
                          }}
                          onClick={() => handleSortClick("pickUpTime", "asc")}
                        ></i>
                        <i
                          className="fas fa-long-arrow-alt-down"
                          style={{
                            color: getArrowColor("pickUpTime", "desc"),
                          }}
                          onClick={() => handleSortClick("pickUpTime", "desc")}
                        ></i>
                      </span> */}
                    </th>
                    <th
                      title="Passenger"
                      className="sorting border-right"
                      tabIndex="0"
                      aria-controls="leads-table"
                      rowSpan="1"
                      colSpan="1"
                      aria-label="Lead Agent: activate to sort column ascending"
                    >
                      Passenger{" "}
                      {/* <span className="right-sorting-new">
                        <i
                          className="fas fa-long-arrow-alt-up"
                          style={{
                            color: getArrowColor("customerId.firstName", "asc"),
                          }}
                          onClick={() =>
                            handleSortClick("customerId.firstName", "asc")
                          }
                        ></i>
                        <i
                          className="fas fa-long-arrow-alt-down"
                          style={{
                            color: getArrowColor(
                              "customerId.firstName",
                              "desc"
                            ),
                          }}
                          onClick={() =>
                            handleSortClick("customerId.firstName", "desc")
                          }
                        ></i>
                      </span> */}
                    </th>
                    <th
                      title="Pickup - Drop Off"
                      className="sorting border-right w-30"
                      tabIndex="0"
                      aria-controls="leads-table"
                      rowSpan="1"
                      colSpan="1"
                      aria-label="Lead Agent: activate to sort column ascending"
                    >
                      Pickup - Drop Off{" "}
                      {/* <span className="right-sorting-new">
                        <i
                          className="fas fa-long-arrow-alt-up"
                          style={{
                            color: getArrowColor("pickUpDate", "asc"),
                          }}
                          onClick={() => handleSortClick("pickUpDate", "asc")}
                        ></i>
                        <i
                          className="fas fa-long-arrow-alt-down"
                          style={{
                            color: getArrowColor("pickUpDate", "desc"),
                          }}
                          onClick={() => handleSortClick("pickUpDate", "desc")}
                        ></i>
                      </span> */}
                    </th>
                    <th
                      title="Vehicle Type"
                      className="sorting border-right"
                      tabIndex="0"
                      aria-controls="leads-table"
                      rowSpan="1"
                      colSpan="1"
                      aria-label="Lead Agent: activate to sort column ascending"
                    >
                      Vehicle Type{" "}
                      <span className="right-sorting-new">
                        <i
                          className="fas fa-long-arrow-alt-up"
                          style={{
                            color: getArrowColor("type.name", "asc"),
                          }}
                          onClick={() => handleSortClick("type.name", "asc")}
                        ></i>
                        <i
                          className="fas fa-long-arrow-alt-down"
                          style={{
                            color: getArrowColor("type.name", "desc"),
                          }}
                          onClick={() => handleSortClick("type.name", "desc")}
                        ></i>
                      </span>
                    </th>
                    <th
                      title="Price"
                      className="sorting border-right"
                      tabIndex="0"
                      aria-controls="leads-table"
                      rowSpan="1"
                      colSpan="1"
                      aria-label="Lead Agent: activate to sort column ascending"
                    >
                      Price{" "}
                      {/* <span className="right-sorting-new">
                        <i
                          className="fas fa-long-arrow-alt-up"
                          style={{ color: getArrowColor("totalAmount", "asc") }}
                          onClick={() => handleSortClick("totalAmount", "asc")}
                        ></i>
                        <i
                          className="fas fa-long-arrow-alt-down"
                          style={{
                            color: getArrowColor("totalAmount", "desc"),
                          }}
                          onClick={() => handleSortClick("totalAmount", "desc")}
                        ></i>
                      </span> */}
                    </th>
                    <th
                      title="Driver Pay"
                      className="sorting border-right"
                      tabIndex="0"
                      aria-controls="leads-table"
                      rowSpan="1"
                      colSpan="1"
                      aria-label="Lead Agent: activate to sort column ascending"
                    >
                      Driver Pay
                    </th>
                    <th
                      title="Status"
                      className="sorting border-right"
                      tabIndex="0"
                      aria-controls="leads-table"
                      rowSpan="1"
                      colSpan="1"
                      aria-label="Lead Agent: activate to sort column ascending"
                    >
                      Status{" "}
                      <span className="right-sorting-new">
                        <i
                          className="fas fa-long-arrow-alt-up"
                          style={{ color: getArrowColor("status", "asc") }}
                          onClick={() => handleSortClick("status", "asc")}
                        ></i>
                        <i
                          className="fas fa-long-arrow-alt-down"
                          style={{ color: getArrowColor("status", "desc") }}
                          onClick={() => handleSortClick("status", "desc")}
                        ></i>
                      </span>
                    </th>
                    <th
                      title="Actions"
                      className="text-right pr-20 sorting_disabled border-right"
                      rowSpan="1"
                      colSpan="1"
                      aria-label="Action"
                    >
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {reservation &&
                    reservation?.unassigned &&
                    // reservation?.unassigned.results &&
                    reservation?.unassigned?.length > 0 &&
                    paginatedData?.results.map((current, i) => {
                      // if (current.delete === false) {
                      return (
                        <AdminTodayOrderComponentRow
                          key={i}
                          current={current}
                          i={i}
                          handleModal={handleModal}
                        />
                      );
                      // }
                    })}
                </tbody>
              </table>
              {reservation &&
                reservation?.unassigned &&
                // reservation.unassigned &&
                paginatedData?.results?.length === 0 && (
                  // paginatedData?.length > 0 &&
                  <div
                    className="dash-stats-list"
                    style={{ textAlign: "center" }}
                  >
                    <br />
                    <br />
                    <img
                      alt=""
                      src={shift1}
                      style={{ width: "145px", opacity: "0.5" }}
                    />
                    <br />
                    <p style={{ opacity: "0.5" }}>No Data Found</p>
                  </div>
                )}
              <div
                id="leads-table_processing"
                className="dataTables_processing card"
                style={{ display: "none" }}
              >
                Processing...
              </div>
            </div>
          </div>
          {reservation &&
            reservation.unassigned &&
            reservation.unassigned.length > 0 &&
            paginatedData?.results?.length > 0 && (
              <div className="d-flex border">
                {reservation.loading === false ? (
                  <div className="flex-grow-1">
                    <div
                      className="dataTables_info"
                      id="leads-table_info"
                      role="status"
                      aria-live="polite"
                    >
                      Showing{" "}
                      {1 +
                        paginatedData.limit *
                          (paginatedData?.currentPageNumber - 1)}{" "}
                      to{" "}
                      {paginatedData.displayingResults +
                        paginatedData.limit *
                          (paginatedData?.currentPageNumber - 1)}{" "}
                      of {paginatedData.totalResults} entries
                    </div>
                  </div>
                ) : (
                  <div className="flex-grow-1">
                    <div
                      className="dataTables_info"
                      id="leads-table_info"
                      role="status"
                      aria-live="polite"
                    >
                      Loading entries
                    </div>
                  </div>
                )}
                <div>
                  <div
                    className="dataTables_paginate paging_simple_numbers"
                    id="leads-table_paginate"
                  >
                    <ul className="pagination">
                      <li className="page-item">
                        <NavLink
                          className="page-link"
                          to="#"
                          aria-label="Previous"
                          // onClick={onClickPrev}
                          onClick={(e) =>
                            handlePagination(
                              currentPage - 1,
                              sortField,
                              sortOrder,
                              searchTerm,
                              limit
                            )
                          }
                        >
                          <span aria-hidden="true">Previous</span>
                          <span className="sr-only">Previous</span>
                        </NavLink>
                      </li>
                      {reservation &&
                        reservation.unassigned &&
                        // reservation.reservation.previous &&
                        paginatedData.paginationArr &&
                        paginatedData.paginationArr.length > 0 &&
                        // reservation.reservation.previous.paginationArr.map((current, i) => {
                        // reservation.reservation.previous.paginationArr.slice(startIndex, endIndex).map((current, i) => {
                        Array.from(
                          {
                            length:
                              paginatedData.endPage -
                              paginatedData.startPage +
                              1,
                          },
                          (_, i) => {
                            const current = paginatedData.startPage + i;
                            return paginatedData?.currentPageNumber ===
                              current ? (
                              <li className="page-item active" key={i}>
                                <Link
                                  to=""
                                  className="page-link"
                                  value={current}
                                >
                                  {current}
                                </Link>
                              </li>
                            ) : (
                              <li className="page-item" key={i}>
                                <Link
                                  className="page-link"
                                  value={current}
                                  onClick={(e) =>
                                    handlePagination(
                                      parseInt(current),
                                      sortField,
                                      sortOrder,
                                      searchTerm,
                                      limit
                                    )
                                  }
                                  to=""
                                >
                                  {current}
                                </Link>
                              </li>
                            );
                          }
                        )}
                      <li className="page-item">
                        <NavLink
                          className="page-link"
                          to="#"
                          aria-label="Next"
                          // onClick={onClickNext}
                          onClick={(e) =>
                            handlePagination(
                              currentPage + 1,
                              sortField,
                              sortOrder,
                              searchTerm,
                              limit
                            )
                          }
                        >
                          <span aria-hidden="true">Next</span>
                          <span className="sr-only">Next</span>
                        </NavLink>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            )}
          {/* <div>
              <div
                className="dataTables_paginate paging_simple_numbers"
                id="leads-table_paginate"
              >
                <ul className="pagination">
                  <li className="page-item">
                    <NavLink
                      className="page-link"
                      to="#"
                      aria-label="Previous"
                      onClick={onClickPrev}
                    >
                      Showing {1 + limit * (currentPageNumber - 1)} to{" "}
                      {reservation?.unassigned?.displayingResults +
                        limit * (currentPageNumber - 1)}{" "}
                      of {reservation?.unassigned?.totalResults} entries
                    </div>
                  </div>
                ) : (
                  <div className="flex-grow-1">
                    <div
                      className="dataTables_info"
                      id="leads-table_info"
                      role="status"
                      aria-live="polite"
                    >
                      Loading entries
                    </div>
                  </div>
                )}
                <div>
                  <div
                    className="dataTables_paginate paging_simple_numbers"
                    id="leads-table_paginate"
                  >
                    <ul className="pagination">
                      <li className="page-item">
                        <NavLink
                          className="page-link"
                          to="#"
                          aria-label="Previous"
                          onClick={onClickPrev}
                        >
                          <span aria-hidden="true">Previous</span>
                          <span className="sr-only">Previous</span>
                        </NavLink>
                      </li>
                      {reservation.unassigned &&
                        reservation.unassigned.paginationArr &&
                        reservation.unassigned.paginationArr.length > 0 &&
                        // reservation.unassigned.paginationArr.map((current, i) => {
                        Array.from(
                          { length: endPage - startPage + 1 },
                          (_, i) => {
                            const current = startPage + i;
                            return currentPageNumber === current ? (
                              <li className="page-item active" key={i}>
                                <Link
                                  to=""
                                  className="page-link"
                                  value={current}
                                >
                                  {current}
                                </Link>
                              </li>
                            ) : (
                              <li className="page-item" key={i}>
                                <Link
                                  className="page-link"
                                  value={current}
                                  onClick={() => handleChangeData(current)}
                                  to=""
                                >
                                  {current}
                                </Link>
                              </li>
                            );
                          }
                        )}
                      <li className="page-item">
                        <NavLink
                          className="page-link"
                          to="#"
                          aria-label="Next"
                          onClick={onClickNext}
                        >
                          <span aria-hidden="true">Next</span>
                          <span className="sr-only">Next</span>
                        </NavLink>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div> */}
          {/* </div> */}
          {/* } */}
        </div>
      </div>
      <RightModal open={modal.open} handleModal={handleModal}>
        {modal.open && (
          <AdminTodayOrderEditComponent
            state={modal.data}
            handleModal={handleModal}
          />
        )}
      </RightModal>
    </>
  ) : (
    <>
      <NotAllowed />
    </>
  );
};
export default AdminTodayOrderComponent;
