/** @format */

import React from "react";
// import AdminReportSearchandFilterV2 from "./AdminReportSearchandFilterV2";
import AdminBasicReportTable from "./AdminBasicReportTable";

function AdminDailyActivityReportTable() {
  return (
    <>
      {/* <div className="settings-box rounded">
          <form
            method="POST"
            id="editSettings"
            className="ajax-form"
            autoComplete="off"
          >
            <div className="s-b-inner s-b-notifications bg-white b-shadow-4 rounded">
              <div className="s-b-n-header d-lg-flex justify-content-between card-header border-bottom-grey">
                <div className="s-b-n-header" id="tabs">
                  <h2 className="mb-0 p-20 f-21 font-weight-normal text-capitalize">
                    <span>REPORTS</span>
                  </h2>
                </div>
              </div> */}

      {/* <AdminReportSearchandFilterV2 /> */}

      <AdminBasicReportTable />

      {/* </form>
        </div>
      </div> */}
      <div
        className="dataTables_wrapper dt-bootstrap4 no-footer"
        style={{ display: "block" }}
        id="divMsg"
      >
        <div className="d-block d-lg-flex d-md-flex justify-content-center  action-bar mb-20 mt-3 mx-3">
          <div id="table-actions" className="flex-grow-1 align-items-center">
            <h4 className=" mb-0 f-21 text-capitalize font-weight-bold">
              {" "}
              Drivers Activity Reports{" "}
            </h4>
          </div>
          <div
            className="btn-group mt-2 mt-lg-0 mt-md-0 ml-0 ml-lg-3 ml-md-3"
            role="group"
          >
            <div
              className="btn-group me-2"
              role="group"
              aria-label="First group"
            >
              <button
                type="button"
                className="btn btn-success"
                data-toggle="tooltip"
                data-placement="top"
                title="Refresh"
              >
                <svg
                  className="svg-inline--fa fa-sync fa-w-16"
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fas"
                  data-icon="sync"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                  data-fa-i2svg=""
                >
                  <path
                    fill="currentColor"
                    d="M440.65 12.57l4 82.77A247.16 247.16 0 0 0 255.83 8C134.73 8 33.91 94.92 12.29 209.82A12 12 0 0 0 24.09 224h49.05a12 12 0 0 0 11.67-9.26 175.91 175.91 0 0 1 317-56.94l-101.46-4.86a12 12 0 0 0-12.57 12v47.41a12 12 0 0 0 12 12H500a12 12 0 0 0 12-12V12a12 12 0 0 0-12-12h-47.37a12 12 0 0 0-11.98 12.57zM255.83 432a175.61 175.61 0 0 1-146-77.8l101.8 4.87a12 12 0 0 0 12.57-12v-47.4a12 12 0 0 0-12-12H12a12 12 0 0 0-12 12V500a12 12 0 0 0 12 12h47.35a12 12 0 0 0 12-12.6l-4.15-82.57A247.17 247.17 0 0 0 255.83 504c121.11 0 221.93-86.92 243.55-201.82a12 12 0 0 0-11.8-14.18h-49.05a12 12 0 0 0-11.67 9.26A175.86 175.86 0 0 1 255.83 432z"
                  ></path>
                </svg>
              </button>
              <a
                href="./document/Summary Report.xlsx"
                type="button"
                className="btn btn-info"
                data-toggle="tooltip"
                data-placement="top"
                title="Export Excel File"
              >
                <svg
                  className="svg-inline--fa fa-file-excel fa-w-12"
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fas"
                  data-icon="file-excel"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 384 512"
                  data-fa-i2svg=""
                >
                  <path
                    fill="currentColor"
                    d="M224 136V0H24C10.7 0 0 10.7 0 24v464c0 13.3 10.7 24 24 24h336c13.3 0 24-10.7 24-24V160H248c-13.2 0-24-10.8-24-24zm60.1 106.5L224 336l60.1 93.5c5.1 8-.6 18.5-10.1 18.5h-34.9c-4.4 0-8.5-2.4-10.6-6.3C208.9 405.5 192 373 192 373c-6.4 14.8-10 20-36.6 68.8-2.1 3.9-6.1 6.3-10.5 6.3H110c-9.5 0-15.2-10.5-10.1-18.5l60.3-93.5-60.3-93.5c-5.2-8 .6-18.5 10.1-18.5h34.8c4.4 0 8.5 2.4 10.6 6.3 26.1 48.8 20 33.6 36.6 68.5 0 0 6.1-11.7 36.6-68.5 2.1-3.9 6.2-6.3 10.6-6.3H274c9.5-.1 15.2 10.4 10.1 18.4zM384 121.9v6.1H256V0h6.1c6.4 0 12.5 2.5 17 7l97.9 98c4.5 4.5 7 10.6 7 16.9z"
                  ></path>
                </svg>
              </a>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12">
            <table
              className="table border w-100"
              id="leads-table"
              role="grid"
              aria-describedby="leads-table_info"
              style={{ overflowX: "auto" }}
            >
              <thead className="card-header">
                <tr role="row">
                  <th
                    className="sorting border-right"
                    tabIndex="0"
                    aria-controls="leads-table"
                    rowspan="1"
                    colspan="1"
                    aria-label="Mobile: activate to sort column ascending"
                  >
                    Order ID
                  </th>
                  <th
                    title="Customer Name"
                    className="sorting_disabled border-right"
                    rowspan="1"
                    colspan="1"
                    aria-label="Next Follow Up"
                  >
                    Load#
                  </th>
                  <th
                    title="Type"
                    className="sorting border-right"
                    tabIndex="0"
                    aria-controls="leads-table"
                    rowspan="1"
                    colspan="1"
                    aria-label="Lead Agent: activate to sort column ascending"
                  >
                    Driver Name
                  </th>
                  <th
                    title="Date"
                    className="sorting border-right"
                    tabIndex="0"
                    aria-controls="leads-table"
                    rowspan="1"
                    colspan="1"
                    aria-label="Lead Agent: activate to sort column ascending"
                  >
                    Passenger Name
                  </th>
                  <th
                    title="Notes"
                    className="sorting border-right"
                    tabIndex="0"
                    aria-controls="leads-table"
                    rowspan="1"
                    colspan="1"
                    aria-label="Lead Agent: activate to sort column ascending"
                  >
                    Reservation Date
                  </th>
                  <th
                    title="Notes"
                    className="sorting border-right"
                    tabIndex="0"
                    aria-controls="leads-table"
                    rowspan="1"
                    colspan="1"
                    aria-label="Lead Agent: activate to sort column ascending"
                  >
                    Pickup Address
                  </th>
                  <th
                    title="Notes"
                    className="sorting border-right"
                    tabIndex="0"
                    aria-controls="leads-table"
                    rowspan="1"
                    colspan="1"
                    aria-label="Lead Agent: activate to sort column ascending"
                  >
                    Pickup Time
                  </th>
                  <th
                    title="Notes"
                    className="sorting border-right"
                    tabIndex="0"
                    aria-controls="leads-table"
                    rowspan="1"
                    colspan="1"
                    aria-label="Lead Agent: activate to sort column ascending"
                  >
                    Dropoff Address
                  </th>
                  <th
                    title="Notes"
                    className="sorting border-right"
                    tabIndex="0"
                    aria-controls="leads-table"
                    rowspan="1"
                    colspan="1"
                    aria-label="Lead Agent: activate to sort column ascending"
                  >
                    Drop off Time
                  </th>
                  <th
                    title="Notes"
                    className="sorting border-right"
                    tabIndex="0"
                    aria-controls="leads-table"
                    rowspan="1"
                    colspan="1"
                    aria-label="Lead Agent: activate to sort column ascending"
                  >
                    Driver Amount
                  </th>
                  <th
                    title="Action"
                    className="text-right sorting_disabled border-right"
                    rowspan="1"
                    colspan="1"
                    aria-label="Action"
                  >
                    Status
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr id="row-20" role="row" className="odd border">
                  <td className="border-right">909</td>
                  <td className="border-right">333</td>
                  <td className="border-right">John Deo</td>
                  <td className="border-right">Jeff Debner</td>
                  <td className="border-right">4/25/2024</td>
                  <td className="border-right">
                    6300 York Ave S, Edina, MN 55435
                  </td>
                  <td className="border-right">2:00PM</td>
                  <td className="border-right">
                    8900 Penn Ave S, Bloomington, MN 55431
                  </td>
                  <td className="border-right">220PM</td>
                  <td className="border-right">$25</td>
                  <td className="border-right">
                    <span className="badge bg-success text-white p-1">
                      Completed
                    </span>
                  </td>
                </tr>

                <tr id="row-20" role="row" className="odd border">
                  <td className="border-right">456</td>
                  <td className="border-right">555</td>
                  <td className="border-right">Sample 1</td>
                  <td className="border-right">Mohamed Ahmed</td>
                  <td className="border-right">4/26/2024</td>
                  <td className="border-right">
                    6300 York Ave S, Edina, MN 55435
                  </td>
                  <td className="border-right">1:15PM</td>
                  <td className="border-right">
                    8900 Penn Ave S, Bloomington, MN 55431
                  </td>
                  <td className="border-right">1:30PM</td>
                  <td className="border-right">$30</td>
                  <td className="border-right">
                    <span className="badge bg-success text-white p-1">
                      Cancelled{" "}
                    </span>
                  </td>
                </tr>

                <tr id="row-20" role="row" className="odd border">
                  <td className="border-right">567</td>
                  <td className="border-right">123</td>
                  <td className="border-right">Ahmed Majid</td>
                  <td className="border-right">Shayan Ali</td>
                  <td className="border-right">4/25/2024</td>
                  <td className="border-right">
                    6300 York Ave S, Edina, MN 55435
                  </td>
                  <td className="border-right">2:00PM</td>
                  <td className="border-right">
                    8900 Penn Ave S, Bloomington, MN 55431
                  </td>
                  <td className="border-right">11:20am</td>
                  <td className="border-right">$40</td>
                  <td className="border-right">
                    <span className="badge bg-success text-white p-1">
                      No Show
                    </span>
                  </td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <th colspan="8" className="border-right"></th>
                  <th className="border-right">Total</th>
                  <th className="border-right">$95</th>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>
    </>
  );
}

export default AdminDailyActivityReportTable;
