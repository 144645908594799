import React from 'react'

const Checkuser = () => {
  return (
    <div
      className="container-custom d-flex justify-content-center align-items-center"
      // style={{ display: "none" }}
    >
      <div className="spinner-border" role="status" aria-hidden="true"></div>
    </div>
  )
}

export default Checkuser