/** @format */

import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import AdminReportSearchTableRow from "./AdminReportSearchTableRow";
import moment from "moment";
import ExcelExport from "../../../ExcelExport";
import shift1 from "../../../assets/images/load.png";
// import { useEffect } from "react";
// import { reservationReportSearch } from "../../../actions/kpiActions";
import { paginationDataToShow } from "../../../actions/generalActions";
import { Link, NavLink } from "react-router-dom";

const AdminDailyActivityReportSearchTable = () => {
  const kpi = useSelector((state) => state.kpi);
  const auth = useSelector((state) => state.auth);
  const [dropDownRow, setDropDownRow] = useState(null);
  const [closeDropDownRow, setCloseDropDownRow] = useState(null);
  const [showMoreVertDropDown, setshowMoreVertDropDown] = useState(false);
  const openMoreVertDropDown = () => {
    setshowMoreVertDropDown((showMoreVertDropDown) => !showMoreVertDropDown);
  };
  const dispatch = useDispatch();
  // const reportData = async (e) => {
  //     dispatch(reservationReportSearch({companyId: auth.user.companyId.id }))
  // }
  // useEffect(() => {
  //     reportData()
  // },[])
  // console.log(kpi, "djdjdjjdjjdjdjdjdjdjdjdjdjdjdjdjdjdjd")

  // const data = kpi?.reservationReportSearch?.map(item=>({
  const data = kpi?.reservationReportSearch?.map((item) => {
    const dateString = item.reservation.dateForReservation;
    const [datePart, timePart] = dateString.split("T");
    const [year, month, day] = datePart.split("-");
    const formattedDate = `${month}/${day}/${year}`;
    return {
      "Order Id": item.reservation?.orderId,
      "Load Number": item.reservation?.loadNumber,
      "Reservation Date": formattedDate,
      "Passenger First Name": item.reservation?.customerId?.firstName,
      "Passenger Last Name": item.reservation?.customerId?.lastName,
      "Order Mode": item.reservation?.type.name,
      "Pick Up Date": moment(
        item.reservation?.pickUpDate.toString().substring(0, 10)
      ).format("MM/DD/YYYY"),
      "Pick Up Time":
        (parseInt(item.reservation?.pickUpTime.substring(0, 2)) === 0
          ? 12
          : parseInt(item.reservation?.pickUpTime.substring(0, 2)) > 12
          ? parseInt(item.reservation?.pickUpTime.substring(0, 2)) - 12
          : parseInt(item.reservation?.pickUpTime.substring(0, 2))) +
        ":" +
        item.reservation?.pickUpTime.substring(3, 5) +
        (parseInt(item.reservation?.pickUpTime.substring(0, 2)) > 11
          ? "pm"
          : "am"),
      "Pick Up Address": item.reservation?.pickUpAddress?.address,
      "Pick Up Latitude": item.reservation?.pickUpAddress?.latitude,
      "Pick Up Longitude": item.reservation?.pickUpAddress?.longitude,
      "Drop Off Date": moment(
        item.reservation?.dropOffDate.toString().substring(0, 10)
      ).format("MM/DD/YYYY"),
      "Drop Off Time":
        (parseInt(item.reservation?.dropOffTime.substring(0, 2)) === 0
          ? 12
          : parseInt(item.reservation?.dropOffTime.substring(0, 2)) > 12
          ? parseInt(item.reservation?.dropOffTime.substring(0, 2)) - 12
          : parseInt(item.reservation?.dropOffTime.substring(0, 2))) +
        ":" +
        item.reservation?.dropOffTime.substring(3, 5) +
        (parseInt(item.reservation?.dropOffTime.substring(0, 2)) > 11
          ? "pm"
          : "am"),
      "Drop Off Address": item.reservation?.dropOffAddress?.address,
      "Drop Off Latitude": item.reservation?.dropOffAddress?.latitude,
      "Drop Off Longitude": item.reservation?.dropOffAddress?.longitude,
      "Vehicle No": item.reservation?.assetId?.assetNo,
      "Driver First Name": item.reservation?.driverId?.firstName,
      "Driver Last Name": item.reservation?.driverId?.lastName,
      "Distance of Base Location to Pickup":
        item.reservation?.fareAndDistance?.distanceFromBaseLocation,
      "Distance of Pickup to Dropoff":
        item.reservation?.fareAndDistance?.distanceFromPickToDropOff,
      "Total Order Distance": item.reservation?.fareAndDistance?.totalMileage,
      "Driver Amount": item.reservation?.fareAndDistance?.driverAmount
        ? parseFloat(item?.reservation?.fareAndDistance?.driverAmount)
        : 0,
      Status: item.reservation?.status,
      // "External Client Number": item.reservation?.externalClientId,
      // "Trip Status": item.trip?.tripStatus,
      // // "Trip Start Time": item.trip?.tripStartTime?.toString().substring(11,16),

      // "Trip Start Time":
      //   item.trip && item.trip.tripStartTime
      //     ? (parseInt(item.trip?.tripStartTime.toString().substring(11, 13)) ===
      //       0
      //         ? 12
      //         : parseInt(
      //             item.trip?.tripStartTime.toString().substring(11, 13)
      //           ) > 12
      //         ? parseInt(
      //             item.trip?.tripStartTime.toString().substring(11, 13)
      //           ) - 12
      //         : parseInt(
      //             item.trip?.tripStartTime.toString().substring(11, 13)
      //           )) +
      //       ":" +
      //       item.trip?.tripStartTime.toString().substring(14, 16) +
      //       (parseInt(item.trip?.tripStartTime.toString().substring(11, 13)) >
      //       11
      //         ? "pm"
      //         : "am")
      //     : "-",

      // "Arrived At Customer Pick Up":
      //   item.trip && item.trip.arrivedTime
      //     ? (parseInt(item.trip.arrivedTime.toString().substring(11, 13)) === 0
      //         ? 12
      //         : item.trip &&
      //           item.trip.arrivedTime &&
      //           parseInt(item.trip.arrivedTime.toString().substring(11, 13)) >
      //             12
      //         ? parseInt(item.trip.arrivedTime.toString().substring(11, 13)) -
      //           12
      //         : item.trip &&
      //           item.trip.arrivedTime &&
      //           parseInt(item.trip.arrivedTime.toString().substring(11, 13))) +
      //       ":" +
      //       (item.trip &&
      //         item.trip.arrivedTime &&
      //         item.trip.arrivedTime.toString().substring(14, 16)) +
      //       (parseInt(item.trip?.arrivedTime?.toString()?.substring(11, 13)) >
      //       11
      //         ? "pm"
      //         : "am")
      //     : "-",
      // "Ride Start":
      //   item.trip && item.trip.pickUpTime
      //     ? (parseInt(item.trip.pickUpTime.toString().substring(11, 13)) === 0
      //         ? 12
      //         : item.trip &&
      //           item.trip.pickUpTime &&
      //           parseInt(item.trip.pickUpTime.toString().substring(11, 13)) > 12
      //         ? parseInt(item.trip.pickUpTime.toString().substring(11, 13)) - 12
      //         : item.trip &&
      //           item.trip.pickUpTime &&
      //           parseInt(item.trip.pickUpTime.toString().substring(11, 13))) +
      //       ":" +
      //       (item.trip &&
      //         item.trip.pickUpTime &&
      //         item.trip.pickUpTime.toString().substring(14, 16)) +
      //       (parseInt(item.trip?.pickUpTime?.toString()?.substring(11, 13)) > 11
      //         ? "pm"
      //         : "am")
      //     : "-",

      // "Ride End":
      //   item.trip && item.trip.dropOffTime
      //     ? (parseInt(item.trip.dropOffTime.toString().substring(11, 13)) === 0
      //         ? 12
      //         : item.trip &&
      //           item.trip.dropOffTime &&
      //           parseInt(item.trip.dropOffTime.toString().substring(11, 13)) >
      //             12
      //         ? parseInt(item.trip.dropOffTime.toString().substring(11, 13)) -
      //           12
      //         : item.trip &&
      //           item.trip.dropOffTime &&
      //           parseInt(item.trip.dropOffTime.toString().substring(11, 13))) +
      //       ":" +
      //       (item.trip &&
      //         item.trip.dropOffTime &&
      //         item.trip.dropOffTime.toString().substring(14, 16)) +
      //       (parseInt(item.trip?.dropOffTime?.toString()?.substring(11, 13)) >
      //       11
      //         ? "pm"
      //         : "am")
      //     : "-",

      // "Actual Pick Up Latitude": item.trip?.pickUpLat,
      // "Actual Pick Up Longitude": item.trip?.pickUpLng,
      // "Actual Order Drop Latitude": item.trip?.dropOffLat,
      // "Actual Order Drop Longitude": item.trip?.dropOffLng,
      // "Min Distance By Google": item?.min,
      // "Max Distance By Google": item?.max,
      // "Avg Distance of all the Routes By Google": item?.avg,
    };
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [sortField, setSortField] = useState("reservation.pickUpDate");
  const [sortOrder, setSortOrder] = useState("desc"); //asc, desc
  const [searchTerm, setSearchTerm] = useState("");
  const [limit, setLimit] = useState(10); // Set your default limit here

  // Function to handle pagination, sorting, and searching
  const handlePagination = (page, field, order, search, newLimit) => {
    let newPage;
    if (page < 1) {
      newPage = 1;
    } else if (page > paginatedData.totalPages) {
      newPage = paginatedData.totalPages;
    } else {
      newPage = page;
    }
    setCurrentPage(newPage);
    setSortField(field);
    setSortOrder(order);
    setSearchTerm(search);
    setLimit(newLimit);
  };

  const fullData = kpi.reservationReportSearch;
  // Use the pagination function to get the data for the current page
  const paginatedData = paginationDataToShow(
    limit,
    currentPage,
    fullData,
    sortField,
    sortOrder,
    searchTerm
  );
  // console.log(paginatedData, "paginatedData");
  // Function to handle sorting icon click
  const handleSortClick = (field, order) => {
    // Call handlePagination with sorting parameters
    handlePagination(currentPage, field, order, searchTerm, limit);
  };
  // Function to determine the color based on the current sorting order
  const getArrowColor = (field, direction) => {
    return sortField === field && sortOrder === direction
      ? "#000000"
      : "#A0A0A0";
  };
  return (
    <>
      <div className="d-flex flex-column w-tables rounded mt-3 bg-white">
        <div className="d-flex border">
          <div className="flex-grow-1">
            <div
              className="dataTables_length d-flex justify-content-between"
              id="leads-table_length"
            >
              {/* <div className="body-wrapper clearfix">
                <div>
                  <h4 className=" mb-0 f-21 text-capitalize font-weight-bold">
                    Reports
                  </h4>
                </div>
              </div>{" "} */}
              <div className="flex-grow-1">
                <div className="d-flex" id="leads-table_length">
                  <div className="p-2">
                    <label>Show entries</label>
                  </div>
                  <label>
                    <select
                      name="leads-table_length"
                      aria-controls="leads-table"
                      className="custom-select custom-select-sm form-control form-control-sm"
                      autoComplete="false"
                      value={limit} // Make sure to set the value attribute to the current limit
                      onChange={(e) =>
                        handlePagination(
                          1,
                          sortField,
                          sortOrder,
                          searchTerm,
                          parseInt(e.target.value)
                        )
                      }
                    >
                      {[10, 25, 50, 100].map((current, i) => {
                        return (
                          <option value={current} key={i}>
                            {current}
                          </option>
                        );
                      })}
                    </select>
                  </label>
                </div>
              </div>
              <div className=" text-right">
                {/* {kpi &&
                  kpi.reservationReportSearch &&
                  kpi.reservationReportSearch.length > 0 && ( */}
                <div
                  className="btn-group me-2"
                  role="group"
                  aria-label="First group"
                >
                  <button
                    type="button"
                    className="btn btn-success"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Refresh"
                  >
                    <i className="fas fa-sync"></i>
                  </button>

                  {/* {kpi &&
                    kpi.reservationReportSearch &&
                    kpi.reservationReportSearch.length > 0 && ( */}
                  <ExcelExport
                    excelData={data}
                    fileName={`Report ${moment()
                      .utcOffset(-360)
                      .format("MM/DD/YYYY")}`}
                  />
                  {/* )} */}
                </div>

                {/* )} */}
              </div>
            </div>
          </div>
        </div>
        <div
          id="leads-table_wrapper"
          className="dataTables_wrapper dt-bootstrap4 no-footer"
        >
          <div className="row">
            <div className="col-sm-12">
              <table
                className="table border w-100 dataTable no-footer"
                id="leads-table"
                role="grid"
                aria-describedby="leads-table_info"
                style={{ overflowX: "auto" }}
              >
                <thead className="card-header">
                  <tr role="row">
                    <th
                      title="Order ID"
                      className="sorting border-right align-table-header"
                      tabIndex="0"
                      aria-controls="leads-table"
                      rowSpan="1"
                      colSpan="1"
                      aria-label="Mobile: activate to sort column ascending"
                    >
                      Order ID
                    </th>
                    <th
                      title="Load#"
                      className="sorting_disabled border-right align-table-header"
                      rowSpan="1"
                      colSpan="1"
                      aria-label="Next Follow Up"
                    >
                      Load#
                    </th>
                    <th
                      title="Driver#"
                      className="sorting_disabled border-right align-table-header"
                      rowSpan="1"
                      colSpan="1"
                      aria-label="Next Follow Up"
                    >
                      Driver#
                    </th>
                    <th
                      title="Vehicle#"
                      className="sorting_disabled border-right align-table-header"
                      rowSpan="1"
                      colSpan="1"
                      aria-label="Next Follow Up"
                    >
                      Vehicle#
                    </th>
                    <th
                      title="Time"
                      className="sorting border-right align-table-header"
                      tabIndex="0"
                      aria-controls="leads-table"
                      rowSpan="1"
                      colSpan="1"
                      aria-label="Lead Agent: activate to sort column ascending"
                    >
                      Time
                    </th>
                    <th
                      title="Passenger"
                      className="sorting border-right align-table-header"
                      tabIndex="0"
                      aria-controls="leads-table"
                      rowSpan="1"
                      colSpan="1"
                      aria-label="Lead Agent: activate to sort column ascending"
                    >
                      Passenger
                    </th>
                    <th
                      title="Reservation Date"
                      className="sorting border-right align-table-header"
                      tabIndex="0"
                      aria-controls="leads-table"
                      rowSpan="1"
                      colSpan="1"
                      aria-label="Lead Agent: activate to sort column ascending"
                    >
                      Reservation Date
                      <span className="right-sorting-new">
                        <i
                          className="fas fa-long-arrow-alt-up"
                          style={{
                            color: getArrowColor(
                              "reservation.pickUpDate",
                              "asc"
                            ),
                          }}
                          onClick={() =>
                            handleSortClick("reservation.pickUpDate", "asc")
                          }
                        ></i>
                        <i
                          className="fas fa-long-arrow-alt-down"
                          style={{
                            color: getArrowColor(
                              "reservation.pickUpDate",
                              "desc"
                            ),
                          }}
                          onClick={() =>
                            handleSortClick("reservation.pickUpDate", "desc")
                          }
                        ></i>
                      </span>
                    </th>
                    <th
                      title="Pickup - Drop Off"
                      className="sorting border-right w-30 align-table-header"
                      tabIndex="0"
                      aria-controls="leads-table"
                      rowSpan="1"
                      colSpan="1"
                      aria-label="Lead Agent: activate to sort column ascending"
                    >
                      Pickup - Drop Off
                    </th>
                    <th
                      title="Vehicle Type"
                      className="sorting border-right align-table-header"
                      tabIndex="0"
                      aria-controls="leads-table"
                      rowSpan="1"
                      colSpan="1"
                      aria-label="Lead Agent: activate to sort column ascending"
                    >
                      Vehicle Type
                    </th>
                    <th
                      title="Price"
                      className="sorting border-right align-table-header"
                      tabIndex="0"
                      aria-controls="leads-table"
                      rowSpan="1"
                      colSpan="1"
                      aria-label="Lead Agent: activate to sort column ascending"
                    >
                      Driver Amount
                    </th>
                    <th
                      title="Status"
                      className="sorting border-right align-table-header"
                      tabIndex="0"
                      aria-controls="leads-table"
                      rowSpan="1"
                      colSpan="1"
                      aria-label="Lead Agent: activate to sort column ascending"
                    >
                      Status
                    </th>
                    {/* <th title="Action" className="text-right pr-20 sorting_disabled border-right" rowSpan="1" colSpan="1" aria-label="Action">
                                            Actions
                                        </th> */}
                  </tr>
                </thead>
                <tbody>
                  {kpi &&
                    kpi?.reservationReportSearch &&
                    // kpi.reservationReportSearch.reservation &&
                    kpi?.reservationReportSearch?.length > 0 &&
                    paginatedData?.results.map((current, i) => {
                      // if (current.delete === false) {
                      return (
                        <AdminReportSearchTableRow current={current} i={i} />
                      );
                      // }
                    })}
                </tbody>
              </table>
              {kpi &&
                kpi?.reservationReportSearch &&
                kpi?.reservationReportSearch?.length === 0 && (
                  <div
                    className="dash-stats-list"
                    style={{ textAlign: "center" }}
                  >
                    <br />
                    <br />
                    <img
                      alt=""
                      src={shift1}
                      style={{ width: "145px", opacity: "0.5" }}
                    />
                    <br />
                    <p style={{ opacity: "0.5" }}>No Report Found</p>
                  </div>
                )}

              <div
                id="leads-table_processing"
                className="dataTables_processing card"
                style={{ display: "none" }}
              >
                Processing...
              </div>
            </div>
          </div>
          {kpi &&
            kpi?.reservationReportSearch &&
            kpi?.reservationReportSearch?.length > 0 &&
            paginatedData?.results?.length > 0 && (
              <div className="d-flex border">
                {kpi.loading === false ? (
                  <div className="flex-grow-1">
                    <div
                      className="dataTables_info"
                      id="leads-table_info"
                      role="status"
                      aria-live="polite"
                    >
                      Showing{" "}
                      {1 +
                        paginatedData.limit *
                          (paginatedData?.currentPageNumber - 1)}{" "}
                      to{" "}
                      {paginatedData.displayingResults +
                        paginatedData.limit *
                          (paginatedData?.currentPageNumber - 1)}{" "}
                      of {paginatedData.totalResults} entries
                    </div>
                  </div>
                ) : (
                  <div className="flex-grow-1">
                    <div
                      className="dataTables_info"
                      id="leads-table_info"
                      role="status"
                      aria-live="polite"
                    >
                      Loading entries
                    </div>
                  </div>
                )}

                <div>
                  <div
                    className="dataTables_paginate paging_simple_numbers"
                    id="leads-table_paginate"
                  >
                    <ul className="pagination">
                      <li className="page-item">
                        <NavLink
                          className="page-link"
                          to="#"
                          aria-label="Previous"
                          // onClick={onClickPrev}
                          onClick={(e) =>
                            handlePagination(
                              currentPage - 1,
                              sortField,
                              sortOrder,
                              searchTerm,
                              limit
                            )
                          }
                        >
                          <span aria-hidden="true">Previous</span>
                          <span className="sr-only">Previous</span>
                        </NavLink>
                      </li>
                      {kpi &&
                        kpi?.reservationReportSearch &&
                        paginatedData?.paginationArr &&
                        paginatedData?.paginationArr?.length > 0 &&
                        Array.from(
                          {
                            length:
                              paginatedData.endPage -
                              paginatedData.startPage +
                              1,
                          },
                          (_, i) => {
                            const current = paginatedData.startPage + i;
                            return paginatedData?.currentPageNumber ===
                              current ? (
                              <li className="page-item active" key={i}>
                                <Link
                                  to=""
                                  className="page-link"
                                  value={current}
                                >
                                  {current}
                                </Link>
                              </li>
                            ) : (
                              <li className="page-item" key={i}>
                                <Link
                                  className="page-link"
                                  value={current}
                                  onClick={(e) =>
                                    handlePagination(
                                      parseInt(current),
                                      sortField,
                                      sortOrder,
                                      searchTerm,
                                      limit
                                    )
                                  }
                                  to=""
                                >
                                  {current}
                                </Link>
                              </li>
                            );
                          }
                        )}
                      <li className="page-item">
                        <NavLink
                          className="page-link"
                          to="#"
                          aria-label="Next"
                          // onClick={onClickNext}
                          onClick={(e) =>
                            handlePagination(
                              currentPage + 1,
                              sortField,
                              sortOrder,
                              searchTerm,
                              limit
                            )
                          }
                        >
                          <span aria-hidden="true">Next</span>
                          <span className="sr-only">Next</span>
                        </NavLink>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            )}
          {/* <div className="d-flex border">
            
          </div> */}
        </div>
      </div>
    </>
  );
};
export default AdminDailyActivityReportSearchTable;
