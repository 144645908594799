import React from 'react'
import AdminHeader from '../../components/adminComponents/adminHeaderSidebarTitleComponents/AdminHeader'
import AdminSidebar from '../../components/adminComponents/adminHeaderSidebarTitleComponents/AdminSidebar'
import AdminOptimizeBookingComponent from '../../components/adminComponents/adminBookingComponents/AdminOptimizeBookingComponent'

const AdminBookingOptimizePage = () => {
    return (
        <>
            <AdminHeader />
            <AdminSidebar />
            <div className="body-wrapper clearfix">
                <section className="main-container " id="fullscreen">
                    <div className="px-4 py-2 border-top-0 emp-dashboard">
                        <div className="d-block d-lg-flex d-md-flex justify-content-between action-bar mb-20 mt-3">
                            <div
                                id="table-actions"
                                className="flex-grow-1 align-items-center"
                            >
                                <h4 className=" mb-0 f-21 text-capitalize font-weight-bold">
                                    {" "}
                                    Optimize Bookings{" "}
                                </h4>
                            </div>
                            {/* <div
                                className="btn-group mt-2 mt-lg-0 mt-md-0 ml-0 ml-lg-3 ml-md-3"
                                role="group"
                            >
                                <div
                                    className="btn-group me-2"
                                    role="group"
                                    aria-label="First group"
                                >
                                    <button
                                        type="button"
                                        className="btn btn-info"
                                        data-toggle="tooltip"
                                        data-original-title="Export Excel File"
                                        onClick={handleExportToExcel}
                                    >
                                        <i className="fas fa-file-excel"></i>{" "}
                                    </button>
                                    <button
                                        type="button"
                                        className="btn btn-dark"
                                        data-toggle="tooltip"
                                        data-original-title="Filters"
                                        onClick={() => setShowFilter(!showFilter)}
                                    >
                                        <i className="fa fa-filter"></i>{" "}
                                    </button>{" "}
                                    <Link
                                        type="button"
                                        className="btn btn-info d-flex align-items-center"
                                        to="/today-bookings"
                                    >
                                        Today's Booking{" "}
                                        <i
                                            className="fa fa-eye"
                                            style={{ fontSize: "22px", marginLeft: "5px" }}
                                        ></i>
                                    </Link>
                                    <Link
                                        type="button"
                                        className="btn btn-warning d-flex align-items-center"
                                        to="/scheduled-bookings"
                                    >
                                        Scheduled Booking{" "}
                                        <i
                                            className="fa fa-eye"
                                            style={{ fontSize: "22px", marginLeft: "5px" }}
                                        ></i>
                                    </Link>
                                    <Link
                                        type="button"
                                        className="btn btn-primary d-flex align-items-center"
                                        to="/booking-order"
                                    >
                                        Create{" "}
                                        <i
                                            className="fa fa-plus-circle"
                                            style={{ fontSize: "22px", marginLeft: "5px" }}
                                        ></i>
                                    </Link>
                                </div>
                            </div> */}
                        </div>
                        <AdminOptimizeBookingComponent />
                        {/* {showFilter && (
                            <AdminSearchByFilterComponentV2 showFilter={showFilter} />
                        )}

                        {myState.viewDispatching === "dateSearch" ? (
                            <>
                                <AdminOrderSearchTable setExportData={setExportData} />
                            </>
                        ) : (
                            <AdminBookingPastBooking setExportData={setExportData} />
                        )} */}
                    </div>
                </section>
            </div>
        </>
    )
}
export default AdminBookingOptimizePage