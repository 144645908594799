/** @format */

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getRouteandTripByIdV2,
  undoRouteTrip,
  updateRouteById,
  updateRouteTrip,
} from "../../../actions/routeActions";
import {
  getAllStudents,
  getRoutePassengersByCompanyId,
} from "../../../actions/userActions";
import moment from "moment";
import { notifyFailure } from "../../../components/toast";
import { addRouteTrip } from "../../../actions/routeTripActions";
import LoadingComponentRideModal from "../../../components/LoadingComponentRideModal";

const RouteViewModal = ({
  showModal,
  setShowModal,
  selectedRoute,
  setSelectedRoute,
}) => {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const routes = useSelector((state) => state.routes);
  const { loading, routeDetails } = routes;

  const startTripHandler = () => {
    if (routes?.routeDetails?.route?.driverBookingStatus !== "Assigned") {
      notifyFailure("Driver not Assigned!");
    } else {
      dispatch(
        addRouteTrip({
          companyId: auth.user.companyId.id,
          driverId: routes?.routeDetails?.route?.driverId?.id,
          routeId: routes?.routeDetails?.route?.id,
          tripStartTime:
            moment().format().toString().substring(0, 19) + ".000Z",
        })
      );
    }
    // setTimeout(() => {
    //   dataLoaded();
    //   dispatch(
    //     getRouteandTripByIdV2({
    //       _id: params.id,
    //       today: moment(today).format("YYYY-MM-DD"),
    //     })
    //   );
    // }, 1500);
    // }}
  };
  const endTripHandler = () => {};

  const pickCustomerHandler = (customerId, index) => {
    if (!routeDetails.trip) {
      notifyFailure("Trip not started yet!");
      return;
    }
    // console.log("==>", customerId);
    if (toggleValues[index]?.pick) {
      dispatch(
        undoRouteTrip(
          {
            _id: routes?.routeDetails?.trip?.id,
            customerId: customerId,
            undoActions: "pick",
          },
          () => {
            dispatch(
              getRouteandTripByIdV2({
                _id: routes?.routeDetails?.route?.id,
                today: selectedDate.format("YYYY-MM-DD"),
              })
            );
          }
        )
      );
    } else {
      dispatch(
        updateRouteTrip(
          {
            _id: routes?.routeDetails?.trip?.id,
            customerId: customerId,
            pickUpTime: moment().format().toString().substring(0, 19) + ".000Z",
            arrivedTime:
              moment().format().toString().substring(0, 19) + ".000Z",
            // tripStatus: "Ongoing",
            pickUpLat: 24.9261844,
            pickUpLng: 67.1356306,
          },
          () => {
            dispatch(
              getRouteandTripByIdV2({
                _id: routes?.routeDetails?.route?.id,
                today: selectedDate.format("YYYY-MM-DD"),
              })
            );
          }
        )
      );
    }

    // updateToggleValues();
  };

  const dropCustomerHandler = (customerId, index) => {
    if (!routeDetails.trip) {
      notifyFailure("Trip not started yet!");
      return;
    }
    if (toggleValues[index]?.drop) {
      dispatch(
        undoRouteTrip(
          {
            _id: routes?.routeDetails?.trip?.id,
            customerId: customerId,
            undoActions: "dropoff",
          },
          () => {
            dispatch(
              getRouteandTripByIdV2({
                _id: routes?.routeDetails?.route?.id,
                today: selectedDate.format("YYYY-MM-DD"),
              })
            );
          }
        )
      );
    } else {
      dispatch(
        updateRouteTrip(
          {
            _id: routes?.routeDetails?.trip?.id,
            customerId: customerId,
            dropOffTime:
              moment().format().toString().substring(0, 19) + ".000Z",
            // tripStatus: "Completed",
            dropOffLat: 24.9261844,
            dropOffLng: 67.1356306,
          },
          () => {
            dispatch(
              getRouteandTripByIdV2({
                _id: routes?.routeDetails?.route?.id,
                today: selectedDate.format("YYYY-MM-DD"),
              })
            );
          }
        )
      );
    }
    // updateToggleValues();
  };

  const [toggleValues, setToggleValues] = useState([]);

  useEffect(() => {
    if (routeDetails) {
      let arr = [];
      routeDetails?.route?.customerDetails?.forEach((e) => {
        arr.push({
          pick:
            routes?.routeDetails?.trip === null
              ? false
              : routes?.routeDetails?.trip?.customersDetails?.some(
                  (item) =>
                    item?.customerId == e?.customerId?.id &&
                    item.hasOwnProperty("pickUpTime") &&
                    !item.hasOwnProperty("customerCancelReason")
                ),
          noshow:
            routes?.routeDetails?.trip === null
              ? false
              : routes?.routeDetails?.trip?.customersDetails?.some(
                  (item) =>
                    item?.customerId == e?.customerId?.id &&
                    item.hasOwnProperty("customerCancelReason")
                ),
          drop:
            routes?.routeDetails?.trip === null
              ? false
              : routes?.routeDetails?.trip?.customersDetails?.some(
                  (item) =>
                    item?.customerId == e?.customerId?.id &&
                    item.hasOwnProperty("dropOffTime") &&
                    !item.hasOwnProperty("customerCancelReason")
                ),
        });
      });
      setToggleValues(arr);
    }
  }, [routeDetails]);

  const updateToggleValues = () => {
    let arr = [];
    routeDetails?.route?.customerDetails?.forEach((e) => {
      arr.push({
        pick:
          routes?.routeDetails?.trip === null
            ? false
            : routes?.routeDetails?.trip?.customersDetails?.some(
                (item) =>
                  item?.customerId == e?.customerId?.id &&
                  item.hasOwnProperty("pickUpTime") &&
                  !item.hasOwnProperty("customerCancelReason")
              ),
        noshow:
          routes?.routeDetails?.trip === null
            ? false
            : routes?.routeDetails?.trip?.customersDetails?.some(
                (item) =>
                  item?.customerId == e?.customerId?.id &&
                  item.hasOwnProperty("customerCancelReason")
              ),
        drop:
          routes?.routeDetails?.trip === null
            ? false
            : routes?.routeDetails?.trip?.customersDetails?.some(
                (item) =>
                  item?.customerId == e?.customerId?.id &&
                  item.hasOwnProperty("dropOffTime") &&
                  !item.hasOwnProperty("customerCancelReason")
              ),
      });
    });
    setToggleValues(arr);
  };

  const [selectedDate, setSelectedDate] = useState(moment());

  useEffect(() => {
    if (routeDetails)
      dispatch(
        getRouteandTripByIdV2({
          _id: routeDetails?.route?.id,
          today: selectedDate.format("YYYY-MM-DD"),
        })
      );
  }, [selectedDate]);

  // console.log(selectedDate);
  return (
    <>
      <div
        className={`modal fade ${showModal ? "show modal-y-axis" : "hide"}`}
        role="dialog"
      >
        <div
          className="modal-dialog modal-dialog2 d-flex justify-content-center align-items-center modal-xl"
          // style={{ width: "1140px !important" }}
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLongTitle">
                {" "}
                Route View
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                // onClick={() => openDropDown()}
                onClick={() => setShowModal(false)}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            {selectedRoute?.id == routeDetails?.route?.id ? (
              <div className="modal-body">
                <div className="d-flex flex-column rounded ">
                  <div className="d-flex border ">
                    <div className="flex-grow-1">
                      <div className=" d-flex" id="leads-table_length">
                        <div className="p-2">
                          <h5>Route# {routeDetails?.route?.routeNumber}</h5>
                          <p>
                            <strong>Driver Name:</strong>{" "}
                            {routeDetails?.route?.driverId?.firstName}{" "}
                            {routeDetails?.route?.driverId?.lastName}
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="flex-grow-1 mt-3">
                      <div className="d-flex">
                        <div className="row">
                          <ul className="date-display d-flex">
                            <button
                              className="btn btn-secondary"
                              onClick={() =>
                                setSelectedDate(
                                  moment(selectedDate).subtract(1, "days")
                                )
                              }
                            >
                              <i
                                className="fa fa-angle-left"
                                onClick={() =>
                                  setSelectedDate((prev) =>
                                    prev.subtract(1, "days")
                                  )
                                }
                              ></i>
                            </button>
                            <h6 className="pl-3 pr-3 py-2 mt-2 " id="date-info">
                              {/* Thu, Dec 14, 2023 */}
                              {selectedDate.format("ddd, MMM DD, YYYY")}
                            </h6>
                            <button
                              className="btn  btn-secondary"
                              onClick={() =>
                                setSelectedDate(
                                  moment(selectedDate).add(1, "days")
                                )
                              }
                            >
                              <i className="fa fa-angle-right"></i>
                            </button>
                          </ul>
                        </div>
                      </div>
                    </div>

                    {/* <!-- SEARCH BY TASK START --> */}
                    <div className="task-search task-search-new d-flex  py-1 px-lg-3 px-0 border-right-grey align-items-center">
                      {/* <form className="w-100 mr-1 mr-lg-0 mr-md-1 ml-md-1 ml-0 ml-lg-0" onSubmit={(e) => e.preventDefault()}>
                      <div className="input-group bg-grey rounded">
                        <div className="input-group-prepend">
                          <span className="input-group-text border-0 bg-additional-grey">
                            <i className="fa fa-search f-13 text-dark-grey"></i>
                          </span>
                        </div>
                        <input
                          type="text"
                          className="form-control f-14 p-1 border-additional-grey"
                          id="search-text-field"
                          placeholder="Start typing to search"
                          autocomplete="off"
                        />
                      </div>
                    </form> */}
                    </div>
                    {/* <!-- SEARCH BY TASK END --> */}
                  </div>
                </div>
                <div
                  id="leads-table_wrapper"
                  className="dataTables_wrapper dt-bootstrap4 no-footer"
                >
                  <div className="row">
                    <div className="col-sm-12">
                      <table
                        className="table border w-100 dataTable no-footer"
                        id="leads-table"
                        role="grid"
                        aria-describedby="leads-table_info"
                        style={{ overflowX: "auto" }}
                        data-bs-original-title=""
                        title=""
                      >
                        <thead className="card-header">
                          <tr role="row">
                            <th
                              className="sorting_disabled border-right"
                              rowspan="1"
                              colspan="1"
                              aria-label="Next Follow Up"
                            >
                              Customer Name
                            </th>
                            <th
                              title="Pickup Drop Off"
                              className="sorting border-right "
                              tabIndex="0"
                              aria-controls="leads-table"
                              rowspan="1"
                              colspan="1"
                              aria-label="Lead Agent: activate to sort column ascending"
                            >
                              Pickup Time
                            </th>
                            <th
                              title="Date"
                              className="sorting border-right w-30 extraWidth"
                              tabIndex="0"
                              aria-controls="leads-table"
                              rowspan="1"
                              colspan="1"
                              aria-label="Lead Agent: activate to sort column ascending"
                              // style={{ width: "40% !important" }}
                            >
                              Pickup Drop Address
                            </th>
                            <th
                              title="Drop Off Time"
                              className="sorting border-right"
                              tabIndex="0"
                              aria-controls="leads-table"
                              rowspan="1"
                              colspan="1"
                              aria-label="Lead Agent: activate to sort column ascending"
                            >
                              Drop Off Time
                            </th>
                            <th
                              title="Location Name"
                              className="sorting border-right"
                              tabIndex="0"
                              aria-controls="leads-table"
                              rowspan="1"
                              colspan="1"
                              aria-label="Lead Agent: activate to sort column ascending"
                            >
                              Location Name
                            </th>
                            <th
                              title="Note"
                              className="sorting border-right"
                              tabIndex="0"
                              aria-controls="leads-table"
                              rowspan="1"
                              colspan="1"
                              aria-label="Lead Agent: activate to sort column ascending"
                            >
                              Note
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {routeDetails?.route?.customerDetails?.map(
                            (current, i) => (
                              <tr id="row-20" role="row" className="odd border">
                                <td className="border-right">
                                  {current?.customerId?.firstName}{" "}
                                  {current?.customerId?.lastName}{" "}
                                </td>
                                <td className="border-right">
                                  <div className="btn-choice mx-2">
                                    <label className="pr-3">
                                      {current?.pickUpTime?.split(":")[0]}:
                                      {current?.pickUpTime?.split(":")[1]}{" "}
                                      {parseInt(
                                        current?.pickUpTime?.split(":")[0]
                                      ) > 12
                                        ? "PM"
                                        : "AM"}
                                    </label>
                                    <label className="switch">
                                      <input
                                        type="checkbox"
                                        className="switch-input"
                                        id="toggle"
                                        autocomplete="off"
                                        onChange={() =>
                                          pickCustomerHandler(
                                            current?.customerId?.id,
                                            i
                                          )
                                        }
                                        checked={toggleValues[i]?.pick}
                                      />
                                      <span
                                        className="switch-label"
                                        data-on="Yes"
                                        data-off="No"
                                      ></span>
                                      <span className="switch-handle"></span>
                                    </label>
                                  </div>
                                </td>
                                {/* <td className="border-right">
                                <div className="d-flex justify-content-between">
                                  <div>
                                    <div>
                                      <p className="f-12 font-medium mb-0">
                                        <strong>
                                          {current?.pickUpAddress?.address}
                                        </strong>{" "}
                                      </p>
                                      <p className="f-12 mb-0">
                                        {current?.pickUpAddress?.city},{" "}
                                        {current?.pickUpAddress?.state}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="mt-4 px-4">
                                    <div className="hidden sm:block">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="80"
                                        height="12"
                                        viewBox="0 0 40 6"
                                        fill="none"
                                      >
                                        <path
                                          d="M40 3L35 0.113249V5.88675L40 3ZM0 3.5H35.5V2.5H0V3.5Z"
                                          fill="black"
                                        ></path>
                                      </svg>
                                    </div>
                                    <p className="text-center font-medium">
                                      <span>{current.totalMileage}mi</span>
                                    </p>
                                  </div>
                                  <div>
                                    <div>
                                      <p className="f-12 font-medium mb-0">
                                        <strong>
                                          {current?.dropOffAddress?.address}
                                        </strong>
                                      </p>
                                      <p className="f-12 mb-0">
                                        {current?.dropOffAddress?.city},{" "}
                                        {current?.dropOffAddress?.state}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </td> */}
                                <td className="border-right w-30">
                                  <div className="d-flex justify-content-between">
                                    <div>
                                      <div>
                                        <p className="f-12 font-medium mb-0">
                                          <strong>
                                            {current?.pickUpAddress?.address}
                                          </strong>{" "}
                                        </p>
                                      </div>
                                      <div>
                                        <time>
                                          {" "}
                                          {moment(
                                            current?.pickUpDate?.substring(
                                              0,
                                              10
                                            )
                                          ).format("MM/DD/YYYY")}{" "}
                                          -{" "}
                                          {parseInt(
                                            current.pickUpTime.substring(0, 2)
                                          ) === 0
                                            ? 12
                                            : parseInt(
                                                current.pickUpTime.substring(
                                                  0,
                                                  2
                                                )
                                              ) > 12
                                            ? parseInt(
                                                current.pickUpTime.substring(
                                                  0,
                                                  2
                                                )
                                              ) - 12
                                            : parseInt(
                                                current.pickUpTime.substring(
                                                  0,
                                                  2
                                                )
                                              )}
                                          {":" +
                                            current.pickUpTime.substring(3, 5)}
                                          {parseInt(
                                            current?.pickUpTime.substring(0, 2)
                                          ) > 11
                                            ? "pm"
                                            : "am"}
                                        </time>
                                      </div>
                                    </div>
                                    <div className="mt-4 px-4">
                                      <div className="hidden sm:block">
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="50"
                                          height="8"
                                          viewBox="0 0 40 6"
                                          fill="none"
                                        >
                                          <path
                                            d="M40 3L35 0.113249V5.88675L40 3ZM0 3.5H35.5V2.5H0V3.5Z"
                                            fill="black"
                                          ></path>
                                        </svg>
                                      </div>
                                      <p className="text-center font-medium">
                                        {/* <span>{current.distanceFromBaseLocation} {current.distanceFromPickToDropOff} {current.totalMileage} mi</span> */}
                                        <span>
                                          {current?.distanceFromPickToDropOff}{" "}
                                          mi
                                        </span>
                                      </p>
                                    </div>
                                    <div>
                                      <div>
                                        <p className="f-12 font-medium mb-0">
                                          <strong>
                                            {current?.dropOffAddress?.address}
                                          </strong>
                                        </p>
                                        {/* <p className="f-12 mb-0">IL 60623</p> */}
                                      </div>
                                      <div>
                                        <time>
                                          {" "}
                                          {moment(
                                            current?.dropOffDate?.substring(
                                              0,
                                              10
                                            )
                                          ).format("MM/DD/YYYY")}{" "}
                                          -{" "}
                                          {parseInt(
                                            current.dropOffTime.substring(0, 2)
                                          ) === 0
                                            ? 12
                                            : parseInt(
                                                current.dropOffTime.substring(
                                                  0,
                                                  2
                                                )
                                              ) > 12
                                            ? parseInt(
                                                current.dropOffTime.substring(
                                                  0,
                                                  2
                                                )
                                              ) - 12
                                            : parseInt(
                                                current.dropOffTime.substring(
                                                  0,
                                                  2
                                                )
                                              )}
                                          {":" +
                                            current.dropOffTime.substring(3, 5)}
                                          {parseInt(
                                            current.dropOffTime.substring(0, 2)
                                          ) > 11
                                            ? "pm"
                                            : "am"}
                                        </time>
                                      </div>
                                    </div>
                                  </div>
                                </td>
                                <td className="border-right">
                                  <div className="btn-choice mx-2">
                                    <label className="pr-3">
                                      {current?.dropOffTime?.split(":")[0]}:
                                      {current?.dropOffTime?.split(":")[1]}{" "}
                                      {parseInt(
                                        current?.dropOffTime?.split(":")[0]
                                      ) > 12
                                        ? "PM"
                                        : "AM"}
                                    </label>
                                    <label className="switch">
                                      <input
                                        type="checkbox"
                                        className="switch-input"
                                        id="toggle"
                                        autocomplete="off"
                                        onChange={() =>
                                          dropCustomerHandler(
                                            current?.customerId?.id,
                                            i
                                          )
                                        }
                                        checked={toggleValues[i]?.drop}
                                      />
                                      <span
                                        className="switch-label"
                                        data-on="Yes"
                                        data-off="No"
                                      ></span>
                                      <span className="switch-handle"></span>
                                    </label>
                                  </div>
                                </td>
                                <td className="border-right">
                                  <strong>{current?.schoolName} </strong>
                                </td>
                                <td className="border-right">
                                  {current?.dispatchNotes
                                    ? current?.dispatchNotes
                                    : "-"}
                                </td>
                                {/* <td className="border-right">
                                Lorem ipsum dolor sit amet
                              </td> */}
                              </tr>
                            )
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="modal-body">
                <LoadingComponentRideModal />
              </div>
            )}
            {/* <div className="modal-footer">
              <button
                type="button"
                className="btn-secondary rounded f-14 p-2 mr-3"
                data-dismiss="modal"
                onClick={() => setShowModal(false)}
              >
                Close
              </button>
              <button
                type="sunmit"
                className="btn-primary rounded f-14 p-2 mr-3"
                onClick={handleSubmit}
              >
                Update
              </button>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
};
export default RouteViewModal;
