import React from "react";

const UpdatingModal = () => {
  return (
    <>
      <div
        className="modal modal-dialog-centered"
        role="dialog"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-body ">
              {/* <div className="row"> */}
              {/* <div className="spinner-border" role="status">
                <span className="">Updating</span>
              </div>
              <button className="btn btn-primary"> */}
                <span className="spinner-grow spinner-grow-sm"></span>
                &nbsp; Updating..
              {/* </button> */}
              {/* Updating */}
              {/* </div> */}
            </div>
            
          </div>
        </div>
      </div>
    </>
  );
};
export default UpdatingModal;
