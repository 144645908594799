import React from "react";
import { Accordion } from "react-bootstrap";
import AdminRouteLogTableRow from "../../../pages/adminPages/AdminRoutesPage/AdminRouteLogTableRow";
import { useSelector } from "react-redux";
import moment from "moment";
import shift1 from "../../../assets/images/load.png";

const AdminDriverRouteTripReportTable = () => {
  const kpi = useSelector((state) => state.kpi);

  return (
    <>
      {kpi?.routeReportSearchByDriverId !== null ? (
        <div className="d-flex flex-wrap justify-content-between">
          <div className="col-lg-12 col-md-12 ntfcn-tab-content-left w-100 p-4 ">
            <div className="row">
              <div className="col-sm-12">
                <div className="form-group ">
                  <p className="f-18 text-dark mb-12" htmlFor="Name">
                    {" "}
                    {kpi?.routeReportSearchByDriverId?.length > 0 &&
                      kpi?.routeReportSearchByDriverId[0]?.driver?.firstName +
                        " " +
                        kpi?.routeReportSearchByDriverId[0]?.driver?.lastName +
                        " Route Details:"}
                  </p>
                </div>
                <Accordion defaultActiveKey="0">
                  {kpi &&
                  kpi?.routeReportSearchByDriverId &&
                  kpi?.routeReportSearchByDriverId?.length > 0 ? (
                    kpi?.routeReportSearchByDriverId?.map(
                      (routeType, index) => (
                        <Accordion.Item eventKey={index} key={index}>
                          {/* <Accordion.Item> */}
                          <Accordion.Header className="w-100">
                            {moment(
                              routeType?.trip?.tripStartTime
                                ?.toString()
                                .substring(0, 10)
                            ).format("MM/DD/YYYY")}
                          </Accordion.Header>

                          <Accordion.Body>
                            <table
                              className="table border w-100 dataTable no-footer"
                              id="leads-table"
                              role="grid"
                              aria-describedby="leads-table_info"
                              style={{ overflowX: "auto" }}
                            >
                              <thead className="card-header">
                                <tr role="row">
                                  <th
                                    title="Client's Name"
                                    className="sorting border-right "
                                    tabIndex="0"
                                    aria-controls="leads-table"
                                    rowSpan="1"
                                    colSpan="1"
                                    aria-label="Mobile: activate to sort column ascending"
                                  >
                                    Client's Name
                                  </th>
                                  <th
                                    title="Route#"
                                    className="sorting border-right "
                                    tabIndex="0"
                                    aria-controls="leads-table"
                                    rowSpan="1"
                                    colSpan="1"
                                    aria-label="Mobile: activate to sort column ascending"
                                  >
                                    Route#
                                  </th>
                                  <th
                                    title="Status"
                                    className="sorting border-right"
                                    tabIndex="0"
                                    aria-controls="leads-table"
                                    rowSpan="1"
                                    colSpan="1"
                                    aria-label="Lead Agent: activate to sort column ascending"
                                  >
                                    Status
                                  </th>
                                  <th
                                    title="Pickup Time"
                                    className="sorting border-right"
                                    tabIndex="0"
                                    aria-controls="leads-table"
                                    rowSpan="1"
                                    colSpan="1"
                                    aria-label="Lead Agent: activate to sort column ascending"
                                  >
                                    Pickup Time
                                  </th>
                                  <th
                                    title="Dropoff Time"
                                    className="sorting border-right"
                                    tabIndex="0"
                                    aria-controls="leads-table"
                                    rowSpan="1"
                                    colSpan="1"
                                    aria-label="Lead Agent: activate to sort column ascending"
                                  >
                                    Dropoff Time
                                  </th>
                                  <th
                                    title="Pickup"
                                    className="sorting border-right"
                                    tabIndex="0"
                                    aria-controls="leads-table"
                                    rowSpan="1"
                                    colSpan="1"
                                    aria-label="Lead Agent: activate to sort column ascending"
                                  >
                                    Pickup
                                  </th>
                                  <th
                                    title="Dropoff"
                                    className="sorting border-right"
                                    tabIndex="0"
                                    aria-controls="leads-table"
                                    rowSpan="1"
                                    colSpan="1"
                                    aria-label="Lead Agent: activate to sort column ascending"
                                  >
                                    DropOff
                                  </th>
                                  {/* <th
                                        title="Dropoff Latitude"
                                        className="sorting border-right"
                                        tabIndex="0"
                                        aria-controls="leads-table"
                                        rowSpan="1"
                                        colSpan="1"
                                        aria-label="Lead Agent: activate to sort column ascending"
                                    >
                                        Dropoff Latitude
                                    </th>
                                    <th
                                        title="Dropoff Longitude"
                                        className="sorting border-right"
                                        tabIndex="0"
                                        aria-controls="leads-table"
                                        rowSpan="1"
                                        colSpan="1"
                                        aria-label="Lead Agent: activate to sort column ascending"
                                    >
                                        Dropoff Longitude
                                    </th> */}
                                </tr>
                              </thead>
                              <tbody>
                                {routeType?.trip?.customersDetails?.map(
                                  (current, i) => {
                                    return (
                                      <AdminRouteLogTableRow
                                        current={current}
                                        i={i}
                                        routeType={routeType}
                                        showRoute
                                        // setModal={setModal}
                                        key={i}
                                      />
                                    );
                                  }
                                )}
                              </tbody>
                            </table>
                          </Accordion.Body>
                        </Accordion.Item>
                      )
                    )
                  ) : (
                    <div
                      className="dash-stats-list"
                      style={{ textAlign: "center" }}
                    >
                      <br />
                      <br />
                      <img
                        alt=""
                        src={shift1}
                        style={{ width: "145px", opacity: "0.5" }}
                      />
                      <br />
                      <p style={{ opacity: "0.5" }}>No Report Found</p>
                    </div>
                  )}
                </Accordion>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <>
          <div className="dash-stats-list" style={{ textAlign: "center" }}>
            <br />
            <br />
            <img
              alt=""
              src={shift1}
              style={{ width: "145px", opacity: "0.5" }}
            />
            <br />
            <p style={{ opacity: "0.5" }}>No Search or No Report Found</p>
          </div>
        </>
      )}
    </>
  );
};

export default AdminDriverRouteTripReportTable;
