/** @format */

import React, { useEffect, useState } from "react";
import AdminHeader from "../../components/adminComponents/adminHeaderSidebarTitleComponents/AdminHeader";
import AdminSidebar from "../../components/adminComponents/adminHeaderSidebarTitleComponents/AdminSidebar";
import AdminAccountingModalDriverSettlement from "./AdminAccountingModalDriverSettlement";
import { useDispatch, useSelector } from "react-redux";
import {
  getFilterSettlements,
  notPaidAllDriversReservations,
} from "../../actions/invoiceActions";
import { Link, NavLink } from "react-router-dom";
import AdminDiverSettlementSearchFilterRow from "../../components/adminComponents/adminAccountingComponent/AdminDiverSettlementSearchFilterRow";
import { paginationDataToShow } from "../../actions/generalActions";
// import Select from "react-select";
// import { notifyFailure } from "../../components/toast";
import shift1 from "../../assets/images/load.png";
// import { getAllCardsData3 } from "../../actions/kpiActions";
import moment from "moment";
import ExcelExport from "../../ExcelExport";

const AdminAccountingDriverSettlementNewPage = () => {
  const [showDropDown, setshowDropDown] = useState(false);
  const auth = useSelector((state) => state.auth);
  const invoices = useSelector((state) => state.invoices);
  const vehicle = useSelector((x) => x.vehicle);

  // const availableDrivers = useSelector(
  //   (state) => state.users.allDriversWithoutPagination
  // );
  const dispatch = useDispatch();
  const openDropDown = () => {
    for (let key of Object.keys(values)) {
      if (values[key] === "") {
        delete values[key];
      }
    }
    if (showDropDown === false) {
      dispatch(notPaidAllDriversReservations(values));
    }
    setshowDropDown((showDropDown) => !showDropDown);
  };

  const chicagoDateOptions = {
    timeZone: "America/Chicago",
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  };

  const chicagoDate = new Date().toLocaleDateString(
    "en-US",
    chicagoDateOptions
  );
  const currentDate = new Date(chicagoDate);
  // Adjust to get the Monday of the current week
  const firstDayOfWeek = new Date(currentDate);
  firstDayOfWeek.setDate(
    currentDate.getDate() - ((currentDate.getDay() + 6) % 7)
  );

  // // Calculate the Sunday of the current week
  const lastDayOfWeek = new Date(firstDayOfWeek);
  lastDayOfWeek.setDate(firstDayOfWeek.getDate() + 6);

  // Format the dates to YYYY-MM-DD
  const weekFirst = `${firstDayOfWeek.getFullYear()}-${String(
    firstDayOfWeek.getMonth() + 1
  ).padStart(2, "0")}-${String(firstDayOfWeek.getDate()).padStart(2, "0")}`;
  const weekLast = `${lastDayOfWeek.getFullYear()}-${String(
    lastDayOfWeek.getMonth() + 1
  ).padStart(2, "0")}-${String(lastDayOfWeek.getDate()).padStart(2, "0")}`;

  // // Adjust to get the Monday of the previous week
  // const firstDayOfPreviousWeek = new Date(currentDate);
  // firstDayOfPreviousWeek.setDate(
  //   currentDate.getDate() - currentDate.getDay() - 6
  // );

  // // Calculate the Sunday of the previous week
  // const lastDayOfPreviousWeek = new Date(firstDayOfPreviousWeek);
  // lastDayOfPreviousWeek.setDate(firstDayOfPreviousWeek.getDate() + 6);

  // // // Format the dates to YYYY-MM-DD
  // // const weekFirst = `${firstDayOfPreviousWeek.getFullYear()}-${String(
  // //   firstDayOfPreviousWeek.getMonth() + 1
  // // ).padStart(2, "0")}-${String(firstDayOfPreviousWeek.getDate()).padStart(
  // //   2,
  // //   "0"
  // // )}`;
  // // const weekLast = `${lastDayOfPreviousWeek.getFullYear()}-${String(
  // //   lastDayOfPreviousWeek.getMonth() + 1
  // // ).padStart(2, "0")}-${String(lastDayOfPreviousWeek.getDate()).padStart(
  // //   2,
  // //   "0"
  // // )}`;
  // const [lastWeekDates, setLastWeekDates] = useState({
  //   from: `${firstDayOfPreviousWeek.getFullYear()}-${String(
  //     firstDayOfPreviousWeek.getMonth() + 1
  //   ).padStart(2, "0")}-${String(firstDayOfPreviousWeek.getDate()).padStart(
  //     2,
  //     "0"
  //   )}`,
  //   to: `${lastDayOfPreviousWeek.getFullYear()}-${String(
  //     lastDayOfPreviousWeek.getMonth() + 1
  //   ).padStart(2, "0")}-${String(lastDayOfPreviousWeek.getDate()).padStart(
  //     2,
  //     "0"
  //   )}`,
  // });
  const getPreviousWeek = (date) => {
    const firstDayOfPreviousWeek = new Date(date);
    firstDayOfPreviousWeek.setDate(date.getDate() - date.getDay() - 6);
    const lastDayOfPreviousWeek = new Date(firstDayOfPreviousWeek);
    lastDayOfPreviousWeek.setDate(firstDayOfPreviousWeek.getDate() + 6);
    return { from: firstDayOfPreviousWeek, to: lastDayOfPreviousWeek };
  };

  const getNextWeek = (date) => {
    const firstDayOfNextWeek = new Date(date);
    firstDayOfNextWeek.setDate(date.getDate() - date.getDay() + 8);
    const lastDayOfNextWeek = new Date(firstDayOfNextWeek);
    lastDayOfNextWeek.setDate(firstDayOfNextWeek.getDate() + 6);
    return { from: firstDayOfNextWeek, to: lastDayOfNextWeek };
  };

  const initialLastWeekDates = getPreviousWeek(currentDate);

  const [lastWeekDates, setLastWeekDates] = useState({
    from: `${initialLastWeekDates.from.getFullYear()}-${String(
      initialLastWeekDates.from.getMonth() + 1
    ).padStart(2, "0")}-${String(initialLastWeekDates.from.getDate()).padStart(
      2,
      "0"
    )}`,
    to: `${initialLastWeekDates.to.getFullYear()}-${String(
      initialLastWeekDates.to.getMonth() + 1
    ).padStart(2, "0")}-${String(initialLastWeekDates.to.getDate()).padStart(
      2,
      "0"
    )}`,
  });

  const handlePreviousWeek = () => {
    const newDates = getPreviousWeek(new Date(lastWeekDates.from));
    setLastWeekDates({
      from: `${newDates.from.getFullYear()}-${String(
        newDates.from.getMonth() + 1
      ).padStart(2, "0")}-${String(newDates.from.getDate()).padStart(2, "0")}`,
      to: `${newDates.to.getFullYear()}-${String(
        newDates.to.getMonth() + 1
      ).padStart(2, "0")}-${String(newDates.to.getDate()).padStart(2, "0")}`,
    });
  };

  const handleNextWeek = () => {
    const newDates = getNextWeek(new Date(lastWeekDates.from));
    setLastWeekDates({
      from: `${newDates.from.getFullYear()}-${String(
        newDates.from.getMonth() + 1
      ).padStart(2, "0")}-${String(newDates.from.getDate()).padStart(2, "0")}`,
      to: `${newDates.to.getFullYear()}-${String(
        newDates.to.getMonth() + 1
      ).padStart(2, "0")}-${String(newDates.to.getDate()).padStart(2, "0")}`,
    });
  };
  const [values, setValues] = useState({
    // driverId: "",
    companyId: auth.user.companyId.id,
    from: weekFirst,
    to: weekLast,
  });
  const handleChange = (fieldName) => (event) => {
    setValues({
      ...values,
      [fieldName]: event.target.value,
    });
  };

  const cardsData2 = async (e) => {
    const chicagoDateOptions = {
      timeZone: "America/Chicago",
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    };
    const chicagoDate = new Date().toLocaleDateString(
      "en-US",
      chicagoDateOptions
    );
    const parts = chicagoDate.split("/");
    const formattedChicagoDate = `${parts[2]}-${parts[0]}-${parts[1]}`;
    // dispatch(
    //   getAllCardsData3({
    //     companyId: auth.user.companyId.id,
    //     today: formattedChicagoDate,
    //   })
    // );
    // dispatch(
    //   mostTripsByDriverInAWeek({
    //     companyId: auth.user.companyId.id,
    //     today: formattedChicagoDate,
    //   })
    // );
    dispatch(getFilterSettlements(orderSearch));
  };

  const [currentPage, setCurrentPage] = useState(1);
  const [sortField, setSortField] = useState("driverId.firstName");
  const [sortOrder, setSortOrder] = useState("asc"); //asc, desc
  const [searchTerm, setSearchTerm] = useState("");
  const [limit, setLimit] = useState(10); // Set your default limit here

  // Function to handle pagination, sorting, and searching
  const handlePagination = (page, field, order, search, newLimit) => {
    let newPage;
    if (page < 1) {
      newPage = 1;
    } else if (page > paginatedData.totalPages) {
      newPage = paginatedData.totalPages;
    } else {
      newPage = page;
    }
    setCurrentPage(newPage);
    setSortField(field);
    setSortOrder(order);
    setSearchTerm(search);
    setLimit(newLimit);
  };

  const fullData = invoices?.searchedSettlement;
  // Use the pagination function to get the data for the current page
  const paginatedData = paginationDataToShow(
    limit,
    currentPage,
    fullData,
    sortField,
    sortOrder,
    searchTerm
  );

  // console.log("paginatedData",paginatedData)
  // Function to handle sorting icon click
  const handleSortClick = (field, order) => {
    // Call handlePagination with sorting parameters
    handlePagination(currentPage, field, order, searchTerm, limit);
  };
  // Function to determine the color based on the current sorting order
  const getArrowColor = (field, direction) => {
    return sortField === field && sortOrder === direction
      ? "#000000"
      : "#A0A0A0";
  };

  let orderSearch;
  // const chicagoDateOptions = {
  //   timeZone: "America/Chicago",
  //   year: "numeric",
  //   month: "2-digit",
  //   day: "2-digit",
  // };

  // const chicagoDate = new Date().toLocaleDateString(
  //   "en-US",
  //   chicagoDateOptions
  // );
  const currentDate2 = new Date(chicagoDate);

  // // Adjust to get the Monday of the current week
  // const firstDayOfWeek = new Date(currentDate);
  // firstDayOfWeek.setDate(
  //   currentDate.getDate() - ((currentDate.getDay() + 6) % 7)
  // );

  // // Calculate the Sunday of the current week
  // const lastDayOfWeek = new Date(firstDayOfWeek);
  // lastDayOfWeek.setDate(firstDayOfWeek.getDate() + 6);

  // // Format the dates to YYYY-MM-DD
  // const weekFirst = `${firstDayOfWeek.getFullYear()}-${String(
  //   firstDayOfWeek.getMonth() + 1
  // ).padStart(2, "0")}-${String(firstDayOfWeek.getDate()).padStart(2, "0")}`;
  // const weekLast = `${lastDayOfWeek.getFullYear()}-${String(
  //   lastDayOfWeek.getMonth() + 1
  // ).padStart(2, "0")}-${String(lastDayOfWeek.getDate()).padStart(2, "0")}`;
  orderSearch = {
    companyId: auth.user.companyId.id,
    // monthFirst: weekFirst,
    // monthLast: weekLast,
    monthFirst: lastWeekDates.from,
    monthLast: lastWeekDates.to,
  };

  // console.log(orderSearch);
  const data = invoices?.searchedSettlement?.map((e) => {
    const vehicleFound = vehicle?.vehicle?.find(
      (x) => x.id === e?.driverId?.vehicleId
    );

    return {
      "Batch Number": e?.batchNumber,
      "Driver Name": e?.driverId?.firstName + " " + e?.driverId?.lastName,
      "# of Trips": e?.reservations?.length,
      "Vehicle#": vehicleFound?.assetNo,
      Service: vehicleFound?.serviceType?.name,
      "Date Range":
        moment(e?.dateRange?.from?.substring(0, 10)).format("MMM D") +
        " - " +
        moment(e?.dateRange?.to?.substring(0, 10)).format("MMM D, YYYY"),
      "Weekly Earning": parseFloat(e?.netAmount).toFixed(2),
    };
  });

  useEffect(() => {
    cardsData2();
  }, [lastWeekDates]);

  return (
    <>
      <AdminHeader />
      <AdminSidebar />
      <div className="body-wrapper clearfix">
        <section className="main-container " id="fullscreen">
          {/* <div className="preloader-container d-flex justify-content-center align-items-center">
                        <div className="spinner-border" role="status" aria-hidden="true"></div>
                    </div> */}
          <div className="px-4 py-2 border-top-0 emp-dashboard">
            <div className="d-block d-lg-flex d-md-flex justify-content-between action-bar mb-20 mt-3">
              <div
                id="table-actions"
                className="flex-grow-1 align-items-center"
              >
                <h4 className=" mb-0 f-21 text-capitalize font-weight-bold">
                  {" "}
                  Driver Settlement{" "}
                </h4>
              </div>
            </div>
            <div className="card mb-3">
              <div className="card-header">
                <span className="f-18 font-weight-bold text-dark mx-1 ">
                  Search Weekly Reports
                </span>
              </div>
              <div className="card-body p-0 ">
                <div className="row p-2 px-4">
                  {/* <div className="col">
                    <div className="form-group">
                      <label
                        className="f-14 text-dark mb-12"
                        data-label=""
                        htmlFor="clnumber"
                      >
                        Driver Name
                      </label>
                      
                      <Select
                        className="basic-single"
                        classNamePrefix="select"
                        defaultValue={""}
                        value={availableDrivers
                          ?.map((x) => ({
                            label: `${x.firstName} ${x.lastName}`,
                            value: x.id,
                          }))
                          .find(
                            (customer) => customer.value === values?.driverId
                          )}
                        isDisabled={false}
                        isClearable={false}
                        isRtl={false}
                        isSearchable={true}
                        name="pickUp"
                        onChange={(e) => {
                          setValues({
                            ...values,
                            driverId: e.value,
                          });
                        }}
                        options={availableDrivers?.map((x) => ({
                          label: `${x.firstName} ${x.lastName} `,
                          value: x.id,
                        }))}
                      />
                    </div>
                  </div> */}
                  <div className="col">
                    <div className="form-group">
                      <label
                        className="f-14 text-dark mb-12"
                        data-label=""
                        htmlFor="clnumber"
                      >
                        From
                      </label>
                      <input
                        type="date"
                        className="form-control height-35 f-14"
                        placeholder=""
                        id=""
                        autocomplete="off"
                        value={values.from}
                        onChange={handleChange("from")}
                      />
                    </div>
                  </div>
                  <div className="col">
                    <div className="form-group">
                      <label
                        className="f-14 text-dark mb-12"
                        data-label=""
                        htmlFor="clnumber"
                      >
                        To
                      </label>
                      <input
                        type="date"
                        className="form-control height-35 f-14"
                        placeholder=""
                        id=""
                        autocomplete="off"
                        value={values.to}
                        onChange={handleChange("to")}
                      />
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-auto mt-0 mt-sm-3">
                    <div className="d-grid mt-3">
                      <button
                        className="btn btn-success"
                        data-toggle="modal"
                        data-target="#generatedData"
                        aria-hidden="true"
                        onClick={openDropDown}
                      >
                        Search
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-12 col-lg-12 col-md-12 e-d-info-notices">
              <div className="row mb-3 mt-xl-0 mt-lg-4 mt-md-4 mt-4">
                <div className="col-md-12 mt-md-4">
                  <div className="card">
                    <div
                      className="card-header"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div style={{ display: "flex" }}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-clipboard-data"
                          viewBox="0 0 16 16"
                        >
                          <path d="M4 11a1 1 0 1 1 2 0v1a1 1 0 1 1-2 0v-1zm6-4a1 1 0 1 1 2 0v5a1 1 0 1 1-2 0V7zM7 9a1 1 0 0 1 2 0v3a1 1 0 1 1-2 0V9z"></path>
                          <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z"></path>
                          <path d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z"></path>
                        </svg>
                        <span className="f-18 font-weight-bold text-dark mx-1 ">
                          Driver Settlement
                        </span>
                        <span className="f-16 mx-3">
                          {/* {moment(firstDayOfPreviousWeek).format("MMM D")} -{" "} */}
                          {/* {moment(lastDayOfPreviousWeek).format("MMM D, YYYY")} */}
                          <ul className="date-display d-flex align-items-center">
                            <button
                              className="btn btn-secondary py-0"
                              onClick={handlePreviousWeek}
                            >
                              <i className="fa fa-angle-left"></i>
                            </button>
                            <h6 className="pl-3 pr-3 m-0" id="date-info">
                              {moment(lastWeekDates.from).format("MMM D")} -{" "}
                              {moment(lastWeekDates.to).format("MMM D, YYYY")}
                            </h6>
                            <button
                              className="btn btn-secondary py-0"
                              onClick={handleNextWeek}
                            >
                              <i className="fa fa-angle-right"></i>
                            </button>
                          </ul>
                        </span>
                      </div>
                      <div className="d-inline">
                        <ExcelExport
                          excelData={data}
                          fileName={`Driver Settlement Report`}
                        />
                      </div>
                    </div>
                    <div className="card-body">
                      <div
                        id="leads-table_wrapper"
                        className="dataTables_wrapper dt-bootstrap4 no-footer"
                      >
                        <div className="row">
                          <div className="col-sm-12">
                            <table
                              className="table border w-100 dataTable no-footer"
                              id="leads-table"
                              role="grid"
                              aria-describedby="leads-table_info"
                              style={{ overflowX: "auto" }}
                            >
                              <thead className="card-header">
                                <tr role="row">
                                  <th
                                    title="Batch#"
                                    className="sorting border-right"
                                    tabIndex="0"
                                    aria-controls="leads-table"
                                    rowspan="1"
                                    colspan="1"
                                    aria-label="Mobile: activate to sort column ascending"
                                  >
                                    Batch#
                                    <span className="right-sorting-new">
                                      <i
                                        className="fas fa-long-arrow-alt-up"
                                        style={{
                                          color: getArrowColor(
                                            "batchNumber",
                                            "asc"
                                          ),
                                        }}
                                        onClick={() =>
                                          handleSortClick("batchNumber", "asc")
                                        }
                                      ></i>
                                      <i
                                        className="fas fa-long-arrow-alt-down"
                                        style={{
                                          color: getArrowColor(
                                            "batchNumber",
                                            "desc"
                                          ),
                                        }}
                                        onClick={() =>
                                          handleSortClick("batchNumber", "desc")
                                        }
                                      ></i>
                                    </span>
                                  </th>
                                  <th
                                    title="Driver Name"
                                    className="sorting_disabled border-right"
                                    rowspan="1"
                                    colspan="1"
                                    aria-label="Next Follow Up"
                                  >
                                    Driver Name
                                    <span className="right-sorting-new">
                                      <i
                                        className="fas fa-long-arrow-alt-up"
                                        style={{
                                          color: getArrowColor(
                                            "driverId.firstName",
                                            "asc"
                                          ),
                                        }}
                                        onClick={() =>
                                          handleSortClick(
                                            "driverId.firstName",
                                            "asc"
                                          )
                                        }
                                      ></i>
                                      <i
                                        className="fas fa-long-arrow-alt-down"
                                        style={{
                                          color: getArrowColor(
                                            "driverId.firstName",
                                            "desc"
                                          ),
                                        }}
                                        onClick={() =>
                                          handleSortClick(
                                            "driverId.firstName",
                                            "desc"
                                          )
                                        }
                                      ></i>
                                    </span>
                                  </th>
                                  <th
                                    title="# of Trips"
                                    className="sorting border-right"
                                    tabIndex="0"
                                    aria-controls="leads-table"
                                    rowspan="1"
                                    colspan="1"
                                    aria-label="Lead Agent: activate to sort column ascending"
                                  >
                                    # of Trips
                                  </th>
                                  <th
                                    title="Vehicle#"
                                    className="sorting_disabled border-right"
                                    rowspan="1"
                                    colspan="1"
                                    aria-label="Next Follow Up"
                                  >
                                    Vehicle#
                                  </th>
                                  <th
                                    title="Service"
                                    className="sorting_disabled border-right"
                                    rowspan="1"
                                    colspan="1"
                                    aria-label="Next Follow Up"
                                  >
                                    Service
                                  </th>
                                  <th
                                    title="Date Range"
                                    className="sorting border-right"
                                    tabIndex="0"
                                    aria-controls="leads-table"
                                    rowspan="1"
                                    colspan="1"
                                    aria-label="Lead Agent: activate to sort column ascending"
                                  >
                                    Date Range
                                  </th>
                                  <th
                                    title="Weekly Earning"
                                    className="sorting border-right"
                                    tabIndex="0"
                                    aria-controls="leads-table"
                                    rowspan="1"
                                    colspan="1"
                                    aria-label="Lead Agent: activate to sort column ascending"
                                  >
                                    Weekly Earning
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {invoices &&
                                  invoices?.searchedSettlement &&
                                  invoices?.searchedSettlement?.length > 0 &&
                                  paginatedData?.results.map((current, i) => {
                                    // if (current.delete === false) {
                                    return (
                                      <AdminDiverSettlementSearchFilterRow
                                        current={current}
                                        i={i}
                                        // key={i}
                                        // handleModal={handleModal}
                                      />
                                    );
                                    // }
                                  })}
                              </tbody>
                              <tfoot className="card-header">
                                <tr role="row">
                                  <td
                                    colspan="6"
                                    className="border-right text-right "
                                  >
                                    <strong>Total </strong>
                                  </td>
                                  <td>
                                    <strong>
                                      ${" "}
                                      {invoices &&
                                        invoices?.searchedSettlement &&
                                        invoices?.searchedSettlement?.length >
                                          0 &&
                                        parseFloat(
                                          invoices?.searchedSettlement?.reduce(
                                            (sum, order) =>
                                              sum + order.netAmount,
                                            0
                                          )
                                        ).toFixed(2)}
                                    </strong>
                                  </td>
                                </tr>
                              </tfoot>
                            </table>
                            {invoices &&
                              invoices?.searchedSettlement &&
                              paginatedData?.results?.length === 0 && (
                                <div
                                  className="dash-stats-list"
                                  style={{ textAlign: "center" }}
                                >
                                  <br />
                                  <br />
                                  <img
                                    alt=""
                                    src={shift1}
                                    style={{ width: "145px", opacity: "0.5" }}
                                  />
                                  <br />
                                  <p style={{ opacity: "0.5" }}>
                                    No Data Found
                                  </p>
                                </div>
                              )}
                          </div>
                        </div>
                      </div>
                    </div>
                    {invoices &&
                      invoices?.searchedSettlement &&
                      invoices?.searchedSettlement?.length > 0 &&
                      paginatedData?.results?.length > 0 && (
                        <div className="card-footer">
                          <div className="d-flex align-items-center">
                            {
                              // users.users && (
                              invoices.loading === false ? (
                                <div className="flex-grow-1">
                                  <div
                                    className="dataTables_info"
                                    id="leads-table_info"
                                    role="status"
                                    aria-live="polite"
                                  >
                                    Showing{" "}
                                    {1 +
                                      paginatedData.limit *
                                        (paginatedData?.currentPageNumber -
                                          1)}{" "}
                                    to{" "}
                                    {paginatedData.displayingResults +
                                      paginatedData.limit *
                                        (paginatedData?.currentPageNumber -
                                          1)}{" "}
                                    of {paginatedData.totalResults} entries
                                  </div>
                                </div>
                              ) : (
                                <div className="flex-grow-1">
                                  <div
                                    className="dataTables_info"
                                    id="leads-table_info"
                                    role="status"
                                    aria-live="polite"
                                  >
                                    Loading entries
                                  </div>
                                </div>
                              )
                            }
                            <div>
                              <div
                                className="dataTables_paginate paging_simple_numbers"
                                id="leads-table_paginate"
                              >
                                <ul className="pagination">
                                  <li className="page-item">
                                    <NavLink
                                      className="page-link"
                                      to="#"
                                      aria-label="Previous"
                                      // onClick={onClickPrev}
                                      onClick={(e) =>
                                        handlePagination(
                                          currentPage - 1,
                                          sortField,
                                          sortOrder,
                                          searchTerm,
                                          limit
                                        )
                                      }
                                    >
                                      <span aria-hidden="true">Previous</span>
                                      <span className="sr-only">Previous</span>
                                    </NavLink>
                                  </li>
                                  {invoices &&
                                    invoices?.searchedSettlement &&
                                    paginatedData?.paginationArr &&
                                    paginatedData?.paginationArr?.length > 0 &&
                                    Array.from(
                                      {
                                        length:
                                          paginatedData.endPage -
                                          paginatedData.startPage +
                                          1,
                                      },
                                      (_, i) => {
                                        const current =
                                          paginatedData.startPage + i;
                                        return paginatedData?.currentPageNumber ===
                                          current ? (
                                          <li
                                            className="page-item active"
                                            key={i}
                                          >
                                            <Link
                                              to=""
                                              className="page-link"
                                              value={current}
                                            >
                                              {current}
                                            </Link>
                                          </li>
                                        ) : (
                                          <li className="page-item" key={i}>
                                            <Link
                                              className="page-link"
                                              value={current}
                                              onClick={(e) =>
                                                handlePagination(
                                                  parseInt(current),
                                                  sortField,
                                                  sortOrder,
                                                  searchTerm,
                                                  limit
                                                )
                                              }
                                              to=""
                                            >
                                              {current}
                                            </Link>
                                          </li>
                                        );
                                      }
                                    )}
                                  <li className="page-item">
                                    <NavLink
                                      className="page-link"
                                      to="#"
                                      aria-label="Next"
                                      // onClick={onClickNext}
                                      onClick={(e) =>
                                        handlePagination(
                                          currentPage + 1,
                                          sortField,
                                          sortOrder,
                                          searchTerm,
                                          limit
                                        )
                                      }
                                    >
                                      <span aria-hidden="true">Next</span>
                                      <span className="sr-only">Next</span>
                                    </NavLink>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <AdminAccountingModalDriverSettlement
        // current={current}
        showDropDown={showDropDown}
        openDropDown={openDropDown}
        from={values.from}
        to={values.to}
      />
      {/* <div className="body-wrapper clearfix">
                <section className="main-container " id="fullscreen">
                    <div className="px-4 py-2 border-top-0 emp-dashboard">
                        <div className="d-block d-lg-flex d-md-flex justify-content-between action-bar mb-20 mt-3">
                            <div
                                id="table-actions"
                                className="flex-grow-1 align-items-center"
                            >
                                <h4 className=" mb-0 f-21 text-capitalize font-weight-bold">
                                    {" "}
                                    Driver Settlement{" "}
                                </h4>
                            </div>
                            <div
                                className="btn-group mt-2 mt-lg-0 mt-md-0 ml-0 ml-lg-3 ml-md-3"
                                role="group"
                            >
                                <div
                                    className="btn-group me-2"
                                    role="group"
                                    aria-label="First group"
                                >
                                    <button
                                        type="button"
                                        className="btn btn-dark"
                                        data-toggle="tooltip"
                                        data-original-title="Filters"
                                        // onClick={() => setShowFilter(!showFilter)}
                                    >
                                        <i className="fa fa-filter"></i>{" "}
                                    </button>
                                    <button
                                        // href="add-driver-settlement.html"
                                        type="button"
                                        className="btn btn-primary"
                                        // onClick={() => navigate("/add-driver-settlement")}
                                    >
                                        <i className="fas fa-plus-circle d-none d-sm-inline-block"></i>{" "}
                                        Add Driver Settlement
                                    </button>
                                </div>
                            </div>
                        </div>
    
                        {showFilter && (
                            // <AdminSearchByFilterComponentV2 showFilter={showFilter} />
                            <div
                                className={`card-body p-0 bg-white b-shadow-4 rounded ${showFilter ? "d-block" : "d-none"}`}
                            >
                                <div className="row p-2 px-4">
                                    <div className="col-sm-3">
                                        <div className="form-group">
                                            <label
                                                className="f-14 text-dark mb-12"
                                                data-label=""
                                                htmlFor="clnumber"
                                            >
                                                Driver Name
                                            </label>
                                            <Select
                                                className="basic-single"
                                                classNamePrefix="select"
                                                defaultValue={""}
                                                value={availableDrivers
                                                    ?.map((x) => ({
                                                        label: `${x.firstName} ${x.lastName}`,
                                                        value: x.id,
                                                    }))
                                                    .find((customer) => customer.value === values?.driverId)}
                                                isDisabled={false}
                                                isClearable={false}
                                                isRtl={false}
                                                isSearchable={true}
                                                name="pickUp"
                                                onChange={(e) => {
                                                    setValues({
                                                        ...values,
                                                        driverId: e.value,
                                                    });
                                                } }
                                                options={availableDrivers?.map((x) => ({
                                                    label: `${x.firstName} ${x.lastName} `,
                                                    value: x.id,
                                                }))} />
                                        </div>
                                    </div>
                                    <div className="col-sm-3">
                                        <div className="form-group">
                                            <label
                                                className="f-14 text-dark mb-12"
                                                data-label=""
                                                htmlFor="clnumber"
                                            >
                                                Month
                                            </label>
                                            <Select
                                                className="basic-single"
                                                classNamePrefix="select"
                                                defaultValue=""
                                                onChange={handleMonthChange}
                                                options={monthOptions} />
                                        </div>
                                    </div>
                                    <div className="col-sm-3">
                                        <div className="form-group">
                                            <label
                                                className="f-14 text-dark mb-12"
                                                data-label=""
                                                htmlFor="clnumber"
                                            >
                                                Batch#
                                            </label>
                                            <input
                                                type="Batch#"
                                                className="form-control height-35 f-14"
                                                placeholder="Batch#"
                                                id=""
                                                autocomplete="off"
                                                value={values.batchNumber}
                                                onChange={handleChange("batchNumber")} />
                                        </div>
                                    </div>
                                    <div className="col-sm-3 mt-3">
                                        <div className="d-grid mt-3">
                                            <button className="btn btn-success"
                                                onClick={handleClick}
                                            >
                                                Search
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </section>
            </div> */}
    </>
  );
};
export default AdminAccountingDriverSettlementNewPage;
