/** @format */

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSettlementsByCompanyId } from "../../../actions/invoiceActions";
import { paginationDataToShow } from "../../../actions/generalActions";
// import AdminDiverSettlementRow from "./AdminDiverSettlementRow";
import { Link, NavLink } from "react-router-dom";
// import AdminDriverSettlementReportRow from "./AdminDriverSettlementReportRow";
import shift1 from "../../../assets/images/load.png";
import AdminDiverSettlementSearchFilterRow from "./AdminDiverSettlementSearchFilterRow";

const AdminDriverSettlementReport = () => {
  const auth = useSelector((state) => state.auth);
  const invoices = useSelector((state) => state.invoices);

  const dispatch = useDispatch();
  const allSettlementData = async (e) => {
    dispatch(
      getSettlementsByCompanyId({ companyId: auth?.user?.companyId?.id })
    );
  };

  useEffect(() => {
    allSettlementData();
  }, []);

  const [currentPage, setCurrentPage] = useState(1);
  const [sortField, setSortField] = useState("driverId.firstName");
  const [sortOrder, setSortOrder] = useState("asc"); //asc, desc
  const [searchTerm, setSearchTerm] = useState("");
  const [limit, setLimit] = useState(10); // Set your default limit here

  // Function to handle pagination, sorting, and searching
  const handlePagination = (page, field, order, search, newLimit) => {
    let newPage;
    if (page < 1) {
      newPage = 1;
    } else if (page > paginatedData.totalPages) {
      newPage = paginatedData.totalPages;
    } else {
      newPage = page;
    }
    setCurrentPage(newPage);
    setSortField(field);
    setSortOrder(order);
    setSearchTerm(search);
    setLimit(newLimit);
  };

  const fullData = invoices?.reportData;
  // const fullData = invoices?.reportData?.flatMap((item) =>
  //   item.reservations.map((reservation) => ({
  //     ...item,
  //     reservation: reservation,
  //   }))
  // );
  // Use the pagination function to get the data for the current page
  const paginatedData = paginationDataToShow(
    limit,
    currentPage,
    fullData,
    sortField,
    sortOrder,
    searchTerm
  );

  // Function to handle sorting icon click
  const handleSortClick = (field, order) => {
    // Call handlePagination with sorting parameters
    handlePagination(currentPage, field, order, searchTerm, limit);
  };
  // Function to determine the color based on the current sorting order
  const getArrowColor = (field, direction) => {
    return sortField === field && sortOrder === direction
      ? "#000000"
      : "#A0A0A0";
  };
  // console.log(paginatedData, "paginatedData1");

  // // Example input data
  // let inputData = [
  //   {
  //     batch: 1,
  //     reservation: [{ a: "a" }, { b: "b" }],
  //   },
  //   {
  //     batch: 2,
  //     reservation: [{ c: "c" }, { d: "d" }],
  //   },
  // ];

  // // Transforming the data dynamically
  // let transformedData = invoices.reportData.flatMap((item) =>
  //   item.reservations.map((reservation) => ({
  //     ...item,
  //     reservation: reservation,
  //   }))
  // );

  // console.log(fullData, "fullData");

  return (
    <>
      <div className="d-flex flex-column w-tables rounded mt-3 bg-white">
        <div className="d-flex ">
          <div className="flex-grow-1">
            <div className="dataTables_length d-flex" id="leads-table_length">
              <div className="p-2">
                <label>Show entries</label>
              </div>
              <label>
                <select
                  name="leads-table_length"
                  aria-controls="leads-table"
                  className="custom-select custom-select-sm form-control form-control-sm"
                  autoComplete="false"
                  value={limit}
                  onChange={(e) =>
                    handlePagination(
                      1,
                      sortField,
                      sortOrder,
                      searchTerm,
                      parseInt(e.target.value)
                    )
                  }
                >
                  {[10, 25, 50, 100].map((current, i) => {
                    return (
                      <option value={current} key={i}>
                        {current}
                      </option>
                    );
                  })}
                </select>
              </label>
              <div
                id="table-actions"
                className="flex-grow-1 align-items-center"
              >
                <h4 className=" mb-0 f-21 text-capitalize font-weight-bold"></h4>
              </div>
            </div>
          </div>
          <div className="task-search task-search-new search-col-table d-flex  py-1 px-lg-3 px-0 align-items-center">
            <form className="w-100 mr-1 mr-lg-0 mr-md-1 ml-md-1 ml-0 ml-lg-0" onSubmit={(e) => e.preventDefault()}>
              <div className="input-group bg-grey rounded">
                <div className="input-group-prepend">
                  <span className="input-group-text border-0 bg-additional-grey">
                    <i className="fa fa-search f-13 text-dark-grey"></i>
                  </span>
                </div>
                <input
                  type="text"
                  className="form-control f-14 p-1 border-additional-grey"
                  id="search-text-field"
                  placeholder="Start typing to search"
                  value={searchTerm}
                  onChange={(e) =>
                    handlePagination(
                      1,
                      sortField,
                      sortOrder,
                      e.target.value,
                      limit
                    )
                  }
                />
              </div>
            </form>
          </div>
        </div>
        <div
          id="leads-table_wrapper"
          className="dataTables_wrapper dt-bootstrap4 no-footer"
        >
          <div className="row">
            <div className="col-sm-12">
              <table
                className="table border w-100 dataTable no-footer"
                id="leads-table"
                role="grid"
                aria-describedby="leads-table_info"
                style={{ fontSize: "14px" }}
              >
                <thead className="card-header">
                  <tr role="row">
                    <th
                      title="Batch#"
                      className="sorting border-right"
                      tabIndex="0"
                      aria-controls="leads-table"
                      rowSpan="1"
                      colSpan="1"
                      aria-label="Mobile: activate to sort column ascending"
                    >
                      Batch# <span className="right-sorting-new"></span>
                      <span className="right-sorting-new">
                        <i
                          className="fas fa-long-arrow-alt-up"
                          style={{
                            color: getArrowColor("batchNumber", "asc"),
                          }}
                          onClick={() => handleSortClick("batchNumber", "asc")}
                        ></i>
                        <i
                          className="fas fa-long-arrow-alt-down"
                          style={{
                            color: getArrowColor("batchNumber", "desc"),
                          }}
                          onClick={() => handleSortClick("batchNumber", "desc")}
                        ></i>
                      </span>
                    </th>
                    {/* <th
                      title="Order ID"
                      className="sorting border-right"
                      tabIndex="0"
                      aria-controls="leads-table"
                      rowSpan="1"
                      colSpan="1"
                      aria-label="Lead Agent: activate to sort column ascending"
                    >
                      Order ID <span className="right-sorting-new"></span>
                    </th>
                    <th
                      title="Driver Name"
                      className="sorting border-right"
                      tabIndex="0"
                      aria-controls="leads-table"
                      rowSpan="1"
                      colSpan="1"
                      aria-label="Mobile: activate to sort column ascending"
                    >
                      Driver Name <span className="right-sorting-new"></span>
                    </th>

                    <th
                      title="Passenger"
                      className="sorting border-right"
                      tabIndex="0"
                      aria-controls="leads-table"
                      rowSpan="1"
                      colSpan="1"
                      aria-label="Lead Agent: activate to sort column ascending"
                    >
                      Passenger <span className="right-sorting-new"></span>
                    </th>
                    <th
                      title="Pickup - Drop Off"
                      className="sorting border-right"
                      tabIndex="0"
                      aria-controls="leads-table"
                      rowSpan="1"
                      colSpan="1"
                      aria-label="Lead Agent: activate to sort column ascending"
                    >
                      Pickup - Drop Off{" "}
                      <span className="right-sorting-new"></span>
                    </th>
                    <th
                      title="Status"
                      className="sorting border-right"
                      tabIndex="0"
                      aria-controls="leads-table"
                      rowSpan="1"
                      colSpan="1"
                      aria-label="Lead Agent: activate to sort column ascending"
                    >
                      Status <span className="right-sorting-new"></span>
                    </th>

                    <th
                      title="Driver Amount"
                      className="sorting border-right"
                      tabIndex="0"
                      aria-controls="leads-table"
                      rowSpan="1"
                      colSpan="1"
                      aria-label="Lead Agent: activate to sort column ascending"
                    >
                      Driver Amount <span className="right-sorting-new"></span>
                    </th> */}
                    <th
                      title="Driver Name"
                      className="sorting_disabled border-right"
                      rowspan="1"
                      colspan="1"
                      aria-label="Next Follow Up"
                    >
                      Driver Name
                      <span className="right-sorting-new">
                        <i
                          className="fas fa-long-arrow-alt-up"
                          style={{
                            color: getArrowColor("driverId.firstName", "asc"),
                          }}
                          onClick={() =>
                            handleSortClick("driverId.firstName", "asc")
                          }
                        ></i>
                        <i
                          className="fas fa-long-arrow-alt-down"
                          style={{
                            color: getArrowColor("driverId.firstName", "desc"),
                          }}
                          onClick={() =>
                            handleSortClick("driverId.firstName", "desc")
                          }
                        ></i>
                      </span>
                    </th>
                    <th
                      title="#ofTrips"
                      className="sorting border-right"
                      tabIndex="0"
                      aria-controls="leads-table"
                      rowspan="1"
                      colspan="1"
                      aria-label="Lead Agent: activate to sort column ascending"
                    >
                      # of Trips
                    </th>
                    <th
                      title="Vehicle#"
                      className="sorting_disabled border-right"
                      rowspan="1"
                      colspan="1"
                      aria-label="Next Follow Up"
                    >
                      Vehicle#
                    </th>
                    <th
                      title="Service"
                      className="sorting_disabled border-right"
                      rowspan="1"
                      colspan="1"
                      aria-label="Next Follow Up"
                    >
                      Service
                    </th>
                    <th
                      title="Date Range"
                      className="sorting border-right"
                      tabIndex="0"
                      aria-controls="leads-table"
                      rowspan="1"
                      colspan="1"
                      aria-label="Lead Agent: activate to sort column ascending"
                    >
                      Date Range
                    </th>
                    <th
                      title="Weekly Earning"
                      className="sorting border-right"
                      tabIndex="0"
                      aria-controls="leads-table"
                      rowspan="1"
                      colspan="1"
                      aria-label="Lead Agent: activate to sort column ascending"
                    >
                      Weekly Earning
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {invoices &&
                    invoices?.reportData &&
                    invoices?.reportData?.length > 0 &&
                    paginatedData?.results.map((current, i) => {
                      // if (current.delete === false) {
                      return (
                        <AdminDiverSettlementSearchFilterRow
                          current={current}
                          i={i}
                          excel={true}
                          // key={i}
                          // handleModal={handleModal}
                        />
                        // <AdminDriverSettlementReportRow
                        //   current={current}
                        //   i={i}
                        // />
                      );
                      // }
                    })}
                </tbody>
                <tfoot className="card-header">
                  <tr role="row">
                    <td colspan="6" className="border-right text-right ">
                      <strong>Total </strong>
                    </td>
                    <td>
                      <strong>
                        ${" "}
                        {invoices &&
                          invoices?.reportData &&
                          invoices?.reportData?.length > 0 &&
                          parseFloat(
                            invoices?.reportData?.reduce(
                              (sum, order) => sum + order.netAmount,
                              0
                            )
                          ).toFixed(2)}
                      </strong>
                    </td>
                  </tr>
                </tfoot>
              </table>
              {invoices &&
                invoices?.reportData &&
                paginatedData?.results?.length === 0 && (
                  <div
                    className="dash-stats-list"
                    style={{ textAlign: "center" }}
                  >
                    <br />
                    <br />
                    <img
                      alt=""
                      src={shift1}
                      style={{ width: "145px", opacity: "0.5" }}
                    />
                    <br />
                    <p style={{ opacity: "0.5" }}>No Data</p>
                  </div>
                )}
              <div
                id="leads-table_processing"
                className="dataTables_processing card"
                style={{ display: "none" }}
              >
                Processing...
              </div>
            </div>
          </div>
          {invoices &&
            invoices?.reportData &&
            invoices?.reportData?.length > 0 &&
            paginatedData?.results?.length > 0 && (
              <div className="d-flex ">
                {
                  // users.users && (
                  invoices.loading === false ? (
                    <div className="flex-grow-1">
                      <div
                        className="dataTables_info"
                        id="leads-table_info"
                        role="status"
                        aria-live="polite"
                      >
                        Showing{" "}
                        {1 +
                          paginatedData.limit *
                            (paginatedData?.currentPageNumber - 1)}{" "}
                        to{" "}
                        {paginatedData.displayingResults +
                          paginatedData.limit *
                            (paginatedData?.currentPageNumber - 1)}{" "}
                        of {paginatedData.totalResults} entries
                      </div>
                    </div>
                  ) : (
                    <div className="flex-grow-1">
                      <div
                        className="dataTables_info"
                        id="leads-table_info"
                        role="status"
                        aria-live="polite"
                      >
                        Loading entries
                      </div>
                    </div>
                  )
                }
                <div className="search-col-usertable">
                  <div
                    className="dataTables_paginate paging_simple_numbers"
                    id="leads-table_paginate"
                  >
                    <ul className="pagination">
                      <li className="page-item">
                        <NavLink
                          className="page-link"
                          to="#"
                          aria-label="Previous"
                          // onClick={onClickPrev}
                          onClick={(e) =>
                            handlePagination(
                              currentPage - 1,
                              sortField,
                              sortOrder,
                              searchTerm,
                              limit
                            )
                          }
                        >
                          <span aria-hidden="true">Previous</span>
                          <span className="sr-only">Previous</span>
                        </NavLink>
                      </li>
                      {invoices &&
                        invoices?.reportData &&
                        paginatedData?.paginationArr &&
                        paginatedData?.paginationArr?.length > 0 &&
                        Array.from(
                          {
                            length:
                              paginatedData.endPage -
                              paginatedData.startPage +
                              1,
                          },
                          (_, i) => {
                            const current = paginatedData.startPage + i;
                            return paginatedData?.currentPageNumber ===
                              current ? (
                              <li className="page-item active" key={i}>
                                <Link
                                  to=""
                                  className="page-link"
                                  value={current}
                                >
                                  {current}
                                </Link>
                              </li>
                            ) : (
                              <li className="page-item" key={i}>
                                <Link
                                  className="page-link"
                                  value={current}
                                  onClick={(e) =>
                                    handlePagination(
                                      parseInt(current),
                                      sortField,
                                      sortOrder,
                                      searchTerm,
                                      limit
                                    )
                                  }
                                  to=""
                                >
                                  {current}
                                </Link>
                              </li>
                            );
                          }
                        )}
                      <li className="page-item">
                        <NavLink
                          className="page-link"
                          to="#"
                          aria-label="Next"
                          // onClick={onClickNext}
                          onClick={(e) =>
                            handlePagination(
                              currentPage + 1,
                              sortField,
                              sortOrder,
                              searchTerm,
                              limit
                            )
                          }
                        >
                          <span aria-hidden="true">Next</span>
                          <span className="sr-only">Next</span>
                        </NavLink>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            )}
        </div>
      </div>
    </>
  );
};
export default AdminDriverSettlementReport;
