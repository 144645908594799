import React from 'react'
import { Polyline } from '@react-google-maps/api';

const RouteLine = ({path, colorCode}) => {
  const routePath = path.map(point => ({
    lat: point.location.latitude,
    lng: point.location.longitude,
  }));
  // console.log(routePath, "routePathroutePathroutePath")
  return (
    <Polyline
      path={routePath}
      options={{
        strokeColor: colorCode,
        strokeOpacity: 1.0,
        strokeWeight: 2,
      }}
    />
  )
}

export default RouteLine
