import moment from "moment";
import React from "react";
import { useSelector } from "react-redux";

function RidePatternTable({ tableData }) {
  const routes = useSelector((state) => state.routes);
  const service = useSelector((state) => state.service);

  // const { routeDetails } = routes;

  return (
    <table
      className="table border w-100 dataTable no-footer"
      id="leads-table"
      role="grid"
      aria-describedby="leads-table_info"
      style={{ overflowX: "auto" }}
    >
      <thead className="card-header">
        <tr role="row">
          <th
            title="S.no"
            className="sorting border-right"
            tabIndex="0"
            aria-controls="leads-table"
            rowSpan="1"
            colSpan="1"
            aria-label="Mobile: activate to sort column ascending"
          >
            S.no
          </th>{" "}
          <th
            title="Action"
            className="sorting border-right"
            tabIndex="0"
            aria-controls="leads-table"
            rowSpan="1"
            colSpan="1"
            aria-label="Mobile: activate to sort column ascending"
          >
            Action
          </th>
          <th
            title="Passenger Name"
            className="sorting border-right"
            tabIndex="0"
            aria-controls="leads-table"
            rowSpan="1"
            colSpan="1"
            aria-label="Mobile: activate to sort column ascending"
          >
            Passenger Name
          </th>
          <th
            title="Address"
            className="sorting border-right"
            tabIndex="0"
            aria-controls="leads-table"
            rowSpan="1"
            colSpan="1"
            aria-label="Lead Agent: activate to sort column ascending"
          >
            Address
          </th>
          <th
            title="Location"
            className="sorting border-right"
            tabIndex="0"
            aria-controls="leads-table"
            rowSpan="1"
            colSpan="1"
            aria-label="Lead Agent: activate to sort column ascending"
          >
            Location
          </th>
          <th
            title="Time"
            className="sorting border-right"
            tabIndex="0"
            aria-controls="leads-table"
            rowSpan="1"
            colSpan="1"
            aria-label="Lead Agent: activate to sort column ascending"
          >
            Time
          </th>
          <th
            title="Days"
            className="sorting border-right"
            tabIndex="0"
            aria-controls="leads-table"
            rowSpan="1"
            colSpan="1"
            aria-label="Lead Agent: activate to sort column ascending"
          >
            Days
          </th>
        </tr>
      </thead>
      <tbody>
        {tableData?.map((current, i) => {
          return (
            <tr role="row" className="odd border" key={i}>
              <td className="border-right">{i + 1}</td>
              <td className="border-right">{current?.routeType}</td>
              <td className="border-right">
                <div>
                  <p className="m-0">
                    {current?.customerId?.firstName}{" "}
                    {current?.customerId?.lastName}
                  </p>
                </div>
              </td>

              <td className="border-right">
                <div>
                  <p className="m-0">
                    {current.routeType == "Pick"
                      ? current?.customerDetails?.pickUpAddress?.address
                      : current?.customerDetails?.dropOffAddress?.address}
                  </p>
                </div>
              </td>

              <td className="border-right">
                {current?.customerDetails?.schoolName}
              </td>
              <td className="border-right">
                {moment(current?.time, "hh:mm").format(" hh:mm a")}
              </td>
              {/* <td className="border-right">
                {current?.fareAndDistance?.totalMileage} miles
              </td> */}
              <td className="border-right">
                {current?.customerDetails?.daysOfWeek?.map(
                  (x) => x.active && <span> {x.dayName.slice(0, 3)},</span>
                )}
              </td>

              <td className="border-right">{current?.dispatchNotes}</td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

export default RidePatternTable;
