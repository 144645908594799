/** @format */

import React from "react";

const AdminBookingUploadFileModalTableRow = ({
  current,
  i,
  removeItemByIndex,
}) => {
  // const chicagoDateOptions = {
  //   timeZone: "America/Chicago",
  //   year: "numeric",
  //   month: "2-digit",
  //   day: "2-digit",
  // };
  const date = new Date(
    Math.round((current?.[1] - 25569) * 86400 * 1000)
  ).toISOString();
  const subStr = date.substring(0, 10);
  const parts = subStr.split("-");
  const displayDate = `${parts[1]}/${parts[2]}/${parts[0]}`;
  const dateTime = new Date(
    Math.round((current?.[1] + current[6] - 25569) * 86400 * 1000)
  );
  let od = new Date(new Date(dateTime).getTime()).toUTCString();
  let oldDateTime = od.toString().split(" ")[4].substring(0, 5);

  // console.log(date, displayDate, od, dateTime, oldDateTime);
  // const
  return (
    <>
      <tr
        role="row"
        className="odd border"
        key={i}
        // onMouseLeave={() => setshowMoreVertDropDown(false)}
      >
        <td className="border-right">{current?.[0]}</td>
        <td className="border-right">{displayDate}</td>
        <td className="border-right">
          <strong>
            {current[2]} {current[3]}
          </strong>
          <br />
          {current[4]}
          <br />
        </td>
        <td className="border-right">{current[5]}</td>
        <td className="border-right">
          {parseInt(oldDateTime?.substring(0, 2)) === 0
            ? 12
            : parseInt(oldDateTime?.substring(0, 2)) > 12
            ? parseInt(oldDateTime?.substring(0, 2)) - 12
            : parseInt(oldDateTime?.substring(0, 2))}
          {":" + oldDateTime?.substring(3, 5)}
          {parseInt(oldDateTime?.substring(0, 2)) > 11 ? "pm" : "am"}
        </td>
        <td className="border-right wi-30min">
          <div className="d-flex justify-content-between">
            <div>
              <div>
                <p className="f-12 font-medium mb-0">
                  <strong>{current[7]}</strong>{" "}
                </p>
              </div>
              <div></div>
            </div>
            <div className="mt-4 px-4">
              <div className="hidden sm:block">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="50"
                  height="8"
                  viewBox="0 0 40 6"
                  fill="none"
                >
                  <path
                    d="M40 3L35 0.113249V5.88675L40 3ZM0 3.5H35.5V2.5H0V3.5Z"
                    fill="black"
                  ></path>
                </svg>
              </div>
              <p className="text-center font-medium">
                {/* <span>{current.distanceFromBaseLocation} {current.distanceFromPickToDropOff} {current.totalMileage} mi</span> */}
                <span>{current[9]} mi</span>
              </p>
            </div>
            <div>
              <div>
                <p className="f-12 font-medium mb-0">
                  <strong>{current[8]}</strong>
                </p>
              </div>
              <div></div>
            </div>
          </div>
        </td>
        <td className="border-right">
          <button
            type="button"
            className="btn-secondary"
            data-dismiss="modal"
            onClick={() => removeItemByIndex(i)}
          >
            <i className="fa fa-close"></i>
          </button>
        </td>
      </tr>
    </>
  );
};

export default AdminBookingUploadFileModalTableRow;
