/** @format */

import React, { useEffect } from "react";
import AdminHeader from "../../components/adminComponents/adminHeaderSidebarTitleComponents/AdminHeader";
import AdminSidebar from "../../components/adminComponents/adminHeaderSidebarTitleComponents/AdminSidebar";
import { NavLink, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { navigateUserFunc } from "../../actions/generalActions";
import NotAllowed from "../../components/NotAllowed";
import AdminNotesTable from "../../components/adminComponents/adminNotesComponent/AdminNotesTable";

const AdminNotePage = () => {
  let navigate = useNavigate();
  const auth = useSelector((state) => state?.auth);
  useEffect(() => {
    if (
      auth?.user?.permissionId?.notes?.isAllow === false ||
      auth?.user?.disable === true
    ) {
      navigateUserFunc(auth?.user, navigate);
    }
  }, []);
  return (
    <>
      <AdminHeader />
      <AdminSidebar />
      <div className="body-wrapper clearfix">
        <section className="main-container " id="fullscreen">
          <div className="px-4 py-2 border-top-0 emp-dashboard">
            <AdminNotesTable />
          </div>
        </section>
      </div>
    </>
  );
};
export default AdminNotePage;
