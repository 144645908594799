/** @format */

import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import moment from "moment";
import InvoicingTableRow from "./InvoicingTableRow";
import { getAllInvoicesActionV2 } from "../../../actions/invoiceActions";
import { Link, NavLink } from "react-router-dom";
import shift1 from "../../../assets/images/load.png";
import { paginationDataToShow } from "../../../actions/generalActions";
// import { getAllReservation } from "../../../actions/reservationAction";

const InvoicingTable = () => {
  const invoices = useSelector((state) => state.invoices);
  const auth = useSelector((state) => state.auth);
  const [showMoreVertDropDown, setshowMoreVertDropDown] = useState(false);
  const openMoreVertDropDown = () => {
    setshowMoreVertDropDown((showMoreVertDropDown) => !showMoreVertDropDown);
  };
  const dispatch = useDispatch();

  const getInvoices = async () => {
    dispatch(
      getAllInvoicesActionV2({
        companyId: auth.user.companyId.id,
        today: moment().format("YYYY-MM-DD"),
        weekFirst:
          moment().format("dddd") !== "Sunday"
            ? moment().startOf("week").add(1, "days").format("YYYY-MM-DD")
            : moment().startOf("week").add(-6, "days").format("YYYY-MM-DD"),
        weekLast:
          moment().format("dddd") !== "Sunday"
            ? moment().endOf("week").add(1, "days").format("YYYY-MM-DD")
            : moment().endOf("week").add(-6, "days").format("YYYY-MM-DD"),
        monthFirst: moment().startOf("month").format("YYYY-MM-DD"),
        monthLast: moment().endOf("month").format("YYYY-MM-DD"),
      })
    );
  };

  const tempData = (data) => {
    setFullData(data?.invoices);
  };

  useEffect(() => {
    getInvoices();
  }, []);

  const [currentPage, setCurrentPage] = useState(1);
  const [sortField, setSortField] = useState("reservationId.orderId");
  const [sortOrder, setSortOrder] = useState("desc"); //asc, desc
  const [searchTerm, setSearchTerm] = useState("");
  const [limit, setLimit] = useState(50); // Set your default limit here

  // Function to handle pagination, sorting, and searching
  const handlePagination = (page, field, order, search, newLimit) => {
    let newPage;
    if (page < 1) {
      newPage = 1;
    } else if (page > paginatedData.totalPages) {
      newPage = paginatedData.totalPages;
    } else {
      newPage = page;
    }
    setCurrentPage(newPage);
    setSortField(field);
    setSortOrder(order);
    setSearchTerm(search);
    setLimit(newLimit);
  };

  const [fullData, setFullData] = useState(invoices?.invoices);

  // Use the pagination function to get the data for the current page
  const paginatedData = paginationDataToShow(
    limit,
    currentPage,
    fullData,
    sortField,
    sortOrder,
    searchTerm
  );

  // Function to handle sorting icon click
  const handleSortClick = (field, order) => {
    // Call handlePagination with sorting parameters
    handlePagination(currentPage, field, order, searchTerm, limit);
  };
  // Function to determine the color based on the current sorting order
  const getArrowColor = (field, direction) => {
    return sortField === field && sortOrder === direction
      ? "#000000"
      : "#A0A0A0";
  };
  // console.log(paginatedData, "paginatedData1");

  return (
    <>
      <div className="d-flex flex-column w-tables rounded mt-3 bg-white">
        <div className="d-flex border">
          <div className="flex-grow-1">
            <div className="dataTables_length d-flex" id="leads-table_length">
              <div className="p-2">
                <label>Show entries</label>
              </div>
              <label>
                <select
                  name="leads-table_length"
                  aria-controls="leads-table"
                  className="custom-select custom-select-sm form-control form-control-sm"
                  autoComplete="false"
                  value={limit} // Make sure to set the value attribute to the current limit
                  onChange={(e) =>
                    handlePagination(
                      1,
                      sortField,
                      sortOrder,
                      searchTerm,
                      parseInt(e.target.value)
                    )
                  }
                >
                  {[10, 25, 50, 100].map((current, i) => {
                    return (
                      <option value={current} key={i}>
                        {current}
                      </option>
                    );
                  })}
                </select>
              </label>
            </div>
          </div>
          <div className="task-search task-search-new d-flex  py-1 px-lg-3 px-0 border-right-grey align-items-center">
            <form className="w-100 mr-1 mr-lg-0 mr-md-1 ml-md-1 ml-0 ml-lg-0" onSubmit={(e) => e.preventDefault()}>
              <div className="input-group bg-grey rounded">
                <div className="input-group-prepend">
                  <span className="input-group-text border-0 bg-additional-grey">
                    <i className="fa fa-search f-13 text-dark-grey"></i>
                  </span>
                </div>
                <input
                  type="text"
                  className="form-control f-14 p-1 border-additional-grey"
                  id="search-text-field"
                  placeholder="Start typing to search"
                  value={searchTerm}
                  onChange={(e) =>
                    handlePagination(
                      1,
                      sortField,
                      sortOrder,
                      e.target.value,
                      limit
                    )
                  }
                />
              </div>
            </form>
          </div>
        </div>
        <div
          id="leads-table_wrapper"
          className="dataTables_wrapper dt-bootstrap4 no-footer"
        >
          <div className="row">
            <div className="col-sm-12">
              <table
                className="table border w-100 dataTable no-footer"
                id="leads-table"
                role="grid"
                aria-describedby="leads-table_info"
                style={{ overflowX: "auto" }}
              >
                <thead className="card-header">
                  <tr role="row">
                    <th
                      title="Order ID"
                      className="sorting border-right"
                      tabIndex="0"
                      aria-controls="leads-table"
                      rowSpan="1"
                      colSpan="1"
                      aria-label="Mobile: activate to sort column ascending"
                    >
                      Order ID{" "}
                      <span className="right-sorting-new">
                        <i
                          className="fas fa-long-arrow-alt-up"
                          style={{
                            color: getArrowColor(
                              "reservationId.orderId",
                              "asc"
                            ),
                          }}
                          onClick={() =>
                            handleSortClick("reservationId.orderId", "asc")
                          }
                        ></i>
                        <i
                          className="fas fa-long-arrow-alt-down"
                          style={{
                            color: getArrowColor(
                              "reservationId.orderId",
                              "desc"
                            ),
                          }}
                          onClick={() =>
                            handleSortClick("reservationId.orderId", "desc")
                          }
                        ></i>
                      </span>
                    </th>
                    <th
                      title="Vehicle#"
                      className="sorting_disabled border-right"
                      rowSpan="1"
                      colSpan="1"
                      aria-label="Next Follow Up"
                    >
                      Vehicle#{" "}
                      <span className="right-sorting-new">
                        <i
                          className="fas fa-long-arrow-alt-up"
                          style={{
                            color: getArrowColor(
                              "reservationId.assetId.assetNo",
                              "asc"
                            ),
                          }}
                          onClick={() =>
                            handleSortClick(
                              "reservationId.assetId.assetNo",
                              "asc"
                            )
                          }
                        ></i>
                        <i
                          className="fas fa-long-arrow-alt-down"
                          style={{
                            color: getArrowColor(
                              "reservationId.assetId.assetNo",
                              "desc"
                            ),
                          }}
                          onClick={() =>
                            handleSortClick(
                              "reservationId.assetId.assetNo",
                              "desc"
                            )
                          }
                        ></i>
                      </span>
                    </th>

                    <th
                      title="Passenger"
                      className="sorting border-right"
                      tabIndex="0"
                      aria-controls="leads-table"
                      rowSpan="1"
                      colSpan="1"
                      aria-label="Lead Agent: activate to sort column ascending"
                    >
                      Passenger{" "}
                      <span className="right-sorting-new">
                        <i
                          className="fas fa-long-arrow-alt-up"
                          style={{
                            color: getArrowColor(
                              "reservationId.customerId.firstName",
                              "asc"
                            ),
                          }}
                          onClick={() =>
                            handleSortClick(
                              "reservationId.customerId.firstName",
                              "asc"
                            )
                          }
                        ></i>
                        <i
                          className="fas fa-long-arrow-alt-down"
                          style={{
                            color: getArrowColor(
                              "reservationId.customerId.firstName",
                              "desc"
                            ),
                          }}
                          onClick={() =>
                            handleSortClick(
                              "reservationId.customerId.firstName",
                              "desc"
                            )
                          }
                        ></i>
                      </span>
                    </th>

                    <th
                      title="Amount"
                      className="sorting border-right"
                      tabIndex="0"
                      aria-controls="leads-table"
                      rowSpan="1"
                      colSpan="1"
                      aria-label="Lead Agent: activate to sort column ascending"
                    >
                      Amount{" "}
                      <span className="right-sorting-new">
                        <i
                          className="fas fa-long-arrow-alt-up"
                          style={{
                            color: getArrowColor(
                              "reservationId.totalAmount",
                              "asc"
                            ),
                          }}
                          onClick={() =>
                            handleSortClick("reservationId.totalAmount", "asc")
                          }
                        ></i>
                        <i
                          className="fas fa-long-arrow-alt-down"
                          style={{
                            color: getArrowColor(
                              "reservationId.totalAmount",
                              "desc"
                            ),
                          }}
                          onClick={() =>
                            handleSortClick("reservationId.totalAmount", "desc")
                          }
                        ></i>
                      </span>
                    </th>
                    <th
                      title="Status"
                      className="sorting border-right"
                      tabIndex="0"
                      aria-controls="leads-table"
                      rowSpan="1"
                      colSpan="1"
                      aria-label="Lead Agent: activate to sort column ascending"
                    >
                      Status{" "}
                      <span className="right-sorting-new">
                        <i
                          className="fas fa-long-arrow-alt-up"
                          style={{
                            color: getArrowColor("reservationId.status", "asc"),
                          }}
                          onClick={() =>
                            handleSortClick("reservationId.status", "asc")
                          }
                        ></i>
                        <i
                          className="fas fa-long-arrow-alt-down"
                          style={{
                            color: getArrowColor(
                              "reservationId.status",
                              "desc"
                            ),
                          }}
                          onClick={() =>
                            handleSortClick("reservationId.status", "desc")
                          }
                        ></i>
                      </span>
                    </th>
                    <th
                      title="Action"
                      className="sorting border-right"
                      rowSpan="1"
                      colSpan="1"
                      aria-label="Action"
                    >
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {invoices &&
                    invoices?.invoices &&
                    invoices?.invoices?.length > 0 &&
                    paginatedData?.results.map((current, i) => {
                      return (
                        <InvoicingTableRow current={current} i={i} key={i} />
                      );
                    })}
                </tbody>
                <tfoot className="card-header">
                  <tr role="row">
                    <td colspan="5" className="border-right text-right ">
                      <strong>Total </strong>
                    </td>
                    <td>
                      <strong>
                        ${" "}
                        {invoices &&
                          invoices?.invoices &&
                          invoices?.invoices?.length > 0 &&
                          invoices?.cardDetails?.length > 0 &&
                          // paginatedData?.results?.length > 0 &&
                          invoices?.cardDetails[2].sum}
                      </strong>
                    </td>
                  </tr>
                </tfoot>
              </table>
              {invoices &&
                invoices?.invoices &&
                paginatedData?.results?.length === 0 && (
                  <div
                    className="dash-stats-list"
                    style={{ textAlign: "center" }}
                  >
                    <br />
                    <br />
                    <img
                      alt=""
                      src={shift1}
                      style={{ width: "145px", opacity: "0.5" }}
                    />
                    <br />
                    <p style={{ opacity: "0.5" }}>No Data Found</p>
                  </div>
                )}
              <div
                id="leads-table_processing"
                className="dataTables_processing card"
                style={{ display: "none" }}
              >
                Processing...
              </div>
            </div>
          </div>
          {invoices &&
            invoices?.invoices &&
            invoices?.invoices?.length > 0 &&
            paginatedData?.results?.length > 0 && (
              <div className="d-flex border">
                {invoices.loading === false ? (
                  <div className="flex-grow-1">
                    <div
                      className="dataTables_info"
                      id="leads-table_info"
                      role="status"
                      aria-live="polite"
                    >
                      Showing{" "}
                      {1 +
                        paginatedData.limit *
                          (paginatedData?.currentPageNumber - 1)}{" "}
                      to{" "}
                      {paginatedData.displayingResults +
                        paginatedData.limit *
                          (paginatedData?.currentPageNumber - 1)}{" "}
                      of {paginatedData.totalResults} entries
                    </div>
                  </div>
                ) : (
                  <div className="flex-grow-1">
                    <div
                      className="dataTables_info"
                      id="leads-table_info"
                      role="status"
                      aria-live="polite"
                    >
                      Loading entries
                    </div>
                  </div>
                )}
                <div>
                  <div
                    className="dataTables_paginate paging_simple_numbers"
                    id="leads-table_paginate"
                  >
                    <ul className="pagination">
                      <li className="page-item">
                        <NavLink
                          className="page-link"
                          to="#"
                          aria-label="Previous"
                          onClick={(e) =>
                            handlePagination(
                              currentPage - 1,
                              sortField,
                              sortOrder,
                              searchTerm,
                              limit
                            )
                          }
                        >
                          <span aria-hidden="true">Previous</span>
                          <span className="sr-only">Previous</span>
                        </NavLink>
                      </li>
                      {invoices &&
                        invoices?.invoices &&
                        paginatedData?.paginationArr &&
                        paginatedData?.paginationArr?.length > 0 &&
                        Array.from(
                          {
                            length:
                              paginatedData.endPage -
                              paginatedData.startPage +
                              1,
                          },
                          (_, i) => {
                            const current = paginatedData.startPage + i;
                            return paginatedData?.currentPageNumber ===
                              current ? (
                              <li className="page-item active" key={i}>
                                <Link
                                  to=""
                                  className="page-link"
                                  value={current}
                                >
                                  {current}
                                </Link>
                              </li>
                            ) : (
                              <li className="page-item" key={i}>
                                <Link
                                  className="page-link"
                                  value={current}
                                  onClick={(e) =>
                                    handlePagination(
                                      parseInt(current),
                                      sortField,
                                      sortOrder,
                                      searchTerm,
                                      limit
                                    )
                                  }
                                  to=""
                                >
                                  {current}
                                </Link>
                              </li>
                            );
                          }
                        )}
                      <li className="page-item">
                        <NavLink
                          className="page-link"
                          to="#"
                          aria-label="Next"
                          onClick={(e) =>
                            handlePagination(
                              currentPage + 1,
                              sortField,
                              sortOrder,
                              searchTerm,
                              limit
                            )
                          }
                        >
                          <span aria-hidden="true">Next</span>
                          <span className="sr-only">Next</span>
                        </NavLink>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            )}
          {/* <div>
              <div
                className="dataTables_paginate paging_simple_numbers"
                id="leads-table_paginate"
              >
                <ul className="pagination">
                  <li className="page-item">
                    <NavLink
                      className="page-link"
                      to=""
                      aria-label="Previous"
                      onClick={onClickPrev}
                    >
                      <span aria-hidden="true">Previous</span>
                      <span className="sr-only">Previous</span>
                    </NavLink>
                  </li>
                  {invoices?.invoices &&
                    invoices?.invoices?.paginationArr &&
                    invoices?.invoices?.paginationArr.length > 0 &&
                    // invoices?.invoices?.paginationArr.map((current, i) => {
                    Array.from({ length: endPage - startPage + 1 }, (_, i) => {
                      const current = startPage + i; 
                      return currentPageNumber === current ? (
                        <li className="page-item active" key={i}>
                          <Link className="page-link" value={current} to="">
                            {current}
                          </Link>
                        </li>
                      ) : (
                        <li className="page-item" key={i}>
                          <Link
                            className="page-link"
                            to=""
                            value={current}
                            onClick={() => handleChangeData(current)}
                          >
                            {current}
                          </Link>
                        </li>
                      );
                    })}
                  <li className="page-item">
                    <NavLink
                      className="page-link"
                      to=""
                      aria-label="Next"
                      onClick={onClickNext}
                    >
                      <span aria-hidden="true">Next</span>
                      <span className="sr-only">Next</span>
                    </NavLink>
                  </li>
                </ul>
              </div>
            </div> */}
        </div>
      </div>
    </>
  );
};
export default InvoicingTable;
