/** @format */

import React, { useEffect, useState } from "react";
import AdminBookingTodayBooking from "../../../components/adminComponents/adminBookingComponents/AdminBookingTodayBooking";
import AdminHeader from "../../../components/adminComponents/adminHeaderSidebarTitleComponents/AdminHeader";
import AdminSidebar from "../../../components/adminComponents/adminHeaderSidebarTitleComponents/AdminSidebar";
import RoutesTable from "./RoutesTable";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { navigateUserFunc } from "../../../actions/generalActions";
import NotAllowed from "../../../components/NotAllowed";
import AdminBookingUploadExcelData from "../../../components/adminComponents/adminBookingComponents/AdminBookingUploadExcelData";
import AdminRouteUploadFileModal from "../AdminAddRoutePage/AdminRouteUploadFileModal";
import { getAllRoutesOfCompanyV2 } from "../../../actions/routeActions";
import { getRoutePassengersByCompanyId } from "../../../actions/userActions";

const AdminBookingTodayPage = () => {
  let navigate = useNavigate();
  const auth = useSelector((state) => state.auth);

  useEffect(() => {
    if (
      auth?.user?.permissionId?.routes?.isAllow === false ||
      auth?.user?.disable === true
    ) {
      navigateUserFunc(auth?.user, navigate);
    }
  }, []);

  const [routeNo, setRouteNo] = useState("");
  const handleChange = (event) => {
    setRouteNo(event.target.value);
  };

  const [showUploadFileModal, setShowUploadFileModal] = useState(false);
  const toggleUploadFileModal = () => {
    setShowUploadFileModal((showDropDown) => !showDropDown);
  };
  const dispatch = useDispatch();

  const refresh = () => {
    dispatch(
      getAllRoutesOfCompanyV2({
        companyId: auth.user.companyId.id,
      })
    );
  };
  useEffect(() => {
    dispatch(
      getRoutePassengersByCompanyId({ companyId: auth.user.companyId.id })
    );
  }, []);
  return (
    <>
      <AdminHeader />
      <AdminSidebar />
      <div className="body-wrapper clearfix">
        <section className="main-container " id="fullscreen">
          <div className="px-4 py-2 border-top-0 emp-dashboard">
            <div className="d-block d-lg-flex d-md-flex justify-content-between action-bar mb-20 mt-3">
              <div
                id="table-actions"
                className="flex-grow-1 align-items-center"
              >
                <h4 className=" mb-0 f-21 text-capitalize font-weight-bold">
                  {" "}
                  Routes
                </h4>
              </div>

              {/* <div
                className="btn-group mt-2 mt-lg-0 mt-md-0 ml-0 ml-lg-3 ml-md-3"
                role="group"
              >
                <input type="text" placeholder="Search By Route Number" className="form-control height-35 f-14"
                value={routeNo} onChange={handleChange}
                />
              </div> */}

              {/* <div
                className="btn-group mt-2 mt-lg-0 mt-md-0 ml-0 ml-lg-3 ml-md-3"
                role="group"
              >
                <NavLink to="/routes" type="button" className="btn-secondary rounded f-14 p-2 mr-3 float-left mb-2 mb-lg-0 mb-md-0 activee" id="add-lead">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-eye" viewBox="0 0 16 16">
                    <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z" />
                    <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z" />
                  </svg> Routes
                </NavLink>
                <NavLink
                  to="add"
                  type="button"
                  className="btn-secondary rounded f-14 p-2 mr-3 float-left mb-2 mb-lg-0 mb-md-0"
                  id="add-lead"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-plus-circle"
                    viewBox="0 0 16 16"
                  >
                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                    <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                  </svg>{" "}
                  Create Route
                </NavLink>
              </div> */}

              <div
                className="btn-group mt-2 mt-lg-0 mt-md-0 ml-0 ml-lg-3 ml-md-3"
                role="group"
              >
                <div
                  className="btn-group me-2"
                  role="group"
                  aria-label="First group"
                >
                  <button
                    type="button"
                    className="btn btn-success"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Refresh"
                    onClick={refresh}
                  >
                    <i className="fas fa-sync"></i>
                  </button>
                  <button
                    type="button"
                    className="btn btn-dark"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Filters"
                    onclick="showHideDiv('divFilter')"
                  >
                    <i className="fa fa-filter"></i>
                  </button>
                  <button
                    type="button"
                    className="btn btn-info"
                    style={{ backgroundColor: "#14737D" }}
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Upload File"
                    onClick={() => setShowUploadFileModal(true)}
                  >
                    <i
                      className="fas fa-upload"
                      type="button"
                      data-toggle="modal"
                      data-target="#uploadDoc"
                    ></i>{" "}
                  </button>
                  <button
                    type="button"
                    className="btn btn-info"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Export Excel File"
                  >
                    <i className="fas fa-file-excel"></i>{" "}
                  </button>
                  <Link
                    to="/routes/add"
                    type="button"
                    className="btn btn-primary"
                  >
                    Create{" "}
                    <i className="fas fa-plus-circle d-none d-sm-inline-block"></i>
                  </Link>
                </div>
              </div>
            </div>
            {auth?.user?.permissionId?.routes?.read === true ? (
              <>
                {/* <div className="row p-3">
                  <div className="col-lg-3">
                      <div className="form-group my-3">
                          <input type="text" placeholder="Search By Route Number" className="form-control height-35 f-14"
                          value={routeNo} onChange={handleChange}
                          />
                      </div>
                  </div>
                </div> */}
                <RoutesTable routeNo={routeNo} />
              </>
            ) : (
              <NotAllowed />
            )}
          </div>
        </section>
      </div>

      {showUploadFileModal && (
        <AdminRouteUploadFileModal
          showDropDown={showUploadFileModal}
          setShowUploadFileModal={setShowUploadFileModal}
          toggleUploadFileModal={toggleUploadFileModal}
        />
      )}
    </>
  );
};
export default AdminBookingTodayPage;
