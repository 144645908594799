/** @format */

import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";
import {
  markedLocationRoute,
  updateRouteTrip,
} from "../../../actions/routeActions";
import { addRouteTrip } from "../../../actions/routeTripActions";

const DriverRouteTodayRouteComponentRow = ({
  current,
  i,
  dropDownRow,
  setDropDownRow,
}) => {
  const auth = useSelector((state) => state.auth);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const onClickNavigation = () => {
    dispatch(markedLocationRoute(current));
  };
  const [values, setValues] = useState({
    companyId: "",
    routeId: "",
    tripStartTime: "",
  });
  useEffect(() => {
    setValues({
      ...values,
      routeId: current.route?.id,
    });
    setJsonData(current?.route?.ridePattern);
    // console.log("chal raha hn mein")
  }, [current]);
  const routeTripData = {
    companyId: auth.user.companyId.id,
    driverId: auth.user.id,
    routeId: values.routeId,
    tripStartTime: moment().format().toString().substring(0, 19) + ".000Z",
  };

  const handleChange = (fieldName) => (event) => {
    setValues({
      ...values,
      [fieldName]: event.target.value,
    });
  };

  const handleSubmit = async (data) => {
    // event.preventDefault()
    // console.log(data, "datadatadatadatadata")
    dispatch(addRouteTrip(routeTripData));
    setTimeout(() => navigate(`/today-route/start-ride/${data}`), 1500);
    // setValues({
    //     assetNo: '',
    //     serviceType: '',
    //     vehicleOwnership: '',
    // })
  };

  const handleArrived = async (event) => {
    event.preventDefault();
    dispatch(updateRouteTrip(arrivedData));
  };

  const [jsonData, setJsonData] = useState(current?.route?.ridePattern);

  const handleTaskDone = (index) => {
    const updatedJsonData = [...jsonData];
    updatedJsonData[index].taskDone = true;
    setJsonData(updatedJsonData);
  };

  const handlePickUp = async (event) => {
    dispatch(
      updateRouteTrip({
        _id: current.trip?.id,
        customerId: currentTask?.customerId?.id,
        pickUpTime: moment().format().toString().substring(0, 19) + ".000Z",
        // tripStatus: "Ongoing",
        pickUpLat: 24.9261844,
        pickUpLng: 67.1356306,
      })
    );
  };

  const handleEndRide = async (event) => {
    if (currentTask2.length > 1)
      dispatch(
        updateRouteTrip({
          _id: current.trip?.id,
          customerId: currentTask?.customerId?.id,
          dropOffTime: moment().format().toString().substring(0, 19) + ".000Z",
          // tripStatus: "Completed",
          dropOffLat: 24.9261844,
          dropOffLng: 67.1356306,
        })
      );
    else {
      dispatch(
        updateRouteTrip({
          _id: current.trip?.id,
          customerId: currentTask?.customerId?.id,
          dropOffTime: moment().format().toString().substring(0, 19) + ".000Z",
          // tripStatus: "Completed",
          dropOffLat: 24.9261844,
          dropOffLng: 67.1356306,
          isCompleted: true,
        })
      );
    }
  };

  const handleNoShow = async (event) => {
    if (currentTask2.length > 2)
      dispatch(
        updateRouteTrip({
          _id: current.trip?.id,
          customerCancelReason: "No Show",
          customerId: currentTask?.customerId?.id,
          pickUpTime: moment().format().toString().substring(0, 19) + ".000Z",
          // tripStatus: "Completed",
          pickUpLat: 24.9261844,
          pickUpLng: 67.1356306,
        })
      );
    else {
      dispatch(
        updateRouteTrip({
          _id: current.trip?.id,
          customerCancelReason: "No Show",
          customerId: currentTask?.customerId?.id,
          pickUpTime: moment().format().toString().substring(0, 19) + ".000Z",
          // tripStatus: "Completed",
          pickUpLat: 24.9261844,
          pickUpLng: 67.1356306,
          isCompleted: true,
        })
      );
    }
  };

  // const reallocateJson = () => {
  //   setJsonData(current?.route?.ridePattern)
  // }

  const currentTask = jsonData.find((item) => !item.taskDone);
  const currentTask2 = jsonData.filter((item) => !item.taskDone);
  // console.log(currentTask2.length,"currentTask2")
  const arrivedData = {
    _id: current.trip?.id,
    customerId: currentTask?.customerId?.id,
    arrivedTime: moment().format().toString().substring(0, 19) + ".000Z",
  };
  // const updateJSON = (index) => {
  //   if (index !== (jsonData.length - 1)){
  //     let newArr = [...jsonData];
  //     newArr[index].taskDone = true
  //     setJsonData(newArr)
  //   }
  //   else {
  //     let newArr = [...jsonData];
  //     for (let ind = 0; i < jsonData.length; i++){
  //       newArr[ind].taskDone =false
  //     }
  //     setJsonData(newArr)
  //   }
  // }
  return (
    <>
      <tr role="row" key={i} className="odd border">
        <td className="border-right">
          <b>
            {current?.route?.routeNumber}{" "}
            {current?.route?.routeNumberReturn && "( Return )"}
          </b>
          <i data-toggle="tooltip" className=""></i>
        </td>
        <td className="border-right">
          {current?.route?.customerDetails?.length}
        </td>
        <td className="border-right">
          {/* <button
            className="btn btn-sm btn-primary fa fa-eye"
            onClick={() => navigate(`/today-route/details/${current?.route?.id}`)}
          >
          </button>  */}
          <button
            className="btn btn-sm btn-primary"
            onClick={() =>
              navigate(`/today-route/details/${current?.route?.id}`)
            }
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-eye"
              viewBox="0 0 16 16"
            >
              <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z" />
              <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z" />
            </svg>{" "}
          </button>
        </td>
        <td className="border-right">
          <NavLink
            to={"/navigate-route"}
            onClick={onClickNavigation}
            type="button"
            className="btn btn-info"
            style={{ cursor: "default" }}
          >
            Navigate
          </NavLink>
          <i data-toggle="tooltip" className=""></i>
        </td>
        <td className="border-right">
          {auth?.user?.permissionId?.myRoutes?.create === true &&
          current.trip?.isCompleted === true ? (
            <button
              type="button"
              className="btn btn-success"
              style={{ cursor: "default" }}
              onClick={() =>
                navigate(`/today-route/start-ride/${current?.route?.id}`)
              }
            >
              Completed
            </button>
          ) : current.trip === null ? (
            <button
              type="button"
              className="btn btn-primary"
              // onClick={handleSubmit}
              onClick={() => handleSubmit(current?.route?.id)}
            >
              Start Ride
            </button>
          ) : current.trip ? (
            <button
              type="button"
              className="btn btn-success"
              style={{ cursor: "default" }}
              onClick={() =>
                navigate(`/today-route/start-ride/${current?.route?.id}`)
              }
            >
              In Progress
            </button>
          ) : (
            <></>
          )}
        </td>
        {/* {
          current.route?.status === "Scheduled" ?
          <td className="border-right" >
            <button type="button" className="btn btn-primary" onClick={handleSubmit}>Start Ride</button>
          </td>
          :
          current.route.ridePattern &&
          current.route.ridePattern.length > 0 ?
          current.route.ridePattern.map((current, i) => {
            return (
              current.taskDone === false &&
              <td className="border-right" key={i} >
                <button type="button" className="btn btn-primary" >{current.routeType + " " + current.customerId.firstName}</button>
              </td>
            )
          })
          :
          <></>
        } */}
      </tr>
    </>
  );
};

export default DriverRouteTodayRouteComponentRow;

// {
//   (current.route?.status === "Completed" || current.trip?.tripStatus === "Completed") ?
//   <td className="border-right text-center" >
//     <button type="button" className="btn btn-success" style={{cursor:"default"}} >Completed</button>
//   </td>
// // }
// // {
//   // current.route?.status !== "Completed" &&
// :
//   current.route?.status === "Cancelled" ?
//   <td className="border-right text-center" >
//     <button type="button" className="btn btn-info" style={{cursor:"default"}} >Cancelled</button>
//   </td>
// :
//   current &&
//   current.trip === null ?
//   <td className="border-right text-center" >
//     <button type="button" className="btn btn-primary" onClick={handleSubmit}>Start Ride</button>
//   </td>
//   :
//   current.trip?.tripStatus === "Towards Customer" ?
//   <td className="border-right text-center" >
//     <button type="button" className="btn btn-dark" onClick={handleArrived}>Arrived</button>
//   </td>
//   :
//   current.trip?.tripStatus === "Arrived" ?
//   <>
//     <td className="border-right text-center" >
//       <button type="button" className="btn btn-secondary" onClick={handlePickUp}>Pick Customer</button>
//     </td>
//     {/* <td className="border-right text-center" >
//       <button type="button" className="btn btn-danger" onClick={handleSubmit}>Cancel</button>
//     </td> */}
//   </>
//   :
//   current.trip?.tripStatus === "Ongoing" ?
//   <td className="border-right text-center" >
//     <button type="button" className="btn btn-warning" onClick={handleEndRide}>Ride End</button>
//   </td>
//   :
//   <></>
// }

// start ride ke neeche ki condition
// ((currentTask) &&
// (currentTask.isArrived === false) &&
// (currentTask.routeType === 'Pick') )
//  ? (
//   <>
//     <button type="button" className="btn btn-primary"
//     onClick={handleArrived}
//     // onClick={() => handleTaskDone(jsonData.indexOf(currentTask))}
//     >
//       {/* {currentTask.routeType+ " " + currentTask.customerId.firstName +" " + currentTask.customerId.lastName} */}
//       {"Arrived " + currentTask.customerId.firstName +" " + currentTask.customerId.lastName}
//     </button>
//     {/* {currentTask.routeType === 'Pick' && (
//       <button type="button" className="btn btn-primary"
//       // onClick={() => handleTaskDone(jsonData.indexOf(currentTask))}
//       >
//         {currentTask.routeType+ " " + currentTask.customerId.firstName +" " + currentTask.customerId.lastName}
//       </button>
//     )} */}
//   </>
// )
// :
// ((currentTask) &&
// (currentTask.isArrived === true) &&
// (currentTask.routeType === 'Pick') )
//  ? (
//   <>
//     <button type="button" className="btn btn-primary"
//     onClick={handlePickUp}
//     >
//       {"Picking " + currentTask.customerId.firstName +" " + currentTask.customerId.lastName}
//     </button>
//     &nbsp;
//     <button type="button" className="btn btn-danger"
//     onClick={handleNoShow}
//     >
//       {"No Show"}
//     </button>
//   </>
// )
// :
// ((currentTask) &&
// (currentTask.routeType === 'Drop') )
//  ? (
//   <>
//     <button type="button" className="btn btn-primary"
//     onClick={handleEndRide}
//     >
//       {"Dropping " + currentTask.customerId.firstName +" " + currentTask.customerId.lastName}
//     </button>
//   </>
// )
