import React from "react";
import ExcelImport from "../../../ExcelImport";
import AdminSidebar from "../../../components/adminComponents/adminHeaderSidebarTitleComponents/AdminSidebar";
import AdminHeader from "../../../components/adminComponents/adminHeaderSidebarTitleComponents/AdminHeader";
import AdminRouteDistanceReport from "../../../components/adminComponents/adminReportComponents/AdminRouteDistanceReport";
import AdminSummaryReportTable from "../../../components/adminComponents/adminReportComponents/AdminSummaryReportTable";
import ExcelExport from "../../../ExcelExport";
import { useSelector } from "react-redux";

const AdminRouteSummaryReportPage = () => {
  const kpi = useSelector((state) => state.kpi);
  // console.log(kpi);
  // const exportData = kpi?.routeSummaryReport?.map((item) => ({
  //     "Route #": item?.routeNo,
  //     "Shift Time": item?.shiftTime,
  //     From: item?.route?.length > 0 ? item?.route[0].location : "",
  //     To:
  //       item?.route?.length > 0
  //         ? item?.route[item?.route?.length - 1].location
  //         : "",
  //     "# of Stops": item?.route?.length > 0 ? item?.route?.length : "",
  //     Distance: item?.distance,
  //     ETA: item?.eta,
  //   }));

  const exportData = kpi?.routeSummaryReport?.flatMap((item) => {
    if (item?.route?.length > 1) {
      return item.route
        .map((location, index) => {
          if (index < item.route.length - 1) {
            return {
              "Route #": item.routeNo,
              "Shift Time": item.shiftTime,
              From: item.route[index].location,
              To: item.route[index + 1].location,
              "# of Stops": index + 1,
              Distance: parseFloat(item.route[index].distance),
              "Total Distance": parseFloat(item.distance),
              ETA: item.eta,
            };
          }
          return null; // Exclude the last item since it has no "To" address
        })
        .filter((row) => row !== null);
    } else {
      // Handle the case where there is only one or no stops
      return [
        {
          "Route #": item.routeNo,
          "Shift Time": item.shiftTime,
          From: item.route?.length > 0 ? item.route[0].location : "",
          To: "",
          "# of Stops": 1,
          Distance: parseFloat(item.distance),
          "Total Distance": parseFloat(item.distance),
          ETA: item.eta,
        },
      ];
    }
  });

  return (
    <>
      <AdminSidebar />
      <AdminHeader />
      <div className="body-wrapper clearfix">
        <section className="main-container " id="fullscreen">
          <div className="px-4 py-2 border-top-0 emp-dashboard">
            <div className="settings-box rounded">
              <div className="s-b-inner s-b-notifications bg-white b-shadow-4 rounded">
                <div className="s-b-n-header d-lg-flex justify-content-between card-header border-bottom-grey">
                  <div className="s-b-n-header" id="tabs">
                    <h2 className="mb-0 p-20 f-21 font-weight-normal text-capitalize">
                      <span>ROUTE SUMMARY REPORTS</span>
                    </h2>
                  </div>
                  <ExcelExport
                    excelData={exportData}
                    fileName="Route Summary Report"
                    styling
                  />
                </div>
                <AdminSummaryReportTable />
              </div>
            </div>
          </div>
        </section>
      </div>
      {/* </div> */}
    </>
  );
};

export default AdminRouteSummaryReportPage;
