import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { routeSummaryReport } from '../../../actions/kpiActions';

const AdminSummaryReportTable = () => {
    const auth = useSelector((state) => state.auth);
    const reservation = useSelector((state) => state.reservation);
    const kpi = useSelector((state) => state.kpi);
    const { loading } = kpi;

    const dispatch = useDispatch();

    const routeSummaryReportData = async (e) => {
        dispatch(
            routeSummaryReport({ companyId: auth?.user?.companyId?.id })
        );
      };
    
      useEffect(() => {
        routeSummaryReportData();
      }, []);
    
  return (
    <>
    <br />
    <div className="col-sm-12">
            <table
              className="table border w-100 dataTable no-footer"
              id="leads-table"
              role="grid"
              aria-describedby="leads-table_info"
              style={{ overflowX: "auto" }}
            >
              <thead className="card-header">
                <tr role="row">
                  <th
                    title="Route No"
                    className="sorting border-right"
                    tabIndex="0"
                    aria-controls="leads-table"
                    rowspan="1"
                    colspan="1"
                    aria-label="Mobile: activate to sort column ascending"
                  >
                    Route No
                  </th>
                  <th
                    title="Shift Time"
                    className="sorting border-right"
                    tabIndex="0"
                    aria-controls="leads-table"
                    rowspan="1"
                    colspan="1"
                    aria-label="Mobile: activate to sort column ascending"
                  >
                    Shift Time
                  </th>
                  <th
                    title="Address Path"
                    className="sorting_disabled border-right"
                    rowspan="1"
                    colspan="1"
                    aria-label="Next Follow Up"
                  >
                    Address Path
                  </th>
                  <th
                    title="Total Distance"
                    className="sorting border-right"
                    tabIndex="0"
                    aria-controls="leads-table"
                    rowspan="1"
                    colspan="1"
                    aria-label="Lead Agent: activate to sort column ascending"
                  >
                    Total Distance
                  </th>
                  
                </tr>
              </thead>
              <tbody>
                {kpi?.routeSummaryReport?.map((row, i) => (
                  <tr key={i} className="odd border">
                    <td className="border-right">{row.routeNo}</td>
                    <td className="border-right">{row.shiftTime}</td>
                    {/* <td className="border-right">{row.location[array]}</td> */}
                    <td className="border-right">
                        {row?.route?.map((x, index) => (
                            <span key={index}>
                            {x.location}
                            {x.distance && (
                                <>
                                {" - " + x.distance + " "}
                                <span style={{ display: 'inline-block', transform: 'rotate(0deg)' }}>
                                    &rarr;
                                </span>
                                </>
                            )}
                            <br />
                            </span>
                        ))}
                    </td>
                    <td className="border-right">{row?.distance}<br/>{row?.eta}</td>
                  </tr>
                ))}
              </tbody>
            </table>
            {/* {kpi?.getRouteDistanceReport?.length === 0 && (
              <div className="dash-stats-list" style={{ textAlign: "center" }}>
                <br />
                <br />
                <img
                  alt=""
                //   src={shift1}
                  style={{ width: "145px", opacity: "0.5" }}
                />
                <br />
                <p style={{ opacity: "0.5" }}>Generate Report</p>
              </div>
            )} */}
            <div
              id="leads-table_processing"
              className="dataTables_processing card"
              style={{ display: "none" }}
            >
              Processing...
            </div>
          </div>
    </>
  )
}

export default AdminSummaryReportTable
