/** @format */

import moment from "moment";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  getRouteandTripByIdV2,
  updateRouteTrip,
} from "../../../actions/routeActions";

const DriverRideComponent = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const routes = useSelector((state) => state.routes);
  const service = useSelector((state) => state.service);
  const auth = useSelector((x) => x.auth);
  useEffect(() => {
    dispatch(
      getRouteandTripByIdV2({
        _id: params.id,
        today: moment().format("YYYY-MM-DD"),
      })
    );
  }, []);
  // console.log(data, "data data data")
  // const handleArrived = async (event) => {
  //     event.preventDefault()
  //     dispatch(updateRouteTrip(arrivedData))
  // }
  // const handlePickUp = async (event) => {
  //     dispatch(updateRouteTrip({_id: current.trip?.id,
  //       customerId: currentTask?.customerId?.id,
  //       pickUpTime: moment().format().toString().substring(0,19)+".000Z",
  //       // tripStatus: "Ongoing",
  //       pickUpLat: 24.9261844,
  //       pickUpLng: 67.1356306,
  //     }))
  //   }

  //   const handleEndRide = async (event) => {
  //     if (currentTask2.length > 1)
  //     dispatch(updateRouteTrip({_id: current.trip?.id,
  //       customerId: currentTask?.customerId?.id,
  //       dropOffTime: moment().format().toString().substring(0,19)+".000Z",
  //       // tripStatus: "Completed",
  //       dropOffLat: 24.9261844,
  //       dropOffLng: 67.1356306
  //     }))
  //     else {
  //       dispatch(updateRouteTrip({_id: current.trip?.id,
  //         customerId: currentTask?.customerId?.id,
  //         dropOffTime: moment().format().toString().substring(0,19)+".000Z",
  //         // tripStatus: "Completed",
  //         dropOffLat: 24.9261844,
  //         dropOffLng: 67.1356306,
  //         isCompleted: true
  //       }))
  //     }
  //   }

  //   const handleNoShow = async (event) => {
  //     if (currentTask2.length > 1)
  //     dispatch(updateRouteTrip({_id: current.trip?.id,
  //       customerCancelReason: "No Show",
  //       customerId: currentTask?.customerId?.id,
  //       pickUpTime: moment().format().toString().substring(0,19)+".000Z",
  //       // tripStatus: "Completed",
  //       pickUpLat: 24.9261844,
  //       pickUpLng: 67.1356306,
  //     }))
  //     else {
  //       dispatch(updateRouteTrip({_id: current.trip?.id,
  //         customerCancelReason: "No Show",
  //         customerId: currentTask?.customerId?.id,
  //         pickUpTime: moment().format().toString().substring(0,19)+".000Z",
  //         // tripStatus: "Completed",
  //         pickUpLat: 24.9261844,
  //         pickUpLng: 67.1356306,
  //         isCompleted: true
  //       }))
  //     }
  //   }
  return (
    <>
      {routes?.routeDetails?.route && (
        <div className="row">
          <div className="col-lg-8 col-xl-9">
            <h3 className="heading-h1 mb-0">Ride Actions</h3>
            <br />
            <table
              className="table border w-100 dataTable no-footer"
              id="leads-table"
              role="grid"
              aria-describedby="leads-table_info"
              style={{ overflowX: "auto" }}
            >
              <thead className="card-header">
                <tr role="row">
                  <th
                    title="Passenger Name"
                    className="sorting border-right"
                    tabIndex="0"
                    aria-controls="leads-table"
                    rowSpan="1"
                    colSpan="1"
                    aria-label="Mobile: activate to sort column ascending"
                  >
                    Passenger Name
                  </th>
                  <th
                    title="Type"
                    className="sorting_disabled border-right"
                    rowSpan="1"
                    colSpan="1"
                    aria-label="Next Follow Up"
                  >
                    Type
                  </th>
                  <th
                    title="Pickup Address"
                    className="sorting border-right"
                    tabIndex="0"
                    aria-controls="leads-table"
                    rowSpan="1"
                    colSpan="1"
                    aria-label="Lead Agent: activate to sort column ascending"
                  >
                    Pickup Address
                  </th>
                  <th
                    title="Dropoff Address"
                    className="sorting border-right"
                    tabIndex="0"
                    aria-controls="leads-table"
                    rowSpan="1"
                    colSpan="1"
                    aria-label="Lead Agent: activate to sort column ascending"
                  >
                    Dropoff Address
                  </th>
                  <th
                    title="Notes"
                    className="sorting border-right"
                    tabIndex="0"
                    aria-controls="leads-table"
                    rowSpan="1"
                    colSpan="1"
                    aria-label="Lead Agent: activate to sort column ascending"
                  >
                    Notes
                  </th>
                  <th
                    title="Actions"
                    className="text-right pr-20 sorting_disabled border-right"
                    rowSpan="1"
                    colSpan="1"
                    aria-label="Action"
                  >
                    Actions
                  </th>
                </tr>
              </thead>
              {/* {
                            routes?.routeDetails?.route?.customerDetails &&
                            routes?.routeDetails?.route?.customerDetails.length > 0 &&
                            routes?.routeDetails?.route?.customerDetails.map((current,i) => {
                                return(
                                    <>
                                        <div className="card" style={{ height: "100px"}}>
                                            <div className="card-body">
                                                <h6 className="card-title m-b-15">{Passenger details}</h6>
                                                <h6 className="card-title m-b-15">Passenger details</h6>
                                            </div>
                                        </div>
                                        <br />
                                    </>
                                )
                            })} */}
              <tbody>
                {routes?.routeDetails?.route?.customerDetails?.map(
                  (current, i) => {
                    const currentTask =
                      routes?.routeDetails?.route?.ridePattern.find(
                        (item) =>
                          item.taskDone === false &&
                          item.customerId.id == current?.customerId?.id
                      );
                    const currentTask2 =
                      routes?.routeDetails?.route?.ridePattern.filter(
                        (item) => item.taskDone === false
                      );
                    // console.log(currentTask,currentTask2.length, "currentTask2.length")
                    return (
                      <tr role="row" className="odd border" key={i}>
                        <td className="border-right">
                          <div>
                            <p className="m-0">
                              {current?.customerId?.firstName}{" "}
                              {current?.customerId?.lastName}
                            </p>
                          </div>
                        </td>
                        <td className="border-right">
                          {service?.service &&
                            service?.service.length > 0 &&
                            service?.service.find((x) => x.id == current.type)
                              ?.name}
                        </td>
                        <td className="border-right">
                          <div>
                            <p className="m-0">
                              {current.pickUpAddress?.address}
                            </p>
                            <p className="m-0">
                              {moment(current.pickUpDate).format(
                                "DD MMMM, YYYY"
                              )}{" "}
                              @{" "}
                              {moment(current.pickUpTime, "hh:mm").format(
                                " hh:mm a"
                              )}
                            </p>
                          </div>
                        </td>
                        <td className="border-right">
                          <div>
                            <p className="m-0">
                              {current.dropOffAddress?.address}
                            </p>
                            <p className="m-0">
                              {moment(current.dropOffDate).format(
                                "DD MMMM, YYYY"
                              )}{" "}
                              @{" "}
                              {moment(current.dropOffTime, "hh:mm").format(
                                "hh:mm a"
                              )}
                            </p>
                          </div>
                        </td>
                        <td className="border-right">
                          {current.dispatchNotes}
                        </td>
                        <td className="border-right">
                          {(auth?.user?.permissionId?.myRoutes?.create ===
                            true ||
                            auth?.user?.permissionId?.myRoutes?.update ===
                              true) &&
                          routes?.routeDetails?.trip?.isCompleted === true ? (
                            <button
                              type="button"
                              className="btn btn-success"
                              style={{ cursor: "default" }}
                            >
                              Completed
                            </button>
                          ) : currentTask &&
                            currentTask.isArrived === false &&
                            currentTask.routeType === "Pick" ? (
                            <>
                              <button
                                type="button"
                                className="btn btn-primary"
                                onClick={() =>
                                  dispatch(
                                    updateRouteTrip({
                                      _id: routes?.routeDetails?.trip?.id,
                                      customerId: current?.customerId?.id,
                                      arrivedTime:
                                        moment()
                                          .format()
                                          .toString()
                                          .substring(0, 19) + ".000Z",
                                    })
                                  )
                                }
                              >
                                {/* {"Arrived " + currentTask.customerId.firstName +" " + currentTask.customerId.lastName} */}
                                {"Arrived "}
                              </button>
                            </>
                          ) : currentTask &&
                            currentTask.isArrived === true &&
                            currentTask.routeType === "Pick" ? (
                            <>
                              <button
                                type="button"
                                className="btn btn-primary"
                                onClick={() =>
                                  dispatch(
                                    updateRouteTrip({
                                      _id: routes?.routeDetails?.trip?.id,
                                      customerId: current?.customerId?.id,
                                      pickUpTime:
                                        moment()
                                          .format()
                                          .toString()
                                          .substring(0, 19) + ".000Z",
                                      // tripStatus: "Ongoing",
                                      pickUpLat: 24.9261844,
                                      pickUpLng: 67.1356306,
                                    })
                                  )
                                }
                              >
                                {/* {"Picking " + currentTask.customerId.firstName +" " + currentTask.customerId.lastName} */}
                                {"Picking "}
                              </button>
                              &nbsp;
                              <button
                                type="button"
                                className="btn btn-danger"
                                onClick={() =>
                                  currentTask2.length > 2
                                    ? dispatch(
                                        updateRouteTrip({
                                          _id: routes?.routeDetails?.trip?.id,
                                          customerId: current?.customerId?.id,
                                          customerCancelReason: "No Show",
                                          pickUpTime:
                                            moment()
                                              .format()
                                              .toString()
                                              .substring(0, 19) + ".000Z",
                                          // tripStatus: "Completed",
                                          pickUpLat: 24.9261844,
                                          pickUpLng: 67.1356306,
                                          dropOffTime:
                                            moment()
                                              .format()
                                              .toString()
                                              .substring(0, 19) + ".000Z",
                                          // tripStatus: "Completed",
                                          dropOffLat: 24.9261844,
                                          dropOffLng: 67.1356306,
                                        })
                                      )
                                    : dispatch(
                                        updateRouteTrip({
                                          _id: routes?.routeDetails?.trip?.id,
                                          customerId: current?.customerId?.id,
                                          customerCancelReason: "No Show",
                                          pickUpTime:
                                            moment()
                                              .format()
                                              .toString()
                                              .substring(0, 19) + ".000Z",
                                          // tripStatus: "Completed",
                                          pickUpLat: 24.9261844,
                                          pickUpLng: 67.1356306,
                                          dropOffTime:
                                            moment()
                                              .format()
                                              .toString()
                                              .substring(0, 19) + ".000Z",
                                          // tripStatus: "Completed",
                                          dropOffLat: 24.9261844,
                                          dropOffLng: 67.1356306,
                                          isCompleted: true,
                                        })
                                      )
                                }
                              >
                                {"No Show"}
                              </button>
                            </>
                          ) : currentTask &&
                            currentTask.routeType === "Drop" ? (
                            <>
                              <button
                                type="button"
                                className="btn btn-primary"
                                onClick={() =>
                                  currentTask2.length > 1
                                    ? dispatch(
                                        updateRouteTrip({
                                          _id: routes?.routeDetails?.trip?.id,
                                          customerId: current?.customerId?.id,
                                          dropOffTime:
                                            moment()
                                              .format()
                                              .toString()
                                              .substring(0, 19) + ".000Z",
                                          // tripStatus: "Completed",
                                          dropOffLat: 24.9261844,
                                          dropOffLng: 67.1356306,
                                        })
                                      )
                                    : dispatch(
                                        updateRouteTrip({
                                          _id: routes?.routeDetails?.trip?.id,
                                          customerId: current?.customerId?.id,
                                          dropOffTime:
                                            moment()
                                              .format()
                                              .toString()
                                              .substring(0, 19) + ".000Z",
                                          // tripStatus: "Completed",
                                          dropOffLat: 24.9261844,
                                          dropOffLng: 67.1356306,
                                          isCompleted: true,
                                        })
                                      )
                                }
                              >
                                {"Dropping "}
                              </button>
                            </>
                          ) : !currentTask ? (
                            <button
                              type="button"
                              className="btn btn-success"
                              style={{ cursor: "default" }}
                            >
                              Completed
                            </button>
                          ) : (
                            <></>
                          )}
                        </td>
                      </tr>
                    );
                  }
                )}
              </tbody>
            </table>
          </div>
          <div className="col-lg-4 col-xl-3">
            <h3 className="heading-h1 mb-0">Ride Pattern</h3>
            <br />
            <table
              className="table border w-100 dataTable no-footer"
              id="leads-table"
              role="grid"
              aria-describedby="leads-table_info"
              style={{ overflowX: "auto" }}
            >
              <thead className="card-header">
                <tr role="row">
                  <th
                    title="S.no"
                    className="sorting border-right"
                    tabIndex="0"
                    aria-controls="leads-table"
                    rowSpan="1"
                    colSpan="1"
                    aria-label="Mobile: activate to sort column ascending"
                  >
                    S.no
                  </th>{" "}
                  <th
                    title="Action"
                    className="sorting border-right"
                    tabIndex="0"
                    aria-controls="leads-table"
                    rowSpan="1"
                    colSpan="1"
                    aria-label="Mobile: activate to sort column ascending"
                  >
                    Action
                  </th>
                  <th
                    title="Passenger Name"
                    className="sorting border-right"
                    tabIndex="0"
                    aria-controls="leads-table"
                    rowSpan="1"
                    colSpan="1"
                    aria-label="Mobile: activate to sort column ascending"
                  >
                    Passenger Name
                  </th>
                </tr>
              </thead>
              <tbody>
                {routes?.routeDetails?.route?.ridePattern?.map((current, i) => {
                  return (
                    <tr role="row" className="odd border" key={i}>
                      <td className="border-right">{i + 1}</td>
                      <td className="border-right">{current?.routeType}</td>
                      <td className="border-right">
                        <div>
                          <p className="m-0">
                            {current.customerId?.firstName}{" "}
                            {current.customerId?.lastName}
                          </p>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <br />
            <h3 className="heading-h1 mb-0">Ride Status</h3>
            <br />
            {routes?.routeDetails?.trip?.isCompleted === true ? (
              <button
                type="button"
                className="btn btn-success"
                style={{ cursor: "default", width: "100%" }}
              >
                Completed
              </button>
            ) : routes?.routeDetails?.trip !== null ? (
              <button
                type="button"
                className="btn btn-info"
                style={{ cursor: "default", width: "100%" }}
              >
                In Progress
              </button>
            ) : routes?.routeDetails?.trip === null ? (
              <button
                type="button"
                className="btn btn-primary"
                style={{ cursor: "default", width: "100%" }}
              >
                Scheduled
              </button>
            ) : (
              <></>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default DriverRideComponent;
