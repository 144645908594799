import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fromTo,
  getReservationsByDate,
} from "../../../actions/reservationAction";
import { tabOrdersSearch } from "../../../actions/tabActions";

const AdminSearchByFilterComponent = () => {
  const auth = useSelector((state) => state.auth);
  const reservation = useSelector((state) => state.reservation);
  const dispatch = useDispatch();
  const [values, setValues] = useState({
    companyId: "",
    driverBookingStatus: "",
    from: "",
    to: "",
  });
  const handleChange = (fieldName) => (event) => {
    setValues({
      ...values,
      [fieldName]: event.target.value,
    });
  };

  let orderSearch;
  // if (values.location !== ''){
  orderSearch = {
    companyId: auth.user.companyId.id,
    driverBookingStatus: values.driverBookingStatus,
    from: values.from,
    to: values.to,
  };
  const fromToData = {
    driverBookingStatus: values.driverBookingStatus,
    from: values.from,
    to: values.to,
  };

  const [ordersTypeObj, setOrdersTypeObj] = useState([]);
  const handleClick = async (event) => {
    event.preventDefault();
    for (let key of Object.keys(orderSearch)) {
      if (orderSearch[key] === "") {
        delete orderSearch[key];
      }
    }
    if (orderSearch.driverBookingStatus === "") {
      delete orderSearch.driverBookingStatus;
      delete fromToData.driverBookingStatus;
    }

    dispatch(tabOrdersSearch());
    dispatch(getReservationsByDate(orderSearch));
    dispatch(fromTo(fromToData));
  };
  return (
    <>
      <div className="row p-3">
        <div className="col-lg-3">{/* for space */}</div>
        <div className="col-lg-2">
          <div className="form-group my-3">
            <div className="d-flex">
              <div>
                <label
                  className="f-14 text-dark mb-12"
                  data-label=""
                  htmlFor="clnumber"
                >
                  Status
                </label>
              </div>
            </div>
            <div className="select-picker">
              <select
                className="form-control select-picker"
                style={{ height: "39px", fontSize: "14px" }}
                value={values.type}
                onChange={handleChange("driverBookingStatus")}
                // required
              >
                <option value="" key={"st"}>
                  {" "}
                  Select
                </option>
                {["Unassigned", "Pending", "Assigned"].map((current, i) => {
                  return (
                    <option value={current} key={i}>
                      {current}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
        </div>
        {/* </div> */}
        <div className="col-lg-2">
          <div className="form-group my-3">
            <div className="d-flex">
              <div>
                <label
                  className="f-14 text-dark mb-12"
                  data-label=""
                  htmlFor="clnumber"
                >
                  From
                </label>
              </div>
            </div>
            <input
              type="date"
              className="form-control height-35 f-14"
              value={values.from}
              onChange={handleChange("from")}
              autoComplete="off"
            />
          </div>
        </div>
        <div className="col-lg-2">
          <div className="form-group my-3">
            <div className="d-flex">
              <div>
                <label
                  className="f-14 text-dark mb-12"
                  data-label=""
                  htmlFor="clnumber"
                >
                  To
                </label>
              </div>
            </div>
            <input
              type="date"
              className="form-control height-35 f-14"
              placeholder=""
              id="driver"
              min="0"
              value={values.to}
              onChange={handleChange("to")}
              autoComplete="off"
            />
          </div>
        </div>
        <div className="col-lg-2">
          <div className="form-group my-2">
            <br />
            <br />
            <button className="btn btn-success btn-block" onClick={handleClick}>
              {" "}
              Search{" "}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminSearchByFilterComponent;
