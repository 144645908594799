/** @format */

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { deleteReservationCompletely } from "../../../actions/reservationAction";
import axios from "axios";
import { SERVERADDRESS } from "../../../constants";
import { notifySuccess } from "../../toast";

const AdminCompleteRideDeletion = ({
  showDeleteDropDown,
  openDeleteDropDown,
  current,
  index,
  assigned,
  fullData,
  setFullData,
}) => {
  const auth = useSelector((state) => state.auth);
  const reservation = useSelector((state) => state.reservation);
  // const {loading} = reservation
  const [loading, setLoading]= useState(false)
  const [values, setValues] = useState({
    _id: "",
    delete: "",
  });
  useEffect(() => {
    setValues({ ...values, _id: current?.id });
  }, [current]);
  const dataLoad = {
    _id: current._id ? current._id : current.id,
    completelyDelete: true,
    dispatcherId: auth.user.id,
  };

  const dispatch = useDispatch();
const loadingTrue = async () => {
  setLoading(true)
}
  const handleSubmit = async (event) => {
    event.preventDefault();
    await loadingTrue()
    const { data } = await axios.put(
      `${SERVERADDRESS}/v1/reservation/updateReservation`,
      dataLoad
    );
  setLoading(false)
    notifySuccess("Reservation Deleted");
    successCB();
    // dispatch(deleteReservationCompletely(dataLoad, successCB));
    //   setTimeout(
    //     () =>
    //     {
    //         if (assigned === "unassigned"){
    //         }
    //         else if (assigned === "assigned"){
    //         }
    //         else if (assigned === "pending"){
    //         }
    // openDeleteDropDown();
    //     },
    //     2000
    // );
  };
  const successCB = (updatedData) => {
    setFullData((prevData) =>
      prevData.filter((x) => {
        return x._id !== dataLoad._id || (x.id && x.id !== dataLoad._id);
      })
    );
    // setFullData((prevData) => {
    //   const updatedIndex = prevData.findIndex((item) => {
    //     return (
    //       item._id === dataLoad._id || (item.id && item.id === dataLoad._id)
    //     );
    //   });

    //   if (updatedIndex !== -1) {
    //     const newData = [...prevData];
    //     newData[updatedIndex] = { ...updatedData, _id: dataLoad._id };
    //     return newData;
    //   }
    //   return prevData;
    // });
    // setFullData(fullData);
    openDeleteDropDown();
  };
  return (
    <>
      <div
        className={`modal fade ${
          showDeleteDropDown === true ? "show modal-y-axis" : "hide"
        }`}
        role="dialog"
        data-backdrop="static"
        data-keyboard="false" // To prevent closing with the keyboard
        onClick={(e) => {
          // Check if the click occurred outside the modal content
          if (e.target === e.currentTarget) {
            openDeleteDropDown(); // Call your close modal function
          }
        }}
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <form onSubmit={handleSubmit}>
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLongTitle">
                  {" "}
                  Delete Ride
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => openDeleteDropDown()}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body" style={{ width: "50% !important" }}>
                <div className="row">
                  <div className="col-md-12">
                    <p>Are you sure you want to delele? </p>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn-secondary rounded f-14 p-2 mr-3"
                  data-dismiss="modal"
                  disabled={loading}
                  onClick={() => openDeleteDropDown()}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  disabled={loading}
                  className="btn-primary rounded f-14 p-2 mr-3"
                >
                  Yes
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};
export default AdminCompleteRideDeletion;
