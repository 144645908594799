/** @format */

import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SERVERADDRESS } from "../../../constants";
import shift1 from "../../../assets/images/load.png";
import axios from "axios";
import moment from "moment";
import AdminOptimizeBookingRow from "./AdminOptimizeBookingRow";
import Select from "react-select";
import { editClientAction } from "../../../actions/userActions";

const AdminOptimizeBookingComponent = () => {
  const auth = useSelector((state) => state.auth);
  const reservation = useSelector((state) => state.reservation);
  const [reqData, setReqData] = useState([]);
  const dispatch = useDispatch();

  const availableDrivers = useSelector(
    (state) => state.users.allDriversWithoutPagination
  );
  const [values, setValues] = useState({
    companyId: "",
    date: moment().format("YYYY-MM-DD"),
  });
  const handleChange = (fieldName) => (event) => {
    setValues({
      ...values,
      [fieldName]: event.target.value,
    });
  };
  const [liveData, setLiveData] = useState(false);
  const handleCheckboxChange = () => {
    setLiveData(!liveData);
  };
  let orderSearch;
  // if (values.location !== ''){
  orderSearch = {
    companyId: auth.user.companyId.id,
    date: values.date,
  };
  const dateData = {
    date: values.date,
  };
  const reservationOptimizeData = async () => {
    const chicagoDateOptions = {
      timeZone: "America/Chicago",
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    };

    const chicagoDate = new Date().toLocaleDateString(
      "en-US",
      chicagoDateOptions
    );
    const parts = chicagoDate.split("/");
    const formattedChicagoDate = `${parts[2]}-${parts[0]}-${parts[1]}`;

    const { data } = await axios.post(
      `${SERVERADDRESS}/v5/reservation/reservationOptimize`,
      {
        // id: current?.id
        companyId: auth.user.companyId.id,
        // date: '2024-01-16T00:00:00.000Z',
        date: values.date + "T00:00:00.000Z",
        unavailableDrivers: selectedValue,
        liveData:
          formattedChicagoDate == moment().format("YYYY-MM-DD").toString()
            ? true
            : false,
      }
    );
    setReqData(data);
    // console.log("results1");
    // const results = await generatePermutations(
    //   reservation?.reservation?.today?.slice(0, 2)
    // );
    // console.log("results", results);
  };

  const generatePermutations = async (bookings) => {
    const result = [];

    function generate(n, arr) {
      if (n === 1) {
        result.push([...arr]);
        return;
      }

      for (let i = 0; i < n; i++) {
        generate(n - 1, arr);
        if (n % 2 === 0) {
          swap(arr, i, n - 1);
        } else {
          swap(arr, 0, n - 1);
        }
      }
    }

    generate(bookings.length, bookings.slice());

    return result;
  };

  function swap(arr, i, j) {
    const temp = arr[i];
    arr[i] = arr[j];
    arr[j] = temp;
  }
  // useEffect (() => {
  //     reservationOptimizeData()
  // }, [auth]);
  // }, []);
  const handleClick = async (event) => {
    event.preventDefault();
    for (let key of Object.keys(orderSearch)) {
      if (orderSearch[key] === "") {
        delete orderSearch[key];
      }
    }
    if (orderSearch.status === "") {
      delete orderSearch.status;
      delete dateData.status;
    }

    // dispatch(tabOrdersSearch());
    // dispatch(reservationOptimizeData(orderSearch));
    reservationOptimizeData();
    // dispatch(fromTo(dateData));
  };
  // console.log(values, "values--->")
  // console.log(dateData, "dateDate--->")

  // const handleUpdateMany = async () => {
  //   // Extract only the necessary data for update
  //   const suggestionsToUpdate = reqData
  //     .filter(
  //       (suggestion) =>
  //         suggestion?.suggestedDriver?.id != suggestion?.replacingDriver?.id
  //     )
  //     .map((suggestion) => ({
  //       bookingId: suggestion?.booking?.id,
  //       suggestedDriver: suggestion?.suggestedDriver?.id,
  //     }));

  //   // API call to updateMany
  //   try {
  //     const response = await axios.post(
  //       `${SERVERADDRESS}/v1/reservation/reservationOptimizeUpdate`,
  //       {
  //         suggestionsToUpdate,
  //         assigneeId: auth.user.id,
  //         date: moment().format().toString().substring(0, 19) + ".000Z",
  //         companyId: auth.user.companyId.id,
  //       }
  //     );

  //     //   console.log('UpdateMany API response:', response.data);
  //   } catch (error) {
  //     console.error("Error updating many bookings:", error);
  //   }
  // };

  // Log available drivers to ensure they are being fetched correctly
  // console.log("Available Drivers:", availableDrivers);

  // Prepare the options for the Select component
  const options = availableDrivers?.map((driver) => ({
    value: driver.id,
    label: `${driver.firstName} ${driver.lastName}`,
  }));

  // Log options to ensure they are being constructed correctly
  // console.log("Options:", options);

  const [selectedValue, setSelectedValue] = useState([]);

  // Function to handle selection
  const onSelect = (selectedList) => {
    if (selectedList.length > selectedValue.length) {
      const selectedIds = selectedList.map((option) => option.value);
      const newEntries = selectedIds.filter(
        (id) => !selectedValue.includes(id)
      );
      setSelectedValue(selectedIds);
      if (newEntries.length > 0) {
        dispatch(
          editClientAction({
            userId: newEntries[0],
            unavailableForOptimazation: true,
          })
        );
      }
      // console.log(selectedIds, "selectedIds");
    }
    if (selectedList.length < selectedValue.length) {
      const remainingIds = selectedList.map((option) => option.value);
      const removedEntries = selectedValue.filter(
        (id) => !remainingIds.includes(id)
      );
      setSelectedValue(remainingIds);
      if (removedEntries.length > 0) {
        dispatch(
          editClientAction({
            userId: removedEntries[0],
            unavailableForOptimazation: false,
          })
        );
      }
      // console.log(remainingIds, "remainingIds");
    }
  };
  // Function to handle removal
  // const onRemove = (selectedList) => {
  //   const remainingIds = selectedList.map((option) => option.value);
  //   const removedEntries = selectedValue.filter(
  //     (id) => !remainingIds.includes(id)
  //   );
  //   setSelectedValue(remainingIds);
  //   if (removedEntries.length > 0) {
  //     dispatch(
  //       editClientAction({
  //         userId: removedEntries[0],
  //         unavailableForOptimazation: false,
  //       })
  //     );
  //   }
  // };
  const isInitialRender = useRef(true);
  useEffect(() => {
    if (isInitialRender.current) {
      const unuseDrivers = availableDrivers
        ?.filter((x) => x.unavailableForOptimazation)
        .map((e) => e.id);
      setSelectedValue(unuseDrivers);
      isInitialRender.current = false;
    }
  }, [availableDrivers]);
  // console.log(selectedValue);

  return (
    <>
      <div className="d-flex flex-column w-tables rounded mt-3 bg-white">
        {/* <div
                className="card-body p-0 bg-white b-shadow-4 rounded d-block"
                // className={`card-body p-0 bg-white b-shadow-4 rounded ${showFilter ? "d-block" : "d-none"}`}
            > */}
        <br />
        <div className="row">
          {/* for space */}
          {/* <div className="col-sm-1">
          </div> */}
          <div className="col-sm-6 px-5">
            <div className="form-group">
              <label className="f-14 text-dark mb-12" data-label="">
                Unavailable Drivers
              </label>
              <Select
                options={options}
                onChange={onSelect} // Function will trigger on select event
                // onRemove={onRemove} // Function will trigger on remove event
                displayValue="name" // Property name to display in the dropdown options
                // value={selectedValue} // Preselected value to persist in dropdown
                value={options.filter((option) =>
                  selectedValue.includes(option.value)
                )}
                getOptionLabel={(option) => option.label} // Adjust based on your data structure
                getOptionValue={(option) => option.value}
                isMulti
              />
            </div>
          </div>
          <div className="col-sm-3 px-2">
            <div className="form-group">
              <label
                className="f-14 text-dark mb-12"
                data-label=""
                htmlFor="date"
              >
                Date
              </label>
              <input
                type="date"
                className="form-control height-35 f-14"
                placeholder=""
                id="date"
                // autocomplete="off"
                value={values.date}
                onChange={handleChange("date")}
              />
            </div>
          </div>
          {/* <div className="col-sm-1 mt-3 px-3">
            <div className="d-grid mt-3">
              <input
                type="checkbox"
                checked={liveData}
                onChange={handleCheckboxChange}
              />
              <label className="form-check-label pl-1 f-13 text-dark mx-1">
                Live Data
              </label>
            </div>
          </div> */}
          <div className="col-sm-2 mt-3 px-4">
            <div className="d-grid mt-3">
              <button className="btn btn-success" onClick={handleClick}>
                Optimize
              </button>
            </div>
          </div>

          {/* <div className="d-flex flex-column w-tables rounded mt-3 bg-white"> */}
          <div
            id="leads-table_wrapper"
            className="dataTables_wrapper dt-bootstrap4 no-footer"
          >
            {/* <div className="row"> */}
            <div className="col-sm-12">
              <table
                className="table border w-100 dataTable no-footer"
                id="leads-table"
                role="grid"
                aria-describedby="leads-table_info"
                style={{ overflowX: "auto" }}
              >
                <thead className="card-header">
                  <tr role="row">
                    <th
                      title="Order ID"
                      className="sorting border-right align-table-header min-width-orderId"
                      tabIndex="0"
                      aria-controls="leads-table"
                      rowSpan="1"
                      colSpan="1"
                      aria-label="Mobile: activate to sort column ascending"
                    >
                      Order ID
                    </th>
                    <th
                      title="Replacing Driver"
                      className="sorting_disabled border-right align-table-header"
                      rowSpan="1"
                      colSpan="1"
                      aria-label="Next Follow Up"
                    >
                      Replacing Driver
                    </th>
                    <th
                      title="Suggested Driver"
                      className="sorting border-right align-table-header"
                      tabIndex="0"
                      aria-controls="leads-table"
                      rowSpan="1"
                      colSpan="1"
                      aria-label="Lead Agent: activate to sort column ascending"
                    >
                      Suggested Driver
                    </th>
                    {/* <th
                      title="Vehicle Type"
                      className="sorting border-right"
                      tabIndex="0"
                      aria-controls="leads-table"
                      rowSpan="1"
                      colSpan="1"
                      aria-label="Lead Agent: activate to sort column ascending"
                    >
                      Vehicle Type
                    </th> */}
                    <th
                      title="Vehicle Type"
                      className="sorting border-right align-table-header"
                      tabIndex="0"
                      aria-controls="leads-table"
                      rowSpan="1"
                      colSpan="1"
                      aria-label="Lead Agent: activate to sort column ascending"
                    >
                      Service
                    </th>
                    <th
                      title="Pickup - Drop Off"
                      className="sorting border-right w-30 align-table-header"
                      tabIndex="0"
                      aria-controls="leads-table"
                      rowSpan="1"
                      colSpan="1"
                      aria-label="Lead Agent: activate to sort column ascending"
                    >
                      Pickup - Drop Off
                    </th>
                    <th
                      title="Pickup - Drop Off"
                      className="sorting border-right w-30 align-table-header"
                      tabIndex="0"
                      aria-controls="leads-table"
                      rowSpan="1"
                      colSpan="1"
                      aria-label="Lead Agent: activate to sort column ascending"
                    >
                      Route Path
                    </th>
                    {/* <th
                      title="Driver Address"
                      className="sorting border-right w-30"
                      tabIndex="0"
                      aria-controls="leads-table"
                      rowSpan="1"
                      colSpan="1"
                      aria-label="Lead Agent: activate to sort column ascending"
                    >
                      Driver Address
                    </th> */}
                  </tr>
                </thead>
                <tbody>
                  {reqData &&
                    reqData?.length > 0 &&
                    reqData?.map((current, i) => {
                      // if (current.delete === false) {
                      return (
                        <AdminOptimizeBookingRow current={current} i={i} />
                      );
                      // }
                    })}
                </tbody>
              </table>
              {reqData?.length === 0 && (
                <div
                  className="dash-stats-list"
                  style={{ textAlign: "center" }}
                >
                  <br />
                  <br />
                  <img
                    alt=""
                    src={shift1}
                    style={{ width: "145px", opacity: "0.5" }}
                  />
                  <br />
                  <p style={{ opacity: "0.5" }}>No Search or No Report Found</p>
                </div>
              )}
              <div
                id="leads-table_processing"
                className="dataTables_processing card"
                style={{ display: "none" }}
              >
                Processing...
              </div>
            </div>
          </div>
          <br />
          {/* {reqData && reqData?.length > 0 && (
            <div className="col-lg-12">
              <div
                className="form-group "
                style={{ marginTop: "1.5em !important" }}
              >
                <button
                  type="submit"
                  className="btn-primary rounded f-14 p-2 mr-3 centre-btn"
                  onClick={handleUpdateMany}
                >
                  Confirm
                </button>
              </div>
            </div>
          )} */}
          <div className="d-flex border"></div>
        </div>
      </div>
      {/* </div> */}
      {/* </div> */}
    </>
  );
};
export default AdminOptimizeBookingComponent;
