import {
    NOTE_CREATE_REQUEST,
    NOTE_CREATE_SUCCESS,
    NOTE_CREATE_FAIL,
    NOTE_VIEW_REQUEST,
    NOTE_VIEW_SUCCESS,
    NOTE_VIEW_FAIL,
    REMINDER_RECEIVE_SUCCESS,
} from "../constants"

export const noteReducer = (
    state = {
        note: {
            note: null,
        }
    },
    action
    ) => {
        switch (action.type) {
        case NOTE_CREATE_REQUEST : 
            return {
                ...state,
            };
        case NOTE_CREATE_SUCCESS :
            return {
                ...state,
                note: [action.payload, ...state.note ],
            };
        case NOTE_CREATE_FAIL :
            return {
                ...state,
                error: action.payload,
            };
        case NOTE_VIEW_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case NOTE_VIEW_SUCCESS:
            return {
                ...state,
                loading: false,
                note: action.payload,
            };
        case NOTE_VIEW_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case REMINDER_RECEIVE_SUCCESS:
            return {
                ...state,
                note: state.note.some(note => note.id == action.payload.id) 
            ? state.note 
            : [action.payload, ...state.note],
            };

        default:
            return state;
    }
}