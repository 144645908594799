/** @format */

import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import moment from "moment";
import { addTrip } from "../../../actions/tripActions";
import { markedLocation, updateTrip } from "../../../actions/reservationAction";
import { NavLink } from "react-router-dom";
import axios from "axios";
import { SERVERADDRESS } from "../../../constants";

const DriverDashboardTableRow = ({
  current,
  i,
  dropDownRow,
  setDropDownRow,
}) => {
  const trip = useSelector((state) => state.trip);
  const auth = useSelector((state) => state.auth);
  const sockets = useSelector((state) => state.sockets);
  const { socketInstance } = sockets;
  const [values, setValues] = useState({
    companyId: "",
    reservationId: "",
    tripStartTime: "",
  });
  const handleChange = (fieldName) => (event) => {
    setValues({
      ...values,
      [fieldName]: event.target.value,
    });
  };
  // console.log(values)
  useEffect(() => {
    setValues({
      ...values,
      reservationId: current.reservation?.id,
    });
  }, [current]);
  const [values2, setValues2] = useState({
    _id: "",
    // arrivedTime: '',
    // pickUpTime: '',
    // pickUpLat: '',
    // pickUpLng: '',
    // dropOffTime: '',
    // dropOffLat: '',
    // dropOffLng: '',
    // waitingTimePickUp: '',
    // waitingTimePickUpInDecimal: '',
    // tripDuration: '',
    // tripDurationInDecimal: '',
    // tripStatus: '',
    // tripCancel: '',
    // isCompleted: '',
  });
  useEffect(() => {
    if (current.trip !== null) {
      setValues2({
        ...values2,
        _id: current.trip?.id,
        // arrivedTime: current.trip?.arrivedTime,
        // pickUpTime: current.trip?.pickUpTime,
        // pickUpLat: current.trip?.pickUpLat,
        // pickUpLng: current.trip?.pickUpLng,
        // dropOffTime: current.trip?.dropOffTime,
        // dropOffLat: current.trip?.dropOffLat,
        // dropOffLng: current.trip?.dropOffLng,
        // waitingTimePickUp: current.trip?.waitingTimePickUp,
        // waitingTimePickUpInDecimal: current.trip?.waitingTimePickUpInDecimal,
        // tripDuration: current.trip?.tripDuration,
        // tripDurationInDecimal: current.trip?.tripDurationInDecimal,
        // tripStatus: current.trip?.tripStatus,
        // tripCancel: current.trip?.tripCancel,
        // isCompleted: current.trip?.isCompleted,
      });
    }
  }, [current]);
  const dispatch = useDispatch();
  const onClickNavigation = () => {
    dispatch(markedLocation(current));
  };
  const tripData = {
    companyId: auth.user.companyId.id,
    driverId: auth.user.id,
    reservationId: values.reservationId,
    // pickUpLat: 24.9261844,
    // pickUpLng: 67.1356306,
    tripStartTime: moment().format().toString().substring(0, 19) + ".000Z",
  };
  // console.log(values2, "sssssssssssssssssssssssssssssssssssssss")
  const handleSubmit = async (event) => {
    event.preventDefault();
    // console.log("first")
    dispatch(addTrip(tripData));
    socketInstance.emit("send-notification-to-dispatcher", {
      text:
        auth.user.firstName +
        " " +
        auth.user.lastName +
        " has started the ride for order no. " +
        values.orderId,
      companyId: auth.user.companyId.id,
    });
    setTimeout(() => {
      socketInstance.emit("send-reservation-req", {
        userId: auth.user.id,
        companyId: auth.user.companyId.id,
        reservationId: values.reservationId,
        role: "driver",
      });
      console.log(
        {
          userId: auth.user.id,
          reservationId: values.reservationId,
          role: "driver",
        },
        "running"
      );
    }, 5000);
    // setValues({
    //   assetNo: "",
    //   serviceType: "",
    //   vehicleOwnership: "",
    // });
  };

  useEffect(() => {
    if (socketInstance) {
      socketInstance?.emit("send-reservation-req", {
        userId: auth.user.id,
        companyId: auth.user.companyId.id,
        reservationId: values.reservationId,
        role: "admin",
      });
      socketInstance?.on(
        "receive-reservation-detail",
        (newNotificationRecieved) => {
          console.log("reservation received==>", newNotificationRecieved);
        }
      );
    }
    return () => {
      socketInstance?.off("receive-reservation-detail");
    };
  }, [socketInstance]);

  const handleArrived = async (event) => {
    dispatch(
      updateTrip({
        _id: values2._id,
        arrivedTime: moment().format().toString().substring(0, 19) + ".000Z",
        tripStatus: "Arrived",
      })
    );
    const obj = {
      text:
        auth.user.firstName +
        " " +
        auth.user.lastName +
        " has arrived at the pick up for order no. " +
        values.orderId,
      companyId: auth.user.companyId.id,
    };
    // console.log(obj);
    socketInstance.emit("send-notification-to-dispatcher", {
      text:
        auth.user.firstName +
        " " +
        auth.user.lastName +
        " has arrived at the pick up for order no. " +
        values.orderId,
      companyId: auth.user.companyId.id,
    });
  };

  const handleNoShow = async (event) => {
    const dateTime = new Date();
    const { data } = await axios.post(
      `${SERVERADDRESS}/v1/messages/noShowRequest`,
      {
        sender: auth.user,
        chatId: "6666dd17c081610020689117",
        message: "No Show",
        senderId: auth.user.id,
        dateTime,
        requestDetails: {
          type: "No Show",
          reason: "I have waiting for 10 minutes",
        },
        isRequest: true,
      }
    );
    socketInstance.emit("new message", {
      sender: {
        firstName: auth.user.firstName,
        lastName: auth.user.lastName,
        id: auth.user.id,
        ...(auth?.user?.profilePic?.fileUrl && {
          profilePic: { fileUrl: auth.user.profilePic.fileUrl },
        }),
      },
      chatId: {
        photo: "https://cdn-icons-png.flaticon.com/512/9790/9790561.png",
        isGroup: true,
        users: [
          {
            role: "user",
            isOnline: false,
            firstName: "a for",
            lastName: "edited",
            email: "a4@example.com",
            id: "665a635e73383a002017ffbf",
          },
          {
            role: "admin",
            isOnline: true,
            email: "abc@example.com",
            firstName: "Admin",
            lastName: "Company",
            profilePic: "652a46a62000e40027a5fa18",
            id: "63cb14f73f55723694dbd18f",
          },
          {
            role: "user",
            isOnline: false,
            email: "comp@example.com",
            firstName: "External 1",
            lastName: "External 1",
            id: "64a30a48dc4ff74a7098077a",
          },
          {
            role: "user",
            isOnline: true,
            firstName: "first",
            lastName: "dispatcher",
            email: "firstdispatcher@example.com",
            id: "646811f89f439ada514639d3",
          },
          {
            role: "user",
            isOnline: true,
            firstName: "new",
            lastName: "admin",
            email: "newadmin@example.com",
            id: "644be1b819c20022904648bb",
          },
          {
            role: "user",
            isOnline: true,
            firstName: "sarmad",
            lastName: "raees",
            email: "sarma@gmail.com",
            id: "65ce9fc96fb36700294d2eb5",
          },
          {
            role: "user",
            isOnline: true,
            email: "firstdriver@example.com",
            firstName: "first",
            lastName: "Driver",
            profilePic: "657800c1d66e7600276764e8",
            id: "63cf2534057dae4374e3eb1b",
          },
        ],
        noShowMessageReq: false,
        noShowMessageAction: false,
        company: "63cb14f53f55723694dbd185",
        groupType: "Selected Users",
        chatName: "2034",
        groupAdmin: {
          role: "admin",
          isOnline: true,
          email: "abc@example.com",
          firstName: "Admin",
          lastName: "Company",
          profilePic: "652a46a62000e40027a5fa18",
          id: "63cb14f73f55723694dbd18f",
        },
        reservationId: {
          orderId: 2034,
          id: "6666dcebc081610020689072",
        },
        readBy: [],
        unreadCount: [],
        id: "6666dd17c081610020689117",
      },
      message: "No Show",
      senderId: auth.user.id,
      dateTime,
      requestDetails: {
        type: "No Show",
        reason: "I have waiting for 10 minutes",
      },
      isRequest: true,
    });
  };

  const handlePickUp = async (event) => {
    dispatch(
      updateTrip({
        _id: values2._id,
        pickUpTime: moment().format().toString().substring(0, 19) + ".000Z",
        tripStatus: "Ongoing",
        pickUpLat: 24.9261844,
        pickUpLng: 67.1356306,
      })
    );
    const obj = {
      text:
        auth.user.firstName +
        " " +
        auth.user.lastName +
        " has arrived at the pick up for order no. " +
        values.orderId,
      companyId: auth.user.companyId.id,
    };
    // console.log(obj);
    socketInstance.emit("send-notification-to-dispatcher", {
      text:
        auth.user.firstName +
        " " +
        auth.user.lastName +
        " has picked the customer for order no. " +
        values.orderId,
      companyId: auth.user.companyId.id,
    });
  };

  const handleEndRide = async (event) => {
    dispatch(
      updateTrip({
        _id: values2._id,
        dropOffTime: moment().format().toString().substring(0, 19) + ".000Z",
        tripStatus: "Completed",
        dropOffLat: 24.9261844,
        dropOffLng: 67.1356306,
        isCompleted: true,
      })
    );
    socketInstance.emit("send-notification-to-dispatcher", {
      text:
        auth.user.firstName +
        " " +
        auth.user.lastName +
        " has completed order no. " +
        values.orderId,
      companyId: auth.user.companyId.id,
    });
  };

  return (
    <>
      <tr role="row" key={i} className="odd border">
        <td className="border-right">
          {current.reservation?.orderId}
          <i data-toggle="tooltip" className=""></i>
        </td>
        <td className="border-right">
          {current.reservation?.customerId.firstName +
            " " +
            current.reservation?.customerId.lastName}
        </td>
        <td className="border-right">
          {" "}
          {current.reservation?.customerId.phone}
        </td>
        <td className="border-right">
          {current.reservation?.assetId?.assetNo}
          <i data-toggle="tooltip" className=""></i>
          {/* <i data-toggle="tooltip" className="fa fa-info-circle fa-w-16 text-info"></i> */}
        </td>
        <td className="border-right w-30">
          <div className="d-flex justify-content-between">
            <div>
              <div>
                <p className="f-12 font-medium mb-0">
                  <strong>{current.reservation?.pickUpAddress.address}</strong>{" "}
                </p>
                {/* <p className="f-12 mb-0">IL 60609, USA</p> */}
              </div>
              <div>
                <time>
                  {moment(
                    current.reservation?.pickUpDate?.substring(0, 10)
                  ).format("MM/DD/YYYY")}{" "}
                  -{" "}
                  {parseInt(current.reservation?.pickUpTime.substring(0, 2)) ===
                  0
                    ? 12
                    : parseInt(
                        current.reservation?.pickUpTime.substring(0, 2)
                      ) > 12
                    ? parseInt(
                        current.reservation?.pickUpTime.substring(0, 2)
                      ) - 12
                    : parseInt(current.reservation?.pickUpTime.substring(0, 2))}
                  {":" + current.reservation?.pickUpTime.substring(3, 5)}
                  {parseInt(current.reservation?.pickUpTime.substring(0, 2)) >
                  11
                    ? "pm"
                    : "am"}
                </time>
              </div>
            </div>
            <div className="mt-4 px-4">
              <div className="hidden sm:block">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="50"
                  height="8"
                  viewBox="0 0 40 6"
                  fill="none"
                >
                  <path
                    d="M40 3L35 0.113249V5.88675L40 3ZM0 3.5H35.5V2.5H0V3.5Z"
                    fill="black"
                  ></path>
                </svg>
              </div>
              <p className="text-center font-medium">
                <span>{current.reservation?.totalMileage} mi</span>
              </p>
            </div>
            <div>
              <div>
                <p className="f-12 font-medium mb-0">
                  <strong>{current.reservation?.dropOffAddress.address}</strong>
                </p>
                {/* <p className="f-12 mb-0">IL 60623</p> */}
              </div>
              <div>
                <time>
                  {moment(
                    current.reservation?.dropOffDate?.substring(0, 10)
                  ).format("MM/DD/YYYY")}{" "}
                  -{" "}
                  {parseInt(
                    current.reservation?.dropOffTime.substring(0, 2)
                  ) === 0
                    ? 12
                    : parseInt(
                        current.reservation?.dropOffTime.substring(0, 2)
                      ) > 12
                    ? parseInt(
                        current.reservation?.dropOffTime.substring(0, 2)
                      ) - 12
                    : parseInt(
                        current.reservation?.dropOffTime.substring(0, 2)
                      )}
                  {":" + current.reservation?.dropOffTime.substring(3, 5)}
                  {parseInt(current.reservation?.dropOffTime.substring(0, 2)) >
                  11
                    ? "pm"
                    : "am"}
                </time>
              </div>
            </div>
          </div>
        </td>
        <td className="border-right">
          {moment(
            current.reservation?.dateForReservation?.substring(0, 10)
          ).format("MM/DD/YYYY")}
          <br />{" "}
          {parseInt(
            current.reservation?.dateForReservation?.substring(0, 2)
          ) === 0
            ? 12
            : parseInt(
                current.reservation?.dateForReservation?.substring(0, 2)
              ) > 12
            ? parseInt(
                current.reservation?.dateForReservation?.substring(0, 2)
              ) - 12
            : parseInt(
                current.reservation?.dateForReservation?.substring(0, 2)
              )}
          {":" + current.reservation?.pickUpTime.substring(3, 5)}
          {parseInt(current.reservation?.dateForReservation?.substring(0, 2)) >
          11
            ? "pm"
            : "am"}
        </td>
        {/* <td className="border-right"> {current.reservation?.driverBookingStatus} </td> */}
        <td className="border-right text-center">
          <NavLink
            to={"/navigate-reservation"}
            onClick={onClickNavigation}
            type="button"
            className="btn btn-info"
            style={{ cursor: "default" }}
          >
            Navigate
          </NavLink>
        </td>
        {(auth?.user?.permissionId?.myOrder?.create === true ||
          auth?.user?.permissionId?.myOrder?.update === true) &&
        (current.reservation?.status === "Completed" ||
          current.trip?.tripStatus === "Completed") ? (
          <td className="border-right text-center">
            <button
              type="button"
              className="btn btn-success"
              style={{ cursor: "default" }}
            >
              Completed
            </button>
          </td>
        ) : // }
        // {
        // current.reservation?.status !== "Completed" &&
        current.reservation?.status === "Canceled" ? (
          <td className="border-right text-center">
            <button
              type="button"
              className="btn btn-info"
              style={{ cursor: "default" }}
            >
              Canceled
            </button>
          </td>
        ) : current && current.trip === null ? (
          <td className="border-right text-center">
            <button
              type="button"
              className="btn btn-primary"
              onClick={handleSubmit}
            >
              Start Ride
            </button>
          </td>
        ) : current.trip?.tripStatus === "Towards Customer" ? (
          <>
            <td className="border-right text-center">
              <button
                type="button"
                className="btn btn-dark"
                onClick={handleArrived}
              >
                Arrived
              </button>
            </td>
          </>
        ) : current.trip?.tripStatus === "Arrived" ? (
          <>
            <td className="border-right text-center">
              <button
                type="button"
                className="btn btn-secondary"
                onClick={handlePickUp}
              >
                Pick Customer
              </button>
            </td>
            <td className="border-right text-center">
              <button
                type="button"
                className="btn btn-dark"
                onClick={handleNoShow}
              >
                No show
              </button>
            </td>
            {/* <td className="border-right text-center" >
              <button type="button" className="btn btn-danger" onClick={handleSubmit}>Cancel</button>
            </td> */}
          </>
        ) : current.trip?.tripStatus === "Ongoing" ? (
          <td className="border-right text-center">
            <button
              type="button"
              className="btn btn-warning"
              onClick={handleEndRide}
            >
              Ride End
            </button>
          </td>
        ) : (
          <></>
        )}
      </tr>
    </>
  );
};
export default DriverDashboardTableRow;
