import React from 'react'

const DriverRouteDeclineModal = ({showDeclineDropDown, openDeclineDropDown, values, setValues, current, i ,handleSubmit, handleChange}) => {
  return (
    <>
        <div
            className={`modal fade ${showDeclineDropDown ? "show modal-y-axis" : "hide"}`}
            role="dialog"
            data-backdrop="static"
            data-keyboard="false" // To prevent closing with the keyboard
            onClick={(e) => {
              // Check if the click occurred outside the modal content
              if (e.target === e.currentTarget) {
                openDeclineDropDown(); // Call your close modal function
              }
            }}
        >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLongTitle">
                {" "}
                Decline Reason
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => openDeclineDropDown()}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-md-12">
                  <label htmlFor="allowed_file_types">Reason: </label>
                  <textarea
                    type="text"
                    name="allowed_file_types"
                    id="allowed_file_types"
                    placeholder="Enter The Note"
                    className="form-control f-14"
                    autoComplete="false"
                    value={values.declineReason}
                    onChange={handleChange("declineReason")}
                  ></textarea>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn-secondary rounded f-14 p-2 mr-3"
                data-dismiss="modal"
                onClick={() => openDeclineDropDown()}
              >
                Close
              </button>
              <button
                type="button"
                className="btn-primary rounded f-14 p-2 mr-3"
                onClick={handleSubmit}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default DriverRouteDeclineModal