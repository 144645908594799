/** @format */

import React from "react";
import MessageArea from "./messageArea";
import UsersArea from "./usersArea";
import UsersChatNew from "./usersChatNew";
import io from "socket.io-client";
import { SOCKETADDRESS } from "../../constants";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { useState } from "react";

// let socket, selectedChatCompare;

function ChatComponent() {
  const sockets = useSelector((state) => state.sockets);
  // const [socketConnected, setSocketConnected] = useState(false);

  // useEffect(() => {
  // socket = io(SOCKETADDRESS);
  // socket.on("typing", () => setIsTyping(true));
  // socket.on("stop typing", () => setIsTyping(false));
  // }, []);

  //   useEffect(() => {
  //     socket = io(SOCKETADDRESS);

  //     socket.emit("setup", auth.user.id);
  //     socket.on("connected", () => {
  //       setSocketConnected(true);
  //       // console.log()
  //     });
  //  return () => {
  //       socket.disconnect();
  //       // socket.off("login");
  //     };
  //     }, []);

  // console.log("socket connected==>", sockets);
  return (
    <>
      {/* <div className="d-block d-lg-flex d-md-flex justify-content-between action-bar mb-20 mt-3">
        <div id="table-actions" className="flex-grow-1 align-items-center pad_left">
          <h4 className=" mb-0 f-21 text-capitalize font-weight-bold"> Messages </h4>
        </div>
      </div> */}
      {/* <div className="d-flex align-items-stretch h-100 px-2 py-3"> */}
        {/* {sockets.socketInstance && ( */}
        <>
          <UsersChatNew />

          {/* <UsersArea
          // socketConnected={socketConnected} socket={socket}
          /> */}
          {/* <MessageArea
          // socketConnected={socketConnected} socket={socket}
          /> */}
        </>
        {/* )} */}
      {/* </div> */}
    </>
  );
}

export default ChatComponent;
