/** @format */

import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
// import { SERVERADDRESS, SOCKET_SERVER_ADDRESS, TOGGLE_SIDEBAR } from "../../../constants";
// import { HeaderNotificationModel } from "../../HeaderNotificationModel";
import { Link, useNavigate } from "react-router-dom";
import {
  createUserLoginInfo,
  // getUserByToken2,
  userLogout,
} from "../../../actions/authActions";
import io from "socket.io-client";
// import MessageDropDown from "./MessageDropDown";
import NotificationDropDown from "./NotificationDropDown";
// import { notifyAutoLogout } from "../../toast";
import { useIdleTimer } from "react-idle-timer";
import { Device } from "@capacitor/device";
import axios from "axios";
import moment from "moment";
import { driverEarning } from "../../../actions/reservationAction";
import music from "../../../assets/music/notification-sound-3.mp3";
import { toast } from "react-toastify";
// import defaultImg from "../../../assets/images/profile.jpg";
// import groupIcon from "../../../assets/images/undraw_People_re_8spw.png";

import {
  SERVERADDRESS,
  SOCKET_SERVER_ADDRESS,
  TOGGLE_SIDEBAR,
  MESSAGE_RECEIVE_SUCCESS,
  NOTIFICATION_RECEIVE_SUCCESS,
  // SOCKETADDRESS,
  SOCKET_CONNECTED,
  SOCKET_DISCONNECT,
  CHAT_SELECT_SUCCESS,
  REMINDER_RECEIVE_SUCCESS,
  RESERVATION_UPDATE_SUCCESS,
} from "../../../constants";
import {
  getNotificationsAction,
  readNotificationAction,
} from "../../../actions/notificationActions";
import ChatMessageDropDown from "./ChatMessageDropDown";
import { rideNotification } from "../../toast";
import { getChatsAction } from "../../../actions/chatActions";
// const socket = io('http://localhost:5000')
// const socket = io(SOCKET_SERVER_ADDRESS)
let socketInstance;

const AdminHeader = ({ notShow }) => {
  const auth = useSelector((state) => state.auth);
  const users = useSelector((state) => state.users);
  const reservation = useSelector((state) => state.reservation);
  const sockets = useSelector((state) => state.sockets);
  const chats = useSelector((state) => state.chats);
  const { socketConnected } = sockets;
  const mail = useSelector((state) => state.mail);
  const dispatch = useDispatch();
  const [showDropDown, setshowDropDown] = useState(false);
  const [opensidedrawer, setopensidedrawer] = useState(false);

  const openDropDown = () => {
    setshowDropDown((state) => !state);
  };
  const toggleDriver = () => {
    setopensidedrawer((state) => !state);
  };
  let navigate = useNavigate();
  const navigateUser = () => {
    navigate("/login");
  };
  const notificationRef = useRef();
  const logout = async (e) => {
    await dispatch(
      userLogout({ refreshToken: auth.tokens.refresh.token }, navigateUser)
    );
  };
  const [cc, setCC] = useState(0);
  const [data, setData] = useState([]);
  const [msg, setMsg] = useState([]);

  // comment out callback notifucation to test speed

  // useEffect(() => {
  //   if (!socketConnected) return;
  //   if (auth?.user?.jobTitle === "Driver") {
  //     socketInstance?.emit(
  //       "driver-notifications",
  //       {
  //         companyId: auth.user.companyId.id,
  //         driverId: auth.user.id,
  //       },
  //       (response) => {
  //         // console.log(response)
  //         setData(response.notifications);
  //         // setMsg(response.messages);
  //         // dispatch(setActiveRoute(response.route));
  //         return () => {
  //           socketInstance?.off("driver-notifications");
  //         };
  //       }
  //     );
  //   } else if (auth?.user?.jobTitle === "Admin") {
  //     socketInstance?.emit(
  //       "admin-notifications",
  //       {
  //         companyId: auth.user.companyId.id,
  //         userId: auth.user.id,
  //       },
  //       (response) => {
  //         setData(response.notifications);
  //         // setMsg(response.messages);
  //         return () => {
  //           socketInstance?.off("admin-notifications");
  //         };
  //       }
  //     );
  //   }
  // }, [cc]);
  // let bb = 0;
  // useEffect(() => {
  //   setInterval(
  //     () => setCC(bb++),
  //     // , 2000);
  //     2000
  //   );
  // }, []);
  // console.log(data, msg, "msg<")

  useEffect(() => {
    dispatch(getNotificationsAction(auth.user.id));
    // socketInstance = io(SOCKET_SERVER_ADDRESS);
    socketInstance = io(SOCKET_SERVER_ADDRESS, {
      transports: ["polling"],
      reconnection: true,
      reconnectionDelay: 1000,
      reconnectionDelayMax: 10000,
      reconnectionAttempts: Infinity,
    });
    socketInstance?.emit("setup", auth.user.id);
    socketInstance?.on("connected", () => {
      dispatch({
        type: SOCKET_CONNECTED,
        payload: { socketInstance, socketConnected: true },
      });
    });
    socketInstance?.emit("newUser", auth.user.id);
    socketInstance?.on("reconnect-user", (socketId) => {
      // console.log("Reconnect event received, socketId:", socketId);
      if (auth.user.id) {
        socketInstance?.emit("newUser", auth.user.id); // Re-emit the newUser event
      }
    });
    return () => {
      // console.log("Disconnecting socket...");
      socketInstance?.disconnect();
      // console.log("Socket disconnected");
      socketInstance.off("connected");
      socketInstance.off("reconnect-user");
      dispatch({
        type: SOCKET_DISCONNECT,
        payload: { socketInstance: null, socketConnected: false },
      });
    };
  }, []);

  const onIdle = () => {
    logout();
    toast.dismiss();
    // setTimeout(() =>
    //   notifyAutoLogout()
    // , 3000);
  };
  const onActive = (event) => {
    //
  };
  const {
    start,
    reset,
    activate,
    pause,
    resume,
    isIdle,
    isPrompted,
    isLeader,
    getTabId,
    getRemainingTime,
    getElapsedTime,
    getLastIdleTime,
    getLastActiveTime,
    getTotalIdleTime,
    getTotalActiveTime,
  } = useIdleTimer({
    //   onPrompt,
    onIdle,
    onActive,
    //   onAction,
    timeout: 1000 * 60 * 10,
    //   timeout: 1000 * 10,
    promptTimeout: 0,
    events: [
      "mousemove",
      "keydown",
      "wheel",
      "DOMMouseScroll",
      "mousewheel",
      "mousedown",
      "touchstart",
      "touchmove",
      "MSPointerDown",
      "MSPointerMove",
      "visibilitychange",
      // 'blur'
    ],
    immediateEvents: [],
    debounce: 0,
    throttle: 0,
    eventsThrottle: 200,
    element: document,
    startOnMount: true,
    startManually: false,
    stopOnIdle: false,
    //   crossTab: false,
    crossTab: true,
    name: "idle-timer",
    syncTimers: 0,
    leaderElection: false,
  });
  let j = 0;
  let jj = 0;
  const locationOfUserOld = async () => {
    if (auth?.activeUserCountry === null) {
      if (auth?.user?.permissionId?.isAdmin === false) {
        // const ok = await axios.get("https://geolocation-db.com/json/");
        // console.log(ok, "okok");
        const [info, deviceInfo, res] = await Promise.all([
          Device.getId(),
          Device.getInfo(),
          axios.get("https://ipinfo.io/json?token=b59a11ac063a47"),
        ]);
        let [ipAddress, country, state, deviceType] = [
          res.data.ip,
          res.data.country,
          res.data.region,
          deviceInfo.platform,
        ];
        // console.log(info.identifier, ipAddress, country, state, deviceType, "binfo")
        if (country !== "US") {
          const regionNames = new Intl.DisplayNames(["en"], { type: "region" });
          await axios.post(`${SERVERADDRESS}/v1/auth/out-of-region-email`, {
            email: auth.user.email,
            name: auth.user.firstName + " " + auth.user.lastName,
            country: regionNames.of(country),
          });
        }
        // console.log(regionNames.of(country), "country");
        // const currDate = moment().format()
        // const arr = currDate.toString().substring(0,19)
        // const newCurrDate = arr+".000+00:00"
        dispatch(
          createUserLoginInfo({
            userId: auth.user.id,
            companyId: auth.user.companyId.id,
            loginStatus: true,
            online: true,
            fingerPrintUuid: info.identifier,
            ipAddress: ipAddress,
            country: country,
            state: state,
            deviceType: deviceType,
          })
        );
      }
    }
  };
  const locationOfUser = async () => {
    // if (auth?.activeUserCountry === null) {
    //   // if (auth?.user?.permissionId?.isAdmin === false) {
    //   // const ok = await axios.get("https://geolocation-db.com/json/");
    //   // console.log(ok, "okok");
    //   const [info, deviceInfo] = await Promise.all([
    //     Device.getId(),
    //     Device.getInfo(),
    //   ]);
    //   let [deviceType] = [deviceInfo.platform];
    //   dispatch(
    //     createUserLoginInfo({
    //       userId: auth.user.id,
    //       companyId: auth.user.companyId.id,
    //       loginStatus: true,
    //       online: true,
    //       fingerPrintUuid: info.identifier,
    //       // ipAddress: ipAddress,
    //       // country: country,
    //       // state: state,
    //       deviceType: deviceType,
    //     })
    //   );
    //   // }
    // }
  };
  const getDriverEarning = async () => {
    if (auth?.user?.isDriver === true) {
      dispatch(
        driverEarning({
          driverId: auth.user.id,
          today: moment().format("YYYY-MM-DD"),
          weekFirst:
            moment().format("dddd") !== "Sunday"
              ? moment().startOf("week").add(1, "days").format("YYYY-MM-DD")
              : moment().startOf("week").add(-6, "days").format("YYYY-MM-DD"),
          weekLast:
            moment().format("dddd") !== "Sunday"
              ? moment().endOf("week").add(1, "days").format("YYYY-MM-DD")
              : moment().endOf("week").add(-6, "days").format("YYYY-MM-DD"),
        })
      );
    }
  };
  // const chkUser = async () => {
  //   dispatch(getUserByToken2(auth?.tokens?.refresh?.token));
  // };
  useEffect(() => {
    locationOfUser();
    getDriverEarning();
    // chkUser();
  }, []);
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    const handleVisibilityChange = () => {
      setIsVisible(document.visibilityState === "visible");
      if (document.visibilityState === "hidden") {
        // Dismiss all toasts when page is hidden
        toast.dismiss();
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);
  // console.log(isVisible, "isVisible");
  useEffect(() => {
    if (socketInstance) {
      socketInstance?.on("message recieved", (newMessageRecieved) => {
        // console.log("message received==>", newMessageRecieved);
        const chatFound = chats.list.find(
          (x) => x.id === newMessageRecieved.chatId.id
        );
        if (!chatFound) {
          dispatch(
            getChatsAction({
              userId: auth.user.id,
              companyId: auth.user.companyId.id,
            })
          );
        }
        dispatch({
          type: MESSAGE_RECEIVE_SUCCESS,
          payload: newMessageRecieved,
        });
      });

      socketInstance?.on("new-ride-req-received", (newMessageRecieved) => {
        // console.log("new-ride-req-received==>", newMessageRecieved);
        // dispatch({
        //   type: MESSAGE_RECEIVE_SUCCESS,
        //   payload: newMessageRecieved,
        // });
        notificationRef.current.play();
      });

      socketInstance?.on("notification recieved", (notificationReceived) => {
        // console.log("notification received==>", notificationReceived);
        dispatch({
          type: NOTIFICATION_RECEIVE_SUCCESS,
          payload: notificationReceived,
        });
        notificationRef.current.play();
      });
      socketInstance?.on(
        `receive-reminder-note${auth?.user?.companyId?.id}`,
        (reminderReceived) => {
          // console.log(
          //   reminderReceived,
          //   "reminderReceivedreminderReceivedreminderReceived"
          // );
          dispatch({
            type: REMINDER_RECEIVE_SUCCESS,
            payload: reminderReceived,
          });
        }
      );
      socketInstance?.on(
        "receive-reservation-detail",
        (newNotificationRecieved) => {
          // console.log("reservation received==>", newNotificationRecieved);
          const { reservation, trip } = newNotificationRecieved;
          dispatch({
            type: RESERVATION_UPDATE_SUCCESS,
            payload: reservation,
          });
        }
      );
      if (auth?.user?.permissionId?.booking?.isAllow === true) {
        socketInstance?.on(
          "receive-notification-to-dispatcher",
          (newNotificationRecieved) => {
            // console.log("notification received==>", newNotificationRecieved);
            // auth.tokens.refresh.token &&
            if (isVisible) {
              rideNotification(newNotificationRecieved.text);
            }
          }
        );
      }
    }
    return () => {
      socketInstance.off("message recieved");
      socketInstance.off("receive-notification-to-dispatcher");
      socketInstance.off(`receive-reminder-note${auth?.user?.companyId?.id}`);
      socketInstance.off("notification recieved");
      socketInstance.off("new-ride-req-received");
      socketInstance.off("receive-reservation-detail");
    };
  }, [socketInstance]);
  // let arr = [];
  const notifications = useSelector((state) => state.notifications);
  // const clickHandler = (notificationId, chat) => {
  //   const notification = notifications.list.find((x) => x.id == notificationId);
  //   if (!notification.readBy.find((x) => x.readerId === auth.user.id))
  //     dispatch(
  //       readNotificationAction({ notificationId, readerId: auth.user.id })
  //     );
  //   dispatch({
  //     type: CHAT_SELECT_SUCCESS,
  //     payload: chat,
  //   });
  //   openDropDownMessage();
  //   // setShowDropDownMessage(false);
  // };
  // function formatTime(dateTime) {
  //   const timeNow = new Date();
  //   const messageTime = new Date(dateTime);
  //   const secondsDiff = Math.round((timeNow - messageTime) / 1000);
  //   const hoursDiff = Math.round(secondsDiff / 60 / 60);

  //   let time = "";
  //   if (secondsDiff < 60) {
  //     time = "Just Now";
  //   } else {
  //     if (secondsDiff < 600) {
  //       time = Math.round(secondsDiff / 60) + " mins";
  //     } else {
  //       if (hoursDiff > 24) {
  //         time = moment(messageTime).format("ddd, hh:mm a");
  //       } else {
  //         // console.log("messageTIme==>", messageTime);
  //         time = moment(messageTime).format("hh:mm a");
  //       }
  //     }
  //   }
  //   return time;
  // }
  // const [showDropDownMessage, setShowDropDownMessage] = useState(false)
  // const openDropDownMessage = () => {
  //   setShowDropDownMessage((state) => !state);
  // };
  // console.log(users.onlineUsers.length, "onlineUsers");
  const [hoveredItem, setHoveredItem] = useState(null);

  const handleMouseEnter = (index) => {
    setHoveredItem(index);
  };

  // const handleMouseLeave = () => {
  //   setHoveredItem(null);
  // };

  const handleMouseLeave = () => {
    setHoveredItem(hoveredItem); // Show the phone number
    setTimeout(() => {
      setHoveredItem(null); // Hide the phone number after 3 seconds
    }, 500);
  };

  const handleCopyClick = (phoneCheck) => {
    const phoneNumber = phoneCheck;

    // Create a temporary input element
    const inputElement = document.createElement("input");
    inputElement.value = phoneNumber;
    document.body.appendChild(inputElement);

    // Select and copy the text inside the input element
    inputElement.select();
    document.execCommand("copy");

    // Remove the temporary input element
    document.body.removeChild(inputElement);
  };

  let orderIds = [];

  if (
    auth?.user?.permissionId?.booking?.isAllow === true &&
    reservation?.reservation?.today?.length > 0
  ) {
    // Populate the array with orderId values that meet the conditions
    reservation.reservation.today.forEach((current) => {
      if (
        current.driverCancellation.length > 0 &&
        current.status === "Requested" &&
        current.driverBookingStatus === "Unassigned"
      ) {
        orderIds.push(current.orderId);
      }
    });

    // Sort the array in ascending order
    orderIds.sort((a, b) => a - b);
  }
  // chicago time 24 format
  // const chicagoDateOptions = {
  //   timeZone: "America/Chicago",
  //   year: "numeric",
  //   month: "2-digit",
  //   day: "2-digit",
  //   hour: "2-digit",
  //   minute: "2-digit",
  //   second: "2-digit",
  //   hour12: false,
  // };

  // const chicagoTime = new Date("2024-08-23").toLocaleString(
  //   "en-US",
  //   chicagoDateOptions
  // );

  // const [datePart, timePart] = chicagoTime.split(", ");
  // const [month, day, year] = datePart.split("/");
  // const [hour, minute] = timePart.split(":");

  // const formattedChicagoDate = `${year}-${month}-${day}`;
  // const formattedChicagoTime = `${hour}:${minute}`;
  // ********************************************************

  // let orderIds2 = [];

  // if (
  //   auth?.user?.permissionId?.booking?.isAllow === true &&
  //   reservation?.reservation?.today?.length > 0
  // ) {
  //   // Populate the array with orderId values that meet the conditions
  //   reservation.reservation.today.forEach((current) => {
  //     if (current.status === "In Progress") {
  //       orderIds2.push(current.orderId);
  //     }
  //   });

  //   // Sort the array in ascending order
  //   orderIds2.sort((a, b) => a - b);
  // }
  const chicagoDateOptions = {
    timeZone: "America/Chicago",
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    hour12: false, // Use 24-hour clock format
  };

  const chicagoDateTime = new Date().toLocaleString(
    "en-US",
    chicagoDateOptions
  );

  // Split the date and time
  const [datePart, timePart] = chicagoDateTime.split(", ");
  const parts = datePart.split("/");
  const formattedChicagoDate = `${parts[2]}-${parts[0]}-${parts[1]}`;
  // Convert timePart to an integer for hour and minute
  const [currentHour, currentMinute] = timePart.split(":").map(Number);

  // Calculate the cutoff time by subtracting 2 hour
  const cutoffHour = currentHour - 2;
  const cutoffTimeStr = `${cutoffHour
    .toString()
    .padStart(2, "0")}:${currentMinute.toString().padStart(2, "0")}`;

  let orderIds2 = [];

  if (
    auth?.user?.permissionId?.booking?.isAllow === true &&
    reservation?.reservation?.today?.length > 0
  ) {
    reservation.reservation.today.forEach((current) => {
      if (current.status === "In Progress") {
        const dropOffHour = current.dropOffTime;
        if (dropOffHour <= cutoffTimeStr) {
          // Example comparison
          orderIds2.push(current.orderId);
        }
      }
    });

    // Sort the array in ascending order
    orderIds2.sort((a, b) => a - b);
  }
  return (
    <>
      {!notShow && (
        <header className="main-header clearfix bg-white" id="header">
          <div className="navbar-left float-left d-flex align-items-center">
            {auth?.user?.permissionId?.booking?.isAllow === true &&
              reservation?.reservation?.today?.length > 0 &&
              reservation?.reservation?.today?.some(
                (x) =>
                  x?.driverCancellation?.length > 0 &&
                  x?.status === "Requested" &&
                  x?.driverBookingStatus === "Unassigned"
              ) && (
                <div className="page-title d-none d-lg-flex">
                  <div className="page-heading">
                    <span className="text-dark f-12 f-w-500 ml-2">
                      <NavLink to="/today-bookings" className="text-dark">
                        Rejected:
                      </NavLink>{" "}
                    </span>
                  </div>
                </div>
              )}
            <div className="blink">
              {auth?.user?.permissionId?.booking?.isAllow === true &&
                reservation?.reservation?.today?.length > 0 &&
                reservation?.reservation?.today?.map((current, i) => {
                  return (
                    current.driverCancellation.length > 0 &&
                    current.status === "Requested" &&
                    current.driverBookingStatus === "Unassigned" && (
                      <span
                        key={i}
                        // className="blink"
                        style={{
                          borderRadius: "10px",
                          padding: "5px",
                          margin: "12px",
                          boxShadow: "0 1px 10px 0 rgba(255, 0, 0, 0.3)", // Red shadow with 30% opacity
                        }}
                      >
                        &bull; {current?.orderId}
                      </span>
                    )
                  );
                })}
            </div>
            {/* {auth?.user?.permissionId?.booking?.isAllow === true &&
              reservation?.reservation?.today?.length > 0 &&
              reservation?.reservation?.today?.some(
                (x) =>
                  x?.status === "In Progress" &&
                  (parseInt(x.dropOffTime.substring(0, 2)) === 0
                    ? 12
                    : parseInt(x.dropOffTime.substring(0, 2)) > 12
                    ? parseInt(x.dropOffTime.substring(0, 2)) - 12
                    : parseInt(x.dropOffTime.substring(0, 2))) +
                    ":" +
                    x.dropOffTime.substring(3, 5) +
                    (parseInt(x?.dropOffTime.substring(0, 2)) > 11
                      ? "pm"
                      : "am") */}
            {auth?.user?.permissionId?.booking?.isAllow === true &&
              reservation?.reservation?.today?.length > 0 &&
              reservation?.reservation?.today?.some((x) => {
                const dropOffHour = x.dropOffTime;
                return (
                  x?.status === "In Progress" && dropOffHour <= cutoffTimeStr // Example comparison
                );
              }) && (
                <div className="page-title d-none d-lg-flex">
                  <div className="page-heading">
                    <span className="text-dark f-12 f-w-500 ml-2">
                      <NavLink to="/today-bookings" className="text-dark">
                        In Progress:
                      </NavLink>{" "}
                    </span>
                  </div>
                </div>
              )}

            <div className="blink">
              {orderIds2.length > 0 &&
                orderIds2.map((orderId, i) => (
                  <span
                    key={i}
                    style={{
                      borderRadius: "10px",
                      padding: "5px",
                      margin: "12px",
                      boxShadow: "0 1px 10px 0 rgba(255, 0, 0, 0.3)", // Red shadow with 30% opacity
                    }}
                  >
                    &bull; {orderId}
                  </span>
                ))}
            </div>
            <div
              className="d-block d-lg-none menu-collapse cursor-pointer position-relative"
              onClick={() => dispatch({ type: TOGGLE_SIDEBAR })}
            >
              <div className="mc-wrap">
                <div className="mcw-line"></div>
                <div className="mcw-line center"></div>
                <div className="mcw-line"></div>
              </div>
            </div>
          </div>
          <div className="page-header-right float-right d-flex align-items-center">
            <ul>
              {auth?.user?.isDriver === false &&
                users &&
                users?.onlineUsers &&
                users?.onlineUsers?.length > 0 &&
                users?.onlineUsers?.map((item, i) => {
                  return (
                    <li
                      className="circle"
                      style={{
                        backgroundColor: item?.bgColor
                          ? item?.bgColor
                          : "black",
                      }}
                      key={i}
                      onMouseEnter={() => handleMouseEnter(i)}
                      onMouseLeave={handleMouseLeave}
                    >
                      {item?.firstName?.charAt(0)?.toUpperCase()}
                      {hoveredItem === i && (
                        <div
                          className="driver-card-2"
                          // onMouseEnter={handleMouseEnter}
                          // onMouseLeave={handleMouseLeave}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            color: "black",
                          }}
                        >
                          <header>
                            <span
                              style={{ fontSize: "1.2rem", fontWeight: "bold" }}
                            >
                              {item?.firstName} {item?.lastName}
                            </span>
                            <br />
                            <span
                              style={{ fontSize: "0.9rem", color: "#A0A0A0" }}
                            >
                              {item?.phone ? item?.phone : "-"}
                              <i
                                className="fa fa-copy"
                                style={{ cursor: "pointer", marginLeft: "5px" }}
                                onClick={handleCopyClick(
                                  item?.phone ? item?.phone : "-"
                                )}
                              ></i>
                            </span>
                          </header>
                        </div>
                      )}
                    </li>
                  );
                })}
              {auth?.user?.isDriver === true && (
                <>
                  <li>
                    <NavLink
                      to="/earnings"
                      className="notification_box dropdown"
                    >
                      <strong style={{ color: "#000000" }}>
                        Weekly Earning:{" "}
                        <span style={{ color: "darkgreen" }}>
                          $
                          {reservation?.driverEarning?.weeklyEarning?.earning
                            ? reservation?.driverEarning?.weeklyEarning?.earning
                            : 0.0}
                        </span>{" "}
                        | &nbsp;
                      </strong>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/earnings"
                      className="notification_box dropdown"
                    >
                      <strong style={{ color: "#000000" }}>
                        Daily Earning:{" "}
                        <span style={{ color: "darkgreen" }}>
                          $
                          {reservation?.driverEarning?.dailyEarning?.earning
                            ? reservation?.driverEarning?.dailyEarning?.earning.toFixed(
                                2
                              )
                            : 0.0}
                        </span>{" "}
                        | &nbsp;{" "}
                      </strong>
                    </NavLink>
                  </li>
                </>
              )}
              {auth?.user?.isExternalCompany === false && (
                <li
                  style={{
                    marginTop: "0.4rem",
                  }}
                >
                  <div className="notification_box dropdown">
                    <Link
                      className="d-block dropdown-toggle header-icon-box show-user-notifications"
                      type="link"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-chat-square-text-fill f-16 text-dark-grey"
                        viewBox="0 0 16 16"
                      >
                        <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2h-2.5a1 1 0 0 0-.8.4l-1.9 2.533a1 1 0 0 1-1.6 0L5.3 12.4a1 1 0 0 0-.8-.4H2a2 2 0 0 1-2-2V2zm3.5 1a.5.5 0 0 0 0 1h9a.5.5 0 0 0 0-1h-9zm0 2.5a.5.5 0 0 0 0 1h9a.5.5 0 0 0 0-1h-9zm0 2.5a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5z" />
                      </svg>
                      {/* <span className="badge badge-pill custom-badge" >{3}
                    </span> */}
                      {/* for mail system below code */}
                      {/* {
                      mail.mailData &&
                      mail.mailData.length > 0 &&
                      mail.mailData.map((item,i)=>{
                        item.messages[item.messages.length-1].view.forEach(e => {
                          if(e.receiver == auth.user.id && e.messageSeen === false){
                            j+=1
                          }
                        });
                        return (
                          i === (mail.mailData.length-1) &&
                          <>
                            {
                              j === 0 ?
                              <></>
                              :
                              <span className="badge badge-pill custom-badge" key={i}>{j}
                              </span>
                            }
                          </>
                          )
                        }
                      )
                    } */}
                      <span className="badge badge-pill custom-badge">
                        {notifications?.list?.length > 0 &&
                          notifications?.list?.length -
                            notifications?.list?.filter((x) =>
                              x.readBy.find((y) => y.readerId == auth.user.id)
                            ).length}
                      </span>
                    </Link>
                    {/* for mail system below code */}
                    {/* <MessageDropDown msg={msg}/> */}
                    <ChatMessageDropDown />
                  </div>
                </li>
              )}
              {auth?.user?.isExternalCompany === false && (
                <li
                  style={{
                    marginTop: "0.4rem",
                  }}
                >
                  <div className="notification_box dropdown">
                    <Link
                      className="d-block dropdown-toggle header-icon-box show-user-notifications"
                      type="link"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <i className="fa fa-bell f-16 text-dark-grey"></i>
                    </Link>
                    <NotificationDropDown data={data} />
                  </div>
                </li>
              )}
              {/* <li>
              <div className="d-flex align-items-center">
                <Link className="d-block header-icon-box open-search">
                  <i className="fa fa-search f-16 text-dark-grey"></i>
                </Link>
              </div>
            </li> */}
              {/* <li>
              <div className="notification_box dropdown">
                <button
                  className="d-block dropdown-toggle header-icon-box show-user-notifications bg-button"
                  type="link"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                  onClick={openDropDown}
                >
                  <i className="fa fa-bell f-16 text-dark-grey"></i>
                </button>
              </div>
              <HeaderNotificationModel showDropDown={showDropDown} data={data}/>
            </li> */}
              <li
                style={{
                  marginTop: "0.4rem",
                }}
              >
                <div className="add_box dropdown">
                  <Link
                    // className="d-block dropdown-toggle bg-gray"
                    className="d-block dropdown-toggle "
                    type="link"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <i className="fa fa-user f-20 text-dark-grey"></i>
                    {/* <img
                    src="https://cdn-icons-png.flaticon.com/512/1077/1077114.png"
                    alt=""
                    className="mr-2 taskEmployeeImg rounded-circle"
                  /> */}
                  </Link>
                  {/* <!-- DROPDOWN - INFORMATION --> */}
                  <div
                    className="dropdown-menu dropdown-menu-right"
                    aria-labelledby="dropdownMenuLink"
                    tabIndex="0"
                    x-placement="bottom-end"
                    style={{
                      position: "absolute",
                      willChange: "transform",
                      top: "0px",
                      left: "0px",
                      transform: "translate3d(38px, 30px, 0px)",
                    }}
                  >
                    {
                      // (auth.user?.jobTitle === "Driver" || auth.user?.jobTitle === "Dispatcher") && (
                      <>
                        {" "}
                        {auth?.user?.isExternalCompany === false && (
                          <>
                            <div className="d-flex px-3 justify-content-between align-items-center border-bottom-grey py-1 bg-white">
                              <div className="___class_+?50___">
                                {}
                                <p
                                  className="mb-0 text-dark f-w-500"
                                  style={{ fontSize: "20px" }}
                                >
                                  {auth?.user?.permissionId?.name}
                                </p>
                              </div>
                            </div>
                            <div id="notification-list"></div>
                          </>
                        )}
                        <Link
                          className="dropdown-item f-16 text-dark openRightModal"
                          to="/my-profile"
                        >
                          <i className="bi bi-person-bounding-box f-w-500 mr-2 f-14"></i>
                          Profile
                        </Link>
                        <Link
                          className="dropdown-item f-16 text-dark openRightModal"
                          to="/change-password"
                        >
                          <i className="bi bi-gear-wide-connected f-w-500 mr-2 f-14"></i>
                          Change Password
                        </Link>
                      </>
                      // )
                    }
                    <Link
                      className="dropdown-item f-16 text-dark openRightModal"
                      to=""
                      onClick={logout}
                    >
                      <i className="side-icon bi bi-power f-w-500 mr-2 f-14"></i>
                      Logout
                    </Link>
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <audio src={music} ref={notificationRef}></audio>
        </header>
      )}
    </>
  );
};
export default AdminHeader;

// {
//   auth?.user?.isExternalCompany === false &&
//   <>
//   <NavLink
//     to="#"
//     className="dropdown-toggle nav-link"
//     data-toggle="dropdown"
//     onClick={openDropDownMessage}
//   >
//     <i className="fa fa-comment-o"></i>
//     {notifications.list.length > 0 && (
//       <span className="badge badge-pill">
//         {notifications.list.length -
//           notifications.list.filter((x) =>
//             x.readBy.find((y) => y.readerId == auth.user.id)
//           ).length}
//       </span>
//     )}
//   </NavLink>
//   <div
//     className={`dropdown-menu-message dropdown-menu notifications ${
//       showDropDownMessage ? "show" : ".hide"
//     } `}
//   >
//     <div className="topnav-dropdown-header">
//       <span className="notification-title">Notifications</span>
//       {/* abhi set krna hai */}
//       {/* <Link to="" className="clear-noti"> Clear All </Link> */}
//     </div>
//     <div className="noti-content">
//       <ul className="notification-list">
//         {notifications.loading ? (
//           <div id="loader-wrapper">
//             <div id="loader">
//               <div className="loader-ellips">
//                 <span className="loader-ellips__dot"></span>
//                 <span className="loader-ellips__dot"></span>
//                 <span className="loader-ellips__dot"></span>
//                 <span className="loader-ellips__dot"></span>
//               </div>
//             </div>
//           </div>
//         ) : (
//           notifications.list.length === 0 && (
//             <p className="text-center my-5">
//               You don't have any notifications yet!{" "}
//             </p>
//           )
//         )}
//         {notifications.list
//           // .filter((x) => x.sender.id !== auth.user.id)
//           .sort((a, b) => moment(a.dateTime).isBefore(b.dateTime))
//           .map((item, i) => {
//             // console.log(item.id)
//             arr = [];
//             return (
//               <li
//                 className={`notification-message ${
//                   item.readBy.find((x) => x.readerId === auth.user.id)
//                     ? ""
//                     : "bg-gray"
//                 }`}
//                 key={i}
//               >
//                 {/* <a > */}
//                 <Link
//                   to={`/chat`}
//                   onClick={() => clickHandler(item.id, item.chat)}
//                 >
//                   <div className="list-item">
//                     <div className="list-left">
//                       <span className="avatar">
//                         <img
//                           alt="Profile Picture"
//                           src={
//                             item.chat.isGroup
//                               ? "/undraw_People_re_8spw.png"
//                               : item.sender.profilePic
//                               ? item.sender.profilePic.fileUrl
//                               : defaultImg
//                           }
//                           style={{ objectFit: "fill", height: "100%" }}
//                         />
//                       </span>
//                     </div>
//                     <div className="list-body">
//                       {/* { */}
//                       <div className="message-author">
//                         <span className="title">
//                           {item.chat.isGroup ? (
//                             <>
//                               {/* <b>
//                               {item.sender.firstName} {item.sender.lastName}
//                             </b> */}
//                               New message in <b>{item.chat.chatName}</b>
//                             </>
//                           ) : (
//                             <>
//                               <b>
//                                 {item.sender.firstName} {item.sender.lastName}
//                               </b>{" "}
//                               sent you a message
//                             </>
//                           )}
//                         </span>
//                         {/* } */}
//                         <span className="message-time">
//                           {formatTime(item.chat.latestMessage.dateTime)}
//                           {/* {moment().format("DD-MM-YYYY") ===
//                       moment(item.chat.latestMessage.dateTime).format(
//                         "DD-MM-YYYY"
//                       )
//                         ? moment(item.chat.latestMessage.dateTime).format(
//                             "LT"
//                           )
//                         : moment(item.chat.latestMessage.dateTime).format(
//                             "DD MMM"
//                           )} */}
//                         </span>
//                       </div>
//                       <div className="clearfix"></div>
//                       <span className="message-content">
//                         {item.chat.isGroup && (
//                           <>
//                             <b>
//                               {item.sender.firstName} {item.sender.lastName}:{" "}
//                             </b>
//                           </>
//                         )}
//                         {item.chat.latestMessage.message}
//                       </span>
//                     </div>
//                   </div>
//                 </Link>
//                 {/* </a> */}
//               </li>
//             );
//           })}
//       </ul>
//     </div>
//     {/* <div className="topnav-dropdown-footer">
//       <Link to="/inbox">View all Messages</Link>
//     </div> */}
//   </div>
//   </>
// }
