/** @format */

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Accordion from "react-bootstrap/Accordion";
import { useLocation } from "react-router-dom";
import Select from "react-select";
import {
  getAllStudents,
  getRoutePassengersByCompanyId,
} from "../../../actions/userActions";
import AddressSearchInput from "../../../components/AddressSearchInput";
// import AdminBookingAddClientModal from "../../../components/adminComponents/adminBookingComponents/AdminBookingAddClientModal";
// import AddStudentModal from "./AddStudentModal";
import PassengerRouteAccordionItem from "./PassengerRouteAccordionItem";
import AddStudentModalRoute from "./AddStudentModalRoute";

function PassengerRouteAccordionComponent({
  values,
  setValues,
  // handleChange,
  customerDetailsArr,
  setCustomerDetailsArr,
  customerDetailsObj,
  dropObj,
  // openDropDown
}) {
  const dispatch = useDispatch();
  const location = useLocation();

  const auth = useSelector((state) => state.auth);
  const kpi = useSelector((state) => state.kpi);
  const students = useSelector((state) => state.students);

  const [searchingClients, setSearchingClients] = useState(false);
  const [oldName, setOldName] = useState("");

  const [showDropDown, setshowDropDown] = useState(false);
  const [selectedStudent, setSelectedStudent] = useState({});

  const openDropDown = () => {
    setshowDropDown((showDropDown) => !showDropDown);
  };
  // const [passengerRouteArr, setPassengerRouteArr] = useState([customerDetailsObj]);

  const addPassengerRoute = () => {
    setCustomerDetailsArr([...customerDetailsArr, customerDetailsObj]);
  };

  const removePassengerRoute = (index) => {
    setCustomerDetailsArr(customerDetailsArr.filter((x, i) => i !== index));
  };

  const addShiftAM = (index) => {
    setCustomerDetailsArr(
      customerDetailsArr.map((x, i) =>
        i === index
          ? {
              ...x,
              shiftAM: true,
            }
          : x
      )
    );
  };

  const addShiftPM = (index) => {
    setCustomerDetailsArr(
      customerDetailsArr.map((x, i) =>
        i === index
          ? {
              ...x,
              shiftPM: true,
            }
          : x
      )
    );
  };

  const addShiftMID = (index) => {
    setCustomerDetailsArr(
      customerDetailsArr.map((x, i) =>
        i === index
          ? {
              ...x,

              shiftMID: true,
            }
          : x
      )
    );
  };

  const removeReturnRouteAM = (index) => {
    setCustomerDetailsArr(
      customerDetailsArr.map((x, i) =>
        i === index
          ? {
              ...x,
              shiftAM: undefined,
            }
          : x
      )
    );
  };

  const removeReturnRoutePM = (index) => {
    setCustomerDetailsArr(
      customerDetailsArr.map((x, i) =>
        i === index
          ? {
              ...x,
              shiftPM: undefined,
            }
          : x
      )
    );
  };

  const removeReturnRouteMID = (index) => {
    setCustomerDetailsArr(
      customerDetailsArr.map((x, i) =>
        i === index
          ? {
              ...x,
              shiftMID: undefined,
            }
          : x
      )
    );
  };

  // const handleAddress = (addressObj, index, field) => {
  //   setCustomerDetailsArr(
  //     customerDetailsArr.map((x, i) =>
  //       i == index ? { ...x, [field]: addressObj } : x
  //     )
  //   );
  // };

  // const handleChange = (e, index) => {
  //   setCustomerDetailsArr(
  //     customerDetailsArr.map((arrItem, itemIndex) =>
  //       index == itemIndex
  //         ? { ...arrItem, [e.target.name]: e.target.value }
  //         : arrItem
  //     )
  //   );
  // };

  useEffect(() => {
    // dispatch(
    //   getAllStudents({
    //     companyId: auth.user.companyId.id,
    //   })
    // );
    dispatch(
      getRoutePassengersByCompanyId({
        companyId: auth.user.companyId.id,
      })
    );
  }, []);

  useEffect(() => {
    if (selectedStudent && selectedStudent.studentId) {
      setCustomerDetailsArr(
        customerDetailsArr.map((x, i) =>
          i === selectedStudent.index
            ? { ...x, customerId: selectedStudent.studentId }
            : x
        )
      );
    }
  }, [selectedStudent]);

  return (
    <>
      <Accordion defaultActiveKey="0">
        {customerDetailsArr.map((customerRoute, index) => (
          <Accordion.Item eventKey={index} key={index}>
            <PassengerRouteAccordionItem
              {...{
                values,
                setValues,
                customerRoute,
                index,
                removePassengerRoute,
                addShiftAM,
                addShiftMID,
                addShiftPM,
                removeReturnRoutePM,
                removeReturnRouteAM,
                removeReturnRouteMID,
                setSelectedStudent,
                openDropDown,
                customerDetailsArr,
                setCustomerDetailsArr,
              }}
            />
          </Accordion.Item>
        ))}

        <div className="row text-right">
          <div className="col-12 col-md-4 ml-auto pl-4">
            <div className="form-group my-2">
              {true ? (
                <button
                  type="button"
                  className="btn-lightest-grey rounded f-12 p-2 mr-3 "
                  onClick={addPassengerRoute}
                >
                  Add Another
                </button>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </Accordion>
      {showDropDown && (
        <AddStudentModalRoute
          showDropDown={showDropDown}
          openDropDown={openDropDown}
          setSelectedStudent={setSelectedStudent}
          // values={values}
          // setValues={setValues}
        />
      )}
    </>
  );
}
export default PassengerRouteAccordionComponent;
