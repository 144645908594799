/** @format */

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateRouteById } from "../../../actions/routeActions";
import {
  getAllStudents,
  getRoutePassengersByCompanyId,
} from "../../../actions/userActions";

const ArrangeOrderModalV2 = ({
  showModal,
  setShowModal,
  selectedRoute,
  setSelectedRoute,

  customerDetailsArr,
  setCustomerDetailsArr,
  ridePattern,
  setRidePattern,
  // passer,
  // passengerRouteArr,
  // setPassengerRouteArr,

  // customerDetailsArr,
  // setCustomerDetailsArr,
  // orignalRidePattern,
  // setOrignalRidePattern,
  // ridePattern,
  // setRidePattern,

  setIsRidePatternArranged,
}) => {
  const dispatch = useDispatch();
  const auth = useSelector((x) => x.auth);
  const students = useSelector((x) => x.students);

  const [passengerRouteArr, setPassengerRouteArr] = useState([]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    // setPassengerRouteArr(orderArr);

    // event.preventDefault();
    setIsRidePatternArranged(true);

    setShowModal(false);
    // dispatch(
    //   updateRouteById(
    //     { _id: selectedRoute.id, ridePattern: ridePattern },
    //     successHandler
    //   )
    // );
  };

  const successHandler = () => {
    setShowModal(false);
  };

  // useEffect(() => {
  //   let tempRidePatternArr = [];
  //   if (selectedRoute)
  //     if (selectedRoute?.ridePattern?.length == 0) {
  //       for (let i = 0; i < selectedRoute.customerDetails.length; i++) {
  //         tempRidePatternArr.push({
  //           ...selectedRoute.customerDetails[i],
  //           routeType: "Pick",
  //         });
  //         tempRidePatternArr.push({
  //           ...selectedRoute.customerDetails[i],
  //           routeType: "Drop",
  //         });
  //       }
  //     }

  //   setRidePattern(
  //     selectedRoute?.ridePattern?.length > 0
  //       ? selectedRoute.ridePattern
  //       : tempRidePatternArr
  //   );
  // }, [selectedRoute]);

  // useEffect(() => {
  //   dispatch(
  //     getAllStudents({
  //       companyId: auth.user.companyId.id,
  //     })
  //   );
  // }, []);

  // const handleSubmit = async (event) => {
  //   event.preventDefault();
  //   // setPassengerRouteArr(orderArr);

  //   dispatch(
  //     updateRouteById(
  //       { _id: selectedRoute.id, ridePattern: ridePattern },
  //       successHandler
  //     )
  //   );
  // };

  // const successHandler = () => {
  //   setShowModal(false);
  // };

  const swapRidePattern = (index1, index2) => {
    setRidePattern((array) => {
      let data = [...array];
      let temp = data[index1];
      data[index1] = data[index2];
      data[index2] = temp;
      return data;
    });
  };
  // console.log(ridePattern, "ridePattern1");
  // useEffect(() => {
  //   let tempRidePatternArr = [];
  //   if (selectedRoute)
  //     if (selectedRoute?.ridePattern?.length == 0) {
  //       for (let i = 0; i < selectedRoute.customerDetails.length; i++) {
  //         tempRidePatternArr.push({
  //           ...selectedRoute.customerDetails[i],
  //           routeType: "Pick",
  //         });
  //         tempRidePatternArr.push({
  //           ...selectedRoute.customerDetails[i],
  //           routeType: "Drop",
  //         });
  //       }
  //     }

  //     setRidePattern(
  //     selectedRoute?.ridePattern?.length > 0
  //       ? selectedRoute.ridePattern
  //       : tempRidePatternArr
  //   );
  // }, [selectedRoute]);

  useEffect(() => {
    // dispatch(
    //   getAllStudents({
    //     companyId: auth.user.companyId.id,
    //   })
    // );
    dispatch(
      getRoutePassengersByCompanyId({
        companyId: auth.user.companyId.id,
      })
    );
  }, []);

  // console.log("selected route==>", selectedRoute);

  useEffect(() => {
    if (
      ridePattern.length == 0 ||
      ridePattern.length !== customerDetailsArr.length * 2
      // ||
      // true
    ) {
      let tempRidePatternArr = [];

      // // tempRidePatternArr.reverse();

      // for (let i = 0; i < customerDetailsArr.length; i++) {
      //   tempRidePatternArr.push({ ...customerDetailsArr[i], routeType: "Drop" });
      //   tempRidePatternArr.push({ ...customerDetailsArr[i], routeType: "Pick" });
      // }

      for (let i = 0; i < customerDetailsArr.length; i++) {
        tempRidePatternArr.push({
          customerId: customerDetailsArr[i].customerId?.id,
          address: customerDetailsArr[i].dropOffAddress,
          time: customerDetailsArr[i].dropOffTime,
          customerDetails: customerDetailsArr[i],
          routeType: "Drop",
        });

        tempRidePatternArr.push({
          customerId: customerDetailsArr[i].customerId?.id,
          address: customerDetailsArr[i].pickUpAddress,
          time: customerDetailsArr[i].pickUpTime,
          customerDetails: customerDetailsArr[i],

          routeType: "Pick",
        });
      }

      tempRidePatternArr.reverse();
      tempRidePatternArr.sort((a, b) => b.routeType.localeCompare(a.routeType));
      setRidePattern(tempRidePatternArr);
    }
  }, [customerDetailsArr]);

  useEffect(() => {
    setIsRidePatternArranged(true);
  }, []);

  // console.log("selected route==>", selectedRoute);
  // console.log("ride patt==>", ridePattern);

  return (
    <>
      <div
        className={`modal fade ${showModal ? "show modal-y-axis" : "hide"}`}
        role="dialog"
        data-backdrop="static"
        data-keyboard="false" // To prevent closing with the keyboard
        onClick={(e) => {
          // Check if the click occurred outside the modal content
          if (e.target === e.currentTarget) {
            setShowModal(false); // Call your close modal function
          }
        }}
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLongTitle">
                {" "}
                Ride Pattern
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                // onClick={() => openDropDown()}
                onClick={() => setShowModal(false)}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body ">
              <div className="row">
                <div className="col-12 col-md-4">
                  <h6>Students List</h6>
                  <ul className="">
                    {customerDetailsArr?.map((x, i) => {
                      // const studentFound = students?.routePassengerList?.find(
                      //   (customer) => customer.id == x.customerId
                      // );
                      return (
                        <li className=" bg-grey rounded p-2 my-1">
                          {`${i + 1} ) 
                          ${`${x.customerId.firstName} ${x.customerId.lastName}`}
                          `}
                        </li>
                      );
                    })}
                  </ul>
                </div>
                <div className="col-12 col-md-8">
                  <div className="row align-items-center">
                    <div className="col-12 ">
                      <h6>Ride Pattern</h6>
                    </div>
                  </div>
                  <div className="row pt-2">
                    {ridePattern.map((x, i) => {
                      const studentFound = students?.routePassengerList?.find(
                        (customer) => customer.id === x.customerId
                      );
                      return (
                        <div className="col-12 mb-1">
                          <div className="bg-grey p-1 d-flex justify-content-between align-items-center">
                            <p className="m-0">
                              {`${i + 1}) ${x.routeType} ${
                                // x.student
                                studentFound
                                  ? `${studentFound.firstName} ${
                                      studentFound.lastName
                                        ? studentFound.lastName
                                        : ""
                                    }`
                                  : "Student not selected"
                              }
                        `}
                            </p>

                            <div>
                              {i !== 0 ? (
                                <>
                                  {x.routeType == "Drop" ? (
                                    i > ridePattern.length / 2 &&
                                    ridePattern
                                      // .slice(0, i - 1)
                                      .slice(i, ridePattern.length)

                                      .find(
                                        (z) => z.routeType !== "Pick"
                                        // &&
                                        // z.customerId == x.customerId
                                      ) ? (
                                      <button
                                        className="btn btn-md"
                                        onClick={() =>
                                          swapRidePattern(i, i - 1)
                                        }
                                      >
                                        <i className="bi bi-chevron-up text-primary"></i>
                                      </button>
                                    ) : (
                                      <button className="btn btn-md" disabled>
                                        <i className="bi bi-chevron-up"></i>
                                      </button>
                                    )
                                  ) : ridePattern
                                      .slice(i, ridePattern.length)
                                      .find(
                                        (z) =>
                                          z.routeType === "Drop" &&
                                          z.customerId === x.customerId
                                      ) ? (
                                    <button
                                      className="btn btn-md"
                                      onClick={() => swapRidePattern(i, i - 1)}
                                    >
                                      <i className="bi bi-chevron-up text-primary"></i>
                                    </button>
                                  ) : (
                                    <button className="btn btn-md" disabled>
                                      <i className="bi bi-chevron-up"></i>
                                    </button>
                                  )}
                                </>
                              ) : (
                                <button className="btn btn-md" disabled>
                                  <i className="bi bi-chevron-up"></i>
                                </button>
                              )}

                              {i !== ridePattern.length - 1 ? (
                                <>
                                  {x.routeType === "Drop" ? (
                                    ridePattern
                                      .slice(0, i + 1)
                                      .find(
                                        (z) =>
                                          z.routeType === "Pick" &&
                                          z.customerId === x.customerId
                                      ) ? (
                                      <button
                                        className="btn btn-md"
                                        onClick={() =>
                                          swapRidePattern(i, i + 1)
                                        }
                                      >
                                        <i className="bi bi-chevron-down text-primary"></i>
                                      </button>
                                    ) : (
                                      <button className="btn btn-md" disabled>
                                        <i className="bi bi-chevron-down"></i>
                                      </button>
                                    )
                                  ) : i < ridePattern.length / 2 - 1 &&
                                    ridePattern
                                      // .slice(i + 2, ridePattern.length)
                                      .slice(0, i - 1)
                                      .find(
                                        (z) => z.routeType !== "Drop"
                                        // &&
                                        // z.customerId == x.customerId
                                      ) ? (
                                    <button
                                      className="btn btn-md"
                                      onClick={() => swapRidePattern(i, i + 1)}
                                    >
                                      <i className="bi bi-chevron-down text-primary"></i>
                                    </button>
                                  ) : (
                                    <button className="btn btn-md" disabled>
                                      <i className="bi bi-chevron-down"></i>
                                    </button>
                                  )}
                                </>
                              ) : (
                                <button className="btn btn-md" disabled>
                                  <i className="bi bi-chevron-down"></i>
                                </button>
                              )}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn-secondary rounded f-14 p-2 mr-3"
                data-dismiss="modal"
                onClick={() => setShowModal(false)}
              >
                Close
              </button>
              <button
                type="sunmit"
                className="btn-primary rounded f-14 p-2 mr-3"
                onClick={handleSubmit}
              >
                Update
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default ArrangeOrderModalV2;
