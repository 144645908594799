import React from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";

const borderColor = "#000";
const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    borderBottomColor: borderColor,
    // color: "#fff",
    borderBottomWidth: 1,
    alignItems: "center",
    // height: 24,
    textAlign: "center",
    fontStyle: "bold",
    flexGrow: 1,
    fontSize: 10,
    // backgroundColor: "#D12860",
  },
  no: {
    width: "10%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    padding: 4,
    textAlign: "left",
    fontSize: 10,
  },
  stop: {
    width: "10%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    padding: 4,
    fontSize: 10,
    textAlign: "left",
  },
  address: {
    width: "50%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    padding: 4,
    textAlign: "left",
    fontSize: 10,
  },
  date: {
    width: "15%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    padding: 4,
    textAlign: "left",
    fontSize: 10,
  },
  time: {
    width: "15%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    padding: 4,
    textAlign: "left",
    fontSize: 10,
  },
});

const InvoiceTableHeader = () => (
  <View style={styles.container}>
    <Text style={styles.no}>No.</Text>
    <Text style={styles.stop}>Stop</Text>
    <Text style={styles.address}>Address</Text>
    <Text style={styles.date}>Date</Text>
    <Text style={styles.time}>Time</Text>
  </View>
);

export default InvoiceTableHeader;
