/** @format */

import React, { useEffect, useState } from "react";
import { InfoWindowF, Marker } from "@react-google-maps/api";
import axios from "axios";
import { SERVERADDRESS } from "../constants";

const SendDriverLocation = () => {
  const [arr, setArr] = useState([]);
  const usersData = async () => {
    const { data } = await axios.post(
      `${SERVERADDRESS}/v1/track/getTracksByCompanyId`,
      { companyId: "63cb14f53f55723694dbd185" }
    );
    setArr(data);
    // console.log(data, "datadata")
  };
  useEffect(() => {
    usersData();
  }, []);
  // useEffect(() => {
  //     const fetchData = async (asset) => {
  //       await axios.post(
  //         `https://api.nextbillion.io/skynet/asset/${asset.driverId.id}/track?key=396f04858df34fa384b0b75f2c3b7a58`,
  //         {
  //             "locations":[
  //                 {
  //                 "location":{
  //                     "lat": asset.latitude += 0.00022,
  //                     "lon": asset.longitude += 0.00022
  //                 },
  //                 "timestamp": Date.now(),
  //                 "accuracy":2,
  //                 "speed":5,
  //                 "bearing":25,
  //                 "altitude":110.5,
  //                 "meta_data":{
  //                     "test": "sample_location_1"
  //                 }
  //             }
  //         ],
  //         "device_id":asset.nextBillionDeviceId
  //         }
  //     )
  //     };

  //     const intervalId = setInterval(async () => {
  //     //   const arr = [/* Your array of assets */];
  //       for (const asset of arr) {
  //         await fetchData(asset);
  //       }
  //     }, 1000);

  //     // Clean up the interval when the component unmounts
  //     return () => clearInterval(intervalId);
  //   }, [arr]); // Empty dependency array ensures that the effect runs once on mount
  return (
    <>
      <div>This page is changing driver position dynamically</div>
    </>
  );
};

export default SendDriverLocation;
