/** @format */

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getRouteDistanceReport,
  resetRouteDistanceReport,
} from "../../../actions/kpiActions";
import shift1 from "../../../assets/images/load.png";
import ExcelExport from "../../../ExcelExport";
import moment from "moment";
import { NavLink } from "react-router-dom";
import {
  tabAideReport,
  tabFullReport,
  tabWithoutAideReport,
} from "../../../actions/tabActions";
// import UpdatingModal from "../../UpdatingModal";
import { ProgressBar } from "react-bootstrap";

const AdminRouteDistanceReport = () => {
  const auth = useSelector((state) => state.auth);
  const reservation = useSelector((state) => state.reservation);
  const kpi = useSelector((state) => state.kpi);
  const { loading } = kpi;
  const dispatch = useDispatch();
  const myState = useSelector((state) => state.changeTheTab);
  const [report, setReport] = useState({
    aide: "FULL",
  });

  const handleClick = async (event) => {
    event.preventDefault();
    // for (let key of Object.keys(orderSearch)) {
    //   if (orderSearch[key] === "") {
    //     delete orderSearch[key];
    //   }
    // }
    // if (orderSearch.status === "") {
    //   delete orderSearch.status;
    //   delete dateData.status;
    // }
    dispatch(getRouteDistanceReport(report));
  };
  // console.log("myState--->", myState);
  // console.log("report--->", report);
  const data = kpi?.getRouteDistanceReport?.map((item) => ({
    "Route No.": item?.routeNo,
    "Shift Time": item?.shiftTime,
    "Pick Up Address": item?.pickUpAddress,
    "Drop Off Address": item?.dropOffAddress,
    "Min Distance": item?.min,
    "Max Distance": item?.max,
    // "Avg Distance of all the Routes By Google": item?.avg,
  }));
  const [progress, setProgress] = useState(20);
  useEffect(() => {
    const updateProgress = () => {
      if (loading) {
        // Increase progress randomly or in a specific pattern
        const newProgress = Math.min(progress + Math.random() * 10, 90);
        setProgress(newProgress);
      }
    };

    const intervalId = setInterval(updateProgress, 500); // Update progress every 500 milliseconds

    return () => {
      clearInterval(intervalId); // Cleanup the interval on component unmount
    };
  }, [progress, loading]);
  useEffect(() => {
    dispatch(resetRouteDistanceReport());
  }, []);
  return (
    <>
      {/* {loading && <UpdatingModal />} */}
      {/* {loading && <UpdatingModal />} */}
      {/* <div className="d-flex flex-column w-tables rounded mt-3 bg-white"> */}
      <div className="row">
        <div className="col-sm-2"></div>
        <div className="col-sm-7 mt-3">
          {myState.viewAide === "viewFullReport" ? (
            <div className="row align-items-center">
              <div className="col">
                <div className="mitbsnav">
                  <ul className="nav nav-pills nav-justified">
                    <li className="nav-item active">
                      <NavLink
                        to="/report"
                        onClick={() => {
                          dispatch(tabFullReport());
                          dispatch(resetRouteDistanceReport());
                          setProgress(20);
                          setReport({
                            aide: "FULL",
                          });
                        }}
                        className=""
                      >
                        Full Report
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink
                        to="/route-distance-report"
                        onClick={() => {
                          dispatch(tabAideReport());
                          dispatch(resetRouteDistanceReport());
                          setProgress(20);
                          setReport({
                            aide: "AIDE",
                          });
                        }}
                        className=""
                      >
                        AIDE Mileage: Route Report
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink
                        to="/report"
                        onClick={() => {
                          dispatch(tabWithoutAideReport());
                          dispatch(resetRouteDistanceReport());
                          setProgress(20);
                          setReport({
                            aide: "WITHOUT-AIDE",
                          });
                        }}
                        className=""
                      >
                        Without AIDE Mileage
                      </NavLink>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          ) : myState.viewAide === "viewAideReport" ? (
            <div className="row align-items-center">
              <div className="col">
                <div className="mitbsnav">
                  <ul className="nav nav-pills nav-justified">
                    <li className="nav-item">
                      <NavLink
                        to="/route-distance-report"
                        onClick={() => {
                          dispatch(tabFullReport());
                          dispatch(resetRouteDistanceReport());
                          setProgress(20);
                          setReport({
                            aide: "FULL",
                          });
                        }}
                        className=""
                      >
                        Full Report
                      </NavLink>
                    </li>
                    <li className="nav-item active">
                      <NavLink
                        to="/report"
                        onClick={() => {
                          dispatch(tabAideReport());
                          dispatch(resetRouteDistanceReport());
                          setProgress(20);
                          setReport({
                            aide: "AIDE",
                          });
                        }}
                        className=""
                      >
                        AIDE Mileage: Route Report
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink
                        to="/report"
                        onClick={() => {
                          dispatch(tabWithoutAideReport());
                          dispatch(resetRouteDistanceReport());
                          setProgress(20);
                          setReport({
                            aide: "WITHOUT-AIDE",
                          });
                        }}
                        className=""
                      >
                        Without AIDE Mileage
                      </NavLink>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          ) : myState.viewAide === "viewWithoutAideReport" ? (
            <div className="row align-items-center">
              <div className="col">
                <div className="mitbsnav">
                  <ul className="nav nav-pills nav-justified">
                    <li className="nav-item">
                      <NavLink
                        to="/report"
                        onClick={() => {
                          dispatch(tabFullReport());
                          dispatch(resetRouteDistanceReport());
                          setProgress(20);
                          setReport({
                            aide: "FULL",
                          });
                        }}
                        className=""
                      >
                        Full Report
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink
                        to="/report"
                        onClick={() => {
                          dispatch(tabAideReport());
                          dispatch(resetRouteDistanceReport());
                          setProgress(20);
                          setReport({
                            aide: "AIDE",
                          });
                        }}
                        className=""
                      >
                        AIDE Mileage: Route Report
                      </NavLink>
                    </li>
                    <li className="nav-item active">
                      <NavLink
                        to="/report"
                        onClick={() => {
                          dispatch(tabWithoutAideReport());
                          dispatch(resetRouteDistanceReport());
                          setProgress(20);
                          setReport({
                            aide: "WITHOUT-AIDE",
                          });
                        }}
                        className=""
                      >
                        Without AIDE Mileage
                      </NavLink>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}
          {/* <div className="row align-items-center">
                        <div className="col">
                            <div className="mitbsnav">
                                <ul className="nav nav-pills nav-justified">
                                <li className="nav-item active">
                                    <NavLink to="/report" 
                                    onClick={() => dispatch(tabFullReport())} 
                                    className="">Full Report</NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink to="/report" 
                                    onClick={() => dispatch(tabAideReport())} 
                                    className="">AIDE Mileage: Route Report</NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink to="/report" 
                                    onClick={() => dispatch(tabWithoutAideReport())}
                                    className="">Without AIDE Mileage</NavLink>
                                </li>
                                </ul>
                            </div>
                        </div>
                    </div> */}
        </div>

        <div className="col-sm-2 mt-3">
          <div className="d-grid">
            <button
              className="btn-primary rounded f-14 p-2 mr-3"
              onClick={handleClick}
            >
              Generate
            </button>
          </div>
          {/* <br /> */}
          {loading && (
            <ProgressBar
              style={{ marginTop: "0.5px" }}
              now={progress}
              min={0}
              max={100}
              variant="success"
              animated={true}
              striped={true}
            />
          )}
        </div>
        {kpi.getRouteDistanceReport &&
          kpi.getRouteDistanceReport.length > 0 && (
            <div className="flex-grow-1">
              <div className="dataTables_length d-flex" id="leads-table_length">
                <div className="text-right">
                  {kpi &&
                    kpi.getRouteDistanceReport &&
                    kpi.getRouteDistanceReport.length > 0 && (
                      <ExcelExport
                        excelData={data}
                        fileName={`Route Distance Report ${moment()
                          .utcOffset(-360)
                          .format("MM/DD/YYYY")}`}
                      />
                    )}
                </div>
              </div>
            </div>
          )}
        <br />
        <div
          id="leads-table_wrapper"
          className="dataTables_wrapper dt-bootstrap4 no-footer"
        >
          <br />
          {
            <div className="flex-grow-1">
              <div className="dataTables_length d-flex" id="leads-table_length">
                <div className="col-md-12 text-left">
                  {" "}
                  <h4>
                    {myState.viewAide === "viewFullReport"
                      ? "Full Report"
                      : myState.viewAide === "viewAideReport"
                      ? "AIDE Mileage: Route Report"
                      : myState.viewAide === "viewWithoutAideReport"
                      ? "Without AIDE Mileage Report"
                      : ""}
                  </h4>
                  {/* {
                                        kpi &&
                                        kpi.getRouteDistanceReport &&
                                        kpi.getRouteDistanceReport.length > 0 &&
                                        <ExcelExport excelData={data} fileName={`Route Distance Report ${moment().format('MM/DD/YYYY')}`}/>
                                    } */}
                </div>
              </div>
            </div>
          }
          <div className="col-sm-12">
            <table
              className="table border w-100 dataTable no-footer"
              id="leads-table"
              role="grid"
              aria-describedby="leads-table_info"
              style={{ overflowX: "auto" }}
            >
              <thead className="card-header">
                <tr role="row">
                  <th
                    title="Route No"
                    className="sorting border-right"
                    tabIndex="0"
                    aria-controls="leads-table"
                    rowspan="1"
                    colspan="1"
                    aria-label="Mobile: activate to sort column ascending"
                  >
                    Route No
                  </th>
                  <th
                    title="Shift Time"
                    className="sorting border-right"
                    tabIndex="0"
                    aria-controls="leads-table"
                    rowspan="1"
                    colspan="1"
                    aria-label="Mobile: activate to sort column ascending"
                  >
                    Shift Time
                  </th>
                  <th
                    title="Pick Up Address"
                    className="sorting_disabled border-right"
                    rowspan="1"
                    colspan="1"
                    aria-label="Next Follow Up"
                  >
                    Pick Up Address
                  </th>
                  <th
                    title="Drop Off Address"
                    className="sorting border-right"
                    tabIndex="0"
                    aria-controls="leads-table"
                    rowspan="1"
                    colspan="1"
                    aria-label="Lead Agent: activate to sort column ascending"
                  >
                    Drop Off Address
                  </th>
                  {/* <th
                                    title="OrderMilage"
                                    className="sorting border-right"
                                    tabIndex="0"
                                    aria-controls="leads-table"
                                    rowspan="1"
                                    colspan="1"
                                    aria-label="Lead Agent: activate to sort column ascending"
                                >
                                    Order Milage
                                </th> */}
                  <th
                    title="Min"
                    className="sorting border-right"
                    tabIndex="0"
                    aria-controls="leads-table"
                    rowspan="1"
                    colspan="1"
                    aria-label="Lead Agent: activate to sort column ascending"
                  >
                    Min
                  </th>
                  <th
                    title="Max"
                    className="sorting border-right"
                    tabIndex="0"
                    aria-controls="leads-table"
                    rowspan="1"
                    colspan="1"
                    aria-label="Lead Agent: activate to sort column ascending"
                  >
                    Max
                  </th>
                  {/* <th
                                    title="Avg"
                                    className="sorting border-right"
                                    tabIndex="0"
                                    aria-controls="leads-table"
                                    rowspan="1"
                                    colspan="1"
                                    aria-label="Lead Agent: activate to sort column ascending"
                                >
                                    Avg
                                </th> */}
                </tr>
              </thead>
              <tbody>
                {kpi?.getRouteDistanceReport?.map((row, i) => (
                  <tr key={i} className="odd border">
                    <td className="border-right">{row.routeNo}</td>
                    <td className="border-right">{row.shiftTime}</td>
                    <td className="border-right">{row.pickUpAddress}</td>
                    <td className="border-right">{row.dropOffAddress}</td>
                    {/* <td className="border-right">
                                        {row.orderMilage.toFixed(2)}
                                        </td> */}
                    <td className="border-right">{row.min.toFixed(2)}</td>
                    <td className="border-right">{row.max.toFixed(2)}</td>
                    {/* <td className="border-right">
                                        {row.avg.toFixed(2)}
                                        </td> */}
                  </tr>
                ))}
              </tbody>
            </table>
            {kpi?.getRouteDistanceReport?.length === 0 && (
              <div className="dash-stats-list" style={{ textAlign: "center" }}>
                <br />
                <br />
                <img
                  alt=""
                  src={shift1}
                  style={{ width: "145px", opacity: "0.5" }}
                />
                <br />
                <p style={{ opacity: "0.5" }}>Generate Report</p>
              </div>
            )}
            <div
              id="leads-table_processing"
              className="dataTables_processing card"
              style={{ display: "none" }}
            >
              Processing...
            </div>
          </div>
          {/* </div> */}
        </div>

        <br />
        <div className="d-flex border"></div>
      </div>
    </>
  );
};
export default AdminRouteDistanceReport;
