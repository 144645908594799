import React from 'react'
import NavBar from '../../components/landingWebsiteComponents/NavBar'
import Banner from '../../components/landingWebsiteComponents/Banner'
import Footer from '../../components/landingWebsiteComponents/Footer'
import WebsiteAccountDetailComponent from '../../components/landingWebsiteComponents/websiteAccountDetailComponent/WebsiteAccountDetailComponent'

const AccountDetailsPage = () => {
  return (
    <>
        <NavBar />
        <br />
        <WebsiteAccountDetailComponent />
        <br />
        <Footer />
    </>
  )
}
export default AccountDetailsPage