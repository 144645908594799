/** @format */

import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateReservationDriverStatus } from "../../../actions/reservationAction";
import DriverMyorderDeclineModal from "./DriverMyorderDeclineModal";

const DriverMyOrderTableRowComponent = ({ current, i }) => {
  const [showDeclineDropDown, setshowDeclineDropDown] = useState(false);
  const openDeclineDropDown = () => {
    setshowDeclineDropDown((showDeclineDropDown) => !showDeclineDropDown);
  };
  const dispatch = useDispatch();
  const [values, setValues] = useState({
    reservationId: "",
    responseDate: "",
    driverBookingStatus: "",
    declineReason: "",
    // seenByAdmin: '',
    seenByDriver: "",
  });
  const reservationData = {
    reservationId: values.reservationId,
    responseDate: moment().format().toString().substring(0, 19) + ".000Z",
    driverBookingStatus: values.driverBookingStatus,
    declineReason: values.declineReason,
    // seenByAdmin: '',
    seenByDriver: true,
  };
  useEffect(() => {
    setValues({
      ...values,
      reservationId: current.id,
    });
  }, [current]);
  const [apiHit, setApiHit] = useState(false);

  const handleChange = (fieldName) => (event) => {
    setValues({
      ...values,
      [fieldName]: event.target.value,
    });
    if (event.target.value === "Assigned") {
      setApiHit((apiHit) => !apiHit);
    }
    if (event.target.value === "Unassigned") {
      openDeclineDropDown();
    }
    // handleSubmit()
    // console.log(values, "dikhao bhai")
  };
  const handleSubmit = async () => {
    if (reservationData.driverBookingStatus !== "") {
      for (let key of Object.keys(reservationData)) {
        if (reservationData[key] === "") {
          delete reservationData[key];
        }
      }
      dispatch(updateReservationDriverStatus(reservationData));
    }
    setshowDeclineDropDown(false);
  };
  useEffect(() => {
    handleSubmit();
  }, [apiHit]);
  const auth = useSelector((state) => state.auth);
  // console.log(reservationData, "reservationData")
  return (
    <>
      <tr role="row" key={i} className="odd border">
        <td className="border-right">
          {current.orderId}
          <i data-toggle="tooltip" className=""></i>
        </td>
        <td className="border-right">
          {current.customerId.firstName + " " + current.customerId.lastName}
        </td>
        <td className="border-right"> {current.customerId.phone}</td>
        <td className="border-right">
          {current?.assetId?.assetNo}
          <i data-toggle="tooltip" className=""></i>
          {/* <i data-toggle="tooltip" className="fa fa-info-circle fa-w-16 text-info"></i> */}
        </td>
        <td className="border-right w-30">
          <div className="d-flex justify-content-between">
            <div>
              <div>
                <p className="f-12 font-medium mb-0">
                  <strong>{current.pickUpAddress.address}</strong>{" "}
                </p>
                {/* <p className="f-12 mb-0">IL 60609, USA</p> */}
              </div>
              <div>
                <time>
                  {moment(current?.pickUpDate?.substring(0, 10)).format(
                    "MM/DD/YYYY"
                  )}{" "}
                  -{" "}
                  {parseInt(current.pickUpTime.substring(0, 2)) === 0
                    ? 12
                    : parseInt(current.pickUpTime.substring(0, 2)) > 12
                    ? parseInt(current.pickUpTime.substring(0, 2)) - 12
                    : parseInt(current.pickUpTime.substring(0, 2))}
                  {":" + current.pickUpTime.substring(3, 5)}
                  {parseInt(current.pickUpTime.substring(0, 2)) > 11
                    ? "pm"
                    : "am"}
                </time>
              </div>
            </div>
            <div className="mt-4 px-4">
              <div className="hidden sm:block">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="50"
                  height="8"
                  viewBox="0 0 40 6"
                  fill="none"
                >
                  <path
                    d="M40 3L35 0.113249V5.88675L40 3ZM0 3.5H35.5V2.5H0V3.5Z"
                    fill="black"
                  ></path>
                </svg>
              </div>
              <p className="text-center font-medium">
                <span>{current.totalMileage} mi</span>
              </p>
            </div>
            <div>
              <div>
                <p className="f-12 font-medium mb-0">
                  <strong>{current.dropOffAddress.address}</strong>
                </p>
                {/* <p className="f-12 mb-0">IL 60623</p> */}
              </div>
              <div>
                <time>
                  {moment(current?.dropOffDate?.substring(0, 10)).format(
                    "MM/DD/YYYY"
                  )}{" "}
                  -{" "}
                  {parseInt(current.dropOffTime.substring(0, 2)) === 0
                    ? 12
                    : parseInt(current.dropOffTime.substring(0, 2)) > 12
                    ? parseInt(current.dropOffTime.substring(0, 2)) - 12
                    : parseInt(current.dropOffTime.substring(0, 2))}
                  {":" + current.dropOffTime.substring(3, 5)}
                  {parseInt(current.dropOffTime.substring(0, 2)) > 11
                    ? "pm"
                    : "am"}
                </time>
              </div>
            </div>
          </div>
        </td>
        <td className="border-right">
          {moment(current.dateForReservation?.substring(0, 10)).format(
            "MM/DD/YYYY"
          )}
          <br />
        </td>
        <td className="border-right">
          {current?.fareAndDistance?.driverAmount
            ? "$" + current?.fareAndDistance?.driverAmount
            : "$" + 0}
        </td>
        <td className="border-right"> {current.driverBookingStatus} </td>
        <td className="border-right">
          <div className="select-picker">
            {current.driverBookingStatus === "Assigned" ? (
              <button
                type="button"
                className="btn btn-success"
                style={{ cursor: "default", margin: "auto", width: "100%" }}
              >
                Accepted
              </button>
            ) : (
              auth?.user?.permissionId?.myOrder?.update === true && (
                <select
                  className="form-control select-picker assign_role"
                  style={{ height: "35px" }}
                  value={values.driverBookingStatus}
                  onChange={handleChange("driverBookingStatus")}
                >
                  <option value="">New Request</option>
                  <option value="Assigned">Accept</option>
                  <option value="Unassigned">Decline</option>
                </select>
              )
            )}
          </div>
        </td>
      </tr>
      <DriverMyorderDeclineModal
        showDeclineDropDown={showDeclineDropDown}
        openDeclineDropDown={openDeclineDropDown}
        values={values}
        setValues={setValues}
        current={current}
        i={i}
        handleSubmit={handleSubmit}
        handleChange={handleChange}
      />
      {/* <DriverMyorderDeclineModal showDeclineDropDown={showDeclineDropDown} openDeclineDropDown={openDeclineDropDown}/> */}
    </>
  );
};
export default DriverMyOrderTableRowComponent;
