import React, { useEffect } from 'react'
import AdminHeader from '../../../components/adminComponents/adminHeaderSidebarTitleComponents/AdminHeader'
import AdminSidebar from '../../../components/adminComponents/adminHeaderSidebarTitleComponents/AdminSidebar'
import AdminSignalRouteViewComponent from './AdminSignalRouteViewComponent'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import AdminSingleRouteViewEachPassenger from './AdminSingleRouteViewEachPassenger'

const AdminSignalRouteViewPage = () => {
    const routes = useSelector((state) => state.routes);
    const auth = useSelector((state) => state.auth);

    const { routeDetails } = routes;
    let navigate = useNavigate();
    // useEffect(() => {
    //   if ((auth?.user?.permissionId?.routes?.isAllow === false) || (auth?.user?.disable === true)) {
    //     navigateUserFunc(auth?.user, navigate);
    //   }
    // }, []);
  
    return (
        <>
            <AdminHeader />
            <AdminSidebar />
            <div className="body-wrapper clearfix">
                <section className="main-container " id="fullscreen">
                    <div className="px-4 py-2 border-top-0 emp-dashboard">
                        <div className="d-lg-flex d-md-flex d-block py-4">
                            <div>
                                <h4 className=" mb-0 f-21 text-capitalize font-weight-bold">Route View</h4>
                            </div>
                        </div>
                        <AdminSingleRouteViewEachPassenger />
                        {/* <AdminSignalRouteViewComponent /> */}
                        {/* {auth?.user?.permissionId?.settings?.read === true ?
                            <AdminSettingVehicleFullDetailComponent />
                            :
                            <NotAllowed />
                        } */}
                    </div>
                </section>
            </div>
        </>
    )
}
export default AdminSignalRouteViewPage