/** @format */

import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { SERVERADDRESS } from "../../../constants";

const AdminDispatchingTodayOrderRideLogModal = ({
  showRideLogDropDown,
  openRideLogDropDown,
  current,
  i,
}) => {
  const reservation = useSelector((state) => state.reservation);
  const [reqData, setReqData] = useState();
  const dataLoaded = async () => {
    const { data } = await axios.post(
      `${SERVERADDRESS}/v1/reservation/reservationDetails`,
      {
        _id: current?._id,
      }
    );
    setReqData(data);
  };
  // useEffect (() => {
  //   dataLoaded()
  // }, [current]);
  useEffect(() => {
    if (showRideLogDropDown) {
      dataLoaded();
    }
  }, [showRideLogDropDown]);

  return (
    <>
      <div
        className={`modal fade ${
          showRideLogDropDown ? "show modal-y-axis" : "hide"
        }`}
        role="dialog"
        data-backdrop="static"
        data-keyboard="false" // To prevent closing with the keyboard
        onClick={(e) => {
          // Check if the click occurred outside the modal content
          if (e.target === e.currentTarget) {
            openRideLogDropDown(); // Call your close modal function
          }
        }}
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLongTitle">
                {" "}
                Assigning Details
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => openRideLogDropDown()}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="row">
                {/* <div className="col-md-5">
                                    <div className="form-group ">
                                        <p className="f-18 text-dark mb-12" htmlFor="Name">
                                            Assigning Details:
                                        </p>
                                    </div>
                                </div> */}
                <table
                  className="table border dataTable no-footer"
                  id="leads-table"
                  role="grid"
                  aria-describedby="leads-table_info"
                  style={{ overflowX: "auto", width: "95%", margin: "auto" }}
                >
                  <thead className="card-header">
                    <tr role="row">
                      <th
                        title="Assignee's Name"
                        className="sorting border-right"
                        tabIndex="0"
                        aria-controls="leads-table"
                        rowSpan="1"
                        colSpan="1"
                        aria-label="Mobile: activate to sort column ascending"
                      >
                        Assignee's Name
                      </th>
                      <th
                        title="Assigned On"
                        className="sorting_disabled border-right"
                        rowSpan="1"
                        colSpan="1"
                        aria-label="Next Follow Up"
                      >
                        Assigned On
                      </th>
                      <th
                        title="Assigned to"
                        className="sorting_disabled border-right"
                        rowSpan="1"
                        colSpan="1"
                        aria-label="Next Follow Up"
                      >
                        Assigned to
                      </th>
                      <th
                        title="Response Date"
                        className="sorting_disabled border-right"
                        rowSpan="1"
                        colSpan="1"
                        aria-label="Next Follow Up"
                      >
                        Response Date
                      </th>
                      <th
                        title="Status"
                        className="sorting_disabled border-right"
                        rowSpan="1"
                        colSpan="1"
                        aria-label="Next Follow Up"
                      >
                        Status
                      </th>
                      <th
                        title="Reason to Reject"
                        className="sorting_disabled border-right"
                        rowSpan="1"
                        colSpan="1"
                        aria-label="Next Follow Up"
                      >
                        Reason to Reject
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {reqData?.resStatus &&
                      reqData?.resStatus.length > 0 &&
                      reqData?.resStatus.map((e, i) => {
                        return (
                          <tr role="row" className="odd border" key={i}>
                            <td className="border-right">
                              {/* {current?.trip?.tripDuration} */}
                              {e?.assigneeId?.firstName +
                                " " +
                                e?.assigneeId?.lastName}
                            </td>
                            <td className="border-right">
                              {/* {moment(
                                                    current?.trip?.tripStartTime?.substring(0, 10)
                                                ).format("DD-MM-YYYY")}{" "}
                                                {current?.trip?.tripStartTime?.substring(11, 16)} */}
                              {moment(e.assignedDate).format("MM/DD/YYYY")}
                            </td>
                            <td className="border-right">
                              {e.driverId.firstName + " " + e.driverId.lastName}
                            </td>
                            <td className="border-right">
                              {moment(e.responseDate).format("MM/DD/YYYY")}
                              {/* {moment(current?.trip?.arrivedTime?.substring(0, 10)).format(
                                                    "DD-MM-YYYY"
                                                )}{" "}
                                                {current?.trip?.arrivedTime?.substring(11, 16)} */}
                            </td>
                            {/* <td className="border-right">
                                                {current?.trip?.waitingTimePickUp}
                                            </td>
                                            <td className="border-right">
                                                {moment(current?.trip?.pickUpTime?.substring(0, 10)).format(
                                                    "DD-MM-YYYY"
                                                )}{" "}
                                                {current?.trip?.pickUpTime?.substring(11, 16)}
                                            </td>
                                            <td className="border-right">
                                                {moment(current?.trip?.dropOffTime?.substring(0, 10)).format(
                                                    "DD-MM-YYYY"
                                                )}{" "}
                                                {current?.trip?.dropOffTime?.substring(11, 16)}
                                            </td> */}
                            {/* <td className="border-right">
                                                {current?.trip?.tripDuration}
                                            </td>
                                            <td className="border-right">
                                                {current?.trip?.pickUpLng}
                                            </td> */}
                            {/* <td className="border-right">
                                                {current?.trip?.pickUpLat}
                                            </td> */}
                            <td className="border-right">
                              {e.driverBookingStatus === "Assigned"
                                ? "Accepeted"
                                : e.driverBookingStatus === "Pending"
                                ? "Pending"
                                : "Rejected"}
                            </td>
                            <td className="border-right">{e.declineReason}</td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
              {/* </div> */}

              {/* <div className="row border-bottom-grey">
                                <div className="col-md-5">
                                    <div className="form-group ">
                                        <p className="f-18 text-dark mb-12" htmlFor="Name"> Assigning Details:</p>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="form-group ">
                                        {reqData?.resStatus &&
                                            reqData?.resStatus.length > 0 &&
                                            reqData?.resStatus.map((e, i) => {
                                                return (
                                                    <p className="f-18 text-dark mb-12" htmlFor="Name" key={i}>&bull; Admin assigned this ride to {e.driverId.firstName + " " + e.driverId.lastName} on {moment(e.assignedDate).format('MM/DD/YYYY')}. The Driver has {e.driverBookingStatus === 'Assigned' ? "Accepeted" : `Rejected due to reason: ${e.declineReason}`} on {moment(e.responseDate).format('MM/DD/YYYY')}.</p>
                                                )
                                            })}
                                    </div>
                                </div>
                            </div> */}
            </div>
            <div className="modal-footer">
              <button
                type="button"
                onClick={() => openRideLogDropDown()}
                className="btn-primary rounded f-14 p-2 mr-3"
              >
                OK
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default AdminDispatchingTodayOrderRideLogModal;
