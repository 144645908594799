/** @format */

import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  createEnternalCompany,
  getExternalCompanies,
} from "../../../actions/companyAction";
import MyComponent from "../Map/Map";

const AdminSettingPaymentAddCompanyModal = ({
  showModal,
  setShowModal,
  setSelectedCompanyId,
}) => {
  const auth = useSelector((x) => x.auth);
  const [values, setValues] = useState({
    companyId: auth.user.companyId.id,
    name: "",

    email: "",
    phone: "",

    address: "",
    city: "",
    zipCode: "",
  });

  const dispatch = useDispatch();
  const companyData = {
    companyId: values.companyId,
    name: values.name,
    email: values.email,
    phone: values.phone,
    address: values.address,
    city: values.city,
    zipCode: values.zipCode,
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    // console.log("first")
    // for (let key of Object.keys(clientData)) {
    //   if (clientData[key] === "") {
    //     delete clientData[key];
    //   }
    // }
    // console.log("submit==>", values);
    dispatch(createEnternalCompany(companyData, successHandler));
    // setValues2({
    //   companyId: "",
    //   firstName: "",
    //   lastName: "",
    //   email: "",
    //   password: "",
    //   phone: "",
    //   workPhone: "",
    //   otherPhone: "",
    //   role: "",
    //   clientWorkInformation: "",
    //   address: "",
    //   // state: '',
    //   city: "",
    //   zip: "",
    //   summary: "",
    //   isClient: true,
    // });
  };

  const handlePhone = (fieldName) => (event) => {
    if (event.target.value.length === 1) {
      if (event.target.value !== "(") {
        setValues({
          ...values,
          [fieldName]: "(" + event.target.value,
        });
      }
    } else if (event.target.value.length === 4) {
      if (event.target.value !== ")") {
        setValues({
          ...values,
          [fieldName]: event.target.value + ")-",
        });
      }
    } else if (event.target.value.length === 9) {
      if (event.target.value !== "-") {
        setValues({
          ...values,
          [fieldName]: event.target.value + "-",
        });
      }
    } else {
      setValues({
        ...values,
        [fieldName]: event.target.value,
      });
    }
  };
  const alphabets = [
    "a",
    "b",
    "c",
    "d",
    "e",
    "f",
    "g",
    "h",
    "i",
    "j",
    "k",
    "l",
    "m",
    "n",
    "o",
    "p",
    "q",
    "r",
    "s",
    "t",
    "u",
    "v",
    "w",
    "x",
    "y",
    "z",
    "A",
    "B",
    "C",
    "D",
    "E",
    "F",
    "G",
    "H",
    "I",
    "J",
    "K",
    "L",
    "M",
    "N",
    "O",
    "P",
    "Q",
    "R",
    "S",
    "T",
    "U",
    "V",
    "W",
    "X",
    "Y",
    "Z",
    "+",
    "-",
    "/",
    "*",
    "!",
    "@",
    "#",
    "$",
    "%",
    "^",
    "&",
    "_",
    "(",
    ")",
    ",",
    ".",
  ];

  const successHandler = (companyData) => {
    setShowModal(false);
    dispatch(getExternalCompanies({ companyId: auth.user.companyId.id }));
    // console.log("data aya==>", companyData);
    setSelectedCompanyId(companyData.id);
    setValues({
      companyId: auth.user.companyId.id,
      name: "",
      email: "",
      phone: "",
      address: "",
      city: "",
      zipCode: "",
    });
  };
  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

  return (
    <>
      <div
        className={`modal fade ${showModal ? "show modal-y-axis" : "hide"}`}
        role="dialog"
        data-backdrop="static"
        data-keyboard="false" // To prevent closing with the keyboard
        onClick={(e) => {
          // Check if the click occurred outside the modal content
          if (e.target === e.currentTarget) {
            setShowModal(false); // Call your close modal function
          }
        }}
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLongTitle">
                {" "}
                Add Company
              </h5>
              <button
                type="button"
                className="close"
                id="close1"
                data-dismiss="modal"
                aria-label="Close"
                // onClick={() => openDropDown()}
                onClick={() => setShowModal(false)}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group my-3">
                    <label
                      className="f-14 text-dark-grey mb-12"
                      data-label=""
                      htmlFor="companyName"
                    >
                      Company Name
                    </label>
                    <sup className="text-danger f-12">*</sup>
                    <input
                      type="text"
                      id="companyName"
                      className="form-control height-35 f-14"
                      placeholder="Enter Your Company Name"
                      autoComplete="false"
                      value={values.name}
                      name="companyName"
                      onChange={handleChange}
                    />
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group my-3">
                    <label
                      className="f-14 text-dark-grey mb-12"
                      data-label=""
                      htmlFor="email"
                    >
                      Email
                    </label>
                    <sup className="text-danger f-12">*</sup>
                    <input
                      type="Email"
                      id="email"
                      className="form-control height-35 f-14"
                      placeholder="Enter Your Email"
                      autoComplete="false"
                      value={values.email}
                      name="email"
                      onChange={handleChange}
                    />
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group my-3">
                    <label
                      className="f-14 text-dark-grey mb-12"
                      data-label=""
                      htmlFor="phone"
                    >
                      Phone
                    </label>
                    {/* <sup className="text-danger f-12">*</sup> */}
                    <input
                      type="tel"
                      id="phone"
                      className="form-control height-35 f-14"
                      // placeholder="Enter Your Primary Phone Number"
                      // autoComplete="false"
                      minLength="14"
                      maxLength="14"
                      onKeyDown={(e) => {
                        if (e.ctrlKey && e.code === "KeyV") {
                          return;
                        }
                        if (alphabets.includes(e.key)) {
                          e.preventDefault();
                        }
                        if (
                          e.code === "Backspace" &&
                          values.phone.length === 6
                        ) {
                          setValues({
                            ...values,
                            phone: values.phone.substring(0, 4),
                          });
                        }
                        if (
                          e.code === "Backspace" &&
                          values.phone.length === 2
                        ) {
                          setValues({
                            ...values,
                            phone: "",
                          });
                        }
                        if (
                          e.code === "Backspace" &&
                          values.phone.length === 10
                        ) {
                          setValues({
                            ...values,
                            phone: values.phone.substring(0, 9),
                          });
                        }
                      }}
                      value={values.phone}
                      name="phone"
                      onChange={handlePhone("phone")}
                      placeholder="(123)-456-7890"
                    />
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group my-3">
                    <label
                      className="f-14 text-dark-grey mb-12"
                      data-label=""
                      htmlFor="address"
                    >
                      Address
                    </label>
                    {/* <sup className="text-danger f-12">*</sup> */}
                    {/* <input
                      type="text"
                      className="form-control height-35 f-14"
                      placeholder="Enter Your Address"
                      autoComplete="false"
                      value={values.address}
                      name="address"
                      onChange={handleChange}
                    /> */}
                    <MyComponent
                      values2={values}
                      setValues2={setValues}
                      id="address"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group my-3">
                    <label
                      className="f-14 text-dark-grey mb-12"
                      data-label=""
                      htmlFor="city"
                    >
                      City
                    </label>
                    {/* <sup className="text-danger f-12">*</sup> */}
                    <input
                      type="text"
                      id="city"
                      className="form-control height-35 f-14"
                      placeholder="Enter Your City"
                      autoComplete="false"
                      value={values.city}
                      name="city"
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group my-3">
                    <label
                      className="f-14 text-dark-grey mb-12"
                      data-label=""
                      htmlFor="zipCode"
                    >
                      Zip
                    </label>
                    {/* <sup className="text-danger f-12">*</sup> */}
                    <input
                      type="text"
                      id="zipCode"
                      className="form-control height-35 f-14"
                      placeholder="Enter Your Zip"
                      autoComplete="false"
                      value={values.zipCode}
                      name="zipCode"
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                id="close2"
                className="btn-secondary rounded f-14 p-2 mr-3"
                data-dismiss="modal"
                onClick={() => setShowModal(false)}
              >
                Close
              </button>
              <button
                type="submit"
                id="submit"
                className="btn-primary rounded f-14 p-2 mr-3"
                onClick={handleSubmit}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default AdminSettingPaymentAddCompanyModal;
