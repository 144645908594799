import React, { useEffect, useState } from 'react'
import { GoogleMap, useJsApiLoader, StandaloneSearchBox } from '@react-google-maps/api';
import { Marker } from '@react-google-maps/api';
import AutoComplete from '../components/adminComponents/Map/AutoComplete';
import { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import MarkerWithTransition from '../components/adminComponents/adminTrackingMap/MarkerWithTransition';
import Demo5 from './Demo5';
import RouteLine from './RouteLine';

const containerStyle = {
  width: '100%',
  height: '100vh'
};

const Demo4 = () => {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: "AIzaSyC653TecaJSucmBmqXPr9twuP_jG55NACA"
  })
  const [mapApiLoaded, setMapApiLoaded] = useState(true);
    const [mapInstance, setMapInstance] = useState(null);
    const [mapApi, setMapApi] = useState(null);
    const [places, setPlaces] = useState([]);
    const [center, setCenter] = useState({ lat: -35.279, lng: 149.129 });
    const [zoom, setZoom] = useState(15);
    const [address, setAddress] = useState("");
    const [draggable, setDraggable] = useState(true)
    const [lat, setLat] = useState(null)
  const [coordinates, setCoordinates] = useState({
    lat: null,
    lng:null,
  });
  useEffect(() => {
    setCurrentLocation()
  },[])
  const onMarkerInteraction = (childKey, childProps, mouse) => {
    setDraggable(false);
    setCoordinates({
      lat: mouse.lat,
      lng: mouse.lng
    })
};
const onMarkerInteractionMouseUp = (childKey, childProps, mouse) => {
  setDraggable(true);
};
function apiHasLoaded(map, maps){
  setMapApiLoaded(true)
  setMapInstance(map)
  setMapApi(maps)
};

const addPlace = (place) => {
  setPlaces([place]);
  setCoordinates({
    lat: place.geometry.location.lat(),
    lng: place.geometry.location.lng(),
  });
};
const setCurrentLocation = () => {
  if ("geolocation" in navigator) {
    navigator.geolocation.getCurrentPosition((position) => {
        // setCenter([position.coords.latitude, position.coords.longitude])
        // setCenter({
        //   lat: position.coords.latitude,
        //   lng: position.coords.longitude,
        // })
              setCoordinates({
                lat: position.coords.latitude,
                lng: position.coords.longitude,
              });
              // console.log(position.coords.latitude, position.coords.longitude)
    });
  }
}
const handleSelect = async (value) => {
  const results = await geocodeByAddress(value);
  const latLng = await getLatLng(results[0]);
  setAddress(value);
  // addPlace(value)
  setCoordinates(latLng);
  setCenter(latLng)
};

// useEffect(() => {


// Geocode.fromLatLng(coordinates.lat, coordinates.lng).then(
//   (response) => {
//     setAddress(response.results[0].formatted_address);
//   },
//   (error) => {
//     console.error(error);
//   }
// );

// }, [coordinates.lat, coordinates.lng])
const _onChange = ({ center, zoom }) => {
  // console.log("center onChange==>", center)
    setCenter(center)
    setZoom(zoom)
};
  const _onClick = (value) => {
    // console.log(value.latLng.lat)
    // console.log(value.latLng.lat(),"S")
    setCoordinates({
      lat: value.latLng.lat(),
      lng: value.latLng.lng(),
    });
  };

  const _onClick2 = (e) => {
    // console.log(e)
  };

  const [map, setMap] = React.useState(null)

  const onLoad = React.useCallback(function callback(map) {
    const bounds = new window.google.maps.LatLngBounds(coordinates);
    map.fitBounds(bounds);
    setMap(map)
  }, [])

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null)
  }, [])
// console.log(coordinates)

const routePoints = {
  "snappedPoints":
    [
      {
        "location":
          { "latitude": -35.27800489993019, "longitude": 149.129531998742 },
        "originalIndex": 0,
        "placeId": "ChIJr_xl0GdNFmsRsUtUbW7qABM",
      },
      {
        "location":
          { "latitude": -35.2784195, "longitude": 149.12946589999999 },
        "placeId": "ChIJr_xl0GdNFmsRsUtUbW7qABM",
      },
      {
        "location":
          { "latitude": -35.2784195, "longitude": 149.12946589999999 },
        "placeId": "ChIJ6aXGemhNFmsRZE_zHqhmrG4",
      },
      {
        "location":
          { "latitude": -35.2792731, "longitude": 149.12933809999998 },
        "placeId": "ChIJ6aXGemhNFmsRZE_zHqhmrG4",
      },
      {
        "location":
          { "latitude": -35.2792731, "longitude": 149.12933809999998 },
        "placeId": "ChIJTcTdZ2hNFmsRXokM4mWCWfk",
      },
      {
        "location": { "latitude": -35.279557, "longitude": 149.1292973 },
        "placeId": "ChIJTcTdZ2hNFmsRXokM4mWCWfk",
      },
      {
        "location": { "latitude": -35.279557, "longitude": 149.1292973 },
        "placeId": "ChIJiUfNQmhNFmsRSsAI-1m6y1g",
      },
      {
        "location":
          { "latitude": -35.279610999999996, "longitude": 149.1292889 },
        "placeId": "ChIJiUfNQmhNFmsRSsAI-1m6y1g",
      },
      {
        "location": { "latitude": -35.2796484, "longitude": 149.1292791 },
        "placeId": "ChIJiUfNQmhNFmsRSsAI-1m6y1g",
      },
      {
        "location": { "latitude": -35.2796484, "longitude": 149.1292791 },
        "placeId": "ChIJ_RyFQ2hNFmsRoHJAbW7qABM",
      },
      {
        "location":
          { "latitude": -35.279947299999996, "longitude": 149.1291894 },
        "placeId": "ChIJ_RyFQ2hNFmsRoHJAbW7qABM",
      },
      {
        "location":
          { "latitude": -35.279947299999996, "longitude": 149.1291894 },
        "placeId": "ChIJOyypT2hNFmsRZBtscGL0htw",
      },
      {
        "location":
          { "latitude": -35.280323564795005, "longitude": 149.1290903128365 },
        "originalIndex": 1,
        "placeId": "ChIJOyypT2hNFmsRZBtscGL0htw",
      },
      {
        "location":
          { "latitude": -35.2803426, "longitude": 149.12908529999999 },
        "placeId": "ChIJOyypT2hNFmsRZBtscGL0htw",
      },
      {
        "location":
          { "latitude": -35.2803426, "longitude": 149.12908529999999 },
        "placeId": "ChIJr8xRTGhNFmsRzMb-rxgjspc",
      },
      {
        "location":
          { "latitude": -35.280409899999995, "longitude": 149.1290699 },
        "placeId": "ChIJr8xRTGhNFmsRzMb-rxgjspc",
      },
      {
        "location": { "latitude": -35.28048179999999, "longitude": 149.129062 },
        "placeId": "ChIJr8xRTGhNFmsRzMb-rxgjspc",
      },
      {
        "location": { "latitude": -35.2805541, "longitude": 149.1290623 },
        "placeId": "ChIJr8xRTGhNFmsRzMb-rxgjspc",
      },
      {
        "location": { "latitude": -35.280626, "longitude": 149.1290712 },
        "placeId": "ChIJr8xRTGhNFmsRzMb-rxgjspc",
      },
      {
        "location": { "latitude": -35.280626, "longitude": 149.1290712 },
        "placeId": "ChIJv5r0smlNFmsR5nunau79Fv4",
      },
      {
        "location":
          { "latitude": -35.280695099999996, "longitude": 149.12908489999998 },
        "placeId": "ChIJv5r0smlNFmsR5nunau79Fv4",
      },
      {
        "location": { "latitude": -35.2807629, "longitude": 149.1291046 },
        "placeId": "ChIJv5r0smlNFmsR5nunau79Fv4",
      },
      {
        "location": { "latitude": -35.2808294, "longitude": 149.1291306 },
        "placeId": "ChIJv5r0smlNFmsR5nunau79Fv4",
      },
      {
        "location": { "latitude": -35.2809064, "longitude": 149.1291693 },
        "placeId": "ChIJv5r0smlNFmsR5nunau79Fv4",
      },
      {
        "location":
          { "latitude": -35.280968200000004, "longitude": 149.129208 },
        "placeId": "ChIJv5r0smlNFmsR5nunau79Fv4",
      },
      {
        "location":
          { "latitude": -35.28101395754623, "longitude": 149.1292430025548 },
        "originalIndex": 2,
        "placeId": "ChIJv5r0smlNFmsR5nunau79Fv4",
      },
      {
        "location":
          { "latitude": -35.28103840000001, "longitude": 149.1292617 },
        "placeId": "ChIJv5r0smlNFmsR5nunau79Fv4",
      },
      {
        "location": { "latitude": -35.2810936, "longitude": 149.1293121 },
        "placeId": "ChIJv5r0smlNFmsR5nunau79Fv4",
      },
      {
        "location": { "latitude": -35.2810979, "longitude": 149.1293176 },
        "placeId": "ChIJv5r0smlNFmsR5nunau79Fv4",
      },
      {
        "location": { "latitude": -35.2810979, "longitude": 149.1293176 },
        "placeId": "ChIJpYMSrmlNFmsRXkCoIkZxgBg",
      },
      {
        "location":
          { "latitude": -35.281152399999996, "longitude": 149.1294256 },
        "placeId": "ChIJpYMSrmlNFmsRXkCoIkZxgBg",
      },
      {
        "location":
          { "latitude": -35.281152399999996, "longitude": 149.1294256 },
        "placeId": "ChIJ601MoWlNFmsR5mvkfPp2ovA",
      },
      {
        "location": { "latitude": -35.2811784, "longitude": 149.1294706 },
        "placeId": "ChIJ601MoWlNFmsR5mvkfPp2ovA",
      },
      {
        "location": { "latitude": -35.2812258, "longitude": 149.1295413 },
        "placeId": "ChIJ601MoWlNFmsR5mvkfPp2ovA",
      },
      {
        "location":
          { "latitude": -35.2812771, "longitude": 149.12960759999999 },
        "placeId": "ChIJ601MoWlNFmsR5mvkfPp2ovA",
      },
      {
        "location": { "latitude": -35.281332, "longitude": 149.1296695 },
        "placeId": "ChIJ601MoWlNFmsR5mvkfPp2ovA",
      },
      {
        "location":
          { "latitude": -35.2813904, "longitude": 149.12972670000002 },
        "placeId": "ChIJ601MoWlNFmsR5mvkfPp2ovA",
      },
      {
        "location":
          { "latitude": -35.281451700000005, "longitude": 149.1297788 },
        "placeId": "ChIJ601MoWlNFmsR5mvkfPp2ovA",
      },
      {
        "location":
          { "latitude": -35.28146506991143, "longitude": 149.12978858234607 },
        "originalIndex": 3,
        "placeId": "ChIJ601MoWlNFmsR5mvkfPp2ovA",
      },
      {
        "location":
          { "latitude": -35.28151580000001, "longitude": 149.1298257 },
        "placeId": "ChIJ601MoWlNFmsR5mvkfPp2ovA",
      },
      {
        "location": { "latitude": -35.28158259999999, "longitude": 149.129867 },
        "placeId": "ChIJ601MoWlNFmsR5mvkfPp2ovA",
      },
      {
        "location":
          { "latitude": -35.281666099999995, "longitude": 149.1299091 },
        "placeId": "ChIJ601MoWlNFmsR5mvkfPp2ovA",
      },
      {
        "location": { "latitude": -35.2817377, "longitude": 149.1299379 },
        "placeId": "ChIJ601MoWlNFmsR5mvkfPp2ovA",
      },
      {
        "location":
          { "latitude": -35.281810899999996, "longitude": 149.1299602 },
        "placeId": "ChIJ601MoWlNFmsR5mvkfPp2ovA",
      },
      {
        "location":
          { "latitude": -35.281884999999996, "longitude": 149.1299765 },
        "placeId": "ChIJ601MoWlNFmsR5mvkfPp2ovA",
      },
      {
        "location":
          { "latitude": -35.28194399606459, "longitude": 149.1299842294294 },
        "originalIndex": 4,
        "placeId": "ChIJ601MoWlNFmsR5mvkfPp2ovA",
      },
      {
        "location":
          { "latitude": -35.281959799999996, "longitude": 149.12998629999998 },
        "placeId": "ChIJ601MoWlNFmsR5mvkfPp2ovA",
      },
      {
        "location":
          { "latitude": -35.282035199999996, "longitude": 149.1299895 },
        "placeId": "ChIJ601MoWlNFmsR5mvkfPp2ovA",
      },
      {
        "location": { "latitude": -35.2821254, "longitude": 149.1299851 },
        "placeId": "ChIJ601MoWlNFmsR5mvkfPp2ovA",
      },
      {
        "location":
          { "latitude": -35.282199999999996, "longitude": 149.1299743 },
        "placeId": "ChIJ601MoWlNFmsR5mvkfPp2ovA",
      },
      {
        "location": { "latitude": -35.2822739, "longitude": 149.1299573 },
        "placeId": "ChIJ601MoWlNFmsR5mvkfPp2ovA",
      },
      {
        "location": { "latitude": -35.2823468, "longitude": 149.129934 },
        "placeId": "ChIJ601MoWlNFmsR5mvkfPp2ovA",
      },
      {
        "location": { "latitude": -35.2824178, "longitude": 149.1299043 },
        "placeId": "ChIJ601MoWlNFmsR5mvkfPp2ovA",
      },
      {
        "location": { "latitude": -35.2824379, "longitude": 149.1298945 },
        "placeId": "ChIJ601MoWlNFmsR5mvkfPp2ovA",
      },
      {
        "location": { "latitude": -35.2824379, "longitude": 149.1298945 },
        "placeId": "ChIJe9LPnWlNFmsR7mJw8mYHwG0",
      },
      {
        "location":
          { "latitude": -35.282472999999996, "longitude": 149.1298835 },
        "placeId": "ChIJe9LPnWlNFmsR7mJw8mYHwG0",
      },
      {
        "location": { "latitude": -35.2825375, "longitude": 149.1298525 },
        "placeId": "ChIJe9LPnWlNFmsR7mJw8mYHwG0",
      },
      {
        "location":
          { "latitude": -35.28257309999999, "longitude": 149.1298319 },
        "placeId": "ChIJe9LPnWlNFmsR7mJw8mYHwG0",
      },
      {
        "location":
          { "latitude": -35.28257309999999, "longitude": 149.1298319 },
        "placeId": "ChIJaUpThGlNFmsRMHWxoH7EOsc",
      },
      {
        "location":
          { "latitude": -35.282665400000006, "longitude": 149.12974459999998 },
        "placeId": "ChIJaUpThGlNFmsRMHWxoH7EOsc",
      },
      {
        "location":
          { "latitude": -35.28274030000001, "longitude": 149.1296645 },
        "placeId": "ChIJaUpThGlNFmsRMHWxoH7EOsc",
      },
      {
        "location":
          { "latitude": -35.282809799999995, "longitude": 149.12957799999998 },
        "placeId": "ChIJaUpThGlNFmsRMHWxoH7EOsc",
      },
      {
        "location":
          { "latitude": -35.28282136229385, "longitude": 149.12956142620385 },
        "originalIndex": 5,
        "placeId": "ChIJaUpThGlNFmsRMHWxoH7EOsc",
      },
      {
        "location": { "latitude": -35.2828744, "longitude": 149.1294854 },
        "placeId": "ChIJaUpThGlNFmsRMHWxoH7EOsc",
      },
      {
        "location":
          { "latitude": -35.282922299999996, "longitude": 149.1294044 },
        "placeId": "ChIJaUpThGlNFmsRMHWxoH7EOsc",
      },
      {
        "location":
          { "latitude": -35.282931500000004, "longitude": 149.1293876 },
        "placeId": "ChIJaUpThGlNFmsRMHWxoH7EOsc",
      },
      {
        "location": { "latitude": -35.2830263, "longitude": 149.1291788 },
        "placeId": "ChIJaUpThGlNFmsRMHWxoH7EOsc",
      },
      {
        "location": { "latitude": -35.2830263, "longitude": 149.1291788 },
        "placeId": "ChIJyd3JiWlNFmsR9RUq2ySTTZQ",
      },
      {
        "location": { "latitude": -35.283054, "longitude": 149.1290996 },
        "placeId": "ChIJyd3JiWlNFmsR9RUq2ySTTZQ",
      },
      {
        "location": { "latitude": -35.2830794, "longitude": 149.1290094 },
        "placeId": "ChIJyd3JiWlNFmsR9RUq2ySTTZQ",
      },
      {
        "location": { "latitude": -35.2830794, "longitude": 149.1290094 },
        "placeId": "ChIJWSb8ImpNFmsRp_4JAxJFE1A",
      },
      {
        "location":
          { "latitude": -35.28313383700836, "longitude": 149.12893500604946 },
        "originalIndex": 6,
        "placeId": "ChIJWSb8ImpNFmsRp_4JAxJFE1A",
      },
      {
        "location":
          { "latitude": -35.283134499999996, "longitude": 149.12893409999998 },
        "placeId": "ChIJWSb8ImpNFmsRp_4JAxJFE1A",
      },
      {
        "location":
          { "latitude": -35.283190399999995, "longitude": 149.1288668 },
        "placeId": "ChIJWSb8ImpNFmsRp_4JAxJFE1A",
      },
      {
        "location": { "latitude": -35.2832503, "longitude": 149.1288041 },
        "placeId": "ChIJWSb8ImpNFmsRp_4JAxJFE1A",
      },
      {
        "location": { "latitude": -35.2833133, "longitude": 149.1287463 },
        "placeId": "ChIJWSb8ImpNFmsRp_4JAxJFE1A",
      },
      {
        "location": { "latitude": -35.2833794, "longitude": 149.128694 },
        "placeId": "ChIJWSb8ImpNFmsRp_4JAxJFE1A",
      },
      {
        "location":
          { "latitude": -35.283448299999996, "longitude": 149.128647 },
        "placeId": "ChIJWSb8ImpNFmsRp_4JAxJFE1A",
      },
      {
        "location": { "latitude": -35.2835199, "longitude": 149.1286054 },
        "placeId": "ChIJWSb8ImpNFmsRp_4JAxJFE1A",
      },
      {
        "location": { "latitude": -35.2835934, "longitude": 149.1285699 },
        "placeId": "ChIJWSb8ImpNFmsRp_4JAxJFE1A",
      },
      {
        "location":
          { "latitude": -35.283668899999995, "longitude": 149.12854059999998 },
        "placeId": "ChIJWSb8ImpNFmsRp_4JAxJFE1A",
      },
      {
        "location":
          { "latitude": -35.28372649999999, "longitude": 149.1285237 },
        "placeId": "ChIJWSb8ImpNFmsRp_4JAxJFE1A",
      },
      {
        "location":
          { "latitude": -35.28386179999999, "longitude": 149.12849319999998 },
        "placeId": "ChIJWSb8ImpNFmsRp_4JAxJFE1A",
      },
      {
        "location": { "latitude": -35.2839978, "longitude": 149.1284682 },
        "placeId": "ChIJWSb8ImpNFmsRp_4JAxJFE1A",
      },
      {
        "location":
          { "latitude": -35.2840205, "longitude": 149.12846779999998 },
        "placeId": "ChIJWSb8ImpNFmsRp_4JAxJFE1A",
      },
      {
        "location":
          { "latitude": -35.2840205, "longitude": 149.12846779999998 },
        "placeId": "ChIJZe8tFWpNFmsR4brZ1vldk2E",
      },
      {
        "location":
          { "latitude": -35.2840524, "longitude": 149.12845969999998 },
        "placeId": "ChIJZe8tFWpNFmsR4brZ1vldk2E",
      },
      {
        "location":
          { "latitude": -35.284341500000004, "longitude": 149.1284124 },
        "placeId": "ChIJZe8tFWpNFmsR4brZ1vldk2E",
      },
      {
        "location": { "latitude": -35.2843875, "longitude": 149.1284034 },
        "placeId": "ChIJZe8tFWpNFmsR4brZ1vldk2E",
      },
      {
        "location": { "latitude": -35.2843875, "longitude": 149.1284034 },
        "placeId": "ChIJVx7Ta2pNFmsRx9OI9CnN5tI",
      },
      {
        "location": { "latitude": -35.2845916, "longitude": 149.1283726 },
        "placeId": "ChIJVx7Ta2pNFmsRx9OI9CnN5tI",
      },
      {
        "location": { "latitude": -35.2845916, "longitude": 149.1283726 },
        "placeId": "ChIJtWxAZmpNFmsRlbUCkc6VtnA",
      },
      {
        "location":
          { "latitude": -35.28459730000001, "longitude": 149.1283703 },
        "placeId": "ChIJtWxAZmpNFmsRlbUCkc6VtnA",
      },
      {
        "location":
          { "latitude": -35.284728747199374, "longitude": 149.12834860726772 },
        "originalIndex": 7,
        "placeId": "ChIJtWxAZmpNFmsRlbUCkc6VtnA",
      },
    ],
}

const routePoints2 = {
  "snappedPoints": [
    {
      "location": {"latitude": -35.27801, "longitude": 149.12958}
    },
    {
      "location": {"latitude": -35.28032, "longitude": 149.12907}
    },
    {
      "location": {"latitude": -35.28099, "longitude": 149.12929}
    },
    {
      "location": {"latitude": -35.28144, "longitude": 149.12984}
    },
    {
      "location": {"latitude": -35.28194, "longitude": 149.13003}
    },
    {
      "location": {"latitude": -35.28282, "longitude": 149.12956}
    },
    {
      "location": {"latitude": -35.28302, "longitude": 149.12881}
    },
    {
      "location": {"latitude": -35.28473, "longitude": 149.12836}
    }
  ]
}

const routePoints3 = {
  "snappedPoints": [
    {
      "location": {
        "latitude": -35.278004899930188,
        "longitude": 149.129531998742
      },
      "originalIndex": 0,
      "placeId": "ChIJr_xl0GdNFmsRsUtUbW7qABM"
    },
    {
      "location": {
        "latitude": -35.2784195,
        "longitude": 149.12946589999999
      },
      "placeId": "ChIJr_xl0GdNFmsRsUtUbW7qABM"
    },
    {
      "location": {
        "latitude": -35.2784195,
        "longitude": 149.12946589999999
      },
      "placeId": "ChIJewPEemhNFmsRBZS3AAgdLYc"
    },
    {
      "location": {
        "latitude": -35.2788849,
        "longitude": 149.1293962
      },
      "placeId": "ChIJewPEemhNFmsRBZS3AAgdLYc"
    },
    {
      "location": {
        "latitude": -35.2788849,
        "longitude": 149.1293962
      },
      "placeId": "ChIJewPEemhNFmsRy3WJkU_ipyI"
    },
    {
      "location": {
        "latitude": -35.2792731,
        "longitude": 149.12933809999998
      },
      "placeId": "ChIJewPEemhNFmsRy3WJkU_ipyI"
    },
    {
      "location": {
        "latitude": -35.2792731,
        "longitude": 149.12933809999998
      },
      "placeId": "ChIJTcTdZ2hNFmsRXokM4mWCWfk"
    },
    {
      "location": {
        "latitude": -35.279557,
        "longitude": 149.1292973
      },
      "placeId": "ChIJTcTdZ2hNFmsRXokM4mWCWfk"
    },
    {
      "location": {
        "latitude": -35.279557,
        "longitude": 149.1292973
      },
      "placeId": "ChIJiUfNQmhNFmsRSsAI-1m6y1g"
    },
    {
      "location": {
        "latitude": -35.279610999999996,
        "longitude": 149.1292889
      },
      "placeId": "ChIJiUfNQmhNFmsRSsAI-1m6y1g"
    },
    {
      "location": {
        "latitude": -35.2796484,
        "longitude": 149.1292791
      },
      "placeId": "ChIJiUfNQmhNFmsRSsAI-1m6y1g"
    },
    {
      "location": {
        "latitude": -35.2796484,
        "longitude": 149.1292791
      },
      "placeId": "ChIJ_RyFQ2hNFmsRoHJAbW7qABM"
    },
    {
      "location": {
        "latitude": -35.279947299999996,
        "longitude": 149.1291894
      },
      "placeId": "ChIJ_RyFQ2hNFmsRoHJAbW7qABM"
    },
    {
      "location": {
        "latitude": -35.279947299999996,
        "longitude": 149.1291894
      },
      "placeId": "ChIJOyypT2hNFmsRZBtscGL0htw"
    },
    {
      "location": {
        "latitude": -35.280323564795005,
        "longitude": 149.1290903128365
      },
      "originalIndex": 1,
      "placeId": "ChIJOyypT2hNFmsRZBtscGL0htw"
    },
    {
      "location": {
        "latitude": -35.2803426,
        "longitude": 149.12908529999999
      },
      "placeId": "ChIJOyypT2hNFmsRZBtscGL0htw"
    },
    {
      "location": {
        "latitude": -35.2803426,
        "longitude": 149.12908529999999
      },
      "placeId": "ChIJr8xRTGhNFmsRzMb-rxgjspc"
    },
    {
      "location": {
        "latitude": -35.280409899999995,
        "longitude": 149.1290699
      },
      "placeId": "ChIJr8xRTGhNFmsRzMb-rxgjspc"
    },
    {
      "location": {
        "latitude": -35.28048179999999,
        "longitude": 149.129062
      },
      "placeId": "ChIJr8xRTGhNFmsRzMb-rxgjspc"
    },
    {
      "location": {
        "latitude": -35.2805541,
        "longitude": 149.1290623
      },
      "placeId": "ChIJr8xRTGhNFmsRzMb-rxgjspc"
    },
    {
      "location": {
        "latitude": -35.280626,
        "longitude": 149.1290712
      },
      "placeId": "ChIJr8xRTGhNFmsRzMb-rxgjspc"
    },
    {
      "location": {
        "latitude": -35.280626,
        "longitude": 149.1290712
      },
      "placeId": "ChIJXV1fsmlNFmsRhgtpwDkRZ7Y"
    },
    {
      "location": {
        "latitude": -35.280695099999996,
        "longitude": 149.12908489999998
      },
      "placeId": "ChIJXV1fsmlNFmsRhgtpwDkRZ7Y"
    },
    {
      "location": {
        "latitude": -35.2807629,
        "longitude": 149.1291046
      },
      "placeId": "ChIJXV1fsmlNFmsRhgtpwDkRZ7Y"
    },
    {
      "location": {
        "latitude": -35.2808294,
        "longitude": 149.1291306
      },
      "placeId": "ChIJXV1fsmlNFmsRhgtpwDkRZ7Y"
    },
    {
      "location": {
        "latitude": -35.2809064,
        "longitude": 149.1291693
      },
      "placeId": "ChIJXV1fsmlNFmsRhgtpwDkRZ7Y"
    },
    {
      "location": {
        "latitude": -35.2809099,
        "longitude": 149.1291713
      },
      "placeId": "ChIJXV1fsmlNFmsRhgtpwDkRZ7Y"
    },
    {
      "location": {
        "latitude": -35.2809099,
        "longitude": 149.1291713
      },
      "placeId": "ChIJXV1fsmlNFmsRCOk-kVUrrJk"
    },
    {
      "location": {
        "latitude": -35.2809764,
        "longitude": 149.1292212
      },
      "placeId": "ChIJXV1fsmlNFmsRCOk-kVUrrJk"
    },
    {
      "location": {
        "latitude": -35.281012077118824,
        "longitude": 149.12925678502435
      },
      "originalIndex": 2,
      "placeId": "ChIJXV1fsmlNFmsRCOk-kVUrrJk"
    },
    {
      "location": {
        "latitude": -35.2810539,
        "longitude": 149.1292985
      },
      "placeId": "ChIJXV1fsmlNFmsRCOk-kVUrrJk"
    },
    {
      "location": {
        "latitude": -35.2810831,
        "longitude": 149.1293358
      },
      "placeId": "ChIJXV1fsmlNFmsRCOk-kVUrrJk"
    },
    {
      "location": {
        "latitude": -35.2810831,
        "longitude": 149.1293358
      },
      "placeId": "ChIJy0pspmlNFmsROzo41f073J4"
    },
    {
      "location": {
        "latitude": -35.281160500000006,
        "longitude": 149.1294412
      },
      "placeId": "ChIJy0pspmlNFmsROzo41f073J4"
    },
    {
      "location": {
        "latitude": -35.281196799999989,
        "longitude": 149.1294992
      },
      "placeId": "ChIJy0pspmlNFmsROzo41f073J4"
    },
    {
      "location": {
        "latitude": -35.281245900000009,
        "longitude": 149.1295683
      },
      "placeId": "ChIJy0pspmlNFmsROzo41f073J4"
    },
    {
      "location": {
        "latitude": -35.2813096,
        "longitude": 149.1296454
      },
      "placeId": "ChIJy0pspmlNFmsROzo41f073J4"
    },
    {
      "location": {
        "latitude": -35.2813784,
        "longitude": 149.1297156
      },
      "placeId": "ChIJy0pspmlNFmsROzo41f073J4"
    },
    {
      "location": {
        "latitude": -35.2814427,
        "longitude": 149.1297718
      },
      "placeId": "ChIJy0pspmlNFmsROzo41f073J4"
    },
    {
      "location": {
        "latitude": -35.2814427,
        "longitude": 149.1297718
      },
      "placeId": "ChIJy0pspmlNFmsRRU1wc0qC2mY"
    },
    {
      "location": {
        "latitude": -35.281464199999995,
        "longitude": 149.12978859999998
      },
      "placeId": "ChIJy0pspmlNFmsRRU1wc0qC2mY"
    },
    {
      "location": {
        "latitude": -35.281464206848945,
        "longitude": 149.12978860484074
      },
      "originalIndex": 3,
      "placeId": "ChIJy0pspmlNFmsRRU1wc0qC2mY"
    },
    {
      "location": {
        "latitude": -35.281529000000006,
        "longitude": 149.12983440000002
      },
      "placeId": "ChIJy0pspmlNFmsRRU1wc0qC2mY"
    },
    {
      "location": {
        "latitude": -35.281596300000004,
        "longitude": 149.12987460000002
      },
      "placeId": "ChIJy0pspmlNFmsRRU1wc0qC2mY"
    },
    {
      "location": {
        "latitude": -35.2816802,
        "longitude": 149.1299153
      },
      "placeId": "ChIJy0pspmlNFmsRRU1wc0qC2mY"
    },
    {
      "location": {
        "latitude": -35.2817523,
        "longitude": 149.12994279999998
      },
      "placeId": "ChIJy0pspmlNFmsRRU1wc0qC2mY"
    },
    {
      "location": {
        "latitude": -35.2818256,
        "longitude": 149.129964
      },
      "placeId": "ChIJy0pspmlNFmsRRU1wc0qC2mY"
    },
    {
      "location": {
        "latitude": -35.2819,
        "longitude": 149.129979
      },
      "placeId": "ChIJy0pspmlNFmsRRU1wc0qC2mY"
    },
    {
      "location": {
        "latitude": -35.281943363600476,
        "longitude": 149.12998373057263
      },
      "originalIndex": 4,
      "placeId": "ChIJy0pspmlNFmsRRU1wc0qC2mY"
    },
    {
      "location": {
        "latitude": -35.281977,
        "longitude": 149.1299874
      },
      "placeId": "ChIJy0pspmlNFmsRRU1wc0qC2mY"
    },
    {
      "location": {
        "latitude": -35.281977,
        "longitude": 149.1299874
      },
      "placeId": "ChIJmXjun2lNFmsRnCbWpHfNZUk"
    },
    {
      "location": {
        "latitude": -35.2820653,
        "longitude": 149.1299891
      },
      "placeId": "ChIJmXjun2lNFmsRnCbWpHfNZUk"
    },
    {
      "location": {
        "latitude": -35.282140399999996,
        "longitude": 149.12998349999998
      },
      "placeId": "ChIJmXjun2lNFmsRnCbWpHfNZUk"
    },
    {
      "location": {
        "latitude": -35.282229699999995,
        "longitude": 149.1299683
      },
      "placeId": "ChIJmXjun2lNFmsRnCbWpHfNZUk"
    },
    {
      "location": {
        "latitude": -35.2822484,
        "longitude": 149.1299639
      },
      "placeId": "ChIJmXjun2lNFmsRnCbWpHfNZUk"
    },
    {
      "location": {
        "latitude": -35.2822484,
        "longitude": 149.1299639
      },
      "placeId": "ChIJmXjun2lNFmsRDPPZ-JRYHU8"
    },
    {
      "location": {
        "latitude": -35.2823323,
        "longitude": 149.1299392
      },
      "placeId": "ChIJmXjun2lNFmsRDPPZ-JRYHU8"
    },
    {
      "location": {
        "latitude": -35.2824038,
        "longitude": 149.1299107
      },
      "placeId": "ChIJmXjun2lNFmsRDPPZ-JRYHU8"
    },
    {
      "location": {
        "latitude": -35.2824379,
        "longitude": 149.1298945
      },
      "placeId": "ChIJmXjun2lNFmsRDPPZ-JRYHU8"
    },
    {
      "location": {
        "latitude": -35.2824379,
        "longitude": 149.1298945
      },
      "placeId": "ChIJe9LPnWlNFmsR7mJw8mYHwG0"
    },
    {
      "location": {
        "latitude": -35.2824789,
        "longitude": 149.1298745
      },
      "placeId": "ChIJe9LPnWlNFmsR7mJw8mYHwG0"
    },
    {
      "location": {
        "latitude": -35.2825552,
        "longitude": 149.1298262
      },
      "placeId": "ChIJe9LPnWlNFmsR7mJw8mYHwG0"
    },
    {
      "location": {
        "latitude": -35.2825975,
        "longitude": 149.1297957
      },
      "placeId": "ChIJe9LPnWlNFmsR7mJw8mYHwG0"
    },
    {
      "location": {
        "latitude": -35.2825975,
        "longitude": 149.1297957
      },
      "placeId": "ChIJaUpThGlNFmsRMHWxoH7EOsc"
    },
    {
      "location": {
        "latitude": -35.2827343,
        "longitude": 149.12968659999999
      },
      "placeId": "ChIJaUpThGlNFmsRMHWxoH7EOsc"
    },
    {
      "location": {
        "latitude": -35.2828162,
        "longitude": 149.1296029
      },
      "placeId": "ChIJaUpThGlNFmsRMHWxoH7EOsc"
    },
    {
      "location": {
        "latitude": -35.282835594597778,
        "longitude": 149.12957895606874
      },
      "originalIndex": 5,
      "placeId": "ChIJaUpThGlNFmsRMHWxoH7EOsc"
    },
    {
      "location": {
        "latitude": -35.2828729,
        "longitude": 149.1295329
      },
      "placeId": "ChIJaUpThGlNFmsRMHWxoH7EOsc"
    },
    {
      "location": {
        "latitude": -35.2829342,
        "longitude": 149.1294179
      },
      "placeId": "ChIJaUpThGlNFmsRMHWxoH7EOsc"
    },
    {
      "location": {
        "latitude": -35.2829342,
        "longitude": 149.1294179
      },
      "placeId": "ChIJyd3JiWlNFmsR9RUq2ySTTZQ"
    },
    {
      "location": {
        "latitude": -35.282978799999995,
        "longitude": 149.1293258
      },
      "placeId": "ChIJyd3JiWlNFmsR9RUq2ySTTZQ"
    },
    {
      "location": {
        "latitude": -35.2830733,
        "longitude": 149.1290899
      },
      "placeId": "ChIJyd3JiWlNFmsR9RUq2ySTTZQ"
    },
    {
      "location": {
        "latitude": -35.2830733,
        "longitude": 149.1290899
      },
      "placeId": "ChIJU3pcImpNFmsR7Rd0f_YVa5E"
    },
    {
      "location": {
        "latitude": -35.2831054,
        "longitude": 149.129039
      },
      "placeId": "ChIJU3pcImpNFmsR7Rd0f_YVa5E"
    },
    {
      "location": {
        "latitude": -35.2831574,
        "longitude": 149.1289639
      },
      "originalIndex": 6,
      "placeId": "ChIJU3pcImpNFmsR7Rd0f_YVa5E"
    },
    {
      "location": {
        "latitude": -35.2832133,
        "longitude": 149.1288936
      },
      "placeId": "ChIJU3pcImpNFmsR7Rd0f_YVa5E"
    },
    {
      "location": {
        "latitude": -35.283272400000008,
        "longitude": 149.1288276
      },
      "placeId": "ChIJU3pcImpNFmsR7Rd0f_YVa5E"
    },
    {
      "location": {
        "latitude": -35.2833219,
        "longitude": 149.1287789
      },
      "placeId": "ChIJU3pcImpNFmsR7Rd0f_YVa5E"
    },
    {
      "location": {
        "latitude": -35.2833916,
        "longitude": 149.1287188
      },
      "placeId": "ChIJU3pcImpNFmsR7Rd0f_YVa5E"
    },
    {
      "location": {
        "latitude": -35.2833916,
        "longitude": 149.1287188
      },
      "placeId": "ChIJU3pcImpNFmsRJStstjJ9RIs"
    },
    {
      "location": {
        "latitude": -35.2834236,
        "longitude": 149.12868190000003
      },
      "placeId": "ChIJU3pcImpNFmsRJStstjJ9RIs"
    },
    {
      "location": {
        "latitude": -35.283498,
        "longitude": 149.12860369999999
      },
      "placeId": "ChIJU3pcImpNFmsRJStstjJ9RIs"
    },
    {
      "location": {
        "latitude": -35.2835371,
        "longitude": 149.1285684
      },
      "placeId": "ChIJU3pcImpNFmsRJStstjJ9RIs"
    },
    {
      "location": {
        "latitude": -35.2835761,
        "longitude": 149.12853529999998
      },
      "placeId": "ChIJU3pcImpNFmsRJStstjJ9RIs"
    },
    {
      "location": {
        "latitude": -35.2836434,
        "longitude": 149.12848449999998
      },
      "placeId": "ChIJU3pcImpNFmsRJStstjJ9RIs"
    },
    {
      "location": {
        "latitude": -35.2837852,
        "longitude": 149.12839900000003
      },
      "placeId": "ChIJU3pcImpNFmsRJStstjJ9RIs"
    },
    {
      "location": {
        "latitude": -35.28390499999999,
        "longitude": 149.1283336
      },
      "placeId": "ChIJU3pcImpNFmsRJStstjJ9RIs"
    },
    {
      "location": {
        "latitude": -35.2839257,
        "longitude": 149.1283265
      },
      "placeId": "ChIJU3pcImpNFmsRJStstjJ9RIs"
    },
    {
      "location": {
        "latitude": -35.2839257,
        "longitude": 149.1283265
      },
      "placeId": "ChIJZe8tFWpNFmsR4brZ1vldk2E"
    },
    {
      "location": {
        "latitude": -35.2839599,
        "longitude": 149.1283096
      },
      "placeId": "ChIJZe8tFWpNFmsR4brZ1vldk2E"
    },
    {
      "location": {
        "latitude": -35.284263599999996,
        "longitude": 149.12818289999998
      },
      "placeId": "ChIJZe8tFWpNFmsR4brZ1vldk2E"
    },
    {
      "location": {
        "latitude": -35.284312,
        "longitude": 149.1281612
      },
      "placeId": "ChIJZe8tFWpNFmsR4brZ1vldk2E"
    },
    {
      "location": {
        "latitude": -35.284312,
        "longitude": 149.1281612
      },
      "placeId": "ChIJVx7Ta2pNFmsRx9OI9CnN5tI"
    },
    {
      "location": {
        "latitude": -35.2845663,
        "longitude": 149.1281041
      },
      "placeId": "ChIJVx7Ta2pNFmsRx9OI9CnN5tI"
    },
    {
      "location": {
        "latitude": -35.2845663,
        "longitude": 149.1281041
      },
      "placeId": "ChIJtWxAZmpNFmsRlbUCkc6VtnA"
    },
    {
      "location": {
        "latitude": -35.284720487515081,
        "longitude": 149.12809576929931
      },
      "originalIndex": 7,
      "placeId": "ChIJtWxAZmpNFmsRlbUCkc6VtnA"
    }
  ]
}

const routePoints4 = {
  "snappedPoints": [
    {
      "location": {
        "latitude": -35.278004899930188,
        "longitude": 149.129531998742
      },
      "originalIndex": 0,
      "placeId": "ChIJr_xl0GdNFmsRsUtUbW7qABM"
    },
    {
      "location": {
        "latitude": -35.280323564795005,
        "longitude": 149.1290903128365
      },
      "originalIndex": 1,
      "placeId": "ChIJOyypT2hNFmsRZBtscGL0htw"
    },
    {
      "location": {
        "latitude": -35.281012077118824,
        "longitude": 149.12925678502435
      },
      "originalIndex": 2,
      "placeId": "ChIJXV1fsmlNFmsRCOk-kVUrrJk"
    },
    {
      "location": {
        "latitude": -35.281464206848945,
        "longitude": 149.12978860484074
      },
      "originalIndex": 3,
      "placeId": "ChIJy0pspmlNFmsRRU1wc0qC2mY"
    },
    {
      "location": {
        "latitude": -35.281943363600476,
        "longitude": 149.12998373057263
      },
      "originalIndex": 4,
      "placeId": "ChIJy0pspmlNFmsRRU1wc0qC2mY"
    },
    {
      "location": {
        "latitude": -35.282835594597778,
        "longitude": 149.12957895606874
      },
      "originalIndex": 5,
      "placeId": "ChIJaUpThGlNFmsRMHWxoH7EOsc"
    },
    {
      "location": {
        "latitude": -35.2831574,
        "longitude": 149.1289639
      },
      "originalIndex": 6,
      "placeId": "ChIJU3pcImpNFmsR7Rd0f_YVa5E"
    },
    {
      "location": {
        "latitude": -35.284720487515081,
        "longitude": 149.12809576929931
      },
      "originalIndex": 7,
      "placeId": "ChIJtWxAZmpNFmsRlbUCkc6VtnA"
    }
  ]
}

const onLoad2 = ref => this.searchBox = ref;

const onPlacesChanged = (e) => console.log(e);

  return isLoaded ? (
        <>
<GoogleMap
mapContainerStyle={containerStyle}
center={center}
zoom={15}
// onLoad={onLoad}
onUnmount={onUnmount}
onClick={_onClick}
// center={center}
//     zoom={zoom}
//     draggable={draggable}
//     onChange={_onChange}
//     onChildMouseDown={onMarkerInteraction}
//     onChildMouseUp={onMarkerInteractionMouseUp}
//     onChildMouseMove={onMarkerInteraction}
//     onChildClick={() => console.log("child click")}
//     onClick={_onClick}
//     bootstrapURLKeys={{
//       key: "AIzaSyDv5TZ4_il1sukhMFSFChda0UHcL0js0Zg",
//       libraries: ["places", "geometry"],
//     }}
//     yesIWantToUseGoogleMapApiInternals
//     onGoogleApiLoaded={({ map, maps }) => apiHasLoaded(map, maps)}
>
{ /* Child components, such as markers, info windows, etc. */ }
{/* <StandaloneSearchBox
// onClick={_onClick2}
// ref={_onClick2}
// onLoad={onLoad2}
// onPlacesChanged={
//   onPlacesChanged
// }
>
<input
type="text"
placeholder="Customized your placeholder"
onClick={_onClick2}
style={{
  boxSizing: `border-box`,
  border: `1px solid transparent`,
  width: `240px`,
  height: `32px`,
  padding: `0 12px`,
  borderRadius: `3px`,
  boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
  fontSize: `14px`,
  outline: `none`,
  textOverflow: `ellipses`,
  position: "absolute",
  left: "50%",
  marginLeft: "-120px"
}}
/>
</StandaloneSearchBox> */}
{/* <MarkerWithTransition stop={coordinates}/> */}
{/* <Marker
  // onLoad={onLoad}
  position={coordinates}
/> */}
{/* <Demo5 coordinates={coordinates}/> */}
{/* google default output for document */}
{/* <RouteLine path={routePoints.snappedPoints} colorCode={'#FFFF00'}/>  */}
<RouteLine path={routePoints2.snappedPoints} colorCode={'#FF0000'}/>
{/* google output with interpolate=true */}
<RouteLine path={routePoints3.snappedPoints} colorCode={'#0000FF'}/>
{/* google output with interpolate=false */}
<RouteLine path={routePoints4.snappedPoints} colorCode={'#008000'}/>
</GoogleMap>

        </>
  ) : <></>
 
}


export default Demo4
