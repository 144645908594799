/** @format */

import React from "react";

const AdminNoshowAcceptDeclineModal = ({
  showDeclineDropDown,
  openDeclineDropDown,
  //   current,
  //   modalStatus,
  sendMessage,
  handleReasonChange,
  reason,
  // index,
  // assigned,
}) => {
  //   console.log(modalStatus, "modalStatus");
  //   const handleSubmit = () => {};
  return (
    <>
      <div
        className={`modal fade ${
          showDeclineDropDown === true ? "show modal-y-axis" : "hide"
        }`}
        // className={`modal fade show modal-y-axis`}

        role="dialog"
        data-backdrop="static"
        data-keyboard="false" // To prevent closing with the keyboard
        onClick={(e) => {
          // Check if the click occurred outside the modal content
          if (e.target === e.currentTarget) {
            openDeclineDropDown(); // Call your close modal function
          }
        }}
      >
        <div
          className="modal-dialog modal-dialog-centered modal-dialog-msg"
          role="document"
        >
          <div className="modal-content">
            <form onSubmit={sendMessage}>
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLongTitle">
                  {" "}
                  Reason
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => openDeclineDropDown()}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="row">
                  <div className="col-md-12">
                    {/* <label htmlFor="allowed_file_types">Reason: </label> */}
                    <label>Reason: </label>
                    <sup className="text-danger f-12">*</sup>
                    <textarea
                      type="text"
                      placeholder="Enter The Reason To Cancel"
                      className="form-control f-14"
                      autoComplete="false"
                      value={reason}
                      onChange={handleReasonChange}
                      required
                    ></textarea>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn-secondary rounded f-14 p-2 mr-3"
                  data-dismiss="modal"
                  onClick={() => openDeclineDropDown()}
                >
                  Close
                </button>
                <button
                  type="submit"
                  className="btn-primary rounded f-14 p-2 mr-3"
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};
export default AdminNoshowAcceptDeclineModal;
