/** @format */

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { useNavigate, useParams } from "react-router-dom";
import {
  getPassengerByExternalCompanyId,
  updateExternalCompanyUser,
} from "../../actions/userActions";

const ExternalCompanyUserEditModal = ({
  current,
  showDropDown,
  openDropDown,
  setSelectedStudent,
}) => {
  const auth = useSelector((state) => state.auth);
  const users = useSelector((state) => state.users);
  // const externalCompanies = useSelector((state) => state.externalCompanies);
  const [data, setData] = useState({});

  const [values, setValues] = useState({
    _id: "",
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    notes: "",
  });
  const handleChange = (fieldName) => (event) => {
    setValues({
      ...values,
      [fieldName]: event.target.value,
    });
  };
  const currentUser = async () => {
    try {
      setValues({
        _id: "",
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        notes: "",
      });
      setValues({
        // id: current.id,
        _id: current.id,
        firstName: current.firstName,
        lastName: current.lastName,
        email: current.email,
        phone: current.phone,
        notes: current.notes,
      });
      // setIsDriver(current?.isDriver)
    } catch (error) {
      // console.log(error);
    }
  };
  useEffect(() => {
    currentUser();
  }, [current]);

  const dispatch = useDispatch();

  const userDataNew = {
    // userId: values.id,
    _id: values._id,
    firstName: values.firstName,
    lastName: values.lastName,
    email: values.email,
    phone: values.phone,
    notes: values.notes,
  };
  const handleSuccess = () => {
    openDropDown();
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    dispatch(updateExternalCompanyUser(userDataNew, handleSuccess));
    // setTimeout(() =>
    //     dispatch(updateExternalCompanyUser(userDataNew))
    // , 2000);
  };
  // console.log("curent abh naya naya",current)
  // console.log("values abh naya naya",values, userDataNew)
  const handlePhone = (fieldName) => (event) => {
    const numericValue = event.target.value.replace(/\D/g, "");
    let formattedValue = "";
    if (numericValue.length > 0) {
      formattedValue = `(${numericValue.slice(0, 3)}`;
    }
    if (numericValue.length > 3) {
      formattedValue += `)${numericValue.slice(3, 6)}`;
    }
    if (numericValue.length > 6) {
      formattedValue += `-${numericValue.slice(6, 10)}`;
    }
    setValues({
      ...values,
      [fieldName]: formattedValue,
    });
  };

  // const handlePhone = (fieldName) => (event) => {
  //   if (event.target.value.length === 1) {
  //     if (event.target.value !== "(") {
  //       setData({
  //         ...data,
  //         [fieldName]: "(" + event.target.value,
  //       });
  //     }
  //   } else if (event.target.value.length === 4) {
  //     if (event.target.value !== ")") {
  //       setData({
  //         ...data,
  //         [fieldName]: event.target.value + ")-",
  //       });
  //     }
  //   } else if (event.target.value.length === 9) {
  //     if (event.target.value !== "-") {
  //       setData({
  //         ...data,
  //         [fieldName]: event.target.value + "-",
  //       });
  //     }
  //   } else {
  //     setData({
  //       ...data,
  //       [fieldName]: event.target.value,
  //     });
  //   }
  // };
  // const alphabets = [
  //   "a",
  //   "b",
  //   "c",
  //   "d",
  //   "e",
  //   "f",
  //   "g",
  //   "h",
  //   "i",
  //   "j",
  //   "k",
  //   "l",
  //   "m",
  //   "n",
  //   "o",
  //   "p",
  //   "q",
  //   "r",
  //   "s",
  //   "t",
  //   "u",
  //   "v",
  //   "w",
  //   "x",
  //   "y",
  //   "z",
  //   "A",
  //   "B",
  //   "C",
  //   "D",
  //   "E",
  //   "F",
  //   "G",
  //   "H",
  //   "I",
  //   "J",
  //   "K",
  //   "L",
  //   "M",
  //   "N",
  //   "O",
  //   "P",
  //   "Q",
  //   "R",
  //   "S",
  //   "T",
  //   "U",
  //   "V",
  //   "W",
  //   "X",
  //   "Y",
  //   "Z",
  //   "+",
  //   "-",
  //   "/",
  //   "*",
  //   "!",
  //   "@",
  //   "#",
  //   "$",
  //   "%",
  //   "^",
  //   "&",
  //   "_",
  //   "(",
  //   ")",
  //   ",",
  //   ".",
  // ];

  return (
    <>
      <div
        className={`modal fade ${showDropDown ? "show modal-y-axis" : "hide"}`}
        role="dialog"
        data-backdrop="static"
        data-keyboard="false" // To prevent closing with the keyboard
        onClick={(e) => {
          // Check if the click occurred outside the modal content
          if (e.target === e.currentTarget) {
            openDropDown(); // Call your close modal function
          }
        }}
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLongTitle">
                {" "}
                Edit
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => openDropDown()}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <div className="modal-body">
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group my-3">
                    <label
                      className="f-14 text-dark-grey mb-12"
                      data-label=""
                      htmlFor="after_days"
                    >
                      First Name
                    </label>
                    <sup className="text-danger f-12">*</sup>
                    <input
                      type="text"
                      className="form-control height-35 f-14"
                      placeholder="Enter Your First Name"
                      autoComplete="false"
                      value={values.firstName}
                      onChange={handleChange("firstName")}
                      required
                      maxLength={35}
                    />
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group my-3">
                    <label
                      className="f-14 text-dark-grey mb-12"
                      data-label=""
                      htmlFor="after_days"
                    >
                      Last Name
                    </label>
                    <sup className="text-danger f-12">*</sup>
                    <input
                      type="text"
                      className="form-control height-35 f-14"
                      placeholder="Enter Your Last Name"
                      name="lastName"
                      autoComplete="false"
                      value={values.lastName}
                      onChange={handleChange("lastName")}
                      required
                      maxLength={35}
                    />
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group my-3">
                    <label
                      className="f-14 text-dark-grey mb-12"
                      data-label=""
                      htmlFor="after_days"
                    >
                      Email
                    </label>
                    {/* <sup className="text-danger f-12">*</sup> */}
                    <input
                      type="Email"
                      className="form-control height-35 f-14"
                      placeholder="Enter Your Email"
                      autoComplete="false"
                      value={values.email}
                      onChange={handleChange("email")}
                    />
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group my-3">
                    <label
                      className="f-14 text-dark-grey mb-12"
                      data-label=""
                      htmlFor="after_days"
                    >
                      Phone Number
                    </label>
                    {/* <sup className="text-danger f-12">*</sup> */}
                    {/* <input
                      type="tel"
                      className="form-control height-35 f-14"
                      minLength="14"
                      maxLength="14"
                      // required
                      onKeyDown={(e) => {
                        if (e.ctrlKey && e.code === "KeyV") {
                          return;
                        }
                        if (alphabets.includes(e.key)) {
                          e.preventDefault();
                        }
                        if (
                          e.code === "Backspace" &&
                          values.phone.length === 6
                        ) {
                          setValues({
                            ...values,
                            phone: values.phone.substring(0, 4),
                          });
                        }
                        if (
                          e.code === "Backspace" &&
                          values.phone.length === 2
                        ) {
                          setValues({
                            ...values,
                            phone: "",
                          });
                        }
                        if (
                          e.code === "Backspace" &&
                          values.phone.length === 10
                        ) {
                          setValues({
                            ...values,
                            phone: values.phone.substring(0, 9),
                          });
                        }
                      }}
                      name="phone"
                      value={values.phone}
                      onChange={handlePhone("phone")}
                      placeholder="(123)-456-7890"
                    /> */}
                    <input
                      type="tel"
                      className="form-control height-35 f-14"
                      name="phone"
                      value={values.phone}
                      onChange={handlePhone("phone")}
                      placeholder="(123)456-7890"
                    />
                  </div>
                </div>

                <div className="col-md-12">
                  <label htmlFor="allowed_file_types">Note: </label>
                  <textarea
                    type="text"
                    name="allowed_file_types"
                    id="allowed_file_types"
                    placeholder="Enter The Note"
                    className="form-control f-14"
                    autoComplete="false"
                    value={values.notes}
                    onChange={handleChange("notes")}
                  ></textarea>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn-secondary rounded f-14 p-2 mr-3"
                data-dismiss="modal"
                onClick={() => openDropDown()}
              >
                Close
              </button>
              <button
                className="btn-primary rounded f-14 p-2 mr-3"
                onClick={handleSubmit}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default ExternalCompanyUserEditModal;
