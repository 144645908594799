import {
  TRIP_VIEW_REQUEST,
  TRIP_VIEW_SUCCESS,
  TRIP_VIEW_FAIL,
  TRIP_CREATE_REQUEST,
  TRIP_CREATE_SUCCESS,
  TRIP_CREATE_FAIL,
  TRIP_MAP_DATA_REQUEST,
  TRIP_MAP_DATA_SUCCESS,
  TRIP_MAP_DATA_FAIL,
  TRIP_WAY_POINTS_REQUEST,
  TRIP_WAY_POINTS_SUCCESS,
  TRIP_WAY_POINTS_FAIL,
  TRIP_RESET_WAY_POINTS,
  // TRIP_UPDATE_REQUEST,
  // TRIP_UPDATE_SUCCESS,
  // TRIP_UPDATE_FAIL,
} from "../constants";

export const tripReducer = (
  state = {
    trip: {
      loading: false,
      readTrip: null,
      tripCreate: null,
      wayPoints: null,
      tripMapData: null,
    },
  },
  action
) => {
  switch (action.type) {
    // TRIP VIEW TABLE
    case TRIP_VIEW_REQUEST:
      return {
        ...state,
      };
    case TRIP_VIEW_SUCCESS:
      return {
        ...state,
        readTrip: action.payload,
      };
    case TRIP_VIEW_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    // TRIP CREATE
    case TRIP_CREATE_REQUEST:
      return {
        ...state,
      };
    case TRIP_CREATE_SUCCESS:
      return {
        ...state,
        tripCreate: action.payload,
      };
    case TRIP_CREATE_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    // TRIP AND ROUTE TRIP MAP DATA
    case TRIP_MAP_DATA_REQUEST:
      return {
        ...state,
        loading: true,
        tripMapData: [],
      };
    case TRIP_MAP_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        tripMapData: action.payload,
      };
    case TRIP_MAP_DATA_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case TRIP_WAY_POINTS_REQUEST:
      return {
        ...state,
      };
    case TRIP_WAY_POINTS_SUCCESS:
      return {
        ...state,
        wayPoints: action.payload,
      };
    case TRIP_WAY_POINTS_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case TRIP_RESET_WAY_POINTS:
      return {
        ...state,
        wayPoints: action.payload,
      };

    // // TRIP UPDATE
    // case TRIP_UPDATE_REQUEST :
    //     return {
    //         ...state,
    //     }
    // case TRIP_UPDATE_SUCCESS :
    //     return {
    //         ...state,
    //         // readTrip: action.payload,
    //     }
    // case TRIP_UPDATE_FAIL :
    //     return {
    //         ...state,
    //         error: action.payload,
    //     }

    default:
      return state;
  }
};
