import {
  FARE_VIEW_REQUEST,
  FARE_VIEW_SUCCESS,
  FARE_VIEW_FAIL,
  FARE_PRIVATE_VIEW_REQUEST,
  FARE_PRIVATE_VIEW_SUCCESS,
  FARE_PRIVATE_VIEW_FAIL,
  FARE_CREATE_REQUEST,
  FARE_CREATE_SUCCESS,
  FARE_CREATE_FAIL,
} from "../constants";

export const fareReducer = (
  state = {
    fare: {
      fare: null,
      privateFare: null,
    },
  },
  action
) => {
  switch (action.type) {
    // FARE VIEW TABLE
    case FARE_VIEW_REQUEST:
      return {
        ...state,
      };
    case FARE_VIEW_SUCCESS:
      return {
        ...state,
        fare: action.payload,
      };
    case FARE_VIEW_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case FARE_PRIVATE_VIEW_REQUEST:
      return {
        ...state,
      };
    case FARE_PRIVATE_VIEW_SUCCESS:
      return {
        ...state,
        privateFare: action.payload,
      };
    case FARE_PRIVATE_VIEW_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    // FARE CREATE
    case FARE_CREATE_REQUEST:
      return {
        ...state,
      };
    case FARE_CREATE_SUCCESS:
      return {
        ...state,
        fare: [...state.fare, action.payload],
      };
    case FARE_CREATE_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    default:
      return state;
  }
};
