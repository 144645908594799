/** @format */

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addStudentAction,
  getPassengerByExternalCompanyId,
} from "../../actions/userActions";
import { useNavigate } from "react-router-dom";

const ExternalCompanyUserCreateView = () => {
  const auth = useSelector((state) => state.auth);
  const users = useSelector((state) => state.users);
  const dispatch = useDispatch();
  let navigate = useNavigate();

  useEffect(() => {
    dispatch(
      getPassengerByExternalCompanyId({
        externalCompanyId: auth?.user?.externalCompanyId,
      })
    );
  }, [auth]);

  // const navigateClient = () => {
  //     navigate("/clients-list");
  //   };

  const [values, setValues] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    notes: "",

    // address: "",
    // externalCompanyId: "",
  });
  const studentData = {
    externalCompanyId: auth.user.externalCompanyId,
    companyId: auth.user.companyId.id,
    firstName: values.firstName,
    lastName: values.lastName,
    email: values.email,
    phone: values.phone,
    notes: values.notes,
    isRouteUser: false,
    // address: values.address,
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    for (let key of Object.keys(studentData)) {
      if (studentData[key] === "") {
        delete studentData[key];
      }
    }

    dispatch(addStudentAction(studentData));
    navigate("/clients-list");
  };
  const handleChange = (fieldName) => (event) => {
    setValues({
      ...values,
      [fieldName]: event.target.value,
    });
  };
  // const handlePhone = (fieldName) => (event) => {
  //   if (event.target.value.length === 1) {
  //     if (event.target.value !== "(") {
  //       setValues({
  //         ...values,
  //         [fieldName]: "(" + event.target.value,
  //       });
  //     }
  //   } else if (event.target.value.length === 4) {
  //     if (event.target.value !== ")") {
  //       setValues({
  //         ...values,
  //         [fieldName]: event.target.value + ")-",
  //       });
  //     }
  //   } else if (event.target.value.length === 9) {
  //     if (event.target.value !== "-") {
  //       setValues({
  //         ...values,
  //         [fieldName]: event.target.value + "-",
  //       });
  //     }
  //   } else {
  //     setValues({
  //       ...values,
  //       [fieldName]: event.target.value,
  //     });
  //   }
  // };
  // const alphabets = [
  //   "a",
  //   "b",
  //   "c",
  //   "d",
  //   "f",
  //   "g",
  //   "h",
  //   "i",
  //   "j",
  //   "k",
  //   "l",
  //   "m",
  //   "n",
  //   "o",
  //   "p",
  //   "q",
  //   "r",
  //   "s",
  //   "t",
  //   "u",
  //   "v",
  //   "w",
  //   "x",
  //   "y",
  //   "z",
  //   "A",
  //   "B",
  //   "C",
  //   "D",
  //   "F",
  //   "G",
  //   "H",
  //   "I",
  //   "J",
  //   "K",
  //   "L",
  //   "M",
  //   "N",
  //   "O",
  //   "P",
  //   "Q",
  //   "R",
  //   "S",
  //   "T",
  //   "U",
  //   "V",
  //   "W",
  //   "X",
  //   "Y",
  //   "Z",
  //   "+",
  //   "-",
  //   "/",
  //   "*",
  //   "!",
  //   "@",
  //   "#",
  //   "$",
  //   "%",
  //   "^",
  //   "&",
  //   "_",
  //   "(",
  //   ")",
  //   ",",
  //   ".",
  // ];
  const handlePhone = (fieldName) => (event) => {
    const numericValue = event.target.value.replace(/\D/g, "");
    let formattedValue = "";
    if (numericValue.length > 0) {
      formattedValue = `(${numericValue.slice(0, 3)}`;
    }
    if (numericValue.length > 3) {
      formattedValue += `)${numericValue.slice(3, 6)}`;
    }
    if (numericValue.length > 6) {
      formattedValue += `-${numericValue.slice(6, 10)}`;
    }
    setValues({
      ...values,
      [fieldName]: formattedValue,
    });
  };

  return (
    <>
      <div className="row">
        <div className="col-md-6">
          <div className="form-group my-3">
            <label
              className="f-14 text-dark-grey mb-12"
              data-label=""
              htmlFor="after_days"
            >
              First Name
            </label>
            <sup className="text-danger f-12">*</sup>
            <input
              type="text"
              className="form-control height-35 f-14"
              placeholder="Enter Your First Name"
              autoComplete="false"
              value={values.firstName}
              onChange={handleChange("firstName")}
              required
              maxLength={35}
            />
          </div>
        </div>

        <div className="col-md-6">
          <div className="form-group my-3">
            <label
              className="f-14 text-dark-grey mb-12"
              data-label=""
              htmlFor="after_days"
            >
              Last Name
            </label>
            <sup className="text-danger f-12">*</sup>
            <input
              type="text"
              className="form-control height-35 f-14"
              placeholder="Enter Your Last Name"
              name="lastName"
              autoComplete="false"
              value={values.lastName}
              onChange={handleChange("lastName")}
              required
              maxLength={35}
            />
          </div>
        </div>

        <div className="col-md-6">
          <div className="form-group my-3">
            <label
              className="f-14 text-dark-grey mb-12"
              data-label=""
              htmlFor="after_days"
            >
              Email
            </label>
            {/* <sup className="text-danger f-12">*</sup> */}
            <input
              type="Email"
              className="form-control height-35 f-14"
              placeholder="Enter Your Email"
              autoComplete="false"
              value={values.email}
              onChange={handleChange("email")}
            />
          </div>
        </div>

        <div className="col-md-6">
          <div className="form-group my-3">
            <label
              className="f-14 text-dark-grey mb-12"
              data-label=""
              htmlFor="after_days"
            >
              Phone Number
            </label>
            {/* <input
              type="tel"
              className="form-control height-35 f-14"
              minLength="14"
              maxLength="14"
              // required
              onKeyDown={(e) => {
                if (e.ctrlKey && e.code === "KeyV") {
                  return;
                }
                if (alphabets.includes(e.key)) {
                  e.preventDefault();
                }
                if (e.code === "Backspace" && values.phone.length === 6) {
                  setValues({
                    ...values,
                    phone: values.phone.substring(0, 4),
                  });
                }
                if (e.code === "Backspace" && values.phone.length === 2) {
                  setValues({
                    ...values,
                    phone: "",
                  });
                }
                if (e.code === "Backspace" && values.phone.length === 10) {
                  setValues({
                    ...values,
                    phone: values.phone.substring(0, 9),
                  });
                }
              }}
              name="phone"
              value={values.phone}
              onChange={handlePhone("phone")}
              placeholder="(123)-456-7890"
            /> */}
            <input
              type="tel"
              className="form-control height-35 f-14"
              name="phone"
              value={values.phone}
              onChange={handlePhone("phone")}
              placeholder="(123)456-7890"
            />
          </div>
        </div>

        <div className="col-md-12">
          <label htmlFor="allowed_file_types">Note: </label>
          <textarea
            type="text"
            name="allowed_file_types"
            id="allowed_file_types"
            placeholder="Enter The Note"
            className="form-control f-14"
            autoComplete="false"
            value={values.notes}
            onChange={handleChange("notes")}
          ></textarea>
        </div>
      </div>
      <div className="modal-footer">
        <button
          // type="submit"
          onClick={handleSubmit}
          className="btn-primary rounded f-14 p-2 mr-3"
        >
          Create
        </button>
      </div>
    </>
  );
};
export default ExternalCompanyUserCreateView;
