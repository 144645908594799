/** @format */

import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteReservation,
  getReservationsSchedule,
} from "../../../actions/reservationAction";

const AdminCancelBooking = ({
  showDeclineDropDown,
  openDeclineDropDown,
  current,
  index,
  assigned,
  fullData,
  setFullData,
}) => {
  const [values, setValues] = useState({
    _id: "",
    delete: "",
    cancelReason: "",
    status: "",
    uniqueOrderId: "",
    orderId: "",
  });
  const auth = useSelector((state) => state.auth);
  const reservation = useSelector((state) => state.reservation);
  const {loading} = reservation
  useEffect(() => {
    setValues({
      ...values,
      _id: current?._id ? current?._id : current?.id,
      uniqueOrderId: current?.uniqueOrderId ? current?.uniqueOrderId : "",
      orderId: current?.orderId,
    });
  }, [current]);
  const handleChange = (fieldName) => (event) => {
    setValues({
      ...values,
      [fieldName]: event.target.value,
    });
  };
  const data = {
    _id: values._id,
    delete: true,
    cancelReason: values.cancelReason,
    status: "Canceled",
    uniqueOrderId: values?.uniqueOrderId
      ? values.uniqueOrderId
      : "companyId:" + auth.user.companyId.id + "orderId" + values.orderId,
    dispatcherId: auth.user.id,
  };
  const dispatch = useDispatch();
  const handleSumbit = async (event) => {
    event.preventDefault();
    dispatch(deleteReservation(data, successCB));
    // setTimeout(() => {
    //   if (assigned === "unassigned") {
    //     // dispatch(getReservationsSchedule({
    //     //     companyId: auth?.user?.companyId?.id,
    //     //     today: moment().format('YYYY-MM-DD'),
    //     //     assigned: "unassigned",
    //     //     limit: reservation.unassigned.limit,
    //     //     page: reservation.unassigned.page === 1 ?
    //     //     reservation.unassigned.page :
    //     //     reservation.unassigned.results.length === 1 ?
    //     //     reservation.unassigned.page - 1:
    //     //     reservation.unassigned.page
    //     // }))
    //   } else if (assigned === "assigned") {
    //     // dispatch(getReservationsSchedule({
    //     //     companyId: auth?.user?.companyId?.id,
    //     //     today: moment().format('YYYY-MM-DD'),
    //     //     assigned: "assigned",
    //     //     limit: reservation.assigned.limit,
    //     //     page: reservation.assigned.page === 1 ?
    //     //     reservation.assigned.page :
    //     //     reservation.assigned.results.length === 1 ?
    //     //     reservation.assigned.page - 1:
    //     //     reservation.assigned.page
    //     // }))
    //   } else if (assigned === "pending") {
    //     // dispatch(getReservationsSchedule({
    //     //     companyId: auth?.user?.companyId?.id,
    //     //     today: moment().format('YYYY-MM-DD'),
    //     //     assigned: "pending",
    //     //     limit: reservation.pending.limit,
    //     //     page: reservation.pending.page === 1 ?
    //     //     reservation.pending.page :
    //     //     reservation.pending.results.length === 1 ?
    //     //     reservation.pending.page - 1:
    //     //     reservation.pending.page
    //     // }))
    //   }
    //   openDeclineDropDown();
    // }, 2000);
  };
  const successCB = (updatedData) => {
    setFullData((prevData) => {
      const updatedIndex = prevData.findIndex((item) => {
        return (
          item._id === updatedData.id || (item.id && item.id === updatedData.id)
        );
      });

      if (updatedIndex !== -1) {
        const newData = [...prevData];
        newData[updatedIndex] = { ...updatedData, _id: updatedData.id };
        return newData;
      }
      return prevData;
    });
    openDeclineDropDown();
  };
  return (
    <>
      <div
        className={`modal fade ${
          showDeclineDropDown === true ? "show modal-y-axis" : "hide"
        }`}
        role="dialog"
        data-backdrop="static"
        data-keyboard="false" // To prevent closing with the keyboard
        onClick={(e) => {
          // Check if the click occurred outside the modal content
          if (e.target === e.currentTarget) {
            openDeclineDropDown(); // Call your close modal function
          }
        }}
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <form onSubmit={handleSumbit}>
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLongTitle">
                  {" "}
                  Cancel Reason
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => openDeclineDropDown()}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="row">
                  <div className="col-md-12">
                    {/* <label htmlFor="allowed_file_types">Reason: </label> */}
                    <label>Reason: </label>
                    <textarea
                      type="text"
                      // name="allowed_file_types"
                      // id="allowed_file_types"
                      placeholder="Enter The Reason To Cancel"
                      className="form-control f-14"
                      autoComplete="false"
                      value={values.cancelReason}
                      onChange={handleChange("cancelReason")}
                      required
                    ></textarea>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn-secondary rounded f-14 p-2 mr-3"
                  data-dismiss="modal"
                  disabled={loading}
                  onClick={() => openDeclineDropDown()}
                >
                  Close
                </button>
                <button
                  type="submit"
                  disabled={loading}
                  className="btn-primary rounded f-14 p-2 mr-3"
                  // onClick={handleSubmit}
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminCancelBooking;
