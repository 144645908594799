/** @format */

import moment from "moment";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
// import img1 from '../../../assets/images/img1.jpg'

const AdminSettingVehicleFullDetailComponent = () => {
  const { state } = useLocation();
  const [data, setData] = useState({});
  useEffect(() => {
    setData({ ...data, ...state });
  }, [state]);
  return (
    <>
      <div className="row emp-dash-detail">
        <div className="col-xl-12 col-md-6 mb-4 mb-lg-0">
          <div className="card bg-white border-0 b-shadow-4 mb-4">
            {/* <div className="card-header bg-white border-0 text-capitalize d-flex justify-content-between p-20">
                        <h4 className="f-18 f-w-500 mb-0">Vehicle Info</h4>
                    </div> */}
            <div className="card-body ">
              <div className="row p-3">
                <div className="col-lg-12">
                  <div className="col-12 px-0 pb-3 d-lg-flex d-md-flex d-block">
                    <p className="mb-0  f-14 text-capitalize">
                      <strong style={{ fontSize: "18px" }}>Basic </strong>
                    </p>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="col-12 px-0 pb-3 d-lg-flex d-md-flex d-block">
                    <p className="mb-0  f-14 text-capitalize">
                      <strong>Vehicle#: </strong>
                      {data?.assetNo}
                    </p>
                  </div>
                </div>
                <div className="col-lg-6">
                  {/* <div className="pro-edit"> */}
                  {/* <a className="edit-icon" onClick={openDropDown}>
                                        <i className="fa fa-pencil"></i>
                                    </a> */}
                  {/* </div> */}
                  <div className="col-12 px-0 pb-3 d-lg-flex d-md-flex d-block">
                    <p className="mb-0 f-14 text-capitalize">
                      <strong>Type: </strong>
                      {data?.vehicleOwnership}
                    </p>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="col-12 px-0 pb-3 d-lg-flex d-md-flex d-block">
                    <p className="mb-0  f-14 text-capitalize">
                      <strong>Service Type: </strong>
                      {data?.serviceType?.name}
                    </p>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="col-12 px-0 pb-3 d-lg-flex d-md-flex d-block">
                    <p className="mb-0  f-14 text-capitalize">
                      <strong>Seats: </strong>
                      {data?.seats}
                    </p>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="col-12 px-0 pb-3 d-lg-flex d-md-flex d-block">
                    <p className="mb-0  f-14 text-capitalize">
                      <strong>Service Name: </strong>
                      {data?.serviceName ? data?.serviceName : "-"}
                    </p>
                  </div>
                </div>

                <div className="col-lg-12">
                  <div className="col-12 px-0 pb-3 d-lg-flex d-md-flex d-block">
                    <p className="mb-0  f-14 text-capitalize">
                      <strong style={{ fontSize: "18px" }}>
                        Vehicle Information{" "}
                      </strong>
                    </p>
                  </div>
                </div>

                <div className="col-lg-6">
                  <div className="col-12 px-0 pb-3 d-lg-flex d-md-flex d-block">
                    <p className="mb-0  f-14 text-capitalize">
                      <strong>Make: </strong>
                      {data?.make}
                    </p>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="col-12 px-0 pb-3 d-lg-flex d-md-flex d-block">
                    <p className="mb-0  f-14 w-30 text-capitalize">
                      <strong>Model: </strong>
                      {data?.model}
                    </p>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="col-12 px-0 pb-3 d-lg-flex d-md-flex d-block">
                    <p className="mb-0  f-14 text-capitalize">
                      <strong>Year: </strong>
                      {data?.year}
                    </p>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="col-12 px-0 pb-3 d-lg-flex d-md-flex d-block">
                    <p className="mb-0  f-14 text-capitalize">
                      <strong>VIN: </strong>
                      {data?.vin}
                    </p>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="col-12 px-0 pb-3 d-lg-flex d-md-flex d-block">
                    <p className="mb-0  f-14 text-capitalize">
                      <strong>Plate: </strong>
                      {data?.numberPlate}
                    </p>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="col-12 px-0 pb-3 d-lg-flex d-md-flex d-block">
                    <p className="mb-0  f-14 text-capitalize">
                      <strong>Color: </strong>
                      {data?.color}
                    </p>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="col-12 px-0 pb-3 d-lg-flex d-md-flex d-block">
                    <p className="mb-0  f-14 text-capitalize">
                      <strong>Mileage: </strong>
                      {data?.mileage}
                    </p>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="col-12 px-0 pb-3 d-lg-flex d-md-flex d-block">
                    <p className="mb-0  f-14 text-capitalize">
                      <strong>Vehicle Inspections Expiration: </strong>
                      {moment(data?.inspectionExpiration).format("MM/DD/YYYY")}
                    </p>
                  </div>
                </div>

                <div className="col-lg-12">
                  <div className="col-12 px-0 pb-3 d-lg-flex d-md-flex d-block">
                    <p className="mb-0  f-14 text-capitalize">
                      <strong style={{ fontSize: "18px" }}>Insurance </strong>
                    </p>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="col-12 px-0 pb-3 d-lg-flex d-md-flex d-block">
                    <p className="mb-0  f-14 text-capitalize">
                      <strong>Insurance Company Name: </strong>
                      {data?.insuranceCompanyName}
                    </p>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="col-12 px-0 pb-3 d-lg-flex d-md-flex d-block">
                    <p className="mb-0  f-14 text-capitalize">
                      <strong>Insurance Policy Number: </strong>
                      {data?.insurancePolicyNumber}
                    </p>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="col-12 px-0 pb-3 d-lg-flex d-md-flex d-block">
                    <p className="mb-0  f-14 text-capitalize">
                      <strong>Insurance Expiration Date: </strong>
                      {moment(data?.insuranceExpirationDate).format(
                        "MM/DD/YYYY"
                      )}
                    </p>
                  </div>
                </div>

                <div className="col-lg-12">
                  <div className="col-12 px-0 pb-3 d-lg-flex d-md-flex d-block">
                    <p className="mb-0  f-14 text-capitalize">
                      <strong style={{ fontSize: "18px" }}>
                        Brakes Expiration{" "}
                      </strong>
                    </p>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="col-12 px-0 pb-3 d-lg-flex d-md-flex d-block">
                    <p className="mb-0  f-14 text-capitalize">
                      <strong>Brakes Expiration Date: </strong>
                      {moment(data?.brakesExpiration).format("MM/DD/YYYY")}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default AdminSettingVehicleFullDetailComponent;

{
  /* <div className="col-12 px-0 pb-3 d-lg-flex d-md-flex d-block">
    <p className="mb-0  f-14 w-30 text-capitalize">Vehicle#:</p>
    <p className="mb-0 text-dark-grey f-14 w-70 text-wrap">{data?.assetNo}</p>
</div>
<div className="col-12 px-0 pb-3 d-lg-flex d-md-flex d-block">
    <p className="mb-0  f-14 w-30 text-capitalize">Type:</p>
    <p className="mb-0 text-dark-grey f-14 w-70 text-wrap">{data?.vehicleOwnership}	</p>
</div>
<div className="col-12 px-0 pb-3 d-lg-flex d-md-flex d-block">
    <p className="mb-0  f-14 w-30 text-capitalize">Mode:</p>
    <p className="mb-0 text-dark-grey f-14 w-70 text-wrap">{data?.serviceType && data?.serviceType.name}</p>
</div>
<div className="col-12 px-0 pb-3 d-lg-flex d-md-flex d-block">
    <p className="mb-0 f-14 w-30 text-capitalize">Make:</p>
    <p className="mb-0 text-dark-grey f-14 w-70 text-wrap">{data?.make}</p>
</div> <div className="col-12 px-0 pb-3 d-lg-flex d-md-flex d-block">
    <p className="mb-0  f-14 w-30 text-capitalize">Model:</p>
    <p className="mb-0 text-dark-grey f-14 w-70 text-wrap">{data?.model}</p>
</div>
<div className="col-12 px-0 pb-3 d-lg-flex d-md-flex d-block">
    <p className="mb-0  f-14 w-30 text-capitalize">Year:</p>
    <p className="mb-0 text-dark-grey f-14 w-70 text-wrap">{data?.year}</p>
</div>
<div className="col-12 px-0 pb-3 d-lg-flex d-md-flex d-block">
    <p className="mb-0  f-14 w-30 text-capitalize">VIN:</p>
    <p className="mb-0 text-dark-grey f-14 w-70 text-wrap">{data?.vin}	</p>
</div>
<div className="col-12 px-0 pb-3 d-lg-flex d-md-flex d-block">
    <p className="mb-0  f-14 w-30 text-capitalize">Plate:</p>
    <p className="mb-0 text-dark-grey f-14 w-70 text-wrap">{data?.numberPlate}</p>
</div>
<div className="col-12 px-0 pb-3 d-lg-flex d-md-flex d-block">
    <p className="mb-0  f-14 w-30 text-capitalize">Color:</p>
    <p className="mb-0 text-dark-grey f-14 w-70 text-wrap">{data?.color}</p>
</div>
<div className="col-12 px-0 pb-3 d-lg-flex d-md-flex d-block">
    <p className="mb-0  f-14 w-30 text-capitalize">Mileage:</p>
    <p className="mb-0 text-dark-grey f-14 w-70 text-wrap">{data?.mileage}</p>
</div> */
}
