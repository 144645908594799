import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getRouteById } from "../../../actions/routeActions";
import moment from "moment";
import LoadingComponent from "../../../components/LoadingComponent";
import DriverPassengerList from "./DriverPassengerList";
import DriverRidePatternTable from "./DriverRidePatternTable";
import PayerPassengerList from "./PayerPassengerList";
import PayerRidePatternTable from "./PayerRidePatternTable";

const DriverRouteViewDetailsComponent = ({payer}) => {
    const dispatch = useDispatch();
    const params = useParams();
    const routes = useSelector((state) => state.routes);
    const service = useSelector((state) => state.service);
  
    const { loading, routeDetails } = routes;
  
    useEffect(() => {
      dispatch(getRouteById(params.id));
    }, []);
  
    return (
        <>
            {loading && <LoadingComponent />}
            <div className="d-flex flex-column w-tables rounded mt-3 bg-white">
                <h5 className="p-2 py-3">Passengers List</h5>
                <div
                    id="leads-table_wrapper"
                    className="dataTables_wrapper dt-bootstrap4 no-footer"
                >
                    <div className="row">
                        <div className="col-sm-12">
                            {
                                !payer &&
                                <DriverPassengerList />
                            }
                            {
                                payer &&
                                <PayerPassengerList />
                            }
                        </div>
                    </div>
                </div>
            </div>

            <div className="d-flex flex-column w-tables rounded mt-3 bg-white">
                <h5 className="p-2 py-3">Ride Pattern</h5>
                <div
                    id="leads-table_wrapper"
                    className="dataTables_wrapper dt-bootstrap4 no-footer"
                >
                    <div className="row">
                        <div className="col-sm-12">
                            {
                                !payer &&
                                <DriverRidePatternTable />
                            }
                            {
                                payer &&
                                <PayerRidePatternTable />
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default DriverRouteViewDetailsComponent