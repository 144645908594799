/** @format */

// import React, { useEffect, useState } from 'react'
// import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';
// import { Marker } from '@react-google-maps/api';
// import AutoComplete from './AutoComplete';
// import { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
// import { clickLatLng, clickMap } from '../../../actions/locationActions';
// import { useDispatch } from 'react-redux'
// // import {  useSelector } from 'react-redux'

// const containerStyle = {
//   width: '100%',
//   height: '40vh'
// };

// function MyComponent({currLat, currLng, searchNeeded}) {
//   const dispatch = useDispatch()
//   const { isLoaded } = useJsApiLoader({
//     id: 'google-map-script',
//     googleMapsApiKey: "AIzaSyDv5TZ4_il1sukhMFSFChda0UHcL0js0Zg"
//   })
//   const [mapApiLoaded, setMapApiLoaded] = useState(true);
//     const [mapInstance, setMapInstance] = useState(null);
//     const [mapApi, setMapApi] = useState(null);
//     const [address, setAddress] = useState("");
//     const [center, setCenter] = useState([]);
//     const [places, setPlaces] = useState([]);
//     const [zoom, setZoom] = useState(15);
//     const [draggable, setDraggable] = useState(true)
//     const [lat, setLat] = useState(null)
//   const [coordinates, setCoordinates] = useState({
//     lat: null,
//     lng:null,
//   });
//   console.log("currLat", typeof(currLat) )
//   useEffect(() => {
//     setCurrentLocation()
//     if (typeof(currLat) === "number"){
//       // console.log("number hai bhai")
//       existLocation()
//     }
//   },[])
//   useEffect(() => {
//     existLocation()
//   },[currLat])
//   const existLocation = (() => {
//     setCenter({
//       lat: currLat,
//       lng: currLng,
//     })
//     setCoordinates({
//       lat: currLat,
//       lng: currLng
//     })
//     dispatch(clickLatLng(currLat,currLng))
//     dispatch(clickMap("https://www.google.com/maps/search/?api=1&query="+currLat+","+currLng))
//   })
//   const onMarkerInteraction = (childKey, childProps, mouse) => {
//     setDraggable(false);
//     setCoordinates({
//       lat: mouse.lat,
//       lng: mouse.lng
//     })
//     dispatch(clickLatLng(mouse.lat,mouse.lng))
//     dispatch(clickMap("https://www.google.com/maps/search/?api=1&query="+mouse.lat+","+mouse.lng))
// };
// const onMarkerInteractionMouseUp = (childKey, childProps, mouse) => {
//   setDraggable(true);
// };
// function apiHasLoaded(map, maps){
//   setMapApiLoaded(true)
//   setMapInstance(map)
//   setMapApi(maps)
// };

// const addPlace = (place) => {
//   setPlaces([place]);
//   setCoordinates({
//     lat: place.geometry.location.lat(),
//     lng: place.geometry.location.lng(),
//   });
//   // console.log(place, "place data")
//   // dispatch(clickLatLng(coordinates.lat,coordinates.lng))
//   dispatch(clickLatLng(place.geometry.location.lat(),place.geometry.location.lng()))
//   dispatch(clickMap("https://www.google.com/maps/search/?api=1&query="+place.geometry.location.lat()+","+place.geometry.location.lng()))
// };
// const setCurrentLocation = () => {
//   if ("geolocation" in navigator) {
//     navigator.geolocation.getCurrentPosition((position) => {
//         // setCenter([position.coords.latitude, position.coords.longitude])
//         setCenter({
//           lat: position.coords.latitude,
//           lng: position.coords.longitude,
//         })
//         setCoordinates({
//           lat: position.coords.latitude,
//           lng: position.coords.longitude,
//         });
//         console.log(position.coords.latitude, position.coords.longitude)
//     });
//   }
//   if(typeof(currLat) === "number"){
//     setCenter({
//       lat: currLat,
//       lng: currLng,
//     })
//     setCoordinates({
//       lat: currLat,
//       lng: currLng
//     })
//   }
// }
// const setLocationInRedux = () => {
//   dispatch(clickLatLng(coordinates.lat,coordinates.lng))
//   dispatch(clickMap("https://www.google.com/maps/search/?api=1&query="+coordinates.lat+","+coordinates.lng))
//   if(typeof(currLat) === "number"){
//     dispatch(clickLatLng(currLat,currLng))
//     dispatch(clickMap("https://www.google.com/maps/search/?api=1&query="+currLat+","+currLng))
//   }
// }
// // useEffect(() => {
// //   setCurrentLocation()
// // },[])
// useEffect(() => {
//   setLocationInRedux()
// },[coordinates.lat, coordinates.lng])

// const handleSelect = async (value) => {
//   const results = await geocodeByAddress(value);
//   const latLng = await getLatLng(results[0]);
//   setAddress(value);
//   // addPlace(value)
//   setCoordinates(latLng);
//   setCenter(latLng)
//   console.log("chal gaya mein", latLng)
//   // dispatch(clickLatLng(coordinates.lat,coordinates.lng))
//   dispatch(clickLatLng(latLng.lat,latLng.lng))
//   dispatch(clickMap("https://www.google.com/maps/search/?api=1&query="+coordinates.lat+","+coordinates.lng))
// };

//   const _onClick = (value) => {
//     // console.log(value.latLng.lat)
//     console.log(value.latLng.lat(),"Sttt")
//     setCoordinates({
//       lat: value.latLng.lat(),
//       lng: value.latLng.lng(),
//     });
//     // dispatch(clickLatLng(coordinates.lat,coordinates.lng))
//     dispatch(clickLatLng(value.latLng.lat(),value.latLng.lng()))
//     dispatch(clickMap("https://www.google.com/maps/search/?api=1&query="+coordinates.lat+","+coordinates.lng))
//   };

//   const [map, setMap] = React.useState(null)

//   const onUnmount = React.useCallback(function callback(map) {
//     setMap(null)
//   }, [])
//   console.log(coordinates)
//   return isLoaded ? (
//         <>
//         {
//           mapApiLoaded && (
//           <div>
//             {/* { !searchNeeded &&  */}
//             <AutoComplete
//               map={mapInstance}
//               mapApi={mapApi}
//               addplace={addPlace}
//               handleSelect={handleSelect}
//               coordinates={coordinates}
//               address={address}
//               setAddress={setAddress}
//             />
//              {/* } */}
//           </div>
//         )
//       }

// <GoogleMap
// mapContainerStyle={containerStyle}
// center={center}
// zoom={15}
// onUnmount={onUnmount}
// onClick={_onClick}
// >
// <Marker
//   position={coordinates}
// />
// </GoogleMap>
//         </>
//   ) : <></>
// }
// export default MyComponent

import React, { useEffect, useState } from "react";
import {
  GoogleMap,
  useJsApiLoader,
  StandaloneSearchBox,
} from "@react-google-maps/api";
import { Marker } from "@react-google-maps/api";
// import AutoComplete from '../components/adminComponents/Map/AutoComplete';
import {
  geocodeByAddress,
  geocodeByPlaceId,
  getLatLng,
} from "react-places-autocomplete";
import AutoComplete from "./AutoComplete";

const containerStyle = {
  width: "100%",
  height: "40vh",
};

// const position = {
//   lat: 24.92622197783596,
//  lng: 67.13584109762604,
// }
// const center = {
//   lat: 24.92622197783596,
//   lng: 67.13584109762604,
// };

function MyComponent({ values2, setValues2, addressss }) {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyC653TecaJSucmBmqXPr9twuP_jG55NACA",
  });
  const [mapApiLoaded, setMapApiLoaded] = useState(true);
  const [mapInstance, setMapInstance] = useState(null);
  const [mapApi, setMapApi] = useState(null);
  const [places, setPlaces] = useState([]);
  const [center, setCenter] = useState([]);
  const [zoom, setZoom] = useState(15);
  const [address, setAddress] = useState("");
  const [draggable, setDraggable] = useState(true);
  const [lat, setLat] = useState(null);
  const [coordinates, setCoordinates] = useState({
    lat: null,
    lng: null,
  });
  // useEffect(() => {
  //   setCurrentLocation()
  // },[])
  useEffect(() => {
    setAddress(addressss);
    // console.log(address,"addressaddress")
  }, [addressss]);

  const onMarkerInteraction = (childKey, childProps, mouse) => {
    setDraggable(false);
    setCoordinates({
      lat: mouse.lat,
      lng: mouse.lng,
    });
  };
  const onMarkerInteractionMouseUp = (childKey, childProps, mouse) => {
    setDraggable(true);
  };
  function apiHasLoaded(map, maps) {
    setMapApiLoaded(true);
    setMapInstance(map);
    setMapApi(maps);
  }

  const addPlace = (place) => {
    // console.log(place)
    setPlaces([place]);
    setCoordinates({
      lat: place.geometry.location.lat(),
      lng: place.geometry.location.lng(),
    });
  };
  // const setCurrentLocation = () => {
  //   if (coordinates.lat === null){
  //     if ("geolocation" in navigator) {
  //       navigator.geolocation.getCurrentPosition((position) => {
  //           // setCenter([position.coords.latitude, position.coords.longitude])
  //           setCenter({
  //             lat: position.coords.latitude,
  //             lng: position.coords.longitude,
  //           })
  //           setCoordinates({
  //             lat: position.coords.latitude,
  //             lng: position.coords.longitude,
  //           });
  //           console.log(position.coords.latitude, position.coords.longitude)
  //       });
  //     }
  //   }
  // }
  const handleSelect = async (value) => {
    const results = await geocodeByAddress(value);
    const latLng = await getLatLng(results[0]);
    const place = await geocodeByPlaceId(results[0].place_id);
    const [place2] = await geocodeByPlaceId(results[0].place_id);
    // console.log(results, latLng, value, place, place2 )
    const { long_name: postalCode = "" } =
      place2.address_components.find((c) => c.types.includes("postal_code")) ||
      {};
    // console.log("postalCode",postalCode);
    // for (const component of results[0].address_components){
    //   console.log(component.types[0], component.long_name)
    // }
    let postal_code = results[0].address_components.find((c) =>
      c.types.includes("postal_code")
    );
    let locality = results[0].address_components.find((c) =>
      c.types.includes("locality")
    );
    let administrative_area_level_1 = results[0].address_components.find((c) =>
      c.types.includes("administrative_area_level_1")
    );
    // console.log(postal_code, administrative_area_level_1,"aaaaaaaa")
    // console.log("map data", {
    //   // ...values2,
    //   latitude: latLng.lat,
    //   longitude: latLng.lng,
    //   map:
    //     "https://www.google.com/maps/search/?api=1&query=" +
    //     latLng.lat +
    //     "," +
    //     latLng.lng,
    //   address: results[0].formatted_address,
    //   city: locality.long_name ? locality.long_name : "",
    //   state: administrative_area_level_1.long_name
    //     ? administrative_area_level_1.long_name
    //     : "",
    //   zip: postal_code !== undefined ? postal_code.long_name : "",
    // });
    setValues2({
      ...values2,
      latitude: latLng.lat,
      longitude: latLng.lng,
      map:
        "https://www.google.com/maps/search/?api=1&query=" +
        latLng.lat +
        "," +
        latLng.lng,
      address: results[0].formatted_address,
      city: locality.long_name ? locality.long_name : "",
      state: administrative_area_level_1.long_name
        ? administrative_area_level_1.long_name
        : "",
      zip: postal_code !== undefined ? postal_code.long_name : "",
    });
    setAddress(value);
    // addPlace(value)
    setCoordinates(latLng);
    setCenter(latLng);
  };

  // useEffect(() => {

  // Geocode.fromLatLng(coordinates.lat, coordinates.lng).then(
  //   (response) => {
  //     setAddress(response.results[0].formatted_address);
  //   },
  //   (error) => {
  //     console.error(error);
  //   }
  // );

  // }, [coordinates.lat, coordinates.lng])
  const _onChange = ({ center, zoom }) => {
    // console.log("center onChange==>", center)
    setCenter(center);
    setZoom(zoom);
  };
  const _onClick = (value) => {
    // console.log(value.latLng.lat)
    // console.log(value.latLng.lat(),"S")
    setCoordinates({
      lat: value.latLng.lat(),
      lng: value.latLng.lng(),
    });
  };

  const _onClick2 = (e) => {
    // console.log(e)
  };

  const [map, setMap] = React.useState(null);

  const onLoad = React.useCallback(function callback(map) {
    const bounds = new window.google.maps.LatLngBounds(coordinates);
    map.fitBounds(bounds);
    setMap(map);
  }, []);

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);
  // console.log(coordinates)
  const onLoad2 = (ref) => (this.searchBox = ref);

  const onPlacesChanged = (e) => console.log(e);

  return isLoaded ? (
    <>
      {mapApiLoaded && (
        <div>
          <AutoComplete
            map={mapInstance}
            mapApi={mapApi}
            addplace={addPlace}
            handleSelect={handleSelect}
            coordinates={coordinates}
            address={address}
            setAddress={setAddress}
          />
        </div>
      )}
    </>
  ) : (
    <></>
  );
}

export default MyComponent;
