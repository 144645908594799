/** @format */

import React, { useEffect, useState } from "react";
import AdminHeader from "../../components/adminComponents/adminHeaderSidebarTitleComponents/AdminHeader";
import AdminSidebar from "../../components/adminComponents/adminHeaderSidebarTitleComponents/AdminSidebar";
// import AdminDriverSettlement from "../../components/adminComponents/adminAccountingComponent/AdminDriverSettlement";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
// import AdminSearchByFilterComponentV2 from "../../components/adminComponents/adminDispatchingComponents/AdminSearchByFilterComponentV2";
// import Select from "react-select";
import {
  // getFilterSettlements,
  overviewTableView,
} from "../../actions/invoiceActions";
// import { fromTo } from "../../actions/reservationAction";
// import { tabAllDriverSettlementSearchFilter } from "../../actions/tabActions";
// import AdminDriverSettlementSearchFilter from "../../components/adminComponents/adminAccountingComponent/AdminDriverSettlementSearchFilter";
import {
  // navigateUserFunc,
  paginationDataToShow,
} from "../../actions/generalActions";
import {
  getAllCardsData3,
  mostTripsByDriverInAWeek,
} from "../../actions/kpiActions";
// import AdminDiverSettlementRow from "../../components/adminComponents/adminAccountingComponent/AdminDiverSettlementRow";
// import AdminDiverSettlementSearchFilterRow from "../../components/adminComponents/adminAccountingComponent/AdminDiverSettlementSearchFilterRow";
import shift1 from "../../assets/images/load.png";
import AdminDiverSettlementOverviewRow from "../../components/adminComponents/adminAccountingComponent/AdminDiverSettlementOverviewRow";
import { getAllVehiclesByCompanyId } from "../../actions/vehicleActions";
import { allCompanyUsers, allEmployees } from "../../actions/userActions";
import ExcelExport from "../../ExcelExport";
import { getServicesByCompanyId } from "../../actions/serviceActions";
import { getBaseLocation } from "../../actions/locationActions";

const AdminAccountingOverviewPage = () => {
  const navigate = useNavigate();
  const [showFilter, setShowFilter] = useState(false);
  const auth = useSelector((state) => state.auth);
  const invoices = useSelector((state) => state.invoices);
  const vehicle = useSelector((state) => state.vehicle);
  const dispatch = useDispatch();
  const myState = useSelector((state) => state.changeTheTab);
  const kpi = useSelector((state) => state.kpi);
  const cardsData2 = async (e) => {
    const chicagoDateOptions = {
      timeZone: "America/Chicago",
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    };
    const chicagoDate = new Date().toLocaleDateString(
      "en-US",
      chicagoDateOptions
    );
    const parts = chicagoDate.split("/");
    const formattedChicagoDate = `${parts[2]}-${parts[0]}-${parts[1]}`;
    dispatch(
      getAllCardsData3({
        companyId: auth.user.companyId.id,
        today: formattedChicagoDate,
      })
    );
    dispatch(
      mostTripsByDriverInAWeek({
        companyId: auth.user.companyId.id,
        today: formattedChicagoDate,
      })
    );
    dispatch(
      getAllVehiclesByCompanyId({ companyId: auth?.user?.companyId?.id })
    );
    dispatch(overviewTableView(orderSearch));
    dispatch(
      allEmployees({
        companyId: auth.user.companyId.id,
        userId: auth.user.id,
      })
    );
    dispatch(
      allCompanyUsers({
        companyId: auth?.user?.companyId?.id,
      })
    );
    dispatch(getServicesByCompanyId({ companyId: auth.user.companyId.id }));
    dispatch(getBaseLocation({ companyId: auth.user.companyId.id }));
  };

  const [currentPage, setCurrentPage] = useState(1);
  const [sortField, setSortField] = useState("driverId.firstName");
  const [sortOrder, setSortOrder] = useState("asc"); //asc, desc
  const [searchTerm, setSearchTerm] = useState("");
  const [limit, setLimit] = useState(10); // Set your default limit here

  // Function to handle pagination, sorting, and searching
  const handlePagination = (page, field, order, search, newLimit) => {
    let newPage;
    if (page < 1) {
      newPage = 1;
    } else if (page > paginatedData.totalPages) {
      newPage = paginatedData.totalPages;
    } else {
      newPage = page;
    }
    setCurrentPage(newPage);
    setSortField(field);
    setSortOrder(order);
    setSearchTerm(search);
    setLimit(newLimit);
  };

  const fullData = invoices?.overviewTable;
  // Use the pagination function to get the data for the current page
  const paginatedData = paginationDataToShow(
    limit,
    currentPage,
    fullData,
    sortField,
    sortOrder,
    searchTerm
  );

  // console.log("paginatedData",paginatedData)
  // Function to handle sorting icon click
  const handleSortClick = (field, order) => {
    // Call handlePagination with sorting parameters
    handlePagination(currentPage, field, order, searchTerm, limit);
  };
  // Function to determine the color based on the current sorting order
  const getArrowColor = (field, direction) => {
    return sortField === field && sortOrder === direction
      ? "#000000"
      : "#A0A0A0";
  };

  let orderSearch;
  const chicagoDateOptions = {
    timeZone: "America/Chicago",
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  };

  const chicagoDate = new Date().toLocaleDateString(
    "en-US",
    chicagoDateOptions
  );
  const currentDate = new Date(chicagoDate);

  // Adjust to get the Monday of the current week
  const firstDayOfWeek = new Date(currentDate);
  firstDayOfWeek.setDate(
    currentDate.getDate() - ((currentDate.getDay() + 6) % 7)
  );

  // Calculate the Sunday of the current week
  const lastDayOfWeek = new Date(firstDayOfWeek);
  lastDayOfWeek.setDate(firstDayOfWeek.getDate() + 6);

  // Format the dates to YYYY-MM-DD
  const weekFirst = `${firstDayOfWeek.getFullYear()}-${String(
    firstDayOfWeek.getMonth() + 1
  ).padStart(2, "0")}-${String(firstDayOfWeek.getDate()).padStart(2, "0")}`;
  const weekLast = `${lastDayOfWeek.getFullYear()}-${String(
    lastDayOfWeek.getMonth() + 1
  ).padStart(2, "0")}-${String(lastDayOfWeek.getDate()).padStart(2, "0")}`;
  orderSearch = {
    companyId: auth.user.companyId.id,
    from: weekFirst,
    to: weekLast,
  };

  const data = invoices?.overviewTable?.map((e) => {
    const vehicleFound = vehicle?.vehicle?.find(
      (x) => x.id === e?.driverId?.vehicleId
    );
    return {
      "Driver Name": e?.driverId?.firstName + " " + e?.driverId?.lastName,
      "Vehicle#": vehicleFound?.assetNo,
      Type: vehicleFound?.serviceType?.name,
      "# of Trips": e?.reservation?.length,
      "Date Range":
        moment(weekFirst).format("MMM D") +
        " - " +
        moment(weekLast).format("MMM D, YYYY"),
      "Weekly Earning": parseFloat(e?.totalPay).toFixed(2),
    };
  });

  useEffect(() => {
    cardsData2();
  }, []);
  return (
    <>
      <AdminHeader />
      <AdminSidebar />
      <div className="body-wrapper clearfix">
        <section className="main-container " id="fullscreen">
          <div className="px-4 py-2 border-top-0 emp-dashboard">
            <div className="d-lg-flex d-md-flex d-block py-4">
              <div>
                <h4 className=" mb-0 f-21 text-capitalize font-weight-bold">
                  Overview
                </h4>
              </div>
            </div>
            <div className="row emp-dash-detail">
              <div className="col-xl-12 col-lg-12 col-md-12 e-d-info-notices">
                <div className="row">
                  <div className="col-md-12">
                    <div className="row">
                      <div className="col-md-3">
                        <div className="b-shadow-4 rounded p-2 bg-col-2">
                          <div className="card-body p-2 clearfix">
                            <div className="driver-text mb-3">
                              <div className="h5 f-18 f-w-600 font-xs">
                                Monthly Active Driver
                              </div>
                            </div>
                            <div className="d-flex justify-content-between mb-2">
                              <div className="driver-text">
                                <p className="f-16 f-w-600 mb-0">
                                  Total Drivers
                                </p>
                              </div>
                              <div className="driver-text">
                                <p className="f-21 font-weight-bold mb-0">
                                  {kpi?.cardsData2?.activeDriverThisMonth}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="b-shadow-4 rounded p-2 bg-col-2">
                          <div className="card-body p-1 clearfix">
                            <div className="driver-text mb-3">
                              <div className="h5 f-18 f-w-600 font-xs">
                                Highest Trips Driver Weekly
                              </div>
                            </div>
                            <div className="d-flex justify-content-between mb-2">
                              <div className="driver-text">
                                <p className="f-16 f-w-600 mb-0">
                                  Total Orders
                                </p>
                              </div>
                              <div className="driver-text">
                                <p className="f-21 font-weight-bold mb-0">
                                  {kpi?.mostTripsByDriverInAWeek?.maxCount}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="b-shadow-4 rounded p-2 bg-col-2">
                          <div className="card-body p-2 clearfix">
                            <div className="driver-text mb-3">
                              <div className="h5 f-18 f-w-600 font-xs">
                                Total Trips Monthly
                              </div>
                            </div>
                            <div className="d-flex justify-content-between mb-2">
                              <div className="driver-text">
                                <p className="f-16 f-w-600 mb-0">
                                  Total Orders
                                </p>
                              </div>
                              <div className="driver-text">
                                <p className="f-21 font-weight-bold mb-0">
                                  {kpi?.cardsData2?.pastThisMonthOrderCount}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="b-shadow-4 rounded p-2 bg-col-2">
                          <div className="card-body p-2 clearfix">
                            <div className="driver-text mb-3">
                              <div className="h5 f-18 f-w-600 font-xs">
                                Total Trips Weekly
                              </div>
                            </div>
                            <div className="d-flex justify-content-between mb-2">
                              <div className="driver-text">
                                <p className="f-16 f-w-600 mb-0">
                                  Total Orders
                                </p>
                              </div>
                              <div className="driver-text">
                                <p className="f-21 font-weight-bold mb-0">
                                  {kpi?.cardsData2?.pastThisWeekOrderCount}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-xl-12 col-lg-12 col-md-12 e-d-info-notices">
                <div className="row mb-3 mt-xl-0 mt-lg-4 mt-md-4 mt-4">
                  <div className="col-md-12 mt-md-4">
                    <div className="card">
                      <div
                        className="card-header"
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div style={{ display: "flex" }}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className="bi bi-clipboard-data"
                            viewBox="0 0 16 16"
                          >
                            <path d="M4 11a1 1 0 1 1 2 0v1a1 1 0 1 1-2 0v-1zm6-4a1 1 0 1 1 2 0v5a1 1 0 1 1-2 0V7zM7 9a1 1 0 0 1 2 0v3a1 1 0 1 1-2 0V9z"></path>
                            <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z"></path>
                            <path d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z"></path>
                          </svg>
                          <span className="f-18 font-weight-bold text-dark mx-1 ">
                            Upcoming Settlement
                          </span>
                          <span className="f-16 mx-3">
                            {" "}
                            {moment(weekFirst).format("MMM D")}
                            {" - "}
                            {moment(weekLast).format("MMM D, YYYY")}
                          </span>
                        </div>
                        <div className="d-inline">
                          <ExcelExport
                            excelData={data}
                            fileName={`Upcoming Settlement Report`}
                          />
                        </div>
                      </div>
                      <div className="card-body">
                        <div className="d-flex ">
                          <div className="flex-grow-1">
                            <div
                              className="dataTables_length d-flex"
                              id="leads-table_length"
                            >
                              <div className="p-2">
                                <label>Show entries</label>
                              </div>
                              <label>
                                <select
                                  name="leads-table_length"
                                  aria-controls="leads-table"
                                  className="custom-select custom-select-sm form-control form-control-sm"
                                  autoComplete="false"
                                  value={limit}
                                  onChange={(e) =>
                                    handlePagination(
                                      1,
                                      sortField,
                                      sortOrder,
                                      searchTerm,
                                      parseInt(e.target.value)
                                    )
                                  }
                                >
                                  {[10, 25, 50, 100].map((current, i) => {
                                    return (
                                      <option value={current} key={i}>
                                        {current}
                                      </option>
                                    );
                                  })}
                                </select>
                              </label>
                              <div
                                id="table-actions"
                                className="flex-grow-1 align-items-center"
                              >
                                <h4 className=" mb-0 f-21 text-capitalize font-weight-bold"></h4>
                              </div>
                            </div>
                          </div>
                          <div className="task-search task-search-new search-col-table d-flex  py-1 px-lg-3 px-0 align-items-center">
                            <form className="w-100 mr-1 mr-lg-0 mr-md-1 ml-md-1 ml-0 ml-lg-0" onSubmit={(e) => e.preventDefault()}>
                              <div className="input-group bg-grey rounded">
                                <div className="input-group-prepend">
                                  <span className="input-group-text border-0 bg-additional-grey">
                                    <i className="fa fa-search f-13 text-dark-grey"></i>
                                  </span>
                                </div>
                                <input
                                  type="text"
                                  className="form-control f-14 p-1 border-additional-grey"
                                  id="search-text-field"
                                  placeholder="Start typing to search"
                                  value={searchTerm}
                                  onChange={(e) =>
                                    handlePagination(
                                      1,
                                      sortField,
                                      sortOrder,
                                      e.target.value,
                                      limit
                                    )
                                  }
                                />
                              </div>
                            </form>
                          </div>
                        </div>
                        <div
                          id="leads-table_wrapper"
                          className="dataTables_wrapper dt-bootstrap4 no-footer"
                        >
                          <div className="row">
                            <div className="col-sm-12">
                              <table
                                className="table border w-100 dataTable no-footer"
                                id="leads-table"
                                role="grid"
                                aria-describedby="leads-table_info"
                                style={{ overflowX: "auto" }}
                              >
                                <thead className="card-header">
                                  <tr role="row">
                                    <th
                                      title="Driver Name"
                                      className="sorting_disabled border-right"
                                      rowspan="1"
                                      colspan="1"
                                      aria-label="Next Follow Up"
                                    >
                                      Driver Name
                                      <span className="right-sorting-new">
                                        <i
                                          className="fas fa-long-arrow-alt-up"
                                          style={{
                                            color: getArrowColor(
                                              "driverId.firstName",
                                              "asc"
                                            ),
                                          }}
                                          onClick={() =>
                                            handleSortClick(
                                              "driverId.firstName",
                                              "asc"
                                            )
                                          }
                                        ></i>
                                        <i
                                          className="fas fa-long-arrow-alt-down"
                                          style={{
                                            color: getArrowColor(
                                              "driverId.firstName",
                                              "desc"
                                            ),
                                          }}
                                          onClick={() =>
                                            handleSortClick(
                                              "driverId.firstName",
                                              "desc"
                                            )
                                          }
                                        ></i>
                                      </span>
                                    </th>
                                    <th
                                      title="Vehicle#"
                                      className="sorting border-right"
                                      tabIndex="0"
                                      aria-controls="leads-table"
                                      rowspan="1"
                                      colspan="1"
                                      aria-label="Lead Agent: activate to sort column ascending"
                                    >
                                      Vehicle#
                                    </th>
                                    <th
                                      title="Type"
                                      className="sorting border-right"
                                      tabIndex="0"
                                      aria-controls="leads-table"
                                      rowspan="1"
                                      colspan="1"
                                      aria-label="Lead Agent: activate to sort column ascending"
                                    >
                                      Type
                                    </th>
                                    <th
                                      title="#ofTrips"
                                      className="sorting border-right"
                                      tabIndex="0"
                                      aria-controls="leads-table"
                                      rowspan="1"
                                      colspan="1"
                                      aria-label="Lead Agent: activate to sort column ascending"
                                    >
                                      # of Trips
                                    </th>
                                    <th
                                      title="Date Range"
                                      className="sorting border-right"
                                      tabIndex="0"
                                      aria-controls="leads-table"
                                      rowspan="1"
                                      colspan="1"
                                      aria-label="Lead Agent: activate to sort column ascending"
                                    >
                                      Date Range
                                    </th>
                                    <th
                                      title="Weekly Earning"
                                      className="sorting border-right"
                                      tabIndex="0"
                                      aria-controls="leads-table"
                                      rowspan="1"
                                      colspan="1"
                                      aria-label="Lead Agent: activate to sort column ascending"
                                    >
                                      Weekly Earning
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {invoices &&
                                    invoices?.overviewTable &&
                                    invoices?.overviewTable?.length > 0 &&
                                    paginatedData?.results.map((current, i) => {
                                      // if (current.delete === false) {
                                      return (
                                        <AdminDiverSettlementOverviewRow
                                          current={current}
                                          i={i}
                                          key={i}
                                          // handleModal={handleModal}
                                        />
                                      );
                                      // }
                                    })}
                                </tbody>
                                <tfoot className="card-header">
                                  <tr role="row">
                                    <td
                                      colspan="5"
                                      className="border-right text-right "
                                    >
                                      <strong>Total </strong>
                                    </td>
                                    <td>
                                      <strong>
                                        ${" "}
                                        {invoices &&
                                          invoices?.overviewTable &&
                                          invoices?.overviewTable?.length > 0 &&
                                          parseFloat(
                                            invoices?.overviewTable?.reduce(
                                              (sum, order) =>
                                                sum + order.totalPay,
                                              0
                                            )
                                          ).toFixed(2)}
                                      </strong>
                                    </td>
                                  </tr>
                                </tfoot>
                              </table>
                              {invoices &&
                                invoices?.overviewTable &&
                                paginatedData?.results?.length === 0 && (
                                  <div
                                    className="dash-stats-list"
                                    style={{ textAlign: "center" }}
                                  >
                                    <br />
                                    <br />
                                    <img
                                      alt=""
                                      src={shift1}
                                      style={{ width: "145px", opacity: "0.5" }}
                                    />
                                    <br />
                                    <p style={{ opacity: "0.5" }}>
                                      No Data Found
                                    </p>
                                  </div>
                                )}
                            </div>
                          </div>
                        </div>
                      </div>
                      {invoices &&
                        invoices?.overviewTable &&
                        invoices?.overviewTable?.length > 0 &&
                        paginatedData?.results?.length > 0 && (
                          <div className="card-footer">
                            <div className="d-flex align-items-center">
                              {invoices.loading === false ? (
                                <div className="flex-grow-1">
                                  <div
                                    className="dataTables_info"
                                    id="leads-table_info"
                                    role="status"
                                    aria-live="polite"
                                  >
                                    Showing{" "}
                                    {1 +
                                      paginatedData.limit *
                                        (paginatedData?.currentPageNumber -
                                          1)}{" "}
                                    to{" "}
                                    {paginatedData.displayingResults +
                                      paginatedData.limit *
                                        (paginatedData?.currentPageNumber -
                                          1)}{" "}
                                    of {paginatedData.totalResults} entries
                                  </div>
                                </div>
                              ) : (
                                <div className="flex-grow-1">
                                  <div
                                    className="dataTables_info"
                                    id="leads-table_info"
                                    role="status"
                                    aria-live="polite"
                                  >
                                    Loading entries
                                  </div>
                                </div>
                              )}
                              <div>
                                <div
                                  className="dataTables_paginate paging_simple_numbers"
                                  id="leads-table_paginate"
                                >
                                  <ul className="pagination">
                                    <li className="page-item">
                                      <NavLink
                                        className="page-link"
                                        to="#"
                                        aria-label="Previous"
                                        // onClick={onClickPrev}
                                        onClick={(e) =>
                                          handlePagination(
                                            currentPage - 1,
                                            sortField,
                                            sortOrder,
                                            searchTerm,
                                            limit
                                          )
                                        }
                                      >
                                        <span aria-hidden="true">Previous</span>
                                        <span className="sr-only">
                                          Previous
                                        </span>
                                      </NavLink>
                                    </li>
                                    {invoices &&
                                      invoices?.overviewTable &&
                                      paginatedData?.paginationArr &&
                                      paginatedData?.paginationArr?.length >
                                        0 &&
                                      Array.from(
                                        {
                                          length:
                                            paginatedData.endPage -
                                            paginatedData.startPage +
                                            1,
                                        },
                                        (_, i) => {
                                          const current =
                                            paginatedData.startPage + i;
                                          return paginatedData?.currentPageNumber ===
                                            current ? (
                                            <li
                                              className="page-item active"
                                              key={i}
                                            >
                                              <Link
                                                to=""
                                                className="page-link"
                                                value={current}
                                              >
                                                {current}
                                              </Link>
                                            </li>
                                          ) : (
                                            <li className="page-item" key={i}>
                                              <Link
                                                className="page-link"
                                                value={current}
                                                onClick={(e) =>
                                                  handlePagination(
                                                    parseInt(current),
                                                    sortField,
                                                    sortOrder,
                                                    searchTerm,
                                                    limit
                                                  )
                                                }
                                                to=""
                                              >
                                                {current}
                                              </Link>
                                            </li>
                                          );
                                        }
                                      )}
                                    <li className="page-item">
                                      <NavLink
                                        className="page-link"
                                        to="#"
                                        aria-label="Next"
                                        // onClick={onClickNext}
                                        onClick={(e) =>
                                          handlePagination(
                                            currentPage + 1,
                                            sortField,
                                            sortOrder,
                                            searchTerm,
                                            limit
                                          )
                                        }
                                      >
                                        <span aria-hidden="true">Next</span>
                                        <span className="sr-only">Next</span>
                                      </NavLink>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};
export default AdminAccountingOverviewPage;
