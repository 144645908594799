/** @format */

import axios from "axios";
import {
  SERVERADDRESS,
  GET_INVOICES_REQUEST,
  GET_INVOICES_SUCCESS,
  GET_INVOICES_FAIL,
  GET_INVOICES_REQUEST_V2,
  GET_INVOICES_SUCCESS_V2,
  GET_INVOICES_FAIL_V2,
  GET_SEARCHED_INVOICES_REQUEST,
  GET_SEARCHED_INVOICES_SUCCESS,
  GET_SEARCHED_INVOICES_FAIL,
  // GET_DRIVER_INVOICES_REQUEST,
  // GET_DRIVER_INVOICES_SUCCESS,
  // GET_DRIVER_INVOICES_FAIL,
  VIEW_SETTLEMENT_REQUEST,
  VIEW_SETTLEMENT_SUCCESS,
  VIEW_SETTLEMENT_FAIL,
  VIEW_PAGE_SETTLEMENT_REQUEST,
  VIEW_PAGE_SETTLEMENT_SUCCESS,
  VIEW_PAGE_SETTLEMENT_FAIL,
  UPDATE_SETTLEMENT_REQUEST,
  UPDATE_SETTLEMENT_SUCCESS,
  UPDATE_SETTLEMENT_FAIL,
  DELETE_SETTLEMENT_REQUEST,
  DELETE_SETTLEMENT_SUCCESS,
  DELETE_SETTLEMENT_FAIL,
  SETTLEMENT_SEARCH_VIEW_REQUEST,
  SETTLEMENT_SEARCH_VIEW_SUCCESS,
  SETTLEMENT_SEARCH_VIEW_FAIL,
  CREATE_SETTLEMENT_REQUEST,
  CREATE_SETTLEMENT_SUCCESS,
  CREATE_SETTLEMENT_FAIL,
  SEARCHED_SETTLEMENT_UPDATED_SUCCESS,
} from "../constants";
import { notifyFailure, notifySuccess } from "../components/toast";

// ALL INVOICES
export const getAllInvoicesAction = (obj) => async (dispatch) => {
  dispatch({
    type: GET_INVOICES_REQUEST,
  });
  try {
    const { data } = await axios.post(
      `${SERVERADDRESS}/v1/invoice/getInvoicesByCompanyId`,
      obj
    );
    localStorage.setItem("invoices", JSON.stringify(data));
    localStorage.setItem("cardDetails", JSON.stringify(data.cardDetails));
    dispatch({
      type: GET_INVOICES_SUCCESS,
      payload: data,
    });
  } catch (err) {
    // console.log(err);
    dispatch({
      type: GET_INVOICES_FAIL,
      payload: err,
    });
  }
};

// ALL INVOICES WITHOUT PAGINATION
export const getAllInvoicesActionV2 = (obj, callback) => async (dispatch) => {
  dispatch({
    type: GET_INVOICES_REQUEST_V2,
  });
  try {
    const { data } = await axios.post(
      `${SERVERADDRESS}/v2/invoice/getInvoicesByCompanyId`,
      obj
    );
    const modifiedData = {
      invoices: data.invoices.slice(0, 200),
    };
    localStorage.setItem("invoices", JSON.stringify(modifiedData));
    localStorage.setItem("cardDetails", JSON.stringify(data.cardDetails));
    localStorage.setItem(
      "driverCardDetails",
      JSON.stringify(data.driverCardDetails)
    );
    dispatch({
      type: GET_INVOICES_SUCCESS_V2,
      // payload: data,
      payload: {
        invoices: data.invoices.slice(0, 200),
        cardDetails: data.cardDetails,
        driverCardDetails: data.driverCardDetails,
      },
    });
    callback && callback(data);
  } catch (err) {
    // console.log(err);
    dispatch({
      type: GET_INVOICES_FAIL_V2,
      payload: err,
    });
  }
};

// ALL INVOICES WITHOUT PAGINATION
export const invoiceSearch = (obj) => async (dispatch) => {
  dispatch({
    type: GET_SEARCHED_INVOICES_REQUEST,
  });
  try {
    const { data } = await axios.post(
      `${SERVERADDRESS}/v1/invoice/invoiceSearch`,
      obj
    );
    // localStorage.setItem("searchedData", JSON.stringify(data));
    dispatch({
      type: GET_SEARCHED_INVOICES_SUCCESS,
      payload: data,
    });
  } catch (err) {
    // console.log(err);
    dispatch({
      type: GET_SEARCHED_INVOICES_FAIL,
      payload: err,
    });
  }
};

// // ALL INVOICES WITHOUT PAGINATION
// export const getDriverInvoicesByCompanyId = (obj) => async (dispatch) => {
//   dispatch({
//     type: GET_DRIVER_INVOICES_REQUEST,
//   });
//   try {
//     const { data } = await axios.post(
//       `${SERVERADDRESS}/v2/invoice/getDriverInvoicesByCompanyId`,
//       obj
//     );
//     const modifiedData = {
//       invoices: data.invoices.slice(0, 200),
//     };
//     localStorage.setItem("driverList", JSON.stringify(modifiedData));
//     localStorage.setItem("driverCardDetails", JSON.stringify(data.cardDetails));
//     dispatch({
//       type: GET_DRIVER_INVOICES_SUCCESS,
//       payload: data,
//     });
//   } catch (err) {
//     // console.log(err);
//     dispatch({
//       type: GET_DRIVER_INVOICES_FAIL,
//       payload: err,
//     });
//   }
// };

// ALL SETTLEMENT
export const getSettlementsByCompanyId = (obj) => async (dispatch) => {
  dispatch({
    type: VIEW_SETTLEMENT_REQUEST,
  });
  try {
    const { data } = await axios.post(
      `${SERVERADDRESS}/v1/settlement/getSettlementsByCompanyId`,
      obj
    );
    localStorage.setItem("settlement", JSON.stringify(data));
    dispatch({
      type: VIEW_SETTLEMENT_SUCCESS,
      payload: data,
    });
  } catch (err) {
    // console.log(err);
    dispatch({
      type: VIEW_SETTLEMENT_FAIL,
      payload: err,
    });
  }
};

export const getSettlementById = (obj) => async (dispatch) => {
  dispatch({
    type: VIEW_PAGE_SETTLEMENT_REQUEST,
  });
  try {
    const { data } = await axios.post(
      `${SERVERADDRESS}/v1/settlement/getSettlementById`,
      obj
    );
    // localStorage.setItem("searchedData", JSON.stringify(data));
    dispatch({
      type: VIEW_PAGE_SETTLEMENT_SUCCESS,
      payload: data,
    });
  } catch (err) {
    // console.log(err);
    dispatch({
      type: VIEW_PAGE_SETTLEMENT_FAIL,
      payload: err,
    });
  }
};

export const updateSettlement = (obj, cb) => async (dispatch) => {
  dispatch({
    type: UPDATE_SETTLEMENT_REQUEST,
  });
  try {
    const { data } = await axios.put(
      `${SERVERADDRESS}/v1/settlement/updateSettlement`,
      obj
    );
    // localStorage.setItem("searchedData", JSON.stringify(data));
    dispatch({
      type: UPDATE_SETTLEMENT_SUCCESS,
      payload: data,
    });
    notifySuccess("Settlement Updated Successfully !");
    if (cb) {
      cb();
    }
  } catch (err) {
    // console.log(err);
    notifyFailure(err.response.data.message);
    dispatch({
      type: UPDATE_SETTLEMENT_FAIL,
      payload: err,
    });
  }
};

export const deleteSettlement = (obj, cb) => async (dispatch) => {
  dispatch({
    type: DELETE_SETTLEMENT_REQUEST,
  });
  try {
    const { data } = await axios.put(
      `${SERVERADDRESS}/v1/settlement/updateSettlement`,
      obj
    );
    // localStorage.setItem("searchedData", JSON.stringify(data));
    dispatch({
      type: DELETE_SETTLEMENT_SUCCESS,
      payload: data,
    });
    notifySuccess("Settlement deleted Successfully !");
    if (cb) {
      cb();
    }
  } catch (err) {
    // console.log(err);
    notifyFailure(err.response.data.message);
    dispatch({
      type: DELETE_SETTLEMENT_FAIL,
      payload: err,
    });
  }
};

export const getFilterSettlements = (obj) => async (dispatch) => {
  dispatch({
    type: SETTLEMENT_SEARCH_VIEW_REQUEST,
  });
  try {
    const { data } = await axios.post(
      `${SERVERADDRESS}/v1/settlement/getFilterSettlements`,
      obj
    );
    // localStorage.setItem("searchedData", JSON.stringify(data));
    dispatch({
      type: SETTLEMENT_SEARCH_VIEW_SUCCESS,
      payload: data,
    });
  } catch (err) {
    // console.log(err);
    dispatch({
      type: SETTLEMENT_SEARCH_VIEW_FAIL,
      payload: err,
    });
  }
};


export const getFilterSettlementsReports = (obj) => async (dispatch) => {
  dispatch({
    type: "SETTLEMENTREPORTS_SEARCH_VIEW_REQUEST",
  });
  try {
    const { data } = await axios.post(
      `${SERVERADDRESS}/v1/settlement/getFilterSettlements`,
      obj
    );
    // localStorage.setItem("searchedData", JSON.stringify(data));
    dispatch({
      type: "SETTLEMENTREPORTS_SEARCH_VIEW_SUCCESS",
      payload: data,
    });
  } catch (err) {
    // console.log(err);
    dispatch({
      type: "SETTLEMENTREPORTS_SEARCH_VIEW_FAIL",
      payload: err,
    });
  }
};

export const notPaidAllDriversReservations = (obj) => async (dispatch) => {
  dispatch({
    type: "NOTPAID_VIEW_REQUEST",
  });
  try {
    const { data } = await axios.post(
      `${SERVERADDRESS}/v1/reservation/notPaidAllDriversReservations`,
      obj
    );
    // notifySuccess("Settlement report search successful!");
    // localStorage.setItem("searchedData", JSON.stringify(data));
    dispatch({
      type: "NOTPAID_VIEW_SUCCESS",
      payload: data,
    });
  } catch (err) {
    // console.log(err);
    // notifyFailure(err?.response?.data?.message);
    dispatch({
      type: "NOTPAID_VIEW_FAIL",
      payload: err,
    });
  }
};
export const overviewTableView = (obj) => async (dispatch) => {
  dispatch({
    type: "OVERVIEW_TABLE_VIEW_REQUEST",
  });
  try {
    const { data } = await axios.post(
      `${SERVERADDRESS}/v1/reservation/notPaidAllDriversReservations`,
      obj
    );
    // localStorage.setItem("searchedData", JSON.stringify(data));
    dispatch({
      type: "OVERVIEW_TABLE_VIEW_SUCCESS",
      payload: data,
    });
  } catch (err) {
    // console.log(err);
    dispatch({
      type: "OVERVIEW_TABLE_VIEW_FAIL",
      payload: err,
    });
  }
};

export const updateSettlementSuccess = (newValues) => ({
  type: SEARCHED_SETTLEMENT_UPDATED_SUCCESS,
  payload: newValues
});

// ADD NEW NOTE
export const createMultipleSettlement = (obj, successCB) => async ( dispatch ) => {
  dispatch({
      type: CREATE_SETTLEMENT_REQUEST,
  })
  try {
      const { data } = await axios.post(`${SERVERADDRESS}/v1/settlement/createMultipleSettlement`, obj)
      dispatch({
          type: CREATE_SETTLEMENT_SUCCESS,
          payload: data,
      })
      // navigateInbox()
      if (successCB) {
          successCB(data);
        }
        notifySuccess("Settlement created Successfully !");
  }
  catch (err) {
      // console.log(err)
      notifyFailure(err.response.data.message);
      dispatch({
          type: CREATE_SETTLEMENT_FAIL,
          payload: err,
      })
  }
}