/** @format */

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateRouteById } from "../../../actions/routeActions";
import {
  getAllStudents,
  getRoutePassengersByCompanyId,
} from "../../../actions/userActions";

const ArrangeOrderModalV2 = ({
  showModal,
  setShowModal,
  selectedRoute,
  setSelectedRoute,
  // passer,
  // passengerRouteArr,
  // setPassengerRouteArr,

  // customerDetailsArr,
  // setCustomerDetailsArr,
  // orignalRidePattern,
  // setOrignalRidePattern,
  // returnRidePattern,
  // setReturnRidePattern,
}) => {
  const directionsService = new window.google.maps.DirectionsService();

  const dispatch = useDispatch();
  const auth = useSelector((x) => x.auth);
  const students = useSelector((x) => x.students);

  const [passengerRouteArr, setPassengerRouteArr] = useState([]);
  const [ridePattern, setRidePattern] = useState([]);

  const [ridePatternWithDistance, setRidePatternWithDistance] = useState([]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    // setPassengerRouteArr(orderArr);

    dispatch(
      updateRouteById(
        {
          _id: selectedRoute.id,
          ridePattern: ridePatternWithDistance,

          totalDrivenMileage: ridePatternWithDistance.reduce(function (
            previousValue,
            currentValue
          ) {
            return previousValue + currentValue?.distanceFromLastPoint;
          },
          0),
        },
        successHandler
      )
    );
  };

  const successHandler = () => {
    setShowModal(false);
  };

  const swapRidePattern = (index1, index2) => {
    setRidePattern((array) => {
      let data = [...array];
      let temp = data[index1];
      data[index1] = data[index2];
      data[index2] = temp;
      return data;
    });
  };

  const calculateMileageAndTime = async (data) => {
    let drivenMileage = 0;

    let arr = [
      {
        customerDetails: data[0].customerDetails,
        customerId: data[0].customerId,

        distanceFromLastPoint: 0,
        routeType: data[0].routeType,
        address: data[0].address,
        time: data[0].time,
        timeFromLastPoint: `0 mins`,
      },
    ];

    let eta = { hour: 0, mins: 0 };

    let estimatedTime;

    for (let i = 0; i < data.length - 1; i++) {
      if (data[i].address.address == data[i + 1].address.address) {
        arr.push({
          customerDetails: data[i].customerDetails,
          customerId: data[i].customerId,

          distanceFromLastPoint: 0,
          routeType: data[i + 1].routeType,
          address: data[i + 1].address,
          time: data[i].time,
          timeFromLastPoint: data[i].timeFromLastPoint,
        });
      } else {
        await directionsService
          .route({
            origin: {
              query: data[i]?.address?.address,
            },
            destination: {
              query: data[i + 1]?.address?.address,
            },
            travelMode: window.google.maps.TravelMode.DRIVING,
          })
          .then((response) => {
            // console.log("response2", response);
            drivenMileage = parseFloat(
              response.routes[0].legs[0].distance.text
                .replace(/,/g, "")
                .split(" mi")[0]
            );

            let oldDate = new Date("2023-04-20T" + data[i].time + ":00.000Z");

            // yahan se new logic
            if (response.routes[0].legs[0].duration.text.length > 7) {
              const timeString = response.routes[0].legs[0].duration.text;
              const hourRegex = /(\d+)\s*hour/;
              const minuteRegex = /(\d+)\s*min/;

              const hourMatch = hourRegex.exec(timeString);
              const minuteMatch = minuteRegex.exec(timeString);

              const hours = hourMatch ? parseInt(hourMatch[1]) : 0;
              const minutes = minuteMatch ? parseInt(minuteMatch[1]) : 0;

              eta.hour = hours;
              eta.mins = minutes;

              let aaa = (hours * 60 + minutes) * 60 * 1000;

              estimatedTime = new Date(aaa + new Date(oldDate).getTime())
                .toISOString()
                .slice(11, 16);

              arr.push({
                customerDetails: data[i].customerDetails,
                customerId: data[i].customerId,

                distanceFromLastPoint: drivenMileage,
                timeFromLastPoint: `${aaa / 60000} mins`,
                routeType: data[i + 1].routeType,
                address: data[i + 1].address,
                time: estimatedTime,
              });
              // setCustomerDetailsArr(
              //   customerDetailsArr.map((x, i) =>
              //     i === index
              //       ? {
              //           ...x,
              //           dropOffTime,
              //         }
              //       : x
              //   )
              // );
            } else {
              eta.mins = parseInt(
                response.routes[0].legs[0].duration.text.split(" mins")[0]
              );

              let aaa =
                parseInt(
                  response.routes[0].legs[0].duration.text.split(" mins")[0]
                ) *
                60 *
                1000;

              estimatedTime = new Date(aaa + new Date(oldDate).getTime())
                .toISOString()
                .slice(11, 16);

              arr.push({
                customerDetails: data[i].customerDetails,
                customerId: data[i].customerId,

                distanceFromLastPoint: drivenMileage,
                routeType: data[i + 1].routeType,
                address: data[i + 1].address,
                time: estimatedTime,
                timeFromLastPoint: `${aaa / 60000} mins`,
              });

              // setCustomerDetailsArr(
              //   customerDetailsArr.map((x, i) =>
              //     i === index
              //       ? {
              //           ...x,
              //           dropOffTime: new Date(aaa + new Date(oldDate).getTime())
              //             .toISOString()
              //             .slice(11, 16),
              //         }
              //       : x
              //   )
              // );
            }
          });
      }
    }
    // console.log("mileage arr orignal==>", arr, orignalRidePattern);
    setRidePatternWithDistance(arr);
  };

  useEffect(() => {
    if (ridePattern.length > 0) calculateMileageAndTime(ridePattern);
  }, [ridePattern]);

  useEffect(() => {
    if (selectedRoute) setRidePattern(selectedRoute.ridePattern);
  }, [selectedRoute]);
  // useEffect(() => {
  //   let tempRidePatternArr = [];
  //   if (selectedRoute)
  //     if (selectedRoute?.ridePattern?.length == 0) {
  //       for (let i = 0; i < selectedRoute.customerDetails.length; i++) {
  //         // tempRidePatternArr.push({
  //         //   ...selectedRoute.customerDetails[i],
  //         //   routeType: "Pick",
  //         // });
  //         // tempRidePatternArr.push({
  //         //   ...selectedRoute.customerDetails[i],
  //         //   routeType: "Drop",
  //         // });

  //       tempReturnRouteArr.push({
  //         customerId: customerDetailsArr[i].customerId,
  //         address: customerDetailsArr[i].dropOffAddress,
  //         time: customerDetailsArr[i].dropOffTime,
  //         customerDetails: customerDetailsArr[i],
  //         routeType: "Drop",
  //       });

  //       tempReturnRouteArr.push({
  //         customerId: customerDetailsArr[i].customerId,
  //         address: customerDetailsArr[i].pickUpAddress,
  //         time: customerDetailsArr[i].pickUpTime,
  //         customerDetails: customerDetailsArr[i],

  //         routeType: "Pick",
  //       });
  //       }
  //     }

  //   setRidePattern(
  //     selectedRoute?.ridePattern?.length > 0
  //       ? selectedRoute.ridePattern
  //       : tempRidePatternArr
  //   );
  // }, [selectedRoute]);

  // useEffect(() => {

  //   let tempRidePatternArr = [];
  //   if (selectedRoute)
  //     if (selectedRoute?.ridePattern?.length == 0) {
  //     for (let i = 0; i < customerDetailsArr.length; i++) {

  //       tempReturnRouteArr.push({
  //         customerId: customerDetailsArr[i].customerId,
  //         address: customerDetailsArr[i].dropOffAddress,
  //         time: customerDetailsArr[i].dropOffTime,
  //         customerDetails: customerDetailsArr[i],
  //         routeType: "Drop",
  //       });

  //       tempReturnRouteArr.push({
  //         customerId: customerDetailsArr[i].customerId,
  //         address: customerDetailsArr[i].pickUpAddress,
  //         time: customerDetailsArr[i].pickUpTime,
  //         customerDetails: customerDetailsArr[i],

  //         routeType: "Pick",
  //       });
  //     }

  //     tempReturnRouteArr.reverse();

  //     setRidePattern(tempReturnRouteArr);
  //   }
  // }, [customerDetailsArr]);

  useEffect(() => {
    // dispatch(
    //   getAllStudents({
    //     companyId: auth.user.companyId.id,
    //   })
    // );
    dispatch(
      getRoutePassengersByCompanyId({
        companyId: auth.user.companyId.id,
      })
    );
  }, []);

  // console.log("selected route==>", selectedRoute);
  return (
    <>
      <div
        className={`modal fade ${showModal ? "show modal-y-axis" : "hide"}`}
        role="dialog"
        data-backdrop="static"
        data-keyboard="false" // To prevent closing with the keyboard
        onClick={(e) => {
          // Check if the click occurred outside the modal content
          if (e.target === e.currentTarget) {
            setShowModal(false); // Call your close modal function
          }
        }}
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLongTitle">
                {" "}
                Ride Pattern
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                // onClick={() => openDropDown()}
                onClick={() => setShowModal(false)}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body ">
              <div className="row">
                <div className="col-12 col-md-4">
                  <h6>Students List</h6>
                  <ul className="">
                    {selectedRoute?.customerDetails?.map((x, i) => {
                      // const studentFound = students?.routePassengerList?.find(
                      //   (customer) => customer.id == x.customerId
                      // );
                      return (
                        <li className=" bg-grey rounded p-2 my-1">
                          {`${i + 1} ) 
                          ${`${x?.customerId?.firstName} ${x?.customerId?.lastName}`}
                          `}
                        </li>
                      );
                    })}
                  </ul>
                </div>
                <div className="col-12 col-md-8">
                  <div className="row align-items-center">
                    <div className="col-12 ">
                      <h6>Ride Pattern</h6>
                    </div>
                  </div>
                  <div className="row pt-2">
                    {ridePattern.map((x, i) => {
                      const studentFound = students?.routePassengerList?.find(
                        (customer) => customer.id == x.customerId
                      );
                      return (
                        <div className="col-12 mb-1">
                          <div className="bg-grey p-1 d-flex justify-content-between align-items-center">
                            <p className="m-0">
                              {`${i + 1}) ${x.routeType} ${
                                // x.student
                                studentFound
                                  ? `${studentFound.firstName} ${studentFound.lastName}`
                                  : "Student not selected"
                              }`}
                            </p>

                            <div>
                              {i !== 0 ? (
                                <>
                                  {x.routeType == "Drop" ? (
                                    i > ridePattern.length / 2 &&
                                    ridePattern
                                      // .slice(0, i - 1)
                                      .slice(i, ridePattern.length)

                                      .find(
                                        (z) => z.routeType !== "Pick"
                                        // &&
                                        // z.customerId == x.customerId
                                      ) ? (
                                      <button
                                        className="btn btn-md"
                                        onClick={() =>
                                          swapRidePattern(i, i - 1)
                                        }
                                      >
                                        <i className="bi bi-chevron-up text-primary"></i>
                                      </button>
                                    ) : (
                                      <button className="btn btn-md" disabled>
                                        <i className="bi bi-chevron-up"></i>
                                      </button>
                                    )
                                  ) : ridePattern
                                      .slice(i, ridePattern.length)
                                      .find(
                                        (z) =>
                                          z.routeType == "Drop" &&
                                          z.customerId == x.customerId
                                      ) ? (
                                    <button
                                      className="btn btn-md"
                                      onClick={() => swapRidePattern(i, i - 1)}
                                    >
                                      <i className="bi bi-chevron-up text-primary"></i>
                                    </button>
                                  ) : (
                                    <button className="btn btn-md" disabled>
                                      <i className="bi bi-chevron-up"></i>
                                    </button>
                                  )}
                                </>
                              ) : (
                                <button className="btn btn-md" disabled>
                                  <i className="bi bi-chevron-up"></i>
                                </button>
                              )}

                              {i !== ridePattern.length - 1 ? (
                                <>
                                  {x.routeType == "Drop" ? (
                                    ridePattern
                                      .slice(0, i + 1)
                                      .find(
                                        (z) =>
                                          z.routeType == "Pick" &&
                                          z.customerId == x.customerId
                                      ) ? (
                                      <button
                                        className="btn btn-md"
                                        onClick={() =>
                                          swapRidePattern(i, i + 1)
                                        }
                                      >
                                        <i className="bi bi-chevron-down text-primary"></i>
                                      </button>
                                    ) : (
                                      <button className="btn btn-md" disabled>
                                        <i className="bi bi-chevron-down"></i>
                                      </button>
                                    )
                                  ) : i < ridePattern.length / 2 - 1 &&
                                    ridePattern
                                      // .slice(i + 2, ridePattern.length)
                                      .slice(0, i + 1)
                                      .find(
                                        (z) => z.routeType !== "Drop"
                                        // &&
                                        // z.customerId == x.customerId
                                      ) ? (
                                    <button
                                      className="btn btn-md"
                                      onClick={() => swapRidePattern(i, i + 1)}
                                    >
                                      <i className="bi bi-chevron-down text-primary"></i>
                                    </button>
                                  ) : (
                                    <button className="btn btn-md" disabled>
                                      <i className="bi bi-chevron-down"></i>
                                    </button>
                                  )}
                                </>
                              ) : (
                                <button className="btn btn-md" disabled>
                                  <i className="bi bi-chevron-down"></i>
                                </button>
                              )}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>

            <div className="modal-footer">
              <button
                type="button"
                className="btn-secondary rounded f-14 p-2 mr-3"
                data-dismiss="modal"
                onClick={() => setShowModal(false)}
              >
                Close
              </button>
              <button
                type="sunmit"
                className="btn-primary rounded f-14 p-2 mr-3"
                onClick={handleSubmit}
              >
                Update
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default ArrangeOrderModalV2;
