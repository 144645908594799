import {
    PERMISSION_VIEW_REQUEST,
    PERMISSION_VIEW_SUCCESS,
    PERMISSION_VIEW_FAIL,
    PERMISSION_CREATE_REQUEST,
    PERMISSION_CREATE_SUCCESS,
    PERMISSION_CREATE_FAIL,
    PERMISSION_UPDATE_REQUEST,
    PERMISSION_UPDATE_SUCCESS,
    PERMISSION_UPDATE_FAIL,
    PERMISSION_DELETE_REQUEST,
    PERMISSION_DELETE_SUCCESS,
    PERMISSION_DELETE_FAIL,
    GET_PERMISSION_BY_ID_REQUEST,
    GET_PERMISSION_BY_ID_SUCCESS,
    GET_PERMISSION_BY_ID_FAIL,
} from "../constants"

export const permissionReducer = (
    state = {
        permission: {
            permission: null,
            permissionDetail: null,
        }
    },
    action
) => {
        switch (action.type) {

        // PERMISSION VIEW TABLE
        case PERMISSION_VIEW_REQUEST : 
            return {
                ...state,
            };
        case PERMISSION_VIEW_SUCCESS :
            return {
                ...state,
                permission: action.payload,
                permissionDetail: null,
            };
        case PERMISSION_VIEW_FAIL :
            return {
                ...state,
                error: action.payload,
            };


        // PERMISSION CREATE 
        case PERMISSION_CREATE_REQUEST :
            return {
                ...state,
                loading: true,
            }
        case PERMISSION_CREATE_SUCCESS :
            return {
                ...state,
                loading: false,
                permission: [...state.permission, action.payload],
            }
        case PERMISSION_CREATE_FAIL :
            return {
                ...state,
                loading: false,
                error: action.payload,
            }


        // PERMISSION UPDATE
        case PERMISSION_UPDATE_REQUEST:
            return {
            ...state,
            loading: true,
            };
        case PERMISSION_UPDATE_SUCCESS:
            return {
            ...state,
            loading: false,
            // permission: {
                permission: state.permission.map((x) =>
                x.permission.id === action.payload.id 
                ? {permission: action.payload, users: x.users} : x
                ),
            // }
                
                // permission: {...state.permission, permissionDetail: state.permission.permissionDetail}

                // permission: action.payload,

            // permission: {...state.permission, permissionDetail: state.permission.permissionDetail.permission.map((x) =>
            //     x.id === action.payload.id ? action.payload : x
            //     )},
            // permissionDetail: state.permissionDetail.map((x) =>
            //     x.permission.id === action.payload.id ? { permission : action.payload} : x
            //     ),
            // permission: {...state.permission, results:state.permission.results.map((x) =>
            //     x._id === action.payload._id ? action.payload : x
            //     )},
            };
        case PERMISSION_UPDATE_FAIL:
            return {
            ...state,
            loading: false,
            error: action.payload,
            };
        // PERMISSION DELETE
        case PERMISSION_DELETE_REQUEST:
            return {
            ...state,
            };
        case PERMISSION_DELETE_SUCCESS:
            return {
            ...state,
            permission: state.permission.filter((x) => x.permission.id !== action.payload.id),
                // permission: {
                //     ...state.permission,
                //     results: state.permission.results.filter((x) =>
                //     x.id !== action.payload.id),
                // },
            };
        case PERMISSION_DELETE_FAIL:
            return {
            ...state,
            error: action.payload,
            };
        
        case GET_PERMISSION_BY_ID_REQUEST : 
            return {
                ...state,
            };
        case GET_PERMISSION_BY_ID_SUCCESS :
            return {
                ...state,
                permissionDetail: action.payload,
            };
        case GET_PERMISSION_BY_ID_FAIL :
            return {
                ...state,
                error: action.payload,
            };


        default:
            return state;
    }
}