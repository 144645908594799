/** @format */

import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import AssignDriverModal from "./AssignDriverModal";
import { useDispatch, useSelector } from "react-redux";
import AdminRouteDeleteModal from "./AdminRouteDeleteModal";
import moment from "moment";

const RoutesTableAccordionRow = ({
  current,
  i,
  route,
  // dropDownRow,
  // setDropDownRow,
  // editOrderHandler,
  deleteRouteHandler,
  // viewRouteHandler,
  removeCustomerHandler,
  pickCustomerHandler,
  noshowCustomerHandler,
  dropCustomerHandler,
  // startTripHandler,
  // endTripHandler,
  toggleValues,
  toggleValuesAM,
  updateToggleValues,
  toggleType,
}) => {
  const routes = useSelector((state) => state.routes);
  const service = useSelector((state) => state.service);
  const students = useSelector((state) => state.students);
  const { routePassengerList } = students;
  const { loading, routeDetails } = routes;

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showMoreVertDropDown, setshowMoreVertDropDown] = useState(false);

  // const [showDeleteDropDown, setshowDeleteDropDown] = useState(false)

  const openMoreVertDropDown = () => {
    setshowMoreVertDropDown((showMoreVertDropDown) => !showMoreVertDropDown);
  };

  const [showDropDown, setshowDropDown] = useState(false);
  // const [data, setData] = useState([]);
  // useEffect(() => {
  //   if (toggleType === "toggleValuesAM") {
  //     setData(toggleValuesAM);
  //   } else setData(toggleValues);
  // }, [toggleType]);
  const openDropDown = () => {
    // console.log(openDropDown, "main open drop oper wala")
    setshowDropDown((showDropDown) => !showDropDown);
    //   console.log(openDropDown, "main open drop neecha wala")
  };

  const [assignDropDown, setAssignDropDown] = useState(false);

  const openAssignDropDown = () => {
    setAssignDropDown((assignDropDown) => !assignDropDown);
  };

  const auth = useSelector((state) => state.auth);

  // const [routeAndTripDetails, setRouteAndTripDetails] = useState(null);

  // useEffect(() => {
  //   if (routeDetails) {
  //     setRouteAndTripDetails(routeDetails);
  //   }
  // }, [routeDetails]);

  // console.log(toggleValues, "toggle");

  const dropdownRef = useRef(null);

  useEffect(() => {
    // Function to close dropdown when clicking outside
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setshowMoreVertDropDown(false);
      }
    };

    // Add event listener to the document
    document.addEventListener("mousedown", handleClickOutside);

    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  const matchingType = service?.service?.find(
    (type) => type.id === current.type
  );
  const user = routePassengerList.find((x) => x?.id === current?.customerId);
  return (
    <>
      <tr id="row-20" role="row" className="odd border">
        {/* <td className="border-right">
                      1008
                      <i
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Route Number# 1008"
                        className="fa fa-info-circle fa-w-16 text-info"
                      ></i>
                    </td> */}
        <td className="border-right">
          <strong>
            <input
              type="checkbox"
              checked={toggleValues[i]?.isComing}
              onChange={() => removeCustomerHandler(toggleType, user?.id, i)}
            />{" "}
            {user?.firstName} {user?.lastName}{" "}
          </strong>
        </td>
        <td className="border-right">{matchingType?.name}</td>
        <td className="border-right">
          <div className="input-group">
            <input
              type="text"
              aria-label=""
              className="form-control mx-1"
              // placeholder="6"
              autocomplete="off"
              // value={current?.pickUpTime?.split(":")[0]}
              value={
                parseInt(current.pickUpTime.substring(0, 2)) === 0
                  ? 12
                  : parseInt(current.pickUpTime.substring(0, 2)) > 12
                  ? parseInt(current.pickUpTime.substring(0, 2)) - 12
                  : parseInt(current.pickUpTime.substring(0, 2))
              }
              disabled
            />
            <label htmlFor="#" className="mt-2">
              :
            </label>
            <input
              type="text"
              aria-label=""
              className="form-control mx-1"
              // value={current?.pickUpTime?.split(":")[1]}
              value={current.pickUpTime.substring(3, 5)}
              autocomplete="off"
              disabled
            />
            <span className="input-group-text" id="pmamtext">
              {/* {parseInt(current?.pickUpTime?.split(":")[0]) > 12 ? "PM" : "AM"}{" "} */}
              {parseInt(current?.pickUpTime.substring(0, 2)) > 11 ? "PM" : "AM"}
            </span>
            <div className="d-flex align-items-center">
              <div className="btn-choice mx-2">
                <label
                  className="switch"
                  style={
                    !toggleValues[i]?.isComing ? { cursor: "not-allowed" } : {}
                  }
                >
                  <input
                    type="checkbox"
                    className="switch-input"
                    id="toggle"
                    autocomplete="off"
                    onChange={() =>
                      pickCustomerHandler(
                        toggleType,
                        user?.id,
                        i,
                        current?.pickUpAddress?.latitude,
                        current?.pickUpAddress?.longitude
                      )
                    }
                    checked={toggleValues[i]?.pick}
                    disabled={!toggleValues[i]?.isComing}
                  />
                  <span
                    className="switch-label"
                    data-on="Yes"
                    data-off="No"
                  ></span>
                  <span className="switch-handle"></span>
                </label>
              </div>
              <div className="btn-choice mx-2">
                {" "}
                No Show
                <label
                  className="switch"
                  style={
                    !toggleValues[i]?.isComing ? { cursor: "not-allowed" } : {}
                  }
                >
                  <input
                    type="checkbox"
                    className="switch-input"
                    id="toggle"
                    autocomplete="off"
                    onChange={() =>
                      noshowCustomerHandler(
                        toggleType,
                        user?.id,
                        i,
                        current?.pickUpAddress?.latitude,
                        current?.pickUpAddress?.longitude
                      )
                    }
                    checked={toggleValues[i]?.noshow}
                    disabled={!toggleValues[i]?.isComing}
                  />
                  <span
                    className="switch-label"
                    data-on="Yes"
                    data-off="No"
                  ></span>
                  <span className="switch-handle"></span>
                </label>
              </div>
            </div>
          </div>
        </td>
        {/* <td className="border-right w-30">
          <div className="d-flex justify-content-between">
            <div>
              <div>
                <p className="f-12 font-medium mb-0">
                  <strong>{current?.pickUpAddress?.address}</strong>{" "}
                </p>
                <p className="f-12 mb-0">
                  {current?.pickUpAddress?.city},{" "}
                  {current?.pickUpAddress?.state}
                </p>
              </div>
            </div>
            <div className="mt-4 px-4">
              <div className="hidden sm:block">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="80"
                  height="12"
                  viewBox="0 0 40 6"
                  fill="none"
                >
                  <path
                    d="M40 3L35 0.113249V5.88675L40 3ZM0 3.5H35.5V2.5H0V3.5Z"
                    fill="black"
                  ></path>
                </svg>
              </div>
              <p className="text-center font-medium">
                <span>{current.totalMileage}mi</span>
              </p>
            </div>
            <div>
              <div>
                <p className="f-12 font-medium mb-0">
                  <strong>{current?.dropOffAddress?.address}</strong>{" "}
                </p>
                <p className="f-12 mb-0">
                  {current?.dropOffAddress?.city},{" "}
                  {current?.dropOffAddress?.state}
                </p>
              </div>
            </div>
          </div>
        </td> */}
        <td className="border-right w-30">
          <div className="d-flex justify-content-between">
            <div>
              <div>
                <p className="f-12 font-medium mb-0">
                  <strong>{current?.pickUpAddress?.address}</strong>{" "}
                </p>
              </div>
              <div>
                <time>
                  {" "}
                  {moment(current?.pickUpDate?.substring(0, 10)).format(
                    "MM/DD/YYYY"
                  )}{" "}
                  -{" "}
                  {parseInt(current.pickUpTime.substring(0, 2)) === 0
                    ? 12
                    : parseInt(current.pickUpTime.substring(0, 2)) > 12
                    ? parseInt(current.pickUpTime.substring(0, 2)) - 12
                    : parseInt(current.pickUpTime.substring(0, 2))}
                  {":" + current.pickUpTime.substring(3, 5)}
                  {parseInt(current?.pickUpTime.substring(0, 2)) > 11
                    ? "pm"
                    : "am"}
                </time>
              </div>
            </div>
            <div className="mt-4 px-4">
              <div className="hidden sm:block">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="50"
                  height="8"
                  viewBox="0 0 40 6"
                  fill="none"
                >
                  <path
                    d="M40 3L35 0.113249V5.88675L40 3ZM0 3.5H35.5V2.5H0V3.5Z"
                    fill="black"
                  ></path>
                </svg>
              </div>
              <p className="text-center font-medium">
                {/* <span>{current.distanceFromBaseLocation} {current.distanceFromPickToDropOff} {current.totalMileage} mi</span> */}
                <span>
                  {current?.fareAndDistance?.distanceFromPickToDropOff} mi
                </span>
              </p>
            </div>
            <div>
              <div>
                <p className="f-12 font-medium mb-0">
                  <strong>{current?.dropOffAddress?.address}</strong>
                </p>
                {/* <p className="f-12 mb-0">IL 60623</p> */}
              </div>
              <div>
                <time>
                  {" "}
                  {moment(current?.dropOffDate?.substring(0, 10)).format(
                    "MM/DD/YYYY"
                  )}{" "}
                  -{" "}
                  {parseInt(current.dropOffTime.substring(0, 2)) === 0
                    ? 12
                    : parseInt(current.dropOffTime.substring(0, 2)) > 12
                    ? parseInt(current.dropOffTime.substring(0, 2)) - 12
                    : parseInt(current.dropOffTime.substring(0, 2))}
                  {":" + current.dropOffTime.substring(3, 5)}
                  {parseInt(current.dropOffTime.substring(0, 2)) > 11
                    ? "pm"
                    : "am"}
                </time>
              </div>
            </div>
          </div>
        </td>
        <td className="border-right">
          <div className="input-group">
            <input
              type="text"
              aria-label=""
              className="form-control mx-1"
              value={
                parseInt(current.dropOffTime.substring(0, 2)) === 0
                  ? 12
                  : parseInt(current.dropOffTime.substring(0, 2)) > 12
                  ? parseInt(current.dropOffTime.substring(0, 2)) - 12
                  : parseInt(current.dropOffTime.substring(0, 2))
              }
              autocomplete="off"
              disabled
            />
            <label htmlFor="#" className="mt-2">
              :
            </label>
            <input
              type="text"
              aria-label=""
              className="form-control mx-1"
              placeholder="40"
              autocomplete="off"
              value={current.dropOffTime.substring(3, 5)}
              disabled
            />
            <span className="input-group-text" id="pmamtext">
              {/* {parseInt(current?.dropOffTime?.split(":")[0]) > 12 ? "PM" : "AM"} */}
              {parseInt(current.dropOffTime.substring(0, 2)) > 11 ? "PM" : "AM"}
            </span>
            <div>
              <div className="btn-choice mx-2">
                <label
                  className="switch"
                  style={
                    !toggleValues[i]?.isComing ? { cursor: "not-allowed" } : {}
                  }
                >
                  <input
                    type="checkbox"
                    className="switch-input"
                    id="toggle"
                    autocomplete="off"
                    onChange={() =>
                      dropCustomerHandler(
                        toggleType,
                        user?.id,
                        i,
                        current?.dropOffAddress?.latitude,
                        current?.dropOffAddress?.longitude
                      )
                    }
                    checked={toggleValues[i]?.drop}
                    disabled={!toggleValues[i]?.isComing}
                  />
                  <span
                    className="switch-label"
                    data-on="Yes"
                    data-off="No"
                  ></span>
                  <span className="switch-handle"></span>
                </label>
              </div>
            </div>
          </div>
        </td>
        <td className=" text-right pr-20">
          <div className="task_view">
            <div className="dropdown">
              <Link
                to=""
                className="task_view_more d-flex align-items-center justify-content-center dropdown-toggle"
                type="link"
                data-toggle="dropdown"
                onClick={openMoreVertDropDown}
                aria-haspopup="true"
                aria-expanded="false"
              >
                <i className="icon-options-vertical icons"></i>
              </Link>
              <div
                className={`dropdown-menu dropdown-menu-right ${
                  showMoreVertDropDown
                    ? "dropdown-menu dropdown-menu-right show"
                    : "dropdown-menu dropdown-menu-right"
                }`}
                aria-labelledby="dropdownMenuLink-20"
                ref={dropdownRef}
              >
                {/* {auth?.user?.permissionId?.routes?.create === true &&
                  !current.routeNumberReturn &&
                  !current.isReturnRoute && (
                    <Link
                      className="dropdown-item"
                      to={`create-return/${current.id}`}
                      onClick={() => createReturnRouteHandler(current)}
                    >
                      <i className="fa fa-plus"> Create return route</i>
                    </Link>
                  )} */}

                <Link
                  className="dropdown-item"
                  to={`/view-route/${route.id}?cusId=${user?.id}&shift=${
                    toggleType === "toggleValuesMID"
                      ? "MID"
                      : toggleType === "toggleValuesPM"
                      ? "PM"
                      : "AM"
                  }`}
                  // to={`/routes/details/${route.id}`}
                  // onClick={() => navigate(`/routes/details/${current.id}`)}
                >
                  <i className="fa fa-address-card-o"> View</i>
                </Link>
                {auth?.user?.permissionId?.routes?.update === true && (
                  <Link to={`edit/${route.id}`} className="dropdown-item">
                    <i className="fa fa-edit"> Edit</i>
                  </Link>
                )}

                {/* {auth?.user?.permissionId?.routes?.update === true && (
                  <Link
                    className="dropdown-item"
                    to=""
                    onClick={() => editOrderHandler(current)}
                  >
                    <i className="fa fa-edit"> Edit Ride Pattern</i>
                  </Link>
                )} */}
                <Link
                  className="dropdown-item"
                  to={`/route-log/${route.id}`}
                  // onClick={() => navigate(`/route-log/${current.id}`)}
                >
                  <i className="fa fa-address-card-o"> View Route Log</i>
                </Link>

                {/* {auth?.user?.permissionId?.routes?.delete === true && (
                  <Link
                    className="dropdown-item delete-table-row"
                    to=""
                    data-id="20"
                    onClick={() => deleteRouteHandler(route)}
                  >
                    <i className="fa fa-trash"> Delete</i>
                  </Link>
                )} */}
              </div>
            </div>
          </div>
        </td>
      </tr>

      {/* <tr
        role="row"
        className="odd border"
        key={i}
        onMouseLeave={() => setshowMoreVertDropDown(false)}
      >
        <td className="border-right">
          <b>
            {current?.routeNumber} {current?.routeNumberReturn && "( Return )"}
          </b>
        </td>
        <td className="border-right">{current?.customerDetails?.length}</td>
    
        <td className="border-right">
          <ul>
            {distinctArray?.map((x, i) => (
              <li>
                {" "}
                {x.type.name}
                {i !== distinctArray.length - 1 ? "," : ""}
              </li>
            ))}
          </ul>
        </td>
        <td className="border-right">
          {distinctDaysArray.map((x, i) => (
            <span>
              {" "}
              {x}
              {i !== distinctDaysArray.length - 1 ? "," : ""}
            </span>
          ))}
        </td>
        <td className="border-right" style={{ textAlign: "center" }}>
          <button
            className="btn btn-sm btn-primary"
            // onClick={() => navigate(`/routes/details/${current.id}`)}
            onClick={() => navigate(`/ride-action/${current.id}`)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-eye"
              viewBox="0 0 16 16"
            >
              <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z" />
              <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z" />
            </svg>{" "}
          </button>
        </td>
        <td className="border-right">
          {current.driverId ? (
            <>
              {current.driverId.firstName} {current.driverId?.lastName}
            </>
          ) : (
            "-"
          )}
        </td>
        <td className="border-right">
          {current.status === "In Progress" ? "Yes" : "No"}
        </td>

        <td className="border-right">
          <button
            className="btn btn-success"
            onClick={
              current.driverBookingStatus !== "Unassigned"
                ? unassignedRoute
                : openAssignDropDown
            }
          >
            {current.driverBookingStatus !== "Unassigned"
              ? "Unassigned Driver"
              : "Assign Driver"}
          </button>
        </td>
      
        <td className=" text-right pr-20">
          <div className="task_view">
            <div className="dropdown">
              <Link
                to=""
                className="task_view_more d-flex align-items-center justify-content-center dropdown-toggle"
                type="link"
                data-toggle="dropdown"
                onClick={openMoreVertDropDown}
                aria-haspopup="true"
                aria-expanded="false"
              >
                <i className="icon-options-vertical icons"></i>
              </Link>
              <div
                className={`dropdown-menu dropdown-menu-right ${
                  showMoreVertDropDown
                    ? "dropdown-menu dropdown-menu-right show"
                    : "dropdown-menu dropdown-menu-right"
                }`}
                aria-labelledby="dropdownMenuLink-20"
              >
                {auth?.user?.permissionId?.routes?.create === true &&
                  !current.routeNumberReturn &&
                  !current.isReturnRoute && (
                    <Link
                      className="dropdown-item"
                      to={`create-return/${current.id}`}
                      onClick={() => createReturnRouteHandler(current)}
                    >
                      <i className="fa fa-plus"> Create return route</i>
                    </Link>
                  )}
                {auth?.user?.permissionId?.routes?.update === true && (
                  <Link to={`edit/${current.id}`} className="dropdown-item">
                    <i className="fa fa-edit"> Edit</i>
                  </Link>
                )}

   
                <Link
                  className="dropdown-item"
                  to={`/routes/details/${current.id}`}
                  // onClick={() => navigate(`/routes/details/${current.id}`)}
                >
                  <i className="fa fa-address-card-o"> Full Details</i>
                </Link>

                {auth?.user?.permissionId?.routes?.update === true && (
                  <Link
                    className="dropdown-item"
                    to=""
                    onClick={() => editOrderHandler(current)}
                  >
                    <i className="fa fa-edit"> Edit Ride Pattern</i>
                  </Link>
                )}
                <Link
                  className="dropdown-item"
                  to={`/route-log/${current.id}`}
                  // onClick={() => navigate(`/route-log/${current.id}`)}
                >
                  <i className="fa fa-address-card-o"> View Route Log</i>
                </Link>

                {auth?.user?.permissionId?.routes?.delete === true && (
                  <Link
                    className="dropdown-item delete-table-row"
                    to=""
                    data-id="20"
                    onClick={() => deleteRouteHandler(current)}
                  >
                    <i className="fa fa-trash"> Delete</i>
                  </Link>
                )}

             
              </div>
            </div>
          </div>
        </td>
      </tr> */}
      <AssignDriverModal
        showDropDown={assignDropDown}
        openDropDown={openAssignDropDown}
        current={current}
        index={i}
      />
      <AdminRouteDeleteModal
        showDropDown={showDropDown}
        openDropDown={openDropDown}
        current={current}
        index={i}
      />
    </>
  );
};
export default RoutesTableAccordionRow;
