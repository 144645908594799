/** @format */

import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addPermission } from "../../../actions/permissionActions";
import { Link, NavLink, useNavigate } from "react-router-dom";
import {
  tabCreatingAccountant,
  tabCreatingAdminOrDispatcher,
  tabCreatingDriver,
} from "../../../actions/tabActions";

const AdminPermissionCreatePermissionComponent = () => {
  const myState = useSelector((state) => state.changeTheTab);

  const auth = useSelector((state) => state.auth);
  const permission = useSelector((state) => state.permission);
  const { loading } = permission;

  const [values, setValues] = useState({
    // "companyId": "63cb14f53f55723694dbd185",
    name: "",
    dashboard: {
      isAllow: false,
      create: false,
      read: false,
      update: false,
      delete: false,
    },
    booking: {
      isAllow: false,
      create: false,
      read: false,
      update: false,
      delete: false,
    },
    // clients: {
    //   isAllow: false,
    //   create: false,
    //   read: false,
    //   update: false,
    //   delete: false,
    // },
    passengers: {
      isAllow: false,
      create: false,
      read: false,
      update: false,
      delete: false,
    },
    dispatching: {
      isAllow: false,
      create: false,
      read: false,
      update: false,
      delete: false,
    },
    track: {
      isAllow: false,
      create: false,
      read: false,
      update: false,
      delete: false,
    },
    reports: {
      isAllow: false,
      create: false,
      read: false,
      update: false,
      delete: false,
    },
    routes: {
      isAllow: false,
      create: false,
      read: false,
      update: false,
      delete: false,
    },
    settings: {
      isAllow: false,
      create: false,
      read: false,
      update: false,
      delete: false,
    },
    permission: {
      isAllow: false,
      create: false,
      read: false,
      update: false,
      delete: false,
    },
    user: {
      isAllow: false,
      create: false,
      read: false,
      update: false,
      delete: false,
    },
    accounting: {
      isAllow: false,
      create: false,
      read: false,
      update: false,
      delete: false,
    },
    dashboardDriver: {
      isAllow: false,
      create: false,
      read: false,
      update: false,
      delete: false,
    },
    // dashboardDispatcher: {
    //   isAllow: false,
    //   create: false,
    //   read: false,
    //   update: false,
    //   delete: false,
    // },
    myOrder: {
      isAllow: false,
      create: false,
      read: false,
      update: false,
      delete: false,
    },
    myRoutes: {
      isAllow: false,
      create: false,
      read: false,
      update: false,
      delete: false,
    },
  });
  const handleChange = (fieldName) => (event) => {
    setValues({
      ...values,
      [fieldName]: event.target.value,
    });
  };
  // console.log(values);
  const dispatch = useDispatch();
  const permissionData = {
    companyId: auth.user.companyId.id,
    name: values.name,
    dashboard: {
      isAllow: values.dashboard.isAllow,
      create: values.dashboard.create,
      read: values.dashboard.read,
      update: values.dashboard.update,
      delete: values.dashboard.delete,
    },
    booking: {
      isAllow: values.booking.isAllow,
      create: values.booking.create,
      read: values.booking.read,
      update: values.booking.update,
      delete: values.booking.delete,
    },
    // clients: {
    //   isAllow: values.clients.isAllow,
    //   create: values.clients.create,
    //   read: values.clients.read,
    //   update: values.clients.update,
    //   delete: values.clients.delete,
    // },
    passengers: {
      isAllow: values.passengers.isAllow,
      create: values.passengers.create,
      read: values.passengers.read,
      update: values.passengers.update,
      delete: values.passengers.delete,
    },
    dispatching: {
      isAllow: values.dispatching.isAllow,
      create: values.dispatching.create,
      read: values.dispatching.read,
      update: values.dispatching.update,
      delete: values.dispatching.delete,
    },
    track: {
      isAllow: values.track.isAllow,
      create: values.track.create,
      read: values.track.read,
      update: values.track.update,
      delete: values.track.delete,
    },
    reports: {
      isAllow: values.reports.isAllow,
      create: values.reports.create,
      read: values.reports.read,
      update: values.reports.update,
      delete: values.reports.delete,
    },
    routes: {
      isAllow: values.routes.isAllow,
      create: values.routes.create,
      read: values.routes.read,
      update: values.routes.update,
      delete: values.routes.delete,
    },
    settings: {
      isAllow: values.settings.isAllow,
      create: values.settings.create,
      read: values.settings.read,
      update: values.settings.update,
      delete: values.settings.delete,
    },
    permission: {
      isAllow: values.permission.isAllow,
      create: values.permission.create,
      read: values.permission.read,
      update: values.permission.update,
      delete: values.permission.delete,
    },
    user: {
      isAllow: values.user.isAllow,
      create: values.user.create,
      read: values.user.read,
      update: values.user.update,
      delete: values.user.delete,
    },
    accounting: {
      isAllow: values.accounting.isAllow,
      create: values.accounting.create,
      read: values.accounting.read,
      update: values.accounting.update,
      delete: values.accounting.delete,
    },
    dashboardDriver: {
      isAllow: values.dashboardDriver.isAllow,
      create: values.dashboardDriver.create,
      read: values.dashboardDriver.read,
      update: values.dashboardDriver.update,
      delete: values.dashboardDriver.delete,
    },
    // dashboardDispatcher: {
    //   isAllow: values.dashboardDispatcher.isAllow,
    //   create: values.dashboardDispatcher.create,
    //   read: values.dashboardDispatcher.read,
    //   update: values.dashboardDispatcher.update,
    //   delete: values.dashboardDispatcher.delete,
    // },
    myOrder: {
      isAllow: values.myOrder.isAllow,
      create: values.myOrder.create,
      read: values.myOrder.read,
      update: values.myOrder.update,
      delete: values.myOrder.delete,
    },
    myRoutes: {
      isAllow: values.myRoutes.isAllow,
      create: values.myRoutes.create,
      read: values.myRoutes.read,
      update: values.myRoutes.update,
      delete: values.myRoutes.delete,
    },
  };
  let navigate = useNavigate();
  const navigatePermission = () => {
    navigate("/permission");
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    // console.log("first");
    dispatch(addPermission(permissionData, navigatePermission));
    // openDropDown()
  };

  return (
    <>
      <div className="">
        <div
          className="mobile-close-overlay w-100 h-100"
          id="close-settings-overlay"
        />
        <Link to="" className="d-block d-lg-none close-it" id="close-settings">
          <svg
            className="svg-inline--fa fa-times fa-w-11"
            aria-hidden="true"
            focusable="false"
            data-prefix="fa"
            data-icon="times"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 352 512"
            data-fa-i2svg=""
          >
            <path
              fill="currentColor"
              d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"
            ></path>
          </svg>
        </Link>

        {myState.viewPermission === "creatingAdminOrDispatcher" ? (
          <>
            <div className="row align-items-center">
              <div className="col">
                <div className="col-7 mitbsnav">
                  <ul className="nav nav-pills nav-justified">
                    <li className="nav-item active">
                      <NavLink
                        to="/create-permission"
                        onClick={() => dispatch(tabCreatingAdminOrDispatcher())}
                        className=""
                      >
                        Admin/Dispatcher
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink
                        to="/create-permission"
                        onClick={() => dispatch(tabCreatingDriver())}
                        className=""
                      >
                        Driver
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink
                        to="/create-permission"
                        onClick={() => dispatch(tabCreatingAccountant())}
                        className=""
                      >
                        Accounting
                      </NavLink>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </>
        ) : myState.viewPermission === "creatingDriver" ? (
          <>
            <div className="row align-items-center">
              <div className="col">
                <div className="col-7 mitbsnav">
                  <ul className="nav nav-pills nav-justified">
                    <li className="nav-item">
                      <NavLink
                        to="/create-permission"
                        onClick={() => dispatch(tabCreatingAdminOrDispatcher())}
                        className=""
                      >
                        Admin/Dispatcher
                      </NavLink>
                    </li>
                    <li className="nav-item active">
                      <NavLink
                        to="/create-permission"
                        onClick={() => dispatch(tabCreatingDriver())}
                        className=""
                      >
                        Driver
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink
                        to="/create-permission"
                        onClick={() => dispatch(tabCreatingAccountant())}
                        className=""
                      >
                        Accounting
                      </NavLink>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </>
        ) : myState.viewPermission === "creatingAccountant" ? (
          <>
            <div className="row align-items-center">
              <div className="col">
                <div className="col-7 mitbsnav">
                  <ul className="nav nav-pills nav-justified">
                    <li className="nav-item">
                      <NavLink
                        to="/create-permission"
                        onClick={() => dispatch(tabCreatingAdminOrDispatcher())}
                        className=""
                      >
                        Admin/Dispatcher
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink
                        to="/create-permission"
                        onClick={() => dispatch(tabCreatingDriver())}
                        className=""
                      >
                        Driver
                      </NavLink>
                    </li>
                    <li className="nav-item active">
                      <NavLink
                        to="/create-permission"
                        onClick={() => dispatch(tabCreatingAccountant())}
                        className=""
                      >
                        Accounting
                      </NavLink>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </>
        ) : (
          <></>
        )}

        <br />

        {/* {myState.viewInvoicing === "theinvoicing" ? (
          auth?.user?.permissionId?.settings?.read === true ? (
            <>
              <InvoicingCards cardDetails={invoices?.cardDetails} />
              <InvoicingTable />
            </>
          ) : (
            <NotAllowed />
          )
        ) : myState.viewInvoicing === "driverpay" ? (
          auth?.user?.permissionId?.settings?.create === true ? (
            <>
              <InvoicingCards cardDetails={invoices?.cardDetails} />
              <DriverPayTable />
            </>
          ) : (
            <NotAllowed />
          )
        ) : (
          <></>
        )} */}
      </div>
      <form onSubmit={handleSubmit}>
        <div className="d-flex flex-column w-tables rounded mt-3 bg-white ">
          <div className="d-flex border">
            <div className="flex-grow-1">
              <div className="dataTables_length d-flex" id="leads-table_length">
                <div className="p-2">
                  {/* <label className="f-18">Role Title: </label> */}
                  {/* <sup className="text-danger f-12"> *</sup> */}
                  <input
                    type="text"
                    id="permissionName"
                    className="form-control height-35 f-14"
                    required
                    placeholder="Role Title..."
                    autoComplete="false"
                    value={values.name}
                    onChange={handleChange("name")}
                  />
                </div>
              </div>
            </div>
            {/* <!-- SEARCH BY TASK START --> */}
            {/* <div className="task-search d-flex  py-1 px-lg-3 px-0 border-right-grey align-items-center">
                        <form className="w-100 mr-1 mr-lg-0 mr-md-1 ml-md-1 ml-0 ml-lg-0" onSubmit={(e) => e.preventDefault()}>
                            <div className="input-group bg-grey rounded">
                                <div className="input-group-prepend">
                                    <span className="input-group-text border-0 bg-additional-grey">
                                        <i className="fa fa-search f-13 text-dark-grey"></i>
                                    </span>
                                </div>
                                <input type="text" className="form-control f-14 p-1 border-additional-grey" id="search-text-field" placeholder="Start typing to search"/>
                            </div>
                        </form>
                    </div> */}
            {/* <!-- SEARCH BY TASK END --> */}
          </div>
          <div
            id="leads-table_wrapper"
            className="dataTables_wrapper dt-bootstrap4 no-footer"
          >
            <div className="row">
              <div className="col-sm-12">
                <div className="row">
                  <div className="col-md-4 my-3 pl-5">
                    <h4 className=" mb-0 f-18 text-capitalize font-weight-bold">
                      {" "}
                      Module Name{" "}
                    </h4>
                  </div>
                  <div className="col-md-2 my-3">
                    <h4 className=" mb-0 f-18 text-capitalize font-weight-bold">
                      {" "}
                      Create{" "}
                    </h4>
                  </div>
                  <div className="col-md-2 my-3">
                    <h4 className=" mb-0 f-18 text-capitalize font-weight-bold">
                      {" "}
                      Read{" "}
                    </h4>
                  </div>
                  <div className="col-md-2 my-3">
                    <h4 className=" mb-0 f-18 text-capitalize font-weight-bold">
                      {" "}
                      Update{" "}
                    </h4>
                  </div>
                  <div className="col-md-2 my-3">
                    <h4 className=" mb-0 f-18 text-capitalize font-weight-bold">
                      {" "}
                      Delete{" "}
                    </h4>
                  </div>
                </div>
                {myState.viewPermission === "creatingAdminOrDispatcher" ? (
                  <>
                    <div className="row ">
                      <div className="col-md-4 pl-5">
                        <div className="form-group my-3">
                          <div className="d-flex">
                            <div className="btn-choice">
                              <label className="switch">
                                <input
                                  type="checkbox"
                                  onChange={() =>
                                    setValues({
                                      ...values,
                                      dashboard: {
                                        ...values.dashboard,
                                        isAllow: !values.dashboard.isAllow,
                                      },
                                    })
                                  }
                                  className="switch-input"
                                  id="toggle1"
                                />
                                <span
                                  className="switch-label"
                                  data-on=""
                                  data-off=""
                                ></span>
                                <span className="switch-handle"></span>
                              </label>
                            </div>
                            <div className="pl-3 my-2">
                              <label
                                className="f-14 text-dark mb-12"
                                data-label=""
                                htmlFor="toggle2"
                              >
                                Dashboard (Admin)
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-2 my-3">
                        <div className="btn-choice">
                          <label className="switch">
                            <input
                              type="checkbox"
                              onChange={() =>
                                setValues({
                                  ...values,
                                  dashboard: {
                                    ...values.dashboard,
                                    create: !values.dashboard.create,
                                  },
                                })
                              }
                              className="switch-input"
                              id="toggle2"
                            />
                            <span
                              className="switch-label"
                              data-on=""
                              data-off=""
                            ></span>
                            <span className="switch-handle"></span>
                          </label>
                        </div>
                      </div>
                      <div className="col-md-2 my-3">
                        <div className="btn-choice">
                          <label className="switch">
                            <input
                              type="checkbox"
                              onChange={() =>
                                setValues({
                                  ...values,
                                  dashboard: {
                                    ...values.dashboard,
                                    read: !values.dashboard.read,
                                  },
                                })
                              }
                              className="switch-input"
                              id="toggle3"
                            />
                            <span
                              className="switch-label"
                              data-on=""
                              data-off=""
                            ></span>
                            <span className="switch-handle"></span>
                          </label>
                        </div>
                      </div>
                      <div className="col-md-2 my-3">
                        <div className="btn-choice">
                          <label className="switch">
                            <input
                              type="checkbox"
                              onChange={() =>
                                setValues({
                                  ...values,
                                  dashboard: {
                                    ...values.dashboard,
                                    update: !values.dashboard.update,
                                  },
                                })
                              }
                              className="switch-input"
                              id="toggle4"
                            />
                            <span
                              className="switch-label"
                              data-on=""
                              data-off=""
                            ></span>
                            <span className="switch-handle"></span>
                          </label>
                        </div>
                      </div>
                      <div className="col-md-2 my-3">
                        <div className="btn-choice">
                          <label className="switch">
                            <input
                              type="checkbox"
                              onChange={() =>
                                setValues({
                                  ...values,
                                  dashboard: {
                                    ...values.dashboard,
                                    delete: !values.dashboard.delete,
                                  },
                                })
                              }
                              className="switch-input"
                              id="toggle5"
                            />
                            <span
                              className="switch-label"
                              data-on=""
                              data-off=""
                            ></span>
                            <span className="switch-handle"></span>
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-4 pl-5">
                        <div className="form-group my-3">
                          <div className="d-flex">
                            <div className="btn-choice">
                              <label className="switch">
                                <input
                                  type="checkbox"
                                  onChange={() =>
                                    setValues({
                                      ...values,
                                      booking: {
                                        ...values.booking,
                                        isAllow: !values.booking.isAllow,
                                      },
                                    })
                                  }
                                  className="switch-input"
                                  id="toggle6"
                                />
                                <span
                                  className="switch-label"
                                  data-on=""
                                  data-off=""
                                ></span>
                                <span className="switch-handle"></span>
                              </label>
                            </div>
                            <div className="pl-3 my-2">
                              <label
                                className="f-14 text-dark mb-12"
                                data-label=""
                                htmlFor="toggle7"
                              >
                                Booking
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-2 my-3">
                        <div className="btn-choice">
                          <label className="switch">
                            <input
                              type="checkbox"
                              onChange={() =>
                                setValues({
                                  ...values,
                                  booking: {
                                    ...values.booking,
                                    create: !values.booking.create,
                                  },
                                })
                              }
                              className="switch-input"
                              id="toggle7"
                            />
                            <span
                              className="switch-label"
                              data-on=""
                              data-off=""
                            ></span>
                            <span className="switch-handle"></span>
                          </label>
                        </div>
                      </div>
                      <div className="col-md-2 my-3">
                        <div className="btn-choice">
                          <label className="switch">
                            <input
                              type="checkbox"
                              onChange={() =>
                                setValues({
                                  ...values,
                                  booking: {
                                    ...values.booking,
                                    read: !values.booking.read,
                                  },
                                })
                              }
                              className="switch-input"
                              id="toggle8"
                            />
                            <span
                              className="switch-label"
                              data-on=""
                              data-off=""
                            ></span>
                            <span className="switch-handle"></span>
                          </label>
                        </div>
                      </div>
                      <div className="col-md-2 my-3">
                        <div className="btn-choice">
                          <label className="switch">
                            <input
                              type="checkbox"
                              onChange={() =>
                                setValues({
                                  ...values,
                                  booking: {
                                    ...values.booking,
                                    update: !values.booking.update,
                                  },
                                })
                              }
                              className="switch-input"
                              id="toggle9"
                            />
                            <span
                              className="switch-label"
                              data-on=""
                              data-off=""
                            ></span>
                            <span className="switch-handle"></span>
                          </label>
                        </div>
                      </div>
                      <div className="col-md-2 my-3">
                        <div className="btn-choice">
                          <label className="switch">
                            <input
                              type="checkbox"
                              onChange={() =>
                                setValues({
                                  ...values,
                                  booking: {
                                    ...values.booking,
                                    delete: !values.booking.delete,
                                  },
                                })
                              }
                              className="switch-input"
                              id="toggle10"
                            />
                            <span
                              className="switch-label"
                              data-on=""
                              data-off=""
                            ></span>
                            <span className="switch-handle"></span>
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-4 pl-5">
                        <div className="form-group my-3">
                          <div className="d-flex">
                            <div className="btn-choice">
                              <label className="switch">
                                <input
                                  type="checkbox"
                                  onChange={() =>
                                    setValues({
                                      ...values,
                                      dispatching: {
                                        ...values.dispatching,
                                        isAllow: !values.dispatching.isAllow,
                                      },
                                    })
                                  }
                                  className="switch-input"
                                  id="toggle11"
                                />
                                <span
                                  className="switch-label"
                                  data-on=""
                                  data-off=""
                                ></span>
                                <span className="switch-handle"></span>
                              </label>
                            </div>
                            <div className="pl-3 my-2">
                              <label
                                className="f-14 text-dark mb-12"
                                data-label=""
                                htmlFor="toggle12"
                              >
                                Dispatching
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-2 my-3">
                        <div className="btn-choice">
                          <label className="switch">
                            <input
                              type="checkbox"
                              onChange={() =>
                                setValues({
                                  ...values,
                                  dispatching: {
                                    ...values.dispatching,
                                    create: !values.dispatching.create,
                                  },
                                })
                              }
                              className="switch-input"
                              id="toggle12"
                            />
                            <span
                              className="switch-label"
                              data-on=""
                              data-off=""
                            ></span>
                            <span className="switch-handle"></span>
                          </label>
                        </div>
                      </div>
                      <div className="col-md-2 my-3">
                        <div className="btn-choice">
                          <label className="switch">
                            <input
                              type="checkbox"
                              onChange={() =>
                                setValues({
                                  ...values,
                                  dispatching: {
                                    ...values.dispatching,
                                    read: !values.dispatching.read,
                                  },
                                })
                              }
                              className="switch-input"
                              id="toggle13"
                            />
                            <span
                              className="switch-label"
                              data-on=""
                              data-off=""
                            ></span>
                            <span className="switch-handle"></span>
                          </label>
                        </div>
                      </div>
                      <div className="col-md-2 my-3">
                        <div className="btn-choice">
                          <label className="switch">
                            <input
                              type="checkbox"
                              onChange={() =>
                                setValues({
                                  ...values,
                                  dispatching: {
                                    ...values.dispatching,
                                    update: !values.dispatching.update,
                                  },
                                })
                              }
                              className="switch-input"
                              id="toggle14"
                            />
                            <span
                              className="switch-label"
                              data-on=""
                              data-off=""
                            ></span>
                            <span className="switch-handle"></span>
                          </label>
                        </div>
                      </div>
                      <div className="col-md-2 my-3">
                        <div className="btn-choice">
                          <label className="switch">
                            <input
                              type="checkbox"
                              onChange={() =>
                                setValues({
                                  ...values,
                                  dispatching: {
                                    ...values.dispatching,
                                    delete: !values.dispatching.delete,
                                  },
                                })
                              }
                              className="switch-input"
                              id="toggle15"
                            />
                            <span
                              className="switch-label"
                              data-on=""
                              data-off=""
                            ></span>
                            <span className="switch-handle"></span>
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-4 pl-5">
                        <div className="form-group my-3">
                          <div className="d-flex">
                            <div className="btn-choice">
                              <label className="switch">
                                <input
                                  type="checkbox"
                                  onChange={() =>
                                    setValues({
                                      ...values,
                                      passengers: {
                                        ...values.passengers,
                                        isAllow: !values.passengers.isAllow,
                                      },
                                    })
                                  }
                                  className="switch-input"
                                  id="toggle16"
                                />
                                <span
                                  className="switch-label"
                                  data-on=""
                                  data-off=""
                                ></span>
                                <span className="switch-handle"></span>
                              </label>
                            </div>
                            <div className="pl-3 my-2">
                              <label
                                className="f-14 text-dark mb-12"
                                data-label=""
                                htmlFor="toggle17"
                              >
                                Passengers
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-2 my-3">
                        <div className="btn-choice">
                          <label className="switch">
                            <input
                              type="checkbox"
                              onChange={() =>
                                setValues({
                                  ...values,
                                  passengers: {
                                    ...values.passengers,
                                    create: !values.passengers.create,
                                  },
                                })
                              }
                              className="switch-input"
                              id="toggle17"
                            />
                            <span
                              className="switch-label"
                              data-on=""
                              data-off=""
                            ></span>
                            <span className="switch-handle"></span>
                          </label>
                        </div>
                      </div>
                      <div className="col-md-2 my-3">
                        <div className="btn-choice">
                          <label className="switch">
                            <input
                              type="checkbox"
                              onChange={() =>
                                setValues({
                                  ...values,
                                  passengers: {
                                    ...values.passengers,
                                    read: !values.passengers.read,
                                  },
                                })
                              }
                              className="switch-input"
                              id="toggle18"
                            />
                            <span
                              className="switch-label"
                              data-on=""
                              data-off=""
                            ></span>
                            <span className="switch-handle"></span>
                          </label>
                        </div>
                      </div>
                      <div className="col-md-2 my-3">
                        <div className="btn-choice">
                          <label className="switch">
                            <input
                              type="checkbox"
                              onChange={() =>
                                setValues({
                                  ...values,
                                  passengers: {
                                    ...values.passengers,
                                    update: !values.passengers.update,
                                  },
                                })
                              }
                              className="switch-input"
                              id="toggle19"
                            />
                            <span
                              className="switch-label"
                              data-on=""
                              data-off=""
                            ></span>
                            <span className="switch-handle"></span>
                          </label>
                        </div>
                      </div>
                      <div className="col-md-2 my-3">
                        <div className="btn-choice">
                          <label className="switch">
                            <input
                              type="checkbox"
                              onChange={() =>
                                setValues({
                                  ...values,
                                  passengers: {
                                    ...values.passengers,
                                    delete: !values.passengers.delete,
                                  },
                                })
                              }
                              className="switch-input"
                              id="toggle20"
                            />
                            <span
                              className="switch-label"
                              data-on=""
                              data-off=""
                            ></span>
                            <span className="switch-handle"></span>
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="row ">
                      <div className="col-md-4 pl-5">
                        <div className="form-group my-3">
                          <div className="d-flex">
                            <div className="btn-choice">
                              <label className="switch">
                                <input
                                  type="checkbox"
                                  onChange={() =>
                                    setValues({
                                      ...values,
                                      track: {
                                        ...values.track,
                                        isAllow: !values.track.isAllow,
                                      },
                                    })
                                  }
                                  className="switch-input"
                                  id="toggle21"
                                />
                                <span
                                  className="switch-label"
                                  data-on=""
                                  data-off=""
                                ></span>
                                <span className="switch-handle"></span>
                              </label>
                            </div>
                            <div className="pl-3 my-2">
                              <label
                                className="f-14 text-dark mb-12"
                                data-label=""
                                htmlFor="toggle22"
                              >
                                Track
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-2 my-3">
                        <div className="btn-choice">
                          <label className="switch">
                            <input
                              type="checkbox"
                              onChange={() =>
                                setValues({
                                  ...values,
                                  track: {
                                    ...values.track,
                                    create: !values.track.create,
                                  },
                                })
                              }
                              className="switch-input"
                              id="toggle22"
                            />
                            <span
                              className="switch-label"
                              data-on=""
                              data-off=""
                            ></span>
                            <span className="switch-handle"></span>
                          </label>
                        </div>
                      </div>
                      <div className="col-md-2 my-3">
                        <div className="btn-choice">
                          <label className="switch">
                            <input
                              type="checkbox"
                              onChange={() =>
                                setValues({
                                  ...values,
                                  track: {
                                    ...values.track,
                                    read: !values.track.read,
                                  },
                                })
                              }
                              className="switch-input"
                              id="toggle23"
                            />
                            <span
                              className="switch-label"
                              data-on=""
                              data-off=""
                            ></span>
                            <span className="switch-handle"></span>
                          </label>
                        </div>
                      </div>
                      <div className="col-md-2 my-3">
                        <div className="btn-choice">
                          <label className="switch">
                            <input
                              type="checkbox"
                              onChange={() =>
                                setValues({
                                  ...values,
                                  track: {
                                    ...values.track,
                                    update: !values.track.update,
                                  },
                                })
                              }
                              className="switch-input"
                              id="toggle24"
                            />
                            <span
                              className="switch-label"
                              data-on=""
                              data-off=""
                            ></span>
                            <span className="switch-handle"></span>
                          </label>
                        </div>
                      </div>
                      <div className="col-md-2 my-3">
                        <div className="btn-choice">
                          <label className="switch">
                            <input
                              type="checkbox"
                              onChange={() =>
                                setValues({
                                  ...values,
                                  track: {
                                    ...values.track,
                                    delete: !values.track.delete,
                                  },
                                })
                              }
                              className="switch-input"
                              id="toggle25"
                            />
                            <span
                              className="switch-label"
                              data-on=""
                              data-off=""
                            ></span>
                            <span className="switch-handle"></span>
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-4 pl-5">
                        <div className="form-group my-3">
                          <div className="d-flex">
                            <div className="btn-choice">
                              <label className="switch">
                                <input
                                  type="checkbox"
                                  onChange={() =>
                                    setValues({
                                      ...values,
                                      routes: {
                                        ...values.routes,
                                        isAllow: !values.routes.isAllow,
                                      },
                                    })
                                  }
                                  className="switch-input"
                                  id="toggle31"
                                />
                                <span
                                  className="switch-label"
                                  data-on=""
                                  data-off=""
                                ></span>
                                <span className="switch-handle"></span>
                              </label>
                            </div>
                            <div className="pl-3 my-2">
                              <label
                                className="f-14 text-dark mb-12"
                                data-label=""
                                htmlFor="toggle32"
                              >
                                Routes
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-2 my-3">
                        <div className="btn-choice">
                          <label className="switch">
                            <input
                              type="checkbox"
                              onChange={() =>
                                setValues({
                                  ...values,
                                  routes: {
                                    ...values.routes,
                                    create: !values.routes.create,
                                  },
                                })
                              }
                              className="switch-input"
                              id="toggle32"
                            />
                            <span
                              className="switch-label"
                              data-on=""
                              data-off=""
                            ></span>
                            <span className="switch-handle"></span>
                          </label>
                        </div>
                      </div>
                      <div className="col-md-2 my-3">
                        <div className="btn-choice">
                          <label className="switch">
                            <input
                              type="checkbox"
                              onChange={() =>
                                setValues({
                                  ...values,
                                  routes: {
                                    ...values.routes,
                                    read: !values.routes.read,
                                  },
                                })
                              }
                              className="switch-input"
                              id="toggle33"
                            />
                            <span
                              className="switch-label"
                              data-on=""
                              data-off=""
                            ></span>
                            <span className="switch-handle"></span>
                          </label>
                        </div>
                      </div>
                      <div className="col-md-2 my-3">
                        <div className="btn-choice">
                          <label className="switch">
                            <input
                              type="checkbox"
                              onChange={() =>
                                setValues({
                                  ...values,
                                  routes: {
                                    ...values.routes,
                                    update: !values.routes.update,
                                  },
                                })
                              }
                              className="switch-input"
                              id="toggle34"
                            />
                            <span
                              className="switch-label"
                              data-on=""
                              data-off=""
                            ></span>
                            <span className="switch-handle"></span>
                          </label>
                        </div>
                      </div>
                      <div className="col-md-2 my-3">
                        <div className="btn-choice">
                          <label className="switch">
                            <input
                              type="checkbox"
                              onChange={() =>
                                setValues({
                                  ...values,
                                  routes: {
                                    ...values.routes,
                                    delete: !values.routes.delete,
                                  },
                                })
                              }
                              className="switch-input"
                              id="toggle35"
                            />
                            <span
                              className="switch-label"
                              data-on=""
                              data-off=""
                            ></span>
                            <span className="switch-handle"></span>
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-4 pl-5">
                        <div className="form-group my-3">
                          <div className="d-flex">
                            <div className="btn-choice">
                              <label className="switch">
                                <input
                                  type="checkbox"
                                  onChange={() =>
                                    setValues({
                                      ...values,
                                      settings: {
                                        ...values.settings,
                                        isAllow: !values.settings.isAllow,
                                      },
                                    })
                                  }
                                  className="switch-input"
                                  id="toggle36"
                                />
                                <span
                                  className="switch-label"
                                  data-on=""
                                  data-off=""
                                ></span>
                                <span className="switch-handle"></span>
                              </label>
                            </div>
                            <div className="pl-3 my-2">
                              <label
                                className="f-14 text-dark mb-12"
                                data-label=""
                                htmlFor="toggle37"
                              >
                                Settings
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-2 my-3">
                        <div className="btn-choice">
                          <label className="switch">
                            <input
                              type="checkbox"
                              onChange={() =>
                                setValues({
                                  ...values,
                                  settings: {
                                    ...values.settings,
                                    create: !values.settings.create,
                                  },
                                })
                              }
                              className="switch-input"
                              id="toggle37"
                            />
                            <span
                              className="switch-label"
                              data-on=""
                              data-off=""
                            ></span>
                            <span className="switch-handle"></span>
                          </label>
                        </div>
                      </div>
                      <div className="col-md-2 my-3">
                        <div className="btn-choice">
                          <label className="switch">
                            <input
                              type="checkbox"
                              onChange={() =>
                                setValues({
                                  ...values,
                                  settings: {
                                    ...values.settings,
                                    read: !values.settings.read,
                                  },
                                })
                              }
                              className="switch-input"
                              id="toggle38"
                            />
                            <span
                              className="switch-label"
                              data-on=""
                              data-off=""
                            ></span>
                            <span className="switch-handle"></span>
                          </label>
                        </div>
                      </div>
                      <div className="col-md-2 my-3">
                        <div className="btn-choice">
                          <label className="switch">
                            <input
                              type="checkbox"
                              onChange={() =>
                                setValues({
                                  ...values,
                                  settings: {
                                    ...values.settings,
                                    update: !values.settings.update,
                                  },
                                })
                              }
                              className="switch-input"
                              id="toggle39"
                            />
                            <span
                              className="switch-label"
                              data-on=""
                              data-off=""
                            ></span>
                            <span className="switch-handle"></span>
                          </label>
                        </div>
                      </div>
                      <div className="col-md-2 my-3">
                        <div className="btn-choice">
                          <label className="switch">
                            <input
                              type="checkbox"
                              onChange={() =>
                                setValues({
                                  ...values,
                                  settings: {
                                    ...values.settings,
                                    delete: !values.settings.delete,
                                  },
                                })
                              }
                              className="switch-input"
                              id="toggle40"
                            />
                            <span
                              className="switch-label"
                              data-on=""
                              data-off=""
                            ></span>
                            <span className="switch-handle"></span>
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-4 pl-5">
                        <div className="form-group my-3">
                          <div className="d-flex">
                            <div className="btn-choice">
                              <label className="switch">
                                <input
                                  type="checkbox"
                                  onChange={() =>
                                    setValues({
                                      ...values,
                                      permission: {
                                        ...values.permission,
                                        isAllow: !values.permission.isAllow,
                                      },
                                    })
                                  }
                                  className="switch-input"
                                  id="toggle41"
                                />
                                <span
                                  className="switch-label"
                                  data-on=""
                                  data-off=""
                                ></span>
                                <span className="switch-handle"></span>
                              </label>
                            </div>
                            <div className="pl-3 my-2">
                              <label
                                className="f-14 text-dark mb-12"
                                data-label=""
                                htmlFor="toggle42"
                              >
                                Permission
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-2 my-3">
                        <div className="btn-choice">
                          <label className="switch">
                            <input
                              type="checkbox"
                              onChange={() =>
                                setValues({
                                  ...values,
                                  permission: {
                                    ...values.permission,
                                    create: !values.permission.create,
                                  },
                                })
                              }
                              className="switch-input"
                              id="toggle42"
                            />
                            <span
                              className="switch-label"
                              data-on=""
                              data-off=""
                            ></span>
                            <span className="switch-handle"></span>
                          </label>
                        </div>
                      </div>
                      <div className="col-md-2 my-3">
                        <div className="btn-choice">
                          <label className="switch">
                            <input
                              type="checkbox"
                              onChange={() =>
                                setValues({
                                  ...values,
                                  permission: {
                                    ...values.permission,
                                    read: !values.permission.read,
                                  },
                                })
                              }
                              className="switch-input"
                              id="toggle43"
                            />
                            <span
                              className="switch-label"
                              data-on=""
                              data-off=""
                            ></span>
                            <span className="switch-handle"></span>
                          </label>
                        </div>
                      </div>
                      <div className="col-md-2 my-3">
                        <div className="btn-choice">
                          <label className="switch">
                            <input
                              type="checkbox"
                              onChange={() =>
                                setValues({
                                  ...values,
                                  permission: {
                                    ...values.permission,
                                    update: !values.permission.update,
                                  },
                                })
                              }
                              className="switch-input"
                              id="toggle44"
                            />
                            <span
                              className="switch-label"
                              data-on=""
                              data-off=""
                            ></span>
                            <span className="switch-handle"></span>
                          </label>
                        </div>
                      </div>
                      <div className="col-md-2 my-3">
                        <div className="btn-choice">
                          <label className="switch">
                            <input
                              type="checkbox"
                              onChange={() =>
                                setValues({
                                  ...values,
                                  permission: {
                                    ...values.permission,
                                    delete: !values.permission.delete,
                                  },
                                })
                              }
                              className="switch-input"
                              id="toggle45"
                            />
                            <span
                              className="switch-label"
                              data-on=""
                              data-off=""
                            ></span>
                            <span className="switch-handle"></span>
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-4 pl-5">
                        <div className="form-group my-3">
                          <div className="d-flex">
                            <div className="btn-choice">
                              <label className="switch">
                                <input
                                  type="checkbox"
                                  onChange={() =>
                                    setValues({
                                      ...values,
                                      user: {
                                        ...values.user,
                                        isAllow: !values.user.isAllow,
                                      },
                                    })
                                  }
                                  className="switch-input"
                                  id="toggle46"
                                />
                                <span
                                  className="switch-label"
                                  data-on=""
                                  data-off=""
                                ></span>
                                <span className="switch-handle"></span>
                              </label>
                            </div>
                            <div className="pl-3 my-2">
                              <label
                                className="f-14 text-dark mb-12"
                                data-label=""
                                htmlFor="toggle47"
                              >
                                User
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-2 my-3">
                        <div className="btn-choice">
                          <label className="switch">
                            <input
                              type="checkbox"
                              onChange={() =>
                                setValues({
                                  ...values,
                                  user: {
                                    ...values.user,
                                    create: !values.user.create,
                                  },
                                })
                              }
                              className="switch-input"
                              id="toggle47"
                            />
                            <span
                              className="switch-label"
                              data-on=""
                              data-off=""
                            ></span>
                            <span className="switch-handle"></span>
                          </label>
                        </div>
                      </div>
                      <div className="col-md-2 my-3">
                        <div className="btn-choice">
                          <label className="switch">
                            <input
                              type="checkbox"
                              onChange={() =>
                                setValues({
                                  ...values,
                                  user: {
                                    ...values.user,
                                    read: !values.user.read,
                                  },
                                })
                              }
                              className="switch-input"
                              id="toggle48"
                            />
                            <span
                              className="switch-label"
                              data-on=""
                              data-off=""
                            ></span>
                            <span className="switch-handle"></span>
                          </label>
                        </div>
                      </div>
                      <div className="col-md-2 my-3">
                        <div className="btn-choice">
                          <label className="switch">
                            <input
                              type="checkbox"
                              onChange={() =>
                                setValues({
                                  ...values,
                                  user: {
                                    ...values.user,
                                    update: !values.user.update,
                                  },
                                })
                              }
                              className="switch-input"
                              id="toggle49"
                            />
                            <span
                              className="switch-label"
                              data-on=""
                              data-off=""
                            ></span>
                            <span className="switch-handle"></span>
                          </label>
                        </div>
                      </div>
                      <div className="col-md-2 my-3">
                        <div className="btn-choice">
                          <label className="switch">
                            <input
                              type="checkbox"
                              onChange={() =>
                                setValues({
                                  ...values,
                                  user: {
                                    ...values.user,
                                    delete: !values.user.delete,
                                  },
                                })
                              }
                              className="switch-input"
                              id="toggle50"
                            />
                            <span
                              className="switch-label"
                              data-on=""
                              data-off=""
                            ></span>
                            <span className="switch-handle"></span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <></>
                )}

                {myState.viewPermission === "creatingAccountant" ||
                myState.viewPermission === "creatingAdminOrDispatcher" ? (
                  <>
                    <div className="row">
                      <div className="col-md-4 pl-5">
                        <div className="form-group my-3">
                          <div className="d-flex">
                            <div className="btn-choice">
                              <label className="switch">
                                <input
                                  type="checkbox"
                                  onChange={() =>
                                    setValues({
                                      ...values,
                                      reports: {
                                        ...values.reports,
                                        isAllow: !values.reports.isAllow,
                                      },
                                    })
                                  }
                                  className="switch-input"
                                  id="toggle26"
                                />
                                <span
                                  className="switch-label"
                                  data-on=""
                                  data-off=""
                                ></span>
                                <span className="switch-handle"></span>
                              </label>
                            </div>
                            <div className="pl-3 my-2">
                              <label
                                className="f-14 text-dark mb-12"
                                data-label=""
                                htmlFor="toggle27"
                              >
                                Reports
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-2 my-3">
                        <div className="btn-choice">
                          <label className="switch">
                            <input
                              type="checkbox"
                              onChange={() =>
                                setValues({
                                  ...values,
                                  reports: {
                                    ...values.reports,
                                    create: !values.reports.create,
                                  },
                                })
                              }
                              className="switch-input"
                              id="toggle27"
                            />
                            <span
                              className="switch-label"
                              data-on=""
                              data-off=""
                            ></span>
                            <span className="switch-handle"></span>
                          </label>
                        </div>
                      </div>
                      <div className="col-md-2 my-3">
                        <div className="btn-choice">
                          <label className="switch">
                            <input
                              type="checkbox"
                              onChange={() =>
                                setValues({
                                  ...values,
                                  reports: {
                                    ...values.reports,
                                    read: !values.reports.read,
                                  },
                                })
                              }
                              className="switch-input"
                              id="toggle28"
                            />
                            <span
                              className="switch-label"
                              data-on=""
                              data-off=""
                            ></span>
                            <span className="switch-handle"></span>
                          </label>
                        </div>
                      </div>
                      <div className="col-md-2 my-3">
                        <div className="btn-choice">
                          <label className="switch">
                            <input
                              type="checkbox"
                              onChange={() =>
                                setValues({
                                  ...values,
                                  reports: {
                                    ...values.reports,
                                    update: !values.reports.update,
                                  },
                                })
                              }
                              className="switch-input"
                              id="toggle29"
                            />
                            <span
                              className="switch-label"
                              data-on=""
                              data-off=""
                            ></span>
                            <span className="switch-handle"></span>
                          </label>
                        </div>
                      </div>
                      <div className="col-md-2 my-3">
                        <div className="btn-choice">
                          <label className="switch">
                            <input
                              type="checkbox"
                              onChange={() =>
                                setValues({
                                  ...values,
                                  reports: {
                                    ...values.reports,
                                    delete: !values.reports.delete,
                                  },
                                })
                              }
                              className="switch-input"
                              id="toggle30"
                            />
                            <span
                              className="switch-label"
                              data-on=""
                              data-off=""
                            ></span>
                            <span className="switch-handle"></span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <></>
                )}
                {myState.viewPermission === "creatingAccountant" ? (
                  <>
                    <div className="row ">
                      <div className="col-md-4 pl-5">
                        <div className="form-group my-3">
                          <div className="d-flex">
                            <div className="btn-choice">
                              <label className="switch">
                                <input
                                  type="checkbox"
                                  onChange={() =>
                                    setValues({
                                      ...values,
                                      accounting: {
                                        ...values.accounting,
                                        isAllow: !values.accounting.isAllow,
                                      },
                                    })
                                  }
                                  className="switch-input"
                                  id="toggle66"
                                />
                                <span
                                  className="switch-label"
                                  data-on=""
                                  data-off=""
                                ></span>
                                <span className="switch-handle"></span>
                              </label>
                            </div>
                            <div className="pl-3 my-2">
                              <label
                                className="f-14 text-dark mb-12"
                                data-label=""
                                htmlFor="toggle66"
                              >
                                Accounting
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-2 my-3">
                        <div className="btn-choice">
                          <label className="switch">
                            <input
                              type="checkbox"
                              onChange={() =>
                                setValues({
                                  ...values,
                                  accounting: {
                                    ...values.accounting,
                                    create: !values.accounting.create,
                                  },
                                })
                              }
                              className="switch-input"
                              id="toggle67"
                            />
                            <span
                              className="switch-label"
                              data-on=""
                              data-off=""
                            ></span>
                            <span className="switch-handle"></span>
                          </label>
                        </div>
                      </div>
                      <div className="col-md-2 my-3">
                        <div className="btn-choice">
                          <label className="switch">
                            <input
                              type="checkbox"
                              onChange={() =>
                                setValues({
                                  ...values,
                                  accounting: {
                                    ...values.accounting,
                                    read: !values.accounting.read,
                                  },
                                })
                              }
                              className="switch-input"
                              id="toggle68"
                            />
                            <span
                              className="switch-label"
                              data-on=""
                              data-off=""
                            ></span>
                            <span className="switch-handle"></span>
                          </label>
                        </div>
                      </div>
                      <div className="col-md-2 my-3">
                        <div className="btn-choice">
                          <label className="switch">
                            <input
                              type="checkbox"
                              onChange={() =>
                                setValues({
                                  ...values,
                                  accounting: {
                                    ...values.accounting,
                                    update: !values.accounting.update,
                                  },
                                })
                              }
                              className="switch-input"
                              id="toggle69"
                            />
                            <span
                              className="switch-label"
                              data-on=""
                              data-off=""
                            ></span>
                            <span className="switch-handle"></span>
                          </label>
                        </div>
                      </div>
                      <div className="col-md-2 my-3">
                        <div className="btn-choice">
                          <label className="switch">
                            <input
                              type="checkbox"
                              onChange={() =>
                                setValues({
                                  ...values,
                                  accounting: {
                                    ...values.accounting,
                                    delete: !values.accounting.delete,
                                  },
                                })
                              }
                              className="switch-input"
                              id="toggle70"
                            />
                            <span
                              className="switch-label"
                              data-on=""
                              data-off=""
                            ></span>
                            <span className="switch-handle"></span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <></>
                )}
                {myState.viewPermission === "creatingDriver" ? (
                  <>
                    <div className="row ">
                      <div className="col-md-4 pl-5">
                        <div className="form-group my-3">
                          <div className="d-flex">
                            <div className="btn-choice">
                              <label className="switch">
                                <input
                                  type="checkbox"
                                  onChange={() =>
                                    setValues({
                                      ...values,
                                      dashboardDriver: {
                                        ...values.dashboardDriver,
                                        isAllow:
                                          !values.dashboardDriver.isAllow,
                                      },
                                    })
                                  }
                                  className="switch-input"
                                  id="toggle51"
                                />
                                <span
                                  className="switch-label"
                                  data-on=""
                                  data-off=""
                                ></span>
                                <span className="switch-handle"></span>
                              </label>
                            </div>
                            <div className="pl-3 my-2">
                              <label
                                className="f-14 text-dark mb-12"
                                data-label=""
                                htmlFor="toggle52"
                              >
                                Dashboard (Driver)
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-2 my-3">
                        <div className="btn-choice">
                          <label className="switch">
                            <input
                              type="checkbox"
                              onChange={() =>
                                setValues({
                                  ...values,
                                  dashboardDriver: {
                                    ...values.dashboardDriver,
                                    create: !values.dashboardDriver.create,
                                  },
                                })
                              }
                              className="switch-input"
                              id="toggle52"
                            />
                            <span
                              className="switch-label"
                              data-on=""
                              data-off=""
                            ></span>
                            <span className="switch-handle"></span>
                          </label>
                        </div>
                      </div>
                      <div className="col-md-2 my-3">
                        <div className="btn-choice">
                          <label className="switch">
                            <input
                              type="checkbox"
                              onChange={() =>
                                setValues({
                                  ...values,
                                  dashboardDriver: {
                                    ...values.dashboardDriver,
                                    read: !values.dashboardDriver.read,
                                  },
                                })
                              }
                              className="switch-input"
                              id="toggle53"
                            />
                            <span
                              className="switch-label"
                              data-on=""
                              data-off=""
                            ></span>
                            <span className="switch-handle"></span>
                          </label>
                        </div>
                      </div>
                      <div className="col-md-2 my-3">
                        <div className="btn-choice">
                          <label className="switch">
                            <input
                              type="checkbox"
                              onChange={() =>
                                setValues({
                                  ...values,
                                  dashboardDriver: {
                                    ...values.dashboardDriver,
                                    update: !values.dashboardDriver.update,
                                  },
                                })
                              }
                              className="switch-input"
                              id="toggle54"
                            />
                            <span
                              className="switch-label"
                              data-on=""
                              data-off=""
                            ></span>
                            <span className="switch-handle"></span>
                          </label>
                        </div>
                      </div>
                      <div className="col-md-2 my-3">
                        <div className="btn-choice">
                          <label className="switch">
                            <input
                              type="checkbox"
                              onChange={() =>
                                setValues({
                                  ...values,
                                  dashboardDriver: {
                                    ...values.dashboardDriver,
                                    delete: !values.dashboardDriver.delete,
                                  },
                                })
                              }
                              className="switch-input"
                              id="toggle55"
                            />
                            <span
                              className="switch-label"
                              data-on=""
                              data-off=""
                            ></span>
                            <span className="switch-handle"></span>
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="row ">
                      <div className="col-md-4 pl-5">
                        <div className="form-group my-3">
                          <div className="d-flex">
                            <div className="btn-choice">
                              <label className="switch">
                                <input
                                  type="checkbox"
                                  onChange={() =>
                                    setValues({
                                      ...values,
                                      myOrder: {
                                        ...values.myOrder,
                                        isAllow: !values.myOrder.isAllow,
                                      },
                                    })
                                  }
                                  className="switch-input"
                                  id="toggle56"
                                />
                                <span
                                  className="switch-label"
                                  data-on=""
                                  data-off=""
                                ></span>
                                <span className="switch-handle"></span>
                              </label>
                            </div>
                            <div className="pl-3 my-2">
                              <label
                                className="f-14 text-dark mb-12"
                                data-label=""
                                htmlFor="toggle57"
                              >
                                My Booking (Driver)
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-2 my-3">
                        <div className="btn-choice">
                          <label className="switch">
                            <input
                              type="checkbox"
                              onChange={() =>
                                setValues({
                                  ...values,
                                  myOrder: {
                                    ...values.myOrder,
                                    create: !values.myOrder.create,
                                  },
                                })
                              }
                              className="switch-input"
                              id="toggle57"
                            />
                            <span
                              className="switch-label"
                              data-on=""
                              data-off=""
                            ></span>
                            <span className="switch-handle"></span>
                          </label>
                        </div>
                      </div>
                      <div className="col-md-2 my-3">
                        <div className="btn-choice">
                          <label className="switch">
                            <input
                              type="checkbox"
                              onChange={() =>
                                setValues({
                                  ...values,
                                  myOrder: {
                                    ...values.myOrder,
                                    read: !values.myOrder.read,
                                  },
                                })
                              }
                              className="switch-input"
                              id="toggle58"
                            />
                            <span
                              className="switch-label"
                              data-on=""
                              data-off=""
                            ></span>
                            <span className="switch-handle"></span>
                          </label>
                        </div>
                      </div>
                      <div className="col-md-2 my-3">
                        <div className="btn-choice">
                          <label className="switch">
                            <input
                              type="checkbox"
                              onChange={() =>
                                setValues({
                                  ...values,
                                  myOrder: {
                                    ...values.myOrder,
                                    update: !values.myOrder.update,
                                  },
                                })
                              }
                              className="switch-input"
                              id="toggle59"
                            />
                            <span
                              className="switch-label"
                              data-on=""
                              data-off=""
                            ></span>
                            <span className="switch-handle"></span>
                          </label>
                        </div>
                      </div>
                      <div className="col-md-2 my-3">
                        <div className="btn-choice">
                          <label className="switch">
                            <input
                              type="checkbox"
                              onChange={() =>
                                setValues({
                                  ...values,
                                  myOrder: {
                                    ...values.myOrder,
                                    delete: !values.myOrder.delete,
                                  },
                                })
                              }
                              className="switch-input"
                              id="toggle60"
                            />
                            <span
                              className="switch-label"
                              data-on=""
                              data-off=""
                            ></span>
                            <span className="switch-handle"></span>
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className="row ">
                      <div className="col-md-4 pl-5">
                        <div className="form-group my-3">
                          <div className="d-flex">
                            <div className="btn-choice">
                              <label className="switch">
                                <input
                                  type="checkbox"
                                  onChange={() =>
                                    setValues({
                                      ...values,
                                      myRoutes: {
                                        ...values.myRoutes,
                                        isAllow: !values.myRoutes.isAllow,
                                      },
                                    })
                                  }
                                  className="switch-input"
                                  id="toggle61"
                                />
                                <span
                                  className="switch-label"
                                  data-on=""
                                  data-off=""
                                ></span>
                                <span className="switch-handle"></span>
                              </label>
                            </div>
                            <div className="pl-3 my-2">
                              <label
                                className="f-14 text-dark mb-12"
                                data-label=""
                                htmlFor="toggle62"
                              >
                                My Routes (Driver)
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-2 my-3">
                        <div className="btn-choice">
                          <label className="switch">
                            <input
                              type="checkbox"
                              onChange={() =>
                                setValues({
                                  ...values,
                                  myRoutes: {
                                    ...values.myRoutes,
                                    create: !values.myRoutes.create,
                                  },
                                })
                              }
                              className="switch-input"
                              id="toggle62"
                            />
                            <span
                              className="switch-label"
                              data-on=""
                              data-off=""
                            ></span>
                            <span className="switch-handle"></span>
                          </label>
                        </div>
                      </div>
                      <div className="col-md-2 my-3">
                        <div className="btn-choice">
                          <label className="switch">
                            <input
                              type="checkbox"
                              onChange={() =>
                                setValues({
                                  ...values,
                                  myRoutes: {
                                    ...values.myRoutes,
                                    read: !values.myRoutes.read,
                                  },
                                })
                              }
                              className="switch-input"
                              id="toggle63"
                            />
                            <span
                              className="switch-label"
                              data-on=""
                              data-off=""
                            ></span>
                            <span className="switch-handle"></span>
                          </label>
                        </div>
                      </div>
                      <div className="col-md-2 my-3">
                        <div className="btn-choice">
                          <label className="switch">
                            <input
                              type="checkbox"
                              onChange={() =>
                                setValues({
                                  ...values,
                                  myRoutes: {
                                    ...values.myRoutes,
                                    update: !values.myRoutes.update,
                                  },
                                })
                              }
                              className="switch-input"
                              id="toggle64"
                            />
                            <span
                              className="switch-label"
                              data-on=""
                              data-off=""
                            ></span>
                            <span className="switch-handle"></span>
                          </label>
                        </div>
                      </div>
                      <div className="col-md-2 my-3">
                        <div className="btn-choice">
                          <label className="switch">
                            <input
                              type="checkbox"
                              onChange={() =>
                                setValues({
                                  ...values,
                                  myRoutes: {
                                    ...values.myRoutes,
                                    delete: !values.myRoutes.delete,
                                  },
                                })
                              }
                              className="switch-input"
                              id="toggle65"
                            />
                            <span
                              className="switch-label"
                              data-on=""
                              data-off=""
                            ></span>
                            <span className="switch-handle"></span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </div>
            </div>
            {/* <div className="row ">
            <div className="col-md-4 pl-5">
              <div className="form-group my-3">
                <div className="d-flex">
                  <div className="btn-choice">
                    <label className="switch">
                      <input
                        type="checkbox"
                        onClick={() =>
                          setValues({
                            ...values,
                            dashboardDispatcher: {
                              ...values.dashboardDispatcher,
                              isAllow: !values.dashboardDispatcher.isAllow,
                            },
                          })
                        }
                        className="switch-input"
                        id="toggle"
                      />
                      <span
                        className="switch-label"
                        data-on=""
                        data-off=""
                      ></span>
                      <span className="switch-handle"></span>
                    </label>
                  </div>
                  <div className="pl-3 my-2">
                    <label
                      className="f-14 text-dark mb-12"
                      data-label=""
                      htmlFor="clnumber"
                    >
                      Dashboard (Dispatcher)
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-2 my-3">
              <div className="btn-choice">
                <label className="switch">
                  <input
                    type="checkbox"
                    onClick={() =>
                      setValues({
                        ...values,
                        dashboardDispatcher: {
                          ...values.dashboardDispatcher,
                          create: !values.dashboardDispatcher.create,
                        },
                      })
                    }
                    className="switch-input"
                    id="toggle"
                  />
                  <span
                    className="switch-label"
                    data-on=""
                    data-off=""
                  ></span>
                  <span className="switch-handle"></span>
                </label>
              </div>
            </div>
            <div className="col-md-2 my-3">
              <div className="btn-choice">
                <label className="switch">
                  <input
                    type="checkbox"
                    onClick={() =>
                      setValues({
                        ...values,
                        dashboardDispatcher: {
                          ...values.dashboardDispatcher,
                          read: !values.dashboardDispatcher.read,
                        },
                      })
                    }
                    className="switch-input"
                    id="toggle"
                  />
                  <span
                    className="switch-label"
                    data-on=""
                    data-off=""
                  ></span>
                  <span className="switch-handle"></span>
                </label>
              </div>
            </div>
            <div className="col-md-2 my-3">
              <div className="btn-choice">
                <label className="switch">
                  <input
                    type="checkbox"
                    onClick={() =>
                      setValues({
                        ...values,
                        dashboardDispatcher: {
                          ...values.dashboardDispatcher,
                          update: !values.dashboardDispatcher.update,
                        },
                      })
                    }
                    className="switch-input"
                    id="toggle"
                  />
                  <span
                    className="switch-label"
                    data-on=""
                    data-off=""
                  ></span>
                  <span className="switch-handle"></span>
                </label>
              </div>
            </div>
            <div className="col-md-2 my-3">
              <div className="btn-choice">
                <label className="switch">
                  <input
                    type="checkbox"
                    onClick={() =>
                      setValues({
                        ...values,
                        dashboardDispatcher: {
                          ...values.dashboardDispatcher,
                          delete: !values.dashboardDispatcher.delete,
                        },
                      })
                    }
                    className="switch-input"
                    id="toggle"
                  />
                  <span
                    className="switch-label"
                    data-on=""
                    data-off=""
                  ></span>
                  <span className="switch-handle"></span>
                </label>
              </div>
            </div>
          </div> */}
            {/* <div className="row">
                <div className="col-md-4 pl-5">
                  <div className="form-group my-3">
                    <div className="d-flex">
                      <div className="btn-choice">
                        <label className="switch">
                          <input
                            type="checkbox"
                            onClick={() =>
                              setValues({
                                ...values,
                                clients: {
                                  ...values.clients,
                                  isAllow: !values.clients.isAllow,
                                },
                              })
                            }
                            className="switch-input"
                            id="toggle"
                          />
                          <span
                            className="switch-label"
                            data-on=""
                            data-off=""
                          ></span>
                          <span className="switch-handle"></span>
                        </label>
                      </div>
                      <div className="pl-3 my-2">
                        <label
                          className="f-14 text-dark mb-12"
                          data-label=""
                          htmlFor="clnumber"
                        >
                          Clients
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-2 my-3">
                  <div className="btn-choice">
                    <label className="switch">
                      <input
                        type="checkbox"
                        onClick={() =>
                          setValues({
                            ...values,
                            clients: {
                              ...values.clients,
                              create: !values.clients.create,
                            },
                          })
                        }
                        className="switch-input"
                        id="toggle"
                      />
                      <span
                        className="switch-label"
                        data-on=""
                        data-off=""
                      ></span>
                      <span className="switch-handle"></span>
                    </label>
                  </div>
                </div>
                <div className="col-md-2 my-3">
                  <div className="btn-choice">
                    <label className="switch">
                      <input
                        type="checkbox"
                        onClick={() =>
                          setValues({
                            ...values,
                            clients: {
                              ...values.clients,
                              read: !values.clients.read,
                            },
                          })
                        }
                        className="switch-input"
                        id="toggle"
                      />
                      <span
                        className="switch-label"
                        data-on=""
                        data-off=""
                      ></span>
                      <span className="switch-handle"></span>
                    </label>
                  </div>
                </div>
                <div className="col-md-2 my-3">
                  <div className="btn-choice">
                    <label className="switch">
                      <input
                        type="checkbox"
                        onClick={() =>
                          setValues({
                            ...values,
                            clients: {
                              ...values.clients,
                              update: !values.clients.update,
                            },
                          })
                        }
                        className="switch-input"
                        id="toggle"
                      />
                      <span
                        className="switch-label"
                        data-on=""
                        data-off=""
                      ></span>
                      <span className="switch-handle"></span>
                    </label>
                  </div>
                </div>
                <div className="col-md-2 my-3">
                  <div className="btn-choice">
                    <label className="switch">
                      <input
                        type="checkbox"
                        onClick={() =>
                          setValues({
                            ...values,
                            clients: {
                              ...values.clients,
                              delete: !values.clients.delete,
                            },
                          })
                        }
                        className="switch-input"
                        id="toggle"
                      />
                      <span
                        className="switch-label"
                        data-on=""
                        data-off=""
                      ></span>
                      <span className="switch-handle"></span>
                    </label>
                  </div>
                </div>
              </div> */}
            <div className="d-flex border">
              <div className="flex-grow-1">
                <div
                  className="dataTables_info"
                  id="leads-table_info"
                  role="status"
                  aria-live="polite"
                ></div>
              </div>
              <div>
                <div
                  className="dataTables_paginate paging_simple_numbers"
                  id="leads-table_paginate"
                >
                  <button
                    type="submit"
                    className="btn-primary rounded f-14 p-2 mr-3"
                    disabled={loading}
                    // onClick={handleSubmit}
                  >
                    Create
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};
export default AdminPermissionCreatePermissionComponent;
