import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { navigateUserFunc } from '../../actions/generalActions'
import AdminHeader from '../../components/adminComponents/adminHeaderSidebarTitleComponents/AdminHeader'
import AdminSidebar from '../../components/adminComponents/adminHeaderSidebarTitleComponents/AdminSidebar'
import AdminSettingPaymentsComponent from '../../components/adminComponents/adminSettingComponents/AdminSettingPaymentsComponent'
import NotAllowed from '../../components/NotAllowed'

const AdminSettingPaymentsPage = () => {
  let navigate = useNavigate();
  const auth = useSelector((state) => state?.auth);
  useEffect(() => {
    if (auth?.user?.permissionId?.accounting?.isAllow === false) {
      if ((auth?.user?.permissionId?.settings?.isAllow === false) || (auth?.user?.disable === true)){
        navigateUserFunc(auth?.user, navigate)
      }
    }
  },[])
  return (
    <>
        <AdminHeader />
        <AdminSidebar />
        <div className="body-wrapper clearfix">
        <section className="main-container " id="fullscreen">
          <div className="px-4 py-2 border-top-0 emp-dashboard">
            <div className="d-lg-flex d-md-flex d-block py-4">
              <div>
                <h4 className=" mb-0 f-21 text-capitalize font-weight-bold">Payers</h4>
              </div>
            </div>
            {
              auth?.user?.permissionId?.dashboard?.read === true || auth?.user?.permissionId?.accounting?.isAllow ?
              <AdminSettingPaymentsComponent />
              :
              <NotAllowed />
            }
          </div>
        </section>
      </div>
    </>
  )
}
export default AdminSettingPaymentsPage