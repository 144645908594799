import React from 'react'
import ExcelImport from '../../../ExcelImport'

const AdminCalculatorReport = () => {
  return (
    <>
        {/* <div>AdminCalculatorReport</div> */}
        <ExcelImport />
    </>
  )
}

export default AdminCalculatorReport